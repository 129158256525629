import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useParams } from 'react-router-dom';
import Routes from 'src/router/Routes';
import { RouteParams } from 'src/sdk/nursingHome';
import HorizontalMenu, { HorizontalMenuItems } from 'src/uikit/HorizontalMenu';

const PartnerHorizontalMenu = () => {
  const { t } = useTranslation();
  const { id } = useParams<RouteParams>();

  const MenuItem: HorizontalMenuItems[] = useMemo(() => {
    return [
      {
        routes: generatePath(Routes.PARTNER_GENERAL, { id }),
        label: t('components.partners.details.menu.general'),
      },
      {
        routes: generatePath(Routes.PARTNER_PRODUCTS, {
          id,
        }),
        label: t('components.partners.details.menu.products'),
      },
    ];
  }, [t, id]);

  return <HorizontalMenu menuItems={MenuItem} />;
};

export default PartnerHorizontalMenu;
