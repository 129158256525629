import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';
import { ReactComponent as AddSVG } from 'src/assets/add.svg';
import { ReactComponent as NoSubscriptionSVG } from 'src/assets/nosubscription.svg';
import { useAuth } from 'src/contexts/Auth';
import Routes from 'src/router/Routes';
import { NHAddress } from 'src/sdk/nursingHome';
import ButtonIcon from 'src/uikit/ButtonIcon';
import { H1 } from 'src/uikit/Typography';
import styled from 'styled-components/macro';

import GeneralCard, { Direction, Type } from './GeneralCard';

const StyledText = styled(H1)`
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

const Content = styled.div`
  background-color: ${({ theme }) => theme.palette.black.lightGrey};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

const NoSubscriptionIcon = styled(NoSubscriptionSVG)`
  margin: 45px 0;
`;

const ButtonLayout = styled.div`
  margin: 30px 0;
`;

const AddIcon = styled(AddSVG)`
  margin-right: 10px;
`;

interface Props {
  address?: NHAddress;
  prospectId?: number;
  modal?: string;
}

const NoSubscriptionCard = ({ address, prospectId, modal }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { nhSession } = useAuth();

  const handleGoToCreateSubscription = useCallback(() => {
    if (nhSession?.id) {
      history.push(
        generatePath(Routes.NURSING_SUBSCRIPTION_CREATION, {
          id: nhSession.id,
        }),
      );
    }
  }, [history, nhSession?.id]);

  return (
    <GeneralCard
      title={t('components.subscriptionCard.title')}
      type={Type.DISABLED}
      direction={Direction.COLUMN}
    >
      <Content>
        <NoSubscriptionIcon />
        <StyledText>
          {t('components.subscriptionCard.noSubscriptionText')}
        </StyledText>
        <ButtonLayout>
          <ButtonIcon
            prepend={<AddIcon />}
            label={t('components.subscriptionCard.addButton')}
            handleClick={handleGoToCreateSubscription}
          />
        </ButtonLayout>
      </Content>
    </GeneralCard>
  );
};

export default NoSubscriptionCard;
