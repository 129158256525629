import { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useFetchSubscriptionsBillingQuery from 'src/components/Subscription/hooks/useFetchSubscriptionsBillingQuery';
import { useAuth } from 'src/contexts/Auth';
import { AlertCard } from 'src/uikit/AlertCard';

import Pagination, { DEFAULT_PAGE_SIZE } from '../../Misc/Pagination';
import SubscriptionBillingCards from './SubscriptionBillingCards';
import SubscriptionBillingHeader from './SubscriptionBillingHeader';

const PAGE_SIZE = DEFAULT_PAGE_SIZE;

const SubscriptionBillingList = () => {
  const { t } = useTranslation();
  const { nhSession } = useAuth();
  const [page = 0, setPage] = useState<number>();

  const { data, isLoading, refetch } = useFetchSubscriptionsBillingQuery({
    page,
    size: PAGE_SIZE,
    nursingHomeId: Number(nhSession?.id),
  });

  return (
    <>
      <SubscriptionBillingHeader />
      {data?.empty && <AlertCard>{t('components.list.noResult')}</AlertCard>}
      {isLoading && (
        <div className="d-flex justify-content-center align-items-center">
          <Spinner animation="border" variant="secondary" />
        </div>
      )}
      <SubscriptionBillingCards data={data?.content} onChange={refetch} />
      {data && data.totalPages > 1 && (
        <Pagination
          totalElements={data.totalElements}
          perPage={PAGE_SIZE}
          onPageChange={setPage}
          currentPage={page}
        />
      )}
    </>
  );
};

export default SubscriptionBillingList;
