import { QueryObserverOptions, useQuery } from 'react-query';
import request from 'src/axios';
import { Partner, PartnerExpand } from 'src/sdk/partner';

type QueryParams = {
  expand: PartnerExpand[];
};

const fetchCurrentPartner = async ({ expand }: QueryParams) => {
  const { data } = await request.get(`/partners/current`, {
    params: {
      expand: expand.join(', '),
    },
  });

  return data;
};

const useFetchCurrentPartnerQuery = (
  params: QueryParams,
  options?: QueryObserverOptions<Partner, Error>,
) => {
  const { data, isLoading, refetch, error } = useQuery<Partner, Error>(
    [`currentPartner`, { ...params }],
    () => fetchCurrentPartner(params),
    {
      ...(options || {}),
    },
  );

  return {
    data,
    isLoading,
    refetch,
    error,
  };
};

export default useFetchCurrentPartnerQuery;
