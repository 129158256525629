import { useTranslation } from 'react-i18next';
import { ReactComponent as Valid } from 'src/assets/validation.svg';
import { ReactComponent as Warning } from 'src/assets/warning.svg';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  gap: 1rem;
`;

const WrapperIcon = styled.div<{ isValid?: boolean }>`
  display: flex;
  align-items: center;

  width: 20px;
  height: 20px;

  svg {
    width: 100%;
    height: 100%;
    color: ${({ theme, isValid }) =>
      isValid ? theme.palette.main.greenElectric : theme.palette.main.red};
  }
`;

interface Props {
  message: string;
  isValid: boolean;
}

const PasswordMatcherItem = ({ message, isValid }: Props) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <WrapperIcon isValid={isValid}>
        {isValid ? <Valid /> : <Warning />}
      </WrapperIcon>
      {t(`components.FormPasswordInput.PasswordMatcher.${message}`)}
    </Wrapper>
  );
};

export default PasswordMatcherItem;
