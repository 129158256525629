import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as HomeIcon } from 'src/assets/home.svg';
import useToast from 'src/hooks/useToast';
import { PartnerProspect } from 'src/sdk/partner-prospect';
import { Button, ButtonColors } from 'src/uikit/Button';
import { P } from 'src/uikit/Typography';
import styled from 'styled-components/macro';

import useDeletePartnerProspectMutation from '../hooks/useDeletePartnerProspectMutation';
import PartnerProspectsModal from '../PartnerProspectModal';

const StyledMessageWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: start-end;
  gap: 5px;
`;

const StyledButtonsWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
`;

interface Props {
  partnerProspect: PartnerProspect | null;
  show: boolean;
  onFinish: () => void;
}

const PartnerProspectsDeleteModal = ({
  partnerProspect,
  show,
  onFinish,
}: Props) => {
  const { t } = useTranslation();
  const { toastSuccess, toastError } = useToast();

  const { deletePartnerProspectMutation } = useDeletePartnerProspectMutation();

  const onClick = async () => {
    if (!partnerProspect) {
      return;
    }

    try {
      await deletePartnerProspectMutation.mutateAsync({
        id: partnerProspect.id,
      });

      toastSuccess(
        t(
          'components.PartnerProspects.PartnerProspectsList.PartnerProspectsDeleteModal.success',
        ),
      );
      handleClose();
    } catch (err: any) {
      if (err.code) {
        return toastError(t(`errorsApi.${err.code}`));
      }
      toastError(
        t(
          'components.PartnerProspects.PartnerProspectsList.PartnerProspectsDeleteModal.error',
        ),
      );
    }
  };

  const handleClose = useCallback(() => {
    onFinish?.();
  }, [onFinish]);

  return (
    <PartnerProspectsModal
      title={t(
        'components.PartnerProspects.PartnerProspectsList.PartnerProspectsDeleteModal.title',
        {
          name: partnerProspect?.name,
        },
      )}
      icon={<HomeIcon />}
      show={Boolean(partnerProspect && show)}
      handleClose={handleClose}
    >
      <StyledMessageWrapper>
        <P>
          {t(
            'components.PartnerProspects.PartnerProspectsList.PartnerProspectsDeleteModal.content',
          )}
        </P>
      </StyledMessageWrapper>

      <StyledButtonsWrapper>
        <Button background={ButtonColors.CANCEL} onClick={handleClose}>
          {t('common.action.cancel')}
        </Button>
        <Button onClick={onClick}>
          {t(
            'components.PartnerProspects.PartnerProspectsList.PartnerProspectsDeleteModal.delete',
          )}
        </Button>
      </StyledButtonsWrapper>
    </PartnerProspectsModal>
  );
};

export default PartnerProspectsDeleteModal;
