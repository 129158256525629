import { AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import request from 'src/axios';

const useDeleteProductPartnerMutation = () => {
  const queryClient = useQueryClient();

  const deleteProductPartnerMutation = useMutation<
    AxiosResponse<any>,
    Error,
    { id: number; productId: number }
  >(
    ({ id, productId }) =>
      request.delete(`/partners/${id}/products/${productId}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`productsList`);
      },
    },
  );

  return deleteProductPartnerMutation;
};

export default useDeleteProductPartnerMutation;
