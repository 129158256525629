import { Redirect, Switch, useLocation } from 'react-router-dom';
import ProvisioningHorizontalMenu from 'src/components/Provisioning/ProvisioningHorizontalMenu';
import ProvisioningChargingStation from 'src/pages/ProvisioningChargingStation';
import ProvisioningClipon from 'src/pages/ProvisioningClipon';
import ProvisioningGateway from 'src/pages/ProvisioningGateway';
import ProvisioningShippingRequests from 'src/pages/ProvisioningShippingRequests';

import PrivateRoute from './PrivateRoute';
import Routes from './Routes';

const ProvisioningSubmenuLayout = () => {
  const location = useLocation();

  return location.pathname === Routes.EQUIPMENT ? (
    <Redirect to={Routes.EQUIPMENT_CLIPON} />
  ) : (
    <>
      <ProvisioningHorizontalMenu />
      <Switch>
        <PrivateRoute
          path={Routes.EQUIPMENT_CLIPON}
          component={ProvisioningClipon}
        />
        <PrivateRoute
          path={Routes.EQUIPMENT_GATEWAY}
          component={ProvisioningGateway}
        />
        <PrivateRoute
          path={Routes.EQUIPMENT_CHARGING}
          component={ProvisioningChargingStation}
        />
        <PrivateRoute
          path={Routes.EQUIPMENT_ORDERING}
          component={ProvisioningShippingRequests}
        />
      </Switch>
    </>
  );
};
export default ProvisioningSubmenuLayout;
