import { useEffect } from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { useParams, withRouter } from 'react-router-dom';
import EquipmentBlock from 'src/components/NursingHome/General/EquipmentBlock';
import NoEquipmentCard from 'src/components/NursingHome/General/NoEquipmentCard';
import NoSubscriptionCard from 'src/components/NursingHome/General/NoSubscriptionCard';
import useFetchNursingHomeQuery from 'src/components/NursingHome/hooks/useFetchNursingHomeQuery';
import SubscriptionCard from 'src/components/Subscription/SubscriptionCard';
import { RouteParams } from 'src/sdk/nursingHome';
import styled from 'styled-components/macro';

import BillingCard from './BillingCard';
import DeliveryAddressCard from './DeliveryAddressCard';
import ShippingRequestCard from './ShippingRequestCard';

const Content = styled(Container)`
  margin: 0;
  max-width: 1400px;
  margin: 32px auto 0 auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const StyledRow = styled(Row)`
  gap: 1rem;
  flex-wrap: nowrap;
  padding-right: 1rem;
`;

const NursingHomeGeneralInformation = () => {
  const { id, modal } = useParams<RouteParams>();

  const { data, isLoading, refetch } = useFetchNursingHomeQuery({
    id: Number(id),
    expand: [
      'address',
      'delivery-addresses',
      'subscription',
      'gateways',
      'clipons',
      'charging-stations',
    ],
  });

  useEffect(() => {
    if (id) {
      refetch();
    }
  }, [id, refetch]);

  return (
    <Content>
      {isLoading && (
        <div className="d-flex justify-content-center align-items-center">
          <Spinner animation="border" variant="secondary" />
        </div>
      )}
      <StyledRow>
        <Col md="6">
          <BillingCard address={data?.address} />
        </Col>
        <Col md="6">
          <DeliveryAddressCard addresses={data?.deliveryAddresses} />
        </Col>
      </StyledRow>
      <StyledRow>
        <Col md="6">
          {data?.subscription ? (
            <SubscriptionCard nursingHome={data} />
          ) : (
            <NoSubscriptionCard address={data?.address} modal={modal} />
          )}
        </Col>
        <Col md="6">
          {data?.subscription ? (
            <EquipmentBlock currentEquipmentCount={data} />
          ) : (
            <NoEquipmentCard />
          )}
        </Col>
      </StyledRow>
      <Row>
        <Col>
          <ShippingRequestCard />
        </Col>
      </Row>
    </Content>
  );
};

export default withRouter(NursingHomeGeneralInformation);
