import { Card as CardBoostrap } from 'react-bootstrap';
import styled, { css } from 'styled-components/macro';

import { Shadow, shadows } from '../theme/shadows';

export type CardProps = {
  shadow?: Shadow;
  $clickable?: boolean;
  border?: boolean;
  disabled?: boolean;
};

export const Card = styled(CardBoostrap)<CardProps>`
  background-color: ${({ theme }) => theme.palette.main.white};
  border-radius: 0.75rem;
  box-sizing: border-box;
  border: none;
  transition: box-shadow 100ms ease-in;
  will-change: transform;

  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;

  ${({ disabled }) =>
    disabled &&
    css`
      background: ${({ theme }) => theme.palette.black.lightGrey};
      border: 1px solid ${({ theme }) => theme.palette.black.grey};
      color: ${({ theme }) => theme.palette.black[70]};
    `}

  ${({ border }) =>
    border &&
    css`
      border: 1px solid ${({ theme }) => theme.palette.black.grey};
    `}

  ${({ shadow }) =>
    shadow &&
    css`
      box-shadow: ${shadows[shadow]};
    `}

  ${({ $clickable }) =>
    $clickable &&
    css`
      transition: box-shadow 100ms ease-in, transform 100ms ease;
      -webkit-font-smoothing: subpixel-antialiased;

      cursor: pointer;
      :hover {
        backface-visibility: hidden;
        transform: scale(1.01) translate3d(0, 0, 0);
        box-shadow: ${({ theme }) => theme.shadows.md};
      }
    `}
`;

export const CardHeader = styled(CardBoostrap.Header)`
  background: none;
  border: none;
  padding-block: 0.5rem;
  padding-inline: 1rem;
`;

export const CardBody = styled(CardBoostrap.Body)`
  padding-block: 0.5rem;
  padding-inline: 1rem;
`;
