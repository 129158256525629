import styled from 'styled-components/macro';

const BR = styled.br``;

const HR = styled.hr`
  border: none;
  border-top: 1px solid #b5b5be;
  color: #b5b5be;
  overflow: visible;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 10rem;
`;

interface Props {
  invisible?: boolean;
}

const Separator = ({ invisible }: Props) => {
  return <>{invisible ? <BR /> : <HR />}</>;
};

export default Separator;
