import { forwardRef, useState } from 'react';
import { OptionsList } from 'react-select-async-paginate';
import request from 'src/axios';
import { GetPartnersResponse, Partner } from 'src/sdk/partner';
import AsyncSelectInput, { AsyncOptionType } from 'src/uikit/AsyncSelectInput';

const size = 1000;

interface Props {
  value: number;
  onChange: (value: number) => void;
  placeholder: string;
  isInvalid?: boolean;
}

const PartnerAsyncSelect = forwardRef<HTMLSelectElement, Props>(
  ({ value, onChange, isInvalid, ...rest }, ref) => {
    const [listOptions, setListOptions] = useState<AsyncOptionType<number>[]>(
      [],
    );
    const loadOptions = async (
      search: string,
      prevOptions: OptionsList<AsyncOptionType<number>>,
    ) => {
      const { data } = await request.get<GetPartnersResponse>('/partners', {
        params: {
          size,
          'name-or-soldto-part': search,
          sort: `name,ASC`,
        },
      });
      const options: AsyncOptionType<number>[] = data.content.map(
        (partner: Partner) => ({
          value: partner.id,
          label: partner.name,
        }),
      );
      const slicedOptions = [
        ...prevOptions,
        ...options,
      ] as AsyncOptionType<number>[];
      const hasMore = (data.pageable?.pageNumber || 0) < data.totalPages - 1;
      setListOptions(listOptions);
      return {
        options: slicedOptions,
        hasMore,
      };
    };

    return (
      <AsyncSelectInput
        ref={ref}
        loadOptions={loadOptions}
        value={
          listOptions.find((e) => e.value === value) as AsyncOptionType<number>
        }
        onChange={(val: { value: number; label: string }) =>
          onChange(val.value)
        }
        isInvalid={isInvalid}
        {...rest}
      />
    );
  },
);

export default PartnerAsyncSelect;
