import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { Col, Form, Row, Stack } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import { ReactComponent as EditIcon } from 'src/assets/edit-badge.svg';
import { ReactComponent as PostalIcon } from 'src/assets/postal.svg';
import useOptionsCountries from 'src/hooks/useOptionsCountries';
import { Button, ButtonColors } from 'src/uikit/Button';
import { Card, CardBody, CardHeader } from 'src/uikit/Card';
import { FormGroup, FormLabel } from 'src/uikit/Form';
import FormSelectInput from 'src/uikit/Form/SelectInput';
import FormTextInput from 'src/uikit/Form/TextInput';
import { H3, Label, Text } from 'src/uikit/Typography';
import styled from 'styled-components/macro';
import * as yup from 'yup';

const IconButton = styled(Button)`
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.25rem;
  width: 40px;
  height: 40px;
  color: ${({ theme }) => theme.palette.black[80]};

  svg {
    width: inherit;
    height: inherit;
  }
`;

const WrapperIcon = styled.div`
  background-color: ${({ theme }) => theme.palette.main.cyan};
  padding: 0.5rem;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  & > * {
    color: white;
  }
`;

const StyledCol = styled(Col)`
  display: flex;
  gap: 0.5rem;
  padding-block: 0.4rem;
`;

export const partnerProspectAddressFormSchema = (t: TFunction) =>
  yup.object({
    name: yup
      .string()
      .min(
        2,
        t(
          'components.PartnerProspects.PartnerProspectDetails.PartnerProspectGeneral.PartnerProspectAddressForm.errorMessage.name',
        ),
      )
      .required(),
    street: yup
      .string()
      .min(
        2,
        t(
          'components.PartnerProspects.PartnerProspectDetails.PartnerProspectGeneral.PartnerProspectAddressForm.errorMessage.street',
        ),
      )
      .required(),
    zip: yup
      .string()
      .matches(
        /^[0-9]+$/,
        t(
          'components.PartnerProspects.PartnerProspectDetails.PartnerProspectGeneral.PartnerProspectAddressForm.errorMessage.zip',
        ),
      )
      .required(),
    town: yup
      .string()
      .min(
        1,
        t(
          'components.PartnerProspects.PartnerProspectDetails.PartnerProspectGeneral.PartnerProspectAddressForm.errorMessage.town',
        ),
      )
      .required(),
    country: yup
      .string()
      .min(
        1,
        t(
          'components.PartnerProspects.PartnerProspectDetails.PartnerProspectGeneral.PartnerProspectAddressForm.errorMessage.country',
        ),
      )
      .required(),
    email: yup
      .string()
      .email(
        t(
          'components.PartnerProspects.PartnerProspectDetails.PartnerProspectGeneral.PartnerProspectAddressForm.errorMessage.emailFormat',
        ),
      ),
    telephone: yup.string(),
  });

export type PartnerProspectAddressFormSchema = yup.InferType<
  ReturnType<typeof partnerProspectAddressFormSchema>
>;

interface Props {
  defaultValues: PartnerProspectAddressFormSchema;
  onSubmit: (values: PartnerProspectAddressFormSchema) => void;
}

const PartnerProspectAddressForm = ({ onSubmit, defaultValues }: Props) => {
  const { t } = useTranslation();

  const { groupedCountriesOptions } = useOptionsCountries();

  const [edit, setEdit] = useState(false);

  const methods = useForm<PartnerProspectAddressFormSchema>({
    resolver: yupResolver(partnerProspectAddressFormSchema(t)),
    defaultValues,
    mode: 'onChange',
  });

  const {
    reset,
    handleSubmit,
    formState: { isValid, isDirty },
  } = methods;

  const onCancel = () => {
    setEdit(false);
    reset();
  };

  const _onSubmit = async (values: PartnerProspectAddressFormSchema) => {
    await onSubmit(values);
    setEdit(false);
  };

  return (
    <Card>
      <CardHeader>
        <Stack direction="horizontal" gap={2}>
          <WrapperIcon>
            <PostalIcon />
          </WrapperIcon>
          <H3 className="ml-2">
            {t(
              'components.PartnerProspects.PartnerProspectDetails.PartnerProspectGeneral.PartnerProspectAddressForm.title',
            )}
          </H3>
          <div className="ml-auto">
            <IconButton
              variant="light"
              disabled={edit}
              onClick={() => setEdit(!edit)}
            >
              <EditIcon />
            </IconButton>
          </div>
        </Stack>
      </CardHeader>
      <CardBody>
        {edit ? (
          <FormProvider {...methods}>
            <Form onSubmit={handleSubmit(_onSubmit)}>
              <FormLabel>
                {t(
                  'components.PartnerProspects.PartnerProspectDetails.PartnerProspectGeneral.PartnerProspectAddressForm.labels.address',
                )}
              </FormLabel>
              <FormGroup>
                <FormTextInput
                  name="name"
                  placeholder={t(
                    'components.PartnerProspects.PartnerProspectDetails.PartnerProspectGeneral.PartnerProspectAddressForm.placeholder.name',
                  )}
                  width="100%"
                />
              </FormGroup>
              <FormGroup>
                <FormTextInput
                  name="street"
                  placeholder={t(
                    'components.PartnerProspects.PartnerProspectDetails.PartnerProspectGeneral.PartnerProspectAddressForm.placeholder.street',
                  )}
                  width="100%"
                />
              </FormGroup>
              <Row>
                <Col>
                  <FormGroup>
                    <FormTextInput
                      name="town"
                      placeholder={t(
                        'components.PartnerProspects.PartnerProspectDetails.PartnerProspectGeneral.PartnerProspectAddressForm.placeholder.city',
                      )}
                      width="100%"
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <FormTextInput
                      name="zip"
                      placeholder={t(
                        'components.PartnerProspects.PartnerProspectDetails.PartnerProspectGeneral.PartnerProspectAddressForm.placeholder.zip',
                      )}
                      width="100%"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <FormSelectInput
                      name="country"
                      placeholder={t(
                        'components.PartnerProspects.PartnerProspectDetails.PartnerProspectGeneral.PartnerProspectAddressForm.placeholder.country',
                      )}
                      width={'100%'}
                      options={groupedCountriesOptions}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <FormTextInput
                      name="telephone"
                      placeholder={t(
                        'components.PartnerProspects.PartnerProspectDetails.PartnerProspectGeneral.PartnerProspectAddressForm.placeholder.phone',
                      )}
                      width="100%"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <FormTextInput
                  placeholder={t(
                    'components.PartnerProspects.PartnerProspectDetails.PartnerProspectGeneral.PartnerProspectAddressForm.placeholder.email',
                  )}
                  name="email"
                  width="100%"
                />
              </FormGroup>
              <Stack
                direction="horizontal"
                gap={2}
                className="justify-content-end"
              >
                <Button background={ButtonColors.CANCEL} onClick={onCancel}>
                  {t(
                    'components.PartnerProspects.PartnerProspectDetails.PartnerProspectGeneral.PartnerProspectAddressForm.cancel',
                  )}
                </Button>
                <Button type="submit" disabled={!isValid || !isDirty}>
                  {t(
                    `components.PartnerProspects.PartnerProspectDetails.PartnerProspectGeneral.PartnerProspectAddressForm.save`,
                  )}
                </Button>
              </Stack>
            </Form>
          </FormProvider>
        ) : (
          <Row>
            <StyledCol md="12">
              <Label>{t('common.address.name')}: </Label>
              <Text as="span">{defaultValues?.name}</Text>
            </StyledCol>
            <StyledCol md="12">
              <Label>{t('common.address.street')}: </Label>
              <Text as="span">{defaultValues?.street}</Text>
            </StyledCol>
            <StyledCol md="4">
              <Label>{t('common.address.zip')}: </Label>
              <Text as="span">{defaultValues?.zip}</Text>
            </StyledCol>
            <StyledCol md="4">
              <Label>{t('common.address.town')}: </Label>
              <Text as="span">{defaultValues?.town}</Text>
            </StyledCol>
            <StyledCol md="3">
              <Label>{t('common.address.country')}: </Label>
              <Text as="span">{defaultValues?.country}</Text>
            </StyledCol>
            <StyledCol md="5">
              <Label>{t('common.address.email')}: </Label>
              <Text as="span">{defaultValues?.email}</Text>
            </StyledCol>
            <StyledCol md="5">
              <Label>{t('common.address.phone')}: </Label>
              <Text as="span">{defaultValues?.telephone}</Text>
            </StyledCol>
          </Row>
        )}
      </CardBody>
    </Card>
  );
};

export default PartnerProspectAddressForm;
