import { useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';
import { ReactComponent as AddSVG } from 'src/assets/add.svg';
import { ReactComponent as EditIcon } from 'src/assets/edit-badge.svg';
import ContractDuration from 'src/components/Misc/ContractDuration';
import SubscriptionDetails from 'src/components/Subscription/SubscriptionDetails';
import Routes from 'src/router/Routes';
import { NursingHomeResponse } from 'src/sdk/nursingHome';
import ButtonIcon from 'src/uikit/ButtonIcon';
import styled from 'styled-components/macro';

import useSubscriptionStates from './hooks/useSubscriptionStates';

const Content = styled(Row)`
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const StyledCol = styled(Col)`
  gap: 2rem;
  display: flex;
  flex-direction: column;
  max-width: 500px;
  padding: 0;
`;

const AddIcon = styled(AddSVG)`
  margin-right: 10px;
`;

const ButtonColumn = styled(Col)`
  display: flex;
  justify-content: center;
`;

type SubscriptionProps = {
  isInactive: boolean;
  nursingHome: NursingHomeResponse;
};

const SubscriptionGeneralInformation = ({
  isInactive,
  nursingHome,
}: SubscriptionProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleGoToCreateSubscription = useCallback(() => {
    history.push(
      generatePath(Routes.NURSING_SUBSCRIPTION_CREATION, {
        id: nursingHome.id,
      }),
    );
  }, [history, nursingHome.id]);

  const handleGoToUpdateSubscription = useCallback(() => {
    history.push(
      generatePath(Routes.NURSING_SUBSCRIPTION_HISTORY, { id: nursingHome.id }),
    );
  }, [history, nursingHome.id]);

  const { canCreate, canEdit } = useSubscriptionStates([
    { ...nursingHome.subscription, id: -1 },
  ]);

  return (
    <Content>
      <StyledCol>
        <SubscriptionDetails
          isInactive={isInactive}
          details={nursingHome.subscription}
        />
        <ContractDuration
          startDate={nursingHome.subscription.startDate || ''}
          endDate={nursingHome.subscription.endDate || ''}
        />
        {canCreate && (
          <div className="d-flex justify-content-center align-items-center">
            <ButtonIcon
              handleClick={handleGoToCreateSubscription}
              prepend={<AddIcon />}
              label={t('components.subscriptionCard.addButton')}
            />
          </div>
        )}
        {canEdit[0] && (
          <ButtonColumn>
            <ButtonIcon
              handleClick={handleGoToUpdateSubscription}
              prepend={<EditIcon />}
              label={t('components.subscriptionCard.editButton')}
            />
          </ButtonColumn>
        )}
      </StyledCol>
    </Content>
  );
};

export default SubscriptionGeneralInformation;
