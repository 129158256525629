import { BrowserRouter, Route, Switch } from 'react-router-dom';

import AuthLayout from './AuthLayout';
import MainLayout from './MainLayout';
import PrivateRoute from './PrivateRoute';
import Routes from './Routes';
import ScrollToTop from './ScrollToTop';

const AuthRoute = [
  Routes.EMAIL_VERIFICATION,
  Routes.LOGIN,
  Routes.REGISTER,
  Routes.RESET_PASSWORD,
  Routes.FORGET_PASSWORD,
  Routes.CHOOSE_PASSWORD,
];

const Router = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Switch>
        <Route path={AuthRoute} component={AuthLayout} />
        <PrivateRoute path={Routes.HOME} component={MainLayout} />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
