import { useState } from 'react';
import { ChevronRight } from 'src/assets/arrows';
import { ReactComponent as HomeIcon } from 'src/assets/home.svg';
import FirmwareNursingHomeDetailsList from 'src/components/Firmware/FirmwareNursingHomeDetailsList';
import { NursingHomeResponse } from 'src/sdk/nursingHome';
import { AlertCard } from 'src/uikit/AlertCard';
import { Card } from 'src/uikit/Card';
import palette from 'src/uikit/theme/palette';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';


const StyledRow = styled.div`
  display: flex;
`;

const PrimaryStyledCol = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  width: 40%;
`;

const StyledCol = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 20%;
`;

const SeeMoreStyledCol = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 10%;
`;

const ArrowIcon = styled(ChevronRight)`
  color: ${({ theme }) => theme.palette.main.primary};
  transform: scale(0.8);
  &.close {
    transform: rotate(180deg);
    transition-duration: 0.5s;
  }
`;

const HoveredCard = styled(Card)`
  width: 100%;
  cursor: pointer;
  margin-bottom: 15px;
  padding: 12px;
  background-color: ${({ theme }) => theme.palette.main.white};
  border-radius: 10px;
  opacity: 1;
  box-shadow: 0.3px 0.4px 0.6px hsl(0deg 0% 70% / 0.33),
    1.2px 1.6px 3px -2px hsl(0deg 0% 70% / 0.41);
  transition: transform 0.1s ease-in, opacity 0.1s ease-in,
    box-shadow 0.1s ease-in-out;
  &:hover {
    box-shadow: 0.3px 0.4px 0.6px hsl(0deg 0% 70% / 0.31),
      1.3px 2.2px 3.1px -1px hsl(0deg 0% 70% / 0.36),
      4.6px 7.8px 10.9px -2px hsl(0deg 0% 70% / 0.41);
    transform: scaleX(1.01);
    opacity: 1;
  }
`;

  interface Props {
    nursingHomeDetails?: NursingHomeResponse[];
  }

  const FirmwareCard = ({ nursingHomeDetails }: Props) => {
    const [detailId, setDetailId] = useState<number>();
    const { t } = useTranslation();

  const onSelected = (id: number) => {
    setDetailId(id !== detailId ? id : undefined);
  };

  return (
    <>
      {
        nursingHomeDetails?.length === 0 ? (
          <AlertCard>{t('components.list.noResult')}</AlertCard>
        )
      :
      <>
      {nursingHomeDetails?.map((detail, key) => (
        <HoveredCard key={key}>
          <StyledRow onClick={() => onSelected(detail.id)}>
            <StyledRow style={{ width: '90%' }}>
              <PrimaryStyledCol>
                <div
                  style={{
                    marginRight: '12px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: `1px solid ${palette.main.primary}`,
                    borderRadius: '100%',
                    color: palette.main.primary,
                    width: '42px',
                    height: '42px',
                  }}
                >
                  <HomeIcon />
                </div>
                <span style={{ textTransform: 'capitalize' }}>
                  {detail.name?.toLowerCase()}
                </span>
              </PrimaryStyledCol>
              <StyledCol>
                  <div>
                    {detail.firmware.versionMajor}.
                    {detail.firmware.versionMinor}.
                    {detail.firmware.versionRevision}
                  </div>
              </StyledCol>
              <StyledCol>
                {detail.counts ? (
                  <>{detail.counts.cliponCount}</>
                ) : (
                  <span>-</span>
                )}
              </StyledCol>
              <StyledCol>
                {detail.counts ? (
                  <>{detail.counts.wrongFirmwareCliponCount}</>
                ) : (
                  <span>-</span>
                )}
              </StyledCol>
            </StyledRow>
            <SeeMoreStyledCol>
              {detail.id === detailId ? (
                <ArrowIcon className="close" />
              ) : (
                <ArrowIcon />
              )}
            </SeeMoreStyledCol>
          </StyledRow>
          {detail.id === detailId && (
            <FirmwareNursingHomeDetailsList
              clipons={detail?.clipons?.map((clipon: any) => ({
                ...clipon,
                outdated:
                  `${clipon?.firmwareMajorVersion}.${clipon?.firmwareMinorVersion}.${clipon?.firmwareRevisionVersion}` !==
                  `${detail?.firmware?.versionMajor}.${detail?.firmware?.versionMinor}.${detail?.firmware?.versionRevision}`,
              }))}
            />
          )}
        </HoveredCard>
      ))}
      </>
      }
    </>
  );
};

export default FirmwareCard;
