import { AxiosResponse } from 'axios';
import { useMutation } from 'react-query';
import request from 'src/axios';

const useStartVerificationEmailMutation = () => {
  const startVerificationEmailMutation = useMutation<
    AxiosResponse<any>,
    Error,
    {
      userId: number;
    }
  >((body) => {
    return request.post(`/users/start-email-verification`, body);
  });

  return { ...startVerificationEmailMutation };
};

export default useStartVerificationEmailMutation;
