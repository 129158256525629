import { useTranslation } from 'react-i18next';
import { Button, ButtonColors } from 'src/uikit/Button';
import { H1 } from 'src/uikit/Typography';
import styled from 'styled-components/macro';

import EmailVerificationImage from './EmailVerificationImage';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 1rem;
  margin-block: 0.5rem;
`;

const Title = styled(H1)`
  font-size: 18px;
  font-weight: 700;
`;

interface Props {
  onClick: () => void;
}

const EmailVerificationStart = ({ onClick }: Props) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <EmailVerificationImage />
      <Title>
        {t('components.Auth.EmailVerification.EmailVerificationStart.title')}
      </Title>

      <Button onClick={onClick} background={ButtonColors.SUCCESS}>
        {t('components.Auth.EmailVerification.EmailVerificationStart.start')}
      </Button>
    </Wrapper>
  );
};

export default EmailVerificationStart;
