import { useMemo } from 'react';
import { Container } from 'react-bootstrap';
import { generatePath, Redirect, Switch, useLocation } from 'react-router-dom';
import SubscriptionHorizontalMenu from 'src/components/Subscription/SubscriptionHorizontalMenu';
import { useAuth } from 'src/contexts/Auth';
import SubscriptionBilling from 'src/pages/SubscriptionBilling';
import SubscriptionCreation from 'src/pages/SubscriptionCreation';
import SubscriptionHistory from 'src/pages/SubscriptionHistory';
import SubscriptionUpdate from 'src/pages/SubscriptionUpdate';
import PrivateRoute from 'src/router/PrivateRoute';
import Routes from 'src/router/Routes';
import styled from 'styled-components/macro';

const Content = styled.div`
  margin: 0 auto;
  max-width: 1400px;
`;

const SubscriptionLayout = () => {
  const { nhSession } = useAuth();
  const location = useLocation();

  const isCreationPage = useMemo(() => {
    return (
      nhSession &&
      (location.pathname ===
        generatePath(Routes.NURSING_SUBSCRIPTION_CREATION, {
          id: nhSession?.id,
        }) ||
        location.pathname ===
          generatePath(Routes.NURSING_SUBSCRIPTION_UPDATE, {
            id: nhSession?.id,
          }))
    );
  }, [location.pathname, nhSession]);

  return location.pathname ===
    generatePath(Routes.NURSING_SUBSCRIPTION, { id: nhSession?.id || -1 }) ? (
    !nhSession?.subscription ? (
      <Redirect
        to={generatePath(Routes.NURSING_SUBSCRIPTION_CREATION, {
          id: nhSession?.id || -1,
        })}
      />
    ) : (
      <Redirect
        to={generatePath(Routes.NURSING_SUBSCRIPTION_HISTORY, {
          id: nhSession?.id || -1,
        })}
      />
    )
  ) : (
    <>
      {!isCreationPage && <SubscriptionHorizontalMenu />}
      <Container fluid>
        <Content>
          <Switch>
            <PrivateRoute
              path={generatePath(Routes.NURSING_SUBSCRIPTION_HISTORY, {
                id: nhSession?.id || -1,
              })}
              component={SubscriptionHistory}
            />
            <PrivateRoute
              path={generatePath(Routes.NURSING_SUBSCRIPTION_BILLING, {
                id: nhSession?.id || -1,
              })}
              component={SubscriptionBilling}
            />
            <PrivateRoute
              path={generatePath(Routes.NURSING_SUBSCRIPTION_CREATION, {
                id: nhSession?.id || -1,
              })}
              component={SubscriptionCreation}
            />
            <PrivateRoute
              path={generatePath(Routes.NURSING_SUBSCRIPTION_UPDATE, {
                id: nhSession?.id || -1,
              })}
              component={SubscriptionUpdate}
            />
          </Switch>
        </Content>
      </Container>
    </>
  );
};

export default SubscriptionLayout;
