import { useCallback, useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateNursingHome } from 'src/sdk/nursingHome';
import { Prospect } from 'src/sdk/prospect';
import { Button } from 'src/uikit/Button';
import SwitchInput from 'src/uikit/SwitchInput';
import { FormButtonsWrapper } from 'src/uikit/Wrapper';
import styled from 'styled-components/macro';

import ListDeliveryAddress from '../../components/ListDeliveryAddress';

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  padding: 10px;
  gap: 10px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`;

const SwitchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px;
  gap: 10px;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

const StyledLabel = styled.label`
  margin: 0;
  text-align: center;
`;

type Props = {
  data: CreateNursingHome;
  prospect?: Prospect;
  isLoading: boolean;
  onSuccess: (data: Partial<CreateNursingHome>) => void;
  onCancel: () => void;
};

const NursingHomeCreationDelivery = ({
  data,
  prospect,
  isLoading,
  onSuccess,
  onCancel,
}: Props) => {
  const { t } = useTranslation();
  const [allChecked = false, setAllChecked] = useState<boolean>();
  const [selectedShipTo, setSelectedShipTo] = useState<Array<string>>(
    data.shipToValues as string[],
  );

  const isEmptySelection = (): boolean => !selectedShipTo.length;

  const addressesFiltered = useMemo(() => {
    if (!prospect?.deliveryAddresses) {
      return [];
    }

    const shipTos = prospect.deliveryAddresses.map((o) => o.shipTo);
    return prospect?.deliveryAddresses.filter(
      ({ shipTo }, index) => !shipTos.includes(shipTo, index + 1),
    );
  }, [prospect?.deliveryAddresses]);

  const addressesAvailable = useMemo(() => {
    return (
      addressesFiltered
        .filter((deliveryAddress) => deliveryAddress.available)
        .map((deliveryAddress) => deliveryAddress.shipTo) || []
    );
  }, [addressesFiltered]);

  useEffect(() => {
    selectedShipTo.length === addressesAvailable.length
      ? setAllChecked(true)
      : setAllChecked(false);
  }, [addressesAvailable.length, selectedShipTo.length]);

  const onSelected = useCallback((values) => {
    setSelectedShipTo([...values]);
  }, []);

  const handleChangeAll = useCallback(
    (value: boolean) => {
      if (value) {
        setSelectedShipTo(addressesAvailable);
        return setAllChecked(false);
      }
      setSelectedShipTo([]);
      return setAllChecked(true);
    },
    [addressesAvailable],
  );

  const onSubmit = () => {
    onSuccess({ ...data, shipToValues: selectedShipTo });
  };

  return (
    <div>
      <div
        style={{
          background: 'white',
          borderRadius: '0px 0px 12px 12px',
          padding: '16px',
        }}
      >
        <Header style={{ marginBottom: '24px' }}>
          <span>{t('if-the-customer-you-have')}</span>
          <SwitchWrapper>
            <StyledLabel htmlFor="selectAll">
              {t(
                'components.nursingHomeCreation.deliveryAddressSelection.selectAll',
              )}
            </StyledLabel>
            <SwitchInput
              onChange={handleChangeAll}
              checked={allChecked}
              disabled={isLoading}
              id="selectAll"
            />
          </SwitchWrapper>
        </Header>
        <ListDeliveryAddress
          onSelected={onSelected}
          selectedShipTo={selectedShipTo}
          addresses={addressesFiltered}
        />
      </div>
      <FormButtonsWrapper>
        <Button onClick={onCancel}>{t('common.action.previous')}</Button>
        <Button disabled={isEmptySelection()} onClick={onSubmit}>
          {t('common.action.next')}
        </Button>
      </FormButtonsWrapper>
    </div>
  );
};

export default NursingHomeCreationDelivery;
