import i18n, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';

import de from './locales/de.json';
import en from './locales/en.json';
import fr from './locales/fr.json';
import it from './locales/it.json';
import nl from './locales/nl_BE.json';

export enum Locales {
  EN = 'en',
  DE = 'de',
  FR = 'fr',
  NL = 'nl-BE',
  IT = 'it',
}

export const config: InitOptions = {
  resources: {
    [Locales.EN]: {
      translation: en,
    },
    [Locales.DE]: {
      translation: de,
    },
    [Locales.FR]: {
      translation: fr,
    },
    [Locales.NL]: {
      translation: nl,
    },
    [Locales.IT]: {
      translation: it,
    },
  },
  lng: Locales.EN,
  fallbackLng: Locales.EN,
  interpolation: {
    escapeValue: false,
  },
};

i18n.use(initReactI18next).init(config);

export const locales = [
  { locale: Locales.EN, name: 'English', tag: 'en' },
  { locale: Locales.DE, name: 'German', tag: 'de' },
  { locale: Locales.FR, name: 'French', tag: 'fr' },
  { locale: Locales.NL, name: 'Netherlands', tag: 'ne' },
];

export default i18n;
