import { useTranslation } from 'react-i18next';
import AddressCard from 'src/components/Misc/Card/AddressCard';
import { NursingHomeResponse } from 'src/sdk/nursingHome';
import { List } from 'src/uikit/Layout/List';
import { H1, H3 } from 'src/uikit/Typography';
import { PageWrapper } from 'src/uikit/Wrapper';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

interface Props {
  nursingHome: NursingHomeResponse;
}

const NursingHomeConfigurationAddressesPartner = ({ nursingHome }: Props) => {
  const { t } = useTranslation();

  return (
    <PageWrapper>
      <Wrapper>
        <H1 className="mx-auto">
          {t('nursingHome.configuration.addresses.partner.title')}
        </H1>
        <H3>{t('nursingHome.configuration.addresses.partner.subtitle')}</H3>

        <List columnSize="350px">
          {nursingHome.deliveryAddresses.map((address) => (
            <AddressCard key={address.id} address={address} />
          ))}
        </List>
      </Wrapper>
    </PageWrapper>
  );
};

export default NursingHomeConfigurationAddressesPartner;
