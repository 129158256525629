import { useCallback, useEffect, useMemo, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { ChevronLeft } from 'src/assets/arrows';
import { useAuth } from 'src/contexts/Auth';
import Routes from 'src/router/Routes';
import { Profiles } from 'src/sdk/authentification';
import { Text } from 'src/uikit/Typography';
import styled from 'styled-components/macro';

import SidebarMenuBottom from './SidebarMenuBottom';
import SidebarMenuTop from './SidebarMenuTop';

const Wrapper = styled('aside')<{ $opened: boolean }>`
  width: ${({ $opened }) => ($opened ? '250px' : '52px')};
  min-height: calc(100vh - 70px);
  text-rendering: optimizeLegibility;
  transition: width 0.3s ease-in-out;
  padding-inline: 0.5rem;
  box-shadow: ${({ theme }) => theme.shadows.xs};
  z-index: 90;
  background-color: ${({ theme }) => theme.palette.main.white};
`;

const ArrowLayout = styled(Row)<{ $opened: boolean }>`
  justify-content: ${({ $opened }) => ($opened ? 'flex-end' : 'center')};
  margin-bottom: 20px;
`;

const ArrowButton = styled.button`
  background: none;
  border: none;
  transform: rotate(180deg);
  transition: transform 0.5s ease-in-out;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;

  &.rotate {
    transform: rotate(0deg);
  }
`;

const SidebarMenu = () => {
  const history = useHistory();
  const [menuOpen = window.innerHeight > 1024, setMenuOpen] =
    useState<boolean>();
  const { authUser, nhSession, removeNhSession } = useAuth();

  useEffect(() => {
    setMenuOpen(window.innerWidth > 1024);
    window.addEventListener(
      'resize',
      () => setMenuOpen(window.innerWidth > 1024),
      false,
    );
  }, []);

  const isAdmin = useMemo(() => {
    return (
      authUser?.profile === Profiles.ORIZON_ADMIN ||
      authUser?.profile === Profiles.PARTNER_ADMIN
    );
  }, [authUser?.profile]);

  const handleClickButton = useCallback(() => {
    removeNhSession?.();
    history.push(Routes.NURSINGS);
  }, [history, removeNhSession]);

  return (
    <Wrapper $opened={menuOpen}>
      <ArrowLayout xs="auto" $opened={menuOpen}>
        <ArrowButton
          className={menuOpen ? 'rotate px-2' : 'px-2'}
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <ChevronLeft />
        </ArrowButton>
      </ArrowLayout>

      <SidebarMenuTop
        nh={nhSession}
        nhSession={nhSession}
        menuOpen={menuOpen}
        handleClickButton={handleClickButton}
      />
      {isAdmin && <SidebarMenuBottom menuOpen={menuOpen} />}
      {process.env.REACT_APP_DEPLOYMENT_DATE && menuOpen && (
        <Text className="mt-4 text-center" $color="white">
          Deployed :
          <span className="ml-1">{process.env.REACT_APP_DEPLOYMENT_DATE}</span>
        </Text>
      )}
    </Wrapper>
  );
};

export default SidebarMenu;
