import { useState } from 'react';
import { Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { Check } from 'src/assets/list-icons';
import request from 'src/axios';
import AddressCard from 'src/components/Misc/Card/AddressCard';
import useFormatErrorApi from 'src/hooks/useFormatErrorApi';
import useToast from 'src/hooks/useToast';
import { Partner, PartnerAddressesResponse } from 'src/sdk/partner';
import { Button } from 'src/uikit/Button';
import { List } from 'src/uikit/Layout/List';
import { Strong } from 'src/uikit/Typography';

type Props = {
  partner: Partner;
};

const PartnerConfigurationAddresses = ({ partner }: Props) => {
  const { t } = useTranslation();

  const { toastError, toastSuccess } = useToast();
  const [isSynchronize, setIsSynchronize] = useState(false);
  const queryClient = useQueryClient();
  const { formatErrorApi } = useFormatErrorApi();

  const onSynchronize = async () => {
    try {
      await request.get<PartnerAddressesResponse>(
        `/partners/${partner.id}/update-addresses`,
      );
      queryClient.invalidateQueries('currentPartner');
      toastSuccess(t('components.partners.details.general.form.synchronizeAddress'));
      setIsSynchronize(true);
    } catch (err) {
      const message = formatErrorApi(err);
      toastError(message);
    }
  };

  return (
    <div>
      <List columnSize="300px">
        {partner.deliveryAddresses?.map((address) => (
          <AddressCard key={address.id} address={address} />
        ))}
      </List>
      <Stack
        direction="horizontal"
        className="mt-5 justify-content-center align-items-center"
        gap={2}
      >
        <Strong>
          {t('components.partners.configuration.addresses.changeAddress')}
        </Strong>
        <Button
          className="ml-2"
          onClick={onSynchronize}
          disabled={isSynchronize}
        >
          {t('components.partners.configuration.addresses.synchronizeAddress')}
        </Button>
        {isSynchronize && <Check />}
      </Stack>
    </div>
  );
};

export default PartnerConfigurationAddresses;
