import { format } from 'date-fns';
import { Col, Row } from 'react-bootstrap';
import { ReactComponent as CliponBadge } from 'src/assets/clipon-badge.svg';
import EquipmentBadgeCol from 'src/components/Equipment/EquipmentBadgeCol';
import EquipmentStatusLabel from 'src/components/Equipment/EquipmentStatusLabel';
import { DATE_FORMAT_SECOND } from 'src/constants';
import { CliponDetails } from 'src/sdk/provisioning';
import { Card, CardBody } from 'src/uikit/Card';
import { Text, Label } from 'src/uikit/Typography';
import styled from 'styled-components/macro';

const CliponBadgeStyled = styled(CliponBadge)`
  width: 40px;
  height: 40px;
`;

const StyledCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconCol = styled(StyledCol)`
  justify-content: flex-end;
  padding-right: 1.5rem !important; // "important" is needed to overwrite the  property
`;

const NumberText = styled(Text)`
  font-weight: normal;
  margin: 0;
  color: ${({ theme }) => theme.palette.black[90]};
  word-break: break-word;
  text-align: center;
`;

const StatusLost = styled(Label)`
  background: ${({ theme }) => theme.palette.main.orange};
  color: ${({ theme }) => theme.palette.main.white};
  border-radius: 50%;
  margin: 0.254rem;
  padding: 0 8px;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1px;
`;

const StatusBroken = styled(StatusLost)`
  background: ${({ theme }) => theme.palette.main.red};
`;

interface Props {
  detail: CliponDetails;
  onClick?: () => void;
  global?: boolean;
}

const EquipmentCliponCard = ({ detail, onClick, global }: Props) => {
  return (
    <Card onClick={onClick} $clickable>
      <CardBody>
        <Row>
          <EquipmentBadgeCol>
            <CliponBadgeStyled />
          </EquipmentBadgeCol>

          {!global ? (
            <>
              <StyledCol md="3">
                <NumberText>{detail.serialNumber}</NumberText>
              </StyledCol>
              <StyledCol md="4">
                <NumberText>{detail.handlingUnit?.ssccNumber}</NumberText>
              </StyledCol>
              <StyledCol md="3">
                <NumberText>
                  {format(new Date(detail.receivedAt), DATE_FORMAT_SECOND)}
                </NumberText>
              </StyledCol>
            </>
          ) : (
            <>
              <StyledCol md="2">
                <NumberText>{detail.name}</NumberText>
              </StyledCol>
              <StyledCol md="2">
                <NumberText>{detail.serialNumber}</NumberText>
              </StyledCol>
              <StyledCol md="2">
                <EquipmentStatusLabel status={detail.equipmentStatus} />
              </StyledCol>
              <StyledCol md="2">
                <NumberText>{detail.handlingUnit?.ssccNumber}</NumberText>
              </StyledCol>
              <StyledCol md="2">
                <NumberText>
                  {format(new Date(detail.receivedAt), DATE_FORMAT_SECOND)}
                </NumberText>
              </StyledCol>
            </>
          )}

          <IconCol md="1">
            {detail.broken && <StatusBroken>B</StatusBroken>}
            {detail.lost && <StatusLost>L</StatusLost>}
          </IconCol>
        </Row>
      </CardBody>
    </Card>
  );
};

export default EquipmentCliponCard;
