import { SubscriptionResponse, SubscriptionStatus } from 'src/sdk/subscription';
import { fontSecondary } from 'src/uikit/theme/typography';
import styled, { css } from 'styled-components/macro';

const Wrapper = styled('span')<{ status: SubscriptionResponse['status'] }>`
  width: 80px;
  height: 35px;

  ${fontSecondary}
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 8px;
  gap: 10px;
  letter-spacing: 0.1px;
  border-radius: 5px;
  border: none;

  ${({ status, theme }) =>
    status === SubscriptionStatus.ACTIVE &&
    css`
      background: ${theme.palette.main.greenElectric};
      color: ${theme.palette.main.white};
    `}

  ${({ status, theme }) =>
    status === SubscriptionStatus.TERMINATED &&
    css`
      background: ${theme.palette.black[60]};
      color: ${theme.palette.main.white};
    `}

  ${({ status, theme }) =>
    status === SubscriptionStatus.BLOCKED &&
    css`
      background: ${theme.palette.main.red};
      color: ${theme.palette.main.white};
    `}

  ${({ status, theme }) =>
    status === SubscriptionStatus.CREATED &&
    css`
      background: ${theme.palette.main.white};
      border: 1px solid ${theme.palette.main.primary};
      color: ${theme.palette.main.primary};
    `}
`;

interface Props {
  status: SubscriptionResponse['status'];
}

const SubscriptionStatusBadge = ({ status }: Props) => {
  return (
    <Wrapper status={status}>
      {status && status[0].toUpperCase() + status.slice(1).toLowerCase()}
    </Wrapper>
  );
};

export default SubscriptionStatusBadge;
