import { ReactNode, forwardRef } from 'react';
import { Check, Close } from 'src/assets/list-icons';
import { Card, CardBody, CardProps } from 'src/uikit/Card';
import styled from 'styled-components/macro';

const StyledWrapperIcon = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin-right: 0.5rem;
`;

const StyledCard = styled(Card)`
  min-height: 75px;
  ${StyledWrapperIcon} {
    display: none;
  }

  :hover {
    ${StyledWrapperIcon} {
      display: flex;
    }
  }
`;

export type HoverCardProps = {
  onClick: () => void;
  type: 'add' | 'delete';
  children: ReactNode;
} & CardProps;

const HoverCard = forwardRef(
  ({ children, type, onClick, ...rest }: HoverCardProps, ref) => {
    return (
      <StyledCard
        ref={ref}
        onClick={() => !rest.disabled && onClick()}
        $clickable={!rest.disabled}
        {...rest}
      >
        <CardBody>
          <div className="h-100">
            {children}
            {!rest.disabled && (
              <StyledWrapperIcon>
                {type === 'add' && <Check />}
                {type === 'delete' && <Close />}
              </StyledWrapperIcon>
            )}
          </div>
        </CardBody>
      </StyledCard>
    );
  },
);

export default HoverCard;
