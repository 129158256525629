import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useForm, FormProvider } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import useLanguageOptions from 'src/hooks/useLanguageOptions';
import useTimeZoneOptions from 'src/hooks/useTimeZoneOptions';
import useToast from 'src/hooks/useToast';
import { Language } from 'src/sdk/common';
import { Button } from 'src/uikit/Button';
import FormSelectInput from 'src/uikit/Form/SelectInput';
import FormTextInput from 'src/uikit/Form/TextInput';
import { H4, Label, Text } from 'src/uikit/Typography';
import { CardWrapper, FormButtonsWrapper } from 'src/uikit/Wrapper';
import styled from 'styled-components/macro';
import * as yup from 'yup';

import useUpdateMainInformations from '../hooks/useUpdateMainInformations';

const StyledInputBlock = styled(Row)`
  display: flex;
`;

const StyledInputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const nursingHomeConfigurationInformationsSchema = (t: TFunction) =>
  yup.object({
    name: yup.string().min(
      2,
      t('form.error.mustBeAtLeast', {
        fieldName: t(
          'components.nursingHomeCreation.nursingHomeAccount.name.placeholder',
        ),
        nbCharMin: 2,
      }),
    ),
    language: yup.string().required(),
    timeZone: yup.string().required(),
    login: yup.string(),
    dayStartTime: yup.string(),
    nightStartTime: yup.string(),
    tolerance: yup.number(),
  });

export type NursingHomeConfigurationInformationsSchema = yup.InferType<
  ReturnType<typeof nursingHomeConfigurationInformationsSchema>
>;

type Props = {
  id: number;
  defaultValues: NursingHomeConfigurationInformationsSchema;
};

const NursingHomeConfigurationInformations = ({ defaultValues, id }: Props) => {
  const { t } = useTranslation();
  const languageOptions = useLanguageOptions();
  const timezoneOptions = useTimeZoneOptions();
  const { toastSuccess, toastError } = useToast();
  const { updateMainInformationsMutation } = useUpdateMainInformations(id);

  const methods = useForm<NursingHomeConfigurationInformationsSchema>({
    resolver: yupResolver(nursingHomeConfigurationInformationsSchema(t)),
    mode: 'onChange',
    defaultValues,
  });

  const { formState, handleSubmit, reset } = methods;
  const { isValid, isDirty } = formState;

  const onSubmit = async (
    values: NursingHomeConfigurationInformationsSchema,
  ) => {
    updateMainInformationsMutation
      .mutateAsync({
        language: values.language as Language,
        name: values.name || '',
        timeZone: values.timeZone,
      })
      .then(() => {
        toastSuccess(t('nursingHome.account.modificationForm.success'));
      })
      .catch(() => toastError(t('common.errorHasOccured')));
  };

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return (
    <CardWrapper>
      <FormProvider {...methods}>
        <H4> {t('nursingHome.account.modificationForm.title')}</H4>
        <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
          <StyledInputs>
            <StyledInputBlock className="py-3">
              <Col md="3">
                <Label>{t('nursingHome.account.modificationForm.login')}</Label>
              </Col>
              <Col md="8" className="offset-md-1">
                <Text>{defaultValues.login}</Text>
              </Col>
            </StyledInputBlock>
            <StyledInputBlock>
              <Col md="3">
                <Label className="mt-2">
                  {t('nursingHome.account.modificationForm.name.label')}
                </Label>
              </Col>
              <Col md="8" className="offset-md-1">
                <FormTextInput name="name" maxLength={64} width="100%" />
              </Col>
            </StyledInputBlock>
            <StyledInputBlock>
              <Col md="3">
                <Label className="mt-2">
                  {t('nursingHome.account.modificationForm.lang.label')}
                </Label>
              </Col>
              <Col md="8" className="offset-md-1">
                <FormSelectInput
                  placeholder={t('components.profileForm.language')}
                  options={languageOptions}
                  name="language"
                />
              </Col>
            </StyledInputBlock>
            <StyledInputBlock>
              <Col md="3">
                <Label className="mt-2">
                  {t('nursingHome.account.modificationForm.timezone.label')}
                </Label>
              </Col>
              <Col md="8" className="offset-md-1">
                <FormSelectInput
                  placeholder={t(
                    'components.nursingHomeCreation.nursingHomeAccount.timezone.placeholder',
                  )}
                  options={timezoneOptions.data || []}
                  name="timeZone"
                />
              </Col>
            </StyledInputBlock>
            <FormButtonsWrapper oneButton>
              <Button
                disabled={
                  !isValid ||
                  !isDirty ||
                  updateMainInformationsMutation.isLoading
                }
                type="submit"
              >
                {t('common.action.save')}
              </Button>
            </FormButtonsWrapper>
          </StyledInputs>
        </form>
      </FormProvider>
    </CardWrapper>
  );
};

export default NursingHomeConfigurationInformations;
