import { Link, NavLink } from 'react-router-dom';
import { fontPrimary, fontSecondary } from 'src/uikit/theme/typography';
import styled, { css } from 'styled-components/macro';

export const H1 = styled.h1`
  ${fontPrimary}
  font-size: 1.75rem;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0.1px;
  text-decoration: none;
  margin-bottom: 0;
`;

export const H2 = styled.h2`
  ${fontSecondary}
  font-size: 1.5rem;
  font-weight: 700;
  font-style: normal;
  line-height: 1.8rem;
  letter-spacing: 0.1px;
  text-decoration: none;
  margin-bottom: 0;
`;

export const H3 = styled.h3`
  ${fontPrimary}
  font-size: 1.2rem;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0.1px;
  text-decoration: none;
  margin-bottom: 0;
`;

export const H4 = styled.h4`
  ${fontPrimary}
  font-style: normal;
  font-weight: 600;
  font-size: 1.1rem;
  line-height: 1.3rem;
  letter-spacing: 0.1px;
  margin-bottom: 0;
`;

export const H5 = styled.h5`
  ${fontSecondary}
  font-size: 1rem;
  font-weight: bold;
  font-style: normal;
  line-height: 24px;
  letter-spacing: 0.1px;
  text-decoration: none;
  margin-bottom: 0;
`;

export const P = styled.p`
  ${fontPrimary}
  font-style: normal;
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 1rem;
  letter-spacing: 0.2px;
  text-decoration: none;
`;

export const Detail = styled.p`
  ${fontSecondary}
  color: ${({ theme }) => theme.palette.black[90]};
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 14px;
  margin-bottom: 0;
`;

export const Strong = styled.strong``;

export const Light = styled.span`
  color: ${({ theme }) => theme.palette.black[70]};
`;

export const Label = styled.label`
  font-size: 1rem;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0.1px;
`;

export const Placeholder = styled.span`
  ${fontPrimary}
  font-style: normal;
  font-weight: 400;
  font-size: 0.9rem;
`;

export const A = styled(Link)`
  ${fontPrimary}
  font-style: normal;
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 1rem;
  letter-spacing: 0.1px;
  color: ${({ theme }) => theme.palette.black[80]};
`;

export const SidebarLink = styled(NavLink)`
  ${fontPrimary}
  font-style: normal;
  letter-spacing: 0.1px;
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 21px;
  color: ${({ theme }) => theme.palette.black[90]};
  text-decoration: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const HeaderTableLabel = styled.span`
  border: none;
  /* background-color: transparent; */
  display: flex;
  color: ${({ theme }) => theme.palette.main.primary};
  letter-spacing: 0.8px;
  text-transform: uppercase;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 16px;
`;

type ColorText = 'primary' | 'red' | 'white';

export const Text = styled.p<{ $color?: ColorText }>`
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;

  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ $color, theme }) => {
    if ($color === 'primary') {
      return css`
        color: ${theme.palette.main.primary};
      `;
    }

    if ($color === 'red') {
      return css`
        color: ${theme.palette.main.red};
      `;
    }

    if ($color === 'white') {
      return css`
        color: ${theme.palette.main.white};
      `;
    }

    return '';
  }}
`;

export const Subtext = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 1.15rem;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.palette.black[80]};
`;

export const Emphased = styled.span`
  font-weight: 600;
  color: ${({ theme }) => theme.palette.main.primary};
`;

export const WarningText = styled.p`
  font-weight: 600;
  text-align: center;
  color: ${({ theme }) => theme.palette.main.red};
  white-space: pre-line;
`;
