import { AxiosResponse } from 'axios';
import { useMutation } from 'react-query';
import request from 'src/axios';
import { UpdateUserEmployeeIdRequest } from 'src/sdk/users';

const useUpdateUserEmployeeIdMutation = () => {
  const updateUserEmployeeIdMutation = useMutation<
    AxiosResponse,
    Error,
    UpdateUserEmployeeIdRequest & {
      id: number;
    }
  >(({ id, ...params }) => request.put(`/users/${id}/employee-id`, params));

  return { updateUserEmployeeIdMutation };
};

export default useUpdateUserEmployeeIdMutation;
