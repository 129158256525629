import { yupResolver } from '@hookform/resolvers/yup';
import { format } from 'date-fns';
import { useMemo } from 'react';
import { Form, Row } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { TFunction, Trans, useTranslation } from 'react-i18next';
import { DATE_BACKEND } from 'src/constants';
import { DeliveryAddress } from 'src/sdk/nursingHome';
import {
  SubscriptionCreationDataElementAddressRequest,
  SubscriptionCreationRequest,
} from 'src/sdk/subscription';
import { Button } from 'src/uikit/Button';
import { Label, Strong, Text } from 'src/uikit/Typography';
import { FormButtonsWrapper } from 'src/uikit/Wrapper';
import { getStartingDate } from 'src/utils/getDate';
import * as yup from 'yup';

import SubscriptionDeliveryForm, {
  SubscriptionDeliveryFormCustomAddressSchema,
  SubscriptionDeliveryFormSchema,
  subscriptionDeliveryFormSchema,
} from '../components/SubscriptionDeliveryForm';
import EquipmentSection, {
  CurrentEquipments,
  SubscriptionEquipmentFormSchema,
  subscriptionEquipmentFormSchema,
} from '../components/SubscriptionEquipmentForm';
import { useSubscriptionUpsert } from '../SubscriptionContext';

const subscriptionEquipmentsSchema = (t: TFunction) =>
  subscriptionDeliveryFormSchema(t).concat(subscriptionEquipmentFormSchema(t));

type SubscriptionEquipmentsSchema = yup.InferType<
  ReturnType<typeof subscriptionEquipmentsSchema>
>;

interface Props {
  data: SubscriptionCreationRequest;
  previousData?: SubscriptionCreationRequest;
  onSuccess: (data: Partial<SubscriptionCreationRequest>) => void;
  onCancel: () => void;
  deliveryAddresses: (DeliveryAddress & { isPartner?: boolean })[];
  currentEquipments?: CurrentEquipments;
}

const SubscriptionCreateEquipments = ({
  data,
  onSuccess,
  onCancel,
  deliveryAddresses,
  currentEquipments,
}: Props) => {
  const { t } = useTranslation();

  const { currentConfiguration } = useSubscriptionUpsert();

  const defaultValues: SubscriptionEquipmentFormSchema &
    SubscriptionDeliveryFormSchema = useMemo(() => {
    const deliveryAddressId = Object.keys(data.customAddress || {}).length
      ? -1
      : deliveryAddresses.find(
          (address) => address.id === data.deliveryAddressId,
        )?.id || -1;

    let requestedDeliveryDate = new Date(
      Math.max(
        new Date(data.requestedDeliveryDate || 0).getTime(),
        new Date(getStartingDate()).getTime(),
      ),
    );
    requestedDeliveryDate = new Date(requestedDeliveryDate.setHours(0));
    const equipements =
      data.type === 'DEMO'
        ? {
            extraClipons: 0,
            extraGateways: 0,
            extraChargingStations: 0,
          }
        : {
            extraClipons: 0,
            extraGateways: data.extraGateways || 0,
            extraChargingStations: data.extraChargingStations || 0,
          };

    return {
      requestedDeliveryDate: format(requestedDeliveryDate, DATE_BACKEND),
      deliveryAddressId,
      customAddress: (data.customAddress
        ? data.customAddress
        : null) as SubscriptionDeliveryFormCustomAddressSchema,
      shipTo: '',
      ...equipements,
    };
  }, [
    data.customAddress,
    data.requestedDeliveryDate,
    data.type,
    data.extraGateways,
    data.extraChargingStations,
    data.deliveryAddressId,
    deliveryAddresses,
  ]);

  const methods = useForm<SubscriptionEquipmentsSchema>({
    resolver: yupResolver(subscriptionEquipmentsSchema(t)),
    mode: 'onChange',
    defaultValues,
  });

  const { formState, handleSubmit, watch } = methods;
  const { isValid } = formState;

  const deliveryAddressId = watch('deliveryAddressId');

  const onSubmit = async (values: SubscriptionEquipmentsSchema) => {
    onSuccess({
      ...data,
      ...values,
      customAddress:
        values.customAddress as SubscriptionCreationDataElementAddressRequest,
      deliveryAddressId: deliveryAddresses.find(
        (address) => address.id === deliveryAddressId,
      )?.id,
    });
  };

  return (
    <FormProvider {...methods}>
      <Row className="my-4 py-2">
        <Label>{t('components.subscriptionCreation.equipments.title')}</Label>
      </Row>

      <Form onSubmit={handleSubmit(onSubmit)} id="choose-equipments-form">
        <fieldset>
          <SubscriptionDeliveryForm
            deliveryAddresses={deliveryAddresses}
            startDate={data.startDate || ''}
          />
        </fieldset>
        <fieldset className="mt-4">
          <Text>
            {t('components.subscriptionCreation.equipments.additionalQuantity')}
          </Text>
          <Trans
            i18nKey={'components.subscriptionCreation.equipments.quantityNote'}
            components={{
              span: <Strong />,
            }}
            values={{
              extraGateway: currentConfiguration?.maximumExtraGateways,
              extraCharginStation:
                currentConfiguration?.maximumExtraChargingStations,
            }}
          />
          <EquipmentSection
            residentEstimate={data?.residentEstimate || 0}
            current={currentEquipments}
          />
        </fieldset>
      </Form>
      <FormButtonsWrapper>
        <Button onClick={onCancel}>{t('common.action.previous')}</Button>
        <Button
          disabled={!isValid || deliveryAddressId === undefined}
          form="choose-equipments-form"
          type="submit"
        >
          {t('common.action.next')}
        </Button>
      </FormButtonsWrapper>
    </FormProvider>
  );
};

export default SubscriptionCreateEquipments;
