import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Address } from 'src/sdk/common';
import styled from 'styled-components/macro';

const StyledRow = styled(Row)`
  justify-content: space-between;
  gap: 1rem;
`;

const StyledCol = styled(Col)``;

const Label = styled.label`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: ${({ theme }) => theme.palette.black[90]};
  margin-right: 13px;
`;

interface Props {
  data?: Address;
}

const DeliveryAddressCard = ({ data }: Props) => {
  const { t } = useTranslation();

  return (
    <StyledRow>
      <StyledCol md="12">
        <Label>{t('common.address.name')}: </Label>
        <span>{data?.name}</span>
      </StyledCol>
      <StyledCol md="12">
        <Label>{t('common.address.street')}: </Label>
        <span>{data?.street}</span>
      </StyledCol>
      <StyledCol md="4">
        <Label>{t('common.address.zip')}: </Label>
        <span>{data?.zip}</span>
      </StyledCol>
      <StyledCol md="4">
        <Label>{t('common.address.town')}: </Label>
        <span>{data?.town}</span>
      </StyledCol>
      <StyledCol md="3">
        <Label>{t('common.address.country')}: </Label>
        <span>{data?.country}</span>
      </StyledCol>
      <StyledCol md="5">
        <Label>{t('common.address.email')}: </Label>
        <span>{data?.email}</span>
      </StyledCol>
      <StyledCol md="5">
        <Label>{t('common.address.phone')}: </Label>
        <span>{data?.telephone}</span>
      </StyledCol>
    </StyledRow>
  );
};

export default DeliveryAddressCard;
