import { format } from 'date-fns';
import { useCallback, useEffect, useMemo } from 'react';
import { Col, Row, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Address, Building, Subscription } from 'src/assets/list-icons';
import request from 'src/axios';
import { DATE_FORMAT_DAY } from 'src/constants';
import useToast from 'src/hooks/useToast';
import { EquipmentTypes } from 'src/sdk/equipment';
import {
  DeliveryAddress,
  EstimatedEquipment,
  NursingHomeResponse,
} from 'src/sdk/nursingHome';
import { SubscriptionCreationRequest } from 'src/sdk/subscription';
import { Button, ButtonColors } from 'src/uikit/Button';
import { Label } from 'src/uikit/Typography';
import { CardWrapper, FormButtonsWrapper } from 'src/uikit/Wrapper';
import styled from 'styled-components/macro';

import EquipmentCardCounter from '../components/EquipmentCardCounter';
import { CurrentEquipments } from '../components/SubscriptionDeliveryForm';
import useCreateOrUpdateSubscriptionMutation from '../hooks/useCreateOrUpdateSubscriptionMutation';

const AddressIcon = styled(Address)`
  width: 60px;
  height: 60px;
`;

const BuildingIcon = styled(Building)`
  width: 60px;
  height: 60px;
`;

const SubscriptionIcon = styled(Subscription)`
  width: 60px;
  height: 60px;
`;

const Wrapper = styled.div`
  display: grid;
  gap: 2rem;
`;

const Section = styled.div`
  width: 100%;
`;

const Title = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #44444f;
  text-align: left;
  margin: 0;
`;

const Header = styled(Row)`
  margin-block: 1rem;
  padding: 0 1rem;
`;

const ModifyCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
`;

const Content = styled(Row)`
  color: #92929d;
  padding: 0 10px;
`;

const EquipmentSection = styled.div`
  padding: 2rem 1rem;
  font-family: 'Poppins';
`;

const EquipmentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding: 0;
  gap: 1rem;
  justify-content: center;
`;

const StyledCol = styled(Col)`
  display: grid;
  gap: 4px;
  padding-left: 30px;
`;

const StyledRow = styled(Row)`
  align-items: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1px;
`;

interface Props {
  data: SubscriptionCreationRequest;
  setCurrentStep: (val: number) => void;
  onSuccess: () => void;
  nh?: NursingHomeResponse;
  deliveryAddresses: DeliveryAddress[];
  currentEquipments?: CurrentEquipments;
}

const SubscriptionSummary = ({
  data,
  setCurrentStep,
  onSuccess,
  nh,
  deliveryAddresses,
  currentEquipments,
}: Props) => {
  const { t } = useTranslation();
  const { toastSuccess, toastError } = useToast();
  const { createOrUpdateSubscriptionMutation } =
    useCreateOrUpdateSubscriptionMutation();

  const { isLoading } = createOrUpdateSubscriptionMutation;

  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      e.preventDefault();

      createOrUpdateSubscriptionMutation
        .mutateAsync({
          automaticRenewal: data.automaticRenewal,
          duration: data.duration / 12,
          extraChargingStations: data.extraChargingStations,
          extraGateways: data.extraGateways,
          nursingHomeId: data.nursingHomeId,
          requestedDeliveryDate: data.requestedDeliveryDate,
          residentEstimate: data.residentEstimate,
          startDate: data.startDate,
          type: data.type,
          ...(data.deliveryAddressId
            ? { deliveryAddressId: data.deliveryAddressId }
            : { customAddress: data.customAddress }),
          noShippingRequest: data.noShippingRequest,
        })
        .then(() => {
          toastSuccess(t('components.subscriptionCreation.validation.success'));
          onSuccess();
        })
        .catch((e) =>
          toastError(t('common.errorHasOccured') + ' .' + e.message),
        );
    },
    [
      createOrUpdateSubscriptionMutation,
      data,
      onSuccess,
      t,
      toastError,
      toastSuccess,
    ],
  );

  const { data: estimates, refetch } = useQuery<EstimatedEquipment, Error>(
    'compute-estimates',
    () =>
      request
        .get<EstimatedEquipment>(
          `/subscriptions/compute-estimates?residents=${data.residentEstimate}`,
        )
        .then((res) => res.data),
  );

  useEffect(() => {
    if (!!data && data.residentEstimate > 0) {
      refetch();
    }
  }, [data, refetch]);

  const currentDeliveryAddress = useMemo(() => {
    return deliveryAddresses.find(
      (address) =>
        data.deliveryAddressId && address.id === data.deliveryAddressId,
    );
  }, [data.deliveryAddressId, deliveryAddresses]);

  const durations = useMemo(
    () => [
      { value: 1, label: '1 month' },
      { value: 24, label: '2 years' },
      { value: 36, label: '3 years' },
      { value: 60, label: '5 years' },
    ],
    [],
  );

  const selectedDuration = useMemo(() => {
    return durations.find((duration) => duration.value === data.duration)
      ?.label;
  }, [data.duration, durations]);

  const equipements = useMemo(
    () => ({
      newClipon:
        (estimates?.cliponEstimate || 0) +
        0 -
        (currentEquipments?.clipons || 0),
      newGateway:
        (estimates?.gatewayEstimate || 0) +
        (data.extraGateways || 0) -
        (currentEquipments?.gateways || 0),
      newChargingStation:
        (estimates?.chargingStationEstimate || 0) +
        (data.extraChargingStations || 0) -
        (currentEquipments?.chargingStations || 0),
    }),
    [
      currentEquipments?.chargingStations,
      currentEquipments?.clipons,
      currentEquipments?.gateways,
      data.extraChargingStations,
      data.extraGateways,
      estimates?.chargingStationEstimate,
      estimates?.cliponEstimate,
      estimates?.gatewayEstimate,
    ],
  );

  return (
    <>
      <CardWrapper>
        <Wrapper>
          <Section>
            <Header>
              <Label>
                {t('components.subscriptionCreation.validation.title')}
              </Label>
            </Header>
            <div className="mb-4" />

            <Content>
              <Col lg="2">
                <BuildingIcon />
              </Col>
              <StyledCol lg="6">
                <Stack direction="horizontal" gap={2}>
                  <Label>
                    {t(
                      'components.subscriptionCreation.validation.nursing.name',
                    )}{' '}
                    :
                  </Label>
                  {nh?.name}
                </Stack>
                <Stack direction="horizontal" gap={2}>
                  <Label>
                    {t(
                      'components.subscriptionCreation.validation.nursing.address',
                    )}{' '}
                    :
                  </Label>
                  {nh?.address?.street} - {nh?.address?.town} {nh?.address?.zip}
                </Stack>
              </StyledCol>
            </Content>
          </Section>
          <Section>
            <Header>
              <Col lg="10">
                <Title>
                  {t(
                    'components.subscriptionCreation.validation.subscription.title',
                  )}
                </Title>
              </Col>
              <ModifyCol lg="2">
                <Button onClick={() => setCurrentStep(0)}>
                  {t('components.subscriptionCreation.validation.modifyButton')}
                </Button>
              </ModifyCol>
            </Header>
            <Content>
              <Col lg="2">
                <SubscriptionIcon />
              </Col>
              <StyledCol lg="5">
                <Stack direction="horizontal" gap={2}>
                  {' '}
                  <Label>
                    {t(
                      'components.subscriptionCreation.validation.subscription.type',
                    )}{' '}
                    :
                  </Label>
                  {data.type}
                </Stack>{' '}
                <Stack direction="horizontal" gap={2}>
                  {' '}
                  <Label>
                    {t(
                      'components.subscriptionCreation.validation.subscription.duration',
                    )}{' '}
                    :
                  </Label>
                  {selectedDuration}
                </Stack>{' '}
              </StyledCol>
              <StyledCol lg="4">
                <Stack direction="horizontal" gap={2}>
                  <Label>
                    {t(
                      'components.subscriptionCreation.validation.subscription.startingDate',
                    )}{' '}
                    :
                  </Label>
                  {format(new Date(data.startDate), DATE_FORMAT_DAY)}
                </Stack>
                <Stack direction="horizontal" gap={2}>
                  {' '}
                  <Label>
                    {t(
                      'components.subscriptionCreation.validation.subscription.residents',
                    )}{' '}
                    :
                  </Label>
                  {data.residentEstimate}
                </Stack>{' '}
              </StyledCol>
            </Content>
          </Section>
          <Section>
            <Header>
              <Col md="10">
                <Title>
                  {t(
                    'components.subscriptionCreation.validation.delivery.title',
                  )}
                </Title>
              </Col>
              <ModifyCol lg="2">
                <Button onClick={() => setCurrentStep(1)}>
                  {t('components.subscriptionCreation.validation.modifyButton')}
                </Button>
              </ModifyCol>
            </Header>
            <Content>
              <Col md="2">
                <AddressIcon />
              </Col>
              <Col md="10">
                <StyledRow>
                  <Col md="12">
                    <Label>
                      {t(
                        'components.subscriptionCreation.validation.delivery.date',
                      )}{' '}
                      :
                    </Label>
                    {format(
                      new Date(data.requestedDeliveryDate || ''),
                      DATE_FORMAT_DAY,
                    )}
                  </Col>
                </StyledRow>
                <StyledRow>
                  <Col md="12">
                    <Label>
                      {t(
                        'components.subscriptionCreation.validation.delivery.name',
                      )}{' '}
                      :
                    </Label>
                    {currentDeliveryAddress?.name || data.customAddress?.name}
                  </Col>
                </StyledRow>
                <StyledRow>
                  <Col md="12">
                    <Label>
                      {t(
                        'components.subscriptionCreation.validation.delivery.address',
                      )}{' '}
                      :
                    </Label>
                    {currentDeliveryAddress?.street ||
                      data.customAddress?.street}
                  </Col>
                </StyledRow>
                <StyledRow>
                  <Col md="4">
                    <Label>
                      {t(
                        'components.subscriptionCreation.validation.delivery.zip',
                      )}{' '}
                      :
                    </Label>
                    {currentDeliveryAddress?.zip || data.customAddress?.zip}
                  </Col>
                  <Col md="4">
                    <Label>
                      {t(
                        'components.subscriptionCreation.validation.delivery.town',
                      )}{' '}
                      :
                    </Label>
                    {currentDeliveryAddress?.town || data.customAddress?.town}
                  </Col>
                  <Col md="4">
                    <Label>
                      {t(
                        'components.subscriptionCreation.validation.delivery.country',
                      )}{' '}
                      :
                    </Label>
                    {currentDeliveryAddress?.country ||
                      data.customAddress?.country}
                  </Col>
                </StyledRow>
                <StyledRow>
                  <Col md="6">
                    <Label>
                      {t(
                        'components.subscriptionCreation.validation.delivery.email',
                      )}{' '}
                      :
                    </Label>
                    {currentDeliveryAddress?.email || data.customAddress?.email}
                  </Col>
                  <Col md="6">
                    <Label>
                      {t(
                        'components.subscriptionCreation.validation.delivery.phone',
                      )}{' '}
                      :
                    </Label>
                    {currentDeliveryAddress?.telephone ||
                      data.customAddress?.telephone}
                  </Col>
                </StyledRow>
              </Col>
            </Content>
            <EquipmentSection>
              <EquipmentWrapper>
                {equipements.newClipon !== 0 && (
                  <EquipmentCardCounter
                    type={EquipmentTypes.CLIPON}
                    value={equipements.newClipon}
                  />
                )}
                {equipements.newGateway !== 0 && (
                  <EquipmentCardCounter
                    type={EquipmentTypes.GATEWAY}
                    value={equipements.newGateway}
                  />
                )}
                {equipements.newChargingStation !== 0 && (
                  <EquipmentCardCounter
                    type={EquipmentTypes.CHARGING}
                    value={equipements.newChargingStation}
                  />
                )}
              </EquipmentWrapper>
            </EquipmentSection>
          </Section>
        </Wrapper>
      </CardWrapper>
      <FormButtonsWrapper oneButton>
        <Button
          disabled={isLoading}
          background={ButtonColors.SUCCESS}
          onClick={onSubmit}
        >
          {t('common.action.finish')}
        </Button>
      </FormButtonsWrapper>
    </>
  );
};

export default SubscriptionSummary;
