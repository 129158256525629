import { Badge as BoostrapBadge } from 'react-bootstrap';
import styled from 'styled-components/macro';

export enum BadgeStatus {
  yellow = 'yellow',
  red = 'red',
  greenElectric = 'greenElectric',
  grey = 'grey',
  primary = 'primary',
  orange = 'orange',
}

interface BadgeProps {
  bg: keyof typeof BadgeStatus;
}

export const Badge = styled(BoostrapBadge)<BadgeProps>`
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 28px;
  max-height: 42px;
  min-width: min-content;
  max-width: 100%;
  padding: 0.5rem 1rem;
  border-radius: 5px;

  &.bg-yellow {
    background-color: ${({ theme }) => theme.palette.main.yellow};
  }

  &.bg-red {
    background-color: ${({ theme }) => theme.palette.main.red};
  }

  &.bg-greenElectric {
    background-color: ${({ theme }) => theme.palette.main.greenElectric};
  }

  &.bg-grey {
    background-color: ${({ theme }) => theme.palette.black[60]};
  }

  &.bg-primary {
    background-color: ${({ theme }) => theme.palette.main.lightBlue} !important;
    color: ${({ theme }) => theme.palette.main.primary} !important;
  }

  &.bg-orange {
    background-color: ${({ theme }) => theme.palette.main.orange};
  }
`;
