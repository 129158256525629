import NursingHomeConfigurationAddressesOntex from 'src/components/NursingHome/Configuration/Addresses/NursingHomeConfigurationAddressesOntex';
import NursingHomeConfigurationAddressesPartner from 'src/components/NursingHome/Configuration/Addresses/NursingHomeConfigurationAddressesPartner';
import { usePartner } from 'src/contexts/Partner';
import { NursingHomeResponse } from 'src/sdk/nursingHome';

interface Props {
  nursingHome: NursingHomeResponse;
  id: string;
  isError?: boolean;
}

const UpdateDeliveryAddresses = ({ nursingHome, id }: Props) => {
  const { partner } = usePartner();
  
  return partner ? (
    <NursingHomeConfigurationAddressesPartner nursingHome={nursingHome} />
  ) : (
    <NursingHomeConfigurationAddressesOntex id={id} nursingHome={nursingHome} />
  );
};

export default UpdateDeliveryAddresses;
