import { useRef, useEffect } from 'react';

export default function usePortal() {
  const rootElemRef = useRef(document.createElement('div'));

  useEffect(function setupElement() {
    // Look for existing target dom element to append to
    const parentElem = document.querySelector(`body`);
    // Add the detached element to the parent
    parentElem?.appendChild(rootElemRef.current);
    // This function is run on unmount
    return function removeElement() {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      rootElemRef.current.remove();
    };
  });

  return rootElemRef.current;
}
