import { Redirect, Switch, useLocation } from 'react-router-dom';
import FirmwaresHorizontalMenu from 'src/components/Firmware/FirmwaresHorizontalMenu';
import FirmwaresDeploy from 'src/pages/FirmwaresDeploy';
import FirmwaresOverview from 'src/pages/FirmwaresOverview';
import FirmwaresUpload from 'src/pages/FirmwaresUpload';

import PrivateRoute from './PrivateRoute';
import Routes from './Routes';

const FirmwaresSubmenuLayout = () => {
  const location = useLocation();

  return location.pathname === Routes.FIRMWARES ? (
    <Redirect to={Routes.FIRMWARES_OVERVIEW} />
  ) : (
    <>
      <FirmwaresHorizontalMenu />
      <Switch>
        <PrivateRoute
          path={Routes.FIRMWARES_OVERVIEW}
          component={FirmwaresOverview}
        />
        <PrivateRoute
          path={Routes.FIRMWARES_DEPLOY}
          component={FirmwaresDeploy}
        />
        <PrivateRoute
          path={Routes.FIRMWARES_UPLOAD}
          component={FirmwaresUpload}
        />
      </Switch>
    </>
  );
};
export default FirmwaresSubmenuLayout;
