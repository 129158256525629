import { fontPrimary } from 'src/uikit/theme/typography';
import styled from 'styled-components/macro';

export const AlertCard = styled.div`
  ${fontPrimary}
  font-size: 14px;
  color: ${({ theme }) => theme.palette.main.primary};
  background-color: ${({ theme }) => theme.palette.main.lightBlue};
  border-radius: 10px;
  padding: 0.5rem 1rem;
`;
