export type PaletteColorType = keyof typeof palette;

const palette = {
  main: {
    primary: '#0C9EAB',
    white: '#FFFFFF',
    red: '#F15555',
    greenElectric: '#3DD598',
    greenApple: '#82C43C',
    lightGreen: '#DEF8ED',
    darkBlue: '#015092',
    blue: '#B1E4FF',
    lightBlue: '#DCF0F2',
    purple: '#702074',
    lightPurple: '#E8D3F8',
    orange: '#FF974A',
    lightOrange: '#FFE8D8',
    yellow: '#FFC542',
    lightYellow: '#FFF1D5',
    cyan: '#50B5FF',
    lightRed: '#FDDDDD',
    curiousBlue: '#2799D7',
  },
  black: {
    main: '#171725',
    90: '#44444F',
    80: '#696974',
    70: '#979797',
    60: '#B5B5BE',
    50: '#D5D5DC',
    strongGrey: '#7D7D7F',
    grey: '#E2E2EA',
    lightGrey: '#F1F1F5',
    strongLightGrey: '#FAFAFB',
  },
};

export default palette;
