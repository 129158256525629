import { TFunction, useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import * as yup from 'yup';

import PasswordMatcherItem from './PasswordMatcherItem';

const matcher = {
  leastMinLetter: {
    regex: /^.{8,}$/,
    message: 'leastMinLetter',
  },
  mustContainLetter: {
    regex: /(?=.*[a-z])/,
    message: 'mustContainLetter',
  },
  mustContainUpperAndLower: {
    regex: /(?=.*[A-Z])/,
    message: 'mustContainUpperAndLower',
  },
  mustContainerNumber: {
    regex: /(?=.*\d)/,
    message: 'mustContainerNumber',
  },
  mustContainerSpecialCharacter: {
    regex: /(?=.*[!@#&_(){}\[\]:;'\",?%*~$^+=<>.\\-])/,
    message: 'mustContainerSpecialCharacter',
  },
};

export const passwordSchema = (t: TFunction) =>
  yup
    .string()
    .matches(
        matcher.leastMinLetter.regex,
        t(`components.FormPasswordInput.PasswordMatcher.${matcher.leastMinLetter.message}`),
    )
    .matches(
      matcher.mustContainLetter.regex,
      t(`components.FormPasswordInput.PasswordMatcher.${matcher.mustContainLetter.message}`),
    )
    .matches(
      matcher.mustContainUpperAndLower.regex,
      t(`components.FormPasswordInput.PasswordMatcher.${matcher.mustContainUpperAndLower.message}`),
    )
    .matches(
      matcher.mustContainerNumber.regex,
      t(`components.FormPasswordInput.PasswordMatcher.${matcher.mustContainerNumber.message}`),
    )
    .matches(
      matcher.mustContainerSpecialCharacter.regex,
      t(`components.FormPasswordInput.PasswordMatcher.${matcher.mustContainerSpecialCharacter.message}`),
    );


const Wrapper = styled.div`
  display: grid;
  gap: 0.3rem;
  width: fit-content;
`;

const Title = styled.h2`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
`;

interface PropsValidation {
  value?: string;
}

const PasswordMatcher = ({ value }: PropsValidation) => {
  const { t } = useTranslation();
  return (
    <>
      <Title>{t('components.FormPasswordInput.PasswordMatcher.title')}</Title>
      <Wrapper>
        {Object.values(matcher).map((match) => {
          return (
            <PasswordMatcherItem
              key={match.message}
              isValid={Boolean(value && value.match(match.regex)?.length)}
              message={match.message}
            />
          );
        })}
      </Wrapper>
    </>
  );
};

export default PasswordMatcher;
