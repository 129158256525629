import { PropsWithChildren, ReactNode } from 'react';
import { Card } from 'src/uikit/Card';
import { fontPrimary } from 'src/uikit/theme/typography';
import styled, { css } from 'styled-components/macro';

export enum Direction {
  ROW,
  COLUMN,
}

export enum Type {
  DEFAULT,
  DISABLED,
  ALERT,
}

const Wrapper = styled(Card)<{ type?: Type }>`
  overflow: hidden;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.palette.main.white};
  height: 100%;
  width: 100%;
  border: none;

  ${({ type }) =>
    type === Type.DEFAULT &&
    css`
      background-color: ${({ theme }) => theme.palette.main.white};
    `}
  ${({ type }) =>
    type === Type.DISABLED &&
    css`
      background-color: ${({ theme }) => theme.palette.black.lightGrey};
      border: 1px solid ${({ theme }) => theme.palette.black[60]};
    `}
  ${({ type }) =>
    type === Type.ALERT &&
    css`
      background-color: ${({ theme }) => theme.palette.black.lightGrey};
    `}
`;

const Header = styled(Card.Header)<{ type?: Type }>`
  background-color: ${({ theme }) => theme.palette.main.primary};
  color: ${({ theme }) => theme.palette.main.white};
  text-align: center;
  padding: 1rem 0;
  margin: 0;
  border-bottom: none;

  ${fontPrimary}
  font-weight: 600;
  font-style: normal;

  ${({ type }) =>
    type === Type.DEFAULT &&
    css`
      background-color: ${({ theme }) => theme.palette.main.primary};
    `}
  ${({ type }) =>
    type === Type.DISABLED &&
    css`
      background-color: ${({ theme }) => theme.palette.black[60]};
    `}
  ${({ type }) =>
    type === Type.ALERT &&
    css`
      background-color: ${({ theme }) => theme.palette.main.red};
    `}
`;

const Content = styled(Card.Body)<{ direction: Direction }>`
  display: flex;
  padding: 1rem;
  flex-direction: ${({ direction }) =>
    direction === Direction.ROW ? 'row' : 'column'};
`;

type Props = {
  title?: ReactNode;
  type?: Type;
  direction?: Direction;
};

const GeneralCard = ({
  title,
  type,
  direction,
  children,
}: PropsWithChildren<Props>) => {
  return (
    <Wrapper type={type}>
      <Header type={type}>{title}</Header>
      <Content direction={direction}>{children}</Content>
    </Wrapper>
  );
};

GeneralCard.defaultProps = {
  direction: Direction.ROW,
};

export default GeneralCard;
