import { ReactNode } from 'react';
import styled from 'styled-components/macro';

import { ReactComponent as ChargingIcon } from '../../../assets/charging-badge.svg';
import { ReactComponent as ClipOnIcon } from '../../../assets/cliponx10-badge.svg';
import { ReactComponent as GatewayIcon } from '../../../assets/gateway-badge.svg';

const Wrapper = styled.div`
  display: flex;
  gap: 30px;
`;
const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const StyledIcon = styled.div`
  width: 30px;
  height: 30px;

  svg {
    width: 100%;
    height: 100%;
  }
`;

type EquipmentProps = {
  nb: number;
  icon: ReactNode;
};

const Equipment = ({ nb, icon }: EquipmentProps) => (
  <IconWrapper>
    <StyledIcon>{icon}</StyledIcon>
    <div>{nb}</div>
  </IconWrapper>
);

type Props = {
  clipOnNb: number;
  chargingStationNb: number;
  gatewayNb: number;
};

const EquipmentsWithNumber = ({
  clipOnNb,
  chargingStationNb,
  gatewayNb,
}: Props) => {
  return (
    <Wrapper>
      <Equipment nb={clipOnNb} icon={<ClipOnIcon />} />
      <Equipment nb={chargingStationNb} icon={<ChargingIcon />} />
      <Equipment nb={gatewayNb} icon={<GatewayIcon />} />
    </Wrapper>
  );
};

export default EquipmentsWithNumber;
