import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PlusBadge } from 'src/assets/plus-badge.svg';
import ListPaginated from 'src/components/Misc/ListPaginated';
import { DEFAULT_PAGE_SIZE } from 'src/components/Misc/Pagination';
import useFetchUsersQuery, {
  UsersQueryParams,
} from 'src/components/Users/hooks/useFetchUsersQuery';
import UserCreateModal from 'src/components/Users/UserCreateModal';
import UsersList from 'src/components/Users/UsersList';
import SortingUserHeader from 'src/components/Users/UsersList/SortingUserHeader';
import UserMetricList from 'src/components/Users/UsersList/UserMetricList';
import { useAuth } from 'src/contexts/Auth';
import { useModal } from 'src/hooks/useModal';
import useProfileOptions from 'src/hooks/useProfileOptions';
import { Profiles } from 'src/sdk/authentification';
import { UserResponse, UserSortField } from 'src/sdk/users';
import { Badge } from 'src/uikit/Badge';
import ButtonIcon from 'src/uikit/ButtonIcon';
import SearchInput from 'src/uikit/SearchInput';
import SelectInput from 'src/uikit/SelectInput';
import { H4 } from 'src/uikit/Typography';
import { PageWrapper } from 'src/uikit/Wrapper';

const PAGE_SIZE = DEFAULT_PAGE_SIZE;

type OptionRole = {
  value: Profiles;
  label: string;
};

const Users = () => {
  const filters: Partial<UsersQueryParams> = JSON.parse(
    localStorage.getItem('users-filters') || '{}',
  );

  const { t } = useTranslation();
  const { authUser } = useAuth();
  const { modalOpen, setModalOpen } = useModal();
  const [page, setPage] = useState(filters.page || 0);

  const [search = '', setSearch] = useState<string>(filters.search || '');

  const [profilesFilter, setProfilesFilter] = useState<Profiles | null>(
    typeof filters.profilesFilter === 'string' ? filters.profilesFilter : null,
  );

  const [sortField, setSortField] = useState<UserSortField>(
    filters.sortField || {
      label: 'employeeId',
      direction: 'asc',
    },
  );

  const profileOptions = useProfileOptions([
    Profiles.PRODUCT_SPECIALIST,
    Profiles.PARTNER_ADMIN,
    ...(authUser?.profile === Profiles.ORIZON_ADMIN
      ? [Profiles.ORIZON_ADMIN]
      : []),
  ]);

  const [filterStatus, setFilterStatus] = useState<
    UserResponse['accountStatus'] | undefined
  >(filters['account-status']);

  const { data, isLoading } = useFetchUsersQuery({
    page: page,
    expand: ['avatar-image-path'],
    extra: ['count-by-account-status', 'grand-total'],
    size: PAGE_SIZE,
    sortField,
    'account-status': filterStatus,
    ontexAndPaOnly: authUser?.profile === Profiles.ORIZON_ADMIN,
    profilesFilter: profilesFilter,
    search,
  });

  useLayoutEffect(() => {
    localStorage.setItem(
      'users-filters',
      JSON.stringify({
        page,
        sortField,
        'account-status': filterStatus,
        profilesFilter,
        search,
      }),
    );
  }, [filterStatus, page, profilesFilter, search, sortField]);

  useEffect(() => {
    setPage(0);
  }, [sortField, search, profilesFilter]);

  const handleChangeSearch = (value: string) => {
    setSearch(value);
  };

  const handleFilterMetric = useCallback(
    (value: UserResponse['accountStatus']) => {
      setFilterStatus(value === filterStatus ? undefined : value);
    },
    [filterStatus],
  );

  const currentValueProfileOption = useMemo(() => {
    const values = profileOptions.filter(
      (filterOption) => profilesFilter === filterOption.value,
    );

    return values.length > 0 ? values : null;
  }, [profileOptions, profilesFilter]);

  return (
    <PageWrapper>
      <Row>
        <UserMetricList
          extra={data?.extra}
          filterValue={filterStatus}
          onFilterBy={handleFilterMetric}
        />
      </Row>
      <Row xs="auto" className="align-items-center">
        <H4>{t('pages.users.title')}</H4>
        <Badge bg="primary" className="ml-2">
          {data?.totalElements} / {data?.extra?.grandTotal}
        </Badge>
      </Row>
      <Row
        className="justify-content-between align-items-center mt-3"
        xs="auto"
      >
        <Row className="align-items-center">
          <Col>
            <SearchInput value={search} onChange={handleChangeSearch} />
          </Col>
          <Col>
            <SelectInput
              value={currentValueProfileOption}
              onChange={(option: OptionRole) => {
                setProfilesFilter(option?.value);
              }}
              placeholder={t('pages.users.rolesFilter.placeholder')}
              options={profileOptions}
              variant="rounded"
              isClearable
            />
          </Col>
        </Row>

        <Col>
          <ButtonIcon
            prepend={<PlusBadge />}
            handleClick={() => setModalOpen(true)}
            label={t('pages.users.userButton')}
          />
        </Col>
      </Row>
      <div className="mt-3">
        <ListPaginated
          header={
            <SortingUserHeader
              onSortingChange={setSortField}
              sortField={sortField}
            />
          }
          data={data}
          isLoading={isLoading}
          page={page}
          setPage={setPage}
        >
          <UsersList users={data?.content || []} />
        </ListPaginated>
      </div>

      {modalOpen && (
        <UserCreateModal
          show={modalOpen}
          onFinish={() => setModalOpen(false)}
        />
      )}
    </PageWrapper>
  );
};

export default Users;
