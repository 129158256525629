type SortField<T> = {
  label: keyof T;
  direction: 'asc' | 'desc';
};

export function sortByDirection<T>(
  label: keyof T,
  sortField?: SortField<T>,
  onSortingChange?: (param: SortField<T>) => void,
) {
  let direction: 'asc' | 'desc' = 'asc';

  if (sortField && sortField.label === label) {
    direction = sortField.direction === 'asc' ? 'desc' : 'asc';
  }

  onSortingChange?.({ label, direction });
}
