import { Stack } from 'react-bootstrap';
import { ReactComponent as Charging } from 'src/assets/charging-stations.svg';
import { ReactComponent as Clipon } from 'src/assets/clipons.svg';
import { ReactComponent as Gateway } from 'src/assets/gateways.svg';
import { EquipmentTypes } from 'src/sdk/equipment';
import { fontSecondary } from 'src/uikit/theme/typography';
import { H2 } from 'src/uikit/Typography';
import styled from 'styled-components/macro';

const Wrapper = styled(Stack)`
  flex-direction: row;
  border-radius: 10px;
  min-width: 300px;
  max-width: 300px;
  min-height: 64px;
  max-height: 64px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
`;

const WrapperClipon = styled(Wrapper)`
  border: 1px solid ${({ theme }) => theme.palette.main.primary};
`;

const WrapperGateway = styled(Wrapper)`
  border: 1px solid ${({ theme }) => theme.palette.main.purple};
`;

const WrapperCharging = styled(Wrapper)`
  border: 1px solid ${({ theme }) => theme.palette.main.orange};
`;

const Header = styled(Stack)`
  flex-direction: row;
  display: flex;
  align-items: center;
  padding: 0;
  gap: 16px;
`;

const CliponBadge = styled(Clipon)`
  height: 24px;
  width: 24px;
  color: ${({ theme }) => theme.palette.main.white};
  border-radius: 100%;
  background: ${({ theme }) => theme.palette.main.primary};
  width: 40px;
  height: 40px;
  padding: 8px;
`;

const ChargingBadge = styled(Charging)`
  height: 24px;
  width: 24px;
  color: ${({ theme }) => theme.palette.main.white};
  border-radius: 100%;
  background: ${({ theme }) => theme.palette.main.orange};
  width: 40px;
  height: 40px;
  padding: 8px;
`;

const GatewayBadge = styled(Gateway)`
  height: 24px;
  width: 24px;
  color: ${({ theme }) => theme.palette.main.white};
  border-radius: 100%;
  background: ${({ theme }) => theme.palette.main.purple};
  width: 40px;
  height: 40px;
  padding: 8px;
`;

const ItemTitle = styled(H2)`
  ${fontSecondary}
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.black[90]};
  margin: 0;
`;

const CliponValue = styled.span`
  ${fontSecondary}
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.main.primary};
`;

const GatewayValue = styled(CliponValue)`
  color: ${({ theme }) => theme.palette.main.purple};
`;

const ChargingValue = styled(CliponValue)`
  color: ${({ theme }) => theme.palette.main.orange};
`;

interface Props {
  type: EquipmentTypes;
  value: number;
}

const EquipmentCardCounter = ({ type, value }: Props) => {
  return (
    <>
      {type === EquipmentTypes.CLIPON && (
        <WrapperClipon>
          <Header>
            <CliponBadge />
            <ItemTitle>Clip-on</ItemTitle>
          </Header>
          <CliponValue>{value}</CliponValue>
        </WrapperClipon>
      )}
      {type === EquipmentTypes.GATEWAY && (
        <WrapperGateway>
          <Header>
            <GatewayBadge />
            <ItemTitle>Gateway</ItemTitle>
          </Header>
          <GatewayValue>{value}</GatewayValue>
        </WrapperGateway>
      )}
      {type === EquipmentTypes.CHARGING && (
        <WrapperCharging>
          <Header>
            <ChargingBadge />
            <ItemTitle>Charging</ItemTitle>
          </Header>
          <ChargingValue>{value}</ChargingValue>
        </WrapperCharging>
      )}
    </>
  );
};

export default EquipmentCardCounter;
