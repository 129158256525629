import { format } from 'date-fns';
import { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link, generatePath } from 'react-router-dom';
import { ReactComponent as EditIcon } from 'src/assets/edit-badge.svg';
import { ReactComponent as TerminateIcon } from 'src/assets/delete.svg';
import { ReactComponent as SubscriptionIcon } from 'src/assets/subscription-icon.svg';
import { DATE_FORMAT_DAY } from 'src/constants';
import { useAuth } from 'src/contexts/Auth';
import Routes from 'src/router/Routes';
import { SubscriptionResponse, SubscriptionStatus } from 'src/sdk/subscription';
import { Text } from 'src/uikit/Typography';
import styled, { css } from 'styled-components/macro';
import { Button } from 'src/uikit/Button';
import { Card, CardBody } from '../../../uikit/Card';
import SubscriptionStatusBadge from './SubscriptionStatusBadge';
import useTerminateSubscription from '../hooks/useTerminateSubscription';
import useToast from 'src/hooks/useToast';
import { useTranslation } from 'react-i18next';


const StyledSubscriptionIcon = styled(SubscriptionIcon)<{ disabled: boolean }>`
    border-radius: 50%;
    color: ${({ theme }) => theme.palette.main.white};
    background-color: ${({ theme }) => theme.palette.main.primary};
    height: 40px;
    width: 40px;
    padding: 8px;

    ${({ disabled, theme }) =>
            disabled &&
            css`
                background-color: ${({ theme }) => theme.palette.black[60]};
            `}
`;

const StyledCol = styled(Col)`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const BadgeCol = styled(StyledCol)`
    justify-content: flex-start;
    padding-left: 1.5rem !important; // "important" is needed to overwrite the  property
`;

const EditCol = styled(StyledCol)`
    gap: 0.5rem;
    justify-content: end;
`;

const NumberText = styled(Text)`
    font-weight: normal;
    margin: 0;
    color: ${({ theme }) => theme.palette.black[90]};
    word-break: break-word;
    text-align: center;
`;

const EditButton = styled(Button)`
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border-radius: 6px;
`;


const TerminateButton = styled(Button)`
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border-radius: 6px;
`;

interface Props {
  subscription: SubscriptionResponse;
  index: number;
  onChange?: () => void;
}

const SubscriptionHistoryCard = ({ subscription, index, onChange }: Props) => {
  const { nhSession, authUser } = useAuth();
  const { terminateSubscription } = useTerminateSubscription(subscription.id);
  const { toastError, toastSuccess } = useToast();
  const { t } = useTranslation();


  const routeEdit = useMemo(() => {
    return subscription.status === SubscriptionStatus.ACTIVE ||
    (subscription.status === SubscriptionStatus.CREATED && !subscription.main)
      ? Routes.NURSING_SUBSCRIPTION_UPDATE
      : Routes.NURSING_SUBSCRIPTION_CREATION;
  }, [subscription.main, subscription.status]);

  const canEdit = useMemo(() => {
    return (
      subscription.status !== SubscriptionStatus.BLOCKED &&
      subscription.status !== SubscriptionStatus.TERMINATED &&
      ((subscription.type === 'DEMO' &&
          subscription.status !== SubscriptionStatus.ACTIVE) ||
        subscription.type !== 'DEMO') &&
      index === 0
    );
  }, [index, subscription.status, subscription.type]);


  const canTerminate = useMemo(() => {
    return (
      subscription.status === SubscriptionStatus.ACTIVE &&
      subscription.type !== 'DEMO' &&
      index === 0 &&
      (authUser?.profile === 'ORIZON_ADMINISTRATOR' || authUser?.profile === 'PARTNER_ADMINISTRATOR')
    );
  }, [index, subscription.status, subscription.type]);

  const handleTerminate = () => {
    terminateSubscription.mutateAsync().then(() => {
      toastSuccess(t('pages.subscription.history.success'));
    })
      .catch((err) => {
        toastError(t('pages.subscription.history.failed'));
      });
  };

  return (
    <Card key={subscription.id} shadow="md">
      <CardBody>
        <Row>
          <BadgeCol md="1">
            <StyledSubscriptionIcon
              disabled={subscription.status === SubscriptionStatus.TERMINATED}
            />
          </BadgeCol>
          <StyledCol md="2">
            <NumberText as="span">{subscription.type}</NumberText>
          </StyledCol>
          <StyledCol md="2">
            <NumberText>
              {format(new Date(subscription?.startDate || ''), DATE_FORMAT_DAY)}
            </NumberText>
          </StyledCol>
          <StyledCol md="2">
            <NumberText>
              {format(new Date(subscription?.endDate || ''), DATE_FORMAT_DAY)}
            </NumberText>
          </StyledCol>
          <StyledCol md="2">
            <NumberText>{subscription.residentEstimate}</NumberText>
          </StyledCol>
          <StyledCol md="1" />
          <EditCol md="2">
            <SubscriptionStatusBadge status={subscription.status} />
            {canEdit && (
              <EditButton
                as={Link}
                to={generatePath(routeEdit, {
                  id: nhSession?.id || -1,
                })}
              >
                <EditIcon />
              </EditButton>
            )}
            {canTerminate && (
              <TerminateButton
                background="danger"
                onClick={handleTerminate}
              >
                <TerminateIcon />
              </TerminateButton>
            )}
          </EditCol>
        </Row>
      </CardBody>
    </Card>
  );
};

export default SubscriptionHistoryCard;
