import { AxiosResponse } from 'axios';
import { useMutation } from 'react-query';
import request from 'src/axios';

export interface StartPasswordResetRequest {
  username: string;
}

const useStartPasswordResetMutation = () => {
  const { mutateAsync, isLoading } = useMutation<
    AxiosResponse<any>,
    Error,
    StartPasswordResetRequest
  >((params) => request.post(`/users/start-password-reset`, params));

  return { startPasswordResetMutation: mutateAsync, isLoading };
};

export default useStartPasswordResetMutation;
