import { useMemo, useState } from 'react';
import { Col, Row, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useFetchUsersQuery from 'src/components/Users/hooks/useFetchUsersQuery';
import UsersSimpleList from 'src/components/Users/UsersSimpleList';
import UserSimpleCard from 'src/components/Users/UsersSimpleList/UserSimpleCard';
import useToast from 'src/hooks/useToast';
import { Profiles } from 'src/sdk/authentification';
import { NursingHomeResponse } from 'src/sdk/nursingHome';
import { List } from 'src/uikit/Layout/List';
import SearchInput from 'src/uikit/SearchInput';
import { H2 } from 'src/uikit/Typography';
import { PageWrapper } from 'src/uikit/Wrapper';

import useAddNursingHomeToUserMutation from '../../../Users/hooks/useAddNursingHomeToUserMutation';
import useDeleteNursingHomeToUserMutation from '../../../Users/hooks/useDeleteNursingHomeToUserMutation';

interface Props {
  nursingHome: NursingHomeResponse;
}

const PAGE_SIZE = 1000;

const NursingHomeManageUsers = ({ nursingHome }: Props) => {
  const { t } = useTranslation();
  const { toastSuccess, toastError } = useToast();

  const { mutateAsync: mutateAsyncCreate } = useAddNursingHomeToUserMutation();
  const { mutateAsync: mutateAsyncDelete } =
    useDeleteNursingHomeToUserMutation();

  const [searchFilter, setSearchFilter] = useState<string>();
  const handleChangeSearch = (value: string) => {
    setSearchFilter(value);
  };

  const { data } = useFetchUsersQuery({
    page: 0,
    size: PAGE_SIZE,
    expand: ['avatar-image-path', 'nursing-homes'],
    sortField: {
      label: 'employeeId',
      direction: 'asc',
    },
    search: searchFilter,
    profilesFilter: Profiles.PRODUCT_SPECIALIST,
  });

  const handleClickAddSpecialist = async (userId: number) => {
    try {
      await mutateAsyncCreate({
        id: userId,
        nursingHomeId: nursingHome.id,
      });
      toastSuccess(
        t(
          'components.PartnerProspects.PartnerProspectDetails.PartnerProspectSpecialist.successAdd',
        ),
      );
    } catch (err) {
      toastError(
        t(
          'components.PartnerProspects.PartnerProspectDetails.PartnerProspectSpecialist.errorAdd',
        ),
      );
    }
  };

  const handleClickDeleteSpecialist = async (userId: number) => {
    try {
      await mutateAsyncDelete({
        id: userId,
        nursingHomeId: nursingHome.id,
      });
      toastSuccess(
        t(
          'components.PartnerProspects.PartnerProspectDetails.PartnerProspectSpecialist.successDelete',
        ),
      );
    } catch (err) {
      toastError(
        t(
          'components.PartnerProspects.PartnerProspectDetails.PartnerProspectSpecialist.errorDelete',
        ),
      );
    }
  };

  const usersOwned = useMemo(() => {
    return (
      data?.content.filter((user) => {
        return (
          user.nursingHomes &&
          user.nursingHomes?.findIndex((nh) => nh.id === nursingHome.id) !== -1
        );
      }) || []
    );
  }, [data?.content, nursingHome.id]);

  const usersAvailable = useMemo(() => {
    return (
      data?.content.filter((user) => {
        return (
          (user.nursingHomes === undefined)
          || (user.nursingHomes === null)
          || (user.nursingHomes?.findIndex((nh) => nh.id === nursingHome.id) === -1)
        );
      }) || []
    );
  }, [data?.content, nursingHome.id]);

  return (
    <PageWrapper>
      <Stack>
        <H2 className="mx-auto">
          {t('nursingHome.configuration.manageUsers.title')}
        </H2>
        <Row className="mt-4">
          <Col md="3">
            <SearchInput onChange={handleChangeSearch} />
          </Col>
        </Row>
        <UsersSimpleList
          title={t('nursingHome.configuration.manageUsers.owned')}
        >
          <List columnSize="300px">
            {usersOwned.map((productSpecialist) => (
              <UserSimpleCard
                key={productSpecialist.id}
                user={productSpecialist}
                type="delete"
                variant="hover"
                onClick={() =>
                  handleClickDeleteSpecialist(productSpecialist.id)
                }
              />
            ))}
          </List>
        </UsersSimpleList>

        <UsersSimpleList
          title={t('nursingHome.configuration.manageUsers.available')}
        >
          <List columnSize="300px">
            {usersAvailable.map((productSpecialist) => (
              <UserSimpleCard
                key={productSpecialist.id}
                user={productSpecialist}
                type="add"
                variant="hover"
                onClick={() => handleClickAddSpecialist(productSpecialist.id)}
              />
            ))}
          </List>
        </UsersSimpleList>
      </Stack>
    </PageWrapper>
  );
};

export default NursingHomeManageUsers;
