import { ReactComponent as AvatarIcon } from 'src/assets/avatar.svg';
import { ReactComponent as OntexIcon } from 'src/assets/ontex.svg';
import { ReactComponent as PartnersIcon } from 'src/assets/partners.svg';
import styled from 'styled-components/macro';

import { UserCreateType } from '../UserCreateTypeSelect';

const CustomPartnerIcon = styled(PartnersIcon)`
  color: ${({ theme }) => theme.palette.main.primary};
  width: 25px;
  height: 25px;
  z-index: 2;

  & + svg {
    background: ${({ theme }) => theme.palette.main.greenElectric};
  }
`;

const CustomOntexIcon = styled(OntexIcon)`
  color: ${({ theme }) => theme.palette.main.primary};

  height: 25px;
  z-index: 2;

  & + svg {
    background: ${({ theme }) => theme.palette.main.primary};
  }
`;

const CustomAvatarIcon = styled(AvatarIcon)`
  border-radius: 50%;
  color: white;
  opacity: 0.5;
  position: relative;
  z-index: 1;
  background: ${({ theme }) => theme.palette.main.primary};

`;

const Wrapper = styled.div`
  position: relative;

  ${CustomPartnerIcon}, ${CustomOntexIcon} {
    position: absolute;
    top: 55%;
    left: 55%;
  }
`;

interface Props {
  type?: UserCreateType;
}

const UserCreateIcon = ({ type }: Props) => {
  return (
    <Wrapper>
      {type === 'ontex' && <CustomOntexIcon />}
      {type === 'partner' && <CustomPartnerIcon />}
      <CustomAvatarIcon />
    </Wrapper>
  );
};

export default UserCreateIcon;
