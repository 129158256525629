import { AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import request from 'src/axios';
import {
  PartnerProspectDeliveryAddressUpdateResponse,
  PartnerProspectDeliveryAddressUpdateRequest,
} from 'src/sdk/partner-prospect';

const useUpdatePartnerProspectDeliveryAddressMutation = () => {
  const queryClient = useQueryClient();

  const updatePartnerProspectDeliveryAddressMutation = useMutation<
    AxiosResponse<PartnerProspectDeliveryAddressUpdateResponse>,
    Error,
    { partnerProspectId: number } & PartnerProspectDeliveryAddressUpdateRequest
  >(
    ({ partnerProspectId, id, ...params }) => {
      return request.put(
        `/partner-prospects/${partnerProspectId}/delivery-addresses/${id}`,
        params,
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
      },
    },
  );

  return { updatePartnerProspectDeliveryAddressMutation };
};

export default useUpdatePartnerProspectDeliveryAddressMutation;
