import { AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import request from 'src/axios';
import {
  PartnerProspectUpdateResponse,
  PartnerProspectUpdateRequest,
} from 'src/sdk/partner-prospect';

const useUpdatePartnerProspectMutation = () => {
  const queryClient = useQueryClient();

  const updatePartnerProspectMutation = useMutation<
    AxiosResponse<PartnerProspectUpdateResponse>,
    Error,
    PartnerProspectUpdateRequest
  >(
    ({ id, ...params }) => {
      return request.put(`/partner-prospects/${id}`, params);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
      },
    },
  );

  return { updatePartnerProspectMutation };
};

export default useUpdatePartnerProspectMutation;
