import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo } from 'react';
import { Form, Stack } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation, TFunction } from 'react-i18next';
import { ChevronLeft, ChevronRight } from 'src/assets/arrows';
import { EquipmentTypes } from 'src/sdk/equipment';
import { DeliveryAddress } from 'src/sdk/nursingHome';
import { SubscriptionCreationRequest } from 'src/sdk/subscription';
import { Button, ButtonColors } from 'src/uikit/Button';
import ButtonIcon from 'src/uikit/ButtonIcon';
import { Label } from 'src/uikit/Typography';
import { CardWrapper, FormButtonsWrapper } from 'src/uikit/Wrapper';
import { getStartingDate } from 'src/utils/getDate';
import styled from 'styled-components/macro';
import * as yup from 'yup';

import EquipmentCardCounter from '../components/EquipmentCardCounter';
import SubscriptionDeliveryForm, {
  CurrentEquipments,
  subscriptionDeliveryFormSchema,
} from '../components/SubscriptionDeliveryForm';
import useEstimateEquipments from '../hooks/useEstimateEquipments';

const EquipmentSection = styled.div`
  padding: 2rem 1rem;
  font-family: 'Poppins';
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const EquipmentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding: 0;
  gap: 1rem;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`;

const ButtonSubmit = styled(Button)`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  svg {
    width: 12px;
  }
`;

const subscriptionUpdateDeliverySchema = (t: TFunction) =>
  subscriptionDeliveryFormSchema(t);

type SubscriptionUpdateDeliverySchema = yup.InferType<
  ReturnType<typeof subscriptionUpdateDeliverySchema>
>;

interface Props {
  data: SubscriptionCreationRequest;
  previousData?: SubscriptionCreationRequest;
  onSuccess: (data: Partial<SubscriptionCreationRequest>) => void;
  onCancel: () => void;
  onBack: () => void;
  deliveryAddresses: DeliveryAddress[];
  currentEquipments?: CurrentEquipments;
}

const SubscriptionUpdateDelivery = ({
  data,
  onSuccess,
  onCancel,
  onBack,
  deliveryAddresses,
  currentEquipments,
}: Props) => {
  const { t } = useTranslation();

  const defaultValues: SubscriptionUpdateDeliverySchema = useMemo(() => {
    const deliveryAddressId =
      !data.deliveryAddressId || data.deliveryAddressId === -1
        ? -1
        : deliveryAddresses.find(
            (address) => address.id === data.deliveryAddressId,
          )?.id || -1;

    return {
      deliveryAddressId,
      requestedDeliveryDate: getStartingDate(),
      customAddress: data.customAddress || null,
    };
  }, [data.deliveryAddressId, data.customAddress, deliveryAddresses]);

  const methods = useForm<SubscriptionUpdateDeliverySchema>({
    resolver: yupResolver(subscriptionUpdateDeliverySchema(t)),
    mode: 'onChange',
    defaultValues,
  });

  const { formState, handleSubmit } = methods;
  const { isValid } = formState;

  const onSubmit = async (values: SubscriptionUpdateDeliverySchema) => {
    const customAddress = {
      country: values.customAddress?.country || '',
      email: values.customAddress?.email || '',
      name: values.customAddress?.name || '',
      street: values.customAddress?.street || '',
      telephone: values.customAddress?.telephone || '',
      town: values.customAddress?.town || '',
      zip: values.customAddress?.zip || '',
    };

    onSuccess({
      ...data,
      ...values,
      ...(values.deliveryAddressId === -1
        ? {
            customAddress: customAddress,
            deliveryAddressId: undefined,
          }
        : {
            deliveryAddressId: values.deliveryAddressId,
            customAddress: undefined,
          }),
    });
  };

  const { estimates } = useEstimateEquipments(data.residentEstimate);

  const equipements = useMemo(
    () => ({
      newClipon:
        (estimates?.cliponEstimate || 0) +
        0 -
        (currentEquipments?.clipons || 0),
      newGateway:
        (estimates?.gatewayEstimate || 0) +
        (data.extraGateways || 0) -
        (currentEquipments?.gateways || 0),
      newChargingStation:
        (estimates?.chargingStationEstimate || 0) +
        (data.extraChargingStations || 0) -
        (currentEquipments?.chargingStations || 0),
    }),
    [
      currentEquipments?.chargingStations,
      currentEquipments?.clipons,
      currentEquipments?.gateways,
      data.extraChargingStations,
      data.extraGateways,
      estimates?.chargingStationEstimate,
      estimates?.cliponEstimate,
      estimates?.gatewayEstimate,
    ],
  );

  return (
    <>
      <CardWrapper>
        <Label>
          {t('components.SubscriptionUpsert.SubscriptionUpdateDelivery.title')}
        </Label>
        <EquipmentSection>
          {t(
            'components.SubscriptionUpsert.SubscriptionUpdateDelivery.equipmentTitle',
          )}
          <EquipmentWrapper>
            {equipements.newClipon !== 0 && (
              <EquipmentCardCounter
                type={EquipmentTypes.CLIPON}
                value={equipements.newClipon}
              />
            )}
            {equipements.newGateway !== 0 && (
              <EquipmentCardCounter
                type={EquipmentTypes.GATEWAY}
                value={equipements.newGateway}
              />
            )}
            {equipements.newChargingStation !== 0 && (
              <EquipmentCardCounter
                type={EquipmentTypes.CHARGING}
                value={equipements.newChargingStation}
              />
            )}
          </EquipmentWrapper>
        </EquipmentSection>
        <FormProvider {...methods}>
          <Form
            id="subscription-update-delivery-form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <SubscriptionDeliveryForm
              deliveryAddresses={deliveryAddresses}
              startDate={data.startDate}
            />
          </Form>
        </FormProvider>
      </CardWrapper>
      <FormButtonsWrapper>
        <ButtonIcon prepend={<ChevronLeft />} onClick={onBack} />
        <Stack direction="horizontal">
          <Button background={ButtonColors.CANCEL} onClick={onCancel}>
            {t('components.SubscriptionUpsert.SubscriptionUpdatePlan.cancel')}
          </Button>
          <ButtonSubmit
            form="subscription-update-delivery-form"
            type="submit"
            disabled={!isValid}
            className="ml-2"
          >
            {t('components.SubscriptionUpsert.SubscriptionUpdatePlan.next')}
            <ChevronRight />
          </ButtonSubmit>
        </Stack>
      </FormButtonsWrapper>
    </>
  );
};

export default SubscriptionUpdateDelivery;
