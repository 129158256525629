import { AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import request from 'src/axios';

const useDeletePartnerProspectMutation = () => {
  const queryClient = useQueryClient();

  const deletePartnerProspectMutation = useMutation<
    AxiosResponse,
    Error,
    {
      id: number;
    }
  >(({ id }) => request.delete(`/partner-prospects/${id}`), {
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
  });

  return { deletePartnerProspectMutation };
};

export default useDeletePartnerProspectMutation;
