import { AxiosResponse } from 'axios';
import { format } from 'date-fns';
import { useCallback } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { ReactComponent as ChargingBadge } from 'src/assets/charging-badge.svg';
import Charging from 'src/assets/charging.png';
import request from 'src/axios';
import DefaultModal from 'src/components/Misc/Modal/DefaultModal';
import { DATE_FORMAT_DAY } from 'src/constants';
import { Status } from 'src/sdk/equipment';
import {
  ChargingStationDetails,
  UpdateStatusEquipmentBody,
} from 'src/sdk/provisioning';
import Separator from 'src/uikit/Separator';
import { SpinnerLoader } from 'src/uikit/SpinnerLoader';
import { H1, Label } from 'src/uikit/Typography';
import styled from 'styled-components/macro';

import StatusButton from '../../Provisioning/StatusButton';
import EquipmentStatusLabel from '../EquipmentStatusLabel';

const Wrapper = styled(Container)`
  height: inherit;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
`;

const StyledHeader = styled(Row)`
  text-align: center;
  width: 100%;
`;

const TitleCol = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled(H1)`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: ${({ theme }) => theme.palette.black[90]};
`;

const ChargingBadgeStyled = styled(ChargingBadge)`
  width: 40px;
  height: 40px;
`;

const StatusCol = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
  padding: 0 0 10px 0;
`;

const RowStatus = styled(Row)`
  margin-bottom: 60px;
`;

const DetailCol = styled(Col)`
  border-left: solid 1px ${({ theme }) => theme.palette.black[60]};
  padding-left: 2rem;
`;

const StyledLabel = styled(Label)`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1px;
  color: ${({ theme }) => theme.palette.black[90]};
  margin-right: 0.5rem;
`;

const ColLabel = styled(Col)`
  text-align: left;
`;

interface Props {
  chargingId: number;
  show: boolean;
  onClose: () => void;
  onChange?: () => void;
}

const ModalDetailCharging = ({
  chargingId,
  show,
  onClose,
  onChange,
}: Props) => {
  const { t } = useTranslation();
  const { data: details, refetch } = useQuery<
    ChargingStationDetails | undefined,
    Error
  >(['charging', { chargingId }], () =>
    chargingId >= 0
      ? request
          .get(
            `/charging-stations/${chargingId}?expand=handling-unit,nursing-home`,
          )
          .then((response) => response.data)
      : undefined,
  );

  const { mutateAsync } = useMutation<
    AxiosResponse<ChargingStationDetails>,
    Error,
    UpdateStatusEquipmentBody
  >((params) => request.put(`charging-stations/${details?.id}`, params));

  const handleSetBroken = useCallback(() => {
    mutateAsync({
      description: details?.description || '',
      broken: !(details?.broken || false),
      lost: details?.lost || false,
    }).then(() => {
      onChange?.();
      refetch();
    });
  }, [
    details?.broken,
    details?.description,
    details?.lost,
    mutateAsync,
    onChange,
    refetch,
  ]);

  const handleSetLost = useCallback(() => {
    mutateAsync({
      description: details?.description || '',
      broken: details?.broken || false,
      lost: !(details?.lost || false),
    }).then(() => {
      onChange?.();
      refetch();
    });
  }, [
    details?.broken,
    details?.description,
    details?.lost,
    mutateAsync,
    onChange,
    refetch,
  ]);

  return (
    <DefaultModal
      size="lg"
      isVisible={show}
      handleClose={onClose}
      hasStep={false}
      customHeight="588px"
      header={
        <StyledHeader>
          <Col md="1">
            <ChargingBadgeStyled />
          </Col>
          <TitleCol md="10">
            <Title>{t('components.modalDetailCharging.title')}</Title>
          </TitleCol>
        </StyledHeader>
      }
    >
      <Wrapper>
        {!details ? (
          <div className="d-flex align-items-center justify-content-center">
            <SpinnerLoader animation="grow" />
          </div>
        ) : (
          <>
            <StatusCol>
              <Image src={Charging} />
              <RowStatus>
                <EquipmentStatusLabel status={details.equipmentStatus} />
              </RowStatus>
              <StatusButton
                type={Status.BROKEN}
                enabled={details.broken}
                onClick={handleSetBroken}
              />
              <StatusButton
                type={Status.LOST}
                enabled={details.lost}
                onClick={handleSetLost}
              />
            </StatusCol>
            <DetailCol>
              <Row>
                <Col md="5">
                  <StyledLabel>
                    {t('components.modalDetailCharging.name')}:
                  </StyledLabel>
                </Col>
                <ColLabel md="7">
                  <span>{details.name}</span>
                </ColLabel>
              </Row>
              <Row>
                <Col md="5">
                  <StyledLabel>
                    {t('components.modalDetailCharging.id')}:
                  </StyledLabel>
                </Col>
                <ColLabel md="7">
                  <span>{details.id}</span>
                </ColLabel>
              </Row>
              <Separator invisible />
              <Row>
                <Col md="5">
                  <StyledLabel>
                    {t('components.modalDetailCharging.serial')}:
                  </StyledLabel>
                </Col>
                <ColLabel md="7">
                  <span>{details.serialNumber}</span>
                </ColLabel>
              </Row>
              <Row>
                <Col md="5">
                  <StyledLabel>
                    {t('components.modalDetailCharging.ssccNumber')}:
                  </StyledLabel>
                </Col>
                <ColLabel md="7">
                  <span>{details.handlingUnit?.ssccNumber}</span>
                </ColLabel>
              </Row>
              <Row>
                <Col md="5">
                  <StyledLabel>
                    {t('components.modalDetailCharging.lot')}:
                  </StyledLabel>
                </Col>
                <ColLabel md="7">
                  <span>{details.handlingUnit?.batchNumber}</span>
                </ColLabel>
              </Row>
              <Row>
                <Col md="5">
                  <StyledLabel>
                    {t('components.modalDetailCharging.prodDate')}:
                  </StyledLabel>
                </Col>
                <ColLabel md="7">
                  <span>
                    {details.handlingUnit?.batchProductionDate &&
                      format(
                        new Date(details.handlingUnit?.batchProductionDate),
                        DATE_FORMAT_DAY,
                      )}
                  </span>
                </ColLabel>
              </Row>
              <Row>
                <Col md="5">
                  <StyledLabel>
                    {t('components.modalDetailCharging.description')}:
                  </StyledLabel>
                </Col>
                <ColLabel md="7">
                  <span>{details.description}</span>
                </ColLabel>
              </Row>
              <Separator invisible />
              <Row>
                <Col md="5">
                  <StyledLabel>
                    {t('components.modalDetailCharging.nursing')}:
                  </StyledLabel>
                </Col>
                <ColLabel md="7">
                  <span>{details.nursingHome?.name}</span>
                </ColLabel>
              </Row>
            </DetailCol>
          </>
        )}
      </Wrapper>
    </DefaultModal>
  );
};

export default ModalDetailCharging;
