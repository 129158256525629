import { AxiosResponse } from 'axios';
import { useMutation } from 'react-query';
import request from 'src/axios';
import {
  PartnerCreationDataRequest,
  PartnerCreationDataResponse,
} from 'src/sdk/partner';

/**
 * Generates a mutation hook for checking partner data.
 *
 * @return {Object} An object containing the checkPartnerMutation hook.
 */
const useCheckPartnerMutation = () => {
  const checkPartnerMutation = useMutation<
    AxiosResponse<PartnerCreationDataResponse>,
    Error,
    PartnerCreationDataRequest
  >((params) => request.post('/partners/creation-data', params));

  return { checkPartnerMutation };
  
};

export default useCheckPartnerMutation;
