import { yupResolver } from '@hookform/resolvers/yup';
import { setHours, setMinutes } from 'date-fns';
import { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DATE_TIME_LOCALE } from 'src/constants';
import { Language } from 'src/sdk/common';
import DateSelector from 'src/uikit/DateSelector';
import { Form } from 'src/uikit/Form';
import FormTextInput from 'src/uikit/Form/TextInput';
import { Label, Light } from 'src/uikit/Typography';
import { CardWrapper } from 'src/uikit/Wrapper';
import styled, {css} from 'styled-components/macro';
import * as yup from 'yup';
import { H4 } from 'src/uikit/Typography';
import { Button } from 'src/uikit/Button';




export const defaultNighttimeEnd = setHours(setMinutes(new Date(), 0), 7);
export const defaultNighttimeStart = setHours(setMinutes(new Date(), 0), 19);

const StyledLabel = styled(Label)<{ $disabled?: boolean }>`
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  font-weight: 400;
  ${({ $disabled }) =>
    $disabled &&
    css`
      color: ${({ theme }) => theme.palette.black[70]};
    `}
`;

export type ConfigurationBillingType = 'resident' | 'usage';

const billingTimeFormSchema = yup.object({
  mode : yup.string(),
  nighttimeStart: yup.date().when('specific', {
    is: true,
    then: yup.date().optional(),
  }),
  nighttimeEnd: yup.date().when('specific', {
    is: true,
    then: yup.date().optional(),
  }),
  tolerance: yup.number().when('specific', {
    is: true,
    then: yup.number().optional(),
  }),
});


export type BillingTimeFormSchema = yup.InferType<
  typeof billingTimeFormSchema
>;


interface Props {
    defaultValues: BillingTimeFormSchema;
    onSubmit: (values: BillingTimeFormSchema) => void;
  }

const BillingTimeForm = ({
  defaultValues,
  onSubmit,
}: Props) => {


  const { t, i18n } = useTranslation();



  const methods = useForm<BillingTimeFormSchema>({
    resolver: yupResolver(billingTimeFormSchema),
    mode: 'onChange',
    defaultValues,
  });

  const {
    watch,
    setValue,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = methods;

  const specific = watch('specific');

  useEffect(() => {            
      setValue('nighttimeStart', defaultValues.nighttimeStart);
      setValue('nighttimeEnd', defaultValues.nighttimeEnd);
    
  }, [setValue, specific]);


  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return (
    <CardWrapper>
      <H4 className="ml-2 dashboard-title-section-timeBilling">
        {t('nursingHome.configuration.billings.form.labels.title')}
      </H4>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col>
              <Row className="mt-4">
                <Col md="4">
                  <StyledLabel className="mt-2" 
                  //$disabled={defaultValues?.mode === 'FULL_DAYS' ? true : false}
                  >
                    {t(
                      'nursingHome.configuration.billings.form.labels.nighttimeEnd',
                    )}
                  </StyledLabel>
                </Col>
                <Col md="5">
                  <Controller
                    name="nighttimeEnd"
                    render={({ value, onChange, ref }) => (
                      <DateSelector
                        value={new Date(value)}
                        dateFormat={DATE_TIME_LOCALE[i18n.language as Language]}
                        onChange={(value: Date) => onChange(value)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeCaption="Time"
                       // disabled={defaultValues?.mode === 'FULL_DAYS' ? true : false}
                      />
                    )}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md="4">
                  <StyledLabel className="mt-2"
                   //$disabled={defaultValues?.mode === 'FULL_DAYS' ? true : false}
                   >
                    {t(
                      'nursingHome.configuration.billings.form.labels.nighttimeStart',
                    )}
                  </StyledLabel>
                </Col>
                <Col md="5">
                  <Controller
                    name="nighttimeStart"
                    render={({ value, onChange, ref }) => (
                      <DateSelector
                        value={new Date(value)}
                        onChange={(value: Date) => onChange(value)}
                        dateFormat={DATE_TIME_LOCALE[i18n.language as Language]}
                        showTimeSelect
                        showTimeSelectOnly
                        timeCaption="Time"
                        //disabled={defaultValues?.mode === 'FULL_DAYS' ? true : false}
                      />
                    )}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md="4">
                  <StyledLabel className="mt-2" 
                  //$disabled={defaultValues?.mode === 'FULL_DAYS' ? true : false}
                  >
                    {t(
                      'nursingHome.configuration.billings.form.labels.tolerance',
                    )}
                  </StyledLabel>
                </Col>
                <Col md="5">
                  <FormTextInput
                    type="number"
                    name="tolerance"
                    width="100%"
                    //disabled={defaultValues?.mode === 'FULL_DAYS' ? true : false}
                  />
                </Col>
                <Col className="p-2">
                  <Light>
                    {t('nursingHome.configuration.billings.form.labels.minutes')}
                  </Light>
                </Col>
              </Row>
              <Row xs="auto" className="justify-content-end p-2">
                <Col>
                  <Button type="submit" disabled={!isDirty}>
                    {t('nursingHome.configuration.billings.form.submit')}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </FormProvider>
    </CardWrapper>
  );
};

export default BillingTimeForm;
