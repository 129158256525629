import { SVGProps } from 'react';
import { Button, ButtonProps } from 'src/uikit/Button';
import styled from 'styled-components/macro';

const StyledButton = styled(Button)`
  min-height: 32px;
  max-height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

interface Props extends ButtonProps {
  prepend?: SVGProps<SVGSVGElement>;
  append?: SVGProps<SVGSVGElement>;
  label?: string;
  handleClick?: () => void;
}

const ButtonIcon = ({
  prepend,
  append,
  label,
  handleClick,
  ...others
}: Props) => {
  return (
    <StyledButton onClick={handleClick} {...others}>
      {!!prepend && prepend}
      <span>{label}</span>
      {!!append && append}
    </StyledButton>
  );
};

export default ButtonIcon;
