import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ReactComponent as PlusBadge } from 'src/assets/plus-badge.svg';
import ListPaginated from 'src/components/Misc/ListPaginated';
import { DEFAULT_PAGE_SIZE } from 'src/components/Misc/Pagination';
import Routes from 'src/router/Routes';
import { Button } from 'src/uikit/Button';
import { List } from 'src/uikit/Layout/List';
import SearchInput from 'src/uikit/SearchInput';
import styled from 'styled-components/macro';

import useFetchNursingHomesQuery from '../hooks/useFetchNursingHomesQuery';
import NursingHomeRow from './NursingHomeRow';

const PAGE_SIZE = DEFAULT_PAGE_SIZE;

const Wrapper = styled.div`
  display: grid;
  gap: 2rem;

  padding: 2rem 1rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 1rem 0rem;
  }
`;

const Header = styled(Row)`
  justify-content: space-between;
  gap: 0.5rem;
`;

const ListNursingHome = () => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState('');
  const [page, setPage] = useState(0);

  const { data, isLoading } = useFetchNursingHomesQuery({
    page,
    size: PAGE_SIZE,
    nameOrSoldtoPart: searchValue,
    expand: [
      'clipon-metrics',
      'gateway-metrics',
      'charging-station-metrics',
      'subscription',
      'shipping-requests',
    ],
    sort: {
      direction: 'asc',
      label: 'id',
    },
  });

  return (
    <Wrapper>
      <Header xs="auto">
        <Col md="2">
          <SearchInput
            onChange={(searchValue) => setSearchValue(searchValue)}
          />
        </Col>
        <Col>
          <Button as={Link} to={Routes.NURSING_CREATION}>
            <PlusBadge className="mr-2" />
            {t('components.nursingHomeList.add')}
          </Button>
        </Col>
      </Header>
      <ListPaginated
        isLoading={isLoading}
        page={page}
        setPage={setPage}
        data={data}
      >
        <List>
          {data?.content.map((nursingHome) => (
            <NursingHomeRow key={nursingHome.id} nursingHome={nursingHome} />
          ))}
        </List>
      </ListPaginated>
    </Wrapper>
  );
};

export default ListNursingHome;
