import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { ReactComponent as CharactersImg } from 'src/assets/characters.svg';
import styled from 'styled-components/macro';

const AsideFrame = styled(Container)`
  padding: 0;
`;

const BlockTop = styled(Row)`
  background-color: ${({ theme }) => theme.palette.main.blue};
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: 0;
`;

const BlockBottom = styled(Row)`
  background-color: ${({ theme }) => theme.palette.main.primary};
  width: 100%;
  height: 30vh;
  margin: 0;
`;

const AuthAsideFrame = () => {
  return (
    <AsideFrame>
      <BlockTop>
        <CharactersImg />
      </BlockTop>
      <BlockBottom />
    </AsideFrame>
  );
};
export default AuthAsideFrame;
