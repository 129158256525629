import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { Row } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { useAuth } from 'src/contexts/Auth';
import useFormatErrorApi from 'src/hooks/useFormatErrorApi';
import useToast from 'src/hooks/useToast';
import Routes from 'src/router/Routes';
import { Button } from 'src/uikit/Button';
import ErrorMessage from 'src/uikit/ErrorMessage';
import FormPasswordInput from 'src/uikit/Form/PasswordInput';
import { passwordSchema } from 'src/uikit/Form/PasswordInput/PasswordMatcher';
import { H4, P } from 'src/uikit/Typography';
import styled from 'styled-components/macro';
import * as yup from 'yup';

import AuthForm from '../AuthForm';
import BackToLogin from '../BackToLogin';
import useResetPasswordMutation from '../hooks/useResetPasswordMutation';

const ResetPasswordWelcome = styled(H4)`
  font-size: 1rem;
`;

const ResetPasswordSubtitle = styled(P)``;

const InputBlock = styled.div`
  width: 100%;
  padding: 0;
`;

const ForgotBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface Props {
  userId: string;
  token: string;
  onReset: () => void;
}

const resetPasswordSchema = (t: TFunction) =>
  yup.object({
    newPassword: passwordSchema(t).required(
      t('components.Auth.ResetPasswordForm.validations.required'),
    ),
    confirmPassword: yup
      .string()
      .required(t('components.Auth.ResetPasswordForm.validations.required'))
      .oneOf(
        [yup.ref('newPassword'), null],
        t('components.Auth.ResetPasswordForm.validations.passwordMatch'),
      ),
  });

type ResetPasswordSchema = yup.InferType<
  ReturnType<typeof resetPasswordSchema>
>;

const ResetPasswordForm = ({ userId, token, onReset }: Props) => {
  const { t } = useTranslation();
  const { authUser } = useAuth();

  const [error, setError] = useState('');
  const { toastSuccess } = useToast();
  const { formatErrorApi } = useFormatErrorApi();

  const methods = useForm<ResetPasswordSchema>({
    resolver: yupResolver(resetPasswordSchema(t)),
    mode: 'onChange',
  });

  const { handleSubmit, formState } = methods;

  const { mutateAsync, isLoading } = useResetPasswordMutation();

  const onSubmit = handleSubmit(async (data: ResetPasswordSchema) => {
    setError('');
    try {
      await mutateAsync({
        body: {
          userId: Number(userId),
          token,
          value: data.newPassword,
        },
      });
      toastSuccess(t('components.Auth.ResetPasswordForm.success'));
      onReset();
    } catch (err) {
      const message = formatErrorApi(err);
      setError(message);
    }
  });

  if (authUser) {
    return <Redirect to={Routes.LOGIN} />;
  }

  return (
    <FormProvider {...methods}>
      <AuthForm onSubmit={onSubmit}>
        <ResetPasswordWelcome>
          {t('components.Auth.ResetPasswordForm.title')}
        </ResetPasswordWelcome>
        <ResetPasswordSubtitle>
          {t('components.Auth.ResetPasswordForm.subtitle')}
        </ResetPasswordSubtitle>
        <Row>
          <InputBlock>
            <FormPasswordInput
              name="newPassword"
              placeholder={t('components.Auth.ResetPasswordForm.newPassword')}
              width="100%"
              showRules
            />
          </InputBlock>
          <InputBlock>
            <FormPasswordInput
              name="confirmPassword"
              placeholder={t(
                'components.Auth.ResetPasswordForm.confirmPassword',
              )}
              width="100%"
            />
          </InputBlock>
        </Row>
        <ForgotBlock>
          <ErrorMessage>{error}</ErrorMessage>
          <Button type="submit" disabled={!formState.isValid}>
            {isLoading ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              t('components.Auth.ResetPasswordForm.confirm')
            )}
          </Button>
        </ForgotBlock>
        <BackToLogin />
      </AuthForm>
    </FormProvider>
  );
};

export default ResetPasswordForm;
