import { useCallback } from 'react';
import { useQuery } from 'react-query';
import request from 'src/axios';
import { GetSubscriptionsResponse } from 'src/sdk/subscription';

const useFetchSubscriptionsQuery = (id: number, page: number, size: number) => {
  const fetchSubscriptions = useCallback(
    () =>
      request
        .get(`/subscriptions`, {
          params: {
            'nursing-home-id': id,
            page: page,
            size: size,
            sort: 'createdDate,DESC',
          },
        })
        .then((response) => response.data),
    [id, page, size],
  );

  const { data, isLoading, refetch, error } = useQuery<
    GetSubscriptionsResponse,
    Error
  >(`subscriptionList${id}`, fetchSubscriptions, {
    enabled: id > 0,
  });

  return {
    subscriptions: data,
    isLoading,
    refetch,
    error,
  };
};

export default useFetchSubscriptionsQuery;
