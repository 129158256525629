import { useMemo, useState } from 'react';
import { Col, Row, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ListPaginated from 'src/components/Misc/ListPaginated';
import useAddProductPartnerMutation from 'src/components/Products/hooks/useAddProductPartnerMutation';
import useDeleteProductPartnerMutation from 'src/components/Products/hooks/useDeleteProductPartnerMutation';
import useFetchProductsQuery from 'src/components/Products/hooks/useFetchProductsQuery';
import ProductFilterAsyncSelect from 'src/components/Products/ProductFilterAsyncSelect';
import ProductCard from 'src/components/Products/ProductsList/ProductCard';
import useFormatErrorApi from 'src/hooks/useFormatErrorApi';
import useToast from 'src/hooks/useToast';
import { Partner } from 'src/sdk/partner';
import { Badge } from 'src/uikit/Badge';
import { List } from 'src/uikit/Layout/List';
import { H3, Label } from 'src/uikit/Typography';
import { PageWrapper } from 'src/uikit/Wrapper';
import styled from 'styled-components/macro';

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  margin-top: 10px;
`;

export const StyledLastUpdate = styled.div`
  font-size: 0.9rem;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  color: ${({ theme }) => theme.palette.main.curiousBlue};
`;

export const StyledDate = styled.div`
  font-family: 'Roboto';
  font-size: 15px;
  font-weight: 400;
  text-align: center;
  color: ${({ theme }) => theme.palette.black[90]};
`;

export const ErrorMessage = styled.p`
  font-weight: 600;
  text-align: center;
  color: ${({ theme }) => theme.palette.main.red};
  font-size: 10px;
  padding: 0 4rem;
`;

type Props = {
  partner: Partner;
};

const PAGE_SIZE = 100;

const PartnerProducts = ({ partner }: Props) => {
  const { t } = useTranslation();

  const [brandFilter, setBrandFilter] = useState<string | null>();
  const [colorFilter, setColorFilter] = useState<string | null>();
  const [dropFilter, setDropFilter] = useState<string | null>();
  const [sizeFilter, setSizeFilter] = useState<string | null>();

  const { toastError, toastSuccess } = useToast();
  const { formatErrorApi } = useFormatErrorApi();

  const [page, setPage] = useState(0);

  const { data: dataPartner } = useFetchProductsQuery({
    page: 0,
    size: 5000,
    ...(brandFilter && { brand: brandFilter }),
    ...(colorFilter && { color: colorFilter }),
    ...(dropFilter && { drops: dropFilter }),
    ...(sizeFilter && { productSize: sizeFilter }),
    partnerId: partner.id,
  });

  const { mutateAsync: addMutateAsync } = useAddProductPartnerMutation();
  const { mutateAsync: delMutateAsync } = useDeleteProductPartnerMutation();

  const { data: dataAll, isLoading: isLoadingAll } = useFetchProductsQuery({
    page,
    size: PAGE_SIZE,
    ...(brandFilter && { brand: brandFilter }),
    ...(colorFilter && { color: colorFilter }),
    ...(dropFilter && { drops: dropFilter }),
    ...(sizeFilter && { productSize: sizeFilter }),
  });

  const onClickAddProduct = async (productId: number) => {
    try {
      await addMutateAsync({ id: partner.id, productId });
      toastSuccess(t('components.partners.details.products.successAdd'));
    } catch (err) {
      const message = formatErrorApi(err);
      toastError(message);
    }
  };

  const onClickDeleteProduct = async (productId: number) => {
    try {
      await delMutateAsync({ id: partner.id, productId });
      toastSuccess(t('components.partners.details.products.successDelete'));
    } catch (err) {
      const message = formatErrorApi(err);
      toastError(message);
    }
  };

  const allProducts = useMemo(() => {
    return (
      dataAll?.content.map((product) => {
        const isAdded =
          dataPartner?.content.findIndex(
            (_product) => product.id === _product.id,
          ) !== -1;

        return {
          ...product,
          isAdded,
        };
      }) || []
    );
  }, [dataAll, dataPartner]);

  return (
    <PageWrapper>
      <ContentWrapper>
        <Stack direction="horizontal" gap={2}>
          <H3>{t('components.partners.details.products.label')}</H3>
          <Badge bg="primary" className="ml-2">
            {dataAll?.totalElements}
          </Badge>
        </Stack>

        <Row className="mt-2">
          <Col xs="12" sm="6" md="3" xl="auto">
            <ProductFilterAsyncSelect
              type="brand"
              value={brandFilter}
              onChange={(value) => setBrandFilter(value)}
              placeholder={t(
                'components.partners.details.products.filter.brand',
              )}
              partnerId={partner.id}
              color={colorFilter || null}
              drops={dropFilter || null}
              productSize={sizeFilter || null}
            />
          </Col>
          <Col xs="12" sm="6" md="3" xl="auto">
            <ProductFilterAsyncSelect
              type="color"
              value={colorFilter}
              onChange={(value) => setColorFilter(value)}
              placeholder={t(
                'components.partners.details.products.filter.color',
              )}
              partnerId={partner.id}
              drops={dropFilter || null}
              productSize={sizeFilter || null}
              brand={brandFilter || null}
            />
          </Col>
          <Col xs="12" sm="6" md="3" xl="auto">
            <ProductFilterAsyncSelect
              type="size"
              value={sizeFilter}
              onChange={(value) => setSizeFilter(value)}
              placeholder={t(
                'components.partners.details.products.filter.size',
              )}
              partnerId={partner.id}
              color={colorFilter || null}
              drops={dropFilter || null}
              brand={brandFilter || null}
            />
          </Col>
          <Col xs="12" sm="6" md="3" xl="auto">
            <ProductFilterAsyncSelect
              type="drop"
              value={dropFilter}
              onChange={(value) => setDropFilter(value)}
              placeholder={t(
                'components.partners.details.products.filter.drop',
              )}
              partnerId={partner.id}
              color={colorFilter || null}
              productSize={sizeFilter || null}
              brand={brandFilter || null}
            />
          </Col>
        </Row>

        <Stack
          direction="horizontal"
          className=" mt-3 align-items-center"
          gap={2}
        >
          <Label>{t('components.partners.details.products.assigned')}</Label>
          <Badge bg="primary" className="ml-2">
            {dataPartner?.content.length} / {dataAll?.totalElements}
          </Badge>
        </Stack>

        <List columnSize="200px">
          {dataPartner?.content.map((product) => (
            <ProductCard
              variant="hover"
              key={product.id}
              product={product}
              type="delete"
              onClick={() => {
                onClickDeleteProduct(product.id || 0);
              }}
            />
          ))}
        </List>

        <Label className="mt-3">
          {t('components.partners.details.products.available')}
        </Label>
        <ListPaginated
          page={page}
          setPage={setPage}
          isLoading={isLoadingAll}
          data={dataAll}
        >
          <List columnSize="200px">
            {allProducts.map((product) => (
              <ProductCard
                variant="hover"
                key={product.id}
                product={product}
                type="add"
                disabled={product.isAdded}
                onClick={() => {
                  onClickAddProduct(product.id || 0);
                }}
              />
            ))}
          </List>
        </ListPaginated>
      </ContentWrapper>
    </PageWrapper>
  );
};

export default PartnerProducts;
