import { forwardRef, useState } from 'react';
import { OptionsList } from 'react-select-async-paginate';
import request from 'src/axios';
import {
  GetNursingHomeResponse,
  NursingHomeResponse,
} from 'src/sdk/nursingHome';
import AsyncSelectInput, { AsyncOptionType } from 'src/uikit/AsyncSelectInput';

const size = 9;

interface Props {
  value?: NursingHomeResponse;
  onChange: (value?: NursingHomeResponse) => void;
  placeholder: string;
}

const NursingHomeAsyncSelect = forwardRef<HTMLSelectElement, Props>(
  ({ value, onChange, ...rest }, ref) => {
    const [listOptions, setListOptions] = useState<
      AsyncOptionType<NursingHomeResponse>[]
    >([]);

    const loadOptions = async (
      search: string,
      prevOptions: OptionsList<AsyncOptionType<NursingHomeResponse>>,
    ) => {
      const { data } = await request.get<GetNursingHomeResponse>(
        '/nursing-homes',
        {
          params: {
            page: Math.ceil(prevOptions.length / size),
            size,
            'name-part': search,
          },
        },
      );

      const options: AsyncOptionType<NursingHomeResponse>[] = data?.content.map(
        (nursinghome: NursingHomeResponse) => ({
          value: nursinghome,
          label: `${nursinghome.name}`,
        }),
      );

      const hasMore = (data.pageable?.pageNumber || 0) < data.totalPages - 1;
      const slicedOptions = [
        ...prevOptions,
        ...options,
      ] as AsyncOptionType<NursingHomeResponse>[];

      setListOptions(slicedOptions);

      return {
        options,
        hasMore,
      };
    };

    return (
      <AsyncSelectInput
        ref={ref}
        variant="rounded"
        loadOptions={loadOptions}
        value={
          listOptions.find(
            (e) => e.value === value,
          ) as AsyncOptionType<NursingHomeResponse>
        }
        onChange={(
          val: { value: NursingHomeResponse; label: string } | undefined,
        ) => onChange(val?.value || undefined)}
        {...rest}
      />
    );
  },
);

export default NursingHomeAsyncSelect;
