import { EquipmentTypes } from 'src/sdk/equipment';

import { Gender, GetResponse } from './common';

export enum OrderStatus {
  RECEIVED = 'RECEIVED',
  DONE = 'DONE',
}

export interface Orders {
  id: number;
  poNumber: string;
  sapCreationDate: string;
  status: OrderStatus;
  vendor: {
    code: string;
    name: string;
  };
  items: EquipmentOrder[];
  user: {
    id: number;
    firstName: string;
    lastName: string;
    gender: Gender;
    profile: string;
  };
}

export interface GetOrdersResponse extends GetResponse<Orders> {}

export interface EquipmentOrder {
  id: number;
  equipmentType: EquipmentTypes;
  description: string;
  quantity: number;
  plannedDeliveryDate: string;
  cliponFirstDevEui: string;
  cliponLastDevEui: string;
  status: OrderStatus;
}

export interface VendorCode {
  id: number;
  code: string;
  name: string;
}

export interface MaterialNumber {
  id: number;
  code: string;
  equipmentType: 'CLIPON' | 'GATEWAY' | 'CHARGING_STATION';
}

export interface CreateMaterialOrder {
  materialNumber: string;
  description: string;
  quantity: number;
  plannedDeliveryDate: string;
}

export interface CreatePurchaseOrder {
  poNumber: string;
  vendorCode: string;
  date: string;
  items: Array<CreateMaterialOrder>;
}
