import { useQuery } from 'react-query';
import request from 'src/axios';
import {
  GetResponseShippingRequest,
  ShippingRequestExtra,
  ShippingRequestResponse,
  ShippingRequestExpand,
} from 'src/sdk/shippingRequest';

export interface ShippingRequestSortField {
  label: 'id' | 'createdDate';
  direction: 'asc' | 'desc';
}

export type FetchShippingRequestDataParams = {
  page: number;
  size: number;
  nhId?: string;
  inProgress?: boolean;
  search?: string;
  expand?: ShippingRequestExpand[];
  extra?: ShippingRequestExtra[];
  sortField?: ShippingRequestSortField;
  status?: ShippingRequestResponse['status'];
  source?: ShippingRequestResponse['source'];
  nursingHome?: ShippingRequestResponse['nursingHome'];
};

const fetchShippingRequestData = async ({
  page,
  size,
  nhId,
  inProgress,
  search,
  expand,
  extra,
  sortField,
  status,
  source,
  nursingHome,
}: FetchShippingRequestDataParams): Promise<GetResponseShippingRequest> => {
  const { data } = await request.get('/shipping-requests', {
    params: {
      page,
      size,
      ...(nhId && { 'nursing-home-id': nhId }),
      ...(inProgress && { 'in-progress': inProgress }),
      ...(search && { 'id-part': search }),
      ...(status && { status: status }),
      ...(source && { source: source }),
      ...(nursingHome && { 'nursing-home-id': nursingHome.id }),
      ...(expand && { expand: expand.join(',') }),
      ...(extra && { extra: extra.join(',') }),
      ...(sortField && { sort: `${sortField.label},${sortField.direction}` }),
    },
  });
  return data;
};

const useFetchShipingRequestQuery = (
  params: FetchShippingRequestDataParams,
) => {
  return useQuery<GetResponseShippingRequest, Error>(
    [`shipping-requests/${params.nhId}`, { ...params }],
    () => fetchShippingRequestData(params),
    {
      enabled: params.nhId !== '-1',
      keepPreviousData: true,
    },
  );
};

export default useFetchShipingRequestQuery;
