import { useEffect } from 'react';
import { TFunction } from 'react-i18next';
import { ReactComponent as Charging } from 'src/assets/charging-stations.svg';
import { ReactComponent as Clipon } from 'src/assets/clipons.svg';
import { ReactComponent as Gateway } from 'src/assets/gateways.svg';
import CounterEquipmentInput from 'src/uikit/Form/CounterEquipmentInput/extra';
import palette from 'src/uikit/theme/palette';
import styled from 'styled-components/macro';
import * as yup from 'yup';

import useEstimateEquipments from '../hooks/useEstimateEquipments';
import { useSubscriptionUpsert } from '../SubscriptionContext';
export interface CurrentEquipments {
  clipons: number;
  gateways: number;
  chargingStations: number;
}

const Wrapper = styled.div`
  margin-top: 1rem;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-auto-rows: 1fr;
  justify-content: space-between;
  padding: 0;
  gap: 1rem;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`;

const CliponBadge = styled(Clipon)`
  height: 24px;
  width: 24px;
  color: ${({ theme }) => theme.palette.main.white};
  border-radius: 100%;
  background: ${({ theme }) => theme.palette.main.primary};
  width: 40px;
  height: 40px;
  padding: 8px;
`;

const ChargingBadge = styled(Charging)`
  height: 24px;
  width: 24px;
  color: ${({ theme }) => theme.palette.main.white};
  border-radius: 100%;
  background: ${({ theme }) => theme.palette.main.orange};
  width: 40px;
  height: 40px;
  padding: 8px;
`;

const GatewayBadge = styled(Gateway)`
  height: 24px;
  width: 24px;
  color: ${({ theme }) => theme.palette.main.white};
  border-radius: 100%;
  background: ${({ theme }) => theme.palette.main.purple};
  width: 40px;
  height: 40px;
  padding: 8px;
`;

interface Props {
  residentEstimate: number;
  current?: CurrentEquipments;
}

export const subscriptionEquipmentFormSchema = (t: TFunction) =>
  yup.object({
    extraClipons: yup.number(),
    extraGateways: yup.number(),
    extraChargingStations: yup.number(),
  });

export type SubscriptionEquipmentFormSchema = yup.InferType<
  ReturnType<typeof subscriptionEquipmentFormSchema>
>;

const SubscriptionEquipmentForm = ({ residentEstimate, current }: Props) => {
  const { estimates, refetch } = useEstimateEquipments(residentEstimate);

  const { currentConfiguration: configuration } = useSubscriptionUpsert();

  useEffect(() => {
    if (residentEstimate > 0) {
      refetch();
    }
  }, [refetch, residentEstimate]);

  return (
    <Wrapper>
      <CounterEquipmentInput
        name="extraClipons"
        title="Clip-on"
        badge={<CliponBadge />}
        disabled
        initial={estimates?.cliponEstimate || 0}
        current={current?.clipons}
        max={0}
      />
      <CounterEquipmentInput
        name="extraGateways"
        title="Gateway"
        badge={<GatewayBadge />}
        mainColor={palette.main.purple}
        secondaryColor={palette.main.lightPurple}
        initial={estimates?.gatewayEstimate || 0}
        current={current?.gateways}
        max={configuration?.maximumExtraGateways}
        disabled={configuration?.type === 'DEMO'}
      />
      {/* Charging stations */}
      <CounterEquipmentInput
        name="extraChargingStations"
        title="Charging"
        badge={<ChargingBadge />}
        mainColor={palette.main.yellow}
        secondaryColor={palette.main.lightYellow}
        initial={estimates?.chargingStationEstimate || 0}
        current={current?.chargingStations}
        max={configuration?.maximumExtraChargingStations}
        disabled={configuration?.type === 'DEMO'}
      />
    </Wrapper>
  );
};

export default SubscriptionEquipmentForm;
