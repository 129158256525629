import Navbar from 'react-bootstrap/Navbar';
import { ReactComponent as Logo } from 'src/assets/orizon-logo.svg';
import AuthMenu from 'src/components/Header/AuthMenu';
import LanguageSwitcher from 'src/components/Header/LanguageSwitcher';
import { useAuth } from 'src/contexts/Auth';
import styled from 'styled-components/macro';

import HeaderPartner from './HeaderPartner';

const NavbarContainer = styled(Navbar)`
  width: 100vw;
  height: 70px;
  padding: 1.2rem;
  display: flex;
  justify-content: space-between;
  z-index: 99;
  box-shadow: ${({ theme }) => theme.shadows.xs};

  @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    padding: 1rem;
  }
`;

const StyledLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  // padding: 2rem;
  width: calc(250px - 2.4rem);
  height: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 0rem;
    width: calc(125px - 2.4rem);
  }

  svg {
    height: 100%;
    width: 100%;
  }
`;

const LeftContent = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

type NavigationProps = {
  isAuthLayout?: boolean;
};

const Navigation = ({ isAuthLayout }: NavigationProps) => {
  const { authUser } = useAuth();

  return (
    <NavbarContainer>
      <LeftContent>
        <StyledLogoWrapper>
          <Logo />
        </StyledLogoWrapper>
        <LanguageSwitcher />
      </LeftContent>
      {!isAuthLayout && (
        <>
          {authUser?.partnerUser && <HeaderPartner />}
          {authUser && <AuthMenu user={authUser} />}
        </>
      )}
    </NavbarContainer>
  );
};

export default Navigation;
