import { useCallback, useMemo, useState } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import useFormatErrorApi from 'src/hooks/useFormatErrorApi';
import useToast from 'src/hooks/useToast';
import { PartnerProspect } from 'src/sdk/partner-prospect';
import { UserResponse } from 'src/sdk/users';
import { List } from 'src/uikit/Layout/List';
import SearchInput from 'src/uikit/SearchInput';
import { H4 } from 'src/uikit/Typography';
import { Stack } from 'src/uikit/Wrapper';
import styled from 'styled-components/macro';

import useCreatePartnerProspectSpecialistMutation from '../../../PartnerProspects/hooks/useCreatePartnerProspectSpecialistMutation';
import useDeletePartnerProspectSpecialistMutation from '../../../PartnerProspects/hooks/useDeletePartnerProspectSpecialistMutation';
import useFetchPartnerProspectsQuery from '../../../PartnerProspects/hooks/useFetchPartnerProspectsQuery';
import UserPartnerProspectCard from './UserProspectCard';

const Wrapper = styled(Container)`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  padding: 1rem;
  height: 100%;
`;

const SearchColumn = styled(Col)`
  display: flex;
  justify-content: flex-start;
`;

const Label = styled.label`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
`;

const Emphased = styled.span`
  font-style: bold;
  color: ${({ theme }) => theme.palette.main.primary};
`;

const PAGE_SIZE = 1000;

interface Props {
  user: UserResponse;
}

const UserProspect = ({ user }: Props) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const { toastError, toastSuccess } = useToast();
  const { formatErrorApi } = useFormatErrorApi();

  const [searchFilter, setSearchFilter] = useState<string>('');

  const handleChangeSearch = useCallback((value: string) => {
    setPage(0);
    setSearchFilter(value);
  }, []);

  const { mutateAsync: addPartnerProspectToUserMutation } =
    useCreatePartnerProspectSpecialistMutation();
  const { mutateAsync: deletePartnerProspectToUserMutation } =
    useDeletePartnerProspectSpecialistMutation();

  const handleAddNursingHone = async (partnerProspect: PartnerProspect) => {
    try {
      await addPartnerProspectToUserMutation({
        userId: user.id,
        id: partnerProspect.id,
      });
      toastSuccess(
        t('components.Users.UserDetails.UserPartnerProspects.success'),
      );
    } catch (err) {
      const message = formatErrorApi(err);
      toastError(message);
    }
  };

  const handleDeleteNursingHone = async (partnerProspect: PartnerProspect) => {
    try {
      await deletePartnerProspectToUserMutation({
        userId: user.id,
        id: partnerProspect.id,
      });
      toastSuccess(
        t('components.Users.UserDetails.UserPartnerProspects.success'),
      );
    } catch (err) {
      const message = formatErrorApi(err);
      toastError(message);
    }
  };

  const { data } = useFetchPartnerProspectsQuery(
    page,
    PAGE_SIZE,
    [],
    { direction: 'asc', label: 'name' },
    searchFilter,
  );

  const availableList = useMemo(() => {
    return data?.content.filter((partnerProspect) => {
      return (
        !user.partnerProspects ||
        user.partnerProspects?.findIndex(
          (userPartnerProspect) =>
            userPartnerProspect.id === partnerProspect.id,
        ) === -1
      );
    });
  }, [data?.content, user.partnerProspects]);

  const ownedList = useMemo(() => {
    if (!user.partnerProspects) {
      return [];
    }

    return data?.content.filter((partnerProspect) => {
      return (
        user.partnerProspects?.findIndex(
          (userPartnerProspect) =>
            userPartnerProspect.id === partnerProspect.id,
        ) !== -1
      );
    });
  }, [data?.content, user.partnerProspects]);

  return (
    <Wrapper fluid>
      <Row className="justify-content-between">
        <Label>
          <Trans
            i18nKey={'components.Users.UserDetails.UserPartnerProspects.label'}
            components={{
              span: <Emphased />,
            }}
            values={{
              name: `${user.firstName} ${user.lastName}`,
            }}
          />
        </Label>
      </Row>
      <Stack className="mt-4">
        <H4>
          {t('components.Users.UserDetails.UserPartnerProspects.ownedList')}
        </H4>
        <List columnSize="300px">
          {ownedList?.map((prospect) => {
            return (
              <UserPartnerProspectCard
                type="owned"
                key={prospect.id}
                prospect={prospect}
                onClick={handleDeleteNursingHone}
              />
            );
          })}
        </List>
      </Stack>

      <Stack className="mt-4">
        <H4>
          {t('components.Users.UserDetails.UserPartnerProspects.availableList')}
        </H4>
        <Row className="justify-content-between">
          <SearchColumn md="2">
            <SearchInput onChange={handleChangeSearch} />
          </SearchColumn>
        </Row>
        <List columnSize="300px">
          {availableList?.map((prospect) => {
            return (
              <UserPartnerProspectCard
                type="available"
                key={prospect.id}
                prospect={prospect}
                onClick={handleAddNursingHone}
              />
            );
          })}
        </List>
      </Stack>
    </Wrapper>
  );
};

export default UserProspect;
