import { Container } from 'react-bootstrap';
import MetricCardBadge from 'src/components/Misc/MetricCardBadge';
import ShippingStatus from 'src/components/NursingHome/ShippingRequest/ShippingStatus';
import {
  GetResponseShippingRequest,
  ShippingRequestResponse,
} from 'src/sdk/shippingRequest';
import styled from 'styled-components/macro';

const Wrapper = styled(Container)`
  display: flex;
  gap: 1rem;
`;

interface Props {
  extra?: GetResponseShippingRequest['extra'];
  filterValue?: ShippingRequestResponse['status'];
  onFilterBy: (value: ShippingRequestResponse['status']) => void;
}

const ShippingRequestFilterStatus = ({
  extra,
  filterValue,
  onFilterBy,
}: Props) => {
  return (
    <Wrapper fluid className="justify-content-center align-items-center">
      <MetricCardBadge
        metric={extra?.countByStatus.CREATED}
        selected={filterValue === 'CREATED'}
        onClick={() => onFilterBy('CREATED')}
      >
        <ShippingStatus status="CREATED" />
      </MetricCardBadge>
      <MetricCardBadge
        metric={extra?.countByStatus.ERROR}
        selected={filterValue === 'ERROR'}
        onClick={() => onFilterBy('ERROR')}
      >
        <ShippingStatus status="ERROR" />
      </MetricCardBadge>
      <MetricCardBadge
        metric={extra?.countByStatus.REQUESTED}
        selected={filterValue === 'REQUESTED'}
        onClick={() => onFilterBy('REQUESTED')}
      >
        <ShippingStatus status="REQUESTED" />
      </MetricCardBadge>
      <MetricCardBadge
        metric={extra?.countByStatus.SHIPPED}
        selected={filterValue === 'SHIPPED'}
        onClick={() => onFilterBy('SHIPPED')}
      >
        <ShippingStatus status="SHIPPED" />
      </MetricCardBadge>
      <MetricCardBadge
        metric={extra?.countByStatus.RECEIVED}
        selected={filterValue === 'RECEIVED'}
        onClick={() => onFilterBy('RECEIVED')}
      >
        <ShippingStatus status="RECEIVED" />
      </MetricCardBadge>
    </Wrapper>
  );
};

export default ShippingRequestFilterStatus;
