import { QueryObserverOptions, useQuery } from 'react-query';
import request from 'src/axios';
import { Status } from 'src/sdk/equipment';
import {
  CliponDetails,
  GatewayDetails,
  GetCliponProvisioningResponse,
  ProvisioningCommonDetails,
} from 'src/sdk/provisioning';

import {
  GetChargingStationProvisioningResponse,
  GetGatewayProvisioningResponse,
} from '../../../sdk/provisioning';

export type SortField<T> = {
  label: keyof T;
  direction: 'asc' | 'desc';
};

type DefaultParams = {
  'nursing-home-id'?: number;
  page: number;
  query: string;
  broken: boolean;
  lost: boolean;
  size: number;
  filterValue?: Status;
};

type CliponQueryParams<T> = {
  sortField: SortField<T>;
} & DefaultParams;

type ChargingStationsQueryParams<T> = {
  sortField: SortField<T>;
} & DefaultParams;

type GatewayQueryParams<T> = {
  sortField: SortField<T>;
} & DefaultParams;

export async function fetchClipons({
  broken,
  lost,
  page,
  size,
  sortField,
  query,
  filterValue,
  ...rest
}: CliponQueryParams<CliponDetails>) {
  const { data } = await request.get('/clipons', {
    params: {
      broken: broken,
      lost: lost,
      page,
      sort:
        sortField.label === 'receivedAt'
          ? `latestReceivedAt,${sortField.direction}`
          : `${sortField.label},${sortField.direction}`,
      'name-or-serial-number-or-sscc-part':
        query.length > 0 ? query : undefined,
      expand: 'handling-unit,nursing-home,firmware-upload',
      ...(filterValue && {
        'equipment-status':
          filterValue === Status.NO_STATUS ? undefined : filterValue,
      }),
      ...rest,
    },
  });
  return data;
}

export async function fetchChargingStation({
  broken,
  lost,
  page,
  size,
  sortField,
  query,
  filterValue,
  ...rest
}: ChargingStationsQueryParams<ProvisioningCommonDetails>) {
  const { data } = await request.get('/charging-stations', {
    params: {
      broken: broken,
      lost: lost,
      page: page,
      size: size,
      sort: `${sortField.label},${sortField.direction}`,
      'name-or-serial-number-or-sscc-part':
        query.length > 0 ? query : undefined,
      expand: 'handling-unit,nursing-home',
      ...(filterValue && {
        'equipment-status':
          filterValue === Status.NO_STATUS ? undefined : filterValue,
      }),
      ...rest,
    },
  });
  return data;
}

export async function fetchGateways({
  broken,
  lost,
  page,
  size,
  sortField,
  query,
  filterValue,
  ...rest
}: GatewayQueryParams<GatewayDetails>) {
  const { data } = await request.get('/gateways', {
    params: {
      broken: broken,
      lost: lost,
      page: page,
      size: size,
      sort: `${sortField.label},${sortField.direction}`,
      'name-or-serial-number-or-sscc-part':
        query.length > 0 ? query : undefined,
      expand: 'handling-unit,nursing-home,connection-activity',
      ...(filterValue && {
        'equipment-status':
          filterValue === Status.NO_STATUS ? undefined : filterValue,
      }),
      ...rest,
    },
  });
  return data;
}

type ParamsMap = {
  clipon: CliponQueryParams<CliponDetails>;
  charging: ChargingStationsQueryParams<ProvisioningCommonDetails>;
  gateway: GatewayQueryParams<GatewayDetails>;
};

type ResponseMap = {
  clipon: GetCliponProvisioningResponse;
  charging: GetChargingStationProvisioningResponse;
  gateway: GetGatewayProvisioningResponse;
};

type ResponseType<T extends keyof ParamsMap> = T extends keyof ResponseMap
  ? ResponseMap[T]
  : never;

export type EquipmentType = keyof ParamsMap;

const useFetchEquipmentsQuery = <T extends keyof ParamsMap>(
  type: T,
  params: ParamsMap[T],
  options?: QueryObserverOptions<ResponseType<T>, Error>,
) => {
  const { data, isLoading, refetch, error } = useQuery<ResponseType<T>, Error>(
    [`${type}-list`, { ...params }],
    () => {
      if (type === 'clipon') {
        return fetchClipons(params as ParamsMap['clipon']);
      }
      if (type === 'charging') {
        return fetchChargingStation(params as ParamsMap['charging']);
      }
      return fetchGateways(params as ParamsMap['gateway']);
    },
    { ...options},
  );
 
  const narrowedData = data as ResponseType<T> | undefined;
    
  return {
    data: narrowedData,
    isLoading,
    refetch,
    error,
  };
};

export default useFetchEquipmentsQuery;
