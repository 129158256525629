import { useQuery } from 'react-query';
import request from 'src/axios';
import { ShippingRequestOrderSparePartsLimitsResponse } from 'src/sdk/shippingRequest';

type QueryParams = {
  'nursing-home-id': number;
};

const fetchOrderSpartPartsCreationData = async (params: QueryParams) => {
  const { data } = await request.get(
    `/shipping-requests/order-spare-parts/creation-data`,
    {
      params,
    },
  );

  return data;
};

const useFetchOrderSpartPartsCreationDataQuery = (params: QueryParams) => {
  const { data, isLoading, refetch, error } = useQuery<
    ShippingRequestOrderSparePartsLimitsResponse,
    Error
  >(['sparePartCreationData', { ...params }], () =>
    fetchOrderSpartPartsCreationData(params),
  );

  return {
    data,
    isLoading,
    refetch,
    error,
  };
};

export default useFetchOrderSpartPartsCreationDataQuery;
