import { ReactNode } from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components/macro';

interface ModalProps {
  $hasOpacity?: boolean;
  width?: string;
}

const StyledModal = styled(Modal)<ModalProps>`
  opacity: ${({ $hasOpacity }) => ($hasOpacity ? 0.5 : 1)};
  /* important used to fix react-bootstrap weird behaviour */
  overflow-y: hidden !important;

  .modal-dialog {
    height: 100%;
  }

  .customModal {
    width: ${({ width }) => width || '500px'};      
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
`;

const StyledContent = styled.div`
  padding: 0.75rem;
`;

interface EmptyModalProps {
  children: ReactNode;
  isVisible: boolean;
  opacity?: boolean;
  handleClose: () => void;
  width?: string;
}

const EmptyModal = ({
  children,
  handleClose,
  isVisible,
  opacity,
  width,
}: EmptyModalProps) => {
  return (
    <StyledModal
      show={isVisible}
      onHide={handleClose}
      $hasOpacity={opacity}
      contentClassName="customModal"
      width={width}
    >
      <StyledContent>{children}</StyledContent>
    </StyledModal>
  );
};

export default EmptyModal;
