import { useQuery } from 'react-query';
import request from 'src/axios';
import {
  GetNursingHomeResponse,
  NursingHomeExpand,
  NursingHomeResponse,
} from 'src/sdk/nursingHome';

export type NursingHomeSortField = {
  label: keyof NursingHomeResponse;
  direction: 'asc' | 'desc';
};

type QueryParams = {
  page: number;
  size: number;
  expand: NursingHomeExpand[];
  nameOrSoldtoPart: string;
  sort: NursingHomeSortField;
};

const fetchNursingHomes = async ({
  page,
  size,
  expand,
  nameOrSoldtoPart,
  sort,
}: QueryParams) => {
  const { data } = await request.get('/nursing-homes', {
    params: {
      page,
      size,
      expand: expand.join(', '),
      'name-part': nameOrSoldtoPart,
      sort: `${sort.label},${sort.direction}`,
    },
  });
  return data;
};

const useFetchNursingHomesQuery = (params: QueryParams) => {
  const { data, isLoading, refetch, error } = useQuery<
    GetNursingHomeResponse,
    Error
  >(['nursingHomeList', { ...params }], () => fetchNursingHomes(params));
  return {
    data,
    isLoading,
    refetch,
    error,
  };
};

export default useFetchNursingHomesQuery;
