import { useTranslation } from 'react-i18next';
import SortingButton from 'src/components/SortingHeaders/SortingButton';
import { NursingHomeResponse } from 'src/sdk/nursingHome';
import styled from 'styled-components/macro';

const StyledRow = styled.div`
  display: flex;
  width: 90%;
  margin: 24px 0px; ;
`;

const PrimaryStyledCol = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
`;

const StyledCol = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 20%;
`;

interface Props {
  onSortingChange: (field: keyof NursingHomeResponse) => void;
}

const SortingFirmwareHeader = ({ onSortingChange }: Props) => {
  const { t } = useTranslation();

  return (
    <StyledRow>
      <PrimaryStyledCol>
        <SortingButton
          primary
          label={t('name')}
          field="name"
          onClick={() => onSortingChange('name')}
        />
      </PrimaryStyledCol>
      <StyledCol>
        <SortingButton
          primary
          label={t('deployed-firmware')}
          field="concatVersion"
          onClick={() => onSortingChange('concatVersion')}
        />
      </StyledCol>
      <StyledCol>
        <SortingButton
          primary
          label={t('clipons')}
          field="cliponCount"
          onClick={() => onSortingChange('cliponCount')}
        />
      </StyledCol>
      <StyledCol>
        <SortingButton
          primary
          label={t('outdated-clipons')}
          field="wrongFirmwareCliponCount"
          onClick={() => onSortingChange('wrongFirmwareCliponCount')}
        />
      </StyledCol>
    </StyledRow>
  );
};

export default SortingFirmwareHeader;
