import { useTranslation } from 'react-i18next';
import { UserResponse, UserStatus } from 'src/sdk/users';
import { Badge, BadgeStatus } from 'src/uikit/Badge';

const colors = {
  [UserStatus.Active]: BadgeStatus.greenElectric,
  [UserStatus.Blocked]: BadgeStatus.red,
  [UserStatus.Pending]: BadgeStatus.yellow,
  [UserStatus.NoStatus]: BadgeStatus.grey,
};

interface Props {
  status?: UserResponse['accountStatus'];
}

const UserStatusBadge = ({ status }: Props) => {
  const { t } = useTranslation();

  return (
    <Badge bg={status ? colors[status] : BadgeStatus.grey}>
      {t(`components.Users.UserStatusBadge.${status}`)}
    </Badge>
  );
};

export default UserStatusBadge;
