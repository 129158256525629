import { ReactNode, ElementType, useMemo, forwardRef } from 'react';
import { Col, Image, OverlayTrigger, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import hoverCard, { HoverCardProps } from 'src/components/Misc/Card/HoverCard';
import { Product } from 'src/sdk/product';
import { Card, CardBody, CardProps } from 'src/uikit/Card';
import { Tooltip } from 'src/uikit/Tooltip';
import { Strong } from 'src/uikit/Typography';
import styled from 'styled-components/macro';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  min-height: 120px;
`;

const StyledWrapperImage = styled.div`
  display: flex;
  justify-content: center;
  max-width: 120px;
`;

const DefaultCard = forwardRef<{ children: ReactNode } & CardProps>(
  ({ children, ...rest }, ref) => (
    <Card ref={ref} {...rest}>
      <CardBody>{children}</CardBody>
    </Card>
  ),
);

type variant = 'default' | 'hover';

const Components: { [key in variant]: ElementType } = {
  default: DefaultCard,
  hover: hoverCard,
} as const;

export type ProductCardProps = (
  | ({ variant?: 'hover' } & Omit<HoverCardProps, 'children'>)
  | { variant?: 'default' }
) & {
  product: Product;
} & CardProps;

const ProductCard = ({ product, variant, ...rest }: ProductCardProps) => {
  const { t, i18n } = useTranslation();

  const Component: ElementType = variant
    ? Components[variant]
    : Components.default;

  const drops = useMemo(() => {
    if (!product.drops) {
      return '';
    }

    const value = parseInt(product.drops || '', 10);
    const isNumber = !isNaN(value);
    return isNumber ? value : product.drops;
  }, [product.drops]);

  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={
        rest.disabled ? (
          <Tooltip id="button-tooltip">
            {t('nursingHome.configuration.products.card.disabled')}
          </Tooltip>
        ) : (
          <Tooltip id="button-tooltip">
            <div className="d-flex flex-column align-items-start text-start">
              <Strong>{product.brand}</Strong>
              {drops && (
                <span>
                  {typeof drops === 'number'
                    ? t(
                        'nursingHome.configuration.products.card.dropWithCount',
                        {
                          count: parseInt(product.drops || '', 10),
                        },
                      )
                    : drops}
                </span>
              )}
              {product.size && (
                <span>
                  {t('nursingHome.configuration.products.card.size', {
                    size: product.size,
                  })}
                </span>
              )}
              {product.sapId && <span>{product.sapId}</span>}
            </div>
          </Tooltip>
        )
      }
    >
      {({ ref, ...triggerHandler }) => (
        <Component ref={ref} {...rest} {...triggerHandler}>
          <StyledWrapper>
            <Row className="justify-content-center text-center">
              <StyledWrapperImage>
                <Image src={product.productSticker} />
              </StyledWrapperImage>
            </Row>
            <Row className="justify-content-center text-center">
              <Col xs="12">
                {product.names &&
                  product.names.find(
                    (name) =>
                      name.language && i18n.language.includes(name.language),
                  )?.value}
              </Col>
              <Col xs="12">
                <span>{product.sapId}</span>
              </Col>
            </Row>
          </StyledWrapper>
        </Component>
      )}
    </OverlayTrigger>
  );
};

export default ProductCard;
