import { Controller, useFormContext } from 'react-hook-form';
import SwitchInput from 'src/uikit/SwitchInput';

import { SwitchInputProps } from '../../SwitchInput/index';

export type Props = {
  name: string;
} & Omit<SwitchInputProps, 'value' | 'onChange'>;

const FormSwitchInput = ({ name, disabled, ...rest }: Props) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ value, onChange, ref }) => (
        <SwitchInput
          ref={ref}
          id={!disabled ? name : `${name}-disabled`}
          type="checkbox"
          checked={value}
          value={value}
          onChange={onChange}
          disabled={disabled}
          {...rest}
        />
      )}
    />
  );
};

export default FormSwitchInput;
