import { yupResolver } from '@hookform/resolvers/yup';
import { Form } from 'react-bootstrap';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import PartnerAsyncSelect from 'src/components/Partners/PartnerAsyncSelect';
import { Profiles } from 'src/sdk/authentification';
import ErrorMessage from 'src/uikit/ErrorMessage';
import styled from 'styled-components/macro';
import * as yup from 'yup';

import UserCreateForm, {
  userCreateFormSchema,
} from './components/UserCreateFormFieldset';
import { UserCreateFormFooterProps } from './components/UserCreateFormFooter';
import UserCreateFormFooter from './components/UserCreateFormFooter';

const FormGroup = styled(Form.Group)`
  width: 400px;
  margin-inline: auto;
`;

const userPartnerAdminCreateFormSchema = (t: TFunction) => {
  const baseSchema = userCreateFormSchema(t);
  return baseSchema.shape({
    profile: yup.mixed<Profiles>(),
    partner: yup
      .number()
      .required(
        t(
          'components.Users.UsersList.UserCreateFormFieldset.validations.required',
        ),
      ),
  });
};

export type UserPartnerAdminCreateFormSchema = yup.InferType<
  ReturnType<typeof userPartnerAdminCreateFormSchema>
>;

interface Props extends UserCreateFormFooterProps {
  onSubmit: (values: UserPartnerAdminCreateFormSchema) => void;
}

const UserPartnerAdminCreateForm = ({ onSubmit, onBack, onCancel }: Props) => {
  const { t } = useTranslation();
  const methods = useForm<UserPartnerAdminCreateFormSchema>({
    resolver: yupResolver(userPartnerAdminCreateFormSchema(t)),
    mode: 'onChange',
    defaultValues: userPartnerAdminCreateFormSchema(t).cast({
      profile: Profiles.PARTNER_ADMIN,
    }),
  });

  const { control, errors, handleSubmit } = methods;

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <Controller
            control={control}
            name="partner"
            render={({ value, onChange, ref }) => (
              <PartnerAsyncSelect
                placeholder={t(
                  'components.Users.UsersList.UserPartnerAdminCreateForm.select',
                )}
                value={value}
                onChange={onChange}
                ref={ref}
                isInvalid={!!errors.partner}
              />
            )}
          />
          {errors.partner && (
            <ErrorMessage>{errors.partner.message}</ErrorMessage>
          )}
        </FormGroup>
        <UserCreateForm
          profilesFilter={[Profiles.PARTNER_ADMIN]}
          profileDisabled
        />
        <UserCreateFormFooter onBack={onBack} onCancel={onCancel} />
      </Form>
    </FormProvider>
  );
};

export default UserPartnerAdminCreateForm;
