import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ListPaginated from 'src/components/Misc/ListPaginated';
import { DEFAULT_PAGE_SIZE } from 'src/components/Misc/Pagination';
import PartnerProspectCard from 'src/components/NursingHome/Creation/components/PartnerProspectCard';
import { List } from 'src/uikit/Layout/List';
import SearchInput from 'src/uikit/SearchInput';
import { H3 } from 'src/uikit/Typography';
import { PageWrapper } from 'src/uikit/Wrapper';
import styled from 'styled-components/macro';

import useFetchPartnerProspectsQuery from '../../../PartnerProspects/hooks/useFetchPartnerProspectsQuery';

const ListTitle = styled(H3)`
  line-height: 21px;
  color: ${({ theme }) => theme.palette.black.strongGrey};
  margin: 25px;
  text-align: center;
`;

const PAGE_SIZE = DEFAULT_PAGE_SIZE;

const NursingHomeCreationListPartner = () => {
  const { t } = useTranslation();

  const [page, setPage] = useState(0);

  const [searchValue, setSearchValue] = useState('');

  const { data, isLoading } = useFetchPartnerProspectsQuery(
    page,
    PAGE_SIZE,
    ['address'],
    { direction: 'asc', label: 'name' },
    searchValue,
    true,
  );

  return (
    <PageWrapper>
      <ListTitle>{t('components.listNursingHome.otherList')}</ListTitle>
      <Row className="d-flex align-items-center justify-content-center">
        <Col md="3">
          <SearchInput
            onChange={(searchValue) => setSearchValue(searchValue)}
          />
        </Col>
      </Row>
      <ListPaginated
        data={data}
        isLoading={isLoading}
        page={page}
        setPage={setPage}
      >
        <List columnSize="300px" className="mx-4 mt-4" gap="1.5rem">
          {data?.content?.map((prospect, i) => {
            return <PartnerProspectCard prospect={prospect} />;
          })}
        </List>
      </ListPaginated>
    </PageWrapper>
  );
};

export default NursingHomeCreationListPartner;
