import { forwardRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { OptionsList } from 'react-select-async-paginate';
import request from 'src/axios';
import AsyncSelectInput, { AsyncOptionType } from 'src/uikit/AsyncSelectInput';

type GetResponseFilter = string[];
type ProductFilter = 'brand' | 'color' | 'drop' | 'size';

const ProductFilterPath: { [key in ProductFilter]: string } = {
  brand: '/product-brands',
  color: '/product-colors',
  drop: '/product-drops',
  size: '/product-sizes',
};

const ProductFilterSort: { [key in ProductFilter]: string } = {
  brand: 'brand',
  color: 'incontinenceColor',
  drop: 'drops',
  size: 'size',
};

interface Props {
  value?: string | null;
  onChange: (value: string | null) => void;
  placeholder: string;
  type: ProductFilter;
  b2b?: boolean;
  nursingHomeId?: number;
  partnerId?: number;
  productSize?: string | null;
  brand?: string | null;
  color?: string | null;
  drops?: string | null;
}

const ProductFilterAsyncSelect = forwardRef<HTMLSelectElement, Props>(
  (
    {
      value,
      onChange,
      type,
      b2b,
      nursingHomeId,
      productSize,
      brand,
      color,
      drops,
      partnerId,
      ...rest
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const [listOptions, setListOptions] = useState<AsyncOptionType<string>[]>(
      [],
    );

    const [products, setProducts] = useState<string[]>([]);

    useEffect(() => {
      async function getProducts() {
        const { data } = await request.get<GetResponseFilter>(
          ProductFilterPath[type],
          {
            params: {
              sort: ProductFilterSort[type],
              ...(b2b && nursingHomeId && { 'nursing-home-id': nursingHomeId }),
              ...(partnerId && { 'partner-id': partnerId }),
              ...(productSize && { 'product-size': productSize }),
              ...(brand && { brand: brand }),
              ...(color && { color: color }),
              ...(drops && { drops: drops }),
            },
          },
        );

        setProducts(data);
      }

      getProducts();
    }, [b2b, brand, color, drops, nursingHomeId, partnerId, productSize, type]);

    const loadOptions = async (
      search: string,
      prevOptions: OptionsList<AsyncOptionType<string>>,
    ) => {
      const options: AsyncOptionType<string | null>[] = products
        .map((value: string) =>
          value
            ? {
                value,
                label: value,
              }
            : {
                value,
                label: t('common.all'),
              },
        )
        .filter((item) => item.label.includes(search));

      const slicedOptions = [
        ...prevOptions,
        ...options,
      ] as AsyncOptionType<string>[];

      setListOptions(slicedOptions);

      return {
        options: options as AsyncOptionType<string>[],
        hasMore: false,
      };
    };

    return (
      <AsyncSelectInput
        key={JSON.stringify({
          color,
          brand,
          productSize,
          drops,
        })}
        ref={ref}
        variant="rounded"
        loadOptions={loadOptions}
        value={
          listOptions.find((e) => e.value === value) as AsyncOptionType<string>
        }
        onChange={(val: { value: string; label: string }) => {
          onChange(val ? val.value : null);
        }}
        {...rest}
      />
    );
  },
);

export default ProductFilterAsyncSelect;
