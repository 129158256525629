import { Form as BoostrapForm } from 'react-bootstrap';
import { Label } from 'src/uikit/Typography';
import styled from 'styled-components/macro';

export const StyledLabel = styled.label`
  font-family: 'Roboto';
  font-styl1e: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: ${({ theme }) => theme.palette.black.main};
  text-align: left;
`;

export const StyledHelper = styled.div`
  font-family: 'Roboto';
  font-size: 0.9rem;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.black[70]};
`;

export const Form = styled(BoostrapForm)``;

export const FormLabel = styled(Label)`
  font-family: 'Poppins';
  width: 100%;
  margin-bottom: 1rem;
  text-align: left;
`;

export const FormGroup = styled(Form.Group)`
  margin-bottom: 0rem;
  margin-top: 0.2rem;

  &:has(${FormLabel}) {
    margin-bottom: 0rem;
    margin-top: 0rem;
  }
`;
