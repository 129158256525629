import styled from 'styled-components/macro';

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
  background: ${({ theme }) => theme.palette.main.white};
  border-radius: 10px;
`;

export const FormButtonsWrapper = styled('div')<{ oneButton?: boolean }>`
  display: flex;
  align-items: center;
  margin-top: 1rem;
  justify-content: ${(props) =>
    props.oneButton ? 'flex-end' : 'space-between'};
`;

export const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const PageWrapper = styled.div`
  padding: 1.5rem 1rem;
`;

export const Stack = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
