/**
 * Return true if the strings are different.
 * Note that for this method null, undefined and empty string are the same.
 */
export function areDifferentStrings(s1: string | undefined, s2: string | undefined): boolean {
  if (isNullOrEmptyString(s1)) {
    return !isNullOrEmptyString(s2);
  }
  else {
    return isNullOrEmptyString(s2) || s1 !== s2;
  }
}

/**
 * Return true if a string is null, undefined or empty.
 */
export function isNullOrEmptyString(s: string | undefined): boolean {
  return s == null || s === "";
}
