import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import request from 'src/axios';
import FirmwareList from 'src/components/Firmware/FirmwareList';
import SearchInput from 'src/uikit/SearchInput';
import SwitchInput from 'src/uikit/SwitchInput';
import palette from 'src/uikit/theme/palette';
import { PageWrapper } from 'src/uikit/Wrapper';
import styled from 'styled-components/macro';
import './card.css';

const List = styled.ul`
  list-style: none;
  display: flex;
  margin: 0px;
  padding: 0px;
  justify-content: center;
  align-items: center;
`;

const Card = styled.li`
  display: flex;
  justify-content: center;
  padding: 16px;
  height: 120px;
  width: 120px;
  background: white;
  border-radius: 12px;
  margin-right: 16px;
  box-shadow: 0.3px 0.4px 0.6px hsl(0deg 0% 70% / 0.33),
    2.2px 2.5px 5.1px -1px hsl(0deg 0% 70% / 0.41);
  :hover {
    border: 2px solid;
    border-color: ${({ theme }) => theme.palette.main.primary};
    cursor: pointer;
  }
`;

const Pill = styled.span`
  border-radius: 5px;
  color: white;
  margin: 0.254rem;
  padding: 0 8px;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1px;
`;

type Clipon = {
  versionMajor: number;
  versionMinor: number;
  versionRevision: number;
  cliponCount: number;
};

type FormattedClipon = {
  version: string;
  count: number;
};

const FirmwaresOverview = () => {

  const { t } = useTranslation();
  const [query, setQuery] = useState('');
  const [outdated = false, setOutdated] = useState<boolean>();
  const [selectedVersion, setSelectedVersion] = useState<string>("");

  const { data } = useQuery('firmwares-metrics', async () => {
    return request.get('/firmwares/metrics').then((res) => res.data);
  });

  
  


  const total = data?.cliponUsages?.reduce(
    (acc: number, cur: Clipon) => acc + cur.cliponCount,
    0,
  );
    

  const firmwares = data?.cliponUsages
    ?.map((clipon: Clipon) => ({
      version: `${clipon.versionMajor}.${clipon.versionMinor}.${clipon.versionRevision}`,
      count: clipon.cliponCount,
    }))
    .sort((a: FormattedClipon, b: FormattedClipon) =>
      b.version.localeCompare(a.version, undefined, {
        numeric: true,
        sensitivity: 'base',
      }),
    );


  const metrics = firmwares
    ?.reduce(
      (acc: Array<FormattedClipon>, cur: FormattedClipon) => {
        const index = acc.findIndex(
          (e: FormattedClipon) => e.version === cur.version,
        );
        acc[index > 0 ? index : 0] = {
          ...acc[index > 0 ? index : 0],
          count: acc[index > 0 ? index : 0].count + cur.count,
        };
        return acc;
      },
      [
        { color: palette.main.red, version: t('others'), count: 0 },
        {
          color: palette.main.orange,
          version: firmwares?.[2]?.version,
          count: 0,
        },
        {
          color: palette.main.yellow,
          version: firmwares?.[1]?.version,
          count: 0,
        },
        {
          color: palette.main.greenElectric,
          version: firmwares?.[0]?.version,
          count: 0,
        },
      ],
    )
    .map((firmware: FormattedClipon & { color: string }) => ({
      ...firmware,
      percentage: ((firmware.count / total) * 100).toFixed(2),
    }));


    

    const handleToggleSelectedVersion = (version: any) => {
      if (selectedVersion === version) {
        setSelectedVersion("");
      } else {
        setSelectedVersion(version);
      }
    }

  return (
    <PageWrapper>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          cursor: 'pointer',
        }}
      >
        <List style={{ width: '100%' }}>
          {metrics?.map(
            (
              entry: FormattedClipon & { color: string; percentage: string },
              key: number,
            ) => (
              <Card key={key}  onClick={() =>handleToggleSelectedVersion(entry.version)} 
                className={`firmwareCard ${entry.version === selectedVersion ? 'selected' : ''}`}
              >
                <p
                  style={{
                    textAlign: 'center',
                  }}
                >
                  <Pill
                    style={{
                      background: entry.color,
                    }}
                  >
                    {entry.version}
                  </Pill>
                  <strong style={{ marginTop: '24px', display: 'block' }}>
                    {entry.percentage}%
                  </strong>
                </p>
              </Card>
            ),
          )}
        </List>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          margin: '48px 0px',
        }}
      >
        <div style={{ display: 'flex', width: '20%', marginRight: '12px' }}>
          <SearchInput onChange={(query) => setQuery(query)} />
        </div>
        <SwitchInput
          label={t('outdated-only')}
          checked={outdated}
          onChange={setOutdated}
        />
      </div>
      <div style={{ width: '100%' }}>
        <FirmwareList query={query} outdated={outdated} selectedVersion={selectedVersion}/>
      </div>
    </PageWrapper>
  );
};

export default FirmwaresOverview;
