import { useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { ReactComponent as ActiveItem } from 'src/assets/active-menu-item.svg';
import palette from 'src/uikit/theme/palette';
import { SidebarLink } from 'src/uikit/Typography';
import styled from 'styled-components/macro';

interface ActiveItemIconProps {
  $isActive?: boolean;
}

const ActiveItemIcon = styled(ActiveItem)<ActiveItemIconProps>`
  position: absolute;
  display: ${({ $isActive }) => ($isActive ? 'block' : 'none')};
`;

const Menu = styled(Row)`
  align-items: left;
  ${SidebarLink} {
    font-size: 0.8rem;
    line-height: 1rem;
  }
  :hover > ${ActiveItemIcon} {
    display: block;
  }
  :hover ${SidebarLink} {
    text-decoration: none;
    color: ${({ theme }) => theme.palette.main.primary};
  }
  :active > ${ActiveItemIcon} {
    display: block;
  }
`;

interface SidebarProps {
  $isCollapsed?: boolean;
}

const SidebarLinkColumn = styled(Col)<SidebarProps>`
  display: ${({ $collapsed }) => ($collapsed ? 'none' : 'flex')};
  flex-direction: row;
  align-items: center;
`;

interface Props {
  label: string;
  path?: string;
  opened: boolean;
}

const SidebarSubitem = ({ label, path, opened }: Props) => {
  const history = useHistory();

  const handleClick = useCallback(() => {
    path && history.push(path);
  }, [history, path]);

  return (
    <>
      <Menu onClick={handleClick}>
        <SidebarLinkColumn $isCollapsed={opened}>
          <SidebarLink
            activeStyle={{
              color: palette.main.primary,
            }}
            strict
            to={path || '#'}
          >
            {label}
          </SidebarLink>
        </SidebarLinkColumn>
      </Menu>
    </>
  );
};

export default SidebarSubitem;
