import { AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import request from 'src/axios';
import { OrizonError } from 'src/sdk/common';

import { NursingHomeData } from './useNursingHomeData';

const useRefreshList = (id: number) => {
  const queryClient = useQueryClient();

  const refreshListMutation = useMutation<
    AxiosResponse<{ productAssociationDate: string }>,
    OrizonError
  >(() => request.get(`/products/import-associations?nursing-home-id=${id}`), {
    onSuccess: (res) => {
      queryClient.setQueryData<NursingHomeData>(
        `nursing-home-data${id}`,
        (data: unknown) => ({
          ...(data as NursingHomeData),
          productAssociationDate: res.data.productAssociationDate,
        }),
      );
    },
  });

  return { refreshListMutation };
};

export default useRefreshList;
