import { Status } from 'src/sdk/equipment';

import MetricCardBadge from '../Misc/MetricCardBadge';
import EquipmentStatusLabel from './EquipmentStatusLabel';

interface Props {
  status: Status;
  metric?: number;
  selected: boolean;
  onClick: () => void;
}

const ProvisioningMetricCard = ({
  status,
  metric,
  selected,
  onClick,
}: Props) => {
  return (
    <MetricCardBadge metric={metric} selected={selected} onClick={onClick}>
      <EquipmentStatusLabel status={status} />
    </MetricCardBadge>
  );
};

export default ProvisioningMetricCard;
