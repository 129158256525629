import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosResponse } from 'axios';
import { Col, Row } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { TFunction, Trans, useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import request from 'src/axios';
import useFormatErrorApi from 'src/hooks/useFormatErrorApi';
import useLanguageOptions from 'src/hooks/useLanguageOptions';
import useToast from 'src/hooks/useToast';
import Routes from 'src/router/Routes';
import { Profiles } from 'src/sdk/authentification';
import { Gender } from 'src/sdk/common';
import { UserResponse, CreateUserRequest } from 'src/sdk/users';
import { Button } from 'src/uikit/Button';
import { FormGroup } from 'src/uikit/Form';
import FormPasswordInput from 'src/uikit/Form/PasswordInput';
import { passwordSchema } from 'src/uikit/Form/PasswordInput/PasswordMatcher';
import FormSelectInput from 'src/uikit/Form/SelectInput';
import FormTextInput from 'src/uikit/Form/TextInput';
import { Link } from 'src/uikit/Link';
import { Emphased, H3, Label, P } from 'src/uikit/Typography';
import styled from 'styled-components/macro';
import * as yup from 'yup';

import AuthForm from '../AuthForm';
import BackToLogin from '../BackToLogin';

const Title = styled(H3)`
  margin-bottom: 1rem;
`;

const Description = styled(P)`
  margin-bottom: 20px;
`;

const Subtitle = styled(Label)``;

const SelectBlock = styled(FormGroup)``;

const SubmitRow = styled(Row)`
  display: flex;
  justify-content: flex-end;
`;

const registerFormSchema = (t: TFunction) =>
  yup.object({
    firstName: yup
      .string()
      .required(t('components.Auth.RegisterForm.validations.required'))
      .min(2, t('components.Auth.RegisterForm.validations.tooShort')),
    lastName: yup
      .string()
      .required(t('components.Auth.RegisterForm.validations.required'))
      .min(2, t('components.Auth.RegisterForm.validations.tooShort')),
    email: yup
      .string()
      .required(t('components.Auth.RegisterForm.validations.required'))
      .email(t('components.Auth.RegisterForm.validations.invalidEmail')),
    password: passwordSchema(t).required(),
    confirmPassword: yup
      .string()
      .required(t('components.Auth.RegisterForm.validations.required'))
      .oneOf(
        [yup.ref('password'), null],
        t('components.Auth.RegisterForm.validations.passwordMatch'),
      ),
    gender: yup
      .mixed<Gender>()
      .oneOf(Object.values(Gender))
      .required(t('components.Auth.RegisterForm.validations.required')),
    language: yup
      .string()
      .required(t('components.Auth.RegisterForm.validations.required')),
  });

type RegisterFormSchema = yup.InferType<ReturnType<typeof registerFormSchema>>;

interface Props {
  onUserCreated: (user: UserResponse) => void;
}

const RegisterForm = ({ onUserCreated }: Props) => {
  const { t } = useTranslation();
  const languageOption = useLanguageOptions();

  const genderOption = [
    {
      value: Gender.Male,
      label: t('components.profileForm.gender.male'),
    },
    {
      value: Gender.Female,
      label: t('components.profileForm.gender.female'),
    },
  ];

  const { formatErrorApi } = useFormatErrorApi();
  const { toastError } = useToast();

  const methods = useForm<RegisterFormSchema>({
    resolver: yupResolver(registerFormSchema(t)),
    mode: 'onChange',
    defaultValues: {
      firstName: '',
      gender: Gender.Female,
      confirmPassword: '',
      language: '',
      lastName: '',
      password: '',
      email: '',
    },
  });

  const { handleSubmit, formState } = methods;

  const { mutateAsync, isLoading } = useMutation<
    AxiosResponse<UserResponse>,
    Error,
    CreateUserRequest
  >((params) => request.post('/users/create-manager-public', params));

  const onSubmit = handleSubmit(async (data) => {
    try {
      const { data: responseData } = await mutateAsync({
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        password: data.password,
        profile: Profiles.PRODUCT_SPECIALIST,
        gender: data.gender,
        language: data.language,
      });

      onUserCreated(responseData);
    } catch (err) {
      const message = formatErrorApi(err);
      toastError(message);
    }
  });

  return (
    <FormProvider {...methods}>
      <AuthForm onSubmit={onSubmit}>
        <Title>{t('components.Auth.RegisterForm.title')}</Title>
        <Description>
          <Trans
            i18nKey="components.Auth.RegisterForm.description"
            components={{
              span: <Emphased />,
              a: <Link to={Routes.LOGIN} />,
            }}
          />
        </Description>
        <Subtitle className="mb-2">
          {t('components.Auth.RegisterForm.email')}
        </Subtitle>
        <FormGroup>
          <FormTextInput
            name="email"
            placeholder={t('components.Auth.RegisterForm.placeholder.email')}
            width="57"
            autoComplete="email"
          />
        </FormGroup>
        <Subtitle className="mb-2">
          {t('components.Auth.RegisterForm.information')}
        </Subtitle>
        <FormGroup>
          <FormTextInput
            name="firstName"
            placeholder={t(
              'components.Auth.RegisterForm.placeholder.firstName',
            )}
            width="57"
            autoComplete="off"
          />
        </FormGroup>
        <FormGroup>
          <FormTextInput
            name="lastName"
            placeholder={t('components.Auth.RegisterForm.placeholder.lastName')}
            width="57"
            autoComplete="off"
          />
        </FormGroup>

        <FormGroup>
          <FormPasswordInput
            name="password"
            placeholder={t('components.Auth.RegisterForm.placeholder.password')}
            width="57"
            showRules
          />
        </FormGroup>
        <FormGroup>
          <FormPasswordInput
            name="confirmPassword"
            placeholder={t(
              'components.Auth.RegisterForm.placeholder.confirmPassword',
            )}
            width="57"
          />
        </FormGroup>
        <SelectBlock>
          <FormSelectInput
            name="gender"
            placeholder={t('components.Auth.RegisterForm.placeholder.gender')}
            options={genderOption}
          />
        </SelectBlock>
        <SelectBlock>
          <FormSelectInput
            name="language"
            placeholder={t('components.Auth.RegisterForm.placeholder.language')}
            options={languageOption}
          />
        </SelectBlock>
        <SubmitRow xs="auto">
          <Col>
            <Button type="submit" disabled={!formState.isValid}>
              {isLoading ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                t('components.Auth.RegisterForm.submitButton')
              )}
            </Button>
          </Col>
        </SubmitRow>
        <BackToLogin />
      </AuthForm>
    </FormProvider>
  );
};

export default RegisterForm;
