import { AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import request from 'src/axios';
import {
  SubscriptionResponse,
  SubscriptionCreationRequest,
} from 'src/sdk/subscription';

const useCreateOrUpdateSubscriptionMutation = () => {
  const queryClient = useQueryClient();

  const createOrUpdateSubscriptionMutation = useMutation<
    AxiosResponse<SubscriptionResponse>,
    Error,
    SubscriptionCreationRequest
  >((newSubscription) => request.post(`/subscriptions`, newSubscription), {
    onSuccess: () => {
      queryClient.invalidateQueries(['subscriptionList']);
      queryClient.refetchQueries(['currentNursingHome']);
      queryClient.invalidateQueries('subscription-creation-data');
    },
  });

  return { createOrUpdateSubscriptionMutation };
};

export default useCreateOrUpdateSubscriptionMutation;
