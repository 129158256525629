import { forwardRef, useState } from 'react';
import { OptionsList } from 'react-select-async-paginate';
import request from 'src/axios';
import { Profiles } from 'src/sdk/authentification';
import { GetUsersResponse, UserResponse } from 'src/sdk/users';
import AsyncSelectInput, { AsyncOptionType } from 'src/uikit/AsyncSelectInput';

const size = 9;

interface Props {
  value?: UserResponse;
  onChange: (value: UserResponse) => void;
  placeholder: string;
  profile?: Profiles;
  ontexAndPaOnly: boolean;
}

const UserAsyncSelect = forwardRef<HTMLSelectElement, Props>(
  ({ value, onChange, profile, ontexAndPaOnly, ...rest }, ref) => {
    const [listOptions, setListOptions] = useState<
      AsyncOptionType<UserResponse>[]
    >([]);

    const loadOptions = async (
      search: string,
      prevOptions: OptionsList<AsyncOptionType<UserResponse>>,
    ) => {
      const { data } = await request.get<GetUsersResponse>('/users', {
        params: {
          page: Math.ceil(prevOptions.length / size),
          size,
          'name-part': search,
          profile,
          'ontex-and-pa-only': ontexAndPaOnly,
        },
      });

      const options: AsyncOptionType<UserResponse>[] = data?.content.map(
        (user: UserResponse) => ({
          value: user,
          label: `${user.firstName} ${user.lastName}`,
        }),
      );

      const hasMore = (data.pageable?.pageNumber || 0) < data.totalPages - 1;
      const slicedOptions = [
        ...prevOptions,
        ...options,
      ] as AsyncOptionType<UserResponse>[];

      setListOptions(slicedOptions);

      return {
        options,
        hasMore,
      };
    };

    return (
      <AsyncSelectInput
        ref={ref}
        variant="rounded"
        loadOptions={loadOptions}
        value={
          listOptions.find(
            (e) => e.value === value,
          ) as AsyncOptionType<UserResponse>
        }
        onChange={(val: { value: UserResponse; label: string }) =>
          onChange(val.value)
        }
        {...rest}
      />
    );
  },
);

export default UserAsyncSelect;
