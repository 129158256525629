import { useMemo } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  generatePath,
  Redirect,
  Switch,
  useLocation,
  useParams,
} from 'react-router-dom';
import { ChevronLeft } from 'src/assets/arrows';
import useFetchUserQuery from 'src/components/Users/hooks/useFetchUserQuery';
import UserGeneral from 'src/components/Users/UserDetails/UserGeneral';
import UserNursingHome from 'src/components/Users/UserDetails/UserNursingHome';
import UserProspect from 'src/components/Users/UserDetails/UserProspect';
import PrivateRoute from 'src/router/PrivateRoute';
import Routes from 'src/router/Routes';
import { Profiles } from 'src/sdk/authentification';
import { RouteParams } from 'src/sdk/nursingHome';
import { Link } from 'src/uikit/Link';
import styled from 'styled-components/macro';

import UserHorizontalMenu from '../components/Users/UserDetails/UserHorizontalMenu';
import { useAuth } from '../contexts/Auth';

const Wrapper = styled.div`
  height: calc(100% - 50px);
`;

const UserDetails = () => {
  const { t } = useTranslation();
  const { id } = useParams<RouteParams>();
  const location = useLocation();
  const { authUser } = useAuth();
  const { data, isLoading, refetch } = useFetchUserQuery(parseInt(id, 10), [
    'avatar-image-path',
    'nursing-homes',
    'partner',
    'partner-prospects',
  ]);
  const hasAccessToNursing = useMemo(() => {
    return !(
      data?.profile === Profiles.PARTNER_ADMIN ||
      authUser?.profile === Profiles.PARTNER_ADMIN
    );
  }, [authUser?.profile, data?.profile]);
  const canBeAssignNursingOrProspect = useMemo(() => {
    return (
      data?.profile !== 'ORIZON_ADMINISTRATOR' &&
      data?.profile !== 'PARTNER_ADMINISTRATOR'
    );
  }, [data?.profile]);
  if (location.pathname === generatePath(Routes.USERS_DETAIL, { id })) {
    return <Redirect to={generatePath(Routes.USERS_DETAIL_GENERAL, { id })} />;
  }
  return (
    <Container fluid className="h-100 p-0">
      {canBeAssignNursingOrProspect && (
        <UserHorizontalMenu isPartner={!hasAccessToNursing} />
      )}
      <Wrapper className="mt-4">
        <Link as={Link} to={Routes.USERS} className="d-flex px-3 text-decoration-none text-reset">
          <ChevronLeft />
          {t('pages.userDetails.backToUsers')}
        </Link>
        {!isLoading ? (
          data && (
            <Switch>
              <PrivateRoute path={Routes.USERS_DETAIL_GENERAL}>
                <UserGeneral user={data} callback={refetch} />
              </PrivateRoute>
              {canBeAssignNursingOrProspect && (
                <>
                  {hasAccessToNursing ? (
                    <PrivateRoute path={Routes.USERS_DETAIL_NURSINGS}>
                      <UserNursingHome user={data} callback={refetch} />
                    </PrivateRoute>
                  ) : (
                    <PrivateRoute path={Routes.USERS_DETAIL_PROSPECTS}>
                      <UserProspect user={data} />
                    </PrivateRoute>
                  )}
                </>
              )}
            </Switch>
          )
        ) : (
          <></>
        )}
      </Wrapper>
    </Container>
  );
};

export default UserDetails;
