import { createContext, useContext } from 'react';
import { PartnerProspect } from 'src/sdk/partner-prospect';
import { Prospect } from 'src/sdk/prospect';

export enum NursingHomeCreationStep {
  DeliveryAddressSelection = 'DeliveryAddressSelection',
  NursingHomeAccount = 'NursingHomeAccount',
  DirectorAccount = 'DirectorAccount',
}

export type NursingHomeCreationSteps = Partial<
  Record<NursingHomeCreationStep, number>
>;

type NursingHomeCreationContextProps = {
  prospect?: Prospect | PartnerProspect;
  currentStep: number;
  nursingHomeCreationSteps: NursingHomeCreationSteps;
};

export const NursingHomeCreationContext =
  createContext<NursingHomeCreationContextProps>({
    prospect: undefined,
    currentStep: 0,
    nursingHomeCreationSteps: {
      DeliveryAddressSelection: 0,
      NursingHomeAccount: 1,
      DirectorAccount: 2,
    },
  });

export function useNursingHomeCreation() {
  return useContext(NursingHomeCreationContext);
}
