import { ReactNode } from 'react';
import { Warning } from 'src/assets/list-icons';
import DefaultModal from 'src/components/Misc/Modal/DefaultModal';
import styled from 'styled-components/macro';

interface Props {
  children: ReactNode;
  isVisible: boolean;
  onClose: () => void;
}

const WrapperIcon = styled.div``;

const UserConfirmationModal = ({ children, isVisible, onClose }: Props) => {
  return (
    <DefaultModal
      isVisible={isVisible}
      handleClose={onClose}
      header={
        <WrapperIcon>
          <Warning />
        </WrapperIcon>
      }
    >
      {children}
    </DefaultModal>
  );
};

export default UserConfirmationModal;
