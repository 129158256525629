import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Routes from 'src/router/Routes';
import HorizontalMenu, { HorizontalMenuItems } from 'src/uikit/HorizontalMenu';

const FirmwaresHorizontalMenu = () => {
  const { t } = useTranslation();

  const MenuItem: HorizontalMenuItems[] = useMemo(() => {
    return [
      {
        routes: Routes.FIRMWARES_OVERVIEW,
        label: t('overview'),
      },
      {
        routes: Routes.FIRMWARES_DEPLOY,
        label: t('deploy'),
      },
      {
        routes: Routes.FIRMWARES_UPLOAD,
        label: t('upload'),
      },
    ];
  }, [
    t,
  ]);

  return <HorizontalMenu menuItems={MenuItem} />;
};

export default FirmwaresHorizontalMenu;
