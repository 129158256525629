import { useQuery } from 'react-query';
import request from 'src/axios';
import {
  GetPartnerProspectsResponse,
  PartnerProspect,
  PartnerProspectExpand,
} from 'src/sdk/partner-prospect';

export type PartnerProspectsSortField = {
  label: keyof PartnerProspect;
  direction: 'asc' | 'desc';
};

type QueryParams = {
  page: number;
  size: number;
  expand: PartnerProspectExpand[];
  searchFilter?: string;
  sort: PartnerProspectsSortField;
  withoutNursingHome?: boolean;
};

const fetchPartnerProspects = async ({
  page,
  size,
  expand,
  sort,
  searchFilter,
  withoutNursingHome,
}: QueryParams) => {
  const { data } = await request.get('/partner-prospects', {
    params: {
      page,
      size,
      expand: expand.join(','),
      sort: `${sort.label},${sort.direction}`,
      'name-or-identifier-part': searchFilter,
      'without-nursing-home': withoutNursingHome,
    },
  });

  return data;
};

const useFetchPartnerProspectsQuery = (
  page: number,
  size: number,
  expand: PartnerProspectExpand[],
  sort: PartnerProspectsSortField,
  searchFilter?: string,
  withoutNursingHome?: boolean,
) => {
  const { data, isLoading, refetch, error } = useQuery<
    GetPartnerProspectsResponse,
    Error
  >(['partnersList', { page, size, searchFilter, expand, sort }], () =>
    fetchPartnerProspects({
      page,
      size,
      searchFilter,
      expand,
      sort,
      withoutNursingHome,
    }),
  );

  return {
    data,
    isLoading,
    refetch,
    error,
  };
};

export default useFetchPartnerProspectsQuery;
