import { Container } from 'react-bootstrap';
import { useAuth } from 'src/contexts/Auth';
import { Profiles } from 'src/sdk/authentification';
import { Status } from 'src/sdk/equipment';
import { EquipmentMetrics } from 'src/sdk/provisioning';
import styled from 'styled-components/macro';

import ProvisioningMetricCard from './EquipmentMetricCard';

interface Props {
  metrics?: EquipmentMetrics;
  filterValue?: Status;
  onFilterBy: (value: Status) => void;
}

const Wrapper = styled(Container)`
  display: flex;
  gap: 1rem;
`;

const ProvisioningMetricList = ({
  metrics,
  filterValue,
  onFilterBy,
}: Props) => {
  const { authUser } = useAuth();
  return (
    <Wrapper fluid className="justify-content-center align-items-center">
      {authUser?.profile === Profiles.ORIZON_ADMIN && (
        <>
          <ProvisioningMetricCard
            status={Status.ORDERED}
            metric={metrics?.orderedCount}
            selected={filterValue === Status.ORDERED}
            onClick={() => onFilterBy(Status.ORDERED)}
          />

          <ProvisioningMetricCard
            status={Status.IN_TRANSIT}
            metric={metrics?.inTransitCount}
            selected={filterValue === Status.IN_TRANSIT}
            onClick={() => onFilterBy(Status.IN_TRANSIT)}
          />
          <ProvisioningMetricCard
            status={Status.IN_STOCK}
            metric={metrics?.inStockCount}
            selected={filterValue === Status.IN_STOCK}
            onClick={() => onFilterBy(Status.IN_STOCK)}
          />
        </>
      )}
      <ProvisioningMetricCard
        status={Status.SENT}
        metric={metrics?.sentCount}
        selected={filterValue === Status.SENT}
        onClick={() => onFilterBy(Status.SENT)}
      />
      <ProvisioningMetricCard
        status={Status.IN_USE}
        metric={metrics?.inUseCount}
        selected={filterValue === Status.IN_USE}
        onClick={() => onFilterBy(Status.IN_USE)}
      />
    </Wrapper>
  );
};

export default ProvisioningMetricList;
