import { useQuery, QueryObserverOptions } from 'react-query';
import request from 'src/axios';
import { GetProductsResponse, Product } from 'src/sdk/product';

export type ProspectsSortField = {
  label: keyof Product;
  direction: 'asc' | 'desc';
};

type QueryParams = {
  page: number;
  size: number;
  color?: string;
  drops?: string;
  brand?: string;
  productSize?: string;
  nursingHomeId?: number;
  partnerId?: number;
};

const fetchProducts = async ({
  page,
  size,
  brand,
  color,
  drops,
  productSize,
  nursingHomeId,
  partnerId,
}: QueryParams) => {
  const { data } = await request.get('/products', {
    params: {
      page,
      size,
      brand,
      color,
      drops,
      'product-size': productSize,
      ...(nursingHomeId && { 'nursing-home-id': nursingHomeId }),
      ...(partnerId && { 'partner-id': partnerId }),
    },
  });

  return data;
};

const useFetchProductsQuery = (
  params: QueryParams,
  options?: QueryObserverOptions<GetProductsResponse, Error>,
) => {
  const { data, isLoading, refetch, error } = useQuery<
    GetProductsResponse,
    Error
  >(['productsList', { ...params }], () => fetchProducts(params), {
    ...options,
    keepPreviousData: true,
  });

  return {
    data,
    isLoading,
    refetch,
    error,
  };
};

export default useFetchProductsQuery;
