import { useState } from 'react';
import ShippingRequestExpandableItem from 'src/components/NursingHome/ShippingRequest/ShippingRequestExpandableItem';
import { ShippingRequestResponse } from 'src/sdk/shippingRequest';
import { List } from 'src/uikit/Layout/List';

export enum ShippingRequestMode {
  MOVEMENT,
  HANDLING_UNIT,
}

type ShippingRequestExpandableListProps = {
  shippingRequests?: ShippingRequestResponse[];
  mode: ShippingRequestMode;
};

const ShippingRequestExpandableList = ({
  shippingRequests,
  mode,
}: ShippingRequestExpandableListProps) => {
  const [selectedId, setSelectedId] = useState<number>();

  const handleClick = (id: number) => {
    setSelectedId(id !== selectedId ? id : undefined);
  };

  return (
    <List className="w-100 mt-4">
      {shippingRequests?.map((shippingRequest) => (
        <ShippingRequestExpandableItem
          key={shippingRequest.id}
          shippingRequest={shippingRequest}
          mode={mode}
          selected={selectedId === shippingRequest.id}
          onClick={handleClick}
        />
      ))}
    </List>
  );
};

export default ShippingRequestExpandableList;
