import { useMutation, useQueryClient } from "react-query";
import request from "src/axios"
import { OrizonError } from "src/sdk/common";

export interface ResetPasswordBody {
  userId: number;
  token: string;
  value: string;
}

export interface ResetPasswordQueryParams {
  simulation?: boolean;
}

export interface ResetPasswordProps {
  body: ResetPasswordBody;
  params?: ResetPasswordQueryParams;
}

const resetpassword = async ({ body, params }: ResetPasswordProps): Promise<void> => {
  const response = await request.post('/users/reset-password', body, {
    params
  })
  const data = response.data;

  return data;
}

const useResetPasswordMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<void, OrizonError, ResetPasswordProps>(resetpassword, {
    onSuccess: () => {
      queryClient.invalidateQueries();
    }
  });

  return mutation;
}

export default useResetPasswordMutation;