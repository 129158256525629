import { useQuery } from 'react-query';
import request from 'src/axios';
import { Timezone } from 'src/sdk/nursingHome';

const useTimeZoneOptions = () => {
  return useQuery<[{ label: string; value: string }], Error>(
    'timezones',
    () =>
      request.get('/time-zones').then((res) =>
        res.data
          .sort((a: Timezone, b: Timezone) => a.offset >= b.offset)
          .map((e: Timezone) => {
            return {
              label: `(GMT ${e.offset > 0 ? '+' : ''}${(
                e.offset / 3600000
              ).toFixed(2)}) ${e.id.replaceAll('_', ' ')}`,
              value: e.id,
            };
          }),
      ),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );
};

export default useTimeZoneOptions;
