import { format } from 'date-fns';
import { Controller, useFormContext } from 'react-hook-form';
import { DATE_BACKEND_FIRST_DAY, DATE_FORMAT_DAY } from 'src/constants';
import 'react-datepicker/dist/react-datepicker.css';
import DateSelector from 'src/uikit/DateSelector';
import ErrorMessage from 'src/uikit/ErrorMessage';
import getNestedProperty from 'src/utils/getNestedProperty';

import { DateSelectorProps } from '../../DateSelector/index';

type FormDateSelectorProps = {
  name: string;
} & Omit<DateSelectorProps, 'onChange'>;

const FormDateSelector = ({
  name,
  dateFormat,
  ...rest
}: FormDateSelectorProps) => {
  const { control, errors } = useFormContext();
  const error = getNestedProperty(errors, name);
  const errorMessage = error?.message;
  const showErrorMode = !!error;
  return (
    <Controller
      name={name}
      control={control}
      render={({ value, onChange }) => (
        <div>
          <DateSelector
            value={new Date(value)}
            dateFormat={dateFormat || DATE_FORMAT_DAY}
            onChange={(value: Date) =>
              onChange(format(value, DATE_BACKEND_FIRST_DAY))
            }
            {...rest}
          />
          {showErrorMode && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </div>
      )}
    />
  );
};

export default FormDateSelector;
