import { Spinner } from 'react-bootstrap';
import styled from 'styled-components/macro';

export const SpinnerLoader = styled(Spinner)`
  color: ${({ theme }) => theme.palette.black[60]};
`;


export const SpinnerLoaderSychronize = styled(Spinner)`
  color: ${({ theme }) => theme.palette.main.primary};
`;