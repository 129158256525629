import { ReactNode } from 'react';
import { Container } from 'react-bootstrap';
import { H5 } from 'src/uikit/Typography';
import styled from 'styled-components/macro';

const Wrapper = styled(Container)`
  display: grid;
  gap: 0.75rem;
  overflow-x: auto;
  padding-block: 1.5rem;
  min-height: 120px;
  padding-inline: 1px;
`;

interface Props {
  children: ReactNode;
  title: string;
}

const UsersSimpleList = ({ children, title }: Props) => {
  return (
    <Wrapper fluid>
      <H5>{title}</H5>
      {children}
    </Wrapper>
  );
};

export default UsersSimpleList;
