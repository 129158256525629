import { Col, Form, Row, Stack } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import useOptionsCountries from 'src/hooks/useOptionsCountries';
import { Button, ButtonColors } from 'src/uikit/Button';
import { FormGroup } from 'src/uikit/Form';
import FormSelectInput from 'src/uikit/Form/SelectInput';
import FormTextInput from 'src/uikit/Form/TextInput';
import styled from 'styled-components/macro';
import * as yup from 'yup';

const FormLabel = styled(Form.Label)`
  font-family: 'Poppins';
  width: 100%;
  font-weight: 600;
  margin-bottom: 1rem;
  text-align: left;
  font-size: 14px;
`;

export const partnerProspectAddressUpsertFormSchema = (t: TFunction) =>
  yup.object({
    name: yup
      .string()
      .min(
        2,
        t(
          'components.PartnerProspects.PartnerProspectDetails.PartnerProspectAddressUpsertModal.PartnerProspectAddressUpsertForm.errorMessage.name',
        ),
      )
      .max(
        35,
        t(
          'components.PartnerProspects.PartnerProspectDetails.PartnerProspectAddressUpsertModal.PartnerProspectAddressUpsertForm.errorMessage.nameMax',
        ),
      )
      .required(),
    street: yup
      .string()
      .min(
        2,
        t(
          'components.PartnerProspects.PartnerProspectDetails.PartnerProspectAddressUpsertModal.PartnerProspectAddressUpsertForm.errorMessage.street',
        ),
      )
      .max(
        35,
        t(
          'components.PartnerProspects.PartnerProspectDetails.PartnerProspectAddressUpsertModal.PartnerProspectAddressUpsertForm.errorMessage.streetMax',
        ),
      )
      .required(),
    zip: yup
      .string()
      .matches(
        /^[0-9]+$/,
        t(
          'components.PartnerProspects.PartnerProspectDetails.PartnerProspectAddressUpsertModal.PartnerProspectAddressUpsertForm.errorMessage.zip',
        ),
      )
      .max(
        9,
        t(
          'components.PartnerProspects.PartnerProspectDetails.PartnerProspectAddressUpsertModal.PartnerProspectAddressUpsertForm.errorMessage.zipMax',
        ),
      )
      .required(),
    town: yup
      .string()
      .min(
        1,
        t(
          'components.PartnerProspects.PartnerProspectDetails.PartnerProspectAddressUpsertModal.PartnerProspectAddressUpsertForm.errorMessage.town',
        ),
      )
      .max(
        35,
        t(
          'components.PartnerProspects.PartnerProspectDetails.PartnerProspectAddressUpsertModal.PartnerProspectAddressUpsertForm.errorMessage.townMax',
        ),
      )
      .required(),
    country: yup
      .string()
      .min(
        1,
        t(
          'components.PartnerProspects.PartnerProspectDetails.PartnerProspectAddressUpsertModal.PartnerProspectAddressUpsertForm.errorMessage.country',
        ),
      )
      
      .required(),
    email: yup
      .string()
      .email(
        t(
          'components.PartnerProspects.PartnerProspectDetails.PartnerProspectAddressUpsertModal.PartnerProspectAddressUpsertForm.errorMessage.emailFormat',
        ),
      )
      .max(
        35,
        t(
          'components.PartnerProspects.PartnerProspectDetails.PartnerProspectAddressUpsertModal.PartnerProspectAddressUpsertForm.errorMessage.emailMax',
        ),
      ),
    telephone: yup.string()
    .max(
      35,
      t(
        'components.PartnerProspects.PartnerProspectDetails.PartnerProspectAddressUpsertModal.PartnerProspectAddressUpsertForm.errorMessage.phoneMax',
      ),
    ),
  });

export type PartnerProspectAddressUpsertFormSchema = yup.InferType<
  ReturnType<typeof partnerProspectAddressUpsertFormSchema>
>;

interface Props {
  isUpdate?: boolean;
  onSubmit: (values: PartnerProspectAddressUpsertFormSchema) => void;
  onCancel: () => void;
}

const PartnerProspectAddressUpsertForm = ({
  onSubmit,
  onCancel,
  isUpdate,
}: Props) => {
  const { t } = useTranslation();
  const { groupedCountriesOptions } = useOptionsCountries();

  const {
    handleSubmit,
    formState: { isValid, isDirty },
  } = useFormContext();

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormLabel>
        {t(
          'components.PartnerProspects.PartnerProspectDetails.PartnerProspectAddressUpsertModal.PartnerProspectAddressUpsertForm.labels.address',
        )}
      </FormLabel>
      <FormGroup>
        <FormTextInput
          name="name"
          placeholder={t(
            'components.PartnerProspects.PartnerProspectDetails.PartnerProspectAddressUpsertModal.PartnerProspectAddressUpsertForm.placeholder.name',
          )}
          width="100%"
        />
      </FormGroup>
      <FormGroup>
        <FormTextInput
          name="street"
          placeholder={t(
            'components.PartnerProspects.PartnerProspectDetails.PartnerProspectAddressUpsertModal.PartnerProspectAddressUpsertForm.placeholder.street',
          )}
          width="100%"
        />
      </FormGroup>
      <Row>
        <Col>
          <FormGroup>
            <FormTextInput
              name="town"
              placeholder={t(
                'components.PartnerProspects.PartnerProspectDetails.PartnerProspectAddressUpsertModal.PartnerProspectAddressUpsertForm.placeholder.city',
              )}
              width="100%"
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <FormTextInput
              name="zip"
              placeholder={t(
                'components.PartnerProspects.PartnerProspectDetails.PartnerProspectAddressUpsertModal.PartnerProspectAddressUpsertForm.placeholder.zip',
              )}
              width="100%"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <FormSelectInput
              name="country"
              placeholder={t(
                'components.PartnerProspects.PartnerProspectsList.PartnerProspectCreateModal.PartnerProspectCreateForm.placeholder.deliveryAddressCountry',
              )}
              options={groupedCountriesOptions}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <FormTextInput
              name="telephone"
              placeholder={t(
                'components.PartnerProspects.PartnerProspectDetails.PartnerProspectAddressUpsertModal.PartnerProspectAddressUpsertForm.placeholder.phone',
              )}
              width="100%"
            />
          </FormGroup>
        </Col>
      </Row>
      <FormGroup>
        <FormTextInput
          placeholder={t(
            'components.PartnerProspects.PartnerProspectDetails.PartnerProspectAddressUpsertModal.PartnerProspectAddressUpsertForm.placeholder.email',
          )}
          name="email"
          width="100%"
        />
      </FormGroup>
      <Stack direction="horizontal" className="justify-content-end">
        <Button background={ButtonColors.CANCEL} onClick={onCancel}>
          {t(
            'components.PartnerProspects.PartnerProspectDetails.PartnerProspectAddressUpsertModal.PartnerProspectAddressUpsertForm.cancel',
          )}
        </Button>
        <Button type="submit" disabled={!isValid && isDirty}>
          {t(
            `components.PartnerProspects.PartnerProspectDetails.PartnerProspectAddressUpsertModal.PartnerProspectAddressUpsertForm.${
              isUpdate ? 'save' : 'create'
            }`,
          )}
        </Button>
      </Stack>
    </Form>
  );
};

export default PartnerProspectAddressUpsertForm;
