import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Redirect, generatePath } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { ReactComponent as PlusBadge } from 'src/assets/plus-badge.svg';
import ListPaginated from 'src/components/Misc/ListPaginated';
import { ShippingRequestMode } from 'src/components/NursingHome/ShippingRequest/ShippingRequestExpandableItem';
import ShippingRequestExpandableList from 'src/components/NursingHome/ShippingRequest/ShippingRequestExpandableList';
import ShippingRequestExpandableListHeader from 'src/components/NursingHome/ShippingRequest/ShippingRequestExpandableListHeader';
import { useAuth } from 'src/contexts/Auth';
import Routes from 'src/router/Routes';
import { Button } from 'src/uikit/Button';
import SearchInput from 'src/uikit/SearchInput';
import { PageWrapper } from 'src/uikit/Wrapper';

import useFetchShipingRequestQuery, {
  ShippingRequestSortField,
} from '../components/NursingHome/ShippingRequest/hooks/useFetchShipingRequestQuery';

const EquipmentShippingRequests = () => {
  const { t } = useTranslation();

  const { nhSession, authUser } = useAuth();

  const [page, setPage] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const { subscription, id } = nhSession || {};
  const { status, type } = subscription || {};
  const { profile } = authUser || {};


  const shouldRenderButton = (profile === 'ORIZON_ADMINISTRATOR' || profile === 'PARTNER_ADMINISTRATOR') || (status === 'ACTIVE' && type === 'STANDARD');

  const [sortField, setSortField] = useState<ShippingRequestSortField>({
    label: 'createdDate',
    direction: 'desc',
  });

  const { data, isLoading, isError } = useFetchShipingRequestQuery({
    page,
    size: 18,
    nhId: nhSession?.id.toString() || '-1',
    expand: [
      'counts',
      'custom-address',
      'handling-units',
      'movements',
      'delivery-address',
      'requester',
    ],
    search: searchValue,
    sortField,
  });

  if (isError) return <Redirect to={Routes.ERROR} />;

  return (
    <PageWrapper>
      <Row
        xs="auto"
        className="align-items-center justify-content-between mb-4"
      >
        <Col md="2">
          <SearchInput onChange={setSearchValue} />
        </Col>
        <Col>
        {
          shouldRenderButton ? (
            <Button
              as={Link}
              to={generatePath(Routes.NURSING_EQUIPMENT_ORDERING_NEW, {
                id: id?.toString() || '-1',
              })}
              className="align-self-end"
            >
              <PlusBadge className="mr-2" />
              {t('components.nursingHome.equipments.order.list.add')}
            </Button>
          ) : null
        }
        </Col>
      </Row>
      <ListPaginated
        header={
          <ShippingRequestExpandableListHeader
            onSortingChange={setSortField}
            sortField={sortField}
          />
        }
        isLoading={isLoading}
        page={page}
        setPage={setPage}
        data={data}
      >
        <ShippingRequestExpandableList
          shippingRequests={data?.content}
          mode={ShippingRequestMode.HANDLING_UNIT}
        />
      </ListPaginated>
    </PageWrapper>
  );
};

export default EquipmentShippingRequests;
