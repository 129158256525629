export default function sortByField<T>(
  list: T[],
  field: keyof T,
  direction?: boolean,
): T[] {
  // Using regex to catch when field is a string like 1.1.5
  //  \d matches a digit (equivalent to [0-9])
  // + matches the previous token between one and unlimited times, as many times as possible, giving back as needed (greedy)
  // 1st Capturing Group (\.\d+)+
  // A repeated capturing group will only capture the last iteration. Put a capturing group around the repeated group to capture all iterations or use a non-capturing group instead if you're not interested in the data
  // \. matches the character . with index 4610 (2E16 or 568) literally (case sensitive)
  // Global pattern flags
  // g modifier: global. All matches (don't return after first match)
  // m modifier: multi line. Causes ^ and $ to match the begin/end of each line (not only begin/end of string)
  const regexp = /\d+(\.\d+){2}/gm;

  return [...list].sort((a, b) => {
    const valueA = a[field];
    const valueB = b[field];

    // To be able to use regex match on generic type, we check if it's a string and then split.
    // It returns arrays of length = 3 and for each we compare a and b to give sort direction
    if (typeof valueA === 'string' && typeof valueB === 'string') {
      const isVersionNumber =
        valueA.match(regexp) !== null && valueB.match(regexp) !== null;
      if (isVersionNumber) {
        const splitA = valueA.split('.');
        const splitB = valueB.split('.');

        for (let i = 0; i < 3; i++) {
          if (splitA[i] < splitB[i]) {
            return direction ? -1 : 1;
          } else if (splitA[i] > splitB[i]) {
            return direction ? 1 : -1;
          }
        }
      }
    }

    // This part is used for every other cases
    if ((a[field] || '') < b[field] || '') {
      return direction ? -1 : 1;
    } else {
      return direction ? 1 : -1;
    }
  });
}
