import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useParams } from 'react-router-dom';
import Routes from 'src/router/Routes';
import { RouteParams } from 'src/sdk/nursingHome';
import HorizontalMenu, { HorizontalMenuItems } from 'src/uikit/HorizontalMenu';

interface Props {
  isPartner: boolean;
}

const UserHorizontalMenu = ({ isPartner }: Props) => {
  const { t } = useTranslation();
  const { id } = useParams<RouteParams>();

  const MenuItem: HorizontalMenuItems[] = useMemo(() => {
    return [
      {
        routes: generatePath(Routes.USERS_DETAIL_GENERAL, { id }),
        label: t('components.Users.UserDetails.UserHorizontalMenu.general'),
      },
      !isPartner
        ? {
            routes: generatePath(Routes.USERS_DETAIL_NURSINGS, {
              id,
            }),
            label: t(
              'components.Users.UserDetails.UserHorizontalMenu.nursings',
            ),
          }
        : {
            routes: generatePath(Routes.USERS_DETAIL_PROSPECTS, {
              id,
            }),
            label: t(
              'components.Users.UserDetails.UserHorizontalMenu.prospects',
            ),
          },
    ];
  }, [id, t, isPartner]);

  return <HorizontalMenu menuItems={MenuItem} />;
};

export default UserHorizontalMenu;
