import { ReactNode } from 'react';
import { Container } from 'react-bootstrap';
import { Card } from 'src/uikit/Card';
import { Text } from 'src/uikit/Typography';
import styled, { css } from 'styled-components/macro';

const Wrapper = styled(Card)<{ selected: boolean }>`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  gap: 1rem;
  overflow: hidden;
  text-align: center;
  background: ${({ theme }) => theme.palette.main.white};
  box-shadow: ${({ theme }) => theme.shadows.sm};
  flex: none;
  order: 2;
  flex-grow: 0;
  ${(props) =>
    props.selected &&
    css`
      border: 2px solid ${({ theme }) => theme.palette.main.primary};
      box-shadow: ${({ theme }) => theme.shadows.md};
    `}

  &:hover {
    border: 2px solid ${({ theme }) => theme.palette.main.primary};
    padding: 1rem;
  }
  width: 140px;
  height: 140px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const NumberStatus = styled(Text)``;

interface Props {
  children: ReactNode;
  metric?: number;
  selected: boolean;
  onClick: () => void;
}

const MetricCardBadge = ({ children, metric, selected, onClick }: Props) => {
  return (
    <>
      <Wrapper selected={selected} onClick={onClick} $clickable>
        {children}
        <Container>
          <Content>
            <NumberStatus>{metric || '-'}</NumberStatus>
          </Content>
        </Container>
      </Wrapper>
    </>
  );
};

export default MetricCardBadge;
