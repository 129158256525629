import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'src/contexts/Auth';
import useLanguageOptions from 'src/hooks/useLanguageOptions';
import useProfileOptions from 'src/hooks/useProfileOptions';
import { Profiles } from 'src/sdk/authentification';
import { Gender } from 'src/sdk/common';
import { Button } from 'src/uikit/Button';
import FormSelectInput from 'src/uikit/Form/SelectInput';
import FormTextInput from 'src/uikit/Form/TextInput';
import styled from 'styled-components/macro';
import * as yup from 'yup';

const SubmitRow = styled(Row)`
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
`;

const InputBlock = styled(Col)``;

const profileFormSchema = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().email().required(),
  profile: yup.string().required(),
  language: yup.string().required(),
  employeeId: yup.string(),
  gender: yup.mixed<Gender>().oneOf(Object.values(Gender)).required(),
});

export type ProfileFormSchema = yup.InferType<typeof profileFormSchema>;

interface Props {
  defaultValues: ProfileFormSchema;
  onSubmit: (values: ProfileFormSchema) => void;
}

const ProfileForm = ({ defaultValues, onSubmit }: Props) => {
  const { authUser } = useAuth();
  const { t } = useTranslation();

  const languageOptions = useLanguageOptions();
  const profileOptions = useProfileOptions();

  const methods = useForm<ProfileFormSchema>({
    resolver: yupResolver(profileFormSchema),
    mode: 'onChange',
  });

  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = methods;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const genderOptions = [
    {
      value: Gender.Male,
      label: t('components.profileForm.gender.male'),
    },
    {
      value: Gender.Female,
      label: t('components.profileForm.gender.female'),
    },
  ];

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)} className="d-flex flex-column">
        <Row>
          <InputBlock md="6">
            <FormTextInput
              name="firstName"
              placeholder={t('components.profileForm.firstName')}
              width="100%"
            />
          </InputBlock>
          <InputBlock md="6">
            <FormTextInput
              name="lastName"
              placeholder={t('components.profileForm.lastName')}
              width="100%"
            />
          </InputBlock>

          <InputBlock md="6">
            <FormSelectInput name="gender" options={genderOptions} />
          </InputBlock>

          {!authUser?.partnerUser && (
            <InputBlock md="6">
              <FormTextInput
                name="employeeId"
                placeholder={t('components.profileForm.employeeId')}
                width="100%"
                disabled={authUser?.profile !== Profiles.ORIZON_ADMIN}
              />
            </InputBlock>
          )}
          <InputBlock md="6">
            <FormSelectInput
              name="language"
              placeholder={t('components.profileForm.language')}
              options={languageOptions}
            />
          </InputBlock>
          <InputBlock md="6">
            <FormTextInput
              name="email"
              placeholder={t('components.profileForm.email')}
              width="100%"
              disabled
            />
          </InputBlock>
          <InputBlock md="6">
            <FormSelectInput name="profile" options={profileOptions} disabled />
          </InputBlock>
        </Row>
        <SubmitRow xs="auto">
          <Col>
            <Button type="submit" disabled={!isDirty}>
              {t('components.profileForm.submitButton')}
            </Button>
          </Col>
        </SubmitRow>
      </Form>
    </FormProvider>
  );
};

export default ProfileForm;
