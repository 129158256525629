import { useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ListPaginated from 'src/components/Misc/ListPaginated';
import Pagination, { DEFAULT_PAGE_SIZE } from 'src/components/Misc/Pagination';
import { useAuth } from 'src/contexts/Auth';
import { Profiles } from 'src/sdk/authentification';
import { UserResponse } from 'src/sdk/users';
import { List } from 'src/uikit/Layout/List';
import SearchInput from 'src/uikit/SearchInput';
import { H3 } from 'src/uikit/Typography';
import { PageWrapper } from 'src/uikit/Wrapper';
import { paginate } from 'src/utils/paginate';
import styled from 'styled-components/macro';

import useFetchProspectsQuery from '../../../Prospects/hooks/useFetchProspectsQuery';
import FilterProductSpecialist from '../../FilterProductSpecialist';
import ProspectCard from '../components/ProspectCard';

const PAGE_SIZE = DEFAULT_PAGE_SIZE;

const ListTitle = styled(H3)`
  color: ${({ theme }) => theme.palette.black.strongGrey};
  text-align: center;
`;

const NursingHomeCreationListOntex = () => {
  const { authUser } = useAuth();
  const { t } = useTranslation();

  const [filterValue, setFilter] = useState<UserResponse | undefined>();
  const [page, setPage] = useState(0);

  const [searchValue, setSearchValue] = useState('');

  const userId = useMemo(() => {
    return (
      (authUser?.profile === Profiles.ORIZON_ADMIN
        ? filterValue?.id
        : authUser?.id) || -1
    );
  }, [authUser?.id, authUser?.profile, filterValue]);

  const { data, isLoading } = useFetchProspectsQuery(
    {
      userId,
    },
    {
      enabled: Boolean(userId !== -1),
    },
  );

  const filteredProspects = useMemo(() => {
    return data?.filter(
      (prospect) =>
        prospect.name.toLowerCase().includes(searchValue.toLowerCase()) ||
        prospect.id.toLowerCase().includes(searchValue.toLowerCase()),
    );
  }, [data, searchValue]);

  const paginatedProspects = useMemo(() => {
    return paginate(page, PAGE_SIZE, filteredProspects);
  }, [filteredProspects, page]);

  return (
    <PageWrapper>
      <ListTitle>{t('components.listNursingHome.otherList')}</ListTitle>
      <Row className="align-items-center justify-content-center">
        <Col md="10" xl="5">
          <Row className="align-items-center justify-content-center">
            {authUser?.profile === Profiles.ORIZON_ADMIN && (
              <Col xs="10" md="6" className="mt-2 mt-xl-4">
                <FilterProductSpecialist
                  onChangeFilterValue={(filterValue) => setFilter(filterValue)}
                />
              </Col>
            )}
            <Col xs="10" md="6" className="mt-2 mt-xl-4">
              <SearchInput
                onChange={(searchValue) => setSearchValue(searchValue)}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <ListPaginated
        isLoading={isLoading}
        empty={!data || data?.length === 0}
        page={page}
        setPage={setPage}
      >
        <List
          columnSize={{ xs: '250px', xl: '350px' }}
          className="mx-2 mt-4"
          gap="1.5rem"
        >
          {paginatedProspects?.map((prospect, i) => {
            return <ProspectCard prospect={prospect} />;
          })}
        </List>
      </ListPaginated>

      {(filteredProspects?.length || 0) > PAGE_SIZE && (
        <Pagination
          totalElements={filteredProspects?.length || 0}
          perPage={PAGE_SIZE}
          onPageChange={setPage}
          currentPage={page}
        />
      )}
    </PageWrapper>
  );
};

export default NursingHomeCreationListOntex;
