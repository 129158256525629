import { AxiosResponse } from 'axios';
import { useState, useEffect, useCallback } from 'react';
import { Col, Image, ModalFooter, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { ReactComponent as EditIcon } from 'src/assets/edit-badge.svg';
import { ReactComponent as UploadIcon } from 'src/assets/upload-image-default.svg';
import request from 'src/axios';
import { Avatar, BlobAvatar } from 'src/sdk/avatar';
import { Gender } from 'src/sdk/common';
import { Button } from 'src/uikit/Button';
import { SpinnerLoader } from 'src/uikit/SpinnerLoader';
import { fontPrimary } from 'src/uikit/theme/typography';
import styled, { css } from 'styled-components/macro';

import DefaultModal from './DefaultModal';
import ModalUploadAvatar from './ModalUploadAvatar';

const RowUpload = styled(Row)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

`;


const ImageContainer = styled(Row)`
  width: 30%;
`;

const Footer = styled(ModalFooter)`
  border: none;
`;

const EditBadge = styled(EditIcon)`
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.main.primary};
  color: ${({ theme }) => theme.palette.main.white};
  width: 40px;
  height: 40px;
  padding: 12px;

  position: relative;
  right: 0px;
  bottom: 30px;
`;

const SelectImage = styled(Image)<{ selected: boolean }>`
  border: none;
  border-radius: 50%;
  ${({ selected, theme }) =>
    selected &&
    css`
      border: 4px solid ${theme.palette.main.primary};
    `}
`;

const LabelUpdate = styled(Row)`
  justify-content: center;
  text-transform: uppercase;
  ${fontPrimary}
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.5;
`;

interface Props {
  show: boolean;
  onClose: () => void;
  userId: number;
  role: string;
  partnerUser: any,
  gender: Gender;
  preview?: string;
  onChangeAvatar: (value: number) => void;
  onUploadAvatar: (value: string) => void;
}



const ModalEditAvatar = ({
  show,
  onClose,
  gender,
  preview,
  role,
  partnerUser,
  onChangeAvatar,
  onUploadAvatar,

}: Props) => {
  const { t } = useTranslation();
  const [showModal = false, setShowModal] = useState<boolean>();

  const [upload = preview, setUpload] = useState<string>();
  const [selected = -1, setSelected] = useState<number>();



  /**
   * Get the avatar profile corresponding to the given role.
   * @returns {string}
   */
  const avatarProfile = (): string => {

    const roleMapping: { [key: string]: string } = {
      'CAREGIVER': 'NURSE',
      'NURSE': 'NURSE',
      'HEAD_NURSE': 'NURSE',
      'DIRECTOR': 'DIRECTOR',
      'NURSING_HOME': 'ONTEX',
      'PARTNER_ADMINISTRATOR': 'PARTNER',
      'ORIZON_ADMINISTRATOR': 'ONTEX',
      'SAP': 'ONTEX',
      'TTN': 'ONTEX',
      'B2B': 'ONTEX',
      'PRODUCT_SPECIALIST': partnerUser ? 'PARTNER' : 'ONTEX',
      'SALES_ASSISTANT': partnerUser ? 'PARTNER' : 'ONTEX',
    };
  
    const defaultRole = 'DEFAULT_VALUE';

    // Retrieve the avatar value corresponding to the given role, or return the default value
    return roleMapping[role] || defaultRole;
  };  

  const { data, isLoading, refetch } = useQuery('common-avatars', async () => {
    return request
      .get(`/common-avatars?gender=${gender}&avatar-profile=${avatarProfile()}`)
      .then(async (response) => {        
        const avatars = response.data.content;
        let queries: Promise<AxiosResponse>[] = [];
        avatars.forEach((avatar: Avatar) => {
          queries.push(
            request.get(`/common-avatars/${avatar.id}/image`, {
              responseType: 'blob',
            }),
          );
        });
        
        
        const results = await Promise.all(queries);
        const tmp: BlobAvatar[] = avatars.map(
          (avatar: Avatar, index: number) => {
            return {
              ...avatar,
              blob: URL.createObjectURL(results[index].data),
            };
          },
        );
        return [...tmp];
      });
  });

  useEffect(() => {
    refetch();
  }, [gender, refetch]);

  const selectCommonAvatar = useCallback(
    (index: number) => {
      if (data) {
        setUpload(data[index].blob);
        setSelected(data[index].id);
      }
    },
    [data],
  );

  const handleContinueUpload = useCallback((preview: string) => {
    setUpload(preview);
    setSelected(-1);
    setShowModal(false);
  }, []);

  const onSubmit = async () => {
    if (selected !== -1) {
      return await onChangeAvatar(selected);
    }

    if (upload) {      
      onUploadAvatar(upload);
    }
  };

  return (
    <>
      <DefaultModal size="lg" isVisible={show} handleClose={onClose}>
        <RowUpload>
          {upload ? (
            <ImageContainer>
              <Image
                src={upload}
                width={119}
                height={109}
                onClick={() => setShowModal(true)}
                fluid 
              />
            </ImageContainer>
          ) : (
            <UploadIcon onClick={() => setShowModal(true)} />
          )}
          <EditBadge onClick={() => setShowModal(true)} />
        </RowUpload>
        <LabelUpdate>
          <p>{t('components.modalEditAvatar.labelUpdate')}</p>
        </LabelUpdate>
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center">
            <SpinnerLoader animation="border" />
          </div>
        ) : (
          <Row>
            {data?.map((avatar: BlobAvatar, index) => (
              <Col sm={4} key={index} className='d-flex justify-content-center'>
                <SelectImage
                  src={avatar.blob}
                  width={109}
                  height={109}
                  onClick={() => selectCommonAvatar(index)}
                  selected={avatar.id === selected}
                  fluid
                />
              </Col>
            ))}
          </Row>
        )}
        <Footer>
          <Button onClick={onClose}>{t('common.action.cancel')}</Button>
          <Button onClick={onSubmit}>{t('common.action.save')}</Button>
        </Footer>
      </DefaultModal>
      <ModalUploadAvatar
        show={showModal}
        onClose={() => setShowModal(false)}
        onContinue={handleContinueUpload}
      />
    </>
  );
};

export default ModalEditAvatar;
