import { Controller, useFormContext } from 'react-hook-form';
import { BlobAvatar } from 'src/sdk/avatar';
import { SpinnerLoader } from 'src/uikit/SpinnerLoader';
import palette from 'src/uikit/theme/palette';

type Props = {
  avatars: BlobAvatar[];
  name: string;
  defaultValue?: number;
  isLoading?: boolean;
};

const AvatarInput = ({ avatars, name, defaultValue, isLoading }: Props) => {
  const { control } = useFormContext();

  return (
    <>
      {isLoading && (
        <div className="d-flex justify-content-center align-items-center">
          <SpinnerLoader animation="border" />
        </div>
      )}
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ value, onChange }) => (
          <ul
            style={{
              listStyle: 'none',
              display: 'flex',
              justifyContent: 'center',
              flexWrap: 'wrap',
            }}
          >
            {avatars &&
              avatars.map((avatar: BlobAvatar) => (
                <li
                  style={{
                    ...(avatar.id === value && {
                      outline: `solid white 4px`,
                    }),
                    borderRadius: '100%',
                    cursor: 'pointer',
                    marginRight: '12px',
                    marginBottom: '12px',
                    width: '120px',
                  }}
                  key={avatar.id}
                >
                  <img
                    style={{
                      borderRadius: '100%',
                      ...(avatar.id === value && {
                        outline: `solid ${palette.main.primary} 6px`,
                      }),
                    }}
                    src={avatar.blob}
                    alt="avatar"
                    onClick={() => onChange(avatar.id)}
                  />
                </li>
              ))}
          </ul>
        )}
      />
    </>
  );
};

export default AvatarInput;
