import { Profiles } from './authentification';

export type Language = 'en' | 'de' | 'fr' | 'nl_BE' | 'it' | 'es';

/**
 * Generic type for paginated content returned by the backend.
 * Not all attributes are currently declared in this interface.
 * Feel free to declare additional attributes as needed.
 */
export interface GetResponse<T> {
  totalElements: number;
  totalPages: number;
  sort?: {
    empty?: boolean;
    sorted?: boolean;
    unsorted?: boolean;
  };
  size?: number;
  content: T[];
  number?: number;
  numberOfElements?: number;
  first: boolean;
  last: boolean;
  pageable?: {
    sort?: {
      empty?: boolean;
      sorted?: boolean;
      unsorted?: boolean;
    };
    offset?: number;
    paged: boolean;
    pageSize: number;
    unpaged: boolean;
    pageNumber: number;
  };
  empty: boolean;
  grandTotal?: number;
}

export interface Response {
  id: number;
}

interface OrizonErrorDetails {
  size: number;
  value: undefined;
}

export interface OrizonError {
  timestamp: string;
  status: number;
  error: string;
  code: string;
  message: string;
  details: OrizonErrorDetails;
  username: string;
  profile: Profiles;
  path: string;
  clientPath: string;
}

export interface Address {
  name: string;
  street: string;
  zip: string;
  town: string;
  country: string;
  email: string;
  shipTo?: string;
  telephone: string;
}

export enum Gender {
  Male = 'M',
  Female = 'F',
}
