import axios from 'axios';

import { Config } from './config';

const request = axios.create({
  baseURL: Config.api_url,
  withCredentials: true,
});

request.interceptors.request.use(
  (config) => {
    const userData = localStorage.getItem('CURRENT_USER');
    if (userData) {
      const token = JSON.parse(userData).token;
      if (token && token !== '') {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }

    return config;
  },
  (error) => {
    return error;
  },
);

request.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log(error);
    if (error.response.status === 403) {
      throw error.response.data;
    } else if (error.response.status === 400) {
      throw error.response.data;
    } else if (error.response.status === 500) {
      throw error.response.data;
    } else if (error.response.status === 404) {
      throw error.response.data;
    }
    // TODO understand why silent error

    return error;
  },
);

export default request;
