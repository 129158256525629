import { useTranslation } from 'react-i18next';
import { SubscriptionResponse } from 'src/sdk/subscription';

export type SubscriptionTypesOption = {
  label: string;
  value: SubscriptionResponse['type'];
};

const useSubscriptionTypesOptions = (subscriptionTypesAsked?: string[]) => {
  const { t } = useTranslation();
  const subscriptionTypes = [
    {
      label: t(`common.subscription.DEMO`),
      value: 'DEMO',
    },
    {
      label: t(`common.subscription.STANDARD`),
      value: 'STANDARD',
    },
  ];

  return subscriptionTypesAsked && subscriptionTypesAsked.length > 1
    ? subscriptionTypes.filter((profile) =>
        subscriptionTypesAsked.find(
          (profileAsked) => profileAsked === profile.value,
        ),
      )
    : subscriptionTypes;
};

export default useSubscriptionTypesOptions;
