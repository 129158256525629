import { useQuery } from 'react-query';
import request from 'src/axios';

import { Prospect } from '../../../../sdk/prospect';

type FetchPartnerProspectsParams = {
  id: number;
};

const fetchPartnerProspects = async ({ id }: FetchPartnerProspectsParams) => {
  const { data } = await request.get(`/partner-prospects/${id}`);

  return data;
};

type FetchProspectsParams = {
  soldTo: string;
  salesOrg: string;
};

const fetchProspects = async ({ soldTo, salesOrg }: FetchProspectsParams) => {
  const { data } = await request.get(
    `/prospects/${soldTo}?sales-org=${salesOrg}`,
  );

  return data;
};

const useFetchConfigurationProspectsQuery = (
  type: 'PartnerProspect' | 'Prospect',
  params: FetchProspectsParams | FetchPartnerProspectsParams,
) => {
  const { data, isLoading, refetch, error } = useQuery<Prospect, Error>(
    ['prospect', { ...params }],
    () =>
      type === 'PartnerProspect'
        ? fetchPartnerProspects(params as FetchPartnerProspectsParams)
        : fetchProspects(params as FetchProspectsParams),
  );

  return {
    data,
    isLoading,
    refetch,
    error,
  };
};

export default useFetchConfigurationProspectsQuery;
