import { AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import request from 'src/axios';
import {
  PartnerCreationResponse,
  PartnerCreationRequest,
} from 'src/sdk/partner';


/**
 * Generates a custom hook for creating a partner.
 *
 * @return {Object} An object containing the `createPartnerMutation` function.
 */
const useCreatePartnerMutation = () => {
  const queryClient = useQueryClient();

  const createPartnerMutation = useMutation<
    AxiosResponse<PartnerCreationResponse>,
    Error,
    PartnerCreationRequest
  >((params) => request.post('/partners', params), {
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
  });

  return { createPartnerMutation };
};

export default useCreatePartnerMutation;
