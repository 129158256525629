import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useParams } from 'react-router-dom';
import Routes from 'src/router/Routes';
import { RouteParams } from 'src/sdk/nursingHome';
import HorizontalMenu, { HorizontalMenuItems } from 'src/uikit/HorizontalMenu';

const PartnerProspectHorizontalMenu = () => {
  const { t } = useTranslation();
  const { id } = useParams<RouteParams>();

  const MenuItem: HorizontalMenuItems[] = useMemo(() => {
    return [
      {
        routes: generatePath(Routes.PARTNERS_PROSPECT_GENERAL, { id }),
        label: t(
          'components.PartnerProspects.PartnerProspectDetails.PartnerProspectHorizontalMenu.general',
        ),
      },
      {
        routes: generatePath(Routes.PARTNERS_PROSPECT_DELIVERY_ADDRESSES, {
          id,
        }),
        label: t(
          'components.PartnerProspects.PartnerProspectDetails.PartnerProspectHorizontalMenu.addresses',
        ),
      },
      {
        routes: generatePath(Routes.PARTNERS_PROSPECT_ASSIGNED_PS, { id }),
        label: t(
          'components.PartnerProspects.PartnerProspectDetails.PartnerProspectHorizontalMenu.assigned',
        ),
      },
    ];
  }, [t, id]);

  return <HorizontalMenu menuItems={MenuItem} />;
};

export default PartnerProspectHorizontalMenu;
