import { AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import request from 'src/axios';


const useTerminateSubscription = (id: any) => {
  const queryClient = useQueryClient();
  const terminateSubscription = useMutation<AxiosResponse<any>, Error>(
    () => {
      return request.post(`/subscriptions/${id}/terminate`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
      },
    },
  );
  return { terminateSubscription: terminateSubscription };
};

export default useTerminateSubscription;
