import {
  Col,
  FormControl,
  FormControlProps,
  InputGroup,
} from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { P, Placeholder } from 'src/uikit/Typography';
import styled, { css } from 'styled-components/macro';

const PlaceholderArea = styled(Placeholder)<{
  hasValue: boolean;
  background?: string;
}>`
  position: absolute;
  top: 9px;
  left: 10px;
  transition: 200ms;
  pointer-events: none;
  color: ${({ theme }) => theme.palette.black[90]};
  margin: 0 5px 0;
  padding: 4px;

  // For too long placeholders, eg. in german.
  max-width: 160px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  color: hsl(0, 0%, 50%);

  // To keep placeholder on top, when input unselected but completed.
  ${({ hasValue, background, theme }) =>
    hasValue &&
    css`
      transform: translate3d(-4px, -24px, 0);
      color: ${theme.palette.main.primary};
      background-blend-mode: screen; // for Edge & Chrome, to avoid bold text on transition

      &::before {
        background: ${theme.palette.main.white};
        z-index: -1;
        content: '';
        display: block;
        height: 3px;
        width: 100%;
        position: absolute;
        top: 13px;
        left: 0;
      }
    `}
`;

const StyledInput = styled(FormControl)<{ background?: string }>`
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  position: relative;
  border-radius: 10px;
  border: none;
  background-color: transparent;
  resize: none;
  width: 100%;

  &.is-invalid,
  &:invalid {
    box-shadow: none !important;
  }

  &:focus,
  &:valid {
    z-index: 0 !important;
    background-color: transparent;
  }

  &:disabled {
    background-color: transparent;
    border-color: ${({ theme }) => theme.palette.black[60]};
    color: ${({ theme }) => theme.palette.black[60]};

    & + ${PlaceholderArea} {
      color: ${({ theme }) => theme.palette.black[60]};
    }
  }

  :focus {
    border-color: ${({ theme }) => theme.palette.main.primary};
    color: ${({ theme }) => theme.palette.black.main};
    transition: box-shadow 0.15s ease-in-out;
    box-shadow: none;
  }

  :focus + ${PlaceholderArea} {
    transform: translate3d(-4px, -24px, 0);
    color: ${({ theme }) => theme.palette.main.primary};
    background-blend-mode: screen; // for Edge & Chrome, to avoid bold text on transition
    &::before {
      background: ${({ theme }) => theme.palette.main.white};
      z-index: -1;
      content: '';
      display: block;
      height: 3px;
      width: 100%;
      position: absolute;
      top: 13px;
      left: 0;
    }
  }

  :focus-visible {
    border: none;
    outline: none;
  }

  :error  {
    background-color: ${({ theme }) => theme.palette.main.greenApple};
  }
`;

const Wrapper = styled(InputGroup)<{ invalid: boolean; width: string }>`
  transition: border-color 0.15s ease-in-out;
  border: 1px solid
    ${({ invalid, theme }) =>
      invalid ? theme.palette.main.red : theme.palette.black[50]};
  border-radius: 10px;
  background-color: ${({ theme }) => theme.palette.main.white};
  display: flex;
  align-items: center;

  :focus-within {
    border: 1px solid
      ${({ invalid, theme }) =>
        invalid ? theme.palette.main.red : theme.palette.main.primary};
    outline: 0;
  }

  :focus-visible {
    border: 1px solid
      ${({ invalid, theme }) =>
        invalid ? theme.palette.main.red : theme.palette.main.primary};
    outline: 0;
  }
`;

const ErrorMessage = styled(P)`
  color: ${({ theme }) => theme.palette.main.red};
`;

const StyledCol = styled(Col)`
  padding: 0;
`;

interface Props extends FormControlProps {
  name: string;
  placeholder?: string;
  maxLength?: number;
  backgroundParent?: string;
  width?: string;
  rows?: number;
  defaultValue?: string;
}

const FormTextArea = ({
  name,
  placeholder,
  maxLength,
  disabled,
  backgroundParent,
  width,
  rows,
  defaultValue,
  ...others
}: Props) => {
  const { control, errors } = useFormContext();
  const errorMessage = errors[name]?.message;
  const showErrorMode = !!errors[name];

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ value, onChange }) => (
        <StyledCol>
          <Wrapper
            invalid={showErrorMode && !!errorMessage ? 1 : 0}
            width={width}
          >
            {' '}
            {/* cannot used pure boolean because it generates a console warning */}
            <StyledInput
              {...others}
              as="textarea"
              maxLength={maxLength}
              rows={rows}
              value={value}
              onChange={onChange}
              disabled={disabled}
              background={backgroundParent}
            />
            <PlaceholderArea
              hasValue={!!value && value?.length > 0}
              background={backgroundParent}
            >
              {placeholder}
            </PlaceholderArea>
          </Wrapper>
          {showErrorMode && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </StyledCol>
      )}
    />
  );
};

export default FormTextArea;
