import { useTranslation } from 'react-i18next';
import { Language } from 'src/sdk/common';
import { Option } from 'src/uikit/SelectInput';
import styled from 'styled-components/macro';

import {
  BeFlag,
  DeFlag,
  FrFlag,
  ItFlag,
  EnFlag,
  EsFlag,
} from '../assets/flags';

const StyledLabel = styled.div`
  display: flex;
  gap: 0.25rem;
`;

export const languages: Language[] = [
  'de',
  'en',
  'es',
  'fr',
  'it',
  'nl_BE',
];

const useLanguageOptions = () => {
  const { t } = useTranslation();

  const options: (Option & { value: Language })[] = [
    {
      label: (
        <StyledLabel className="d-flex flex-row align-items-center">
          <EnFlag />
          {t('common.language.EN')}
        </StyledLabel>
      ),
      value: 'en',
    },
    {
      label: (
        <StyledLabel className="d-flex flex-row align-items-center">
          <DeFlag />
          {t('common.language.DE')}
        </StyledLabel>
      ),
      value: 'de',
    },
    {
      label: (
        <StyledLabel className="d-flex flex-row align-items-center">
          <FrFlag />
          {t('common.language.FR')}
        </StyledLabel>
      ),
      value: 'fr',
    },
    {
      label: (
        <StyledLabel className="d-flex flex-row align-items-center">
          <BeFlag />
          {t('common.language.NL')}
        </StyledLabel>
      ),
      value: 'nl_BE',
    },
    {
      label: (
        <StyledLabel className="d-flex flex-row align-items-center">
          <ItFlag />
          {t('common.language.IT')}
        </StyledLabel>
      ),
      value: 'it',
    },
    {
      label: (
        <StyledLabel className="d-flex flex-row align-items-center">
          <EsFlag />
          {t('common.language.ES')}
        </StyledLabel>
      ),
      value: 'es',
    },
  ];

  return options;
};

export default useLanguageOptions;
