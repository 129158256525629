import { QueryObserverOptions, useQuery } from 'react-query';
import request from 'src/axios';

const fetchSecurityMe = async () => {
  const { data } = await request.get(`/security/me`);

  return data;
};

const useFetchSecurityMe = (options?: QueryObserverOptions<any, Error>) => {
  const { data, isLoading, refetch, error } = useQuery<any, Error>(
    [`securityMe`],
    () => fetchSecurityMe(),
    {
      ...(options || {}),
    },
  );

  return {
    data,
    isLoading,
    refetch,
    error,
  };
};

export default useFetchSecurityMe;
