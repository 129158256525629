import { useEffect, useMemo, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import BackToLogin from 'src/components/Auth/BackToLogin';
import EmailVerificationFailed from 'src/components/Auth/EmailVerification/EmailVerificationFailed';
import EmailVerificationStart from 'src/components/Auth/EmailVerification/EmailVerificationStart';
import EmailVerificationSuccess from 'src/components/Auth/EmailVerification/EmailVerificationSuccess';
import useVerifyEmailMutation from 'src/components/Auth/hooks/useVerifyMailMutation';

const EmailVerification = () => {
  const [loading, setLoading] = useState(true);
  const [expiredToken, setExpiredToken] = useState(false);
  const location = useLocation();

  const { userId, token } = useMemo(() => {
    const searchParam = new URLSearchParams(location.search);
    return {
      userId: searchParam.get('user-id') || '-1',
      token: searchParam.get('token') || '',
    };
  }, [location.search]);

  const { startPasswordResetMutation } = useVerifyEmailMutation();

  const onClick = async () => {
    if (userId && token !== '') {
      try {
        await startPasswordResetMutation({
          body: {
            userId: Number(userId),
            token,
          },
        });
        setExpiredToken(false);
      } catch (err) {
        setExpiredToken(true);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const simulate = async () => {
      if (userId && token !== '') {
        try {
          await startPasswordResetMutation({
            body: {
              userId: Number(userId),
              token,
            },
            config: {
              params: {
                simulation: true,
              },
            },
          });

          setExpiredToken(false);
        } catch (err) {
          setExpiredToken(true);
        }
      }
    };
    simulate();
  }, [startPasswordResetMutation, token, userId]);

  return (
    <Row>
      {!loading ? (
        !expiredToken ? (
          <EmailVerificationSuccess />
        ) : (
          <EmailVerificationFailed userId={userId} />
        )
      ) : (
        <EmailVerificationStart onClick={onClick} />
      )}
      <BackToLogin />
    </Row>
  );
};

export default EmailVerification;
