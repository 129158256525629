import { AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import request from 'src/axios';
import {
  ScheduleConfigurationResponse,
  ScheduleConfigurationParametersRequest,
} from 'src/sdk/schedule';

const useScheduleConfigurations = (id : number) => {
  const queryClient = useQueryClient();

  const scheduleConfiguration = useMutation<
    AxiosResponse<ScheduleConfigurationResponse>,
    Error,
    ScheduleConfigurationParametersRequest
  >(
    (body) => {
      return request.put(`nursing-homes/${id}/protocol-configuration`, body);
    },
    {
        /**
         * Executes the onSuccess callback.
         *
         * @return {void} 
        */
      onSuccess: () => {
        queryClient.invalidateQueries();
      },
    },
  );

  return { scheduleConfiguration };
};

export default useScheduleConfigurations;