import { yupResolver } from '@hookform/resolvers/yup';
import { format } from 'date-fns';
import { useEffect, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import { ChevronLeft } from 'src/assets/arrows';
import { DATE_BACKEND, DATE_FORMAT_DAY } from 'src/constants';
import { SubscriptionCreationRequest } from 'src/sdk/subscription';
import { Button } from 'src/uikit/Button';
import ButtonIcon from 'src/uikit/ButtonIcon';
import CounterInput from 'src/uikit/Form/CounterInput';
import FormDateSelector from 'src/uikit/Form/DateSelector';
import FormSwitchInput from 'src/uikit/Form/SwitchInput';
import { Label, P } from 'src/uikit/Typography';
import { FormButtonsWrapper } from 'src/uikit/Wrapper';
import { getStartingDate } from 'src/utils/getDate';
import styled from 'styled-components/macro';
import * as yup from 'yup';

import SubscriptionPlanInput from '../components/SubscriptionPlanInput';
import { useSubscriptionUpsert } from '../SubscriptionContext';

const StyledRow = styled(Row)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 0rem;
  text-align: left;
`;

const CounterRow = styled(StyledRow)``;
const NoShippingRow = styled(StyledRow)``;
const StartingDateRow = styled(StyledRow)`
  align-items: start;

  label {
    margin-top: 0.5rem;
  }
`;

const ErrorMessage = styled(P)`
  color: ${({ theme }) => theme.palette.main.red};
  text-align: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 10%;
  align-items: center;
`;

const PreviousInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
  gap: 10px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;

  color: #b5b5be;
`;

const subscriptionCreatePlanSchema = (t: TFunction) =>
  yup.object({
    type: yup.mixed<SubscriptionCreationRequest['type']>().required(),
    automaticRenewal: yup.boolean(),
    residentEstimate: yup.number().when('type', {
      is: 'DEMO',
      then: (schema) => schema,
      otherwise: (schema) =>
        schema.min(
          20,
          t(
            'components.subscriptionCreation.plan.errorMessage.residentEstimateMin',
          ),
        ),
    }),
    startDate: yup.string(),
    noShippingRequest: yup.boolean(),
    duration: yup.number().required(),
  });

export type SubscriptionCreatePlanSchema = yup.InferType<
  ReturnType<typeof subscriptionCreatePlanSchema>
>;

interface Props {
  data: SubscriptionCreationRequest;
  previousData?: SubscriptionCreationRequest;
  onSuccess: (data: Partial<SubscriptionCreationRequest>) => void;
  onCancel: () => void;
}

const SubscriptionCreatePlan = ({
  data,
  previousData,
  onSuccess,
  onCancel,
}: Props) => {
  const { currentConfiguration, configurations, changeConfiguration } =
    useSubscriptionUpsert();
  const { t } = useTranslation();

  const defaultValues: SubscriptionCreatePlanSchema = useMemo(() => {
    return {
      type: data.type || 'STANDARD',
      automaticRenewal: data.type !== 'DEMO' ? data.automaticRenewal : false,
      residentEstimate: data.residentEstimate,
      startDate: format(
        new Date(
          Math.max(
            new Date(data.startDate || '').getTime(),
            new Date(getStartingDate()).getTime(),
          ),
        ).setHours(0),
        DATE_BACKEND,
      ),
      duration: data.type === 'STANDARD' ? data.duration * 12 : 1,
      noShippingRequest: data.noShippingRequest,
    };
  }, [
    data.automaticRenewal,
    data.duration,
    data.noShippingRequest,
    data.residentEstimate,
    data.startDate,
    data.type,
  ]);

  const methods = useForm<SubscriptionCreatePlanSchema>({
    resolver: yupResolver(subscriptionCreatePlanSchema(t)),
    mode: 'onChange',
    defaultValues,
  });

  const { formState, handleSubmit, errors, setValue, watch, reset } = methods;
  const { isValid } = formState;

  useEffect(() => {
    reset(defaultValues);
  }, [data, defaultValues, reset]);

  const type = watch('type');

  useEffect(() => {
    changeConfiguration(type);
    if (type === 'DEMO') {
      setValue('residentEstimate', 15);
      setValue('duration', 1);
      setValue('automaticRenewal', false);
    } else {
      setValue('residentEstimate', 20);
      setValue('duration', 24);
      setValue('automaticRenewal', true);
    }
  }, [changeConfiguration, setValue, type]);

  const onSubmit = async (formData: SubscriptionCreatePlanSchema) => {
    onSuccess({
      type: formData.type,
      duration:
        formData.type === 'STANDARD'
          ? formData?.duration / 12
          : formData.duration,
      automaticRenewal: formData?.automaticRenewal,
      residentEstimate: formData.residentEstimate,
      startDate: formData.startDate,
      noShippingRequest: formData.noShippingRequest,
    });
  };

  // for subscription update : compute current end date to display it.
  const currentEndDate = useMemo(() => {
    if (!previousData) {
      return;
    }
    const current = new Date(previousData?.startDate);
    if (previousData.type === 'DEMO') {
      current.setMonth(current.getMonth() + previousData?.duration);
    } else {
      current.setFullYear(current.getFullYear() + previousData?.duration / 12);
    }
    return format(current, DATE_FORMAT_DAY);
  }, [previousData]);
  const elements = useMemo(() => {
    return [
      {
        name: t('components.subscriptionCreation.plan.trial.name'),
        type: 'DEMO' as SubscriptionCreationRequest['type'],
        durations: [{ value: 1, label: '1 months' }],
        showRenewal: false,
        features: [
          t('components.subscriptionCreation.plan.trial.department'),
          t('components.subscriptionCreation.plan.trial.residents'),
          t('components.subscriptionCreation.plan.trial.webAppAccess'),
          t('components.subscriptionCreation.plan.trial.mobileAppAccess'),
          t('components.subscriptionCreation.plan.trial.liveAlerts'),
          t('components.subscriptionCreation.plan.trial.bodyPosition'),
          t('components.subscriptionCreation.plan.trial.insights'),
          t('components.subscriptionCreation.plan.trial.support'),
        ],
        featuresAll: false,
        disabled: !!previousData,
      },
      {
        name: t('components.subscriptionCreation.plan.starter.name'),
        type: 'STANDARD' as SubscriptionCreationRequest['type'],
        durations: [
          { value: 24, label: '2 years' },
          { value: 36, label: '3 years' },
          { value: 60, label: '5 years' },
        ],
        showRenewal: true,
        features: [
          t('components.subscriptionCreation.plan.starter.residents'),
          t('components.subscriptionCreation.plan.starter.webAppAccess'),
          t('components.subscriptionCreation.plan.starter.mobileAppAccess'),
          t('components.subscriptionCreation.plan.starter.liveAlerts'),
          t('components.subscriptionCreation.plan.starter.bodyPosition'),
          t('components.subscriptionCreation.plan.starter.insights'),
          t('components.subscriptionCreation.plan.starter.support'),
          t('components.subscriptionCreation.plan.starter.software'),
          t('components.subscriptionCreation.plan.starter.hardware'),
        ],
        featuresAll: false,
      },
    ].filter(
      (element) =>
        configurations?.findIndex(
          (configuration) => element.type === configuration.type,
        ) !== -1,
    );
  }, [configurations, previousData, t]);

  return (
    <FormProvider {...methods}>
      <Label className="my-4">
        {t('components.subscriptionCreation.plan.title')}
      </Label>
      <Form onSubmit={handleSubmit(onSubmit)} id="subscription-plan-form">
        <SubscriptionPlanInput
          name="type"
          elements={elements}
          defaultValue={data.type}
        />

        <CounterRow>
          <Col md="6">
            <label>
              {t('components.subscriptionCreation.plan.numberOfResidents')}
            </label>
          </Col>
          <Col md="3">
            {previousData && (
              <PreviousInfo>
                {t('components.subscriptionCreation.plan.currentSubs')}{' '}
                {previousData.residentEstimate}
              </PreviousInfo>
            )}
          </Col>
          <Col md="3" className="d-flex justify-content-end">
            {currentConfiguration?.type === 'DEMO' ? (
              <CounterInput
                name="residentEstimate"
                step={1}
                min={currentConfiguration?.residentEstimate}
                max={currentConfiguration?.residentEstimate}
              />
            ) : (
              <CounterInput
                name="residentEstimate"
                step={1}
                min={currentConfiguration?.minimumResidentEstimate}
              />
            )}
          </Col>
        </CounterRow>
        <ErrorMessage>{errors?.residentEstimate?.message}</ErrorMessage>
        {currentConfiguration?.supportNoShippingRequest && (
          <NoShippingRow>
            <Col md="6">
              <label>
                {t('components.subscriptionCreation.plan.noShippingRequests')}
              </label>
            </Col>
            <Col md="3" className="d-flex justify-content-end">
              <FormSwitchInput name="noShippingRequest" />
            </Col>
          </NoShippingRow>
        )}
        <StartingDateRow>
          <Col md="6">
            <label>
              {t('components.subscriptionCreation.plan.startingDate')}
            </label>
          </Col>
          <Col md="3">
            {currentEndDate && (
              <PreviousInfo>
                {t('components.subscriptionCreation.plan.currentEndDate')} :{' '}
                {currentEndDate}
              </PreviousInfo>
            )}
          </Col>
          <Col md="3">
            <FormDateSelector
              name="startDate"
              minDate={new Date(getStartingDate())}
              placeholder="Starting date"
              dateFormat={DATE_FORMAT_DAY}
            />
          </Col>
        </StartingDateRow>
      </Form>
      <FormButtonsWrapper>
        <ButtonIcon prepend={<ChevronLeft />} handleClick={onCancel} />
        <Button disabled={!isValid} form="subscription-plan-form" type="submit">
          {t('common.action.next')}
        </Button>
      </FormButtonsWrapper>
    </FormProvider>
  );
};

export default SubscriptionCreatePlan;
