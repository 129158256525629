export type Shadow = 'md' | 'xs' | 'sm' | 'xl' | 'lg' | 'inner';

const shadowColor = '0deg 0% 70%';

export const shadows: { [key in Shadow]: string } = {
  xs: `0.3px 0.4px 0.6px hsl(${shadowColor} / 0.33),
  1.2px 1.6px 3px -2px hsl(${shadowColor} / 0.41);`,
  sm: `0.3px 0.4px 0.6px hsl(${shadowColor} / 0.33),
  2.2px 2.5px 5.1px -1px hsl(${shadowColor} / 0.41);`,
  md: `0.3px 0.4px 0.6px hsl(${shadowColor} / 0.31),
  1.3px 2.2px 3.1px -1px hsl(${shadowColor} / 0.36),
  4.6px 7.8px 10.9px -2px hsl(${shadowColor} / 0.41);`,
  lg: `
  0.3px 0.4px 0.6px hsl(${shadowColor} / 0.32),
  2.3px 3.9px 5.4px -0.5px hsl(${shadowColor} / 0.37),
  5.1px 8.6px 12px -1px hsl(${shadowColor} / 0.41),
  10.6px 17.9px 25px -1.5px hsl(${shadowColor} / 0.46);`,
  xl: `
  0.3px 0.4px 0.6px hsl(${shadowColor} / 0.34),
  2.3px 3.9px 5.4px -0.5px hsl(${shadowColor} / 0.37),
  5.1px 8.6px 12px -1px hsl(${shadowColor} / 0.41),
  10.6px 17.9px 25px -1.5px hsl(${shadowColor} / 0.44),
  20.6px 34.9px 48.6px -2px hsl(${shadowColor} / 0.48);`,
  inner: `
  inset 0 0.4px 0.6px 0 hsl(${shadowColor} / 0.33),
  inset 0 1.4px 2.2px -1px hsl(${shadowColor} / 0.38),
  inset 0 3.4px 4.5px -2px hsl(${shadowColor} / 0.43);
  `,
};
