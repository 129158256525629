import { useTranslation } from 'react-i18next';

const useLocalDate = () => {
  const { i18n } = useTranslation();

  const stringToDate = (value: string) => {
    if (!value) {
      return '-';
    }

    return new Intl.DateTimeFormat(i18n.language).format(new Date(value));
  };

  return { stringToDate };
};

export default useLocalDate;
