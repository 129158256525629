import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import EquipmentBadgeCol from 'src/components/Equipment/EquipmentBadgeCol';
import SortingButton from 'src/components/SortingHeaders/SortingButton';
import { ProvisioningCommonDetails } from 'src/sdk/provisioning';
import styled from 'styled-components/macro';

const StyledRow = styled(Row)`
  padding-inline: 1rem;
`;

const StyledCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export type ChargingStationsSortField = {
  label: keyof ProvisioningCommonDetails;
  direction: 'asc' | 'desc';
};

interface Props {
  global?: boolean;
}

const EquipmentChargingHeader = ({ global }: Props) => {
  const { t } = useTranslation();

  return (
    <StyledRow>
      <EquipmentBadgeCol />
      {!global ? (
        <>
          <StyledCol md="4">
            <SortingButton
              primary
              label={t('components.sortingHeader.sortByName')}
              field="name"
              disableSort
            />
          </StyledCol>
          <StyledCol md="6">
            <SortingButton
              primary
              label={t('components.sortingHeader.sortBySSCC')}
              field="deviceId"
              disableSort
            />
          </StyledCol>
        </>
      ) : (
        <>
          <StyledCol md="2">
            <SortingButton
              primary
              label={t('components.sortingHeader.sortByName')}
              field="name"
              disableSort
            />
          </StyledCol>
          <StyledCol md="3">
            <SortingButton
              primary
              label={t('components.sortingHeader.sortByNumber')}
              field="serialNumber"
              disableSort
            />
          </StyledCol>
          <StyledCol md="2">
            <SortingButton
              primary
              label={t('components.sortingHeader.sortByStatus')}
              field="equipmentStatus"
              disableSort
            />
          </StyledCol>
          <StyledCol md="3">
            <SortingButton
              primary
              label={t('components.sortingHeader.sortBySSCC')}
              field="deviceId"
              disableSort
            />
          </StyledCol>
        </>
      )}
    </StyledRow>
  );
};

export default EquipmentChargingHeader;
