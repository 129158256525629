import { createContext, FC, useContext } from 'react';
import useFetchCurrentPartnerQuery from 'src/components/Partners/hooks/useFetchCurrentPartnerQuery';
import { Partner } from 'src/sdk/partner';

import { useAuth } from './Auth';

type PartnerContextProps = {
  partner?: Partner;
};

export const PartnerContext = createContext<Partial<PartnerContextProps>>({});

export const PartnerProvider: FC = ({ children }) => {
  const { authUser } = useAuth();

  const { data: partner } = useFetchCurrentPartnerQuery(
    { expand: ['delivery-addresses'] },
    {
      enabled: Boolean(authUser?.partnerUser),
    },
  );

  return (
    <PartnerContext.Provider value={{ partner }}>
      {children}
    </PartnerContext.Provider>
  );
};

export function usePartner() {
  return useContext(PartnerContext);
}
