import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PostalIcon } from 'src/assets/postal.svg';
import useToast from 'src/hooks/useToast';
import { PartnerProspectDeliveryAddressResponse } from 'src/sdk/partner-prospect';
import { Button, ButtonColors } from 'src/uikit/Button';
import { P } from 'src/uikit/Typography';
import styled from 'styled-components/macro';

import useDeletePartnerProspectAddressMutation from '../../hooks/useDeletePartnerProspectAddressMutation';
import PartnerProspectModal from '../../PartnerProspectModal';

const StyledMessageWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: start-end;
  gap: 5px;
`;

const StyledButtonsWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
`;

interface Props {
  partnerProspectId: number;
  address: PartnerProspectDeliveryAddressResponse | null;
  show: boolean;
  onFinish: () => void;
}

const PartnerProspectAddressDeleteModal = ({
  partnerProspectId,
  address,
  show,
  onFinish,
}: Props) => {
  const { t } = useTranslation();
  const { toastSuccess, toastError } = useToast();

  const { deletePartnerProspectAddressMutation } =
    useDeletePartnerProspectAddressMutation();

  const onClick = async () => {
    if (!address) {
      return;
    }

    try {
      await deletePartnerProspectAddressMutation.mutateAsync({
        partnerProspectId,
        id: address.id,
      });

      toastSuccess(
        t(
          'components.PartnerProspects.PartnerProspectDetails.PartnerProspectAddressDeleteModal.success',
        ),
      );
      handleClose();
    } catch (err: any) {
      if (err.code) {
        return toastError(t(`errorsApi.${err.code}`));
      }
      toastError(
        t(
          'components.PartnerProspects.PartnerProspectDetails.PartnerProspectAddressDeleteModal.error',
        ),
      );
    }
  };

  const handleClose = useCallback(() => {
    onFinish?.();
  }, [onFinish]);

  return (
    <PartnerProspectModal
      title={t(
        'components.PartnerProspects.PartnerProspectDetails.PartnerProspectAddressDeleteModal.title',
        { value: address?.name },
      )}
      icon={<PostalIcon />}
      show={Boolean(address && show)}
      handleClose={handleClose}
    >
      <StyledMessageWrapper>
        <P>
          {t(
            'components.PartnerProspects.PartnerProspectDetails.PartnerProspectAddressDeleteModal.content',
          )}
        </P>
      </StyledMessageWrapper>

      <StyledButtonsWrapper>
        <Button background={ButtonColors.CANCEL} onClick={handleClose}>
          {t('common.action.cancel')}
        </Button>
        <Button onClick={onClick}>
          {t(
            'components.PartnerProspects.PartnerProspectDetails.PartnerProspectAddressDeleteModal.delete',
          )}
        </Button>
      </StyledButtonsWrapper>
    </PartnerProspectModal>
  );
};

export default PartnerProspectAddressDeleteModal;
