import { useState } from 'react';
import { Col, Row, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Check } from 'src/assets/list-icons';
import { ShippingRequest } from 'src/assets/list-icons';
import { ReactComponent as PostalIcon } from 'src/assets/postal.svg';
import request from 'src/axios';
import AddressCard from 'src/components/Misc/Card/AddressCard';
import useFormatErrorApi from 'src/hooks/useFormatErrorApi';
import useToast from 'src/hooks/useToast';
import { Partner, PartnerAddressesResponse } from 'src/sdk/partner';
import { Button } from 'src/uikit/Button';
import { Card, CardBody, CardHeader } from 'src/uikit/Card';
import { List } from 'src/uikit/Layout/List';
import { H3, Label, Strong, Text } from 'src/uikit/Typography';
import styled from 'styled-components/macro';

const WrapperIcon = styled.div`
  background-color: ${({ theme }) => theme.palette.main.cyan};
  padding: 0.5rem;
  border-radius: 50%;
  width: 40px;
  height: 40px;

  & > * {
    color: white;
  }
`;

type Props = {
  partner: Partner;
};

const PartnerGeneralSynchronize = ({ partner }: Props) => {
  const { t } = useTranslation();
  const { formatErrorApi } = useFormatErrorApi();

  const [isSynchronize, setIsSynchronize] = useState(false);

  const { toastError, toastSuccess } = useToast();

  const [deliveryAddresses, setDeliveryAddresses] = useState<
    Partner['deliveryAddresses']
  >(partner.deliveryAddresses);

  const onSynchronize = async () => {
    try {
      const { data } = await request.get<PartnerAddressesResponse>(
        `/partners/${partner.id}/update-addresses`,
      );
      setDeliveryAddresses(data.deliveryAddresses);
      toastSuccess(t('components.partners.details.general.form.synchronizeAddress'));
      setIsSynchronize(true);
    } catch (err) {
      const message = formatErrorApi(err);
      toastError(message);
    }
  };

  return (
    <Card>
      <CardHeader>
        <Stack direction="horizontal" gap={2}>
          <WrapperIcon>
            <PostalIcon />
          </WrapperIcon>
          <H3 className="ml-2">
            {t('components.partners.details.general.addresses.address.title')}
          </H3>
        </Stack>
      </CardHeader>
      <CardBody>
        <Row>
          <Col md="12" className="mt-2">
            <Stack direction="horizontal" gap={2}>
              <Label>{t('common.address.name')} :</Label>
              <Text>{partner.address?.name}</Text>
            </Stack>
          </Col>
          <Col md="12" className="mt-2">
            <Stack direction="horizontal" gap={2}>
              <Label>{t('common.address.street')} :</Label>
              <Text>{partner.address?.street}</Text>
            </Stack>
          </Col>
          <Col md="4" className="mt-2">
            <Stack direction="horizontal" gap={2}>
              <Label>{t('common.address.city')} :</Label>
              <Text>{partner.address?.town}</Text>
            </Stack>
          </Col>
          <Col md="4">
            <Stack direction="horizontal" gap={2}>
              <Label>{t('common.address.zip')} :</Label>
              <Text>{partner.address?.zip}</Text>
            </Stack>
          </Col>
          <Col md="4">
            <Stack direction="horizontal" gap={2}>
              <Label>{t('common.address.country')} :</Label>
              <Text>{partner.address?.country}</Text>
            </Stack>
          </Col>
          <Col md="6" className="mt-2">
            <Stack direction="horizontal" gap={2}>
              <Label>{t('common.address.email')} :</Label>
              <Text>{partner.address?.email}</Text>
            </Stack>
          </Col>
          <Col md="6" className="mt-2">
            <Stack direction="horizontal" gap={2}>
              <Label>{t('common.address.phone')} :</Label>
              <Text>{partner.address?.telephone}</Text>
            </Stack>
          </Col>
        </Row>
      </CardBody>
      <CardHeader className="mt-4">
        <Stack direction="horizontal" gap={2}>
          <ShippingRequest />
          <H3 className="ml-2">
            {t(
              'components.partners.details.general.addresses.deliveryAddress.title',
            )}
          </H3>
        </Stack>
      </CardHeader>
      <CardBody>
        <Stack className="text-start">
          <List columnSize={'300px'}>
            {deliveryAddresses?.map((deliveryAddress) => (
              <AddressCard
                key={deliveryAddress.id}
                disabled
                border
                address={deliveryAddress}
              />
            ))}
          </List>
          <Stack
            className="my-4 justify-content-center align-items-center"
            direction="horizontal"
            gap={1}
          >
            <Strong>
              {t(
                'components.partners.details.general.addresses.deliveryAddress.changeAddress',
              )}
            </Strong>
            <Button
              className="ml-2"
              onClick={onSynchronize}
              disabled={isSynchronize}
            >
              {t(
                'components.partners.details.general.addresses.deliveryAddress.synchronizeAddress',
              )}
            </Button>
            {isSynchronize && <Check />}
          </Stack>
        </Stack>
      </CardBody>
    </Card>
  );
};

export default PartnerGeneralSynchronize;
