import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import SortingButton from 'src/components/SortingHeaders/SortingButton';
import styled from 'styled-components/macro';

const StyledRow = styled(Row)``;

const StyledCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledColIcon = styled(StyledCol)`
  flex: 0 0 40px;
`;

const SortingHandlingUnitsHeader = () => {
  const { t } = useTranslation();

  return (
    <StyledRow>
      <StyledColIcon />
      <StyledCol md="2">
        <SortingButton
          primary
          label={t('components.sortingHeader.sortBySentOn')}
          field="sentOn"
          disableSort
        />
      </StyledCol>
      <StyledCol md="2">
        <SortingButton
          primary
          label={t('components.sortingHeader.sortByReceptionDate')}
          field="receptionDate"
          disableSort
        />
      </StyledCol>
      <StyledCol md="2">
        <SortingButton
          primary
          label={t('components.sortingHeader.sortBySSCC')}
          field="sscc"
          disableSort
        />
      </StyledCol>
      <StyledCol md="2">
        {/* <SortingButton
          primary
          label={t('components.sortingHeader.sortByTrackingNumber')}
          field="trackingNumber"
          disableSort
        /> */}
      </StyledCol>
      <StyledCol md="2">
        {/* <SortingButton
          primary
          label={t('components.sortingHeader.sortByParcelService')}
          field="parcelService"
          disableSort
        /> */}
      </StyledCol>
    </StyledRow>
  );
};

export default SortingHandlingUnitsHeader;
