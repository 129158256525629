import { AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import request from 'src/axios';
import { UpdateUserRequest, UpdateUserResponse } from 'src/sdk/users';

const useUpdateUserMutation = () => {
  const queryClient = useQueryClient();

  const updateUserMutation = useMutation<
    AxiosResponse<UpdateUserResponse>,
    Error,
    UpdateUserRequest & {
      id: number;
    }
  >(({ id, ...params }) => request.put(`/users/${id}`, params), {
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({
        queryKey: `user${data.id}`,
      });
    },
  });

  return { updateUserMutation };
};

export default useUpdateUserMutation;
