import { useState } from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PlusBadge } from 'src/assets/plus-badge.svg';
import Pagination, { DEFAULT_PAGE_SIZE } from 'src/components/Misc/Pagination';
import PartnerProspectsList from 'src/components/PartnerProspects/PartnerProspectsList';
import PartnerProspectsCreateModal from 'src/components/PartnerProspects/PartnerProspectsList/PartnerProspectCreateModal';
import SortingPartnerProspectsHeader from 'src/components/SortingHeaders/SortingPartnerProspectsHeader';
import { useModal } from 'src/hooks/useModal';
import { AlertCard } from 'src/uikit/AlertCard';
import ButtonIcon from 'src/uikit/ButtonIcon';
import SearchInput from 'src/uikit/SearchInput';
import { H1 } from 'src/uikit/Typography';
import styled from 'styled-components/macro';

import useFetchPartnerProspectsQuery, {
  PartnerProspectsSortField,
} from '../components/PartnerProspects/hooks/useFetchPartnerProspectsQuery';

const Wrapper = styled(Container)`
  display: grid;
  gap: 2rem;
  padding: 40px;
  overflow-x: auto;
`;

const Title = styled(H1)`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
`;

const SearchColumn = styled(Col)`
  display: flex;
  justify-content: flex-start;
`;

const ButtonColumn = styled(Col)`
  display: flex;
  justify-content: flex-end;
`;

const ListWrapper = styled(Container)`
  padding-left: 0;
  padding-right: 0;
`;

const PAGE_SIZE = DEFAULT_PAGE_SIZE;

const PartnerProspects = () => {
  const { t } = useTranslation();
  const { modalOpen, setModalOpen } = useModal();
  const [page, setPage] = useState(0);

  const [searchFilter, setSearchFilter] = useState<string>();

  const handleChangeSearch = (value: string) => {
    setSearchFilter(value);
  };

  const [sort, setSort] = useState<PartnerProspectsSortField>({
    label: 'name',
    direction: 'desc',
  });

  const { data, isLoading, refetch } = useFetchPartnerProspectsQuery(
    page,
    PAGE_SIZE,
    ['subscription', 'counts', 'nursing-home'],
    sort,
    searchFilter,
  );

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleFinishCreation = () => {
    closeModal();
    refetch();
  };

  const handleCancel = () => {
    closeModal();
  };

  return (
    <Wrapper fluid>
      <Title>{t('pages.partnersProspects.title')}</Title>
      <Row className="justify-content-between">
        <SearchColumn md="2">
          <SearchInput onChange={handleChangeSearch} />
        </SearchColumn>
        <ButtonColumn md="3">
          <ButtonIcon
            prepend={<PlusBadge />}
            handleClick={() => setModalOpen(true)}
            label={t('pages.partnersProspects.partnerButton')}
          />
        </ButtonColumn>
      </Row>

      <ListWrapper fluid>
        <SortingPartnerProspectsHeader
          onSortingChange={setSort}
          sortField={sort}
        />
        {data?.empty && <AlertCard>{t('components.list.noResult')}</AlertCard>}
        {isLoading && (
          <div className="d-flex justify-content-center align-items-center">
            <Spinner animation="border" variant="secondary" />
          </div>
        )}
        <PartnerProspectsList partners={data?.content} />
        {data && data.totalPages > 1 && (
          <Pagination
            totalElements={data.totalElements}
            perPage={PAGE_SIZE}
            onPageChange={setPage}
            currentPage={page}
          />
        )}
      </ListWrapper>

      <PartnerProspectsCreateModal
        onCancel={handleCancel}
        onFinish={handleFinishCreation}
        show={modalOpen}
      />
    </Wrapper>
  );
};

export default PartnerProspects;
