import { Stack } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import palette from 'src/uikit/theme/palette';
import { fontSecondary } from 'src/uikit/theme/typography';
import { H2, Label } from 'src/uikit/Typography';
import styled from 'styled-components/macro';

import Counter from '../Counter';

const Wrapper = styled('div')<{ color: string }>`
  border-radius: 0.75rem;
  min-width: 300px;
  max-width: 300px;

  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0.75rem;
  gap: 0.5rem;
  border: 1px solid ${({ color }) => color};

  ${Label} {
    color: ${({ color }) => color};
  }
`;

const WrapperCounter = styled(Stack)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.75rem;
  flex-direction: row;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`;

const Header = styled(Stack)`
  display: flex;
  align-items: center;
  padding: 0;
  gap: 16px;
  flex-direction: row;
`;

const ItemTitle = styled(H2)`
  ${fontSecondary}
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.black[90]};
  margin: 0;
`;

const CounterWrapper = styled(Stack)`
  justify-content: end;
  flex-direction: row;
`;

interface Props {
  name: string;
  title: string;
  badge: any;
  mainColor?: string;
  secondaryColor?: string;
  disabled?: boolean;
  hidden?: boolean;
  label: string;
  max?: number;
  current?: number;
  defaultValue?: number;
  step?: number;
}

const CounterEquipmentInputNew = ({
  name,
  title,
  badge,
  mainColor,
  secondaryColor,
  disabled,
  hidden,
  label,
  max,
  defaultValue,
  step = 1,
}: Props) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue || 0}
      render={({ value, onChange, ref }) => (
        <Wrapper color={mainColor || palette.main.primary}>
          <WrapperCounter>
            <Header>
              {badge}
              <ItemTitle>{title}</ItemTitle>
            </Header>
            {!disabled && (
              <CounterWrapper>
                <Counter
                  name={name}
                  buttonsColor={mainColor}
                  bgColor={secondaryColor}
                  hidden={hidden}
                  min={0}
                  max={max}
                  step={step}
                  value={value}
                  onChange={(value) => onChange(value)}
                />
              </CounterWrapper>
            )}
          </WrapperCounter>
          <Label>{label}</Label>
        </Wrapper>
      )}
    />
  );
};

export default CounterEquipmentInputNew;
