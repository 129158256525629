import { Tooltip as TooltipBoostrap } from 'react-bootstrap';
import styled from 'styled-components/macro';

export const Tooltip = styled(TooltipBoostrap)`
  color: white;

  .tooltip-inner {
    background: ${({ theme }) => theme.palette.main.primary};
    max-width: 250px;
  }

  .arrow,
  .arrow:before {
    border-top-color: ${({ theme }) => theme.palette.main.primary};
  }
`;
