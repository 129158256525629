import { Container } from 'react-bootstrap';
import {
  generatePath,
  Redirect,
  Switch,
  useLocation,
  useParams,
} from 'react-router-dom';
import PartnerGeneral from 'src/components/Partners/Details/General';
import PartnerHeader from 'src/components/Partners/Details/PartnerHeader';
import PartnerHorizontalMenu from 'src/components/Partners/Details/PartnerHorizontalMenu';
import PartnerProducts from 'src/components/Partners/Details/Products';
import PrivateRoute from 'src/router/PrivateRoute';
import Routes from 'src/router/Routes';
import { RouteParams } from 'src/sdk/nursingHome';

import useFetchPartnerQuery from '../components/Partners/hooks/useFetchPartnerQuery';

const Partner = () => {
  const { id } = useParams<RouteParams>();
  const location = useLocation();

  const { data, isLoading } = useFetchPartnerQuery(id, [
    'delivery-addresses',
    'address',
  ]);

  if (location.pathname === generatePath(Routes.PARTNER, { id })) {
    return <Redirect to={generatePath(Routes.PARTNER_GENERAL, { id })} />;
  }

  return (
    <Container fluid className="p-0">
      <PartnerHorizontalMenu />
      <Container fluid>
        <PartnerHeader title={data?.name || ''} />
        {!isLoading ? (
          data && (
            <Switch>
              <PrivateRoute path={Routes.PARTNER_GENERAL}>
                <PartnerGeneral partner={data} />
              </PrivateRoute>
              <PrivateRoute path={Routes.PARTNER_PRODUCTS}>
                <PartnerProducts partner={data} />
              </PrivateRoute>
            </Switch>
          )
        ) : (
          <></>
        )}
      </Container>
    </Container>
  );
};

export default Partner;
