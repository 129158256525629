import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ShippingRequestSortField } from 'src/components/NursingHome/ShippingRequest/hooks/useFetchShipingRequestQuery';
import SortingButton from 'src/components/SortingHeaders/SortingButton';
import { sortByDirection } from 'src/utils/sortByDirection';
import styled from 'styled-components/macro';

const StyledRow = styled(Row)`
  display: flex;
  align-items: center;
  width: 100%;
  border: none !important;
  padding: 0 1.25rem;
`;

const StyledColIcon = styled(Col)`
  flex: 0 0 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
`;
interface Props {
  onSortingChange: React.Dispatch<
    React.SetStateAction<ShippingRequestSortField>
  >;
  sortField: ShippingRequestSortField;
}

const ShippingRequestExpandableListHeader = ({
  onSortingChange,
  sortField,
}: Props) => {
  const { t } = useTranslation();

  const handleSortClick = (label: ShippingRequestSortField['label']) => {
    sortByDirection(label, sortField, onSortingChange);
  };

  return (
    <StyledRow>
      <StyledColIcon />
      <StyledCol md="1">
        <SortingButton
          primary
          label={t('components.sortingHeader.sortById')}
          field="sentOn"
          onClick={() => handleSortClick('id')}
        />
      </StyledCol>
      <StyledCol md="2">
        <SortingButton
          primary
          label={t('components.sortingHeader.sortByCreationDate')}
          field="sentOn"
          onClick={() => handleSortClick('createdDate')}
        />
      </StyledCol>
      <StyledCol md="2">
        <SortingButton
          primary
          label={t('components.sortingHeader.sortByRequestDeliveryDate')}
          field="sentOn"
          disableSort
        />
      </StyledCol>
    </StyledRow>
  );
};

export default ShippingRequestExpandableListHeader;
