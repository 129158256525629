import { yupResolver } from '@hookform/resolvers/yup';
import { setHours, setMinutes } from 'date-fns';
import { useEffect, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BillingConfigurationParametersRequest } from 'src/sdk/billing';
import { Button } from 'src/uikit/Button';
import { Form } from 'src/uikit/Form';
import FormDateSelector from 'src/uikit/Form/DateSelector';
import RadioButton from 'src/uikit/Form/RadioInput';
import FormSwitchInput from 'src/uikit/Form/SwitchInput';
import SwitchInput from 'src/uikit/SwitchInput';
import { Label } from 'src/uikit/Typography';
import styled, { css } from 'styled-components/macro';
import * as yup from 'yup';

export const defaultNighttimeEnd = setHours(setMinutes(new Date(), 0), 7);
export const defaultNighttimeStart = setHours(setMinutes(new Date(), 0), 19);

const StyledLabel = styled(Label)<{ $disabled?: boolean }>`
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  font-weight: 400;
  ${({ $disabled }) =>
    $disabled &&
    css`
      color: ${({ theme }) => theme.palette.black[70]};
    `}
`;

type ModeTypeResident = Extract<
  BillingConfigurationParametersRequest['mode'],
  'FLAT_RATE'
>;
type ModeTypeUsage = Extract<
  BillingConfigurationParametersRequest['mode'],
  'FULL_DAYS' | 'NIGHTS_AND_DAYS'
>;

export type ConfigurationBillingType = 'resident' | 'usage';

const nursingHomeConfigurationBillingsFormSchema = yup.object({
  doNotBill: yup.boolean(),
  date: yup.date().when('doNotBill', {
    is: false,
    then: yup.date().required(),
  }),
  type: yup.mixed<ConfigurationBillingType>().when('doNotBill', {
    is: false,
    then: yup.mixed<ConfigurationBillingType>().required(),
  }),
  mode: yup.mixed().when('type', {
    is: 'resident',
    then: yup.mixed<ModeTypeResident>(),
    otherwise: yup.mixed<ModeTypeUsage>(),
  }),
  actualUsageOnlyOnExcess: yup.boolean().when('mode', {
    is: true,
    then: yup.boolean().required(),
  }),
  nightTimeStart: yup.string().when('mode', {
    is: true,
    then: yup.string().optional(),
  }),
  nightTimeEnd: yup.string().when('mode', {
    is: true,
    then: yup.string().optional(),
  }),
  tolerance: yup.number().when('mode', {
    is: true,
    then: yup.number().optional(),
  }),
});

export type NursingHomeConfigurationBillingsFormSchema = yup.InferType<
  typeof nursingHomeConfigurationBillingsFormSchema
>;

interface Props {
  defaultValues: NursingHomeConfigurationBillingsFormSchema;
  onSubmit: (values: NursingHomeConfigurationBillingsFormSchema) => void;
}

const NursingHomeConfigurationBillingsForm = ({defaultValues, onSubmit}: Props) => {


  const { t } = useTranslation();

  const elememts: {
    id: 'resident' | 'usage';
    label: string;
  }[] = [
    {
      id: 'resident',
      label: t('nursingHome.configuration.billings.currentType.resident'),
    },
    {
      id: 'usage',
      label: t('nursingHome.configuration.billings.currentType.usage'),
    },
  ];

  const methods = useForm<NursingHomeConfigurationBillingsFormSchema>({
    resolver: yupResolver(nursingHomeConfigurationBillingsFormSchema),
    mode: 'onChange',
    defaultValues,
  });

  const {
    watch,
    setValue,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = methods;

  const doNotBill = watch('doNotBill');
  const type = watch('type');
  const mode = watch('mode');


  useEffect(() => {    
    if (type === 'resident') {
      setValue('mode', 'FLAT_RATE');
      setValue('actualUsageOnlyOnExcess', false);
    }

    if (type === 'usage') {
      if (mode === 'FLAT_RATE') {
        setValue('mode', 'FULL_DAYS');
      }
    }

    if(doNotBill){
      setValue('mode', 'FLAT_RATE');
      setValue('actualUsageOnlyOnExcess', false);
    }

  }, [mode, setValue, type, doNotBill]);


  const startAndEndTimes = useMemo(() => {
    
    if(!defaultValues?.nightTimeStart || !defaultValues?.nightTimeEnd) return;
      
    const nightTimeStart = defaultValues?.nightTimeStart.substring(0, 5);
    const nightTimeEnd = defaultValues?.nightTimeEnd.substring(0, 5);

    return {
      nightTimeStart,
      nightTimeEnd
    };
  }, [defaultValues.nightTimeStart, defaultValues.nightTimeEnd]);

  const isDisabledType = useMemo(
    () => type === 'resident' || doNotBill,
    [doNotBill, type],
  );

  // const isDisabledDifferentiate = useMemo(
  //   () => isDisabledType || mode !== 'NIGHTS_AND_DAYS',
  //   [isDisabledType, mode],
  // );

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="mb-4" xs="auto">
          <Col>
            <Label>
              {t('nursingHome.configuration.billings.form.labels.doNotBill')}
            </Label>
          </Col>
          <Col>
            <FormSwitchInput name="doNotBill" />
          </Col>
        </Row>
        <Row>
          <Col md="6" className="pt-2">
            <StyledLabel $disabled={doNotBill}>
              {t('nursingHome.configuration.billings.form.labels.date')}
            </StyledLabel>
          </Col>
          <Col md="3">
            <FormDateSelector
              name="date"
              dateFormat="MM/yyyy"
              minDate={new Date()}
              showMonthYearPicker
              disabled={doNotBill}
            />
          </Col>
        </Row>
        <Row x="auto">
          <Col md="12">
            <RadioButton name="type" elements={elememts} disabled={doNotBill} />
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 8, offset: 4 }} xl={{ span: 6, offset: 6 }}>
            <Row className="mt-4" xs="auto">
              <Col>
                <Controller
                  name="actualUsageOnlyOnExcess"
                  render={({ value, onChange, ref }) => (
                    <SwitchInput
                      ref={ref}
                      disabled={isDisabledType}
                      value={value}
                      checked={value}
                      onChange={(isChecked) => {
                        return onChange(
                          isChecked ? true : false,
                        );
                      }}
                    />
                  )}
                />
              </Col>
              <Col>
                <StyledLabel className="ml-2" $disabled={isDisabledType}>
                  {t('nursingHome.configuration.billings.form.labels.subscriptionNumber')}
                </StyledLabel>
              </Col>
            </Row>
            <Row className="mt-4" xs="auto">
              <Col>
                <Controller
                  name="mode"
                  render={({ value, onChange, ref }) => (
                    <SwitchInput
                      ref={ref}
                      disabled={isDisabledType}
                      value={value}
                      checked={value === 'NIGHTS_AND_DAYS'}
                      onChange={(isChecked) => {
                        return onChange(
                          isChecked ? 'NIGHTS_AND_DAYS' : 'FULL_DAYS',
                        );
                      }}
                    />
                  )}
                />
              </Col>
              <Col>
                <StyledLabel className="ml-2" $disabled={isDisabledType}>
                  {t('nursingHome.configuration.billings.form.labels.mode')}
                </StyledLabel>
              </Col>
              <Col>
                <StyledLabel $disabled={isDisabledType}>
                { defaultValues &&
                `${startAndEndTimes?.nightTimeStart}AM - ${startAndEndTimes?.nightTimeEnd} PM`} 
                </StyledLabel>
              </Col>
              <Col>
                <StyledLabel $disabled={isDisabledType}>
                {t('nursingHome.configuration.billings.form.labels.time')}
                </StyledLabel>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row xs="auto" className="justify-content-end p-2">
          <Col>
            <Button type="submit" disabled={!isDirty}>
              {t('nursingHome.configuration.billings.form.submit')}
            </Button>
          </Col>
        </Row>
      </Form>
    </FormProvider>
  );
};

export default NursingHomeConfigurationBillingsForm;
