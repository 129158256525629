import { yupResolver } from '@hookform/resolvers/yup';
import { add } from 'date-fns';
import { useEffect, useMemo } from 'react';
import { Row, Col, Form as ReactForm } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import { DATE_FORMAT_DAY } from 'src/constants';
import useDebounce from 'src/hooks/useDebounce';
import {
  GetSubscriptionCreationData,
  SubscriptionCreationRequest,
} from 'src/sdk/subscription';
import CounterInput from 'src/uikit/Form/CounterInput';
import FormDateSelector from 'src/uikit/Form/DateSelector';
import FormSelectInput from 'src/uikit/Form/SelectInput';
import palette from 'src/uikit/theme/palette';
import { H2, P } from 'src/uikit/Typography';
import { formatDate } from 'src/utils/date';
import { getStartingDate } from 'src/utils/getDate';
import styled from 'styled-components/macro';
import * as yup from 'yup';

import useSubscriptionTypesOptions from '../../hooks/useSubscriptionTypeOptions';
import useEstimateEquipments from '../hooks/useEstimateEquipments';
import { useSubscriptionUpsert } from '../SubscriptionContext';
import { CurrentEquipments } from './SubscriptionEquipmentForm';
import SubscriptionUpdatePlanDifference from './SubscriptionUpdatePlanDifference';

const Form = styled(ReactForm)`
  margin-top: 1rem;
  display: grid;
  gap: 1.5rem;
  font-family: 'Poppins';
`;

const RowHeader = styled(Row)`
  text-transform: uppercase;
  font-weight: 600;
  font-size: 18px;
  color: ${({ theme }) => theme.palette.black[90]};
  border-bottom: 1px solid ${({ theme }) => theme.palette.black[50]};
  padding-block: 0.5rem;
  letter-spacing: 1px;
`;

const RowContent = styled(Row)`
  align-items: center;
`;

const SectionTitle = styled(H2)`
  font-weight: 700;
  font-family: 'Poppins';
  font-size: 20px;
  line-height: 30px;
  color: ${({ theme }) => theme.palette.black[90]};
  margin-bottom: 0;
`;

const FuturTitle = styled(SectionTitle)`
  color: ${({ theme }) => theme.palette.main.primary};
`;

const StyledCol = styled(Col)`
  display: flex;
  text-align: center;
  justify-content: center;
`;

export const subscriptionUpdatePlanFormSchema = (t: TFunction) =>
  yup.object({
    type: yup.mixed<SubscriptionCreationRequest['type']>().required(),
    residentEstimate: yup.number().required(),
    startDate: yup.string().required(),
    endDate: yup.string().required(),
    gateways: yup.number().required(),
    chargingStations: yup.number().required(),
    clipons: yup.number().required(),
  });

export type SubscriptionUpdatePlanFormSchema = yup.InferType<
  ReturnType<typeof subscriptionUpdatePlanFormSchema>
>;

interface Props {
  data: SubscriptionCreationRequest;
  defaultValues: SubscriptionUpdatePlanFormSchema;
  onSubmit: (values: SubscriptionUpdatePlanFormSchema) => void;
  currentEquipments?: CurrentEquipments;
  creationData: GetSubscriptionCreationData;
}

const SubscriptionUpdatePlanForm = ({
  data,
  defaultValues,
  onSubmit,
  creationData,
}: Props) => {
  const { t, i18n } = useTranslation();
  const subscriptionTypesOptions = useSubscriptionTypesOptions();

  const { currentConfiguration: configuration } = useSubscriptionUpsert();

  const methods = useForm({
    resolver: yupResolver(subscriptionUpdatePlanFormSchema(t)),
    mode: 'onChange',
    defaultValues,
  });

  const { watch, handleSubmit, setValue, reset } = methods;

  const watchValues = watch();

  const watchResidentEstimateDebouce = useDebounce(
    watchValues.residentEstimate,
    500,
  );

  const subscriptionTypesOptionsFiltered = useMemo(() => {
    return subscriptionTypesOptions.filter(
      (option) => defaultValues.type === 'STANDARD' && option.value !== 'DEMO',
    );
  }, [defaultValues.type, subscriptionTypesOptions]);

  const { estimates } = useEstimateEquipments(watchResidentEstimateDebouce, {});

  useEffect(() => {
    if (estimates) {
      const { chargingStationEstimate, gatewayEstimate, cliponEstimate } =
        estimates || {
          chargingStationEstimate: 0,
          gatewayEstimate: 0,
          cliponEstimate: 0,
        };

      setValue(
        'chargingStations',
        chargingStationEstimate + (data.extraChargingStations || 0),
      );
      setValue('gateways', gatewayEstimate + (data.extraGateways || 0));
      setValue('clipons', cliponEstimate);
    }
  }, [data.extraChargingStations, data.extraGateways, estimates, setValue]);

  const _onSubmit = (values: SubscriptionUpdatePlanFormSchema) => {
    onSubmit({
      ...values,
      chargingStations:
        values.chargingStations - (estimates?.chargingStationEstimate || 0),
      clipons: values.clipons - (estimates?.cliponEstimate || 0),
      gateways: values.gateways - (estimates?.gatewayEstimate || 0),
    });

    reset();
  };

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(_onSubmit)} id="subscription-update-form">
        <RowHeader className="w-100">
          <Col md="5"></Col>
          <StyledCol>
            {t(
              'components.SubscriptionUpsert.SubscriptionUpdatePlan.SubscriptionUpdatePlanForm.current',
            )}
          </StyledCol>
          <StyledCol></StyledCol>
          <StyledCol>
            <FuturTitle>
              {t(
                'components.SubscriptionUpsert.SubscriptionUpdatePlan.SubscriptionUpdatePlanForm.future',
              )}
            </FuturTitle>
          </StyledCol>
        </RowHeader>
        <RowContent className="w-100">
          <SectionTitle>
            {t(
              'components.SubscriptionUpsert.SubscriptionUpdatePlan.SubscriptionUpdatePlanForm.subscriptionTitle',
            )}
          </SectionTitle>
        </RowContent>
        <Row className="w-100">
          <Col md="5" className="pt-2">
            {t(
              'components.SubscriptionUpsert.SubscriptionUpdatePlan.SubscriptionUpdatePlanForm.hardwareTitle',
            )}
          </Col>
          <StyledCol className="pt-2">{defaultValues.type}</StyledCol>
          <StyledCol className="pt-2">
            <SubscriptionUpdatePlanDifference
              newValue={watchValues.type}
              oldValue={defaultValues.type}
            />
          </StyledCol>
          <StyledCol>
            <FormSelectInput
              name="type"
              options={subscriptionTypesOptionsFiltered}
            />
          </StyledCol>
        </Row>
        <RowContent className="w-100">
          <Col md="5">
            {t(
              'components.SubscriptionUpsert.SubscriptionUpdatePlan.SubscriptionUpdatePlanForm.residentsLabel',
            )}
          </Col>
          <StyledCol>
            {creationData?.mainSubscription?.residentEstimate}
          </StyledCol>
          <StyledCol>
            <SubscriptionUpdatePlanDifference
              newValue={watchValues.residentEstimate}
              oldValue={creationData?.mainSubscription?.residentEstimate || 0}
            />
          </StyledCol>
          <StyledCol>
            <CounterInput
              name="residentEstimate"
              step={1}
              hidden={watchValues.type === 'DEMO'}
              min={configuration?.minimumResidentEstimate}
            />
          </StyledCol>
        </RowContent>
        <RowContent className="w-100">
          <Col md="5">
            {t(
              'components.SubscriptionUpsert.SubscriptionUpdatePlan.SubscriptionUpdatePlanForm.startDateLabel',
            )}
          </Col>
          <StyledCol>
            {formatDate(
              creationData?.mainSubscription?.startDate,
              i18n.language,
            )}
          </StyledCol>
          <StyledCol>
            <SubscriptionUpdatePlanDifference
              newValue={watchValues.startDate}
              oldValue={creationData?.mainSubscription?.startDate || ''}
            />
          </StyledCol>
          <StyledCol>
            <FormDateSelector
              name="startDate"
              minDate={new Date(getStartingDate())}
              placeholder="Starting date"
              dateFormat={DATE_FORMAT_DAY}
            />
          </StyledCol>
        </RowContent>
        <RowContent className="w-100">
          <Col md="5">
            {t(
              'components.SubscriptionUpsert.SubscriptionUpdatePlan.SubscriptionUpdatePlanForm.endDateLabel',
            )}
          </Col>
          <StyledCol>
            {formatDate(creationData.mainSubscription?.endDate, i18n.language)}
          </StyledCol>
          <StyledCol>
            <SubscriptionUpdatePlanDifference
              newValue={watchValues.endDate}
              oldValue={defaultValues.endDate}
            />
          </StyledCol>
          <StyledCol>
            <FormDateSelector
              name="endDate"
              minDate={new Date(defaultValues.endDate || '')}
              maxDate={add(new Date(watchValues.startDate || ''), {
                years: configuration?.maximumDuration,
              })}
              dateFormat={DATE_FORMAT_DAY}
            />
          </StyledCol>
        </RowContent>

        <RowContent className="w-100">
          <Col>
            <SectionTitle>
              {t(
                'components.SubscriptionUpsert.SubscriptionUpdatePlan.SubscriptionUpdatePlanForm.hardwareTitle',
              )}
            </SectionTitle>
            <P>
              {t(
                'components.SubscriptionUpsert.SubscriptionUpdatePlan.SubscriptionUpdatePlanForm.hardwareSubtitle',
              )}
            </P>
          </Col>
        </RowContent>
        <RowContent className="w-100">
          <Col md="5">
            {t(
              'components.SubscriptionUpsert.SubscriptionUpdatePlan.SubscriptionUpdatePlanForm.cliponLabel',
            )}
          </Col>
          <StyledCol>{defaultValues?.clipons}</StyledCol>
          <StyledCol>
            <SubscriptionUpdatePlanDifference
              newValue={watchValues.clipons}
              oldValue={defaultValues.clipons}
            />
          </StyledCol>
          <StyledCol>
            <CounterInput
              name="clipons"
              min={estimates?.cliponEstimate || 0}
              max={estimates?.cliponEstimate || 0}
            />
          </StyledCol>
        </RowContent>
        <RowContent className="w-100">
          <Col md="5">
            {t(
              'components.SubscriptionUpsert.SubscriptionUpdatePlan.SubscriptionUpdatePlanForm.gatewayLabel',
            )}
          </Col>
          <StyledCol>{defaultValues?.gateways}</StyledCol>
          <StyledCol>
            <SubscriptionUpdatePlanDifference
              newValue={watchValues.gateways}
              oldValue={defaultValues.gateways}
            />
          </StyledCol>
          <StyledCol>
            <CounterInput
              name="gateways"
              mainColor={palette.main.purple}
              secondaryColor={palette.main.lightPurple}
              min={defaultValues?.gateways}
              max={
                (estimates?.gatewayEstimate || 0) +
                (configuration?.maximumExtraGateways || 0)
              }
            />
          </StyledCol>
        </RowContent>
        <RowContent className="w-100">
          <Col md="5">
            {t(
              'components.SubscriptionUpsert.SubscriptionUpdatePlan.SubscriptionUpdatePlanForm.chargingStationsLabel',
            )}
          </Col>
          <StyledCol>{defaultValues?.chargingStations}</StyledCol>
          <StyledCol>
            <SubscriptionUpdatePlanDifference
              newValue={watchValues.chargingStations}
              oldValue={defaultValues.chargingStations}
            />
          </StyledCol>
          <StyledCol>
            <CounterInput
              name="chargingStations"
              mainColor={palette.main.yellow}
              secondaryColor={palette.main.lightYellow}
              min={defaultValues?.chargingStations}
              max={
                (estimates?.chargingStationEstimate || 0) +
                (configuration?.maximumExtraChargingStations || 0)
              }
            />
          </StyledCol>
        </RowContent>
      </Form>
    </FormProvider>
  );
};

export default SubscriptionUpdatePlanForm;
