import {
  generatePath,
  Redirect,
  Switch,
  useLocation,
  useParams,
} from 'react-router-dom';
import EquipmentChargingStation from 'src/pages/EquipmentChargingStation';
import EquipmentClipon from 'src/pages/EquipmentClipon';
import EquipmentGateway from 'src/pages/EquipmentGateway';
import EquipmentOrderNew from 'src/pages/EquipmentOrderNew';
import EquipmentShippingRequests from 'src/pages/EquipmentShippingRequests';
import PrivateRoute from 'src/router/PrivateRoute';
import Routes from 'src/router/Routes';
import { RouteParams } from 'src/sdk/nursingHome';

import EquipmentHorizontalMenu from './EquipmentHorizontalMenu';

const EquipmentGeneralInformation = () => {
  const { id } = useParams<RouteParams>();
  const location = useLocation();

  return location.pathname ===
    generatePath(Routes.NURSING_EQUIPMENT, { id }) ? (
    <Redirect to={generatePath(Routes.NURSING_EQUIPMENT_CLIPON, { id })} />
  ) : (
    <div key={id}>
      <EquipmentHorizontalMenu />
      <Switch>
        <PrivateRoute
          path={generatePath(Routes.NURSING_EQUIPMENT_CLIPON, { id })}
          component={EquipmentClipon}
        />
        <PrivateRoute
          path={generatePath(Routes.NURSING_EQUIPMENT_GATEWAY, { id })}
          component={EquipmentGateway}
        />
        <PrivateRoute
          path={generatePath(Routes.NURSING_EQUIPMENT_CHARGING, { id })}
          component={EquipmentChargingStation}
        />
        <PrivateRoute
          path={generatePath(Routes.NURSING_EQUIPMENT_ORDERING, { id })}
          component={EquipmentShippingRequests}
          exact
        />
        <PrivateRoute
          path={generatePath(Routes.NURSING_EQUIPMENT_ORDERING_NEW, { id })}
          component={EquipmentOrderNew}
          exact
        />
      </Switch>
    </div>
  );
};

export default EquipmentGeneralInformation;
