import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useToast from 'src/hooks/useToast';
import { PartnerProspect } from 'src/sdk/partner-prospect';
import styled from 'styled-components/macro';

import useUpdatePartnerProspectAddressMutation from '../../hooks/useUpdatePartnerProspectAddressMutation';
import useUpdatePartnerProspectMutation from '../../hooks/useUpdatePartnerProspectMutation';
import PartnerProspectHeader from '../PartnerProspectHeader';
import PartnerProspectAddressForm, {
  PartnerProspectAddressFormSchema,
} from './PartnerProspectAddressForm';
import PartnerProspectGeneralForm from './PartnerProspectGeneralForm';
import { PartnerProspectGeneralFormData } from './PartnerProspectGeneralForm';

interface Props {
  data: PartnerProspect;
}

const Wrapper = styled.div`
  display: grid;
  gap: 0.5rem;
`;

const PartnerProspectGeneral = ({ data }: Props) => {
  const { t } = useTranslation();
  const { toastSuccess, toastError } = useToast();
  const { updatePartnerProspectMutation } = useUpdatePartnerProspectMutation();
  const { updatePartnerProspectAddressMutation } =
    useUpdatePartnerProspectAddressMutation();

  const defaultValuesGeneral = useMemo(
    () => ({
      name: data.name,
      identifier: data.identifier,
      language: data.language,
      timeZone: data.timeZone,
    }),
    [data],
  );

  const defaultValuesAddress: PartnerProspectAddressFormSchema = useMemo(
    () => ({
      name: data.address?.name || '',
      street: data.address?.street || '',
      zip: data.address?.zip || '',
      town: data.address?.town || '',
      country: data.address?.country || '',
      email: data.address?.email || '',
      telephone: data.address?.telephone || '',
    }),
    [data],
  );

  const onSubmitGeneral = async (values: PartnerProspectGeneralFormData) => {
    try {
      await updatePartnerProspectMutation.mutateAsync({
        id: data.id,
        name: values.name,
        identifier: values.identifier,
        language: values.language,
        timeZone: values.timeZone,
      });
      toastSuccess(
        t(
          'components.PartnerProspects.PartnerProspectDetails.PartnerProspectGeneral.success',
        ),
      );
    } catch (err: any) {
      if (err.code) {
        return toastError(t(`errorsApi.${err.code}`));
      }
      toastError(
        t(
          'components.PartnerProspects.PartnerProspectDetails.PartnerProspectGeneral.error',
        ),
      );
    }
  };

  const onSubmitAddress = async (values: PartnerProspectAddressFormSchema) => {
    try {
      await updatePartnerProspectAddressMutation.mutateAsync({
        partnerProspectId: data.id,
        ...values,
      });
      toastSuccess(
        t(
          'components.PartnerProspects.PartnerProspectDetails.PartnerProspectGeneral.success',
        ),
      );
    } catch (err: any) {
      if (err.code) {
        return toastError(t(`errorsApi.${err.code}`));
      }
      toastError(
        t(
          'components.PartnerProspects.PartnerProspectDetails.PartnerProspectGeneral.error',
        ),
      );
    }
  };

  return (
    <Wrapper>
      <PartnerProspectHeader title={data.name} />
      <PartnerProspectGeneralForm
        defaultValues={defaultValuesGeneral}
        onSubmit={onSubmitGeneral}
      />
      <PartnerProspectAddressForm
        defaultValues={defaultValuesAddress}
        onSubmit={onSubmitAddress}
      />
    </Wrapper>
  );
};

export default PartnerProspectGeneral;
