import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import SortingButton from 'src/components/SortingHeaders/SortingButton';
import { UserSortField } from 'src/sdk/users';
import { sortByDirection } from 'src/utils/sortByDirection';
import styled from 'styled-components/macro';

const StyledColIcon = styled(Col)`
  flex-basis: 75px;
  flex-grow: initial;
`;

const StyledRow = styled(Row)`
  padding-inline: 1rem;
`;

const StyledCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledColName = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: start;
`;

interface Props {
  onSortingChange: React.Dispatch<React.SetStateAction<UserSortField>>;
  sortField: UserSortField;
}

const SortingUserHeader = ({ onSortingChange, sortField }: Props) => {
  const { t } = useTranslation();

  const handleSortClick = (label: UserSortField['label']) => {
    sortByDirection(label, sortField, onSortingChange);
  };

  return (
    <StyledRow>
      <StyledColIcon />
      <StyledColName md="4">
        <SortingButton
          primary
          label={t('components.sortingHeader.sortByName')}
          field="name"
          onClick={() => handleSortClick('lastName')}
        />
      </StyledColName>
      <StyledCol md="2">
        <SortingButton
          primary
          label={t('components.sortingHeader.sortByRole')}
          field="role"
          onClick={() => handleSortClick('profile')}
        />
      </StyledCol>
      <StyledCol md="2">
        <SortingButton
          primary
          label={t('components.sortingHeader.sortByEmployeeId')}
          field="validationDate"
          onClick={() => handleSortClick('employeeId')}
        />
      </StyledCol>
      <StyledCol md="2">
        <SortingButton
          primary
          label={t('components.sortingHeader.sortByCreationDate')}
          field="creationDate"
          onClick={() => handleSortClick('createdDate')}
        />
      </StyledCol>

      <StyledCol md="1" />
    </StyledRow>
  );
};

export default SortingUserHeader;
