import { useState } from 'react';
import { Col, FormControlProps, Overlay, Popover } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import ErrorMessage from 'src/uikit/ErrorMessage';
import TextInput, { TextInputProps } from 'src/uikit/TextInput';
import getNestedProperty from 'src/utils/getNestedProperty';
import styled from 'styled-components/macro';

import PasswordMatcher from './PasswordMatcher';

const StyledCol = styled(Col)`
  padding: 0;
`;

const StyledPopover = styled(Popover)`
  max-width: 350px;
`;

export type FormPasswordInputProps = {
  name: string;
  showRules?: boolean;
} & TextInputProps &
  FormControlProps;

const FormPasswordInput = ({
  name,
  defaultValue,
  showRules = false,
  ...others
}: FormPasswordInputProps) => {
  const { control, errors } = useFormContext();
  const error = getNestedProperty(errors, name);
  const errorMessage = error?.message;

  const [toggleRules, setToggleRules] = useState(false);

  const showErrorMode = !!error;

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ value, onChange, ref }) => (
        <StyledCol>
          {showRules && (
            <Overlay target={ref.current} show={toggleRules} placement="bottom">
              <StyledPopover
                id="password-matcher-popover"
                className="px-3 py-2"
              >
                <PasswordMatcher value={value} />
              </StyledPopover>
            </Overlay>
          )}

          <TextInput
            {...others}
            ref={ref}
            variant="form"
            type="password"
            value={value}
            onChange={onChange}
            onFocus={() => setToggleRules(true)}
            onBlur={() => setToggleRules(false)}
          />

          {showErrorMode && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </StyledCol>
      )}
    />
  );
};

export default FormPasswordInput;
