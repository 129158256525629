import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Routes from 'src/router/Routes';
import { Button } from 'src/uikit/Button';
import { H1, P } from 'src/uikit/Typography';
import styled from 'styled-components/macro';

import EmailVerificationImage from './EmailVerificationImage';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 1rem;
`;

const Title = styled(H1)`
  font-size: 18px;
  font-weight: 700;
`;

const Content = styled(P)`
  font-size: 14px;
`;

const EmailVerificationSuccess = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <EmailVerificationImage type="success" />
      <Title>
        {t('components.Auth.EmailVerification.EmailVerificationSuccess.title')}
      </Title>
      <Content>
        <Trans
          i18nKey={t(
            'components.Auth.EmailVerification.EmailVerificationSuccess.content',
          )}
        />
      </Content>
      <Button as={Link} to={Routes.LOGIN}>
        {t('components.Auth.RegisterForm.backToLogin')} &gt;
      </Button>
    </Wrapper>
  );
};

export default EmailVerificationSuccess;
