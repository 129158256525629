import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import ShippingRequestExpandableList from 'src/components/NursingHome/ShippingRequest/ShippingRequestExpandableList';
import { useAuth } from 'src/contexts/Auth';
import Routes from 'src/router/Routes';

import useEquipmentOrderData from '../ShippingRequest/hooks/useFetchShipingRequestQuery';
import { ShippingRequestMode } from '../ShippingRequest/ShippingRequestExpandableItem';
import GeneralCard from './GeneralCard';

const ShippingRequestCard = () => {
  const { t } = useTranslation();
  const { nhSession } = useAuth();
  const { data, isLoading, isError } = useEquipmentOrderData({
    page: 0,
    size: 1000,
    nhId: nhSession?.id.toString() || '-1',
    expand: [
      'counts',
      'custom-address',
      'delivery-address',
      'handling-units',
      'movements',
    ],
    status: 'SHIPPED',
  });

  if (isLoading) {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <Spinner animation="border" variant="secondary" />
      </div>
    );
  }

  if (isError) return <Redirect to={Routes.ERROR} />;

  if (!isLoading && (!data || data?.empty)) {
    return <></>;
  }

  return (
    <GeneralCard title={t('components.equipmentOrder.title')}>
      <ShippingRequestExpandableList
        shippingRequests={data.content}
        mode={ShippingRequestMode.HANDLING_UNIT}
      />
    </GeneralCard>
  );
};

export default ShippingRequestCard;
