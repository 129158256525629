import { AxiosResponse } from 'axios';
import { format } from 'date-fns';
import { useCallback } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ReactComponent as CliponBadge } from 'src/assets/clipon-badge.svg';
import Clipon from 'src/assets/clipon.png';
import request from 'src/axios';
import DefaultModal from 'src/components/Misc/Modal/DefaultModal';
import { DATE_FORMAT_DAY, DATE_FORMAT_SECOND } from 'src/constants';
import { Status } from 'src/sdk/equipment';
import { CliponDetails, UpdateStatusEquipmentBody } from 'src/sdk/provisioning';
import Separator from 'src/uikit/Separator';
import { SpinnerLoader } from 'src/uikit/SpinnerLoader';
import { H1, Label } from 'src/uikit/Typography';
import styled from 'styled-components/macro';

import StatusButton from '../../Provisioning/StatusButton';
import EquipmentStatusLabel from '../EquipmentStatusLabel';

const Wrapper = styled(Container)`
  height: inherit;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
`;

const StyledHeader = styled(Row)`
  text-align: center;
  width: 100%;
`;

const TitleCol = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled(H1)`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: ${({ theme }) => theme.palette.black[90]};
`;

const CliponBadgeStyled = styled(CliponBadge)`
  width: 40px;
  height: 40px;
`;

const StatusCol = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
  padding: 0 0 10px 0;
`;

const RowStatus = styled(Row)`
  margin-bottom: 60px;
`;

const DetailCol = styled(Col)`
  border-left: solid 1px ${({ theme }) => theme.palette.black[60]};
  padding-left: 2rem;
`;

const StyledLabel = styled(Label)`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1px;
  color: ${({ theme }) => theme.palette.black[90]};
  margin-right: 0.5rem;
`;

const ColLabel = styled(Col)`
  text-align: left;
`;

interface Props {
  cliponId: number;
  show: boolean;
  onClose: () => void;
  onChange?: () => void;
}

const ModalDetailClipon = ({ cliponId, show, onClose, onChange }: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { data: details, refetch } = useQuery<CliponDetails | undefined, Error>(
    ['clipon', { cliponId }],
    () =>
      cliponId >= 0
        ? request
            .get(
              `/clipons/${cliponId}?expand=handling-unit,nursing-home,firmware-upload`,
            )
            .then((response) => response.data)
        : undefined,
  );

  const { mutateAsync } = useMutation<
    AxiosResponse<CliponDetails>,
    Error,
    UpdateStatusEquipmentBody
  >((params) => request.put(`/clipons/${details?.id}`, params),{
    onSuccess: () => {
      queryClient.invalidateQueries(['clipon-list', { cliponId }]);
    },
  });

  const handleSetBroken = useCallback(() => {
    mutateAsync({
      description: details?.description || '',
      broken: !(details?.broken || false),
      lost: details?.lost || false,
    }).then(() => {
      onChange?.();
      refetch();
    });
  }, [
    details?.broken,
    details?.description,
    details?.lost,
    mutateAsync,
    onChange,
    refetch,
  ]);

  const handleSetLost = useCallback(() => {
    mutateAsync({
      description: details?.description || '',
      broken: details?.broken || false,
      lost: !(details?.lost || false),
    }).then(() => {
      onChange?.();
      refetch();
    });
  }, [
    details?.broken,
    details?.description,
    details?.lost,
    mutateAsync,
    onChange,
    refetch,
  ]);

  return (
    <DefaultModal
      size="lg"
      isVisible={show}
      handleClose={onClose}
      hasStep={false}
      customHeight="588px"
      header={
        <StyledHeader>
          <Col md="1">
            <CliponBadgeStyled />
          </Col>
          <TitleCol md="10">
            <Title>{t('components.modalDetailClipon.title')}</Title>
          </TitleCol>
        </StyledHeader>
      }
    >
      <Wrapper>
        {!details ? (
          <div className="d-flex align-items-center justify-content-center">
            <SpinnerLoader animation="grow" />
          </div>
        ) : (
          <>
            <StatusCol>
              <Image src={Clipon} />
              <RowStatus>
                <EquipmentStatusLabel status={details.equipmentStatus} />
              </RowStatus>
              <StatusButton
                type={Status.BROKEN}
                enabled={details.broken}
                onClick={handleSetBroken}
              />
              <StatusButton
                type={Status.LOST}
                enabled={details.lost}
                onClick={handleSetLost}
              />
            </StatusCol>
            <DetailCol>
              <Row>
                <Col md="5">
                  <StyledLabel>
                    {t('components.modalDetailClipon.name')}:
                  </StyledLabel>
                </Col>
                <ColLabel md="7">
                  <span>{details.name}</span>
                </ColLabel>
              </Row>
              <Row>
                <Col md="5">
                  <StyledLabel>
                    {t('components.modalDetailClipon.id')}:
                  </StyledLabel>
                </Col>
                <ColLabel md="7">
                  <span>{details.id}</span>
                </ColLabel>
              </Row>
              <Separator invisible />
              <Row>
                <Col md="5">
                  <StyledLabel>
                    {t('components.modalDetailClipon.deviceId')}:
                  </StyledLabel>
                </Col>
                <ColLabel md="7">
                  <span>{details.deviceId}</span>
                </ColLabel>
              </Row>
              <Row>
                <Col md="5">
                  <StyledLabel>
                    {t('components.modalDetailClipon.mac')}:
                  </StyledLabel>
                </Col>
                <ColLabel md="7">
                  <span>{details.devEui}</span>
                </ColLabel>
              </Row>
              <Row>
                <Col md="5">
                  <StyledLabel>
                    {t('components.modalDetailClipon.serial')}:
                  </StyledLabel>
                </Col>
                <ColLabel md="7">
                  <span>{details.serialNumber}</span>
                </ColLabel>
              </Row>
              <Row>
                <Col md="5">
                  <StyledLabel>
                    {t('components.modalDetailClipon.firmware')}:
                  </StyledLabel>
                </Col>
                <ColLabel md="7">
                  <span>
                    {details.firmwareMajorVersion}.
                    {details.firmwareMinorVersion}.
                    {details.firmwareRevisionVersion}
                  </span>
                </ColLabel>
              </Row>
              <Row>
                <Col md="5">
                  <StyledLabel>
                    {t('components.modalDetailClipon.ssccNumber')}:
                  </StyledLabel>
                </Col>
                <ColLabel md="7">
                  <span>{details.handlingUnit?.ssccNumber}</span>
                </ColLabel>
              </Row>
              <Row>
                <Col md="5">
                  <StyledLabel>
                    {t('components.modalDetailClipon.lot')}:
                  </StyledLabel>
                </Col>
                <ColLabel md="7">
                  <span>{details.handlingUnit?.batchNumber}</span>
                </ColLabel>
              </Row>
              <Row>
                <Col md="5">
                  <StyledLabel>
                    {t('components.modalDetailClipon.prodDate')}:
                  </StyledLabel>
                </Col>
                <ColLabel md="7">
                  <span>
                    {details.handlingUnit?.batchProductionDate &&
                      format(
                        new Date(details.handlingUnit?.batchProductionDate),
                        DATE_FORMAT_DAY,
                      )}
                  </span>
                </ColLabel>
              </Row>
              <Row>
                <Col md="5">
                  <StyledLabel>
                    {t('components.modalDetailClipon.description')}:
                  </StyledLabel>
                </Col>
                <ColLabel md="7">
                  <span>{details.description}</span>
                </ColLabel>
              </Row>
              <Separator invisible />
              <Row>
                <Col md="5">
                  <StyledLabel>
                    {t('components.modalDetailClipon.nursing')}:
                  </StyledLabel>
                </Col>
                <ColLabel md="7">
                  <span>{details.nursingHome?.name}</span>
                </ColLabel>
              </Row>
              <Row>
                <Col md="5">
                  <StyledLabel>
                    {t('components.modalDetailClipon.status')}:
                  </StyledLabel>
                </Col>
                <ColLabel md="7">
                  <span>{details.state}</span>
                </ColLabel>
              </Row>
              <Row>
                <Col md="5">
                  <StyledLabel>
                    {t('components.modalDetailClipon.lastMsg')}:
                  </StyledLabel>
                </Col>
                <ColLabel md="7">
                  <span>
                    {details.receivedAt &&
                      format(new Date(details.receivedAt), DATE_FORMAT_SECOND)}
                  </span>
                </ColLabel>
              </Row>
            </DetailCol>
          </>
        )}
      </Wrapper>
    </DefaultModal>
  );
};

export default ModalDetailClipon;
