import BootstrapButton, {
  ButtonProps as BootstrapButtonProps,
} from 'react-bootstrap/Button';
import { fontPrimary } from 'src/uikit/theme/typography';
import styled, { css } from 'styled-components/macro';

export enum ButtonColors {
  SUCCESS = 'success',
  DANGER = 'danger',
  CANCEL = 'cancel',
}

export type ButtonProps = {
  background?: ButtonColors;
} & Omit<BootstrapButtonProps, 'as'>;

export const Button = styled(BootstrapButton)<ButtonProps>`
  ${fontPrimary}
  font-weight: 500;
  font-size: .95rem;

  color: ${({ theme }) => theme.palette.main.white};
  border: none;
  padding: 0 1rem;
  cursor: pointer;
  border-radius: 100px;
  min-height: 30px;

  text-decoration: none;
  display: flex;
  align-items: center;

  border-width: 0;
  transition: background-color 200ms ease-in, box-shadow 200ms ease-in;

  & > :first-child {
    &:is(svg) {
      margin-right: 0.25rem;
    }
  }

  & > :last-child {
    &:is(svg) {
      margin-left: 0.25rem;
    }
  }

  :disabled {
    cursor: auto;
    background-color: ${({ theme }) => theme.palette.black[60]};
    border-color: ${({ theme }) => theme.palette.black[60]};
  }

  ${({ background }) =>
    (background === ButtonColors.SUCCESS &&
      css`
        background-color: ${({ theme }) => theme.palette.main.greenElectric};
      `) ||
    (background === ButtonColors.DANGER &&
      css`
        background-color: ${({ theme }) => theme.palette.main.red};
        :hover:not(:disabled),
        :focus:not(:disabled),
        :not(:disabled):not(.disabled):active {
          color: ${({ theme }) => theme.palette.main.white};
          background-color: rgba(236, 51, 18, 0.64);
          border-color: rgba(241, 161, 123, 0.28);
          box-shadow: 0 0 0 0.2rem rgba(241, 161, 123, 0.28);
        }
      `) ||
    (background === ButtonColors.CANCEL &&
      css`
        background-color: ${({ theme }) => theme.palette.main.white};
        color: ${({ theme }) => theme.palette.black.main};
        font-weight: bold;

        :hover:not(:disabled),
        :focus:not(:disabled),
        :not(:disabled):not(.disabled):active {
          color: ${({ theme }) => theme.palette.main.white};
          background-color: rgba(168, 168, 168, 0.64);
          border-color: rgba(197, 197, 197, 0.28);
          box-shadow: 0 0 0 0.2rem rgba(214, 214, 214, 0.28);
        }
      `) ||
    (!background &&
      css`
        background-color: ${({ theme }) => theme.palette.main.primary};
        :hover:not(:disabled),
        :focus:not(:disabled),
        :not(:disabled):not(.disabled):active {
          color: ${({ theme }) => theme.palette.main.white};
          background-color: rgba(12, 158, 171, 0.64);
          border-color: rgba(12, 158, 171, 0.28);
          box-shadow: 0 0 0 0.2rem rgba(12, 158, 171, 0.28);
        }
      `)}
`;
