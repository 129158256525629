import { Profiles } from './authentification';
import { GetResponse, Gender } from './common';
import { Status } from './equipment';
import { Partner } from './partner';
import { PartnerProspect } from './partner-prospect';

export enum UserStatus {
  Pending = 'PENDING',
  Active = 'ACTIVE',
  Blocked = 'BLOCKED',
  NoStatus = 'N/A',
}

export interface Option {
  value: Status;
  label: string;
}

export interface ProductSpecialistOption {
  value: number;
  label: string;
}

export type UserPartner = Pick<Partner, 'id' | 'name' | 'soldTo' | 'salesOrg'>;
export type UserPartnerProspect = Pick<
  PartnerProspect,
  'id' | 'name' | 'identifier' | 'timeZone'
>;

export interface UserGroupResponse {
  /** Format: int64 */
  id?: number;
  name?: string;
}
export interface UserLatestConnectionResponse {
  active?: boolean;
  /** @enum {string} */
  clientType?: 'WEB' | 'MOBILE';
  /** Format: date-time */
  date?: string;
}
export interface UserNursingHomeResponse {
  /** Format: int64 */
  id: number;
  name: string;
  tenantName: string;
}
export interface UserOpenSessionResponse {
  clientId?: string;
  /** Format: int64 */
  connectionId?: number;
  /** Format: date-time */
  createdDate?: string;
  /** Format: int64 */
  id?: number;
}

export interface UserPartnerProspectResponse {
  /** Format: int64 */
  id?: number;
  identifier?: string;
  name?: string;
  timeZone?: string;
}
export interface UserPartnerResponse {
  /** Format: int64 */
  id?: number;
  name?: string;
  salesOrg?: string;
  soldTo?: string;
}
export interface UserResponse {
  /** @enum {string} */
  accountStatus: 'PENDING' | 'ACTIVE' | 'BLOCKED';
  /** Format: date-time */
  activationDate?: string;
  avatarImagePath?: string;
  /** Format: date-time */
  createdDate: string;
  email: string;
  emailVerified?: boolean;
  employeeId?: string;
  firstName: string;
  /** @enum {string} */
  gender: Gender;
  /** Format: int64 */
  id: number;
  /** @enum {string} */
  language: 'en' | 'de' | 'fr' | 'nl_BE' | 'it' | 'es';
  lastName: string;
  latestConnection?: UserLatestConnectionResponse;
  nursingHomes?: UserNursingHomeResponse[];
  openSessions?: UserOpenSessionResponse[];
  partner?: UserPartnerResponse;
  partnerProspects?: UserPartnerProspectResponse[];
  partnerUser?: boolean;
  preferredGroups?: UserGroupResponse[];
  /** @enum {string} */
  profile:
    | 'ORIZON_ADMINISTRATOR'
    | 'NURSING_HOME'
    | 'PARTNER_ADMINISTRATOR'
    | 'PRODUCT_SPECIALIST'
    | 'SALES_ASSISTANT'
    | 'DIRECTOR'
    | 'HEAD_NURSE'
    | 'NURSE'
    | 'CAREGIVER'
    | 'SAP'
    | 'TTN';
  sessionGroups?: UserGroupResponse[];
  username?: string;
}

export type UserExtra = 'grand-total' | 'count-by-account-status';

export type UserExpand =
  | 'user-parameters'
  | 'nursing-homes'
  | 'preferred-departments'
  | 'session-departments'
  | 'preferred-groups'
  | 'session-groups'
  | 'latest-connection'
  | 'open-sessions'
  | 'avatar-image-path'
  | 'counts'
  | 'partner'
  | 'partner-prospects';

export interface UserSortField {
  label:
    | 'employeeId'
    | 'lastName'
    | 'profile'
    | 'createdDate'
    | 'activationDate';
  direction: 'asc' | 'desc';
}

export interface GetUsersResponse extends GetResponse<UserResponse> {
  extra?: {
    grandTotal: number;
    countByAccountStatus: {
      [key in UserResponse['accountStatus']]: number;
    };
  };
}
export interface CreateUserRequest {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  profile: Profiles;
  gender: Gender;
  language: string;
  partnerId?: number;
}

export interface UpdateUserRequest {
  firstName: string;
  /** @enum {string} */
  gender: Gender;
  /** @enum {string} */
  language: 'en' | 'de' | 'fr' | 'nl_BE' | 'it' | 'es';
  lastName: string;
}

export interface UpdateUserEmployeeIdRequest {
  employeeId?: string;
}

export interface UpdateUserProfileRequest {
  /** @enum {string} */
  profile:
    | 'ORIZON_ADMINISTRATOR'
    | 'PARTNER_ADMINISTRATOR'
    | 'PRODUCT_SPECIALIST'
    | 'SALES_ASSISTANT'
    | 'NURSING_HOME'
    | 'DIRECTOR'
    | 'HEAD_NURSE'
    | 'NURSE'
    | 'CAREGIVER'
    | 'SAP'
    | 'TTN'
    | 'B2B';
}

export interface UpdateUserEmailRequest {
  email: string;
}

export interface UpdateUserResponse extends UserResponse {}

export interface UpdateUserStatusRequest {
  userId: number;
  accountStatus: UserStatus;
}

export interface UserVerifyEmailRequest {
  userId: number;
  token: string;
}
