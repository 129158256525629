import { Row } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { SubscriptionCreationRequest } from 'src/sdk/subscription';
import styled from 'styled-components/macro';

import SubscriptionPlan, { PlanProps } from './SubscriptionPlan';

const Wrapper = styled(Row)`
  align-items: center;
  margin-top: 1rem;
  text-align: right;
`;

interface Props {
  name: string;
  elements: Array<PlanProps>;
  defaultValue?: SubscriptionCreationRequest['type'];
}

const SubscriptionPlanInput = ({ name, elements, defaultValue }: Props) => {
  const { control } = useFormContext();

  return (
    <Controller
      controler={control}
      name={name}
      defaultValue={defaultValue}
      render={({ value, onChange }) => (
        <Wrapper>
          {elements.map((e: PlanProps, index) => (
            <SubscriptionPlan
              key={index}
              type={e.type}
              name={e.name}
              durations={e.durations}
              showRenewal={e.showRenewal}
              features={e.features}
              featuresAll={e.featuresAll}
              disabled={e.disabled}
              checked={value === e.type}
              onChecked={() => onChange(e.type)}
            />
          ))}
        </Wrapper>
      )}
    />
  );
};

export default SubscriptionPlanInput;
