import { AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import request from 'src/axios';

const useAddProductNursingHomeMutation = () => {
  const queryClient = useQueryClient();

  const addProductNursingHomeMutation = useMutation<
    AxiosResponse<any>,
    Error,
    { id: number; productId: number }
  >(
    ({ id, productId }) =>
      request.post(`/nursing-homes/${id}/products/${productId}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`productsList`);
      },
    },
  );

  return addProductNursingHomeMutation;
};

export default useAddProductNursingHomeMutation;
