import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CircleStatus from 'src/uikit/CircleStatus';
import { P } from 'src/uikit/Typography';
import styled from 'styled-components/macro';

import BackToLogin from '../BackToLogin';

const Wrapper = styled.div`
  max-width: 600px;
  overflow-y: auto;
`;

const Content = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding-inline: 4rem;
`;

const Text = styled(P)`
  font-size: 14px;
  line-height: 24px;
  text-align: center;
`;

const ResetPasswordSuccess = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Content>
        <CircleStatus type="success" />
        <Text>{t('components.Auth.ResetPasswordSuccess.success')}</Text>
        <Text>{t('components.Auth.ResetPasswordSuccess.login')}</Text>
        <BackToLogin />
      </Content>
    </Wrapper>
  );
};

export default ResetPasswordSuccess;
