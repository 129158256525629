import { useState } from 'react';
import RegisterForm from 'src/components/Auth/Register/RegisterForm';
import RegisterSuccess from 'src/components/Auth/Register/RegisterSuccess';
import { UserResponse } from 'src/sdk/users';

const Register = () => {
  const [userCreated, setUserCreated] = useState<UserResponse>();

  return (
    <>
      {userCreated ? (
        <RegisterSuccess user={userCreated} />
      ) : (
        <RegisterForm onUserCreated={(user) => setUserCreated(user)} />
      )}
    </>
  );
};

export default Register;
