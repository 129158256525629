import { AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import request from 'src/axios';
import {
  ShippingRequestOrderSparePartsRequest,
  ShippingRequestResponse,
} from 'src/sdk/shippingRequest';

const useOrderSparePartsMutation = () => {
  const queryClient = useQueryClient();

  const orderSparePartsMutation = useMutation<
    AxiosResponse<ShippingRequestResponse>,
    Error,
    ShippingRequestOrderSparePartsRequest
  >((body) => request.post(`/shipping-requests/order-spare-parts`, body), {
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
  });

  return { orderSparePartsMutation };
};

export default useOrderSparePartsMutation;
