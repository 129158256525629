import { QueryObserverOptions, useQuery } from 'react-query';
import request from 'src/axios';
import { EstimatedEquipment } from 'src/sdk/nursingHome';

const useEstimateEquipments = (
  residentEstimate: number,
  options?: QueryObserverOptions<EstimatedEquipment, Error>,
) => {
  const { data: estimates, refetch } = useQuery<EstimatedEquipment, Error>(
    ['compute-estimates', { residentEstimate }],
    () =>
      request
        .get<EstimatedEquipment>(
          `/subscriptions/compute-estimates?residents=${residentEstimate}`,
        )
        .then((res) => res.data),
    {
      enabled: residentEstimate > 0,
      ...options,
    },
  );

  return { estimates, refetch };
};

export default useEstimateEquipments;
