import {
  useCallback,
  useEffect,
  useState,
  createElement,
  Fragment,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { ReactComponent as DeliveryIcon } from 'src/assets/delivery.svg';
import { ReactComponent as DirectorIcon } from 'src/assets/director.svg';
import { ReactComponent as HomeIcon } from 'src/assets/home.svg';
import { ReactComponent as NursingHomeIcon } from 'src/assets/nursing-home.svg';
import request from 'src/axios';
import Completed from 'src/components/NursingHome/Creation/components/Completed';
import NursingHomeAccount from 'src/components/NursingHome/Creation/Stepper/NursingHomeCreationAccount';
import DeliveryAddressSelection from 'src/components/NursingHome/Creation/Stepper/NursingHomeCreationDelivery';
import DirectorAccount from 'src/components/NursingHome/Creation/Stepper/NursingHomeCreationDirector';
import NursingHomeSummary from 'src/components/NursingHome/Creation/Stepper/NursingHomeCreationSummary';
import Routes from 'src/router/Routes';
import { Gender } from 'src/sdk/common';
import { CreateNursingHome, RouteParams } from 'src/sdk/nursingHome';
import { Prospect } from 'src/sdk/prospect';
import Stepper from 'src/uikit/Stepper';
import palette from 'src/uikit/theme/palette';
import { clientTimeZone } from 'src/utils/timeZone';
import styled from 'styled-components/macro';

import { NursingHomeCreationContext } from '../components/NursingHome/Creation/Stepper/NursingHomeCreationContext';

const HomeIconWrapper = styled.div`
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${palette.main.primary};
  border-radius: 100%;
  color: ${palette.main.primary};
  background: white;
  width: 48px;
  height: 48px;
`;

const Card = styled.div`
  background: ${palette.main.white};
  border-radius: 12px 12px 0px 0px;
  padding: 0px 24px;
  display: flex;
  justify-content: center;
  position: relative;
  height: 160px;
`;

const computeDefaultValue = (
  prospectId: string,
  salesOrg: string,
): CreateNursingHome => ({
  prospectId,
  partnerProspectId: null,
  salesOrg,
  name: '',
  language: 'en',
  legalEntity: undefined,
  timeZone: clientTimeZone,
  shipToValues: [],
  nursingHomeUsername: '',
  directorFirstName: '',
  directorLastName: '',
  directorEmail: '',
  directorPin: '',
  directorLanguage: 'en',
  directorGender: Gender.Female,
  directorCommonAvatarId: 0,
});

const NursingHomeCreationOntex = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id, salesOrg } = useParams<RouteParams>();
  const [isLoading = false /*, setIsLoading*/] = useState<boolean>();
  const [currentStep = 0, setCurrentStep] = useState<number>();
  const [mode, setMode] = useState<string>();
  const [name, setName] = useState<string>();
  const [createNursingHomeId, setCreateNursingHomeId] = useState<
    number | null
  >();

  const [data, setData] = useState<CreateNursingHome>(
    computeDefaultValue(id, salesOrg),
  );

  const setDataAndIncrementStep = (
    newData: Partial<CreateNursingHome>,
  ): void => {
    setData({ ...data, ...newData });
    setCurrentStep(currentStep + 1);
  };

  const decrementStep = (): void => {
    setCurrentStep(currentStep - 1);
  };

  // To load initial data
  const { data: prospect } = useQuery<Prospect, Error>(
    ['prospects', data.prospectId],
    () =>
      request
        .get<Prospect>(
          `/prospects/${data.prospectId}?sales-org=${data.salesOrg}`,
        )
        .then((res) => {
          return {
            ...res.data,
            shipTo: res.data.deliveryAddresses.filter(
              /* Filter to keep only one address with the same DeliveryAddress. Backend will take all corresponding addresses. */
              (address, index, self) =>
                self.findIndex((other) => other.shipTo === address.shipTo) ===
                index,
            ),
          };
        }),
  );

  useEffect(() => {
    const languageFetch = prospect?.language;
    const language = languageFetch
      ? (languageFetch.toLowerCase() as CreateNursingHome['language'])
      : 'en';
    setData({ ...data, language, directorLanguage: language });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prospect]);

  // called when going back to the list of prospects (soldTo)
  const handleBackToProspects = useCallback(() => {
    history.push(Routes.NURSING_CREATION);
  }, [history]);

  const onCreationSuccess = (newNursingHomeId: number, ecsMode : string, tenantName: string) => {
    setCreateNursingHomeId(newNursingHomeId);
    setMode(ecsMode);
    setName(tenantName)
  };

  const headerData = [
    { icon: DeliveryIcon, title: t('select-one-or-more-ship-to') },
    { icon: NursingHomeIcon, title: t('create-nuring-home-account') },
    { icon: DirectorIcon, title: t('create-director-account') },
    { icon: Fragment, title: t('validate-information') },
  ];

  const stepperData = [
    {
      label: t('pages.creatingNursingHome.secondStep'),
      name: t('pages.creatingNursingHome.secondStep'),
      content: (
        <DeliveryAddressSelection
          data={data}
          prospect={prospect}
          isLoading={isLoading}
          onSuccess={setDataAndIncrementStep}
          onCancel={handleBackToProspects}
        />
      ),
    },
    {
      label: t('pages.creatingNursingHome.firstStep'),
      name: t('pages.creatingNursingHome.firstStep'),
      content: (
        <NursingHomeAccount
          data={data}
          onSuccess={setDataAndIncrementStep}
          onCancel={decrementStep}
          type="Prospect"
        />
      ),
    },
    {
      label: t('pages.creatingNursingHome.thirdStep'),
      name: t('pages.creatingNursingHome.thirdStep'),
      content: (
        <DirectorAccount
          data={data}
          onSuccess={setDataAndIncrementStep}
          onCancel={decrementStep}
          type="Prospect"
        />
      ),
    },
    {
      label: t('pages.creatingNursingHome.fourthStep'),
      name: t('pages.creatingNursingHome.fourthStep'),
      content: (
        <NursingHomeSummary
          data={data}
          setCurrentStep={setCurrentStep}
          onSuccess={onCreationSuccess}
          type="Prospect"
        />
      ),
    },
  ];
  
  
  return (
    <NursingHomeCreationContext.Provider
      value={{
        currentStep,
        prospect: prospect ? { ...prospect, type: 'Prospect' } : undefined,
        nursingHomeCreationSteps: {
          DeliveryAddressSelection: 0,
          NursingHomeAccount: 1,
          DirectorAccount: 2,
        },
      }}
    >
      {createNursingHomeId ? (
        <Completed nhId={createNursingHomeId} nhName={prospect?.name} mode={mode} tenantName={name}/>
      ) : (
        <div
          style={{
            margin: '0px 16px',
          }}
        >
          <Card>
            <div
              style={{
                position: 'absolute',
                left: '16px',
                top: '16px',
              }}
            >
              {createElement(headerData?.[currentStep]?.icon)}
            </div>
            <div
              style={{
                marginTop: '16px',
                textAlign: 'center',
              }}
            >
              <h4>
                <strong>{headerData?.[currentStep]?.title}</strong>
              </h4>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <HomeIconWrapper>
                  <HomeIcon />
                </HomeIconWrapper>
                <h5>
                  <strong>{prospect?.name}</strong>
                </h5>
              </div>
            </div>
          </Card>
          <Stepper
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            hideStepLabel
            stepperData={stepperData}
          />
        </div>
      )}
    </NursingHomeCreationContext.Provider>
  );
};

export default NursingHomeCreationOntex;
