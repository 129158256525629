import { AxiosResponse } from 'axios';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import request from 'src/axios';
import { Avatar, BlobAvatar } from 'src/sdk/avatar';
import { Gender } from 'src/sdk/common';

const useCommonAvatars = () => {
  const { data: commonAvatars, isLoading } = useQuery(
    'avatars',
    async () => {
      const response = await request.get(
        `/common-avatars?avatar-profile=DIRECTOR`,
      );
      const avatars = response.data.content;

      const queries: Promise<AxiosResponse>[] = [];
      avatars.forEach((avatar: Avatar) => {
        queries.push(
          request.get(`/common-avatars/${avatar.id}/image`, {
            responseType: 'blob',
          }),
        );
      });

      const results = await Promise.all(queries);
      const tmp: BlobAvatar[] = avatars.map((avatar: Avatar, index: number) => {
        return { ...avatar, blob: URL.createObjectURL(results[index].data) };
      });

      return [...tmp];
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );

  const commonMaleAvatars = useMemo(() => {
    return (
      commonAvatars?.filter(
        (avatar) => !avatar.gender || avatar.gender === Gender.Male,
      ) || []
    );
  }, [commonAvatars]);

  const commonFemaleAvatars = useMemo(() => {
    return (
      commonAvatars?.filter(
        (avatar) => !avatar.gender || avatar.gender === Gender.Female,
      ) || []
    );
  }, [commonAvatars]);

  return { commonAvatars, commonMaleAvatars, commonFemaleAvatars, isLoading };
};

export default useCommonAvatars;
