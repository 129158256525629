import { I18nextProvider } from 'react-i18next';
import { QueryClient, QueryClientProvider } from 'react-query';
import { PortalToastContainer } from 'src/hooks/useToast';
import { ThemeProvider } from 'styled-components/macro';

import { AppProvider } from './contexts/App';
import { AuthProvider } from './contexts/Auth';
import { PartnerProvider } from './contexts/Partner';
import Router from './router';
import i18n from './translations/i18n';
import theme, { GlobalStyle } from './uikit/theme';

import 'react-toastify/dist/ReactToastify.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 30000,
      retry: 1,
    },
  },
});

function App() {
  return (
    <AppProvider>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <I18nextProvider i18n={i18n}>
            <ThemeProvider theme={theme}>
              <GlobalStyle />
              <PartnerProvider>
                <Router />
                <PortalToastContainer />
              </PartnerProvider>
            </ThemeProvider>
          </I18nextProvider>
        </AuthProvider>
      </QueryClientProvider>
    </AppProvider>
  );
}

export default App;
