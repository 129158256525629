import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import SortingButton from 'src/components/SortingHeaders/SortingButton';
import { PartnerProspect } from 'src/sdk/partner-prospect';
import { sortByDirection } from 'src/utils/sortByDirection';
import styled from 'styled-components/macro';

import { PartnerProspectsSortField } from '../PartnerProspects/hooks/useFetchPartnerProspectsQuery';

const StyledRow = styled(Row)`
  padding-left: 10px;
  padding-right: 10px;
`;

const StyledCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledColIcon = styled(Col)`
  flex: 0 0 50px;
`;

interface Props {
  onSortingChange: React.Dispatch<
    React.SetStateAction<PartnerProspectsSortField>
  >;
  sortField: PartnerProspectsSortField;
}

const SortingPartnerProspectsHeader = ({
  onSortingChange,
  sortField,
}: Props) => {
  const { t } = useTranslation();

  const handleSortClick = (label: keyof PartnerProspect) => {
    sortByDirection(label, sortField, onSortingChange);
  };

  return (
    <StyledRow>
      <StyledColIcon />
      <StyledCol md={{ span: 2 }}>
        <SortingButton
          primary
          label={t('components.sortingHeader.sortByName')}
          field="name"
          onClick={() => handleSortClick('name')}
        />
      </StyledCol>
      <StyledCol md="2">
        <SortingButton
          primary
          label={t('components.sortingHeader.sortByIdentifer')}
          field="identifier"
          disableSort
        />
      </StyledCol>
      <StyledCol md="2">
        <SortingButton
          primary
          label={t('components.sortingHeader.sortBySubscription')}
          field="subscription"
          disableSort
        />
      </StyledCol>
      <StyledCol md="2">
        <SortingButton
          primary
          label={t('components.sortingHeader.sortByEndingDate')}
          field="endingDate"
          disableSort
        />
      </StyledCol>
      <StyledCol md="2">
        <SortingButton
          primary
          label={t('components.sortingHeader.sortByAssociatedPS')}
          field="associatedPs"
          disableSort
        />
      </StyledCol>
    </StyledRow>
  );
};

export default SortingPartnerProspectsHeader;
