import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { GetSubscriptionsResponse } from 'src/sdk/subscription';
import { AlertCard } from 'src/uikit/AlertCard';

import { List } from '../../../uikit/Layout/List';
import SubscriptionHistoryCard from './SubscriptionHistoryCard';
import SubscriptionHistoryHeader from './SubscriptionHistoryHeader';

interface Props {
  data?: GetSubscriptionsResponse;
  isLoading: boolean;
  refetch: () => void;
}


const SubscriptionHistoryList = ({ data, isLoading, refetch }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <SubscriptionHistoryHeader />
      {data?.empty && <AlertCard>{t('components.list.noResult')}</AlertCard>}
      {isLoading && (
        <div className="d-flex justify-content-center align-items-center">
          <Spinner animation="border" variant="secondary" />
        </div>
      )}
      <List>
        {data?.content?.map((subscription, index) => (
          <SubscriptionHistoryCard
            key={subscription.id}
            subscription={subscription}
            index={index}
          />
        ))}
      </List>
    </>
  );
};

export default SubscriptionHistoryList;
