import { ReactNode } from 'react';
import { Col, Row, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ReactComponent as HomeIcon } from 'src/assets/partners.svg';
import Routes from 'src/router/Routes';
import { Link } from 'src/uikit/Link';
import { H2 } from 'src/uikit/Typography';
import styled from 'styled-components/macro';

import { ChevronLeft } from '../../../assets/arrows/index';

interface Props {
  title: ReactNode;
}

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  min-height: 40px;
  border: 1px solid ${({ theme }) => theme.palette.main.primary};
  background-color: white;
  border-radius: 50%;
  color: ${({ theme }) => theme.palette.main.primary};
`;

const PartnerHeader = ({ title }: Props) => {
  const { t } = useTranslation();
  return (
    <Row className="p-3 align-items-center">
      <Col md="2">
        <Link to={Routes.PARTNERS} className="d-flex text-decoration-none text-reset">
          <ChevronLeft />
          <span className="ml-2">
            {t('components.partners.details.header.link')}
          </span>
        </Link>
      </Col>
      <Col md="8" className="d-flex justify-content-center">
        <Stack
          direction="horizontal"
          gap={2}
          className="w-100 justify-content-center"
        >
          <Icon>
            <HomeIcon />
          </Icon>
          <H2>{t('components.partners.details.header.title', { title })}</H2>
        </Stack>
      </Col>
    </Row>
  );
};

export default PartnerHeader;
