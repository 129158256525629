import { Col } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import ErrorMessage from 'src/uikit/ErrorMessage';
import getNestedProperty from 'src/utils/getNestedProperty';
import styled from 'styled-components/macro';

import SelectInput, { Option, GroupedOption } from '../../SelectInput';
import { SelectInputProps } from '../../SelectInput/index';

const StyledCol = styled(Col)`
  padding: 0;
`;

type FormSelectInputProps = {
  name: string;
  placeholder?: string;
  disabled?: boolean;
  hidden?: boolean;
  options: SelectInputProps['options'];
} & Omit<SelectInputProps, 'value' | 'onChange'>;

const FormSelectInput = ({
  name,
  placeholder,
  options,
  disabled,
  hidden,
  ...rest
}: FormSelectInputProps) => {
  const { control, errors } = useFormContext();
  const error = getNestedProperty(errors, name);
  const errorMessage = error?.message;
  const showErrorMode = !!error;

  const getCurrentOption = <T extends Option>(
    options: readonly (T | GroupedOption<T>)[],
    selectedValue: string | number | null,
  ): T | null => {
    const option: T | null = options.reduce<T | null>(
      (acc: T | null, option: T | GroupedOption<T>) => {
        if ('options' in option) {
          const currentOption = getCurrentOption(
            option.options as unknown as GroupedOption<T>[],
            selectedValue,
          );
          return currentOption || acc;
        } else {
          return option.value === selectedValue ? (option as T) : acc;
        }
      },
      null,
    );

    return option;
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ value, onChange, ref }) => (
        <StyledCol>
          <SelectInput
            value={getCurrentOption(options, value)}
            onChange={(val: { value: string; label: string }) =>
              onChange(val.value)
            }
            isInvalid={showErrorMode && !!errorMessage}
            options={options}
            disabled={disabled}
            placeholder={placeholder}
            reference={ref}
            {...rest}
          />
          {showErrorMode && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </StyledCol>
      )}
    />
  );
};

export default FormSelectInput;
