import { Col, Container, Row } from 'react-bootstrap';
import { Route, Switch } from 'react-router-dom';
import AuthAsideFrame from 'src/components/Auth/AuthAsideFrame';
import Navigation from 'src/components/Header/Navigation';
import EmailVerification from 'src/pages/EmailVerification';
import ForgetPassword from 'src/pages/ForgotPassword';
import Login from 'src/pages/Login';
import Register from 'src/pages/Register';
import ResetPassword from 'src/pages/ResetPassword';
// import Center from 'src/uikit/Layout/Center';
import styled from 'styled-components/macro';

import Routes from './Routes';

const AuthColumn = styled(Col)`
  padding: 0;
  height: 100vh;
  overflow-y: auto;
  overflow: hidden;
`;

const AuthCenter = styled.div`
  display: flex;
  justify-content: center;
  height: calc(100% - 70px);
  min-height: 400px;
  padding-block: 2rem;

  > *:first-child {
    margin-block: auto;
  }
`;

const AuthLayout = () => {
  return (
    <Container fluid>
      <Row>
        <AuthColumn xs="12" md="6">
          <Navigation isAuthLayout />

          <AuthCenter className="px-3">
            <Switch>
              <Route path={Routes.LOGIN} component={Login} />
              <Route path={Routes.REGISTER} component={Register} />
              <Route
                path={Routes.EMAIL_VERIFICATION}
                component={EmailVerification}
              />
              <Route path={Routes.FORGET_PASSWORD} component={ForgetPassword} />
              <Route path={Routes.RESET_PASSWORD} component={ResetPassword} />
              <Route path={Routes.CHOOSE_PASSWORD} component={ResetPassword} />
            </Switch>
          </AuthCenter>
        </AuthColumn>
        <AuthColumn xs="12" md="6">
          <AuthAsideFrame />
        </AuthColumn>
      </Row>
    </Container>
  );
};

export default AuthLayout;
