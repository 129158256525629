import { useCallback } from 'react';
import palette from 'src/uikit/theme/palette';
import { fontPrimary } from 'src/uikit/theme/typography';
import styled from 'styled-components/macro';

const Wrapper = styled('div')<{ bg: string }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px 5px;
  gap: 12px;
  width: 120px;

  background: ${({ bg }) => bg};
  border-radius: 100px;

  flex: none;
  order: 1;
  flex-grow: 0;
`;

const StyledButton = styled('button')<{ color: string }>`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: none;
  color: ${({ theme }) => theme.palette.main.white};
  background: ${({ color }) => color};

  flex: none;
  order: 0;
  flex-grow: 0;

  cursor: pointer;

  &:disabled {
    background: ${({ theme }) => theme.palette.black[60]};
  }

  &:hover:not(:disabled),
  &:active:not(:disabled) {
    filter: brightness(1.2);
  }
`;

const StyledInput = styled('input')<{ color: string }>`
  background: transparent;
  border: none;
  width: 100%;

  text-align: center;
  ${fontPrimary}
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: ${({ color }) => color};

  appearance: textfield;
  -moz-appearance: textfield;
  -webkit-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

interface Props {
  name: string;
  min?: number;
  max?: number;
  step?: number;
  disabled?: boolean;
  hidden?: boolean;
  defaultValue?: number;
  buttonsColor?: string;
  bgColor?: string;
  value: number;
  onChange: (newVal: number) => void;
  ref?: any;
}

const Counter = ({
  name,
  min,
  max,
  step = 1,
  disabled,
  hidden,
  defaultValue,
  buttonsColor,
  bgColor,
  value,
  onChange,
  ref,
}: Props) => {
  const handleLeftClick = useCallback(
    (e) => {
      e.preventDefault();
      onChange(Number(value) - step);
    },
    [onChange, step, value],
  );

  const handleRightClick = useCallback(
    (e) => {
      e.preventDefault();
      onChange(Number(value) + step);
    },
    [onChange, step, value],
  );

  return (
    <Wrapper bg={bgColor || palette.main.lightBlue} hidden={hidden}>
      <StyledButton
        disabled={(min || min === 0 ? value <= min : false) || disabled}
        onClick={handleLeftClick}
        color={buttonsColor || palette.main.primary}
      >
        -
      </StyledButton>
      <StyledInput
        type="number"
        min={min}
        max={max}
        step={step}
        value={Number(value || 0)}
        onChange={(e) => onChange(Number(e.target.value))}
        disabled={disabled}
        color={buttonsColor || palette.main.primary}
        ref={ref}
      />
      <StyledButton
        disabled={(max || max === 0 ? value >= max : false) || disabled}
        onClick={handleRightClick}
        color={buttonsColor || palette.main.primary}
      >
        +
      </StyledButton>
    </Wrapper>
  );
};

export default Counter;
