import styled, { css } from 'styled-components/macro';

export const Icon = styled.svg<{ $disabled?: boolean; $boxSize?: 'string' }>`
  ${({ $disabled }) =>
    $disabled &&
    css`
      color: ${({ theme }) => theme.palette.black[50]};
    `};

  ${({ $boxSize: boxSize }) =>
    css`
      width: ${boxSize ? boxSize : '42px'};
      height: ${boxSize ? boxSize : '42px'};
    `};
`;
