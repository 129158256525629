import { useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import { useAuth } from 'src/contexts/Auth';
import HardwareDashboard from 'src/pages/HardwareDashboard';
import Ordering from 'src/pages/Ordering';
import Partner from 'src/pages/Partner';
import PartnerProspect from 'src/pages/PartnerProspect';
import PartnerProspects from 'src/pages/PartnerProspects';
import Partners from 'src/pages/Partners';
import UserDetails from 'src/pages/UserDetails';
import Users from 'src/pages/Users';
import FirmwaresSubmenuLayout from 'src/router/FirmwaresSubmenuLayout';
import PrivateRoute from 'src/router/PrivateRoute';
import ProvisioningSubmenuLayout from 'src/router/ProvisioningSubmenuLayout';
import Routes from 'src/router/Routes';
import { Profiles } from 'src/sdk/authentification';

import PartnerConfiguration from '../../components/Partners/Configuration/index';

const AdminLayout = () => {
  const { authUser } = useAuth();

  const isAdmin = useMemo(
    () =>
      authUser?.profile !== Profiles.PARTNER_ADMIN &&
      authUser?.profile !== Profiles.ORIZON_ADMIN,
    [authUser?.profile],
  );

  if (isAdmin) {
    return <Redirect to={Routes.NURSINGS} />;
  }

  return (
    <>
      <PrivateRoute path={Routes.DASHBOARD} component={HardwareDashboard} />
      <PrivateRoute path={Routes.ORDER} component={Ordering} />
      <PrivateRoute
        path={Routes.EQUIPMENT}
        component={ProvisioningSubmenuLayout}
      />
      {authUser?.profile === Profiles.ORIZON_ADMIN && (
        <>
          <PrivateRoute path={Routes.PARTNERS} component={Partners} />
          <PrivateRoute path={Routes.PARTNER} component={Partner} />
        </>
      )}
      {authUser?.profile === Profiles.PARTNER_ADMIN && (
        <>
          <PrivateRoute
            path={Routes.PARTNER_CONFIG}
            component={PartnerConfiguration}
          />
          <PrivateRoute
            exact
            path={Routes.PARTNERS_PROSPECTS}
            component={PartnerProspects}
          />
          <PrivateRoute
            path={Routes.PARTNERS_PROSPECT}
            component={PartnerProspect}
          />
        </>
      )}

      <PrivateRoute exact path={Routes.USERS} component={Users} />
      <PrivateRoute path={Routes.USERS_DETAIL} component={UserDetails} />
      <PrivateRoute
        path={Routes.FIRMWARES}
        component={FirmwaresSubmenuLayout}
      />
    </>
  );
};

export default AdminLayout;
