import { useMemo } from 'react';
import { Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  SubStandard,
  SubDemo,
  SubDemoOutline,
  SubStandardOutline,
} from 'src/assets/list-icons';
import { NursingHomeSubscriptionResponse } from 'src/sdk/nursingHome';
import { SubscriptionStatus } from 'src/sdk/subscription';
import { Icon } from 'src/uikit/Icon';
import { Label } from 'src/uikit/Typography';
import { formatDate } from 'src/utils/date';

interface Props {
  subscription?: NursingHomeSubscriptionResponse;
}

const NursingHomeRowSubscription = ({ subscription }: Props) => {
  const { t, i18n } = useTranslation();

  const subscriptionInfo = useMemo(() => {
    if (!subscription) {
      return {
        icon: <Icon as={SubStandard} $disabled />,
        label: '-',
      };
    }

    switch (subscription.status) {
      case SubscriptionStatus.ACTIVE:
        return {
          icon:
            subscription.type === 'DEMO' ? (
              <Icon as={SubDemo} />
            ) : (
              <Icon as={SubStandard} />
            ),
          label: t(`components.nursingHomeList.NursingHomeRow.end`, {
            date: formatDate(subscription.endDate, i18n.language),
          }),
        };
      case SubscriptionStatus.CREATED:
      case SubscriptionStatus.BLOCKED:
        return {
          icon:
            subscription.type === 'DEMO' ? (
              <Icon as={SubDemoOutline} />
            ) : (
              <Icon as={SubStandardOutline} />
            ),
          label: t(`components.nursingHomeList.NursingHomeRow.start`, {
            date: formatDate(subscription.startDate, i18n.language),
          }),
        };
      case SubscriptionStatus.TERMINATED:
        return {
          icon:
            subscription.type === 'DEMO' ? (
              <Icon as={SubDemo} $disabled />
            ) : (
              <Icon as={SubStandard} $disabled />
            ),
          label: t(`components.nursingHomeList.NursingHomeRow.ended`, {
            date: formatDate(subscription.startDate, i18n.language),
          }),
        };
      default:
        return {
          icon: <Icon as={SubStandard} $disabled />,
          label: '-',
        };
    }
  }, [subscription, t, i18n]);

  return (
    <Stack direction="horizontal" gap={2}>
      {subscriptionInfo.icon}
      <Label className="ml-3">{subscriptionInfo.label}</Label>
    </Stack>
  );
};

export default NursingHomeRowSubscription;
