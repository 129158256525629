import { useAuth } from 'src/contexts/Auth';

import NursingHomeCreationListOntex from '../components/NursingHome/Creation/List/NursingHomeCreationListOntex';
import NursingHomeCreationListPartner from '../components/NursingHome/Creation/List/NursingHomeCreationListPartner';

const NursingHomeCreation = () => {
  const { authUser } = useAuth();

  return authUser?.partnerUser ? (
    <NursingHomeCreationListPartner />
  ) : (
    <NursingHomeCreationListOntex />
  );
};

export default NursingHomeCreation;
