import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PostalIcon } from 'src/assets/postal.svg';
import useCreatePartnerProspectAddressMutation from 'src/components/PartnerProspects/hooks/useCreatePartnerProspectAddressMutation';
import PartnerProspectsModal from 'src/components/PartnerProspects/PartnerProspectModal';
import useToast from 'src/hooks/useToast';
import { PartnerProspectAddress } from 'src/sdk/partner-prospect';

import useUpdatePartnerProspectDeliveryAddressMutation from '../../../hooks/useUpdatePartnerProspectDeliveryAddressMutation';
import PartnerProspectAddressUpsertForm, {
  PartnerProspectAddressUpsertFormSchema,
  partnerProspectAddressUpsertFormSchema,
} from './PartnerProspectAddressUpsertForm';

interface Props {
  partnerProspectId: number;
  address?: PartnerProspectAddress;
  show: boolean;
  onFinish: () => void;
}

const PartnerProspectAddressUpsertModal = ({
  partnerProspectId,
  address,
  show,
  onFinish,
}: Props) => {
  const { t } = useTranslation();
  const { toastSuccess, toastError } = useToast();

  const { createPartnerProspectAddressMutation } =
    useCreatePartnerProspectAddressMutation();
  const { updatePartnerProspectDeliveryAddressMutation } =
    useUpdatePartnerProspectDeliveryAddressMutation();

  const upsertDefaultValues: PartnerProspectAddressUpsertFormSchema =
    useMemo(() => {
      const _address = address;
      return {
        name: _address?.name || '',
        street: _address?.street || '',
        zip: _address?.zip || '',
        town: _address?.town || '',
        country: _address?.country || '',
        email: _address?.email || '',
        telephone: _address?.telephone || '',
      };
    }, [address]);

  const upsertMethods = useForm<PartnerProspectAddressUpsertFormSchema>({
    resolver: yupResolver(partnerProspectAddressUpsertFormSchema(t)),
    defaultValues: upsertDefaultValues,
    mode: 'onChange',
  });

  const { reset } = upsertMethods;

  const title = useMemo(() => {
    return address?.id
      ? t(
          'components.PartnerProspects.PartnerProspectDetails.PartnerProspectAddressUpsertModal.titleUpdate',
        )
      : t(
          'components.PartnerProspects.PartnerProspectDetails.PartnerProspectAddressUpsertModal.titleCreate',
        );
  }, [address?.id, t]);

  const onSubmit = async (values: PartnerProspectAddressUpsertFormSchema) => {
    try {
      if (address) {
        await updatePartnerProspectDeliveryAddressMutation.mutateAsync({
          partnerProspectId,
          id: address.id,
          name: values.name,
          street: values.street,
          zip: values.zip,
          town: values.town,
          country: values.country,
          email: values.email,
          telephone: values.telephone,
        });
        toastSuccess(
          t(
            'components.PartnerProspects.PartnerProspectDetails.PartnerProspectAddressUpsertModal.success',
          ),
        );
      } else {
        await createPartnerProspectAddressMutation.mutateAsync({
          partnerProspectId,
          name: values.name,
          street: values.street,
          zip: values.zip,
          town: values.town,
          country: values.country,
          email: values.email,
          telephone: values.telephone,
        });
        toastSuccess(
          t(
            'components.PartnerProspects.PartnerProspectDetails.PartnerProspectAddressUpsertModal.success',
          ),
        );
      }
      handleClose();
    } catch (err: any) {
      if (err.code) {
        return toastError(t(`errorsApi.${err.code}`));
      }
      toastError(
        t(
          'components.PartnerProspects.PartnerProspectDetails.PartnerProspectAddressUpsertModal.error',
        ),
      );
    }
  };

  const handleClose = useCallback(() => {
    reset();
    onFinish?.();
  }, [onFinish, reset]);

  const onCancel = () => {
    handleClose();
  };

  return (
    <PartnerProspectsModal
      title={title}
      icon={<PostalIcon />}
      show={show}
      handleClose={handleClose}
    >
      <FormProvider {...upsertMethods}>
        <PartnerProspectAddressUpsertForm
          isUpdate={Boolean(address?.id)}
          onSubmit={onSubmit}
          onCancel={onCancel}
        />
      </FormProvider>
    </PartnerProspectsModal>
  );
};

export default PartnerProspectAddressUpsertModal;
