import { format } from 'date-fns';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { ReactComponent as BlockIcon } from 'src/assets/block.svg';
import { ReactComponent as DeleteIcon } from 'src/assets/delete-badge.svg';
import request from 'src/axios';
import { FirmwareContent } from 'src/sdk/firmware';
import { Card } from 'src/uikit/Card';
import palette from 'src/uikit/theme/palette';
import styled from 'styled-components/macro';

const TableHeader = styled.div`
  width: 70%;
  display: flex;
  justifycontent: space-between;
  color: ${({ theme }) => theme.palette.main.primary};
  margin: 24px 0;
  font-size: 16px;
  padding: 0px 12px;
`;

const HoveredCard = styled(Card)`
  width: 100%;
  margin-bottom: 15px;
  padding: 8px 0;
  background-color: ${({ theme }) => theme.palette.main.white};
  border-radius: 10px;
  opacity: 1;
  box-shadow: 0.3px 0.4px 0.6px hsl(0deg 0% 70% / 0.33),
    1.2px 1.6px 3px -2px hsl(0deg 0% 70% / 0.41);
  transition: transform 0.1s ease-in, opacity 0.1s ease-in,
    box-shadow 0.1s ease-in-out;
  &:hover {
    box-shadow: 0.3px 0.4px 0.6px hsl(0deg 0% 70% / 0.31),
      1.3px 2.2px 3.1px -1px hsl(0deg 0% 70% / 0.36),
      4.6px 7.8px 10.9px -2px hsl(0deg 0% 70% / 0.41);
    transform: scaleX(1.01);
    opacity: 1;
  }
`;

const Tooltip = styled.div`
  position: absolute;
  background: ${({ theme }) => theme.palette.main.primary};
  right: 0;
  padding: 8px;
  top: -70px;
  border-radius: 12px;
  border: 1px solid ${palette.main.white};
  color: ${palette.main.white};
  z-index: 10;
  box-shadow: 0.3px 0.4px 0.6px hsl(0deg 0% 70% / 0.31),
    1.3px 2.2px 3.1px -1px hsl(0deg 0% 70% / 0.36),
    4.6px 7.8px 10.9px -2px hsl(0deg 0% 70% / 0.41);

  &:before {
    content: ' ';
    display: block;
    height: 20px;
    position: absolute;
    border-color: ${palette.main.white} transparent transparent transparent;
    border-style: solid;
    border-width: 12px;
    top: 64px;
    right: 12px;
  }
  &:after {
    border-left: solid transparent 10px;
    border-right: solid transparent 10px;
    border-top: solid ${palette.main.primary} 10px;
    content: ' ';
    height: 0;
    right: 14px;
    top: 64px;
    position: absolute;
    width: 0;
  }
`;

type Props = {
  firmwares: Array<FirmwareContent>;
  callback: () => void;
};

const FirmwaresHistory = ({ firmwares, callback }: Props) => {
  const { t } = useTranslation();
  const [hovered, setHovered] = useState<number | null>(null);
  const { mutateAsync } = useMutation(
    ({
      id,
      ...params
    }: {
      id: number;
      versionMajor: number;
      versionMinor: number;
      versionRevision: number;
      archived: boolean;
    }) => request.put(`firmwares/${id}`, params),
  );
  return (
    <>
      <h4>
        <strong>{t('firmwares-history')}</strong>
      </h4>
      <TableHeader>
        <strong style={{ width: '25%' }}>{t('version')}</strong>
        <strong style={{ width: '25%', textAlign: 'center' }}>
          {t('uploaded-date')}
        </strong>
        <strong style={{ width: '25%', textAlign: 'center' }}>
          {t('used-in-nh')}
        </strong>
        <strong style={{ width: '25%', textAlign: 'center' }}>
          {t('clipons')}
        </strong>
      </TableHeader>
      {firmwares?.map((firmware: FirmwareContent, key: number) => (
        <HoveredCard
          key={key}
          {...(firmware.archived && {
            style: { position: 'relative', color: palette.black['60'] },
          })}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              padding: '8px 12px',
            }}
          >
            <div
              style={{
                width: '70%',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <span style={{ width: '25%' }}>
                {firmware.versionMajor}.{firmware.versionMinor}.
                {firmware.versionRevision}
              </span>
              <span style={{ width: '25%', textAlign: 'center' }}>
                {format(new Date(firmware.uploadDate), 'dd/MM/yyyy')}
              </span>
              <span style={{ width: '25%', textAlign: 'center' }}>
                {firmware.counts.nursingHomes}
              </span>
              <span style={{ width: '25%', textAlign: 'center' }}>{firmware?.counts?.clipons || 0}</span>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'end',
                width: '30%',
              }}
            >
              {!Boolean(firmware.counts.nursingHomes) && (
                <div
                  onMouseEnter={() => {
                    setHovered(key);
                  }}
                  onMouseLeave={() => {
                    setHovered(null);
                  }}
                  style={{ cursor: 'pointer' }}
                  {...(!firmware.archived && {
                    onClick: async () => {
                      await mutateAsync({
                        id: firmware.id,
                        versionMajor: firmware.versionMajor,
                        versionMinor: firmware.versionMinor,
                        versionRevision: firmware.versionRevision,
                        archived: true,
                      });
                      callback?.();
                    },
                  })}
                  >
                  {hovered === key && (
                    <Tooltip>
                      {firmware.archived ? (
                        <Trans
                          i18nKey="this-version-has-been-archived"
                          components={{
                            span: <span style={{ display: 'block' }} />,
                          }}
                        />
                      ) : (
                        <Trans
                          i18nKey="click-here-to-archive"
                          components={{
                            span: <span style={{ display: 'block' }} />,
                          }}
                        />
                      )}
                    </Tooltip>
                  )}
                  {firmware.archived ? <BlockIcon /> : <DeleteIcon />}
                </div>
              )}
            </div>
          </div>
        </HoveredCard>
      ))}
    </>
  );
};

export default FirmwaresHistory;
