import { format } from 'date-fns';
import { useCallback, useEffect, useMemo } from 'react';
import { Col, Row, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Address, Building, Subscription } from 'src/assets/list-icons';
import request from 'src/axios';
import { DATE_FORMAT_DAY } from 'src/constants';
import { useAuth } from 'src/contexts/Auth';
import useToast from 'src/hooks/useToast';
import { EquipmentTypes } from 'src/sdk/equipment';
import {
  DeliveryAddress,
  EstimatedEquipment,
  NursingHomeResponse,
} from 'src/sdk/nursingHome';
import { SubscriptionCreationRequest } from 'src/sdk/subscription';
import { Button } from 'src/uikit/Button';
import { Label, Strong } from 'src/uikit/Typography';
import { FormButtonsWrapper } from 'src/uikit/Wrapper';
import styled from 'styled-components/macro';

import EquipmentCardCounter from '../components/EquipmentCardCounter';
import useCreateOrUpdateSubscriptionMutation from '../hooks/useCreateOrUpdateSubscriptionMutation';

const AddressIcon = styled(Address)`
  width: 60px;
  height: 60px;
`;

const BuildingIcon = styled(Building)`
  width: 60px;
  height: 60px;
`;

const SubscriptionIcon = styled(Subscription)`
  width: 60px;
  height: 60px;
`;

const Section = styled.div`
  width: 100%;
  margin-bottom: 2rem;
`;

const Title = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #44444f;
  text-align: left;
  margin: 0;
`;

const Header = styled(Row)`
  margin-bottom: 30px;
`;

const ModifyCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
`;

const Content = styled(Row)`
  color: #92929d;
`;

const EquipmentSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  padding: 0;
  gap: 1rem;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`;

const StyledCol = styled(Col)`
  display: grid;
  gap: 0.5rem;
`;

const StyledRow = styled(Row)`
  align-items: center;

  display: flex;
  align-items: center;
`;

interface Props {
  data: SubscriptionCreationRequest;
  setCurrentStep: (val: number) => void;
  onSuccess: () => void;
  nh?: NursingHomeResponse;
  deliveryAddresses: DeliveryAddress[];
}

const SubscriptionCreateSummary = ({
  data,
  setCurrentStep,
  onSuccess,
  nh,
  deliveryAddresses,
}: Props) => {
  const { t } = useTranslation();

  const { nhSession, setNhSession } = useAuth();

  const { toastSuccess, toastError } = useToast();
  const { createOrUpdateSubscriptionMutation } =
    useCreateOrUpdateSubscriptionMutation();

  const { isLoading } = createOrUpdateSubscriptionMutation;

  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      e.preventDefault();

      const address = data.deliveryAddressId
        ? { deliveryAddressId: data.deliveryAddressId }
        : { customAddress: data.customAddress };

      createOrUpdateSubscriptionMutation
        .mutateAsync({
          ...data,
          ...(data.noShippingRequest && {
            extraChargingStations: null,
            extraGateways: null,
          }),
          ...(!data.noShippingRequest
            ? address
            : {
                customAddress: null,
                deliveryAddressId: null,
              }),
          requestedDeliveryDate: data.noShippingRequest
            ? null
            : data.requestedDeliveryDate,
          noShippingRequest: data.noShippingRequest ? true : false,
        })
        .then((response) => {
          toastSuccess(t('components.subscriptionCreation.validation.success'));

          if (nhSession && setNhSession) {
            setNhSession({
              ...nhSession,
              subscription: response.data,
            });
          }

          onSuccess();
        })
        .catch((e) =>
          toastError(t('common.errorHasOccured') + ' .' + e.message),
        );
    },
    [
      createOrUpdateSubscriptionMutation,
      data,
      nhSession,
      onSuccess,
      setNhSession,
      t,
      toastError,
      toastSuccess,
    ],
  );

  const { data: estimates, refetch } = useQuery<EstimatedEquipment, Error>(
    'compute-estimates',
    () =>
      request
        .get<EstimatedEquipment>(
          `/subscriptions/compute-estimates?residents=${data.residentEstimate}`,
        )
        .then((res) => res.data),
  );

  useEffect(() => {
    if (!!data && data.residentEstimate > 0) {
      refetch();
    }
  }, [data, refetch]);

  const currentDeliveryAddress = useMemo(() => {
    return deliveryAddresses.find(
      (address) =>
        data.deliveryAddressId && address.id === data.deliveryAddressId,
    );
  }, [data.deliveryAddressId, deliveryAddresses]);

  const durations = useMemo(
    () => [
      { value: 1, label: '1 month' },
      { value: 24, label: '2 years' },
      { value: 36, label: '3 years' },
      { value: 60, label: '5 years' },
    ],
    [],
  );

  const selectedDuration = useMemo(() => {
    return durations.find((duration) => duration.value === data.duration)
      ?.label;
  }, [data.duration, durations]);

  const estimatesValues = useMemo(() => {
    return data.type === 'DEMO'
      ? {
          cliponEstimate: 20,
          gatewayEstimate: 1,
          chargingStationEstimate: 1,
        }
      : {
          cliponEstimate: estimates?.cliponEstimate || 0,
          gatewayEstimate:
            (estimates?.gatewayEstimate || 0) + (data.extraGateways || 0),
          chargingStationEstimate:
            (estimates?.chargingStationEstimate || 0) +
            (data.extraChargingStations || 0),
        };
  }, [
    data.extraChargingStations,
    data.extraGateways,
    data.type,
    estimates?.chargingStationEstimate,
    estimates?.cliponEstimate,
    estimates?.gatewayEstimate,
  ]);

  return (
    <div>
      <Row className="my-4 py-2">
        <Label>{t('components.subscriptionCreation.validation.title')}</Label>
      </Row>
      <Section>
        <Header>
          <Col lg="12">
            <Title>
              {t('components.subscriptionCreation.validation.nursing.title')}
            </Title>
          </Col>
        </Header>
        <Content>
          <Col lg="2">
            <BuildingIcon />
          </Col>
          <StyledCol lg="5">
            <Stack direction="horizontal" gap={2}>
              <Label>
                {t('components.subscriptionCreation.validation.nursing.name')} :
              </Label>
              {nh?.name}
            </Stack>
            <Stack direction="horizontal" gap={2}>
              <Label>
                {t(
                  'components.subscriptionCreation.validation.nursing.address',
                )}{' '}
                :
              </Label>
              {nh?.address?.street} - {nh?.address?.town} {nh?.address?.zip}
            </Stack>
          </StyledCol>
        </Content>
      </Section>
      <Section>
        <Header>
          <Col lg="10">
            <Title>
              {t(
                'components.subscriptionCreation.validation.subscription.title',
              )}
            </Title>
          </Col>
          <ModifyCol lg="2">
            <Button onClick={() => setCurrentStep(0)}>
              {t('components.subscriptionCreation.validation.modifyButton')}
            </Button>
          </ModifyCol>
        </Header>
        <Content>
          <Col lg="2">
            <SubscriptionIcon />
          </Col>
          <StyledCol lg="5">
            <Stack direction="horizontal" gap={2}>
              <Label>
                {t(
                  'components.subscriptionCreation.validation.subscription.type',
                )}{' '}
                :
              </Label>
              {data.type}
            </Stack>
            <Stack direction="horizontal" gap={2}>
              <Label>
                {t(
                  'components.subscriptionCreation.validation.subscription.duration',
                )}{' '}
                :
              </Label>
              {selectedDuration}
            </Stack>
          </StyledCol>
          <StyledCol lg="4">
            <Stack direction="horizontal" gap={2}>
              <Label>
                {t(
                  'components.subscriptionCreation.validation.subscription.startingDate',
                )}{' '}
                :
              </Label>
              {format(new Date(data.startDate), DATE_FORMAT_DAY)}
            </Stack>
            <Stack direction="horizontal" gap={2}>
              <Label>
                {t(
                  'components.subscriptionCreation.validation.subscription.residents',
                )}{' '}
                :
              </Label>
              {data.residentEstimate}
            </Stack>
          </StyledCol>
        </Content>
      </Section>
      {!data.noShippingRequest ? (
        <>
          <Section>
            <Header>
              <Col md="10">
                <Title>
                  {t(
                    'components.subscriptionCreation.validation.delivery.title',
                  )}
                </Title>
              </Col>
              <ModifyCol lg="2">
                <Button onClick={() => setCurrentStep(1)}>
                  {t('components.subscriptionCreation.validation.modifyButton')}
                </Button>
              </ModifyCol>
            </Header>
            <Content>
              <Col md="2">
                <AddressIcon />
              </Col>
              <Col md="10">
                <StyledRow>
                  <Col md="12">
                    <Label>
                      {t(
                        'components.subscriptionCreation.validation.delivery.date',
                      )}{' '}
                      :
                    </Label>
                    {format(
                      new Date(data.requestedDeliveryDate || ''),
                      DATE_FORMAT_DAY,
                    )}
                  </Col>
                </StyledRow>
                <StyledRow>
                  <Col md="12">
                    <Label>
                      {t(
                        'components.subscriptionCreation.validation.delivery.name',
                      )}{' '}
                      :
                    </Label>
                    {currentDeliveryAddress?.name || data.customAddress?.name}
                  </Col>
                </StyledRow>
                <StyledRow>
                  <Col md="12">
                    <Label>
                      {t(
                        'components.subscriptionCreation.validation.delivery.address',
                      )}{' '}
                      :
                    </Label>
                    {currentDeliveryAddress?.street ||
                      data.customAddress?.street}
                  </Col>
                </StyledRow>
                <StyledRow>
                  <Col md="4">
                    <Label>
                      {t(
                        'components.subscriptionCreation.validation.delivery.zip',
                      )}{' '}
                      :
                    </Label>
                    {currentDeliveryAddress?.zip || data.customAddress?.zip}
                  </Col>
                  <Col md="4">
                    <Label>
                      {t(
                        'components.subscriptionCreation.validation.delivery.town',
                      )}{' '}
                      :
                    </Label>
                    {currentDeliveryAddress?.town || data.customAddress?.town}
                  </Col>
                  <Col md="4">
                    <Label>
                      {t(
                        'components.subscriptionCreation.validation.delivery.country',
                      )}{' '}
                      :
                    </Label>
                    {currentDeliveryAddress?.country ||
                      data.customAddress?.country}
                  </Col>
                </StyledRow>
                <StyledRow>
                  <Col md="6">
                    <Label>
                      {t(
                        'components.subscriptionCreation.validation.delivery.email',
                      )}{' '}
                      :
                    </Label>
                    {currentDeliveryAddress?.email || data.customAddress?.email}
                  </Col>
                  <Col md="6">
                    <Label>
                      {t(
                        'components.subscriptionCreation.validation.delivery.phone',
                      )}{' '}
                      :
                    </Label>
                    {currentDeliveryAddress?.telephone ||
                      data.customAddress?.telephone}
                  </Col>
                </StyledRow>
              </Col>
            </Content>
          </Section>
          <Section>
            <Header>
              <Col lg="10">
                <Title>
                  {t(
                    'components.subscriptionCreation.validation.equipments.title',
                  )}
                </Title>
              </Col>
              <ModifyCol lg="2">
                <Button onClick={() => setCurrentStep(1)}>
                  {t('components.subscriptionCreation.validation.modifyButton')}
                </Button>
              </ModifyCol>
            </Header>
            <EquipmentSection>
              <EquipmentCardCounter
                type={EquipmentTypes.CLIPON}
                value={estimatesValues.cliponEstimate}
              />
              <EquipmentCardCounter
                type={EquipmentTypes.GATEWAY}
                value={estimatesValues.gatewayEstimate}
              />
              <EquipmentCardCounter
                type={EquipmentTypes.CHARGING}
                value={estimatesValues.chargingStationEstimate}
              />
            </EquipmentSection>
          </Section>
        </>
      ) : (
        <>
          <Section>
            <Header>
              <Col md="10">
                <Title>
                  {t(
                    'components.subscriptionCreation.validation.delivery.title',
                  )}
                </Title>
              </Col>
            </Header>
            <Content className="align-items-center">
              <Col md="2">
                <AddressIcon />
              </Col>
              <Col md="10">
                <Strong>No equipements to be sent</Strong>
              </Col>
            </Content>
          </Section>
          <Section>
            <Header>
              <Col lg="10">
                <Title>
                  {t(
                    'components.subscriptionCreation.validation.equipments.title',
                  )}
                </Title>
              </Col>
            </Header>
            <EquipmentSection>
              <EquipmentCardCounter type={EquipmentTypes.CLIPON} value={0} />
              <EquipmentCardCounter type={EquipmentTypes.GATEWAY} value={0} />
              <EquipmentCardCounter type={EquipmentTypes.CHARGING} value={0} />
            </EquipmentSection>
          </Section>
        </>
      )}
      <FormButtonsWrapper oneButton>
        <Button disabled={isLoading} onClick={onSubmit}>
          {t('common.action.finish')}
        </Button>
      </FormButtonsWrapper>
    </div>
  );
};

export default SubscriptionCreateSummary;
