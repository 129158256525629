import PartnerGeneralForm from 'src/components/Partners/Details/General/PartnerGeneralForm';
import PartnerGeneralSynchronize from 'src/components/Partners/Details/General/PartnerGeneralSynchronize';
import { Partner } from 'src/sdk/partner';
import styled from 'styled-components/macro';

interface Props {
  partner: Partner;
}

const Wrapper = styled.div`
  display: grid;
  gap: 2rem;

  > *:first-child {
    z-index: 100;
  }
`;

const PartnerGeneral = ({ partner }: Props) => {
  return (
    <Wrapper>
      <PartnerGeneralForm partner={partner} />
      <PartnerGeneralSynchronize partner={partner} />
    </Wrapper>
  );
};

export default PartnerGeneral;
