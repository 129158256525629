import EquipmentList from 'src/components/Equipment/EquipmentList';
import { PageWrapper } from 'src/uikit/Wrapper';

const EquipmentGateway = () => {
  return (
    <PageWrapper>
      <EquipmentList
        type="gateway"
        defaultSortField={{
          label: 'serialNumber',
          direction: 'asc',
        }}
      />
    </PageWrapper>
  );
};

export default EquipmentGateway;
