import { ReactNode } from 'react';
import { Badge, Modal, Col } from 'react-bootstrap';
import { H5 } from 'src/uikit/Typography';
import styled, { css } from 'styled-components/macro';

interface ModalProps {
  $hasOpacity?: boolean;
  height?: string;
}

const StyledModal = styled(Modal)<ModalProps>`
  opacity: ${({ $hasOpacity }) => ($hasOpacity ? 0.5 : 1)};
  box-shadow: ${({ theme }) => theme.shadows.sm};

  .customModal {
    ${({ height }) =>
      height &&
      css`
        min-height: ${height};
        max-height: ${height};
      `}
  }
`;

const StyledHeader = styled(Modal.Header)`
  border-bottom: 0;

  .close > * {
    display: none;
  }

  .close ::before {
    content: 'x';
    visibility: visible;
    font-weight: 400;
    color: black;
    display: inline-block;
    width: 24px;
  }
`;

const StyledCol = styled(Col)`
  padding: 0 0 0 2rem;
  text-align: center;
`;

interface StepIconProps {
  $isSuccess?: boolean;
}

const StepBadge = styled(Badge)<StepIconProps>`
  background-color: ${({ $isSuccess, theme }) =>
    !$isSuccess
      ? theme.palette.main.primary
      : theme.palette.main.greenElectric};
`;

const StepText = styled(H5)`
  margin: 2px 5px;
  font-size: 17px;
  color: ${({ theme }) => theme.palette.main.white};
`;

const StyledBody = styled(Modal.Body)`
  text-align: center;
  padding: 0 1rem 1rem 1rem;
  display: grid;
`;

interface DefaultModalProps {
  children: ReactNode;
  isVisible: boolean;
  hasStep?: boolean;
  stepCount?: number;
  stepNumber?: number;
  size?: 'sm' | 'lg' | 'xl';
  opacity?: boolean;
  handleClose: () => void;
  header?: any;
  customHeight?: string;
  customClose?: boolean;
}

const DefaultModal = ({
  children,
  handleClose,
  isVisible,
  hasStep,
  size,
  stepCount,
  opacity,
  stepNumber,
  header,
  customHeight,
  customClose,
}: DefaultModalProps) => {
  return (
    <StyledModal
      size={size}
      show={isVisible}
      onHide={handleClose}
      $hasOpacity={opacity}
      height={customHeight}
      contentClassName="customModal"
    >
      {hasStep ? (
        <StyledHeader closeButton>
          <StyledCol>
            <StepBadge pill $isSuccess={stepNumber === stepCount}>
              <StepText>{stepNumber}</StepText>
            </StepBadge>
          </StyledCol>
        </StyledHeader>
      ) : (
        <StyledHeader closeButton={customClose ? false : true}>
          {header}
        </StyledHeader>
      )}

      <StyledBody>{children}</StyledBody>
    </StyledModal>
  );
};

export default DefaultModal;
