import styled, { css } from 'styled-components/macro';

interface Props {
  $isInactive: boolean;
}

export const DetailsCard = styled.div<Props>`
  min-height: 80px;
  box-sizing: border-box;
  border-radius: 10px;
  ${({ $isInactive }) =>
    $isInactive
      ? css`
          border: 1px solid ${({ theme }) => theme.palette.black[60]};
          background-color: ${({ theme }) => theme.palette.black.lightGrey};
        `
      : css`
          border: 1px solid ${({ theme }) => theme.palette.main.primary};
          background-color: ${({ theme }) => theme.palette.main.lightBlue};
        `}
`;
