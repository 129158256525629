import { Controller, useFormContext } from 'react-hook-form';

import Counter from '../Counter';

interface Props {
  name: string;
  mainColor?: string;
  secondaryColor?: string;
  disabled?: boolean;
  hidden?: boolean;
  min?: number;
  max?: number;
  step?: number;
  defaultValue?: number;
}

const CounterInput = ({
  name,
  mainColor,
  secondaryColor,
  disabled,
  hidden,
  min,
  max,
  step,
  defaultValue,
}: Props) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue || 0}
      render={({ value, onChange, ref }) => (
        <Counter
          name={name}
          buttonsColor={mainColor}
          bgColor={secondaryColor}
          disabled={disabled}
          hidden={hidden}
          min={min}
          max={max}
          step={step}
          value={value}
          onChange={onChange}
        />
      )}
    />
  );
};

export default CounterInput;
