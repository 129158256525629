import { Container, Spinner } from 'react-bootstrap';
import {
  generatePath,
  Redirect,
  Switch,
  useLocation,
  useParams,
} from 'react-router-dom';
import NursingHomeConfigurationBillings from 'src/components/NursingHome/Configuration/Billings';
import NursingHomeConfigurationProducts from 'src/components/NursingHome/Configuration/Products/NursingHomeConfigurationProducts';
import useFetchNursingHomeQuery from 'src/components/NursingHome/hooks/useFetchNursingHomeQuery';
import PrivateRoute from 'src/router/PrivateRoute';
import Routes from 'src/router/Routes';
import { RouteParams } from 'src/sdk/nursingHome';

import UpdateAddresses from './Addresses';
import GeneralSettings from './GeneralSettings';
import NursingHomeManageUsers from './ManageUsers';
import NursingHomeConfigurationHorizontalMenu from './NursingHomeConfigurationHorizontalMenu';

const NursingHomeConfiguration = () => {
  const { id } = useParams<RouteParams>();
  const location = useLocation();

  const { data, isLoading, error } = useFetchNursingHomeQuery({
    id: Number(id),
    expand: [
      'firmware',
      'parameters',
      'delivery-addresses',
      'delivery-addresses-shipping-request-count',
      'nursing-home-user',
    ],
  });

  if (isLoading) {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <Spinner animation="border" variant="secondary" />
      </div>
    );
  }

  if (location.pathname === generatePath(Routes.NURSING_CONFIG, { id })) {
    return (
      <Redirect to={generatePath(Routes.NURSING_CONFIG_GENERAL, { id })} />
    );
  }

  return (
    <Container fluid className="p-0">
      <NursingHomeConfigurationHorizontalMenu />
      {data && (
        <Switch>
          <PrivateRoute path={Routes.NURSING_CONFIG_GENERAL}>
            <GeneralSettings
              id={Number(id)}
              nursingHome={data}
              isError={!!error}
            />
          </PrivateRoute>
          <PrivateRoute path={Routes.NURSING_CONFIG_BILLINGS}>
            {data && <NursingHomeConfigurationBillings nursingHome={data} />}
          </PrivateRoute>
          <PrivateRoute path={Routes.NURSING_CONFIG_ADDRESSES}>
            {data && (
              <UpdateAddresses id={id} isError={!!error} nursingHome={data} />
            )}
          </PrivateRoute>
          <PrivateRoute path={Routes.NURSING_CONFIG_PRODUCTS}>
            {data && <NursingHomeConfigurationProducts nursingHome={data} />}
          </PrivateRoute>
          <PrivateRoute path={Routes.NURSING_CONFIG_MANAGE_USERS}>
            {data && <NursingHomeManageUsers nursingHome={data} />}
          </PrivateRoute>
        </Switch>
      )}
    </Container>
  );
};

export default NursingHomeConfiguration;
