import { useMemo } from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ProfileAvatar from 'src/components/Profile/ProfileAvatar';
import ProfileForm, {
  ProfileFormSchema,
} from 'src/components/Profile/ProfileForm';
import useFetchUserQuery from 'src/components/Users/hooks/useFetchUserQuery';
import useUpdateUserEmailMutation from 'src/components/Users/hooks/useUpdateUserEmailMutation';
import useUpdateUserEmployeeIdMutation from 'src/components/Users/hooks/useUpdateUserEmployeeIdMutation';
import useUpdateUserMutation from 'src/components/Users/hooks/useUpdateUserMutation';
import useUpdateUserProfileMutation from 'src/components/Users/hooks/useUpdateUserProfileMutation';
import { useApp } from 'src/contexts/App';
import { useAuth } from 'src/contexts/Auth';
import useToast from 'src/hooks/useToast';
import { Gender } from 'src/sdk/common';
import { UpdateUserRequest } from 'src/sdk/users';
import { Locales, locales } from 'src/translations/i18n';
import { fontPrimary } from 'src/uikit/theme/typography';
import { H1 } from 'src/uikit/Typography';
import styled from 'styled-components/macro';

import { areDifferentStrings } from "../utils/string";

const Wrapper = styled.div`
  margin-left: 13px;
  margin-right: 19px;
`;

const Title = styled(H1)`
  ${fontPrimary}
  line-height: 28px;
  font-size: 16px;
  padding: 0.5rem;
  font-style: normal;
  font-weight: 600;
`;

const StyledRow = styled(Row)`
  background: ${({ theme }) => theme.palette.main.white};
  border-radius: 10px;
  padding: 1.5rem;
  min-height: 400px;
`;

const Profile = () => {
  const { t } = useTranslation();
  const { authUser, setAuthUser } = useAuth();
  const { setLanguage } = useApp();
  const { toastSuccess, toastError } = useToast();
  const { updateUserMutation } = useUpdateUserMutation();
  const { updateUserEmployeeIdMutation } = useUpdateUserEmployeeIdMutation();
  const { updateUserEmailMutation } = useUpdateUserEmailMutation();
  const { updateUserProfileMutation } = useUpdateUserProfileMutation();
  const { data, isLoading, refetch } = useFetchUserQuery(
    Number(authUser?.id),
    ["avatar-image-path"],
  );

  
  const defaultValues: ProfileFormSchema = useMemo(() => {
    return {
      firstName: data?.firstName || '',
      lastName: data?.lastName || '',
      email: data?.email || '',
      profile: data?.profile || '',
      language: data?.language || '',
      employeeId: data?.employeeId || '',
      gender: data?.gender || Gender.Male,
    };
  }, [data]);

  const onSubmit = async (values: ProfileFormSchema) => {
    try {
      const result = await updateUserMutation.mutateAsync({
        id: Number(authUser?.id),
        firstName: values.firstName,
        lastName: values.lastName,
        gender: values.gender,
        language: values.language as UpdateUserRequest['language'],
      });
      if (areDifferentStrings(values?.employeeId, result.data.employeeId)) {
        await updateUserEmployeeIdMutation.mutateAsync({
          id: Number(authUser?.id),
          employeeId: values.employeeId,
        });
      }
      if (values?.email !== result.data.email) {
        await updateUserEmailMutation.mutateAsync({
          id: Number(authUser?.id),
          email: values.email,
        });
      }
      if (values?.profile !== result.data.profile) {
        await updateUserProfileMutation.mutateAsync({
          id: Number(authUser?.id),
          profile: values.profile as any,
        });
      }
      await setAuthUser?.({
        id: result.data.id,
        partnerUser: authUser?.partnerUser || false,
        firstName: result.data.firstName,
        lastName: result.data.lastName,
        language: result.data.language,
        profile: result.data.profile,
        token: authUser?.token,
        avatarImagePath: data?.avatarImagePath,
      });
      toastSuccess(t('components.profileForm.success'));
    } catch (err) {
      toastError(t('components.profileForm.error'));
    }
    refetch();
    setLanguage?.(
      locales.find((locale) => values.language === locale.tag)?.locale ||
        Locales.EN,
    );
  };
  return (
    <Wrapper>
      <Title>{t('components.profileForm.title')}</Title>
      {isLoading && (
        <div className="d-flex align-items-center justify-content-center">
          <Spinner animation="border" variant="secondary" />
        </div>
      )}
      <Container>
        <StyledRow>
          {
            isLoading ?
            null
             :
            <Col md="2" className="d-flex flex-column py-4">
              <ProfileAvatar user={data} onChange={() => refetch()} />
            </Col>
          }
          <Col md="10" className="d-flex py-4 ">
            <ProfileForm defaultValues={defaultValues} onSubmit={onSubmit} />
          </Col>
        </StyledRow>
      </Container>
    </Wrapper>
  );
};

export default Profile;
