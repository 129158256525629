import { Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ReactComponent as MovementIcon } from 'src/assets/movement.svg';
import {
  ShippingRequestDeliveryAddressResponse,
  ShippingRequestMovementResponse,
} from 'src/sdk/shippingRequest';
import { HeaderTableLabel } from 'src/uikit/Typography';
import styled from 'styled-components/macro';

// import EquipmentsWithNumber from './EquipmentsWithNumber';
import ShippingAddress from './ShippingAddress';

const Wrapper = styled.div`
  padding-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const WrapperMovement = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Separator = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.palette.black[50]};
`;

export type Movement = {
  sentOnDate: string;
  trackingNumber?: string;
  parcelService?: string;
  equipment: {
    gatewayNb: number;
    clipOnNb: number;
    chargingNb: number;
  };
};

const StyledMovementIcon = styled(MovementIcon)`
  width: 40px;
  height: 40px;
`;

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const StyledSpacedRow = styled(StyledRow)`
  justify-content: space-between;
`;

const StyledCenteredRow = styled(StyledRow)`
  justify-content: center;
`;

const StyledCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface Props {
  address?: ShippingRequestDeliveryAddressResponse;
  movements?: Array<ShippingRequestMovementResponse>;
}

const ShippingRequestDetailsMovements = ({ address, movements }: Props) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Separator />
      {address && <ShippingAddress address={address} />}
      {movements && movements.length > 0 && (
        <WrapperMovement>
          <StyledSpacedRow>
            <StyledRow>
              <StyledCol md={{ span: 2, offset: 2 }}>
                <HeaderTableLabel>{t('common.sentOn')}</HeaderTableLabel>
              </StyledCol>
            </StyledRow>
            <StyledCenteredRow />
          </StyledSpacedRow>
          {movements?.map((movement, index) => (
            <StyledSpacedRow key={index}>
              <StyledRow>
                <StyledCol md="2">
                  <StyledMovementIcon />
                </StyledCol>
                <StyledCol md="2">{movement.date}</StyledCol>
                <StyledCol md="2">
                  {movement.handlingUnitId ? movement.handlingUnitId : ''}
                </StyledCol>
                <StyledCol md="2">
                  {movement.materialNumberCode
                    ? movement.materialNumberCode
                    : ''}
                </StyledCol>
              </StyledRow>
              <StyledCenteredRow>
                <StyledCol md="5">
                  {/* <EquipmentsWithNumber
                    clipOnNb={movement..}
                    gatewayNb={gatewayNb}
                    chargingStationNb={chargingNb}
                  /> */}
                </StyledCol>
              </StyledCenteredRow>
            </StyledSpacedRow>
          ))}
        </WrapperMovement>
      )}
    </Wrapper>
  );
};

export default ShippingRequestDetailsMovements;
