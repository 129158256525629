import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import request from 'src/axios';
import FirmwareCard from 'src/components/Firmware/FirmwareCard';
import Pagination from 'src/components/Misc/Pagination';
import SortingFirmwareHeader from 'src/components/SortingHeaders/SortingFirmwareHeader';
import useSortedList from 'src/hooks/useSortedList';
import { GetNursingHomeResponse } from 'src/sdk/nursingHome';
import { AlertCard } from 'src/uikit/AlertCard';
import { SpinnerLoader } from 'src/uikit/SpinnerLoader';

const PAGE_SIZE = 50;

type QueryParams = {
  page: number;
  query: string;
  outdated: boolean;
};

async function fetchFirmware(queryParams: QueryParams) {
  const { data } = await request.get(
    '/nursing-homes?expand=counts,firmware,clipons',
    {
      params: {
        page: queryParams.page,
        size: PAGE_SIZE,
        'name-part':
          queryParams.query.length > 0 ? queryParams.query : undefined,
        'has-outdated-clipon': queryParams.outdated,
        "include-all-homes": true,
      },
    },
  );
  return data;
}

interface Props {
  query: string;
  outdated: boolean;
  selectedVersion : string
}

const FirmwareList = ({ query, outdated, selectedVersion }: Props) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);

  const { data, isLoading } = useQuery<GetNursingHomeResponse, Error>(
    ['firmware for nursing homes', { page, query, outdated }],
    () => fetchFirmware({ page, query, outdated }),
  );


  const dataExtended = useMemo(() => {
        
    if (!data) return;
    let results = [];  
    
    results = data.content.map((content) => {
      if (!content.counts && !content.firmware) return content;

      //We capitalize the first letter
      content.name = content.name.charAt(0).toUpperCase() + content.name.slice(1);

      const contentExtended = { ...content };

      // We place cliponCount and wrongFirmwareCliponCount as
      // "primary" fields and not expand fields

      contentExtended.cliponCount = content.counts
        ? content.counts.cliponCount
        : 0;
      contentExtended.wrongFirmwareCliponCount = content.counts
        ? content.counts.wrongFirmwareCliponCount
        : 0;

      if(contentExtended.counts){
        if(contentExtended.counts.cliponCount === 0){
          contentExtended.concatVersion = "0.0.0"

        }else {
          if (contentExtended.firmware) {
            contentExtended.concatVersion = `${contentExtended.firmware.versionMajor}.${contentExtended.firmware.versionMinor}.${contentExtended.firmware.versionRevision}`;
            if(contentExtended.clipons.length > 0 ){
              contentExtended.clipons.map(clipon => {
                clipon.concatVersion = `${clipon.firmwareMajorVersion}.${clipon.firmwareMinorVersion}.${clipon.firmwareRevisionVersion}`;
              }) 
            }
          }
        }
      }

      return contentExtended;
    });
    return results;
  }, [data, outdated, selectedVersion]);

  
  let { sortedItems, handleSortFieldChange } = useSortedList(dataExtended);

  
  if ((selectedVersion !== "" && selectedVersion !== "All") && sortedItems) {
    sortedItems = sortedItems
      .filter(item => item.cliponCount > 0)
      .map(item => {
        item.clipons = item.clipons.filter(clipon => clipon.concatVersion === selectedVersion);
        return item;
      });

      sortedItems = sortedItems.filter(item => item.clipons.length > 0)
  }
    


  useEffect(() => {
    setPage(0);
  }, [query]);

  return (
    <div>
      <SortingFirmwareHeader onSortingChange={handleSortFieldChange} />
      {data?.empty && <AlertCard>{t('components.list.noResult')}</AlertCard>}
      {isLoading && (
        <div className="d-flex justify-content-center align-items-center">
          <SpinnerLoader animation="border" />
        </div>
      )}
      <FirmwareCard nursingHomeDetails={sortedItems} />
      {data && data.totalPages > 1 && (
        <Pagination
          totalElements={data.totalElements}
          perPage={PAGE_SIZE}
          onPageChange={setPage}
          currentPage={page}
        />
      )}
    </div>
  );
};

export default FirmwareList;
