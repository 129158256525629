import { useQuery } from 'react-query';
import request from 'src/axios';
import { BillingModes } from 'src/sdk/billing';

const fetchBillingModes = async () => {
  const { data } = await request.get(`/billing-modes`);

  return data;
};

const useFetchBillingModesQuery = () => {
  const { data, isLoading, refetch, error } = useQuery<BillingModes, Error>(
    ['billing-modes'],
    () => fetchBillingModes(),
  );

  return {
    data,
    isLoading,
    refetch,
    error,
  };
};

export default useFetchBillingModesQuery;
