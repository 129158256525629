import { useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { generatePath, useHistory } from 'react-router-dom';
import { ArrowRight } from 'src/assets/arrows';
import { COUNTRY_FLAGS } from 'src/assets/flags';
import { ReactComponent as HomeIcon } from 'src/assets/home.svg';
import Routes from 'src/router/Routes';
import { PartnerProspect } from 'src/sdk/partner-prospect';
import { Card, CardBody } from 'src/uikit/Card';
import { Detail, Text } from 'src/uikit/Typography';
import styled from 'styled-components/macro';

const Name = styled(Text)``;

const Identifier = styled(Detail)`
  color: ${({ theme }) => theme.palette.black[90]};
`;

const WrapperIcon = styled.div`
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: cemter;
  padding: 0.5rem;
  border-radius: 50%;
`;

const WrapperNameIcon = styled(WrapperIcon)`
  color: ${({ theme }) => theme.palette.main.primary};
  border: 1px solid ${({ theme }) => theme.palette.main.primary};
`;

const StyledArrowRight = styled(ArrowRight)`
  color: ${({ theme }) => theme.palette.main.white};
  background: ${({ theme }) => theme.palette.main.primary};
  width: 42px;
  height: 42px;
  padding: 0.5rem;
  border-radius: 50%;
  transition: opacity 200ms ease;
  visibility: hidden;
  opacity: 0;
`;

const StyledCard = styled(Card)`
  min-height: 120px;
  :hover {
    ${StyledArrowRight} {
      color: ${({ theme }) => theme.palette.main.white};
      background: ${({ theme }) => theme.palette.main.primary};
      visibility: visible;
      opacity: 1;
    }

    ${Name} {
      font-weight: 600;
    }
  }
`;

const StyledCol = styled(Col)`
  height: 100%;
  display: grid;
  grid-auto-rows: 1fr;
`;

interface Props {
  prospect: PartnerProspect;
}

const PartnerProspectCard = ({ prospect }: Props) => {
  const history = useHistory();

  const handleClick = useCallback(() => {
    const route = generatePath(Routes.NURSING_CREATION_PARTNER, {
      id: prospect?.id || 'null',
      salesOrg: prospect.identifier || 'null',
    });

    history.push(route);
  }, [history, prospect?.id, prospect.identifier]);

  return (
    <StyledCard onClick={handleClick} $clickable border>
      <CardBody>
        <Row className="h-100">
          <StyledCol>
            <Row className="align-items-center">
              <Col xs="2">
                <WrapperNameIcon>
                  <HomeIcon />
                </WrapperNameIcon>
              </Col>
              <Col className="ml-2">
                <Name as="span">{prospect.name}</Name>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col xs="2">
                <WrapperIcon>
                  {COUNTRY_FLAGS[String(prospect.language) || 'fr']}
                </WrapperIcon>
              </Col>

              <Col className="ml-2">
                <Identifier>{prospect.id || 'XXXXX'}</Identifier>
              </Col>
            </Row>
          </StyledCol>
          <StyledCol xs={3} md={2} className="pb-2">
            <Row className="h-100 align-items-end justify-content-end">
              <StyledArrowRight />
            </Row>
          </StyledCol>
        </Row>
      </CardBody>
    </StyledCard>
  );
};

export default PartnerProspectCard;
