import { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import defaultAvatar from 'src/assets/director-male.svg';
import { Building63px, Address63px, Group63px } from 'src/assets/list-icons';
import request from 'src/axios';
import {
  CreateNursingHome,
  NursingHomeCreationType,
} from 'src/sdk/nursingHome';
import { Button } from 'src/uikit/Button';
import { FormButtonsWrapper } from 'src/uikit/Wrapper';
import styled, { css } from 'styled-components/macro';

import useCreateNursingHomeMutation from '../hooks/useCreateNursingHomeMutation';
import {
  NursingHomeCreationStep,
  useNursingHomeCreation,
} from './NursingHomeCreationContext';

const AvatarImage = styled(Image)<{ width?: number; height?: number }>`
    ${({ width }) =>
            width &&
            css`
                width: ${width}px;
            `}

    ${({ height }) =>
            height &&
            css`
                height: ${height}px;
            `}

    aspect-ratio: 1/1;
    border-radius: 50%;
`;

type Props = {
  data: CreateNursingHome;
  setCurrentStep: (val: number) => void;
  onSuccess: (newNursingHomeId: number, ecsMode: string, tenantName: string) => void;
  isPartnerProspect?: boolean;
  type: NursingHomeCreationType;
};

const NursingHomeCreationSummary = ({
                                      data,
                                      setCurrentStep,
                                      onSuccess,
                                      type,
                                    }: Props) => {
  const { t } = useTranslation();
  const [avatar, setAvatar] = useState<string>();
  const { createNursingMutation } = useCreateNursingHomeMutation(type, false);
  const { isLoading } = createNursingMutation;

  const { prospect, nursingHomeCreationSteps } = useNursingHomeCreation();

  useEffect(() => {
    if (data?.directorCommonAvatarId && data?.directorCommonAvatarId > -1) {
      request
        .get(`/common-avatars/${data.directorCommonAvatarId}/image`, {
          responseType: 'blob',
        })
        .then((response) => {
          setAvatar(URL.createObjectURL(response.data));
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [data.directorCommonAvatarId]);

  // Called at the end of the process of the NH creation
  const onSubmit = () => {
    createNursingMutation
      .mutateAsync(data)
      .then((newNursingHome) => {
        onSuccess(newNursingHome?.data.id as number, newNursingHome?.data.ecsMode as string, newNursingHome?.data.tenantName as string);
      })
      // TODO: Add new page error
      .catch((error) => console.log('error', error));
  };

  return (
    <div>
      <div
        style={{
          background: 'white',
          borderRadius: '0px 0px 12px 12px',
          padding: '16px',
        }}
      >
        <div
          style={{
            marginBottom: '24px',
          }}
        >
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <Building63px />
            <div style={{ marginLeft: '24px' }}>
              <div style={{ display: 'flex', marginBottom: '16px' }}>
                <strong style={{ marginRight: '1em' }}>
                  {t('components.nursingHomeCreation.summary.customer.name')} :
                </strong>
                <div>{prospect?.name}</div>
              </div>
              <div className="d-flex">
                <strong style={{ marginRight: '1em' }}>
                  {t('components.nursingHomeCreation.summary.customer.address')}{' '}
                  :
                </strong>
                <div>
                  {prospect?.type === 'Prospect' && (
                    <>
                      {prospect?.address?.street} {prospect?.address?.zip} -{' '}
                      {prospect?.address?.town}
                    </>
                  )}
                  {prospect?.type === 'PartnerProspect' &&
                    prospect.deliveryAddresses[0] && (
                      <>
                        {prospect.deliveryAddresses[0].street}{' '}
                        {prospect.deliveryAddresses[0].zip} -{' '}
                        {prospect.deliveryAddresses[0].town}
                      </>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {prospect?.type === 'Prospect' && (
          <div
            style={{
              marginBottom: '24px',
            }}
          >
            <div>
              <h5 style={{ marginBottom: '24px' }}>
                <strong>
                  {t('components.nursingHomeCreation.summary.ship.title')}
                </strong>
              </h5>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <Address63px />
                {data.shipToValues?.map((shipToId) => {
                  const deliveryAddress = prospect?.deliveryAddresses.find(
                    (delivery) => delivery.shipTo === shipToId,
                  );

                  return (
                    <span
                      style={{
                        ...(Boolean(
                          (data?.shipToValues?.length as any) > 1,
                        ) && {
                          marginBottom: '16px',
                        }),
                        marginLeft: '24px',
                      }}
                      key={shipToId}
                    >
                      {deliveryAddress?.shipTo} - {deliveryAddress?.name}
                    </span>
                  );
                })}
              </div>
              <div>
                <Button
                  onClick={() =>
                    setCurrentStep(
                      nursingHomeCreationSteps[
                        NursingHomeCreationStep.DeliveryAddressSelection
                        ] || 0,
                    )
                  }
                >
                  {t('common.action.modify')}
                </Button>
              </div>
            </div>
          </div>
        )}
        <div
          style={{
            marginBottom: '24px',
          }}
        >
          <div>
            <h5 style={{ marginBottom: '24px' }}>
              <strong>
                {t('components.nursingHomeCreation.summary.nursing.title')}
              </strong>
            </h5>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <div style={{ width: '100%' }} className="d-flex">
              <Group63px />
              <div
                style={{
                  width: '100%',
                  justifyContent: 'space-between',
                  display: 'flex',
                  marginLeft: '24px',
                }}
              >
                <div style={{ width: '50%' }}>
                  <div style={{ display: 'flex', marginBottom: '16px' }}>
                    <strong style={{ marginRight: '1em' }}>
                      {t(
                        'components.nursingHomeCreation.summary.nursing.login',
                      )}{' '}
                      :
                    </strong>
                    <div>{data.nursingHomeUsername} </div>
                  </div>
                  <div className="d-flex" style={{ marginBottom: '16px' }}>
                    <strong style={{ marginRight: '1em' }}>
                      {t(
                        'components.nursingHomeCreation.summary.nursing.language',
                      )}{' '}
                      :
                    </strong>
                    <div>
                      {t('common.language.' + data.language.toUpperCase())}
                    </div>
                  </div>
                  {type == 'Prospect' &&
                    <div className="d-flex">
                      <strong style={{ marginRight: '1em' }}>
                        {t(
                          'components.nursingHomeCreation.summary.nursing.legalEntity',
                        )}{' '}
                        :
                      </strong>
                      <div>
                        {data.legalEntity}
                      </div>
                    </div>
                  }
                </div>
                <div style={{ width: '50%' }}>
                  <div style={{ display: 'flex', marginBottom: '16px' }}>
                    <strong
                      style={{
                        marginRight: '1em',
                      }}
                    >
                      {t(
                        'components.nursingHomeCreation.summary.nursing.nhName',
                      )}{' '}
                      :
                    </strong>
                    <div>{data.name}</div>
                  </div>
                  <div className="d-flex">
                    <strong style={{ marginRight: '1em' }}>
                      {t(
                        'components.nursingHomeCreation.summary.nursing.timezone',
                      )}{' '}
                      :
                    </strong>
                    <div>{data.timeZone}</div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ marginLeft: '24px' }}>
              <Button
                onClick={() =>
                  setCurrentStep(
                    nursingHomeCreationSteps[
                      NursingHomeCreationStep.NursingHomeAccount
                      ] || 0,
                  )
                }
              >
                {t('common.action.modify')}
              </Button>
            </div>
          </div>
        </div>
        <div>
          <div style={{ marginBottom: '24px' }}>
            <h5>
              <strong>
                {t('components.nursingHomeCreation.summary.account.title')}
              </strong>
            </h5>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div style={{ display: 'flex', width: '100%' }}>
              <AvatarImage
                src={avatar ? avatar : defaultAvatar}
                alt=""
                height={56}
                width={56}
              />
              <div
                style={{
                  width: '100%',
                  justifyContent: 'space-between',
                  display: 'flex',
                  marginLeft: '24px',
                }}
              >
                <div style={{ width: '50%' }}>
                  <div style={{ display: 'flex', marginBottom: '16px' }}>
                    <strong
                      style={{
                        marginRight: '1em',
                      }}
                    >
                      {t(
                        'components.nursingHomeCreation.summary.account.firstName',
                      )}{' '}
                      :
                    </strong>
                    <div>{data.directorFirstName}</div>
                  </div>
                  <div style={{ display: 'flex', marginBottom: '16px' }}>
                    <strong
                      style={{
                        marginRight: '1em',
                      }}
                    >
                      {t(
                        'components.nursingHomeCreation.summary.account.email',
                      )}{' '}
                      :
                    </strong>
                    <div>{data.directorEmail}</div>
                  </div>
                </div>
                <div style={{ width: '50%' }}>
                  <div style={{ display: 'flex', marginBottom: '16px' }}>
                    <strong
                      style={{
                        marginRight: '1em',
                      }}
                    >
                      {t(
                        'components.nursingHomeCreation.summary.account.lastName',
                      )}{' '}
                      :
                    </strong>
                    <div>{data.directorLastName}</div>
                  </div>
                  <div style={{ display: 'flex', marginBottom: '16px' }}>
                    <strong
                      style={{
                        marginRight: '1em',
                      }}
                    >
                      {t(
                        'components.nursingHomeCreation.summary.account.language',
                      )}{' '}
                      :
                    </strong>
                    <div>
                      {t(
                        'common.language.' +
                        data.directorLanguage.toUpperCase(),
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ marginLeft: '24px' }}>
              <Button
                onClick={() =>
                  setCurrentStep(
                    nursingHomeCreationSteps[
                      NursingHomeCreationStep.DirectorAccount
                      ] || 0,
                  )
                }
              >
                {t('common.action.modify')}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <FormButtonsWrapper oneButton>
        <Button disabled={isLoading} onClick={onSubmit}>
          {t('common.action.finish')}
        </Button>
      </FormButtonsWrapper>
    </div>
  );
};

export default NursingHomeCreationSummary;
