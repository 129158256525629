import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import EquipmentBadgeCol from 'src/components/Equipment/EquipmentBadgeCol';
import { SortField } from 'src/components/Equipment/hooks/useFetchEquipmentQuery';
import SortingButton from 'src/components/SortingHeaders/SortingButton';
import { CliponDetails } from 'src/sdk/provisioning';
import { sortByDirection } from 'src/utils/sortByDirection';
import styled from 'styled-components/macro';

const StyledRow = styled(Row)`
  padding-inline: 1rem;
`;

const StyledCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface Props {
  onSortingChange: React.Dispatch<
    React.SetStateAction<SortField<CliponDetails>>
  >;
  sortField: SortField<CliponDetails>;
  global?: boolean;
}

const EquipmentCliponHeader = ({
  onSortingChange,
  sortField,
  global,
}: Props) => {
  const { t } = useTranslation();

  const handleSortClick = (label: keyof CliponDetails) => {
    sortByDirection(label, sortField, onSortingChange);
  };

  return (
    <StyledRow>
      <EquipmentBadgeCol />

      {!global ? (
        <>
          <StyledCol md="3">
            <SortingButton
              primary
              label={t('components.sortingHeader.sortByNumber')}
              field="serialNumber"
              disableSort
            />
          </StyledCol>
          <StyledCol md="4">
            <SortingButton
              primary
              label={t('components.sortingHeader.sortBySSCC')}
              field="registrationStatus"
              disableSort
            />
          </StyledCol>
          <StyledCol md="3">
            <SortingButton
              primary
              label={t('components.sortingHeader.sortByMessage')}
              field="receivedAt"
              onClick={() => handleSortClick('receivedAt')}
            />
          </StyledCol>
        </>
      ) : (
        <>
          <StyledCol md="2">
            <SortingButton
              primary
              label={t('components.sortingHeader.sortByName')}
              field="name"
              disableSort
            />
          </StyledCol>
          <StyledCol md="2">
            <SortingButton
              primary
              label={t('components.sortingHeader.sortByNumber')}
              field="serialNumber"
              disableSort
            />
          </StyledCol>

          <StyledCol md="2">
            <SortingButton
              primary
              label={t('components.sortingHeader.sortByStatus')}
              field="equipmentStatus"
              disableSort
            />
          </StyledCol>
          <StyledCol md="2">
            <SortingButton
              primary
              label={t('components.sortingHeader.sortBySSCC')}
              field="registrationStatus"
              disableSort
            />
          </StyledCol>
          <StyledCol md="2">
            <SortingButton
              primary
              label={t('components.sortingHeader.sortByMessage')}
              field="receivedAt"
              onClick={() => handleSortClick('receivedAt')}
            />
          </StyledCol>
        </>
      )}
    </StyledRow>
  );
};

export default EquipmentCliponHeader;
