import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosResponse } from 'axios';
import { useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { ReactComponent as FileSVG } from 'src/assets/file.svg';
import request from 'src/axios';
import { Button } from 'src/uikit/Button';
import FileUploader from 'src/uikit/FileUploader';
import { Form } from 'src/uikit/Form';
import FormTextInput from 'src/uikit/Form/TextInput';
import { P } from 'src/uikit/Typography';
import styled from 'styled-components/macro';
import * as yup from 'yup';

const FileBadge = styled(FileSVG)`
  margin-right: 15px;
  margin-left: -15px;
  z-index: 2;
  cursor: pointer;
`;

const ErrorMessage = styled(P)`
  color: ${({ theme }) => theme.palette.main.red};
`;

type FormType = {
  versionMajor: number;
  versionMinor: number;
  versionRevision: number;
  archived: boolean;
  existingVersion: string;
};

type Props = {
  callback?: () => void;
};

const UploadNewFirmware = ({ callback }: Props) => {
  const { t } = useTranslation();
  const hiddenFileInput = useRef<HTMLInputElement | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [isValidVersion, setIsValidVersion] = useState<boolean>(false);
  const [isfileError, setIsFileError] = useState<boolean>(false);
  const [isfileZipError, setIsFileZipError] = useState<boolean>(false);
  const [isfileSizeError, setIsFileSizeError] = useState<boolean>(false);


  const formSchema = useMemo(
    () =>
      yup.object().shape({
        versionMajor: yup.number().min(0).max(255),
        versionMinor: yup.number().min(0).max(255),
        versionRevision: yup.number().min(0).max(255),
      }),
    [t],
  );

  const methods = useForm<FormType>({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
  });

  const { handleSubmit, watch } = methods;



  /**
   * Handles the submit api event of the form
   */
  const createFirmwareVersion =  useMutation<AxiosResponse<any>, Error, FormData>(
    (formData) => request.post(`firmwares`, formData)
  );


  /**
   * Handles verification api data before the submit event of the form
   */
  const createSimulationFirmwareVersion = useMutation<AxiosResponse<any>, Error, FormData>(
    (formData) => request.post(`firmwares?simulation=true`, formData)
  );


  /**
   * Handles the change event of the file input.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} event - The change event object.
   * @return {void} This function does not return anything.
   */
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const file = event.target.files?.[0] || null;
      if(file){
      setFile(file); 
      event.target.value = '';
    }else{
      setIsFileError(true);
      setTimeout(() => {
        setIsFileError(false);
      },3000)
      return
    }
  };


  
  const onSubmit = handleSubmit(async (data) => {   
    let dto = {
      versionMajor: data.versionMajor,
      versionMinor: data.versionMinor,
      versionRevision: data.versionRevision,
    } 

    const formData = new FormData();
    formData.append('dto', new Blob([JSON.stringify(dto)], { 
      type: 'application/json'
    }));
    
    if (file) {
      // Check if the file is a zip
      if (
        file.type === 'application/zip' ||
        file.type === 'application/x-zip-compressed' ||
        file.type === 'application/x-zip'
      ) {
        // Check maximum size of 500 KB
      if (file.size > 500000) {
        setIsFileSizeError(true);
        setTimeout(() => {
          setIsFileSizeError(false);
        }, 3000);
        return;
      }
        // Append the file to formData
        formData.append('file', file);
      } else {
        // Display an error if the file is not a zip file
        setIsFileZipError(true);
        setTimeout(() => {
          setIsFileZipError(false);
        }, 3000);
        return;
      }
    }else{
      setIsFileError(true);
      setTimeout(() => {
        setIsFileError(false);
      },3000)
      return
    }
    
    try {
      const response = await createSimulationFirmwareVersion.mutateAsync(formData);
      // Handle the response status code
      if (response.status === 200) {
        await createFirmwareVersion.mutateAsync(formData).then(async (response) => {
          if (response.request.status === 200) {
            try {
              JSON.parse(response.request.response);
            } catch (error) {
              console.log(error);
            } finally {
              callback?.();
              setFile(null);
              methods?.setValue('versionMajor', '');
              methods?.setValue('versionMinor', '');
              methods?.setValue('versionRevision', '');
              methods?.setValue('existingVersion', '');
              setIsValidVersion(false);
            }
          }
        });
      }else if(response.status === 413){
        setIsFileSizeError(true);
        setTimeout(() => {
          setIsFileSizeError(false);
        },3000)
        return
      }
    } catch (error) {
      setIsValidVersion(true);
      setTimeout(() => {
        setIsValidVersion(false);
      }, 3000);
    }
  });

  

  const all = watch();

  const isDisabled = useMemo(() => {
    if(!file){
      return false;
    }
    const currentVersion = `${all.versionMajor}.${all.versionMinor}.${all.versionRevision}`;
    const regexp = /\d+(\.\d+){2}/gm;
    const isVersionNumber = currentVersion.match(regexp) !== null;
    return isVersionNumber ? false : true;
  }, [all]);

  const handleClickBadge = () => {    
    hiddenFileInput.current?.click();
  };

  return (
    <>
      <h4>
        <strong>
          {t('components.firmwareList.modalUploadNewFirmware.title')}
        </strong>
      </h4>
      <div>{t('components.firmwareList.modalUploadNewFirmware.text')}</div>
      <p>
        <strong>
          {t('components.firmwareList.modalUploadNewFirmware.subtext')}
        </strong>
      </p>
      <FormProvider {...methods}>
        <Form onSubmit={onSubmit}>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '50%' }}>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(3,minmax(0,1fr))',
                  gap: '6px',
                  width: '75%',
                }}
              >
                <FormTextInput
                  name="versionMajor"
                  type="number"
                  placeholder={t(
                    'components.firmwareList.modalUploadNewFirmware.inputPlaceholder.major',
                  )}
                  withoutErrorMessage
                />
                <FormTextInput
                  name="versionMinor"
                  placeholder={t(
                    'components.firmwareList.modalUploadNewFirmware.inputPlaceholder.minor',
                  )}
                  type="number"
                  withoutErrorMessage
                />
                <FormTextInput
                  name="versionRevision"
                  placeholder={t(
                    'components.firmwareList.modalUploadNewFirmware.inputPlaceholder.patchLevel',
                  )}
                  type="number"
                  withoutErrorMessage
                />
              </div>
            </div>
            <div style={{ width: '25%' }}>
              <FormTextInput
                name="file"
                placeholder={t(
                  'components.firmwareList.modalUploadNewFirmware.inputPlaceholder.file',
                )}
                icon={<FileBadge onClick={handleClickBadge} />}
              />
              <FileUploader
                name="file"
                inputRef={hiddenFileInput}
                onChange={handleFileChange}
              />
            </div>
          </div>
          {methods.errors.versionMajor && (
            <ErrorMessage>
              {t(
                'components.firmwareList.modalUploadNewFirmware.errorMessages.majorVersionBetween',
              )}
            </ErrorMessage>
          )}
          {methods.errors.versionMinor && (
            <ErrorMessage>
              {t(
                'components.firmwareList.modalUploadNewFirmware.errorMessages.minorVersionBetween',
              )}
            </ErrorMessage>
          )}
          {methods.errors.versionRevision && (
            <ErrorMessage>
              {t(
                'components.firmwareList.modalUploadNewFirmware.errorMessages.patchVersionBetween',
              )}
            </ErrorMessage>
          )}
          {isValidVersion && (
            <ErrorMessage>
              {t(
                'components.firmwareList.modalUploadNewFirmware.errorMessages.versionAlreadyExists',
              )}
            </ErrorMessage>
          )}
          {isfileError && (
            <ErrorMessage>
              {t(
                'components.firmwareList.modalUploadNewFirmware.errorMessages.fileError',
              )}
            </ErrorMessage>
          )}
          {isfileZipError && (
            <ErrorMessage>
              {t(
                'components.firmwareList.modalUploadNewFirmware.errorMessages.fileZipError',
              )}
            </ErrorMessage>
          )}
          {isfileSizeError && (
            <ErrorMessage>
              {t(
                'components.firmwareList.modalUploadNewFirmware.errorMessages.fileSizeError',
              )}
            </ErrorMessage>
          )}
          {file && file !== null && <p>{file.name}</p>}
          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
            }}
          >
            <Button type="submit" disabled={isDisabled}>
              {t(
                'components.firmwareList.modalUploadNewFirmware.inputPlaceholder.uploadButton',
              )}
            </Button>
          </div>
        </Form>
      </FormProvider>
    </>
  );
};

export default UploadNewFirmware;
