import { Form, Row } from 'react-bootstrap';
import { TFunction, useTranslation } from 'react-i18next';
import useLanguageOptions from 'src/hooks/useLanguageOptions';
import useProfileOptions from 'src/hooks/useProfileOptions';
import { Profiles } from 'src/sdk/authentification';
import { Gender } from 'src/sdk/common';
import { Language } from 'src/sdk/partner';
import FormSelectInput from 'src/uikit/Form/SelectInput';
import FormTextInput from 'src/uikit/Form/TextInput';
import { Label } from 'src/uikit/Typography';
import styled from 'styled-components/macro';
import * as yup from 'yup';

const FormAccountWrapper = styled.fieldset`
  display: grid;
  grid-auto-flow: columns;
  width: 400px;
  margin-inline: auto;
`;

const FormGroup = styled(Form.Group)`
  margin-bottom: 4px;
`;

const genderOptions = [
  {
    label: 'Women',
    value: Gender.Female,
  },
  {
    label: 'Man',
    value: Gender.Male,
  },
];

export const userCreateFormSchema = (t: TFunction) =>
  yup.object().shape({
    firstName: yup
      .string()
      .required(
        t(
          'components.Users.UsersList.UserCreateFormFieldset.validations.required',
        ),
      )
      .min(
        2,
        t(
          'components.Users.UsersList.UserCreateFormFieldset.validations.tooShort',
        ),
      ),
    lastName: yup
      .string()
      .required(
        t(
          'components.Users.UsersList.UserCreateFormFieldset.validations.required',
        ),
      )
      .min(
        2,
        t(
          'components.Users.UsersList.UserCreateFormFieldset.validations.tooShort',
        ),
      ),
    email: yup
      .string()
      .required(
        t(
          'components.Users.UsersList.UserCreateFormFieldset.validations.required',
        ),
      )
      .email(
        t(
          'components.Users.UsersList.UserCreateFormFieldset.validations.invalidEmail',
        ),
      ),
    profile: yup
      .mixed<Profiles>()
      .required(
        t(
          'components.Users.UsersList.UserCreateFormFieldset.validations.required',
        ),
      ),
    gender: yup
      .mixed<Gender>()
      .required(
        t(
          'components.Users.UsersList.UserCreateFormFieldset.validations.required',
        ),
      ),
    language: yup
      .mixed<Language>()
      .required(
        t(
          'components.Users.UsersList.UserCreateFormFieldset.validations.required',
        ),
      ),
  });

interface Props {
  profileDisabled?: boolean;
  profilesFilter: Profiles[];
}

const UserCreateForm = ({ profileDisabled = false, profilesFilter }: Props) => {
  const { t } = useTranslation();
  const languageOptions = useLanguageOptions();
  const profilesOptions = useProfileOptions(profilesFilter);

  return (
    <FormAccountWrapper>
      <Row className="mb-4">
        <Label>
          {t('components.Users.UsersList.UserCreateFormFieldset.label')}
        </Label>
      </Row>

      <FormGroup>
        <FormTextInput
          name="firstName"
          placeholder={t(
            'components.Users.UsersList.UserCreateFormFieldset.firstName',
          )}
          width="100%"
        />
      </FormGroup>

      <FormGroup>
        <FormTextInput
          name="lastName"
          placeholder={t(
            'components.Users.UsersList.UserCreateFormFieldset.lastName',
          )}
          width="100%"
        />
      </FormGroup>

      <FormGroup>
        <FormTextInput
          name="email"
          placeholder={t(
            'components.Users.UsersList.UserCreateFormFieldset.email',
          )}
          width="100%"
        />
      </FormGroup>
      {!profileDisabled && (
        <FormGroup>
          <FormSelectInput
            name="profile"
            placeholder={t(
              'components.Users.UsersList.UserCreateFormFieldset.profile',
            )}
            options={profilesOptions}
          />
        </FormGroup>
      )}
      <FormGroup>
        <FormSelectInput
          name="gender"
          placeholder={t(
            'components.Users.UsersList.UserCreateFormFieldset.gender',
          )}
          options={genderOptions}
        />
      </FormGroup>
      <FormGroup>
        <FormSelectInput
          name="language"
          placeholder={t(
            'components.Users.UsersList.UserCreateFormFieldset.language',
          )}
          options={languageOptions}
        />
      </FormGroup>
    </FormAccountWrapper>
  );
};

export default UserCreateForm;
