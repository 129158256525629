import { useMemo } from 'react';
import FirmwareNursingHomeDetailsCard from 'src/components/Firmware/FirmwareNursingHomeDetailsCard';
import SortingFirmwareDetailHeader from 'src/components/SortingHeaders/SortingFirmwareDetailHeader';
import useSortedList from 'src/hooks/useSortedList';
import { Clipons } from 'src/sdk/nursingHome';

export type FirmwareDetailSortField = {
  label: keyof Clipons;
  direction: 'asc' | 'desc';
};

interface Props {
  clipons: Array<Clipons & { outdated: boolean }>;
}

const FirmwareNursingHomeDetailsList = ({ clipons }: Props) => {
  const cliponsExtended = useMemo(() => {
    if (!clipons) return;
    return clipons.map((clipon) => {
      const cliponExtended = { ...clipon };
      // We concat them in order to sort this field as one string
      // firmwareMajorVersion = 1, firmwareMinorVersion = 1,
      // firmwareRevisionVersion =5 => "1.1.5"
      cliponExtended.concatVersion = `${clipon.firmwareMajorVersion}.${clipon.firmwareMinorVersion}.${clipon.firmwareRevisionVersion}`;
      return cliponExtended;
    });
  }, [clipons]);

  const { sortedItems, handleSortFieldChange } = useSortedList(cliponsExtended);

  return (
    <div style={{ width: '75%', margin: 'auto' }}>
      {clipons?.length && (
        <SortingFirmwareDetailHeader onSortingChange={handleSortFieldChange} />
      )}
      <FirmwareNursingHomeDetailsCard nursingHomeClipons={sortedItems} />
    </div>
  );
};

export default FirmwareNursingHomeDetailsList;
