import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo, useState } from 'react';
import { Col, Row, Stack } from 'react-bootstrap';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EditIcon } from 'src/assets/edit-badge.svg';
import { ReactComponent as HomeIcon } from 'src/assets/home.svg';
import useUpdatePartnerMutation from 'src/components/Partners/hooks/useUpdatePartnerMutation';
import useFormatErrorApi from 'src/hooks/useFormatErrorApi';
import useLanguageOptions from 'src/hooks/useLanguageOptions';
import useToast from 'src/hooks/useToast';
import { Partner } from 'src/sdk/partner';
import { Button, ButtonColors } from 'src/uikit/Button';
import { Card, CardBody, CardHeader } from 'src/uikit/Card';
import { Form, FormGroup } from 'src/uikit/Form';
import FormSelectInput from 'src/uikit/Form/SelectInput';
import FormTextInput from 'src/uikit/Form/TextInput';
import { H3, Label } from 'src/uikit/Typography';
import styled from 'styled-components/macro';
import * as yup from 'yup';
import useLegalEntityOptions from '../../../NursingHome/Creation/hooks/useLegalEntityOptions';

const WrapperIcon = styled.div`
    background-color: ${({ theme }) => theme.palette.main.primary};
    padding: 0.5rem;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;

    & > * {
        color: white;
    }
`;
const IconButton = styled(Button)`
    background: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.25rem;
    width: 40px;
    height: 40px;
    color: ${({ theme }) => theme.palette.black[80]};

    svg {
        width: inherit;
        height: inherit;
    }
`;

const FormFooter = styled(Row)`
    justify-content: flex-end;
    gap: 1rem;

    padding: 1.5rem 1rem 0;

    ${Button} {
        font-size: 14px;
    }
`;

export const partnerGeneralFormSchema = yup.object({
  name: yup.string().required(),
  language: yup.mixed<Partner['language']>().required(),
  salesOrg: yup.string(),
  soldTo: yup.string().required(),
  legalEntityName: yup.string(),
});

export type PartnerGeneralFormData = yup.InferType<
  typeof partnerGeneralFormSchema
>;

interface Props {
  partner: Partner;
}

const PartnerGeneralForm = ({ partner }: Props) => {
  const [edit, setEdit] = useState(false);

  const { t } = useTranslation();
  const languageOptions = useLanguageOptions();
  const legalEntityOptions = useLegalEntityOptions();

  const { formatErrorApi } = useFormatErrorApi();
  const { toastSuccess, toastError } = useToast();

  const { updatePartnerMutation } = useUpdatePartnerMutation();

  const defaultValues: PartnerGeneralFormData = useMemo(() => {
    return {
      name: partner.name,
      language: partner.language,
      soldTo: partner.soldTo,
      salesOrg: partner.salesOrg,
      legalEntityName: partner.legalEntityName,
    };
  }, [partner.language, partner.name, partner.salesOrg, partner.soldTo, partner.legalEntityName]);

  const methods = useForm<PartnerGeneralFormData>({
    resolver: yupResolver(partnerGeneralFormSchema),
    defaultValues,
    mode: 'onChange',
  });

  const {
    handleSubmit,
    formState: { isValid, isSubmitting, isDirty },
  } = methods;

  const onSubmit = async (values: PartnerGeneralFormData) => {
    if (!partner) {
      return;
    }

    try {
      await updatePartnerMutation.mutateAsync({
        id: partner.id,
        name: values.name,
        language: values.language,
        legalEntityName: values.legalEntityName || ''
      });

      toastSuccess(t('components.partners.details.general.form.success'));
    } catch (err) {
      const message = formatErrorApi(err);
      toastError(message);
    }
  };

  return (
    <Card>
      <CardHeader>
        <Stack direction="horizontal" className="justify-content-between">
          <Stack direction="horizontal" gap={2}>
            <WrapperIcon>
              <HomeIcon />
            </WrapperIcon>
            <H3 className="ml-2">
              {t('components.partners.details.general.form.title')}
            </H3>
          </Stack>
          <IconButton
            variant="light"
            disabled={edit}
            onClick={() => setEdit(true)}
          >
            <EditIcon />
          </IconButton>
        </Stack>
      </CardHeader>
      <CardBody>
        {edit ? (
          <FormProvider {...methods}>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row xs="auto">
                <Col xs="12" md="6" className="mt-2">
                  <FormGroup>
                    <FormTextInput
                      name="soldTo"
                      placeholder={t(
                        'components.partners.details.general.form.labels.soldTo',
                      )}
                      width="100%"
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col xs="12" md="6" className="mt-2">
                  <FormGroup>
                    <FormTextInput
                      name="salesOrg"
                      placeholder={t(
                        'components.partners.details.general.form.labels.salesOrg',
                      )}
                      width="100%"
                      disabled
                    />
                  </FormGroup>
                </Col>

                <Col xs="12" md="12" className="mt-2">
                  <FormGroup>
                    <FormTextInput
                      name="name"
                      placeholder={t(
                        'components.partners.details.general.form.labels.name',
                      )}
                      width="100%"
                    />
                  </FormGroup>
                </Col>
                <Col xs="12" md="12" className="mt-2">
                  <FormGroup>
                    <FormSelectInput
                      name="language"
                      placeholder={t(
                        'components.partners.details.general.form.labels.language',
                      )}
                      options={languageOptions}
                    />
                  </FormGroup>
                </Col>
                <Col xs="12" md="12" className="mt-2">
                  <FormGroup>
                    <FormSelectInput
                      name="legalEntityName"
                      placeholder={t(
                        'components.partners.details.general.form.labels.legalEntity',
                      )}
                      options={legalEntityOptions.data || []}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <FormFooter
                direction="horizontal"
                className="justify-content-end"
                xs="auto"
              >
                <Col>
                  <Button
                    variant="light"
                    background={ButtonColors.CANCEL}
                    onClick={() => setEdit(false)}
                  >
                    {t(
                      'components.Users.UserDetails.UserInformationForm.cancel',
                    )}
                  </Button>
                </Col>
                <Col>
                  <Button type="submit" disabled={!isValid && !isDirty}>
                    {isSubmitting ? (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      t('components.partners.details.general.form.update')
                    )}
                  </Button>
                </Col>
              </FormFooter>
            </Form>
          </FormProvider>
        ) : (
          <Row className="mb-3">
            <Col md="6" className="mt-2">
              <Stack direction="horizontal" gap={2}>
                <Label>
                  {t('components.partners.details.general.form.labels.soldTo')}
                </Label>
                {defaultValues.soldTo}
              </Stack>
            </Col>

            <Col md="6" className="mt-2">
              <Stack direction="horizontal" gap={2}>
                <Label>
                  {t(
                    'components.partners.details.general.form.labels.salesOrg',
                  )}
                </Label>
                {defaultValues.salesOrg}
              </Stack>
            </Col>
            <Col md="12" className="mt-2">
              <Stack direction="horizontal" gap={2}>
                <Label>
                  {t('components.partners.details.general.form.labels.name')}
                </Label>
                {defaultValues.name}
              </Stack>
            </Col>
            <Col md="12" className="mt-2">
              <Stack direction="horizontal" gap={2}>
                <Label>
                  {t(
                    'components.partners.details.general.form.labels.language',
                  )}
                </Label>
                {defaultValues.language}
              </Stack>
            </Col>
            <Col md="12" className="mt-2">
              <Stack direction="horizontal" gap={2}>
                <Label>
                  {t(
                    'components.partners.details.general.form.labels.legalEntity',
                  )}
                </Label>
                {defaultValues.legalEntityName}
              </Stack>
            </Col>
          </Row>
        )}
      </CardBody>
    </Card>
  );
};

export default PartnerGeneralForm;
