import { useTranslation } from 'react-i18next';
import { Card } from 'src/uikit/Card';
import { Detail, H1 } from 'src/uikit/Typography';
import styled from 'styled-components/macro';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
`;

const ComingSoonCard = () => {
  const { t } = useTranslation();
  return (
    <Card>
      <Content>
        <H1>{t('pages.comingSoon.title')}</H1>
        <Detail>{t('pages.comingSoon.text')}</Detail>
      </Content>
    </Card>
  );
};

export default ComingSoonCard;
