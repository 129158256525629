import { Container } from 'react-bootstrap';
import MetricCardBadge from 'src/components/Misc/MetricCardBadge';
import UserStatusBadge from 'src/components/Users/UserStatusBadge';
import { UserResponse } from 'src/sdk/users';
import { GetUsersResponse } from 'src/sdk/users';
import styled from 'styled-components/macro';

interface Props {
  extra?: GetUsersResponse['extra'];
  filterValue?: UserResponse['accountStatus'];
  onFilterBy: (value: UserResponse['accountStatus']) => void;
}

const Wrapper = styled(Container)`
  display: flex;
  gap: 1rem;
`;

const UserMetricList = ({ filterValue, onFilterBy, extra }: Props) => {
  return (
    <Wrapper fluid className="justify-content-center align-items-center">
      <MetricCardBadge
        metric={extra?.countByAccountStatus['PENDING'] || 0}
        selected={filterValue === 'PENDING'}
        onClick={() => onFilterBy('PENDING')}
      >
        <UserStatusBadge status="PENDING" />
      </MetricCardBadge>
      <MetricCardBadge
        metric={extra?.countByAccountStatus['ACTIVE'] || 0}
        selected={filterValue === 'ACTIVE'}
        onClick={() => onFilterBy('ACTIVE')}
      >
        <UserStatusBadge status="ACTIVE" />
      </MetricCardBadge>
      <MetricCardBadge
        metric={extra?.countByAccountStatus['BLOCKED'] || 0}
        selected={filterValue === 'BLOCKED'}
        onClick={() => onFilterBy('BLOCKED')}
      >
        <UserStatusBadge status="BLOCKED" />
      </MetricCardBadge>
    </Wrapper>
  );
};

export default UserMetricList;
