import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DeleteIcon } from 'src/assets/delete-badge.svg';
import { ReactComponent as PartnerProspectsIcon } from 'src/assets/home.svg';
import { PartnerProspect } from 'src/sdk/partner-prospect';
import { Button } from 'src/uikit/Button';
import { Card, CardBody } from 'src/uikit/Card';
import { Text } from 'src/uikit/Typography';
import { formatDate } from 'src/utils/date';
import styled from 'styled-components/macro';

const StyledCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`;

const StyledColIcon = styled(Col)`
  flex: 0 0 55px;
`;

const WrapperIcon = styled.div<{ $hasNursingHome?: boolean }>`
  --color: ${({ theme }) => theme.palette.main.primary};
  background: ${({ $hasNursingHome, theme }) =>
    $hasNursingHome ? 'var(--color)' : theme.palette.main.white};
  color: ${({ theme, $hasNursingHome }) =>
    $hasNursingHome ? theme.palette.main.white : 'var(--color)'};
  border: 1px solid var(--color);

  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: cemter;
  padding: 0.5rem;
  border-radius: 50%;
`;

const NumberText = styled(Text)`
  font-weight: normal;
  margin: 0;
  color: ${({ theme }) => theme.palette.black[90]};
  word-break: break-word;
  text-align: center;
`;

const StyledButton = styled(Button)`
  width: fit-content;
  padding: 0;
  border-radius: 2px;
  min-height: fit-content;
  background: transparent;
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  justify-content: center;
`;

const StyledHoverdCard = styled(Card)`
  ${StyledButton} {
    display: none;
    filter: grayscale(1);
  }

  &:hover {
    opacity: 1;
    box-shadow: 0px 4px 16px rgba(0 0 0 / 25%);


    ${StyledButton} {
      display: initial;
      filter: grayscale(0);
    }
`;

interface Props {
  partnerProspect: PartnerProspect;
  onClickDelete: (partnerProspect: PartnerProspect) => void;
  onClickUpdate: (partnerProspect: PartnerProspect) => void;
}

const PartnerProspectsCard = ({
  partnerProspect,
  onClickDelete,
  onClickUpdate,
}: Props) => {
  const { i18n } = useTranslation();

  const handleClick = (e: Event) => {
    e.stopPropagation();
    onClickDelete(partnerProspect);
  };

  return (
    <StyledHoverdCard
      key={partnerProspect.id}
      onClick={() => onClickUpdate(partnerProspect)}
      $clickable
    >
      <CardBody>
        <Row>
          <StyledColIcon>
            <WrapperIcon $hasNursingHome={!!partnerProspect.nursingHome}>
              <PartnerProspectsIcon />
            </WrapperIcon>
          </StyledColIcon>

          <StyledCol
            md="2"
            className="d-flex align-items-center justify-content-start"
          >
            <NumberText>{partnerProspect.name}</NumberText>
          </StyledCol>
          <StyledCol md="2">
            <NumberText>{partnerProspect.identifier}</NumberText>
          </StyledCol>
          <StyledCol md="2">
            {partnerProspect.subscription?.type || '-'}
          </StyledCol>
          <StyledCol md="2">
            {partnerProspect.subscription?.endDate
              ? formatDate(partnerProspect.subscription?.endDate, i18n.language)
              : '-'}
          </StyledCol>
          <StyledCol md="2">
            {partnerProspect.counts?.productSpecialists || '-'}
          </StyledCol>
          <StyledCol>
            {!partnerProspect.nursingHome && (
              <StyledButton
                onClick={handleClick}
                background="danger"
                className="ml-auto"
              >
                <DeleteIcon />
              </StyledButton>
            )}
          </StyledCol>
        </Row>
      </CardBody>
    </StyledHoverdCard>
  );
};

export default PartnerProspectsCard;
