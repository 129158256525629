import {
  forwardRef,
  HTMLInputTypeAttribute,
  InputHTMLAttributes,
  ReactNode,
  SVGProps,
  useState,
} from 'react';
import { FormControl, InputGroup } from 'react-bootstrap';
import { ReactComponent as EyeIcon } from 'src/assets/eye.svg';
import { Placeholder } from 'src/uikit/Typography';
import styled, { css } from 'styled-components/macro';

type TextInputVariant = 'default' | 'form';

const StyledInput = styled(FormControl)<{
  background?: string;
}>`
  font-size: 1rem;
  height: 100%;
  position: relative;
  border: none;
  background-color: transparent;
  padding: 0 1rem;

  &:focus,
  &:valid {
    background-color: transparent;
    z-index: 0 !important;
  }

  &:disabled {
    color: ${({ theme }) => theme.palette.black[60]};
    background-color: transparent;
  }

  :focus {
    box-shadow: none;
  }
`;

const SuffixeIcon = styled('span')`
  position: absolute;
  top: 50%;
  right: 0.75rem;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.black[50]};
  transform: translateY(-50%);
`;

const PlaceholderArea = styled.div<{
  variant?: TextInputVariant;
  hasValue: boolean;
  background?: string;
}>`
  ${Placeholder} {
    height: 100%;
    z-index: 10;
    position: relative;
  }

  position: absolute;
  top: 3px;
  left: 1rem;
  transition: 200ms;
  pointer-events: none;
  color: ${({ theme }) => theme.palette.black[90]};

  // For too long placeholders, eg. in german.
  max-width: 160px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: hsl(0, 0%, 50%);

  // To keep placeholder on top, when input unselected but completed.
  ${({ hasValue, variant, theme }) =>
    css`
      ${variant === 'form' &&
      css`
        top: 8px;
        left: 1rem;
      `}

      ${hasValue &&
      css`
        top: -15px;
        left: 1rem !important;
        color: ${theme.palette.main.primary};
        background-blend-mode: screen; // for Edge & Chrome, to avoid bold text on transition

        &::before {
          background: ${theme.palette.main.white};
          content: '';
          display: block;
          height: 3px;
          width: 100%;
          position: absolute;
          top: 14px;
          left: 0;
        }

        ${variant === 'form' &&
        css`
          top: -15px;
          left: 1rem !important;
        `}
      `}
    `}
`;

const PreprendIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0.6rem;

  & + ${StyledInput} {
    padding: 0 0.5rem;
  }

  & ~ ${PlaceholderArea} {
    left: 2.2rem;
  }
`;

const Wrapper = styled(InputGroup)<{
  variant: TextInputVariant;
  $invalid?: boolean;
  disabled?: boolean;
  width: string;
}>`
  height: 32px;
  min-height: 32px;
  border-radius: 32px;

  border: 1px solid ${({ theme }) => theme.palette.black[50]};
  background: white;

  :focus-within {
    border-color: ${({ theme }) => theme.palette.main.primary};
    color: ${({ theme }) => theme.palette.black.main};
    transition: box-shadow 0.15s ease-in-out;
    box-shadow: none;

    ${PlaceholderArea} {
      top: -15px;
      left: 1rem !important;
      color: ${({ theme }) => theme.palette.main.primary};
      background-blend-mode: screen;

      &::before {
        background: ${({ theme }) => theme.palette.main.white};
        content: '';
        display: block;
        height: 3px;
        width: 100%;
        position: absolute;
        top: 14px;
        left: 0;
      }
    }
  }

  &:invalid {
    box-shadow: none !important;
  }

  ${({ disabled, theme }) =>
    disabled &&
    css`
      background-color: ${({ theme }) => theme.palette.black.strongLightGrey};
      border-color: ${({ theme }) => theme.palette.black[50]};
      color: ${({ theme }) => theme.palette.black[60]};

      ${PlaceholderArea} {
        color: ${({ theme }) => theme.palette.black[60]};
        &::before {
          background: ${({ theme }) => theme.palette.black.strongLightGrey};
        }
      }
    `}

  ${({ $invalid: invalid, theme }) =>
    invalid &&
    css`
      border-color: ${theme.palette.main.red};
    `}
    
  

  ${({ variant }) =>
    variant === 'form' &&
    css`
      border-radius: 10px;
      min-height: 44px;
      max-height: 44px;

      :focus-within {
        ${PlaceholderArea} {
          top: -15px;
          left: 1rem !important;
        }
      }
    `}
`;

export type TextInputProps = {
  type?: 'pin' | HTMLInputTypeAttribute;
  icon?: SVGProps<SVGSVGElement> | ReactNode;
  prependIcon?: SVGProps<SVGSVGElement> | ReactNode;
  variant?: TextInputVariant;
} & InputHTMLAttributes<HTMLInputElement>;

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    { value, placeholder, type, icon, disabled, prependIcon, variant, ...rest },
    ref,
  ) => {
    const [show, setShow] = useState(false);

    return (
      <Wrapper
        $invalid={rest['aria-invalid'] ? true : false}
        disabled={disabled ? true : false}
        variant={variant || 'default'}
      >
        {prependIcon && <PreprendIcon>{prependIcon}</PreprendIcon>}
        <StyledInput
          ref={ref}
          value={value}
          type={
            (type === 'pin' || type === 'password') && !show
              ? 'password'
              : 'text'
          }
          disabled={disabled}
          {...rest}
        />
        <PlaceholderArea hasValue={!!value} variant={variant || 'default'}>
          <Placeholder>{placeholder}</Placeholder>
        </PlaceholderArea>
        {type === 'password' || type === 'pin' ? (
          <SuffixeIcon onClick={() => setShow(!show)}>
            <EyeIcon />
          </SuffixeIcon>
        ) : (
          <SuffixeIcon>{icon}</SuffixeIcon>
        )}
      </Wrapper>
    );
  },
);

export default TextInput;
