import { useCallback } from 'react';
import { Row, Col, Stack } from 'react-bootstrap';
import { generatePath, useHistory } from 'react-router-dom';
import { ArrowRight } from 'src/assets/arrows';
import { COUNTRY_FLAGS } from 'src/assets/flags';
import { ReactComponent as HomeIcon } from 'src/assets/home.svg';
import Routes from 'src/router/Routes';
import { Prospect } from 'src/sdk/prospect';
import { Card, CardBody } from 'src/uikit/Card';
import { Icon } from 'src/uikit/Icon';
import { Detail, Text } from 'src/uikit/Typography';
import styled from 'styled-components/macro';

const Name = styled(Text)``;

const Identifier = styled(Detail)`
  color: ${({ theme }) => theme.palette.black[90]};
`;

const WrapperIcon = styled.div`
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: cemter;
  padding: 0.5rem;
  border-radius: 50%;
`;

const WrapperNameIcon = styled(WrapperIcon)`
  min-width: 42px;
  min-height: 42px;
  color: ${({ theme }) => theme.palette.main.primary};
  border: 1px solid ${({ theme }) => theme.palette.main.primary};
`;

const StyledArrowRight = styled(ArrowRight)`
  color: ${({ theme }) => theme.palette.main.white};
  background: ${({ theme }) => theme.palette.main.primary};
  min-width: 42px;
  min-height: 42px;
  padding: 0.5rem;
  border-radius: 50%;
  transition: opacity 200ms ease;
  visibility: hidden;
  opacity: 0;
`;

const StyledCard = styled(Card)`
  min-height: 120px;
  :hover {
    ${StyledArrowRight} {
      color: ${({ theme }) => theme.palette.main.white};
      background: ${({ theme }) => theme.palette.main.primary};
      visibility: visible;
      opacity: 1;
    }

    ${Name} {
      font-weight: 600;
    }
  }
`;

const StyledCol = styled(Col)`
  height: 100%;
  display: grid;
  grid-auto-rows: 1fr auto;
`;

interface Props {
  prospect: Prospect;
}

const ProspectCard = ({ prospect }: Props) => {
  const history = useHistory();

  const handleClick = useCallback(() => {
    const route = generatePath(Routes.NURSING_CREATION_ONTEX, {
      id: prospect?.id || 'null',
      salesOrg: prospect.salesOrg || 'null',
    });

    history.push(route);
  }, [history, prospect?.id, prospect.salesOrg]);

  return (
    <StyledCard onClick={handleClick} $clickable>
      <CardBody>
        <Row className="h-100">
          <StyledCol className="p-0 px-md-2">
            <Stack
              direction="horizontal"
              className="align-items-center"
              gap={2}
            >
              <WrapperNameIcon>
                <Icon as={HomeIcon} />
              </WrapperNameIcon>
              <Name as="span">{prospect.name}</Name>
            </Stack>
            <Stack
              direction="horizontal"
              className="align-items-center"
              gap={2}
            >
              <WrapperIcon>
                {COUNTRY_FLAGS[String(prospect.language) || 'fr']}
              </WrapperIcon>

              <Identifier>{prospect.id || 'XXXXX'}</Identifier>
            </Stack>
          </StyledCol>
          <StyledCol xs={2} md={2} className="p-0 pb-2">
            <StyledArrowRight className="mt-auto" />
          </StyledCol>
        </Row>
      </CardBody>
    </StyledCard>
  );
};

export default ProspectCard;
