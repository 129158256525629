import { AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import request from 'src/axios';
import {
  PartnerProspectCreationResponse,
  PartnerProspectCreationRequest,
} from 'src/sdk/partner-prospect';

const useCreatePartnerProspectMutation = () => {
  const queryClient = useQueryClient();

  const createPartnerProspectMutation = useMutation<
    AxiosResponse<PartnerProspectCreationResponse>,
    Error,
    PartnerProspectCreationRequest
  >(
    (params) => {
      return request.post('/partner-prospects', params);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
      },
    },
  );

  return { createPartnerProspectMutation };
};

export default useCreatePartnerProspectMutation;
