import { useEffect, useCallback } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Switch, useParams } from 'react-router-dom';
import request from 'src/axios';
import EquipmentGeneralInformation from 'src/components/Equipment/EquipmentGeneralInformation';
import NursingHomeConfiguration from 'src/components/NursingHome/Configuration';
import NursingHomeGeneralInformation from 'src/components/NursingHome/General/NursingHomeGeneralInformation';
import useFetchNursingHomeQuery from 'src/components/NursingHome/hooks/useFetchNursingHomeQuery';
import NursingHomeDashboard from 'src/components/NursingHome/NursingHomeDashboard';
import PrivateRoute from 'src/router/PrivateRoute';
import Routes from 'src/router/Routes';
import { RouteParams } from 'src/sdk/nursingHome';
import styled from 'styled-components/macro';

import { useAuth } from '../../contexts/Auth';
import SubscriptionLayout from '../SubscriptionLayout';

const Content = styled(Container)`
  background-color: ${({ theme }) => theme.palette.black.lightGrey};
  padding: 0;
`;

const NursingHomeSubmenuLayout = () => {
  const { id } = useParams<RouteParams>();
  const { nhSession, setNhSession } = useAuth();

  const { data } = useFetchNursingHomeQuery({
    id: Number(id),
    expand: [
      'address',
      'delivery-addresses',
      'subscription',
      'gateways',
      'clipons',
      'charging-stations',
    ],
  });

  const changeTenant = useCallback(() => {
    if (!data) {
      return;
    }

    request
      .post('/security/change-tenant?expand=nursing-home', {
        tenantName: data?.tenantName,
      })
      .then((tenantRes) => {
        if (
          nhSession?.id !== tenantRes.data?.nursingHome.id &&
          tenantRes.data?.nursingHome
        ) {
          setNhSession?.({
            ...tenantRes.data?.nursingHome,
            subscription: data.subscription,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [data, nhSession?.id, setNhSession]);

  useEffect(() => {
    changeTenant();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, data?.id]);

  return (
    <Content fluid>
      <Row>
        <Col>
          <Switch>
            <PrivateRoute
              path={Routes.NURSING_DASHBOARD}
              component={NursingHomeDashboard}
            />
            <PrivateRoute
              path={Routes.NURSING_GENERAL}
              component={NursingHomeGeneralInformation}
            />
            <PrivateRoute
              path={Routes.NURSING_CONFIG}
              component={NursingHomeConfiguration}
            />
            <PrivateRoute
              path={Routes.NURSING_SUBSCRIPTION}
              component={SubscriptionLayout}
            />
            <PrivateRoute
              path={Routes.NURSING_EQUIPMENT}
              component={EquipmentGeneralInformation}
            />
            <PrivateRoute
              path={Routes.NURSING_STOCK}
              component={NursingHomeDashboard}
            />
          </Switch>
        </Col>
      </Row>
    </Content>
  );
};
export default NursingHomeSubmenuLayout;
