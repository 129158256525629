import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo } from 'react';
import { Row, Col, Stack } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as HomeIcon } from 'src/assets/home.svg';
import useLanguageOptions from 'src/hooks/useLanguageOptions';
import useTimeZoneOptions from 'src/hooks/useTimeZoneOptions';
import { PartnerProspect } from 'src/sdk/partner-prospect';
import { Button } from 'src/uikit/Button';
import { Card, CardBody, CardHeader } from 'src/uikit/Card';
import { Form, FormLabel, FormGroup } from 'src/uikit/Form';
import FormSelectInput from 'src/uikit/Form/SelectInput';
import FormTextInput from 'src/uikit/Form/TextInput';
import { H3 } from 'src/uikit/Typography';
import styled from 'styled-components/macro';
import * as yup from 'yup';

export const PartnerProspectGeneralFormSchema = yup.object({
  name: yup.string().required(),
  identifier: yup.string(),
  language: yup.mixed<PartnerProspect['language']>().required(),
  timeZone: yup.string().required(),
});

const WrapperIcon = styled.div`
  background-color: ${({ theme }) => theme.palette.main.primary};
  padding: 0.5rem;
  border-radius: 50%;
  width: 40px;
  height: 40px;

  & > * {
    color: white;
  }
`;

const FormFooter = styled(Row)`
  justify-content: flex-end;
  gap: 1rem;

  padding: 1.5rem 1rem 0;

  ${Button} {
    font-size: 14px;
  }
`;

export type PartnerProspectGeneralFormData = yup.InferType<
  typeof PartnerProspectGeneralFormSchema
>;

interface Props {
  defaultValues: PartnerProspectGeneralFormData;
  onSubmit: (values: PartnerProspectGeneralFormData) => void;
}

const PartnerProspectGeneralForm = ({ defaultValues, onSubmit }: Props) => {
  const { t } = useTranslation();
  const languageOptions = useLanguageOptions();
  const { data: timeZoneData } = useTimeZoneOptions();
  const timeZoneOptions = useMemo(() => {
    return timeZoneData || [];
  }, [timeZoneData]);

  const updateMethods = useForm<PartnerProspectGeneralFormData>({
    resolver: yupResolver(PartnerProspectGeneralFormSchema),
    mode: 'onChange',
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isDirty, isValid },
  } = updateMethods;

  return (
    <Card>
      <CardHeader>
        <Stack direction="horizontal" gap={2}>
          <WrapperIcon>
            <HomeIcon />
          </WrapperIcon>
          <H3 className="ml-2">
            {t(
              'components.PartnerProspects.PartnerProspectDetails.PartnerProspectGeneralForm.title',
            )}
          </H3>
        </Stack>
      </CardHeader>
      <CardBody>
        <FormProvider {...updateMethods}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <FormLabel>
                {t(
                  'components.PartnerProspects.PartnerProspectDetails.PartnerProspectGeneralForm.labels.name',
                )}
              </FormLabel>
              <FormTextInput
                name="name"
                placeholder={t(
                  'components.PartnerProspects.PartnerProspectDetails.PartnerProspectGeneralForm.placeholder.name',
                )}
                width="100%"
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>
                {t(
                  'components.PartnerProspects.PartnerProspectDetails.PartnerProspectGeneralForm.labels.identifier',
                )}
              </FormLabel>
              <FormTextInput
                name="identifier"
                placeholder={t(
                  'components.PartnerProspects.PartnerProspectDetails.PartnerProspectGeneralForm.placeholder.identifier',
                )}
                width="100%"
                maxLength={20}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>
                {t(
                  'components.PartnerProspects.PartnerProspectDetails.PartnerProspectGeneralForm.labels.languageAndTimezone',
                )}
              </FormLabel>
              <Row>
                <Col>
                  <FormSelectInput
                    name="language"
                    placeholder={t(
                      'components.PartnerProspects.PartnerProspectDetails.PartnerProspectGeneralForm.placeholder.language',
                    )}
                    options={languageOptions}
                  />
                </Col>
                <Col>
                  <FormSelectInput
                    name="timeZone"
                    placeholder={t(
                      'components.PartnerProspects.PartnerProspectDetails.PartnerProspectGeneralForm.placeholder.timezone',
                    )}
                    options={timeZoneOptions}
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormFooter xs="auto">
              <Button type="submit" disabled={!isValid || !isDirty}>
                {t(
                  'components.PartnerProspects.PartnerProspectDetails.PartnerProspectGeneralForm.save',
                )}
              </Button>
            </FormFooter>
          </Form>
        </FormProvider>
      </CardBody>
    </Card>
  );
};

export default PartnerProspectGeneralForm;
