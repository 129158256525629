import { useMemo } from 'react';
import { Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  ShippingRequest,
  ShippingRequestOutline,
  SparePartOutline,
} from 'src/assets/list-icons';
import { NursingHomeShippingRequestResponse } from 'src/sdk/nursingHome';
import { Icon } from 'src/uikit/Icon';
import { Label } from 'src/uikit/Typography';
import { formatDate } from 'src/utils/date';

import { SparePart } from '../../../../assets/list-icons/index';

const findSmallestDate = (
  shippingRequests: NursingHomeShippingRequestResponse[],
) => {
  const today = new Date(); // Get today's date in ISO format

  const smallestDate = shippingRequests.reduce<
    NursingHomeShippingRequestResponse | undefined
  >((minShippingRequest, shippingRequest) => {
    if (!shippingRequest?.requestedDeliveryDate) {
      return minShippingRequest;
    }

    const newDate = new Date(shippingRequest?.requestedDeliveryDate);
    const minDate = new Date(minShippingRequest?.requestedDeliveryDate || '');

    if (
      newDate <= today &&
      (minShippingRequest === undefined || newDate < minDate)
    ) {
      return shippingRequest;
    } else {
      return minShippingRequest;
    }
  }, undefined);

  return smallestDate;
};

interface Props {
  shippingRequests?: NursingHomeShippingRequestResponse[];
}

const NursingHomeRowShippingRequest = ({ shippingRequests }: Props) => {
  const { t, i18n } = useTranslation();

  const shippingRequest = useMemo(() => {
    if (!shippingRequests) {
      return null;
    }

    const shippingRequestsFiltered = shippingRequests.filter(
      (shippingRequest) =>
        shippingRequest.status !== 'ERROR' &&
        shippingRequest.status !== 'RECEIVED',
    );

    if (shippingRequestsFiltered.length === 0) {
      return null;
    }

    const lastShippingRequest = findSmallestDate(shippingRequestsFiltered);

    return lastShippingRequest;
  }, [shippingRequests]);

  const icon = useMemo(() => {
    return shippingRequest?.source === 'SPARE_PARTS'
      ? shippingRequest?.status === 'SHIPPED'
        ? SparePart
        : SparePartOutline
      : shippingRequest?.status === 'SHIPPED'
      ? ShippingRequest
      : ShippingRequestOutline;
  }, [shippingRequest]);

  const label = useMemo(() => {
    if (!shippingRequest) {
      return '-';
    }

    return shippingRequest?.status === 'SHIPPED'
      ? t(`components.nursingHomeList.NursingHomeRow.expected`, {
          date: formatDate(
            shippingRequest?.requestedDeliveryDate,
            i18n.language,
          ),
        })
      : t(`components.nursingHomeList.NursingHomeRow.notSent`);
  }, [i18n.language, shippingRequest, t]);

  return (
    <Stack direction="horizontal" gap={2}>
      {shippingRequest ? (
        <Icon as={icon} />
      ) : (
        <Icon as={ShippingRequest} $disabled />
      )}
      <Label className="ml-3">{label}</Label>
    </Stack>
  );
};

export default NursingHomeRowShippingRequest;
