import { AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import request from 'src/axios';

const useForceVerificationEmailMutation = () => {
  const queryClient = useQueryClient();

  const forceVerificationEmailMutation = useMutation<
    AxiosResponse<any>,
    Error,
    {
      userId: number;
    }
  >(
    (body) => {
      return request.post(`/users/force-verify-email`, body);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
      },
    },
  );

  return { ...forceVerificationEmailMutation };
};

export default useForceVerificationEmailMutation;
