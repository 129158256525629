import { InputHTMLAttributes, forwardRef, useMemo } from 'react';
import { ReactComponent as CheckedIcon } from 'src/assets/selected.svg';
import { Label } from 'src/uikit/Typography';
import styled, { css } from 'styled-components/macro';

type SwitchLabelPosition = 'left' | 'right';

const CheckBoxCheck = styled(CheckedIcon)`
  width: 0.8rem;
  height: 0.8rem;
  opacity: 0;
  top: 0;
  left: 0;
  margin: 0.45rem;

  position: absolute;
  transition: opacity 100ms ease-in;

  color: ${({ theme }) => theme.palette.main.white};
`;

const CheckBoxLabel = styled.label`
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
  position: absolute;
  top: 0;
  left: 0;
  width: 3rem;
  height: 1.6rem;
  border-radius: 1rem;
  background: ${({ theme }) => theme.palette.black[50]};
  cursor: pointer;
  &::after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 1.2rem;
    height: 1.2rem;
    margin: 0.2rem;
    background: ${({ theme }) => theme.palette.black[70]};
    transition: background 200ms ease-in-out, margin-left 200ms ease-in-out;
  }
`;
const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 1rem;
  cursor: pointer;
  box-sizing: border-box; /* Opera/IE 8+ */
  width: 3rem;
  &:checked + ${CheckBoxLabel} {
    background: ${({ theme }) => theme.palette.main.primary};

    ${CheckBoxCheck} {
      opacity: 1;
      transition: opacity 300ms ease-in;
    }

    &::after {
      background: ${({ theme }) => theme.palette.main.white};
      margin-left: 1.5rem;
    }
  }

  &:hover:not(:checked) {
    + ${CheckBoxLabel} {
      outline: 1px solid ${({ theme }) => theme.palette.black.grey};
      &::after {
        background: ${({ theme }) => theme.palette.main.primary};
      }
    }
  }

  &:disabled {
    pointer-events: none;
    + ${CheckBoxLabel} {
      pointer-events: none;
      background: ${({ theme }) => theme.palette.black.strongLightGrey};
      outline: 1px solid ${({ theme }) => theme.palette.black.grey};

      &::after {
        background: ${({ theme }) => theme.palette.black.grey};
      }
    }
  }
`;

const StyledLabel = styled(Label)`
  flex-basis: 0;
  flex-grow: 1;
  font-weight: 500;
  max-width: 100%;
`;

const CheckBoxWrapper = styled.div<{ $labelPosition: SwitchLabelPosition }>`
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  height: 1.6rem;

  flex-direction: ${({ $labelPosition }) =>
    $labelPosition === 'right' ? 'row' : 'row-reverse'};

  ${CheckBoxLabel} {
    flex-direction: ${({ $labelPosition }) =>
      $labelPosition === 'left'
        ? css`
            right: 0;
            left: unset;
          `
        : ''};
  }
`;

export type SwitchInputProps = {
  label?: string;
  labelPosition?: SwitchLabelPosition;
  onChange: (isChecked: boolean) => void;
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>;

const SwitchInput = forwardRef<HTMLInputElement, SwitchInputProps>(
  ({ name, label, onChange, labelPosition = 'right', ...rest }, ref) => {
    const _name = useMemo(() => name || crypto.randomUUID(), [name]);

    return (
      <CheckBoxWrapper $labelPosition={labelPosition}>
        <CheckBox
          id={_name}
          ref={ref}
          type="checkbox"
          name={_name}
          onChange={(e) => {
            onChange(e.target.checked);
          }}
          {...rest}
        />
        <CheckBoxLabel htmlFor={_name}>
          <CheckBoxCheck />
        </CheckBoxLabel>
        {label && <StyledLabel>{label}</StyledLabel>}
      </CheckBoxWrapper>
    );
  },
);

export default SwitchInput;
