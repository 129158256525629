import { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ChevronRight } from 'src/assets/arrows';
import { ReactComponent as DeleteIcon } from 'src/assets/delete-badge.svg';
import {
  ShippingRequest,
  ShippingRequestOutline,
  SparePart,
  SparePartOutline,
} from 'src/assets/list-icons';
import useDeleteShippingRequestMutation from 'src/components/NursingHome/ShippingRequest/hooks/useDeleteShippingRequestMutation';
import { useAuth } from 'src/contexts/Auth';
import useFormatErrorApi from 'src/hooks/useFormatErrorApi';
import useToast from 'src/hooks/useToast';
import {
  ShippingRequestResponse,
  ShippingRequestHandlingUnitResponse,
} from 'src/sdk/shippingRequest';
import { Button } from 'src/uikit/Button';
import { Card, CardBody } from 'src/uikit/Card';
import { formatDate } from 'src/utils/date';
import styled, { css } from 'styled-components/macro';

import EquipmentsWithNumber from './EquipmentsWithNumber';
import HandlingUnitsDetails from './HandlingUnitsDetails';
import ShippingRequestDetails from './ShippingRequestDetailsMovements';
import ShippingStatus from './ShippingStatus';

export enum ShippingRequestMode {
  MOVEMENT,
  HANDLING_UNIT,
}

const StyledOutline = styled.svg<{ $shipped: boolean }>`
  color: ${({ $shipped, theme }) => ($shipped ? '' : theme.palette.black[60])};
`;

const StyledColIcon = styled(Col)`
  flex: 0 0 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ArrowButton = styled.button`
  display: flex;
  background: none;
  border: none;
  transition-duration: 0.5s;

  &.rotate {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    transition-duration: 0.5s;
  }
`;

const ArrowIcon = styled(ChevronRight)`
  color: ${({ theme }) => theme.palette.main.primary};

  &.rotate {
    color: ${({ theme }) => theme.palette.main.red};
  }
`;

const StyledRow = styled(Row)`
  display: flex;
  align-items: center;
  width: 100%;
`;

const DisplayCol = styled(Col)<{ isvisible?: boolean }>`
  min-height: 0;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  transition: min-height 200ms ease-in-out, opacity 200ms ease-in-out;
  ${({ isvisible }) =>
    isvisible &&
    css`
      transition: max-height 200ms ease-in-out, opacity 200ms ease-in-out;
      min-height: 200px;
      max-height: 100%;
      opacity: 1;
      visibility: visible;
    `};
`;

const StyledCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledButton = styled(Button)`
  width: fit-content;
  padding: 0;
  border-radius: 2px;
  min-height: fit-content;
  background: transparent;
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  justify-content: center;
`;

type ShippingRequestExpandableListProps = {
  selected: boolean;
  onClick: (id: number) => void;
  shippingRequest: ShippingRequestResponse;
  mode: ShippingRequestMode;
};

const ShippingRequestExpandableItem = ({
  shippingRequest,
  mode,
  onClick,
  selected,
}: ShippingRequestExpandableListProps) => {
  const { t, i18n } = useTranslation();
  const { toastError, toastSuccess } = useToast();
  const { formatErrorApi } = useFormatErrorApi();

  const { authUser } = useAuth();

  const { deleteShippingRequestMutation } = useDeleteShippingRequestMutation();

  const handlingUnits = useMemo(() => {
    if (!shippingRequest?.counts?.equipmentCounts) {
      return [];
    }

    return [
      ...(shippingRequest.handlingUnits || []),
      ...shippingRequest?.counts?.equipmentCounts?.reduce(
        (acc, equipmentCount) => {
          if (
            typeof equipmentCount.requestedCount !== 'number' ||
            typeof equipmentCount.sentCount !== 'number' ||
            typeof equipmentCount.receivedCount !== 'number'
          ) {
            return acc;
          }

          const missing =
            equipmentCount.requestedCount -
            equipmentCount.sentCount -
            equipmentCount.receivedCount;

          if (missing > 0) {
            const newArray = Array.from({ length: missing }).map(
              () =>
                ({
                  id: -1,
                  equipmentType: equipmentCount.type,
                  materialNumberCode: '',
                  quantity: -1,
                  receptionDate: '',
                  sendingDate: '',
                  sscc: '',
                } as ShippingRequestHandlingUnitResponse),
            );
            return [...acc, ...newArray];
          }

          return acc;
        },
        [] as ShippingRequestHandlingUnitResponse[],
      ),
    ];
  }, [shippingRequest?.counts?.equipmentCounts, shippingRequest.handlingUnits]);

  const canBeDelete = useMemo(() => {
    return (
      shippingRequest.status === 'CREATED' &&
      (authUser?.profile === 'PARTNER_ADMINISTRATOR' ||
        authUser?.profile === 'ORIZON_ADMINISTRATOR')
    );
  }, [authUser?.profile, shippingRequest.status]);

  const onDelete = async (e: React.MouseEvent) => {
    e.stopPropagation();
    try {
      await deleteShippingRequestMutation.mutateAsync({
        id: shippingRequest.id,
      });
      toastSuccess(
        t('components.nursingHome.equipments.shippingRequests.successDelete'),
      );
    } catch (err) {
      const message = formatErrorApi(err);
      toastError(message);
    }
  };

  return (
    <Card
      key={shippingRequest.id}
      onClick={() => onClick(shippingRequest.id)}
      $clickable
      shadow="xs"
    >
      <CardBody>
        <StyledRow>
          <StyledColIcon className="px-0">
            {shippingRequest.source === 'SPARE_PARTS' ? (
              shippingRequest.status === 'RECEIVED' ? (
                <SparePart />
              ) : (
                <StyledOutline
                  as={SparePartOutline}
                  $shipped={shippingRequest.status === 'SHIPPED'}
                />
              )
            ) : shippingRequest.status === 'RECEIVED' ? (
              <ShippingRequest />
            ) : (
              <StyledOutline
                as={ShippingRequestOutline}
                shipped={shippingRequest.status === 'SHIPPED'}
              />
            )}
          </StyledColIcon>
          <StyledCol xs="2" md="1">{`#${shippingRequest.id}`}</StyledCol>
          <StyledCol xs="4" md="2">
            {formatDate(shippingRequest.createdDate, i18n.language)}
          </StyledCol>
          <StyledCol xs="4" md="2">
            {formatDate(shippingRequest.requestedDeliveryDate, i18n.language)}
          </StyledCol>
          <StyledCol xs="8" md="4">
            <EquipmentsWithNumber
              clipOnNb={
                shippingRequest?.counts?.equipmentCounts?.find(
                  (count) => count.type === 'CLIPON',
                )?.requestedCount || 0
              }
              gatewayNb={
                shippingRequest?.counts?.equipmentCounts?.find(
                  (count) => count.type === 'GATEWAY',
                )?.requestedCount || 0
              }
              chargingStationNb={
                shippingRequest?.counts?.equipmentCounts?.find(
                  (count) => count.type === 'CHARGING_STATION',
                )?.requestedCount || 0
              }
            />
          </StyledCol>
          <StyledCol xs="3" md="1">
            <ShippingStatus status={shippingRequest.status} />
          </StyledCol>
          <StyledCol className="d-flex justify-content-end">
            {canBeDelete && (
              <StyledButton onClick={onDelete} background="danger">
                <DeleteIcon />
              </StyledButton>
            )}

            <ArrowButton className={selected ? 'rotate' : ''}>
              <ArrowIcon className={selected ? 'rotate' : ''} />
            </ArrowButton>
          </StyledCol>
        </StyledRow>
        <DisplayCol
          md="12"
          isvisible={selected ? 1 : 0} // PC: pure boolean cause a console warning.
          onClick={(e: React.MouseEvent<SVGSVGElement, MouseEvent>) =>
            e.stopPropagation()
          }
        >
          {mode === ShippingRequestMode.MOVEMENT ? (
            <ShippingRequestDetails
              address={
                shippingRequest.deliveryAddress || shippingRequest.customAddress
              }
              movements={shippingRequest.movements}
            />
          ) : (
            <HandlingUnitsDetails
              shippingRequest={shippingRequest}
              handlingUnits={handlingUnits}
            />
          )}
        </DisplayCol>
      </CardBody>
    </Card>
  );
};

export default ShippingRequestExpandableItem;
