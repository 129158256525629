import { format } from 'date-fns';
import { Col, Row } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import { ReactComponent as Charging } from 'src/assets/charging-badge.svg';
import { ReactComponent as Clipon } from 'src/assets/cliponx10-badge.svg';
import { ReactComponent as Gateway } from 'src/assets/gateway-badge.svg';
import SortingHandlingUnitsHeader from 'src/components/NursingHome/ShippingRequest/SortingHandlingUnitsHeader';
import { DATE_FORMAT_DAY } from 'src/constants';
import { EquipmentTypes } from 'src/sdk/equipment';
import {
  ShippingRequestHandlingUnitResponse,
  ShippingRequestResponse,
  ShippingStatus,
} from 'src/sdk/shippingRequest';
import { List } from 'src/uikit/Layout/List';
import { Label, Emphased, Text } from 'src/uikit/Typography';
import styled from 'styled-components/macro';

import HandlingUnitStatusBadge from './HandlingUnitStatusBadge';
import ShippingAddress from './ShippingAddress';

const Wrapper = styled.div`
  padding-top: 0.75rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const WrapperHandlingUnit = styled.div`
  padding: 0 3rem;
`;

const Separator = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.palette.black[50]};
`;

const Content = styled(Row)`
  border-radius: 10px;
  display: flex;
`;

const CliponBadge = styled(Clipon)`
  height: 40px;
  width: 40px;
`;

const ChargingBadge = styled(Charging)`
  height: 40px;
  width: 40px;
`;

const GatewayBadge = styled(Gateway)`
  height: 40px;
  width: 40px;
`;

const StyledCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledColIcon = styled(StyledCol)`
  padding-inline: 0;
  flex: 0 0 40px;
`;

export type HandlingUnit = {
  id: string;
  sscc: string;
  quantity: number;
  shippingStatus: ShippingStatus;
  receptionDate: string;
  sendingDate: string;
  equipmentType: EquipmentTypes;
  materialNumberCode: string;
};

type ShippingRequestDetailsProps = {
  shippingRequest: ShippingRequestResponse;
  handlingUnits?: Array<ShippingRequestHandlingUnitResponse>;
};

const HandlingUnitsDetails = ({
  handlingUnits,
  shippingRequest,
}: ShippingRequestDetailsProps) => {
  return (
    <Wrapper>
      <Separator />
      <WrapperHandlingUnit className="mt-4">
        <Row className="align-items-center">
          <Col className="ml-2">
            {shippingRequest.nursingHome?.name && (
              <Row xs="auto">
                <Col>
                  <Label>
                    <Trans i18nKey="components.nursingHome.equipments.shippingRequests.nursingHome" />
                  </Label>
                </Col>
                <Col>
                  <Emphased className="ml-2">
                    {shippingRequest.nursingHome?.name}
                  </Emphased>
                </Col>
              </Row>
            )}
            <Row xs="auto" className="mt-1">
              <Col>
                <Label>
                  <Trans i18nKey="components.nursingHome.equipments.shippingRequests.requester" />
                </Label>
              </Col>
              <Col>
                <Emphased className="ml-2">
                  {shippingRequest.requester?.firstName
                    ? `${shippingRequest.requester?.firstName} ${shippingRequest.requester?.lastName}`
                    : '-'}
                </Emphased>
              </Col>
            </Row>
            {shippingRequest.source === 'SPARE_PARTS' && (
              <Row xs="auto" className="mt-1">
                <Col>
                  <Label>
                    <Trans i18nKey="components.nursingHome.equipments.shippingRequests.comment" />
                  </Label>
                </Col>
                <Col>
                  <Text className="ml-2">
                    {shippingRequest?.comment
                      ? `${shippingRequest?.comment}`
                      : '-'}
                  </Text>
                </Col>
              </Row>
            )}
          </Col>
          <Col>
            {shippingRequest.deliveryAddress && (
              <ShippingAddress address={shippingRequest.deliveryAddress} />
            )}

            {!shippingRequest.deliveryAddress &&
              shippingRequest.customAddress && (
                <ShippingAddress address={shippingRequest.customAddress} />
              )}
          </Col>
        </Row>
      </WrapperHandlingUnit>
      {handlingUnits && handlingUnits.length > 0 && (
        <WrapperHandlingUnit className="my-4">
          <SortingHandlingUnitsHeader />
          <List gap="2rem" className="my-3">
            {handlingUnits?.map((handlingUnit, index) => (
              <Content key={index}>
                <StyledColIcon>
                  {handlingUnit.equipmentType === EquipmentTypes.CLIPON && (
                    <CliponBadge />
                  )}
                  {handlingUnit.equipmentType === EquipmentTypes.GATEWAY && (
                    <GatewayBadge />
                  )}
                  {handlingUnit.equipmentType === EquipmentTypes.CHARGING && (
                    <ChargingBadge />
                  )}
                </StyledColIcon>
                <StyledCol md="2">
                  {!handlingUnit?.id || handlingUnit.id === -1
                    ? '-'
                    : format(
                        new Date(
                          shippingRequest.movements?.find(
                            (movement) =>
                              movement.handlingUnitId === handlingUnit.id &&
                              movement.type === '601',
                          )?.date || '',
                        ),
                        DATE_FORMAT_DAY,
                      )}
                </StyledCol>
                <StyledCol md="2">
                  {!handlingUnit.id || handlingUnit.id === -1
                    ? '-'
                    : !handlingUnit.receptionDate
                    ? '-'
                    : format(
                        new Date(handlingUnit.receptionDate),
                        DATE_FORMAT_DAY,
                      )}
                </StyledCol>
                <StyledCol md="2">
                  {!handlingUnit.id || handlingUnit.id === -1
                    ? '-'
                    : handlingUnit.sscc}
                </StyledCol>
                <StyledCol md="2">
                  {/* <NumberText>{handlingUnit.id === '-1' ? '-' : ''}</NumberText> */}
                </StyledCol>
                <StyledCol md="2">
                  {/* <NumberText>{handlingUnit.id === '-1' ? '-' : ''}</NumberText> */}
                </StyledCol>
                <StyledCol md="1">
                  <HandlingUnitStatusBadge
                    status={handlingUnit.shippingStatus}
                  />
                </StyledCol>
              </Content>
            ))}
          </List>
        </WrapperHandlingUnit>
      )}
    </Wrapper>
  );
};

export default HandlingUnitsDetails;
