import { ReactNode } from 'react';
import styled from 'styled-components/macro';

import { ReactComponent as Belgium } from './belgium.svg';
import { ReactComponent as English } from './english.svg';
import { ReactComponent as France } from './france.svg';
import { ReactComponent as Germany } from './germany.svg';
import { ReactComponent as Italy } from './italy.svg';
import { ReactComponent as Spain } from './spain.svg';

export const BeFlag = styled(Belgium)`
  width: 24px;
  height: 24px;
`;

export const EnFlag = styled(English)`
  width: 24px;
  height: 24px;
`;

export const FrFlag = styled(France)`
  width: 24px;
  height: 24px;
`;

export const DeFlag = styled(Germany)`
  width: 24px;
  height: 24px;
`;

export const ItFlag = styled(Italy)`
  width: 24px;
  height: 24px;
`;

export const EsFlag = styled(Spain)`
  width: 24px;
  height: 24px;
`;

export const COUNTRY_FLAGS: {
  [key: string]: ReactNode;
} = {
  be: <BeFlag />,
  en: <EnFlag />,
  fr: <FrFlag />,
  de: <DeFlag />,
  it: <ItFlag />,
  es: <EsFlag />,
} as const;
