import { AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import request from 'src/axios';

const useDeletePartnerMutation = () => {
  const queryClient = useQueryClient();

  const deletePartnerMutation = useMutation<
    AxiosResponse,
    Error,
    {
      id: number;
    }
  >(({ id }) => request.delete(`/partners/${id}`), {
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
  });

  return { deletePartnerMutation };
};

export default useDeletePartnerMutation;
