import { useQuery } from 'react-query';
import request from 'src/axios';
import { GetPartnersResponse, Partner, PartnerExpand } from 'src/sdk/partner';

export type PartnersSortField = {
  label: keyof Partner;
  direction: 'asc' | 'desc';
};

type QueryParams = {
  page: number;
  size: number;
  expand: PartnerExpand[];
  nameOrSoldtoPart: string;
  sort: PartnersSortField;
};

const fetchPartners = async ({
  page,
  size,
  expand,
  nameOrSoldtoPart,
  sort,
}: QueryParams) => {
  const { data } = await request.get('/partners', {
    params: {
      page,
      size,
      expand: expand.join(','),
      'name-or-soldto-part': nameOrSoldtoPart,
      sort: `${sort.label},${sort.direction}`,
    },
  });

  return data;
};

const useFetchPartnersQuery = (params: QueryParams) => {
  const { data, isLoading, refetch, error } = useQuery<
    GetPartnersResponse,
    Error
  >(['partnersList', { ...params }], () => fetchPartners(params));

  return {
    data,
    isLoading,
    refetch,
    error,
  };
};

export default useFetchPartnersQuery;
