import { createPortal } from 'react-dom';
import { toast, ToastOptions, ToastContainer } from 'react-toastify';
import usePortal from 'src/hooks/usePortal';

/**
 * A hook that provides toast notification functions.
 *
 * @return {Object} An object containing toast notification functions.
 */
const useToast = () => {

  /**
   * Displays a toast error message.
   *
   * @param {string} label - The error message to display.
   * @param {ToastOptions} options - (optional) Additional options for the toast.
   */
  const toastError = (label: string, options?: ToastOptions) => {
    toast.error(label, { autoClose: false, ...options });
  };


  /**
   * Display a toast error message to the client.
   *
   * @param {string} label - The message to display in the toast.
   * @param {ToastOptions} options - Optional configuration options for the toast.
   * @return {void}
   */
  const toastErrorClient = (label: string, options?: ToastOptions) => {
    toast.error(label, { ...options });
  };

  /**
   * Displays a toast message with a success icon.
   *
   * @param {string} label - The text to display in the toast message.
   * @param {ToastOptions} options - (Optional) Additional options for the toast message.
   * @return {void}
   */
  const toastSuccess = (label: string, options?: ToastOptions) => {
    toast.success(label, { ...options });
  };

  return { toastError, toastSuccess, toastErrorClient };
};

export default useToast;


/**
 * A function component that renders a toast container inside a portal.
 *
 * @returns {ReactNode} The rendered toast container.
 */
export const PortalToastContainer = () => {
  const target = usePortal();

  return createPortal(
    <ToastContainer position="top-right" closeOnClick limit={5} />,
    target,
  );
};
