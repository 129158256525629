import { Address, GetResponse } from './common';
import { PartnerProspect } from './partner-prospect';
import { Prospect } from './prospect';
import { EquipmentMetrics } from './provisioning';

export const nursingHomeExpandList = [
  'parameters',
  'address',
  'delivery-addresses',
  'firmware',
  'subscription',
  'shipping-requests',
  'clipons',
  'gateways',
  'gateway-connection-activity',
  'charging-stations',
  'counts',
  'clipon-metrics',
  'gateway-metrics',
  'charging-station-metrics',
  'actions',
  'delivery-addresses-shipping-request-count',
  'nursing-home-user',
] as const;

export type NursingHomeExpand = typeof nursingHomeExpandList[number];

export interface RouteParams {
  id: string;
  modal: string;
  salesOrg: string;
}

export interface GetParams {
  page: number;
  size: number;
  sort?: string;
}

export interface CommonDetails {
  description: string;
  deviceId: string;
  equipmentStatus: string;
  id: number;
  name: string;
  receivedAt: string; // Should be in Clipons| but doing so has several code impacts
  registrationStatus: string;
  serialNumber: string; // Should be in Clipons| but doing so has several code impacts
}

export interface ChargingStations extends CommonDetails {
}

export interface Clipons extends CommonDetails {
  concatVersion?: string; // used to sort version of firmware by major => minor => revision
  firmwareMajorVersion: number;
  firmwareMinorVersion: number;
  firmwareRevisionVersion: number; // used to sort version of firmware by major => minor => revision
}

export interface Gateways extends CommonDetails {
  connectionActivity: {
    connectionStatus: string;
    latestActivityDate: string;
  };
  equipmentStatus: string;
  eui: string;
}

export interface NHAddress extends Address {
  comment: string;
  id: number;
}

export interface DeliveryAddress extends Address {
  comment: string;
  id: number;
  shippingRequestCount?: number;
  shipTo?: string;
}

export enum NursingHomeParameter {
  TimeZone = 'TIME_ZONE',
  ProspectId = 'SAP_ID',
  SalesOrg = 'SALES_ORG',
  ProductAssociationDate = 'PRODUCT_ASSOCIATION_DATE',
}

export interface Parameter {
  id: number;
  name: string;
  value: string;
}

export interface Firmware {
  digest: string;
  id: number;
  size: number;
  versionMajor: number;
  versionMinor: number;
  versionRevision: number;
}

export interface Action {
  needEquipmentAssignment: boolean;
}

export interface Counts {
  cliponCount: number;
  shippingRequestCount: number;
  wrongFirmwareCliponCount: number;
}

export interface NursingHomeUserResponse {
  /** Format: int64 */
  id?: number;
  username?: string;
}

export interface NursingHomeShippingRequestResponse {
  /** Format: int64 */
  id?: number;
  /** Format: date-time */
  requestedDeliveryDate?: string;
  /** @enum {string} */
  source?: 'SUBSCRIPTION' | 'SPARE_PARTS';
  /** @enum {string} */
  status?: 'CREATED' | 'ERROR' | 'REQUESTED' | 'SHIPPED' | 'RECEIVED';
}

export interface NursingHomeSubscriptionResponse {
  automaticRenewal?: boolean;
  /** Format: int32 */
  chargingStationEstimate?: number;
  /** Format: int32 */
  cliponEstimate?: number;
  /** Format: date-time */
  endDate?: string;
  /** Format: int32 */
  gatewayEstimate?: number;
  /** Format: int64 */
  id?: number;
  main?: boolean;
  /** Format: int32 */
  residentEstimate?: number;
  /** Format: date-time */
  startDate?: string;
  /** @enum {string} */
  status?: 'CREATED' | 'BLOCKED' | 'ACTIVE' | 'TERMINATED';
  /** @enum {string} */
  type?: 'DEMO' | 'STANDARD';
}

export interface NursingHomeResponse {
  actions: Action;
  address: NHAddress;
  chargingStationMetrics?: EquipmentMetrics;
  chargingStations: ChargingStations[];
  cliponCount: number;
  cliponMetrics?: EquipmentMetrics;
  clipons: Clipons[];
  concatVersion?: string; // used to sort current version of firmware by major => minor => revision
  counts?: Counts;
  deliveryAddresses: DeliveryAddress[];
  firmware: Firmware;
  gatewayMetrics?: EquipmentMetrics;
  gateways: Gateways[];
  id: number;
  language: 'en' | 'de' | 'fr' | 'nl_BE' | 'it' | 'es';
  name: string;
  dayStartTime: string;
  nightStartTime: string;
  tolerance: number;
  parameters: Parameter[];
  nursingHomeUser?: NursingHomeUserResponse;
  // used to sort total count of clipon and out of date clipon of clipons
  // because data comes from expand selector and not usable in sort params
  productAssociationMode?: 'MANUAL' | 'B2B';
  salesOrg: string;
  soldTo: string;
  shippingRequests?: NursingHomeShippingRequestResponse[];
  subscription: NursingHomeSubscriptionResponse;
  tenantName: string;
  timeZone: string;
  wrongFirmwareCliponCount: number;
  ecsMode: string;
}

export interface GetNursingHomeResponse
  extends GetResponse<NursingHomeResponse> {
}

export interface Timezone {
  id: string;
  offset: number;
}

export interface LegalEntity {
  id: number,
  name: string,
  address: string,
  country: string,
  countryRegistry: string
}

export type NursingHomeCreationType =
  | Prospect['type']
  | PartnerProspect['type'];

export interface NursingHomeRequest {
  /** Format: int64 */
  firmwareId?: number;
  /** @enum {string} */
  language: 'en' | 'de' | 'fr' | 'nl_BE' | 'it' | 'es';
  name: string;
  /** @description Used by reads, optional during creation, ignored during updates. */
  tenantName?: string;
  /** @description Nursing home time zone, e.g. "Europe/Brussels". */
  timeZone: string;
}


export interface ManeulModeRequest {
  /** Format: string */
  productAssociationMode: 'MANUAL' | 'B2B';
}

export interface CreateNursingHome {
  /** Format: int64 */
  directorCommonAvatarId?: number;
  directorEmail: string;
  directorFirstName: string;
  /** @enum {string} */
  directorGender: 'M' | 'F';
  /** @enum {string} */
  directorLanguage: 'en' | 'de' | 'fr' | 'nl_BE' | 'it' | 'es';
  directorLastName: string;
  directorPin: string;
  /** @enum {string} */
  language: 'en' | 'de' | 'fr' | 'nl_BE' | 'it' | 'es';
  legalEntity?: string,
  name: string;
  nursingHomeUsername: string;
  /**
   * Format: int64
   * @description The ID of the partner prospect. Only used when importing from a partner prospect.
   */
  partnerProspectId: number | null;
  /** @description The SAP ID of the prospect, also known as the 'soldTo'. Only used when importing from a prospect. */
  prospectId: string | null;
  /** @description The sales org of the prospect. Only used when importing from a prospect. */
  salesOrg: string | null;
  shipToValues: string[] | null;
  /** @description Nursing home time zone, e.g. "Europe/Brussels". */
  timeZone: string;
}

export interface EstimatedEquipment {
  chargingStationEstimate: number;
  cliponEstimate: number;
  gatewayEstimate: number;
  residentEstimate: number;
}

export type NursingHomeDeliveryAddressesRequest = {
  addShipTos?: string[];
  removeShipTos?: string[];
};
