import { AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import request from 'src/axios';
import { OrizonError } from 'src/sdk/common';
import { NursingHomeRequest } from 'src/sdk/nursingHome';

const useUpdateMainInformations = (id: number) => {
  const queryClient = useQueryClient();

  const updateMainInformationsMutation = useMutation<
    AxiosResponse<NursingHomeRequest>,
    OrizonError,
    NursingHomeRequest
  >((values) => request.put(`/nursing-homes/${id}`, values), {
    onSuccess: () => {
      queryClient.invalidateQueries(`nursing-home-${id}`);
    },
  });

  return { updateMainInformationsMutation };
};

export default useUpdateMainInformations;
