import { useQuery } from 'react-query';
import request from 'src/axios';
import { GetBillingAssembliesResponse } from 'src/sdk/subscription';

type QueryParams = {
  page: number;
  size: number;
  nursingHomeId: number;
};

const fetchSubscriptionsBilling = async ({
  nursingHomeId,
  page,
  size,
}: QueryParams) => {
  const { data } = await request.get(`/billing-assemblies`, {
    params: {
      'nursing-home-id': nursingHomeId,
      page: page,
      size: size,
      sort: 'id,DESC',
      expand: 'subscription',
    },
  });

  return data;
};

const useFetchSubscriptionsBillingQuery = (params: QueryParams) => {
  const { data, isLoading, refetch, error } = useQuery<
    GetBillingAssembliesResponse,
    Error
  >(
    [`subscriptionBillingsList${params.nursingHomeId}`, { ...params }],
    () => fetchSubscriptionsBilling(params),
    {
      enabled: params.nursingHomeId > 0,
      keepPreviousData: true,
    },
  );

  return {
    data,
    isLoading,
    refetch,
    error,
  };
};

export default useFetchSubscriptionsBillingQuery;
