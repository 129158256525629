import { useMemo } from 'react';
import useFetchCountries from 'src/hooks/useFetchCountries';
import { GroupedOption } from 'src/uikit/SelectInput';

export interface CountryOption {
  value: string;
  label: string;
  group: string;
}

const priority: { [key: string]: string } = {
  '0': 'Others',
  '1': 'Favourites',
} as const;

const useOptionsCountries = () => {
  const { countries } = useFetchCountries();

  const countriesOptions = useMemo(
    () =>
      countries?.map((country) => {
        return {
          label: country.name,
          value: country.sapCode,
          group: country.priority.toString(),
        };
      }) || [],
    [countries],
  );

  const groupedCountriesOptions: GroupedOption<CountryOption>[] =
    countriesOptions?.length === 0
      ? []
      : countriesOptions?.reduce((acc, country) => {
          const group = acc.find((option) => option.label === country.group);

          if (!group) {
            return [
              ...acc,
              {
                label: country.group,
                options: [country],
              },
            ];
          }

          return acc.map((_group) =>
            _group.label === group.label
              ? {
                  ..._group,
                  options: [..._group.options, country],
                }
              : _group,
          );
        }, [] as GroupedOption<CountryOption>[]);

  const groupedCountriesOptionsFormatted = groupedCountriesOptions
    .sort((a, b) => parseInt(a.label) + parseInt(b.label))
    .map((groupedCountriesOption) => ({
      ...groupedCountriesOption,
      label: priority[groupedCountriesOption.label] || priority['0'],
    }));

  return { groupedCountriesOptions: groupedCountriesOptionsFormatted };
};

export default useOptionsCountries;
