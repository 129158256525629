import { QueryObserverOptions, useQuery } from 'react-query';
import request from 'src/axios';
import { EquipmentMetrics } from 'src/sdk/provisioning';

const paramsMap = {
  clipon: '/clipons/metrics',
  charging: '/charging-stations/metrics',
  gateway: '/gateways/metrics',
} as const;

export async function fetchEquipmentsMetrics(type: keyof typeof paramsMap) {
  const { data } = await request.get(paramsMap[type]);
  return data;
}

const useFetchEquipmentsMetricsQuery = (
  type: keyof typeof paramsMap,
  options?: QueryObserverOptions<EquipmentMetrics, Error>,
) => {
  const { data, isLoading, refetch, error } = useQuery<EquipmentMetrics, Error>(
    `${type}-metrics`,
    () => fetchEquipmentsMetrics(type),
    { ...options },
  );

  return {
    data,
    isLoading,
    refetch,
    error,
  };
};

export default useFetchEquipmentsMetricsQuery;
