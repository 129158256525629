import { Gender } from 'src/sdk/common';
import {
  CreateNursingHome,
  NursingHomeCreationType,
} from 'src/sdk/nursingHome';
import { clientTimeZone } from 'src/utils/timeZone';

import {
  NursingHomeCreationSteps,
  NursingHomeCreationStep,
} from '../Stepper/NursingHomeCreationContext';
import useCreateNursingHomeMutation from './useCreateNursingHomeMutation';

export const simulateData = (
  data: Partial<CreateNursingHome>,
  steps: NursingHomeCreationSteps,
  currentStep: NursingHomeCreationStep,
  type: NursingHomeCreationType,
) => {
  const tmpData = { ...data };

  const _currentStep = steps[currentStep] || 0;
  const _directorStep = steps[NursingHomeCreationStep.DirectorAccount] || 0;
  const _accountStep = steps[NursingHomeCreationStep.NursingHomeAccount] || 0;

  if (_currentStep < _directorStep) {
    tmpData.directorFirstName = Date.now().toString();
    tmpData.directorLastName = Date.now().toString();
    tmpData.directorEmail = Date.now().toString() + '@test.fr';
    tmpData.directorPin = '1234';
    tmpData.directorLanguage = 'en';
    tmpData.directorGender = Gender.Male;
    tmpData.directorCommonAvatarId = 0;

    if (_currentStep < _accountStep) {
      tmpData.name = String(
        type === 'PartnerProspect' ? data.partnerProspectId : data.prospectId,
      );
      tmpData.nursingHomeUsername = String(
        type === 'PartnerProspect' ? data.partnerProspectId : data.prospectId,
      );
      tmpData.language = 'en';
      tmpData.timeZone = clientTimeZone;
    }
  }

  return tmpData;
};

const useSimulateNursingHomeCreation = (type: NursingHomeCreationType) => {
  const { createNursingMutation } = useCreateNursingHomeMutation(type, true);

  const simulateNursingHome = (
    data: Partial<CreateNursingHome>,
    steps: NursingHomeCreationSteps,
    currentStep: NursingHomeCreationStep,
  ) => {
    const tmpData = simulateData(
      data,
      steps,
      currentStep,
      type,
    ) as CreateNursingHome;
    return createNursingMutation.mutateAsync(tmpData);
  };

  return { simulateNursingHome, isLoading: createNursingMutation.isLoading };
};

export default useSimulateNursingHomeCreation;
