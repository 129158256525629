import { useMemo } from 'react';
import { ReactComponent as ValidIcon } from 'src/assets/valid.svg';
import styled, { css, keyframes } from 'styled-components/macro';

export enum StepStates {
  NOT_STARTED = 'Pending',
  CURRENT = 'In progress',
  ERROR = 'Error',
  COMPLETED = 'Completed',
}

export interface StepperDataProps {
  label: string;
  name: string;
  state?: StepStates;
  content: React.ReactNode;
  icon?: React.ReactNode;
  hideStep?: boolean;
}

export interface FinalProps {
  content: React.ReactNode;
}

export interface StepProgressProps {
  stepperData: StepperDataProps[];
  currentStep: number;
  setCurrentStep: (val: number) => void;
  hideStepLabel?: boolean;
}

export interface ReducerAction {
  type: string;
  payload: { index: number; state: StepStates };
}

const springUp = keyframes`
  0% { transform: scale(1); }
  10% { transform: scale(0.975); }
  40% { transform: scale(1.4); }
  50%, 100% { transform: scale(1.2); }
`;

const springDown = keyframes`
  0% { transform: scale(1.2); }
  35%, 100% { transform: scale(1); }
`;

const shake = keyframes`
  0% { transform: translateX(0); }
  15% { transform: translateX(0.2rem); }
  30%, 60% { transform: translateX(-0.4rem); }
  45%, 75% { transform: translateX(0.4rem); }
  90% { transform: translateX(-0.2rem); }
  100% { transform: translateX(0); }
`;

const StepProgressBar = styled.ul`
  box-sizing: border-box;
  margin: 0 0 2rem 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
`;

const ProgressStep = styled.li<{ step: StepStates }>`
  list-style: none;
  position: relative;
  text-align: center;
  flex: 1 1 auto;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    height: 3px;
    width: 100%;
    left: 50%;
    transform: translateY(-50%);
    z-index: -1;
    background: #c4c4c4;
    background-position: right bottom;
    transition: background-position 1000ms ease-in-out;

    ${(props) =>
      props.step === StepStates.CURRENT &&
      css`
        background: linear-gradient(
          to left,
          #c4c4c4 50%,
          ${({ theme }) => theme.palette.main.greenElectric} 50%
        );
      `}

    ${(props) =>
      props.step === StepStates.COMPLETED &&
      css`
        background: ${({ theme }) => theme.palette.main.greenElectric};
      `}
  }

  &:last-child::after {
    content: none;
  }
`;

const StepLabel = styled.div`
  color: var(--text-normal);
  position: absolute;
  top: 120%;
  left: 50%;
  transform: translateX(-50%);
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1px;
  color: ${({ theme }) => theme.palette.black.main};
  height: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const StepLabelSubtitle = styled.div<{ state: StepStates }>`
  display: block;
  border-radius: 5px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  text-align: center;
  padding: 5px 0;
  width: 85px;

  ${(props) =>
    props.state === StepStates.COMPLETED &&
    css`
      background-color: ${({ theme }) => theme.palette.main.greenElectric};
      color: ${({ theme }) => theme.palette.main.white};
    `}

  ${(props) =>
    props.state === StepStates.CURRENT &&
    css`
      background-color: ${({ theme }) => theme.palette.main.lightGreen};
      border: 1px solid ${({ theme }) => theme.palette.main.greenElectric};
      color: ${({ theme }) => theme.palette.black.main};
    `}

  ${(props) =>
    props.state === StepStates.NOT_STARTED &&
    css`
      background-color: transparent;
      border: 1px solid ${({ theme }) => theme.palette.black[80]};
      color: ${({ theme }) => theme.palette.black[80]};
    `}

  ${(props) =>
    props.state === StepStates.ERROR &&
    css`
      background-color: ${({ theme }) => theme.palette.main.red};
      color: ${({ theme }) => theme.palette.main.white};
    `}
`;

const StepIcon = styled.span<{ state: StepStates }>`
  transition: background-color 250ms ease;
  background-color: #c4c4c4;
  color: ${({ theme }) => theme.palette.main.white};
  border-radius: 50%;
  height: 2.5rem;
  width: 2.5rem;
  line-height: 2.5rem;
  display: inline-flex;
  transform-origin: center;
  align-items: center;
  justify-content: center;

  ${(props) =>
    props.state === StepStates.COMPLETED &&
    css`
      background-color: ${({ theme }) => theme.palette.main.greenElectric};
      color: white;
      animation: ${springDown} 1000ms ease-in-out forwards;
    `}

  ${(props) =>
    props.state === StepStates.ERROR &&
    css`
      color: white;
      background-color: #bb392d;
      animation: ${shake} 500ms ease-in-out forwards;
    `}

  ${(props) =>
    props.state === StepStates.CURRENT &&
    css`
      background-color: ${({ theme }) => theme.palette.main.greenElectric};
      color: white;
      animation: ${springUp} 1000ms ease forwards;
    `}
`;

const Stepper = ({
  stepperData,
  currentStep,
  hideStepLabel,
}: StepProgressProps) => {
  const computedStepperData = useMemo(() => {
    return stepperData.map((step, i) => {
      if (i < currentStep) {
        step.state = StepStates.COMPLETED;
      } else if (i === currentStep) {
        step.state = StepStates.CURRENT;
      } else {
        step.state = StepStates.NOT_STARTED;
      }
      return step;
    });
  }, [currentStep, stepperData]);

  return (
    <div>
      <div
        style={{
          height: '120px',
          paddingTop: '24px',
          background: 'white',
        }}
      >
        <StepProgressBar>
          {computedStepperData.map(function (step, i) {
            return step.hideStep ? (
              <></>
            ) : (
              <ProgressStep step={step.state || StepStates.NOT_STARTED} key={i}>
                {step.state === StepStates.ERROR && (
                  <StepIcon state={step.state}>!</StepIcon>
                )}
                {step.state === StepStates.COMPLETED && (
                  <StepIcon state={step.state}>
                    <ValidIcon />
                  </StepIcon>
                )}
                {(step.state === StepStates.NOT_STARTED ||
                  step.state === StepStates.CURRENT) && (
                  <StepIcon state={step.state}>
                    {step.icon ? step.icon : i + 1}
                  </StepIcon>
                )}

                <StepLabel>
                  {!hideStepLabel && (
                    <>
                      {step.label}
                      <StepLabelSubtitle
                        state={step.state || StepStates.NOT_STARTED}
                      >
                        {step.state}
                      </StepLabelSubtitle>
                    </>
                  )}
                </StepLabel>
              </ProgressStep>
            );
          })}
        </StepProgressBar>
      </div>
      <div>{stepperData[currentStep].content}</div>
    </div>
  );
};

export default Stepper;
