import styled from 'styled-components/macro';

import { ReactComponent as ArrowForwardAllIcon } from './arrow-forward-all.svg';
import { ReactComponent as ArrowForwardIcon } from './arrow-forward.svg';
import { ReactComponent as ArrowRedoIcon } from './arrow-redo.svg';
import { ReactComponent as ArrowRightCurvedIcon } from './arrow-right-curved.svg';
import { ReactComponent as ArrowRightIcon } from './arrow-right.svg';
import { ReactComponent as ArrowTopRightIcon } from './arrow-top-right.svg';
import { ReactComponent as ArrowsDiagonalsBltrIcon } from './arrows-diagonals-bltr.svg';
import { ReactComponent as ArrowsDiagonalsIcon } from './arrows-diagonals.svg';
import { ReactComponent as ArrowsHvIcon } from './arrows-hv.svg';
import { ReactComponent as ChevronRightIcon } from './chevron-right.svg';
import { ReactComponent as ChevronsRightIcon } from './chevrons-right.svg';
import { ReactComponent as CircleArrowRightCurvedIcon } from './circle-arrow-right-curved.svg';
import { ReactComponent as CircleArrowRightIcon } from './circle-arrow-right.svg';
import { ReactComponent as CircleArrowTopRightIcon } from './circle-arrow-top-right.svg';
import { ReactComponent as CircleChevronRightIcon } from './circle-chevron-right.svg';
import { ReactComponent as CircleChevronsRightIcon } from './circle-chevrons-right.svg';
import { ReactComponent as DropdownOutlineIcon } from './dropdown-outline.svg';
import { ReactComponent as DropdownIcon } from './dropdown.svg';
import { ReactComponent as RefreshCwAlertIcon } from './refresh-cw-alert.svg';
import { ReactComponent as RefreshCwIcon } from './refresh-cw.svg';
import { ReactComponent as RotateCwIcon } from './rotate-cw.svg';
import { ReactComponent as UpAndDownIcon } from './up-and-down.svg';

export const ArrowRight = styled(ArrowRightIcon)`
  width: 24px;
  height: 24px;
`;

export const ArrowLeft = styled(ArrowRight)`
  transform: rotate(180deg);
`;

export const ArrowTop = styled(ArrowRight)`
  transform: rotate(90deg);
`;

export const ArrowDown = styled(ArrowRight)`
  transform: rotate(-90deg);
`;

export const ArrowTopRight = styled(ArrowTopRightIcon)`
  width: 24px;
  height: 24px;
`;

export const ArrowBottomLeft = styled(ArrowTopRight)`
  transform: rotate(180deg);
`;

export const ArrowTopLeft = styled(ArrowTopRight)`
  transform: rotate(-90deg);
`;

export const ArrowBottomRight = styled(ArrowTopRight)`
  transform: rotate(90deg);
`;

export const ArrowRightCurved = styled(ArrowRightCurvedIcon)`
  width: 24px;
  height: 24px;
`;

export const ArrowLeftCurved = styled(ArrowRightCurved)`
  transform: scaleY(-1);
`;

export const ArrowsHv = styled(ArrowsHvIcon)`
  width: 24px;
  height: 24px;
`;

export const ArrowsDiagonals = styled(ArrowsDiagonalsIcon)`
  width: 24px;
  height: 24px;
`;

export const ArrowsDiagonalsBltr = styled(ArrowsDiagonalsBltrIcon)`
  width: 24px;
  height: 24px;
`;

export const ArrowsDiagonalsTlBr = styled(ArrowsDiagonalsBltr)`
  transform: rotate(-90deg);
`;

export const ArrowForward = styled(ArrowForwardIcon)`
  width: 24px;
  height: 24px;
`;

export const ArrowReply = styled(ArrowForward)`
  transform: scaleY(-1);
`;

export const ArrowForward2 = styled(ArrowForward)`
  transform: scaleX(-1);
`;

export const ArrowForwardAll = styled(ArrowForwardAllIcon)`
  width: 24px;
  height: 24px;
`;

export const ArrowReplyAll = styled(ArrowForwardAll)`
  transform: scaleY(-1);
`;

export const ArrowRedo = styled(ArrowRedoIcon)`
  width: 24px;
  height: 24px;
`;

export const ArrowUndo = styled(ArrowRedo)`
  transform: scaleY(-1);
`;

export const ArrowRotateCw = styled(RotateCwIcon)`
  width: 24px;
  height: 24px;
`;

export const ArrowRotateCcw = styled(ArrowRotateCw)`
  transform: scaleY(-1);
`;

export const ArrowRefreshCw = styled(RefreshCwIcon)`
  width: 24px;
  height: 24px;
`;

export const ArrowRefreshCcw = styled(ArrowRefreshCw)`
  transform: scaleY(-1);
`;

export const ArrowRefreshCwAlert = styled(RefreshCwAlertIcon)`
  width: 24px;
  height: 24px;
`;

export const ArrowRefreshCcwAlert = styled(ArrowRefreshCwAlert)`
  transform: scaleY(-1);
`;

export const ChevronRight = styled(ChevronRightIcon)`
  width: 24px;
  height: 24px;
`;

export const ChevronLeft = styled(ChevronRight)`
  transform: rotate(180deg);
`;

export const ChevronTop = styled(ChevronRight)`
  transform: rotate(-90deg);
`;

export const ChevronBottom = styled(ChevronRight)`
  transform: rotate(90deg);
`;

export const ChevronsRight = styled(ChevronsRightIcon)`
  width: 24px;
  height: 24px;
`;

export const ChevronsLeft = styled(ChevronsRight)`
  transform: rotate(180deg);
`;

export const ChevronsTop = styled(ChevronsRight)`
  transform: rotate(-90deg);
`;

export const ChevronsBottom = styled(ChevronsRight)`
  transform: rotate(90deg);
`;

export const CircleArrowRight = styled(CircleArrowRightIcon)`
  width: 24px;
  height: 24px;
`;

export const CircleArrowLeft = styled(CircleArrowRight)`
  transform: rotate(180deg);
`;

export const CircleArrowTop = styled(CircleArrowRight)`
  transform: rotate(-90deg);
`;

export const CircleArrowBottom = styled(CircleArrowRight)`
  transform: rotate(90deg);
`;

export const CircleArrowTopRight = styled(CircleArrowTopRightIcon)`
  width: 24px;
  height: 24px;
`;

export const CircleArrowBottomLeft = styled(CircleArrowTopRight)`
  transform: rotate(180deg);
`;

export const CircleArrowTopLeft = styled(CircleArrowTopRight)`
  transform: rotate(-90deg);
`;

export const CircleArrowBottomRight = styled(CircleArrowTopRight)`
  transform: rotate(90deg);
`;

export const CircleArrowRightCurved = styled(CircleArrowRightCurvedIcon)`
  width: 24px;
  height: 24px;
`;

export const CircleArrowLeftCurved = styled(CircleArrowRightCurved)`
  transform: scaleY(-1);
`;

export const CircleChevronRight = styled(CircleChevronRightIcon)`
  width: 24px;
  height: 24px;
`;

export const CircleChevronLeft = styled(CircleChevronRight)`
  transform: rotate(180deg);
`;

export const CircleChevronTop = styled(CircleChevronRight)`
  transform: rotate(-90deg);
`;

export const CircleChevronBottom = styled(CircleChevronRight)`
  transform: rotate(90deg);
`;

export const CircleChevronsRight = styled(CircleChevronsRightIcon)`
  width: 24px;
  height: 24px;
`;

export const CircleChevronsLeft = styled(CircleChevronsRight)`
  transform: rotate(180deg);
`;

export const CircleChevronsTop = styled(CircleChevronsRight)`
  transform: rotate(-90deg);
`;

export const CircleChevronsBottom = styled(CircleChevronsRight)`
  transform: rotate(90deg);
`;

export const UpAndDown = styled(UpAndDownIcon)`
  width: 16px;
  height: 16px;
`;

export const DropdownOutline = styled(DropdownOutlineIcon)`
  width: 16px;
  height: 16px;
`;

export const Dropdown = styled(DropdownIcon)`
  width: 16px;
  height: 16px;
`;
