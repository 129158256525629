import { useTranslation } from 'react-i18next';
import useStartVerificationEmailMutation from 'src/components/Users/hooks/useStartVerificationEmailMutation';
import useFormatErrorApi from 'src/hooks/useFormatErrorApi';
import useToast from 'src/hooks/useToast';
import { Button } from 'src/uikit/Button';
import { H1, P } from 'src/uikit/Typography';
import styled from 'styled-components/macro';

import EmailVerificationImage from './EmailVerificationImage';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 1rem;
`;

const Title = styled(H1)`
  font-size: 18px;
  font-weight: 700;
`;

const Content = styled(P)`
  font-size: 14px;
`;

interface Props {
  userId: string;
}

const EmailVerificationFailed = ({ userId }: Props) => {
  const { t } = useTranslation();

  const { mutateAsync } = useStartVerificationEmailMutation();
  const { formatErrorApi } = useFormatErrorApi();
  const { toastError, toastSuccess } = useToast();

  const onClick = async () => {
    try {
      await mutateAsync({ userId: parseInt(userId, 10) });
      toastSuccess(
        t('components.Auth.EmailVerification.EmailVerificationFailed.success'),
      );
    } catch (err) {
      const message = formatErrorApi(err);
      toastError(message);
    }
  };

  return (
    <Wrapper>
      <EmailVerificationImage type="error" />
      <Title>
        {t('components.Auth.EmailVerification.EmailVerificationFailed.title')}
      </Title>
      <Content>
        {t('components.Auth.EmailVerification.EmailVerificationFailed.content')}
      </Content>
      <Button onClick={onClick}>
        {t('components.Auth.EmailVerification.EmailVerificationFailed.resend')}
        &gt;
      </Button>
    </Wrapper>
  );
};

export default EmailVerificationFailed;
