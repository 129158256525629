import { Col } from 'react-bootstrap';
import ComingSoonCard from 'src/components/Misc/Card/ComingSoonCard';
import { H1 } from 'src/uikit/Typography';
import styled from 'styled-components/macro';

const Title = styled(H1)`
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
`;

const NursingHomeDashboard = () => {
  return (
    <Col>
      <Title>Dashboard</Title>
      <ComingSoonCard />
    </Col>
  );
};

export default NursingHomeDashboard;
