import { useTranslation } from 'react-i18next';
import { ChevronRight } from 'src/assets/arrows';
import { Button, ButtonColors } from 'src/uikit/Button';
import CircleStatus from 'src/uikit/CircleStatus';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr 50px;
  min-height: 400px;
`;

const WrapperBody = styled.div`
  display: grid;
  justify-content: center;
  align-content: end;
`;

const Text = styled.p`
  color: ${({ theme }) => theme.palette.main.greenElectric};
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
`;

const Actions = styled.div`
  align-self: end;
  display: flex;
  justify-content: end;
`;

const FinishButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  svg {
    height: 15px;
  }
`;

interface Props {
  onFinish: () => void;
}

const UserCreateSuccess = ({ onFinish }: Props) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <div style={{marginTop: '-56px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <CircleStatus type="success" />
      </div>
      <WrapperBody>
        <Text>{t('components.Users.UsersList.UserCreateSuccess.success')} </Text>
        <Text>{t('components.Users.UsersList.UserCreateSuccess.notify')} </Text>
      </WrapperBody>
      <Actions>
        <FinishButton background={ButtonColors.SUCCESS} onClick={onFinish}>
          {t('components.Users.UsersList.UserCreateSuccess.finish')}
          <ChevronRight />
        </FinishButton>
      </Actions>
    </Wrapper>
  );
};

export default UserCreateSuccess;
