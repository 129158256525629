import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import EmptyModal from 'src/components/Misc/Modal/EmptyModal';
import { Button, ButtonColors } from 'src/uikit/Button';
import styled from 'styled-components/macro';

interface Props {
  isVisible: boolean;
  handleClose: () => void;
  handleValidation: () => Promise<void>;
  children: ReactNode;
}

const StyledEmptyModal = styled(EmptyModal)`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;


export const StyledButtonsWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  gap: 0.25rem;
  margin-bottom: 1rem;
`;

const ModalSwitchToManual = ({
  isVisible,
  handleClose,
  handleValidation,
  children,
}: Props) => {
  const { t } = useTranslation();

  return (
    <StyledEmptyModal isVisible={isVisible} handleClose={handleClose}>
      <StyledHeader>
      </StyledHeader>
      {children}
      <StyledButtonsWrapper>
        <Button background={ButtonColors.CANCEL} onClick={handleClose}>
          {t('common.action.cancel')}
        </Button>
        <Button background={ButtonColors.DANGER} onClick={handleValidation}>
          {t('common.action.confirm')}
        </Button>
      </StyledButtonsWrapper>
    </StyledEmptyModal>
  );
};

export default ModalSwitchToManual;
