import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PlusIcon } from 'src/assets/plus-badge.svg';
import { ReactComponent as CheckedIcon } from 'src/assets/selected.svg';
import { SubscriptionCreationRequest } from 'src/sdk/subscription';
import { Button } from 'src/uikit/Button';
import FormSelectInput from 'src/uikit/Form/SelectInput';
import FormSwitchInput from 'src/uikit/Form/SwitchInput';
import { fontSecondary } from 'src/uikit/theme/typography';
import { H3, Text } from 'src/uikit/Typography';
import styled, { css } from 'styled-components/macro';

const Wrapper = styled('div')<{ selected: boolean }>`
  position: inherit;
  display: flex;
  flex-direction: column;
  z-index: 1;
  ${(props) =>
    props.selected
      ? css`
          padding: 1rem 1.5rem;
          background: ${({ theme }) => theme.palette.main.lightBlue};
          box-shadow: ${({ theme }) => theme.shadows.sm};
          border-radius: 1rem;
          transition: all 0.5s ease-in-out;
          z-index: 2;
          min-width: 300px;
          max-width: 300px;
          min-height: 550px;
          max-height: 650px;
        `
      : css`
          padding: 0.75rem 1.25rem;
          background: ${({ theme }) => theme.palette.black.strongLightGrey};
          box-shadow: ${({ theme }) => theme.shadows.xs};
          border-radius: 1rem;
          transition: all 0.5s ease-in-out;
          min-width: 275px;
          max-width: 275px;
          min-height: 425px;
          max-height: 550px;
        `}
`;

const Name = styled(H3)<{ selected: boolean }>`
  ${fontSecondary}
  font-style: normal;
  font-weight: 700;
  font-size: ${(props) => (props.selected ? '1rem' : '0.9rem')};
  line-height: ${(props) => (props.selected ? '19px' : '16px')};
  text-align: center;
  color: ${({ theme }) => theme.palette.main.primary};
  margin-bottom: 15px;
  transition: all 0.5s ease-in-out;
`;

const DurationLabel = styled.label`
  margin-top: 0.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  width: 100%;
  text-align: center;
`;

const GreenCheck = styled(CheckedIcon)`
  width: 1rem;
  height: 1rem;
  color: ${({ theme }) => theme.palette.main.greenElectric};
  margin-right: 0.5rem;
`;

const RenewalWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: center;
  margin-bottom: 1rem;
`;

const BadgeWrapper = styled.div`
  margin: 1rem3 0;
  text-align: center;
`;

const PlusBadge = styled(PlusIcon)`
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.main.primary};
  height: 1.5rem;
  width: 1.5rem;
`;

const FeaturesWrapper = styled('div')<{ selected: boolean }>`
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.selected ? '0.9rem' : '0.6rem')};
  ${fontSecondary}
  font-style: normal;
  font-weight: 400;
  ${(props) =>
    props.selected
      ? css`
          font-size: 0.9ren;
          line-height: 1rem;
        `
      : css`
          font-size: 1rem;
          line-height: 14px;
        `}
  transition: all 0.5s ease-in-out;
  margin-block: 1rem;
`;

const Feature = styled.div`
  display: flex;
`;

const StyledButton = styled(Button)<{ spacing: boolean }>`
  ${({ spacing }) =>
    spacing &&
    css`
      margin-top: 5rem;
    `};
  display: inline-flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  width: 100%;
`;

const DisabledSpace = styled.div`
  height: 2rem;
`;

export interface PlanProps {
  type: SubscriptionCreationRequest['type'];
  name: string;
  durations: Array<{ value: number; label: string }>;
  showRenewal: boolean;
  features: Array<string>;
  featuresAll: boolean;
  disabled?: boolean;
}

interface Props extends PlanProps {
  checked: boolean;
  onChecked: () => void;
}

const SubscriptionPlan = ({
  type,
  name,
  durations,
  showRenewal,
  features,
  featuresAll,
  disabled,
  checked,
  onChecked,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Wrapper selected={checked}>
      <Name selected={checked}>{name}</Name>
      {disabled && <DisabledSpace />}
      {durations.length <= 1 && !disabled && (
        <>
          <DurationLabel>{durations[0].label}</DurationLabel>
        </>
      )}
      <Row className="p-2">
        {!(disabled || durations.length <= 1) && (
          <FormSelectInput
            name={`duration`}
            placeholder={
              checked ? t('components.subscriptionPlan.chooseDuration') : ''
            }
            options={durations}
            disabled={!checked}
          />
        )}
      </Row>
      {showRenewal && (
        <RenewalWrapper>
          <FormSwitchInput name="automaticRenewal" disabled={!checked} />
          <Text>{t('components.subscriptionPlan.automaticRenewal')}</Text>
        </RenewalWrapper>
      )}
      <FeaturesWrapper selected={checked}>
        {featuresAll && (
          <>
            <Feature>
              <GreenCheck />
              <Text>{t('components.subscriptionPlan.allPrevious')}</Text>
            </Feature>

            <BadgeWrapper>
              <PlusBadge />
            </BadgeWrapper>
          </>
        )}
        {features.map((feature, index) => (
          <Feature key={index}>
            <GreenCheck />
            <Text>{feature}</Text>
          </Feature>
        ))}
      </FeaturesWrapper>
      <StyledButton
        onClick={onChecked}
        disabled={disabled}
        spacing={disabled && type !== 'DEMO' ? 1 : 0}
      >
        {disabled && type !== 'DEMO'
          ? t('components.subscriptionPlan.comingSoon')
          : t('components.subscriptionPlan.buttonChoose')}{' '}
        {checked && <CheckedIcon />}
      </StyledButton>
    </Wrapper>
  );
};

export default SubscriptionPlan;
