import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useResetPasswordMutation from 'src/components/Auth/hooks/useResetPasswordMutation';
import ResetPasswordExpired from 'src/components/Auth/ResetPassword/ResetPasswordExpired';
import ResetPasswordForm from 'src/components/Auth/ResetPassword/ResetPasswordForm';
import ResetPasswordSuccess from 'src/components/Auth/ResetPassword/ResetPasswordSuccess';

const ResetPassword = () => {
  const [success, setSuccess] = useState(false);
  const { mutateAsync } = useResetPasswordMutation();

  const location = useLocation();
  const { userId, token } = useMemo(() => {
    const searchParam = new URLSearchParams(location.search);
    return {
      userId: searchParam.get('user-id') || '-1',
      token: searchParam.get('token') || '',
    };
  }, [location.search]);

  const [expiredToken = false, setExpiredToken] = useState<boolean>();

  useEffect(() => {
    if (userId && token !== '') {
      mutateAsync({
        body: {
          userId: Number(userId),
          token,
          value: 'ougjrUe4684gr*', // a valid password
        },
        params: { simulation: true },
      }).catch((err) => {
        setExpiredToken(true);
      });
    }
  }, [mutateAsync, token, userId]);

  return (
    <>
      {expiredToken ? (
        <ResetPasswordExpired />
      ) : !success ? (
        <ResetPasswordForm
          userId={userId}
          token={token}
          onReset={() => setSuccess(true)}
        />
      ) : (
        <ResetPasswordSuccess />
      )}
    </>
  );
};

export default ResetPassword;
