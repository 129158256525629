import { format } from 'date-fns';
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { ChevronRight } from 'src/assets/arrows';
import { Warning } from 'src/assets/list-icons';
import { ReactComponent as Order } from 'src/assets/order-badge.svg';
import OrderingDetailsList from 'src/components/Ordering/List/OrderingDetailsList';
import { DATE_FORMAT_DAY } from 'src/constants';
import { Orders, OrderStatus } from 'src/sdk/order';
import { Card, CardBody } from 'src/uikit/Card';
import { List } from 'src/uikit/Layout/List';
import { Text } from 'src/uikit/Typography';
import styled, { css } from 'styled-components/macro';

const OrderBadge = styled(Order)`
  height: 40px;
`;

const ArrowButton = styled.button`
  display: flex;
  background: none;
  border: none;
  transition-duration: 0.5s;

  &.rotate {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    transition-duration: 0.5s;
  }
`;

const ArrowIcon = styled(ChevronRight)`
  color: ${({ theme }) => theme.palette.main.primary};

  &.rotate {
    color: ${({ theme }) => theme.palette.main.red};
  }
`;

const StyledCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BadgeCol = styled(StyledCol)`
  justify-content: flex-start;
  padding-left: 1.5rem !important; // "important" is needed to overwrite the  property
`;

const ArrowStyledCol = styled(StyledCol)`
  justify-content: flex-end;
`;

const DisplayCol = styled(Col)<{ $isVisible?: boolean }>`
  min-height: 0;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  transition: min-height 200ms ease-in-out, opacity 200ms ease-in-out;
  ${({ $isVisible }) =>
    $isVisible &&
    css`
      min-height: 200px;
      max-height: 100%;
      opacity: 1;
      visibility: visible;
    `};
`;

const NumberText = styled(Text)`
  font-weight: normal;
  margin: 0;
  color: ${({ theme }) => theme.palette.black[90]};
  word-break: break-word;
  text-align: center;
`;

const StyledCard = styled(Card)``;

interface Props {
  orders?: Orders[];
}

const OrderingHistoryCard = ({ orders }: Props) => {
  const [orderId, setOrderId] = useState<number>();

  const onSelected = (id: number) => {
    setOrderId(id !== orderId ? id : undefined);
  };

  return (
    <List className="mt-4">
      {orders?.map((order) => (
        <StyledCard
          key={order.id}
          onClick={() => onSelected(order.id)}
          $clickable
          shadow="xs"
        >
          <CardBody>
            <Row>
              <BadgeCol md="1">
                <OrderBadge />
              </BadgeCol>
              <StyledCol md="2">
                <NumberText>
                  {format(new Date(order.sapCreationDate), DATE_FORMAT_DAY)}
                </NumberText>
              </StyledCol>
              <StyledCol md="2">
                <NumberText>{order.poNumber}</NumberText>
              </StyledCol>
              <StyledCol md="2">
                <NumberText>{order.items?.length}</NumberText>
              </StyledCol>
              <StyledCol md="2">
                <NumberText>{order.vendor?.name}</NumberText>
              </StyledCol>
              <ArrowStyledCol md="2">
                {order.status !== OrderStatus.DONE && <Warning />}
                <ArrowButton className={order.id === orderId ? 'rotate' : ''}>
                  <ArrowIcon className={order.id === orderId ? 'rotate' : ''} />
                </ArrowButton>
              </ArrowStyledCol>
              <DisplayCol
                md="12"
                $isVisible={order.id === orderId}
                onClick={(e: React.MouseEvent<SVGSVGElement, MouseEvent>) =>
                  e.stopPropagation()
                }
              >
                <OrderingDetailsList ordersList={order.items} />
              </DisplayCol>
            </Row>
          </CardBody>
        </StyledCard>
      ))}
    </List>
  );
};

export default OrderingHistoryCard;
