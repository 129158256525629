import { useMutation, useQueryClient } from 'react-query';
import request from 'src/axios';
import { OrizonError } from 'src/sdk/common';
import { ManeulModeRequest } from 'src/sdk/nursingHome';


const useManualMode = (id: number) => {
  const queryClient = useQueryClient();

  const manualMutation = useMutation<
    OrizonError,
    ManeulModeRequest
  >(() => request.put(`/nursing-homes/${id}/product-association-mode`, { productAssociationMode: 'MANUAL' }), {
    onSuccess: () => {
      queryClient.invalidateQueries(`nursing-home-${id}`);
    },
  });

  return { manualMutation };
};

export default useManualMode;