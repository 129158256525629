import { useCallback, useMemo, useState } from 'react';
import sortByField from 'src/utils/sortByField';

export default function useSortedList<Item>(items?: Item[]) {
  const [sortField, setSortField] = useState<keyof Item | undefined>();
  const [sortDirection, setSortDirection] = useState(true);

  const sortedItems = useMemo(() => {
    if (!sortField) return items;
    return sortByField(items || [], sortField, sortDirection);
  }, [items, sortDirection, sortField]);

  const handleSortFieldChange = useCallback(
    (field: keyof Item) => {
      if (field === sortField) {
        setSortDirection((direction) => !direction);
      } else {
        setSortField(field);
        setSortDirection(true);
      }
    },
    [sortField],
  );

  return {
    sortedItems,
    sortField,
    sortDirection,
    handleSortFieldChange,
  };
}
