import { useQuery } from 'react-query';
import request from 'src/axios';
import { BillingConfigurationResponse } from 'src/sdk/billing';

type QueryParams = {
  'nursing-home-id': number;
  year?: number;
  month?: number;
};

const fetchProtocolConfigurationEffective = async (params: QueryParams) => {
  const { data } = await request.get(`/billing-configurations/effective`, {
    params,
  });

  return data;
};

const useFetchProtocolConfigurationsEffectiveQuery = (params: QueryParams) => {
  const { data, isLoading, refetch, error } = useQuery<
    BillingConfigurationResponse,
    Error
  >(['billing-configuration-effective', { ...params }], () =>
    fetchProtocolConfigurationEffective(params),
  );

  return {
    data,
    isLoading,
    refetch,
    error,
  };
};

export default useFetchProtocolConfigurationsEffectiveQuery;
