import { useState, useMemo } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useFetchCurrentPartnerQuery from 'src/components/Partners/hooks/useFetchCurrentPartnerQuery';
import useFormatErrorApi from 'src/hooks/useFormatErrorApi';
import useToast from 'src/hooks/useToast';
import { Profiles } from 'src/sdk/authentification';
import Stepper from 'src/uikit/Stepper';
import { H4 } from 'src/uikit/Typography';
import styled from 'styled-components/macro';

import { useAuth } from '../../../contexts/Auth';
import useCreateUserMutation from '../hooks/useCreateUserMutation';
import UsersModal from '../UsersModal';
import UserCreateIcon from './components/UserCreateIcon';
import UserCreateSuccess from './components/UserCreateSuccess';
import UserCreateTypeSelect, { UserCreateType } from './UserCreateTypeSelect';
import UserOntexCreateForm, {
  UserOntexCreateFormSchema,
} from './UserOntexCreateForm';
import UserPartnerAdminCreateForm, {
  UserPartnerAdminCreateFormSchema,
} from './UserPartnerAdminCreateForm';
import UserPartnerCreateForm, {
  UserPartnerCreateFormSchema,
} from './UserPartnerCreateForm';

const Wrapper = styled(Container)``;

interface Props {
  show: boolean;
  onFinish: () => void;
}

const UserCreateModal = ({ show, onFinish }: Props) => {
  const { t } = useTranslation();

  const { authUser } = useAuth();
  const [currentStep = 0, setCurrentStep] = useState<number>();
  const [type, setType] = useState<UserCreateType>();

  const { toastError } = useToast();
  const { formatErrorApi } = useFormatErrorApi();

  const { data: partnerData } = useFetchCurrentPartnerQuery(
    { expand: [] },
    {
      enabled: authUser?.partnerUser,
    },
  );

  const { createUserMutation } = useCreateUserMutation();

  const handleClose = () => {
    onFinish();
  };

  const handleSuccessSelectType = (type: UserCreateType) => {
    setType(type);
    setCurrentStep(currentStep + 1);
  };

  const getErrors = (errors: any) => {
    const message = formatErrorApi(errors);
    toastError(message);
  };

  const onSubmitPartnerAdminUser = async (
    data: UserPartnerAdminCreateFormSchema,
  ) => {
    try {
      await createUserMutation.mutateAsync({
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        password: data.password,
        profile: Profiles.PARTNER_ADMIN,
        gender: data.gender,
        language: data.language,
        partnerId: data.partner,
      });
      setCurrentStep(currentStep + 1);
    } catch (err) {
      getErrors(err);
    }
  };
  const onSubmitOntexUser = async (data: UserOntexCreateFormSchema) => {
    try {
      await createUserMutation.mutateAsync({
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        password: data.password,
        profile: data.profile,
        gender: data.gender,
        language: data.language,
      });
      setCurrentStep(currentStep + 1);
    } catch (err) {
      getErrors(err);
    }
  };

  const onSubmitPartnerUser = async (data: UserPartnerCreateFormSchema) => {
    try {
      await createUserMutation.mutateAsync({
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        password: data.password,
        profile: data.profile,
        gender: data.gender,
        language: data.language,
        partnerId: partnerData?.id,
      });
      setCurrentStep(currentStep + 1);
    } catch (err) {
      getErrors(err);
    }
  };

  const handleBackForm = () => {
    setCurrentStep(currentStep - 1);
    setType(undefined);
  };

  const title = useMemo(() => {
    return t(`components.Users.UsersList.UserCreateModal.title${type || ''}`);
  }, [t, type]);

  return (
    <UsersModal
      title={title}
      show={show}
      handleClose={handleClose}
      icon={<UserCreateIcon type={type} />}
    >
      <Stepper
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        hideStepLabel
        stepperData={[
          ...(authUser?.profile === Profiles.ORIZON_ADMIN
            ? [
                {
                  name: 'select',
                  label: t(
                    `components.Users.UsersList.UserCreateModal.steps.selectLabel`,
                  ),
                  content: (
                    <Wrapper>
                      <H4>
                        {t(
                          `components.Users.UsersList.UserCreateModal.steps.select`,
                        )}
                      </H4>
                      <UserCreateTypeSelect
                        onSuccess={handleSuccessSelectType}
                      />
                    </Wrapper>
                  ),
                },
              ]
            : []),
          {
            name: 'form',
            label: t(
              `components.Users.UsersList.UserCreateModal.steps.formLabel`,
            ),
            content: (
              <Wrapper>
                <H4 className="mb-4">
                  {t(`components.Users.UsersList.UserCreateModal.steps.form`)}
                </H4>
                {authUser?.profile === Profiles.ORIZON_ADMIN && (
                  <>
                    {type === 'ontex' && (
                      <UserOntexCreateForm
                        onBack={handleBackForm}
                        onCancel={handleClose}
                        onSubmit={onSubmitOntexUser}
                      />
                    )}
                    {type === 'partner' && (
                      <UserPartnerAdminCreateForm
                        onBack={handleBackForm}
                        onCancel={handleClose}
                        onSubmit={onSubmitPartnerAdminUser}
                      />
                    )}
                  </>
                )}

                {authUser?.profile === Profiles.PARTNER_ADMIN && (
                  <UserPartnerCreateForm
                    onBack={handleClose}
                    onCancel={handleClose}
                    onSubmit={onSubmitPartnerUser}
                  />
                )}
              </Wrapper>
            ),
          },
          {
            name: 'success',
            label: t(
              `components.Users.UsersList.UserCreateModal.steps.successLabel`,
            ),
            content: (
              <Wrapper>
                <UserCreateSuccess onFinish={onFinish} />
              </Wrapper>
            ),
          },
        ]}
      />
    </UsersModal>
  );
};

export default UserCreateModal;
