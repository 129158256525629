import { useTranslation } from 'react-i18next';
import SortingButton from 'src/components/SortingHeaders/SortingButton';
import { Clipons } from 'src/sdk/nursingHome';
import styled from 'styled-components/macro';

const StyledRow = styled.div`
  display: flex;
  width: 100%;
  margin: 24px 0px;
`;
const StyledCol = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 33%;
`;

interface Props {
  onSortingChange: (field: keyof Clipons) => void;
}

const SortingFirmwareDetailHeader = ({ onSortingChange }: Props) => {
  const { t } = useTranslation();

  return (
    <StyledRow>
      <StyledCol>
        <SortingButton
          primary
          label={t('components.sortingHeader.sortByName')}
          field="name"
          onClick={() => onSortingChange('name')}
        />
      </StyledCol>
      <StyledCol>
        <SortingButton
          primary
          label={t('components.sortingHeader.sortByVersion')}
          field="concatVersion"
          onClick={() => onSortingChange('concatVersion')}
        />
      </StyledCol>
      <StyledCol>
        <SortingButton
          primary
          label={t('components.sortingHeader.sortByNumber')}
          field="serialNumber"
          onClick={() => onSortingChange('serialNumber')}
        />
      </StyledCol>
    </StyledRow>
  );
};

export default SortingFirmwareDetailHeader;
