import { Dropdown, DropdownProps } from 'react-bootstrap';
import styled, { css } from 'styled-components/macro';

export const MenuItem = styled(Dropdown.Item)`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  padding-inline: 0.75rem;
  font-size: 0.9rem;
`;

export const MenuItems = styled(Dropdown.Menu)`
  margin: 0.25rem;
  border: 1px solid ${({ theme }) => theme.palette.black.lightGrey};
  box-shadow: ${({ theme }) => theme.shadows.md};
`;

export const MenuToggle = styled(Dropdown.Toggle)``;

export const Menu = styled(Dropdown)<{ toogleIcon?: boolean } & DropdownProps>`
  &.show > ${MenuToggle} {
    background: none !important;
  }

  ${({ toogleIcon }) =>
    toogleIcon &&
    css`
      ${MenuToggle} {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 38px;
        height: 38px;
        padding: 0.5rem;
        margin: 0;
        background: none;
        border: none;

        :not(:disabled):not(.disabled):active {
          background: none !important;
        }

        :after {
          display: none;
        }
      }
    `}
`;
