export enum Equipment {
  NURSINGHOME = 'nursing-home',
  CONFIGURED = 'configured',
  LACKING = 'lacking',
}

export enum EquipmentTypes {
  GATEWAY = 'GATEWAY',
  CLIPON = 'CLIPON',
  CHARGING = 'CHARGING_STATION',
}

export enum Status {
  NO_STATUS = 'NO_STATUS',
  IN_STOCK = 'IN_STOCK',
  IN_USE = 'IN_USE',
  BROKEN = 'BROKEN',
  LOST = 'LOST',
  READYTOSEND = 'TO_SEND',
  ORDERED = 'ORDERED',
  IN_TRANSIT = 'IN_TRANSIT',
  SENT = 'SENT',
}

export enum RegistrationStatus {
  UNKNOWN = 'UNKNOWN',
  PENDING = 'PENDING',
  FAILED = 'FAILED',
  REGISTERED = 'REGISTERED',
  ACTIVE = 'ACTIVE',
}

export interface DeleteEquipmentResponse {
  created: boolean;
}

export interface UpdateEquipmentBody {
  serialNumber: string;
  manufacturer: string;
  model: string;
  equipmentStatus: string;
  description: string;
}
