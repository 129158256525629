import { useCallback, useMemo, useState } from 'react';
import { Carousel, Col, Row } from 'react-bootstrap';
import { ChevronLeft, ChevronRight } from 'src/assets/arrows';
import { Department } from 'src/assets/list-icons';
import { DeliveryAddress } from 'src/sdk/nursingHome';
import { Strong } from 'src/uikit/Typography';
import styled from 'styled-components/macro';

import GeneralCard from './GeneralCard';

const DepartmentIcon = styled(Department)`
  width: 92px;
  height: 92px;
`;

const PreviousIcon = styled(ChevronLeft)`
  color: ${({ theme }) => theme.palette.main.primary};
`;

const NextIcon = styled(ChevronRight)`
  color: ${({ theme }) => theme.palette.main.primary};
`;

const ColumnIcon = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledCarousel = styled(Carousel)`
  height: 100%;
  margin-top: 1rem;
  > .carousel-control-prev,
  > .carousel-control-next {
    width: 10%;
  }

  > .carousel-control-prev {
    opacity: 1;
    background: linear-gradient(0.25turn, white, rgba(0, 0, 0, 0));
  }

  > .carousel-control-next {
    opacity: 1;
    background: linear-gradient(0.25turn, rgba(0, 0, 0, 0), white);
  }
`;

const Pagination = styled.div`
  margin-top: auto;
  width: 100%;
  text-align: center;
`;

const AddressCol = styled(Col)`
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  padding-inline: 1rem;
  margin-block: 0.2rem;
`;

const AddressWrapper = styled(Col)`
  display: flex;
  flex-direction: column;
`;

interface Props {
  addresses?: Array<DeliveryAddress>;
}

const DeliveryAddressCard = ({ addresses }: Props) => {
  const count = useMemo(() => (addresses ? addresses.length : 0), [addresses]);
  const [currentDeliveryAddress = count ? 1 : 0, setCurrentDeliveryAdress] =
    useState<number>();

  const handleSelect = useCallback((selectedIndex: number, event: any) => {
    setCurrentDeliveryAdress(selectedIndex + 1);
  }, []);

  return (
    <GeneralCard title="Delivery addresses">
      <ColumnIcon md="3" xl="2">
        <DepartmentIcon />
      </ColumnIcon>
      <AddressWrapper md="9" xl="10">
        {addresses && (
          <>
            <StyledCarousel
              indicators={false}
              interval={null}
              nextIcon={<NextIcon />}
              prevIcon={<PreviousIcon />}
              onSelect={handleSelect}
              controls={count > 1}
            >
              {addresses.map((delivery, id) => (
                <Carousel.Item key={id}>
                  <Row className="w-75 mx-auto">
                    <Col md="12" className="text-center">
                      {delivery.shipTo && <>{delivery.shipTo} - </>}
                      <Strong>{delivery.name}</Strong>
                    </Col>
                    <AddressCol md="12">{delivery.street}</AddressCol>
                    <AddressCol md="12">
                      {delivery.zip} {delivery.town}
                    </AddressCol>
                    <AddressCol md="12">{delivery.country}</AddressCol>
                  </Row>
                </Carousel.Item>
              ))}
            </StyledCarousel>
          </>
        )}
        <Pagination>
          {currentDeliveryAddress} / {count}
        </Pagination>
      </AddressWrapper>
    </GeneralCard>
  );
};

export default DeliveryAddressCard;
