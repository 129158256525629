import { AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import request from 'src/axios';

const useDeletePartnerProspectAddressMutation = () => {
  const queryClient = useQueryClient();

  const deletePartnerProspectAddressMutation = useMutation<
    AxiosResponse,
    Error,
    {
      partnerProspectId: number;
      id: number;
    }
  >(
    ({ partnerProspectId, id }) =>
      request.delete(
        `/partner-prospects/${partnerProspectId}/delivery-addresses/${id}`,
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
      },
    },
  );

  return { deletePartnerProspectAddressMutation };
};

export default useDeletePartnerProspectAddressMutation;
