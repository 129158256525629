import { AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import request from 'src/axios';
import { UpdateUserStatusRequest } from 'src/sdk/users';

const useUpdateUserStatusMutation = () => {
  const queryClient = useQueryClient();

  const updateUserStatusMutation = useMutation<
    AxiosResponse<any>,
    Error,
    UpdateUserStatusRequest
  >(
    (body) => {
      return request.post(`/users/update-account-status`, body);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
      },
    },
  );

  return { updateUserStatusMutation };
};

export default useUpdateUserStatusMutation;
