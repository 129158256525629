import { useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useFetchUsersQuery from 'src/components/Users/hooks/useFetchUsersQuery';
import UsersSimpleList from 'src/components/Users/UsersSimpleList';
import UserSimpleCard from 'src/components/Users/UsersSimpleList/UserSimpleCard';
import useToast from 'src/hooks/useToast';
import { Profiles } from 'src/sdk/authentification';
import { PartnerProspect } from 'src/sdk/partner-prospect';
import { List } from 'src/uikit/Layout/List';
import SearchInput from 'src/uikit/SearchInput';
import styled from 'styled-components/macro';

import useCreatePartnerProspectSpecialistMutation from '../../hooks/useCreatePartnerProspectSpecialistMutation';
import useDeletePartnerProspectSpecialistMutation from '../../hooks/useDeletePartnerProspectSpecialistMutation';
import PartnerProspectHeader from '../PartnerProspectHeader';

const PAGE_SIZE = 5000;

interface Props {
  partnerProspect: PartnerProspect;
}

const Wrapper = styled.div`
  display: grid;
  gap: 0.5rem;
`;

const PartnerProspectSpecialist = ({ partnerProspect }: Props) => {
  const { t } = useTranslation();
  const { toastSuccess, toastError } = useToast();

  const { mutateAsync: mutateAsyncCreate } =
    useCreatePartnerProspectSpecialistMutation();
  const { mutateAsync: mutateAsyncDelete } =
    useDeletePartnerProspectSpecialistMutation();

  const [searchFilter, setSearchFilter] = useState<string>();
  const handleChangeSearch = (value: string) => {
    setSearchFilter(value);
  };

  const handleClickAddSpecialist = async (userId: number) => {
    try {
      await mutateAsyncCreate({
        id: partnerProspect.id,
        userId,
      });
      toastSuccess(
        t(
          'components.PartnerProspects.PartnerProspectDetails.PartnerProspectSpecialist.successAdd',
        ),
      );
    } catch (err) {
      toastError(
        t(
          'components.PartnerProspects.PartnerProspectDetails.PartnerProspectSpecialist.errorAdd',
        ),
      );
    }
  };

  const handleClickDeleteSpecialist = async (userId: number) => {
    try {
      await mutateAsyncDelete({
        id: partnerProspect.id,
        userId,
      });
      toastSuccess(
        t(
          'components.PartnerProspects.PartnerProspectDetails.PartnerProspectSpecialist.successDelete',
        ),
      );
    } catch (err) {
      toastError(
        t(
          'components.PartnerProspects.PartnerProspectDetails.PartnerProspectSpecialist.errorDelete',
        ),
      );
    }
  };

  const { data } = useFetchUsersQuery({
    page: 0,
    size: PAGE_SIZE,
    expand: ['avatar-image-path', 'partner-prospects'],
    sortField: {
      label: 'employeeId',
      direction: 'asc',
    },
    search: searchFilter,
    profilesFilter: Profiles.PRODUCT_SPECIALIST,
  });

  const usersOwned = useMemo(() => {
    return (
      data?.content.filter((productSpecialist) =>
        productSpecialist?.partnerProspects?.find(
          (_partnerProspect) => _partnerProspect.id === partnerProspect.id,
        ),
      ) || []
    );
  }, [data?.content, partnerProspect.id]);

  const usersAvailable = useMemo(() => {
    return (
      data?.content.filter((productSpecialist) => {
        return (
          !productSpecialist?.partnerProspects ||
          !productSpecialist?.partnerProspects?.find(
            (_partnerProspect) => _partnerProspect.id === partnerProspect.id,
          )
        );
      }) || []
    );
  }, [data?.content, partnerProspect.id]);

  return (
    <Wrapper>
      <PartnerProspectHeader title={partnerProspect.name} />
      <Row>
        <Col md="3">
          <SearchInput onChange={handleChangeSearch} />
        </Col>
      </Row>
      <UsersSimpleList
        title={t(
          'components.PartnerProspects.PartnerProspectDetails.PartnerProspectSpecialistOwnedList.title',
        )}
      >
        <List columnSize="300px">
          {usersOwned.map((productSpecialist) => (
            <UserSimpleCard
              key={productSpecialist.id}
              user={productSpecialist}
              type="delete"
              variant="hover"
              onClick={() => handleClickDeleteSpecialist(productSpecialist.id)}
            />
          ))}
        </List>
      </UsersSimpleList>

      <UsersSimpleList
        title={t(
          'components.PartnerProspects.PartnerProspectDetails.PartnerProspectSpecialistAvailableList.title',
        )}
      >
        <List columnSize="300px">
          {usersAvailable.map((productSpecialist) => (
            <UserSimpleCard
              key={productSpecialist.id}
              user={productSpecialist}
              type="add"
              variant="hover"
              onClick={() => handleClickAddSpecialist(productSpecialist.id)}
            />
          ))}
        </List>
      </UsersSimpleList>
    </Wrapper>
  );
};

export default PartnerProspectSpecialist;
