import { format } from 'date-fns';
import { useCallback, useMemo, useState } from 'react';
import { Col, Row, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ListPaginated from 'src/components/Misc/ListPaginated';
import ModalConfirmation from 'src/components/Misc/Modal/ModalConfirmation';
import useDeleteProductNursingHomeMutation from 'src/components/Products/hooks/useDeleteProductNursingHomeMutation';
import useFetchProductsQuery from 'src/components/Products/hooks/useFetchProductsQuery';
import ProductFilterAsyncSelect from 'src/components/Products/ProductFilterAsyncSelect';
import ProductCard from 'src/components/Products/ProductsList/ProductCard';
import useFormatErrorApi from 'src/hooks/useFormatErrorApi';
import useToast from 'src/hooks/useToast';
import { NursingHomeParameter } from 'src/sdk/nursingHome';
import { NursingHomeResponse } from 'src/sdk/nursingHome';
import { Badge } from 'src/uikit/Badge';
import { Button } from 'src/uikit/Button';
import { StyledHelper } from 'src/uikit/Form';
import { List } from 'src/uikit/Layout/List';
import { H1, H3, Label, Text } from 'src/uikit/Typography';
import { PageWrapper } from 'src/uikit/Wrapper';
import styled from 'styled-components/macro';

import { usePartner } from '../../../../contexts/Partner';
import useAddProductNursingHomeMutation from '../../../Products/hooks/useAddProductNursingHomeMutation';
import useRefreshList from '../hooks/useRefreshList';
import ModalSwitchToManual from 'src/components/Misc/Modal/ModalSwitchToManual';

import './nursingHomeCongigurationProducts.css'
import { useAuth } from 'src/contexts/Auth';
import useManualMode from '../hooks/useManualMode';

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  margin-top: 10px;
`;

export const StyledLastUpdate = styled.div`
  font-size: 0.9rem;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  color: ${({ theme }) => theme.palette.main.curiousBlue};
`;

export const StyledDate = styled.div`
  font-family: 'Roboto';
  font-size: 15px;
  font-weight: 400;
  text-align: center;
  color: ${({ theme }) => theme.palette.black[90]};
`;

export const ErrorMessage = styled.p`
  font-weight: 600;
  text-align: center;
  color: ${({ theme }) => theme.palette.main.red};
  font-size: 10px;
  padding: 0 4rem;
`;

type Props = {
  nursingHome: NursingHomeResponse;
};

const PAGE_SIZE = 100;

const NursingHomeConfigurationProducts = ({ nursingHome }: Props) => {
  const { t } = useTranslation();
  const { authUser } = useAuth();
  const { partner } = usePartner();

  const [brandFilter, setBrandFilter] = useState<string | null>();
  const [colorFilter, setColorFilter] = useState<string | null>();
  const [dropFilter, setDropFilter] = useState<string | null>();
  const [sizeFilter, setSizeFilter] = useState<string | null>();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [showSwitchModal, setShowSwitchModal] = useState<boolean>(false);
  const { refreshListMutation } = useRefreshList(nursingHome.id);
  const { manualMutation } = useManualMode(nursingHome.id);
  const { toastError, toastSuccess } = useToast();
  const { formatErrorApi } = useFormatErrorApi();

  const [page, setPage] = useState(0);

  const isB2B = useMemo(
    () => nursingHome.productAssociationMode === 'B2B',
    [nursingHome.productAssociationMode],
  );

  const { data: dataNursingHome, isLoading } = useFetchProductsQuery({
    page: 0,
    size: 5000,
    ...(brandFilter && { brand: brandFilter }),
    ...(colorFilter && { color: colorFilter }),
    ...(dropFilter && { drops: dropFilter }),
    ...(sizeFilter && { productSize: sizeFilter }),
    nursingHomeId: nursingHome.id,
    ...(isB2B && {
      page,
      size: PAGE_SIZE,
    }),
  });
  

  const { mutateAsync: addMutateAsync } = useAddProductNursingHomeMutation();
  const { mutateAsync: delMutateAsync } = useDeleteProductNursingHomeMutation();

  const { data: dataAll, isLoading: isLoadingAll } = useFetchProductsQuery(
    {
      page,
      size: PAGE_SIZE,
      ...(brandFilter && { brand: brandFilter }),
      ...(colorFilter && { color: colorFilter }),
      ...(dropFilter && { drops: dropFilter }),
      ...(sizeFilter && { productSize: sizeFilter }),
      ...(partner && { partnerId: partner.id }),
    },
    {
      enabled: nursingHome.productAssociationMode !== 'B2B',
    },
  );

  const onClickAddProduct = async (productId: number) => {
    try {
      await addMutateAsync({ id: nursingHome.id, productId });
      toastSuccess(t('nursingHome.configuration.products.successAdd'));
    } catch (err) {
      const message = formatErrorApi(err);
      toastError(message);
    }
  };

  const onClickDeleteProduct = async (productId: number) => {
    try {
      await delMutateAsync({ id: nursingHome.id, productId });
      toastSuccess(t('nursingHome.configuration.products.successDelete'));
    } catch (err) {
      const message = formatErrorApi(err);
      toastError(message);
    }
  };

  const productAssociationDate = nursingHome.parameters.find(
    (param) => param.name === NursingHomeParameter.ProductAssociationDate,
  )?.value as string;

  const formatDate = productAssociationDate
    ? format(new Date(productAssociationDate), 'dd/MM/yyyy')
    : t('common.unknown');

  const refreshList = useCallback(
    async (): Promise<void> =>
      refreshListMutation
        .mutateAsync()
        .then(() => {
          setShowModal(false);
          toastSuccess(t('nursingHome.refreshList.success'));
        })
        .catch(() => toastError(t('common.errorHasOccured'))),
    [refreshListMutation, t, toastSuccess, toastError],
  );

  const manualProccess = useCallback(
    async (): Promise<void> =>
      manualMutation.mutateAsync()
        .then(() => {
          setShowModal(false);
          toastSuccess(t('nursingHome.manualMode.success'));
          setShowSwitchModal(false);
        })
        .catch(() => toastError(t('common.errorHasOccured'))),
    [manualMutation, t, toastSuccess, toastError],
  );
  
  const allProducts = useMemo(() => {
    return (
      dataAll?.content.map((product) => {
        const isAdded =
          dataNursingHome?.content.findIndex(
            (_product) => product.id === _product.id,
          ) !== -1;

        return {
          ...product,
          isAdded,
        };
      }) || []
    );
  }, [dataAll, dataNursingHome]);
  
  return (
    <PageWrapper>
      <ModalConfirmation
        isVisible={showModal}
        handleClose={() => setShowModal(false)}
        handleValidation={refreshList}
      >
        <Text>{t('nursingHome.refreshList.confirmationText1')}</Text>
        <Text>{t('nursingHome.refreshList.confirmationText2')}</Text>
      </ModalConfirmation>

      <ModalSwitchToManual
        isVisible={showSwitchModal}
        handleClose={() => setShowSwitchModal(false)}
        handleValidation={manualProccess}
      >
        <div className='d-flex justify-content-center align-items-center flex-column'>
          <Text className='mt-3 mx-2 text-center'>{t('nursingHome.manualMode.switchModalText1')}</Text>
          <Text className='mt-3 textColorRouge'>{t('nursingHome.manualMode.switchModalText2')}</Text>
          <Text className='mt-3'>{t('nursingHome.manualMode.switchModalText3')}</Text>
        </div>
      </ModalSwitchToManual>

      {nursingHome.productAssociationMode === 'B2B' ? (
        <HeaderWrapper>
          <H1 className="mb-4">
            {t('nursingHome.configuration.products.title')}
          </H1>
          <H3>{t('nursingHome.configuration.products.subtitle')}</H3>
          <StyledHelper>
            {t('nursingHome.configuration.products.information')}
          </StyledHelper>
          <StyledButtonWrapper>
            <StyledLastUpdate>
              {t('nursingHome.configuration.products.lastUpdate')}
            </StyledLastUpdate>
            <StyledDate>{formatDate}</StyledDate>
            <Col className='d-flex gap-2'>
              <Button onClick={() => setShowModal(true)}>
                {t('nursingHome.configuration.products.buttonLabel')}
              </Button>
              {
                authUser && authUser.profile === 'ORIZON_ADMINISTRATOR' ? (
                  <Button onClick={() => setShowSwitchModal(true)}>
                    {t('nursingHome.configuration.products.switchButtonLabel')}
                  </Button>
                ):
                null
              } 
            </Col>
          </StyledButtonWrapper>
        </HeaderWrapper>
      ) : (
        <></>
      )}

      <ContentWrapper>
        <Stack direction="horizontal" gap={2}>
          <H3>{t('nursingHome.configuration.products.label')}</H3>
          <Badge bg="primary" className="ml-2">
            {dataAll?.totalElements}
          </Badge>
        </Stack>

        <Row className="mt-2">
          <Col xs="12" sm="6" md="3" xl="auto">
            <ProductFilterAsyncSelect
              type="brand"
              value={brandFilter}
              onChange={(value) => setBrandFilter(value)}
              placeholder={t('nursingHome.configuration.products.filter.brand')}
              b2b={isB2B}
              nursingHomeId={nursingHome.id}
              color={colorFilter || null}
              drops={dropFilter || null}
              productSize={sizeFilter || null}
            />
          </Col>
          <Col xs="12" sm="6" md="3" xl="auto">
            <ProductFilterAsyncSelect
              type="color"
              value={colorFilter}
              onChange={(value) => setColorFilter(value)}
              placeholder={t('nursingHome.configuration.products.filter.color')}
              b2b={isB2B}
              nursingHomeId={nursingHome.id}
              drops={dropFilter || null}
              productSize={sizeFilter || null}
              brand={brandFilter || null}
            />
          </Col>
          <Col xs="12" sm="6" md="3" xl="auto">
            <ProductFilterAsyncSelect
              type="size"
              value={sizeFilter}
              onChange={(value) => setSizeFilter(value)}
              placeholder={t('nursingHome.configuration.products.filter.size')}
              b2b={isB2B}
              nursingHomeId={nursingHome.id}
              color={colorFilter || null}
              drops={dropFilter || null}
              brand={brandFilter || null}
            />
          </Col>
          <Col xs="12" sm="6" md="3" xl="auto">
            <ProductFilterAsyncSelect
              type="drop"
              value={dropFilter}
              onChange={(value) => setDropFilter(value)}
              placeholder={t('nursingHome.configuration.products.filter.drop')}
              b2b={isB2B}
              nursingHomeId={nursingHome.id}
              color={colorFilter || null}
              productSize={sizeFilter || null}
              brand={brandFilter || null}
            />
          </Col>
        </Row>

        {isB2B ? (
          <ListPaginated
            page={page}
            setPage={setPage}
            isLoading={isLoading}
            data={dataNursingHome}
          >
            <List columnSize="200px">
              {dataNursingHome?.content.map((product) => (
                <ProductCard key={product.id} product={product} />
              ))}
            </List>
          </ListPaginated>
        ) : (
          <>
            <Label className="mt-3">
              {t('nursingHome.configuration.products.assigned')}
            </Label>
            <List columnSize="200px">
              {dataNursingHome?.content.map((product) => (
                <ProductCard
                  variant="hover"
                  key={product.id}
                  product={product}
                  type="delete"
                  onClick={() => {
                    onClickDeleteProduct(product.id || 0);
                  }}
                />
              ))}
            </List>

            <Label className="mt-3">
              {t('nursingHome.configuration.products.available')}
            </Label>
            <ListPaginated
              page={page}
              setPage={setPage}
              isLoading={isLoadingAll}
              data={dataAll}
            >
              <List columnSize="200px">
                {allProducts.map((product) => (
                  <ProductCard
                    variant="hover"
                    key={product.id}
                    product={product}
                    type="add"
                    disabled={product.isAdded}
                    onClick={() => {
                      onClickAddProduct(product.id || 0);
                    }}
                  />
                ))}
              </List>
            </ListPaginated>
          </>
        )}
      </ContentWrapper>
    </PageWrapper>
  );
};

export default NursingHomeConfigurationProducts;
