import { AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import request from 'src/axios';

const useCreatePartnerProspectSpecialistMutation = () => {
  const queryClient = useQueryClient();

  const createPartnerProspectSpecialistMutation = useMutation<
    AxiosResponse<any>,
    Error,
    {
      id: number;
      userId: number;
    }
  >(
    ({ id, userId }) => {
      return request.post(`/partner-prospects/${id}/users/${userId}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
      },
    },
  );

  return { ...createPartnerProspectSpecialistMutation };
};

export default useCreatePartnerProspectSpecialistMutation;
