import { ReactNode } from 'react';
import { ModalBody } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import { CloseCross } from 'src/assets/list-icons';
import DefaultModal from 'src/components/Misc/Modal/DefaultModal';
import { H2 } from 'src/uikit/Typography';
import styled from 'styled-components/macro';

const Header = styled(ModalHeader)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  width: 100%;
  border-bottom: none;
`;

const Title = styled(H2)``;

const CloseButton = styled.button`
  background: none;
  padding: 0.5rem;
  box-shadow: unset;
  border: none;
  border-radius: 50%;

  :hover:not(:disabled) {
    transform: scale(1.1);
  }
`;

const Body = styled(ModalBody)`
  padding-top: 0;
`;

interface Props {
  title: string;
  children: ReactNode;
  show: boolean;
  icon: ReactNode;
  handleClose: () => void;
}

const UsersModal = ({ title, children, show, handleClose, icon }: Props) => {
  return (
    <DefaultModal
      size="lg"
      isVisible={show}
      handleClose={handleClose}
      hasStep={false}
      customClose
      header={
        <Header>
          {icon}
          <Title>{title}</Title>
          <CloseButton onClick={handleClose}>
            <CloseCross />
          </CloseButton>
        </Header>
      }
    >
      <Body>{children}</Body>
    </DefaultModal>
  );
};

export default UsersModal;
