import { useMemo } from 'react';
import { Row, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useStartPasswordResetMutation from 'src/components/Auth/hooks/useStartPasswordResetMutation';
import { useModal } from 'src/hooks/useModal';
import useToast from 'src/hooks/useToast';
import { UserResponse } from 'src/sdk/users';
import { Button, ButtonColors } from 'src/uikit/Button';
import { H4 } from 'src/uikit/Typography';
import styled from 'styled-components/macro';

import UserConfirmationModal from './UserConfirmationModal';

const Wrapper = styled(Row)`
  display: flex;
`;

const HeaderRow = styled(Row)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled(H4)``;

const Subtitle = styled.p`
  color: ${({ theme }) => theme.palette.black[70]};
`;

const Message = styled.p`
  color: ${({ theme }) => theme.palette.main.red};
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
`;

interface Props {
  user: UserResponse;
}

const UserResetPassword = ({ user }: Props) => {
  const { t } = useTranslation();
  const { toastSuccess, toastError } = useToast();

  const { modalOpen, setModalOpen } = useModal();

  const fullName = useMemo(() => `${user.firstName} ${user.lastName}`, [user]);

  const { startPasswordResetMutation } = useStartPasswordResetMutation();

  const handleReset = async () => {
    try {
      await startPasswordResetMutation({ username: user.email });
      toastSuccess(t('components.Users.UserDetails.UserResetPassword.success'));
      setModalOpen(false);
    } catch (err) {
      toastError(t('components.Users.UserDetails.UserResetPassword.error'));
    }
  };

  return (
    <Wrapper className="h-100">
      <HeaderRow>
        <Title>
          {t('components.Users.UserDetails.UserResetPassword.title')}
        </Title>
      </HeaderRow>
      <Row>
        <Subtitle>
          {t('components.Users.UserDetails.UserResetPassword.subtitle')}
        </Subtitle>
      </Row>
      <Stack
        direction="horizontal"
        gap={2}
        className="w-100 justify-content-center mt-auto"
      >
        <Button onClick={() => setModalOpen(true)}>
          {t('components.Users.UserDetails.UserResetPassword.button')}
        </Button>
      </Stack>
      <UserConfirmationModal
        isVisible={modalOpen}
        onClose={() => setModalOpen(false)}
      >
        <Row className="justify-content-center mb-2">
          <Message>
            {t('components.Users.UserDetails.UserResetPassword.message', {
              fullName,
            })}
          </Message>
        </Row>
        <Stack direction="horizontal" gap={2} className="justify-content-end">
          <Button
            background={ButtonColors.CANCEL}
            onClick={() => setModalOpen(false)}
          >
            {t('components.Users.UserDetails.UserResetPassword.cancel')}
          </Button>
          <Button className="ml-2" onClick={handleReset}>
            {t('components.Users.UserDetails.UserResetPassword.confirm')}
          </Button>
        </Stack>
      </UserConfirmationModal>
    </Wrapper>
  );
};

export default UserResetPassword;
