import { Col, Row, Stack } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useDeleteUserMutation from 'src/components/Users/hooks/useDeleteUserMutation';
import useFormatErrorApi from 'src/hooks/useFormatErrorApi';
import useToast from 'src/hooks/useToast';
import Routes from 'src/router/Routes';
import { UserResponse } from 'src/sdk/users';
import { Button, ButtonColors } from 'src/uikit/Button';
import styled from 'styled-components/macro';

import UserConfirmationModal from '../UserConfirmationModal';

const Message = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
`;

const Warning = styled.span`
  color: ${({ theme }) => theme.palette.main.red};
`;

const UserWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.main.primary};
  border-radius: 10px;
  display: flex;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem 0.5rem;
`;

const Avatar = styled.img`
  width: 42px;
  aspect-ratio: 1/1;
`;

interface Props {
  user: UserResponse;
  avatar: string;
  modalOpen: boolean;
  setModalOpen: (value: boolean) => void;
}

const UserDeleteModal = ({ user, avatar, modalOpen, setModalOpen }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { toastError, toastSuccess } = useToast();
  const { deleteUserMutation } = useDeleteUserMutation();
  const { formatErrorApi } = useFormatErrorApi();

  const handleClick = async () => {
    try {
      await deleteUserMutation.mutateAsync({ id: user.id });
      toastSuccess(t('components.Users.UserDetails.UserResume.successDelete'));
      history.push(Routes.USERS);
    } catch (err) {
      const message = formatErrorApi(err);
      toastError(message);
    }
  };

  return (
    <UserConfirmationModal
      isVisible={modalOpen}
      onClose={() => setModalOpen(false)}
    >
      <Row className="justify-content-center">
        <Message>
          <Trans
            i18nKey="components.Users.UserDetails.UserDeleteModal.message"
            components={{
              span: <Warning />,
            }}
          />
        </Message>
      </Row>
      <Row className="justify-content-center mb-3" xs="auto">
        <Col>
          <UserWrapper>
            <Avatar src={avatar} />
            {`${user.firstName} ${user.lastName}`}
          </UserWrapper>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Message>
          <Trans
            i18nKey="components.Users.UserDetails.UserDeleteModal.warning"
            components={{
              span: <Warning />,
            }}
          />
        </Message>
      </Row>
      <Stack direction="horizontal" gap={2} className="justify-content-end">
        <Button
          background={ButtonColors.CANCEL}
          onClick={() => setModalOpen(false)}
        >
          {t('components.Users.UserDetails.UserDeleteModal.cancel')}
        </Button>
        <Button
          background={ButtonColors.DANGER}
          className="ml-2"
          onClick={handleClick}
        >
          {t('components.Users.UserDetails.UserDeleteModal.confirm')}
        </Button>
      </Stack>
    </UserConfirmationModal>
  );
};

export default UserDeleteModal;
