import styled from 'styled-components/macro';

const AuthForm = styled.form`
  width: 400px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
`;

export default AuthForm;
