import { useTranslation } from 'react-i18next';
import { OrizonError } from 'src/sdk/common';

const useFormatErrorApi = () => {
  const { t, i18n } = useTranslation();

  const formatErrorApi = (err: any) => {
    const error = err as OrizonError;

    if (error.status === 403) {
      return t(`errorsApi.unauthorized`);
    }

    if ('code' in error) {
      const key = `errorsApi.${error.code}`;
      return i18n.exists(key)
        ? t(`errorsApi.${error.code}`)
        : t(`errorsApi.default`);
    }
    return '';
  };

  return { formatErrorApi };
};

export default useFormatErrorApi;
