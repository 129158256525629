import { Col, FormControlProps } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import ErrorMessage from 'src/uikit/ErrorMessage';
import TextInput, { TextInputProps } from 'src/uikit/TextInput';
import getNestedProperty from 'src/utils/getNestedProperty';
import styled from 'styled-components/macro';

const StyledCol = styled(Col)`
  padding: 0;
`;

export type FormTextInputProps = {
  name: string;
  withoutErrorMessage?: boolean;
} & TextInputProps &
  FormControlProps;

const FormTextInput = ({
  name,
  defaultValue,
  withoutErrorMessage = false,
  ...rest
}: FormTextInputProps) => {
  const { control, errors } = useFormContext();
  const error = getNestedProperty(errors, name);
  const errorMessage = error?.message;
  const showErrorMode = !!error;
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ value, onChange, ref }) => (
        <StyledCol>
          <TextInput
            ref={ref}
            value={value}
            onChange={onChange}
            aria-invalid={errorMessage}
            variant="form"
            {...rest}
          />
          {showErrorMode && !withoutErrorMessage && (
            <ErrorMessage>{errorMessage}</ErrorMessage>
          )}
        </StyledCol>
      )}
    />
  );
};

export default FormTextInput;
