import { useMemo } from 'react';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';
import useFetchOrderSpartPartsCreationDataQuery from 'src/components/NursingHome/Equipment/hooks/useFetchOrderSpartPartsCreationDataQuery';
import NHEquipmentOrderForm, {
  NHEquipmentOrderFormSchema,
} from 'src/components/NursingHome/Equipment/Order/NHEquipmentOrderForm';
import useFetchNursingHomeQuery from 'src/components/NursingHome/hooks/useFetchNursingHomeQuery';
import { useAuth } from 'src/contexts/Auth';
import { usePartner } from 'src/contexts/Partner';
import useFormatErrorApi from 'src/hooks/useFormatErrorApi';
import useToast from 'src/hooks/useToast';
import Routes from 'src/router/Routes';
import { Card } from 'src/uikit/Card';
import { H1 } from 'src/uikit/Typography';
import { PageWrapper } from 'src/uikit/Wrapper';
import { getStartingDate } from 'src/utils/getDate';

import useOrderSparePartsMutation from '../components/NursingHome/Equipment/hooks/useOrderSparePartsMutation';

const EquipmentOrderNew = () => {
  const { t } = useTranslation();
  const { nhSession } = useAuth();

  const { formatErrorApi } = useFormatErrorApi();
  const { toastError, toastSuccess } = useToast();
  const history = useHistory();

  const { partner } = usePartner();

  const { orderSparePartsMutation } = useOrderSparePartsMutation();

  const { data: dataLimit } = useFetchOrderSpartPartsCreationDataQuery({
    'nursing-home-id': nhSession?.id || -1,
  });

  const { data } = useFetchNursingHomeQuery({
    id: Number(nhSession?.id),
    expand: 'all',
  });

  const defaultValues: NHEquipmentOrderFormSchema = useMemo(
    () => ({
      customAddress: {
        country: '',
        email: '',
        name: '',
        street: '',
        telephone: '',
        town: '',
        zip: '',
      },
      comment: '',
      deliveryAddressId: -1,
      chargingStationCount: 0,
      gatewayCount: 0,
      cliponCount: 0,
      requestedDeliveryDate:
        dataLimit?.minRequestedDeliveryDate || getStartingDate(),
    }),
    [dataLimit?.minRequestedDeliveryDate],
  );

  const onSubmit = async (values: NHEquipmentOrderFormSchema) => {
    try {
      await orderSparePartsMutation.mutateAsync({
        nursingHomeId: Number(nhSession?.id),
        comment: values.comment,
        cliponCount: values.cliponCount || 0,
        gatewayCount: values.gatewayCount || 0,
        chargingStationCount: values.chargingStationCount || 0,
        requestedDeliveryDate: values.requestedDeliveryDate,
        ...(!values.customAddress
          ? { deliveryAddressId: values.deliveryAddressId }
          : { customAddress: values.customAddress }),
      });
      toastSuccess(t('pages.equipementOrderNew.success'));

      history.push(
        generatePath(Routes.NURSING_EQUIPMENT_ORDERING, {
          id: nhSession?.id.toString() || '-1',
        }),
      );
    } catch (err) {
      const message = formatErrorApi(err);
      toastError(message);
    }
  };

  const deliveryAddresses = useMemo(() => {
    return [
      ...(data?.deliveryAddresses || []),
      ...(partner?.deliveryAddresses?.map((deliveryAddress) => ({
        ...deliveryAddress,
        isPartner: true,
      })) || []),
    ];
  }, [data?.deliveryAddresses, partner?.deliveryAddresses]);

  return (
    <PageWrapper>
      {dataLimit && (
        <Card className="p-4">
          <Row className="pb-4 mb-4 justify-content-center text-center">
            <H1 className="w-75">{t('pages.equipementOrderNew.title')}</H1>
          </Row>
          <NHEquipmentOrderForm
            defaultValues={defaultValues}
            onSubmit={onSubmit}
            deliveryAddresses={deliveryAddresses}
            equipmentsCount={{
              cliponCount: data?.cliponMetrics?.totalCount || 0,
              gatewayCount: data?.gatewayMetrics?.totalCount || 0,
              chargingStationCount:
                data?.chargingStationMetrics?.totalCount || 0,
            }}
            limit={dataLimit}
          />
        </Card>
      )}
    </PageWrapper>
  );
};

export default EquipmentOrderNew;
