import { AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import request from 'src/axios';

const useDeleteUserMutation = () => {
  const queryClient = useQueryClient();

  const deleteUserMutation = useMutation<
    AxiosResponse<any>,
    Error,
    { id: number }
  >(({ id }) => request.delete(`/users/${id}`), {
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
  });
  return { deleteUserMutation };
};

export default useDeleteUserMutation;
