import { AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import request from 'src/axios';
import {
  CreateNursingHome,
  NursingHomeCreationType,
  NursingHomeResponse,
} from 'src/sdk/nursingHome';

export const URLS: { [keyof in NursingHomeCreationType]: string } = {
  PartnerProspect: 'import-from-partner-prospect',
  Prospect: 'import-from-prospect',
} as const;

const useCreateNursingHomeMutation = (
  type: NursingHomeCreationType,
  simulation?: boolean,
) => {
  const queryClient = useQueryClient();

  const createNursingMutation = useMutation<
    AxiosResponse<NursingHomeResponse>,
    Error,
    CreateNursingHome
  >(
    (newNH) => {
      let data = { ...newNH };
      if (type == 'PartnerProspect') {
        data.legalEntity = undefined;
      }
      return request.post(`/nursing-homes/${URLS[type]}`, data, {
        params: {
          simulation,
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
      },
    },
  );

  return { createNursingMutation };
};

export default useCreateNursingHomeMutation;
