import { useQuery } from 'react-query';
import request from 'src/axios';
import { LegalEntity } from 'src/sdk/nursingHome';

const useLegalEntityOptions = () => {
  return useQuery<[{ label: string; value: string }], Error>(
    'legal-entities',
    () => {
      return request.get('/legal-entities').then((res) =>
        res.data.content
          .sort((a: LegalEntity, b: LegalEntity) => a.country.localeCompare(b.country))
          .map((e: LegalEntity) => {
            return {
              label: `[${e.country}] ${e.name}`,
              value: e.name,
            };
          }),
      );
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );
};

export default useLegalEntityOptions;
