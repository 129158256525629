import { useCallback, useState } from 'react';
import { Col, Row, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Checked } from 'src/assets/avatar-checked.svg';
import { ReactComponent as BlockIcon } from 'src/assets/block.svg';
import { ReactComponent as DeleteIcon } from 'src/assets/delete.svg';
import { ReactComponent as PartnersIcon } from 'src/assets/partners.svg';
import useUpdateUserStatusMutation from 'src/components/Users/hooks/useUpdateUserStatusMutation';
import UserStatusBadge from 'src/components/Users/UserStatusBadge';
import { useAuth } from 'src/contexts/Auth';
import useFormatErrorApi from 'src/hooks/useFormatErrorApi';
import { useModal } from 'src/hooks/useModal';
import useToast from 'src/hooks/useToast';
import { UserResponse, UserStatus } from 'src/sdk/users';
import { Badge, BadgeStatus } from 'src/uikit/Badge';
import { ButtonColors } from 'src/uikit/Button';
import ButtonIcon from 'src/uikit/ButtonIcon';
import { H4, H5 } from 'src/uikit/Typography';
import styled from 'styled-components/macro';

import { usePartner } from '../../../../../contexts/Partner';
import UserAvatar from './UserAvatar';
import UserBlockModal from './UserBlockModal';
import UserDeleteModal from './UserDeleteModal';

const WrapperIcon = styled.div`
  color: ${({ theme }) => theme.palette.main.primary};
`;
const Wrapper = styled(Row)`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const ContentRow = styled(Row)`
  gap: 1rem;
`;

const InfoCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 1rem;
  padding-inline: 0;
  min-width: 300px;
`;

const UserInfoCol = styled(Col)`
  display: flex;
  flex-direction: column;
  padding-inline: 0;
  gap: 0.4rem;
`;

const Profile = styled(H4)`
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.main.primary};
`;

const Partner = styled(H4)`
  color: ${({ theme }) => theme.palette.main.primary};
`;

const Name = styled(H4)`
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.black[90]};
`;

const Email = styled(H5)`
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.black[60]};
`;

const EmployeeId = styled.p`
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
`;

interface Props {
  data?: UserResponse;
}

const UserResume = ({ data: user }: Props) => {
  const { t } = useTranslation();
  const [avatar, setAvatar] = useState('');
  const { partner } = usePartner();

  const { authUser } = useAuth();
  const { toastError, toastSuccess } = useToast();
  const { formatErrorApi } = useFormatErrorApi();

  const { updateUserStatusMutation } = useUpdateUserStatusMutation();

  const { modalOpen: deleteModalOpen, setModalOpen: setDeleteModalOpen } =
    useModal();
  const { modalOpen: blockModalOpen, setModalOpen: setBlockModalOpen } =
    useModal();

  const handleValid = useCallback(async () => {
    if (!user?.id) {
      return;
    }

    try {
      await updateUserStatusMutation.mutateAsync({
        userId: user.id,
        accountStatus: UserStatus.Active,
      });
      toastSuccess(t('components.Users.UserDetails.UserResume.successValid'));
    } catch (err) {
      const message = formatErrorApi(err);
      toastError(message);
    }
  }, [
    formatErrorApi,
    t,
    toastError,
    toastSuccess,
    updateUserStatusMutation,
    user?.id,
  ]);

  const handleBlock = useCallback(() => {
    setBlockModalOpen(true);
  }, [setBlockModalOpen]);

  const handleDelete = useCallback(() => {
    setDeleteModalOpen(true);
  }, [setDeleteModalOpen]);

  return (
    <Wrapper xs="auto">
      <ContentRow xs="auto">
        {user && (
          <UserAvatar user={user} avatar={avatar} setAvatar={setAvatar} />
        )}
        <InfoCol>
          <UserInfoCol>
            {user?.partner && (
              <Stack direction="horizontal" gap={2}>
                <WrapperIcon>
                  <PartnersIcon />
                </WrapperIcon>
                <Partner>{user.partner.name}</Partner>
              </Stack>
            )}

            <Profile>
              {t(
                `components.Users.UserDetails.UserResume.profiles.${user?.profile}`,
              )}
            </Profile>
            <Name>
              {user?.firstName} {user?.lastName}
            </Name>
            <Email>{user?.email}</Email>
            {user?.employeeId && (
              <EmployeeId>
                {t(`components.Users.UserDetails.UserResume.employeeID`, {
                  employeeId: user?.employeeId,
                })}
              </EmployeeId>
            )}
          </UserInfoCol>
          <Stack direction="horizontal" gap={2}>
            <UserStatusBadge status={user?.accountStatus} />
            {!partner?.id && (
              <Badge
                color={
                  user?.emailVerified
                    ? BadgeStatus.greenElectric
                    : BadgeStatus.grey
                }
              >
                {t(
                  `components.Users.UserDetails.UserResume.${
                    user?.emailVerified ? 'emailVerified' : 'emailNotVerified'
                  }`,
                )}
              </Badge>
            )}
          </Stack>
        </InfoCol>
      </ContentRow>
      <Stack direction="horizontal" className="mt-3 align-items-start" gap={2}>
        {user?.accountStatus !== UserStatus.Active ? (
          <ButtonIcon
            append={<Checked />}
            label={t('components.Users.UserDetails.UserResume.validButton')}
            onClick={handleValid}
          />
        ) : (
          <ButtonIcon
            append={<BlockIcon />}
            label={t('components.Users.UserDetails.UserResume.blockButton')}
            background={ButtonColors.DANGER}
            onClick={handleBlock}
          />
        )}
        {authUser?.profile === 'ORIZON_ADMINISTRATOR' && (
          <ButtonIcon
            append={<DeleteIcon />}
            label={t('components.Users.UserDetails.UserResume.deleteButton')}
            background={ButtonColors.DANGER}
            disabled={authUser?.id === user?.id}
            onClick={handleDelete}
          />
        )}
      </Stack>
      {user && (
        <>
          {deleteModalOpen && (
            <UserDeleteModal
              user={user}
              avatar={avatar}
              modalOpen={deleteModalOpen}
              setModalOpen={setDeleteModalOpen}
            />
          )}
          {blockModalOpen && (
            <UserBlockModal
              user={user}
              avatar={avatar}
              modalOpen={blockModalOpen}
              setModalOpen={setBlockModalOpen}
            />
          )}
        </>
      )}
    </Wrapper>
  );
};

export default UserResume;
