import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useFormatErrorApi from 'src/hooks/useFormatErrorApi';
import useToast from 'src/hooks/useToast';

import useCreatePartnerMutation from '../hooks/useCreatePartnerMutation';
import PartnerModal from '../PartnerModal';
import PartnerCheckForm from './PartnerCheckForm';
import PartnerCreateForm, {
  PartnerCreateFormSchema,
  partnerCreateFormSchema,
} from './PartnerCreateForm';

interface Props {
  show: boolean;
  onFinish: () => void;
}

const PartnerCreateModal = ({ show, onFinish }: Props) => {
  const { t } = useTranslation();
  const { toastSuccess } = useToast();
  const { formatErrorApi } = useFormatErrorApi();
  const [errorCreation, setErrorCreation] = useState('');
  const [partner, setPartner] = useState<any>();
  const { createPartnerMutation } = useCreatePartnerMutation();
  const methods = useForm<PartnerCreateFormSchema>({
    resolver: yupResolver(partnerCreateFormSchema),
  });
  const { setValue } = methods;
  useEffect(() => {
    setValue('name', partner?.name);
    setValue('language', partner?.language);
    setValue('legalEntity', partner?.legalEntity);
    setValue('addressName', partner?.address?.name);
    setValue('addressStreet', partner?.address?.street);
    setValue('addressZip', partner?.address?.zip);
    setValue('addressTown', partner?.address?.town);
    setValue('addressCountry', partner?.address?.country);
    setValue('addressEmail', partner?.address?.email);
    setValue('addressTelephone', partner?.address?.telephone);
  }, [partner]);


  /**
   * Submits the form values to create a partner.
   *
   * @param {PartnerCreateFormSchema} values - The form values.
   * @return {Promise<void>} A Promise that resolves when the partner is created.
   */
  const onSubmit = async (values: PartnerCreateFormSchema) => {
    try {
      await createPartnerMutation.mutateAsync({
        soldTo: partner.soldTo,
        salesOrg: partner.salesOrg,
        name: values.name,
        language: values.language,
        legalEntity: values.legalEntity,
      });
      toastSuccess(t('components.PartnerCreateModal.success'));
      handleClose();
    } catch (err: any) {
      const message = formatErrorApi(err);
      setErrorCreation(message);
      setTimeout(() => {
        setErrorCreation('');
      }, 3000);
    }
  };


  const handleClose = useCallback(() => {
    onFinish?.();
  }, [onFinish]);


  const onCancel = () => {
    handleClose();
  };


  return (
    <PartnerModal
      title={t('components.PartnerCreateModal.title')}
      show={show}
      handleClose={handleClose}
    >
      <PartnerCheckForm
        callback={(partner) => {
          setPartner(partner);
        }}
        onCancel={onCancel}
      />
      {partner && (
        <FormProvider {...methods}>
          <PartnerCreateForm
            onSubmit={onSubmit}
            onCancel={onCancel}
            error={errorCreation}
            partner={partner}
          />
        </FormProvider>
      )}
    </PartnerModal>
  );
};

export default PartnerCreateModal;
