import { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { ReactNode } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as ActiveItem } from 'src/assets/active-menu-item.svg';
import { ChevronBottom } from 'src/assets/arrows';
import palette from 'src/uikit/theme/palette';
import { SidebarLink } from 'src/uikit/Typography';
import styled, { css } from 'styled-components/macro';

const Wrapper = styled.li<{ $opened: boolean }>`
  min-width: 42px;
  padding: ${({ $opened }) => ($opened ? '0 1rem 1rem 0' : '0 0 1rem 0')};
  list-style: none;
  align-items: center;
  justify-content: flex-start;
  transition: all 0.3s ease-in-out;
  position: relative;

  &:hover {
    cursor: pointer;
  }
`;

interface ActiveItemIconProps {
  $isActive?: boolean;
}

const ActiveItemIcon = styled(ActiveItem)<ActiveItemIconProps>`
  position: absolute;
  top: -4px;
  left: -0.5rem;
  height: 32px;
  visibility: ${({ $isActive }) => ($isActive ? 'block' : 'hidden')};
  opacity: ${({ $isActive }) => ($isActive ? '1' : '0')};
  transition: opacity 100ms ease-in;
`;

const Menu = styled.div<{ $opened: boolean }>`
  display: flex;
  width: 100%;
  align-items: center;
  padding-left: ${({ $opened }) => ($opened ? '0.5rem' : '0.25rem')};
  justify-content: space-between;

  &:hover {
    cursor: pointer;
  }

  :hover > ${ActiveItemIcon} {
    display: block;
  }
  :hover ${SidebarLink} {
    text-decoration: none;
    cursor: pointer;
    color: ${({ theme }) => theme.palette.main.primary};
  }
  :active > ${ActiveItemIcon} {
    display: block;
  }
`;

const ChildrenRow = styled.ul<{ $opened: boolean }>`
  display: flex;
  border-left: 1px solid ${({ theme }) => theme.palette.black[80]};
  margin-left: 1rem;
  padding-left: 1rem;
  margin-bottom: 0;
  flex-direction: column;
  gap: 0.75rem;

  max-height: 0;
  opacity: 0;
  visibility: hidden;
  transition: max-height 200ms ease-in-out, opacity 200ms ease-in-out,
    padding-top 200ms ease-in;
  ${({ $opened }) =>
    $opened &&
    css`
      padding-top: 0.5rem;
      max-height: 350px;
      opacity: 1;
      visibility: visible;
    `};
`;

const SubmenuButton = styled.button`
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 0.5rem;

  height: 24px;
  width: 24px;

  transition-duration: 0.5s;
  transform: rotate(180deg);

  &.rotate {
    transform: rotate(0deg);
    transition-duration: 0.5s;
  }
`;

const LinkLabel = styled.span<{ $opened?: boolean }>`
  margin-left: 0;
  margin-bottom: 0;

  display: flex;
  overflow: hidden;
  white-space: nowrap;
  width: ${({ $opened }) => ($opened ? 'auto' : '0')};

  text-overflow: clip;
  transition: all 0s ease-in-out;

  &:hover {
    cursor: pointer;
  }
`;

interface Props {
  label: string;
  path?: string;
  icon?: any;
  opened: boolean;
  children?: ReactNode;
  exact?: boolean;
}

const SidebarItem = ({ label, path, icon, opened, children, exact }: Props) => {
  const location = useLocation();
  const history = useHistory();

  const enabled = useMemo(
    () =>
      !!path &&
      (exact ? location.pathname === path : location.pathname.includes(path)),
    [exact, location.pathname, path],
  );

  const [expanded = enabled, setExpanded] = useState<boolean>();
  useEffect(() => setExpanded(enabled), [enabled]);

  const handleClick = useCallback(() => {
    path && history.push(path);
  }, [history, path]);

  const handleChevronClick = (e: MouseEvent) => {
    e.stopPropagation();
    setExpanded(!expanded);
  };

  return (
    <Wrapper $opened={opened}>
      <ActiveItemIcon $isActive={enabled} />
      <Menu $opened={opened} onClick={handleClick}>
        <SidebarLink
          activeStyle={{
            color: palette.main.primary,
          }}
          to={path || '#'}
          exact={exact}
        >
          {icon}
          <LinkLabel $opened={opened}>{label}</LinkLabel>
        </SidebarLink>
        {children && opened && (
          <SubmenuButton
            className={expanded ? '' : 'rotate'}
            onClick={handleChevronClick}
          >
            <ChevronBottom />
          </SubmenuButton>
        )}
      </Menu>
      {children && (
        <ChildrenRow $opened={opened && expanded}>{children}</ChildrenRow>
      )}
    </Wrapper>
  );
};

export default SidebarItem;
