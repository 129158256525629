import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Routes from 'src/router/Routes';
import HorizontalMenu, { HorizontalMenuItems } from 'src/uikit/HorizontalMenu';

const PartnerConfigurationHorizontalMenu = () => {
  const { t } = useTranslation();

  const MenuItem: HorizontalMenuItems[] = useMemo(() => {
    return [
      {
        routes: Routes.PARTNER_CONFIG_GENERAL,
        label: t('configuration.generalSettings'),
      },
      {
        routes: Routes.PARTNER_CONFIG_ADDRESSES,
        label: t('configuration.addresses'),
      },
      {
        routes: Routes.PARTNER_CONFIG_PRODUCTS,
        label: t('configuration.products'),
      },
    ];
  }, [t]);

  return <HorizontalMenu menuItems={MenuItem} />;
};

export default PartnerConfigurationHorizontalMenu;
