import { useQuery } from 'react-query';
import request from 'src/axios';
import { GetBillingConfigurationResponse } from 'src/sdk/billing';

type QueryParams = {
  'nursing-home-id': number;
  page?: number;
  size?: number;
  sort?: string[];
};

const fetchBillingConfiguration = async (params: QueryParams) => {
  const { data } = await request.get(`/billing-configurations`, {
    params,
  });

  return data;
};

const useFetchBillingConfigurationsQuery = (params: QueryParams) => {
  const { data, isLoading, refetch, error } = useQuery<
    GetBillingConfigurationResponse,
    Error
  >(['billing-configurations', { ...params }], () =>
    fetchBillingConfiguration(params),
  );

  return {
    data,
    isLoading,
    refetch,
    error,
  };
};

export default useFetchBillingConfigurationsQuery;
