import { useState } from 'react';
import { Row, Col, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ListPaginated from 'src/components/Misc/ListPaginated';
import useFetchProductsQuery from 'src/components/Products/hooks/useFetchProductsQuery';
import ProductFilterAsyncSelect from 'src/components/Products/ProductFilterAsyncSelect';
import ProductCard from 'src/components/Products/ProductsList/ProductCard';
import { Partner } from 'src/sdk/partner';
import { Badge } from 'src/uikit/Badge';
import { List } from 'src/uikit/Layout/List';
import { H3, H1, Subtext, Strong } from 'src/uikit/Typography';

const PAGE_SIZE = 100;

type Props = {
  partner: Partner;
};

const PartnerConfigurationProducts = ({ partner }: Props) => {
  const { t } = useTranslation();

  const [page, setPage] = useState(0);

  const [brandFilter, setBrandFilter] = useState<string | null>();
  const [colorFilter, setColorFilter] = useState<string | null>();
  const [dropFilter, setDropFilter] = useState<string | null>();
  const [sizeFilter, setSizeFilter] = useState<string | null>();

  const { data: dataPartner, isLoading: isLoadingPartner } =
    useFetchProductsQuery({
      page: page,
      size: PAGE_SIZE,
      ...(brandFilter && { brand: brandFilter }),
      ...(colorFilter && { color: colorFilter }),
      ...(dropFilter && { drops: dropFilter }),
      ...(sizeFilter && { productSize: sizeFilter }),
      partnerId: partner.id,
    });

  return (
    <>
      <div className="text-center">
        <H1 className="mb-3">
          {t('components.partners.configuration.products.title')}
        </H1>
        <Strong>
          {t('components.partners.configuration.products.subtitle')}
        </Strong>
        <div>
          <Subtext>
            {t('components.partners.configuration.products.information')}
          </Subtext>
        </div>
      </div>

      <Stack direction="horizontal" gap={2} className="mt-3">
        <H3>Products</H3>
        <Badge bg="primary" className="ml-2">
          {dataPartner?.totalElements}
        </Badge>
      </Stack>
      <Row className="my-3">
        <Col xs="12" sm="6" md="3" xl="auto">
          <ProductFilterAsyncSelect
            type="brand"
            value={brandFilter}
            onChange={(value) => setBrandFilter(value)}
            placeholder={t(
              'components.partners.configuration.products.filter.brand',
            )}
            color={colorFilter || null}
            drops={dropFilter || null}
            productSize={sizeFilter || null}
            partnerId={partner.id}
          />
        </Col>
        <Col xs="12" sm="6" md="3" xl="auto">
          <ProductFilterAsyncSelect
            type="color"
            value={colorFilter}
            onChange={(value) => setColorFilter(value)}
            placeholder={t(
              'components.partners.configuration.products.filter.color',
            )}
            drops={dropFilter || null}
            productSize={sizeFilter || null}
            brand={brandFilter || null}
            partnerId={partner.id}
          />
        </Col>
        <Col xs="12" sm="6" md="3" xl="auto">
          <ProductFilterAsyncSelect
            type="size"
            value={sizeFilter}
            onChange={(value) => setSizeFilter(value)}
            placeholder={t(
              'components.partners.configuration.products.filter.size',
            )}
            color={colorFilter || null}
            drops={dropFilter || null}
            brand={brandFilter || null}
            partnerId={partner.id}
          />
        </Col>
        <Col xs="12" sm="6" md="3" xl="auto">
          <ProductFilterAsyncSelect
            type="drop"
            value={dropFilter}
            onChange={(value) => setDropFilter(value)}
            placeholder={t(
              'components.partners.configuration.products.filter.drop',
            )}
            color={colorFilter || null}
            productSize={sizeFilter || null}
            brand={brandFilter || null}
            partnerId={partner.id}
          />
        </Col>
      </Row>

      <ListPaginated
        isLoading={isLoadingPartner}
        page={page}
        data={dataPartner}
        setPage={setPage}
      >
        <List columnSize="200px">
          {dataPartner?.content.map((product) => (
            <ProductCard key={product.id} product={product} />
          ))}
        </List>
      </ListPaginated>
    </>
  );
};

export default PartnerConfigurationProducts;
