import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import request from 'src/axios';
import { useAuth, UserSession } from 'src/contexts/Auth';
import Routes from 'src/router/Routes';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'src/uikit/Dropdown';
import { Detail, H4 } from 'src/uikit/Typography';
import styled from 'styled-components/macro';

const ProfileDropdownToggle = styled(DropdownToggle)``;

const ProfileOption = styled(DropdownItem)``;

const ProfileMenu = styled(DropdownMenu)`
  margin-top: 0.25rem !important;
  right: 0;
  left: auto;
`;

const Info = styled.div`
  padding: 0 10px;
  text-align: left;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: none;
  }
`;

const Name = styled(H4)`
  color: ${({ theme }) => theme.palette.black.main};
  line-height: 21px;
  margin: 0;
`;

const Profile = styled(Detail)`
  color: ${({ theme }) => theme.palette.black.strongGrey};
  margin: 5px 0 0;
`;

const AvatarWrapper = styled.div`
  border-radius: 50%;
  margin-inline: 2px;
  width: 42px;
  height: 42px;
  justify-content: center;
`;

const StyledImage = styled(Image)`
  border-radius: 50%;
  width: 100%;
  height: 100%;
`;

type AuthUser = {
  user: UserSession;
};

const AuthMenu = ({ user }: AuthUser) => {
  const { t } = useTranslation();
  const { removeAuthUser } = useAuth();

  const { data: avatar, isLoading } = useQuery(
    ['user-avatar', user.avatarImagePath],
    async () => {
      if (user.avatarImagePath) {
        const response = await request.get(
          user.avatarImagePath.replace('/api/v1', ''),
          {
            responseType: 'blob',
          },
        );

        return URL.createObjectURL(response.data);
      }

      return '';
    },
  );

  const logout = async () => {
    await request.get('security/terminate');
    await removeAuthUser?.();
  };

  return (
    <>
      <Dropdown>
        <ProfileDropdownToggle
          className="btn-light d-flex align-items-center"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="true"
        >
          {!isLoading && avatar && (
            <AvatarWrapper>
              <StyledImage src={avatar} width={119} height={109} />
            </AvatarWrapper>
          )}
          <Info>
            <Name>
              {user.firstName} {user.lastName}
            </Name>
            <Profile>
              {t('components.profileForm.profile.' + user.profile)}
            </Profile>
          </Info>
        </ProfileDropdownToggle>
        <ProfileMenu aria-labelledby="dropdownMenuButton" align="end">
          <ProfileOption href={Routes.PROFILE}>
            {t('components.authMenu.linkToProfile')}
          </ProfileOption>
          <ProfileOption onClick={() => logout()}>
            {t('components.authMenu.linkToLogout')}
          </ProfileOption>
        </ProfileMenu>
      </Dropdown>
    </>
  );
};

export default AuthMenu;
