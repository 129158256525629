import { format } from 'date-fns';
import { useCallback, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ChevronRight } from 'src/assets/arrows';
import { ReactComponent as SubscriptionIcon } from 'src/assets/subscription-icon.svg';
import { DATE_FORMAT_DAY } from 'src/constants';
import { BillingAssemblyResponse } from 'src/sdk/subscription';
import { Card, CardBody } from 'src/uikit/Card';
import { List } from 'src/uikit/Layout/List';
import { Text } from 'src/uikit/Typography';
import styled from 'styled-components/macro';

import SubscriptionBillingSubcards from './SubscriptionBillingSubcards';

const StyledSubscriptionIcon = styled(SubscriptionIcon)`
  border-radius: 50%;
  color: ${({ theme }) => theme.palette.main.white};
  background-color: ${({ theme }) => theme.palette.main.cyan};
  height: 40px;
  width: 40px;
  padding: 8px;
`;

const StyledRow = styled(Row)`
  display: flex;
  align-items: center;
  width: 100%;
`;

const DisplayCol = styled(Col)<{ isvisible?: boolean }>`
  display: ${({ isvisible }) => (!isvisible ? 'none' : 'block')};
`;

const StyledCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NumberText = styled(Text)`
  font-weight: normal;
  margin: 0;
  color: ${({ theme }) => theme.palette.black[90]};
  word-break: break-word;
  text-align: center;
`;

const MonthInput = styled(NumberText)`
  text-transform: uppercase;
`;

const ArrowButton = styled.button`
  display: flex;
  background: none;
  border: none;
  transition-duration: 0.5s;

  &.rotate {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    transition-duration: 0.5s;
  }
`;

const ArrowIcon = styled(ChevronRight)`
  color: ${({ theme }) => theme.palette.main.primary};

  &.rotate {
    color: ${({ theme }) => theme.palette.main.red};
  }
`;

interface Props {
  data?: BillingAssemblyResponse[];
  onChange?: () => void;
}

const SubscriptionBillingCards = ({ data, onChange }: Props) => {
  const { t } = useTranslation();
  const [selectedId, setSelectedId] = useState<number>();

  const onSelected = useCallback(
    (id: number) => {
      setSelectedId(id !== selectedId ? id : undefined);
    },
    [selectedId],
  );

  return (
    <List>
      {data?.map((billing) => (
        <Card
          $clickable
          key={billing.id}
          onClick={() => onSelected(billing.id)}
          $isShadow
        >
          <Row>
            <CardBody>
              <StyledRow>
                <StyledCol md="1">
                  <StyledSubscriptionIcon />
                </StyledCol>
                <StyledCol md="2">
                  <MonthInput as="span">
                    {t(`common.months.month${billing.month}`)}
                  </MonthInput>
                </StyledCol>
                <StyledCol md="2">
                  <MonthInput as="span">{billing.year}</MonthInput>
                </StyledCol>
                <StyledCol md="2">
                  <NumberText>
                    {billing.sendToSapDateTime &&
                      format(
                        new Date(billing.sendToSapDateTime),
                        DATE_FORMAT_DAY,
                      )}
                  </NumberText>
                </StyledCol>
                <StyledCol md="2">
                  <NumberText>{billing.fullDayCount}</NumberText>
                </StyledCol>
                <StyledCol md="2" />
                <StyledCol md="1">
                  <ArrowButton
                    className={billing.id === selectedId ? 'rotate' : ''}
                  >
                    <ArrowIcon
                      className={billing.id === selectedId ? 'rotate' : ''}
                    />
                  </ArrowButton>
                </StyledCol>
              </StyledRow>
              <DisplayCol
                md="12"
                isvisible={billing.id === selectedId ? 1 : 0} // PC: pure boolean cause a console warning.
                onClick={(e: React.MouseEvent<SVGSVGElement, MouseEvent>) =>
                  e.stopPropagation()
                }
              >
                <SubscriptionBillingSubcards data={billing.subscriptions} />
              </DisplayCol>
            </CardBody>
          </Row>
        </Card>
      ))}
    </List>
  );
};

export default SubscriptionBillingCards;
