import { AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import request from 'src/axios';
import {
  BillingConfigurationResponse,
  BillingConfigurationParametersRequest,
} from 'src/sdk/billing';

const useCreateBillingConfigurations = () => {
  const queryClient = useQueryClient();

  const createBillingConfiguration = useMutation<
    AxiosResponse<BillingConfigurationResponse>,
    Error,
    BillingConfigurationParametersRequest
  >(
    (body) => {
      return request.post('/billing-configurations', body);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
      },
    },
  );

  return { createBillingConfiguration };
};

export default useCreateBillingConfigurations;
