import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import useToast from 'src/hooks/useToast';
import { Partner } from 'src/sdk/partner';
import { Button, ButtonColors } from 'src/uikit/Button';
import { P } from 'src/uikit/Typography';
import styled from 'styled-components/macro';

import useDeletePartnerMutation from './hooks/useDeletePartnerMutation';
import PartnerModal from './PartnerModal';

const StyledMessageWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: start-end;
  gap: 5px;
`;

const StyledButtonsWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
`;

interface Props {
  partner: Partner | null;
  show: boolean;
  onFinish: () => void;
}

const PartnerDeleteModal = ({ partner, show, onFinish }: Props) => {
  const { t } = useTranslation();
  const { toastSuccess, toastError } = useToast();

  const { deletePartnerMutation } = useDeletePartnerMutation();

  const onClick = async () => {
    if (!partner) {
      return;
    }

    try {
      await deletePartnerMutation.mutateAsync({
        id: partner.id,
      });

      toastSuccess(t('components.PartnerDeleteModal.success'));
      handleClose();
    } catch (err: any) {
      if (err.code) {
        return toastError(t(`errorsApi.${err.code}`));
      }
      toastError(t('components.PartnerDeleteModal.error'));
    }
  };

  const handleClose = useCallback(() => {
    onFinish?.();
  }, [onFinish]);

  return (
    <PartnerModal
      title={t('components.PartnerDeleteModal.title')}
      show={Boolean(partner && show)}
      handleClose={handleClose}
    >
      <StyledMessageWrapper>
        <P>{t('components.PartnerDeleteModal.content')}</P>
      </StyledMessageWrapper>

      <StyledButtonsWrapper>
        <Button background={ButtonColors.CANCEL} onClick={handleClose}>
          {t('common.action.cancel')}
        </Button>
        <Button onClick={onClick}>
          {t('components.PartnerDeleteModal.delete')}
        </Button>
      </StyledButtonsWrapper>
    </PartnerModal>
  );
};

export default PartnerDeleteModal;
