import { TFunction, useTranslation } from 'react-i18next';
import { ReactComponent as Charging } from 'src/assets/charging-stations.svg';
import { ReactComponent as Clipon } from 'src/assets/clipons.svg';
import { ReactComponent as Gateway } from 'src/assets/gateways.svg';
import { ShippingRequestOrderSparePartsLimitsResponse } from 'src/sdk/shippingRequest';
import CounterEquipmentInputNew from 'src/uikit/Form/CounterEquipmentInput/new';
import palette from 'src/uikit/theme/palette';
import styled from 'styled-components/macro';
import * as yup from 'yup';

export interface CurrentEquipments {
  clipons: number;
  gateways: number;
  chargingStations: number;
}

const Wrapper = styled.div`
  margin-top: 1rem;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-auto-rows: 1fr;
  justify-content: space-between;
  padding: 0;
  gap: 1rem;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`;

const Badge = styled.div`
  height: 24px;
  width: 24px;
  color: ${({ theme }) => theme.palette.main.white};
  border-radius: 100%;
  width: 40px;
  height: 40px;
  padding: 8px;
`;

const CliponBadge = styled(Clipon)`
  background: ${({ theme }) => theme.palette.main.primary};
`;

const ChargingBadge = styled(Charging)`
  background: ${({ theme }) => theme.palette.main.orange};
`;

const GatewayBadge = styled(Gateway)`
  background: ${({ theme }) => theme.palette.main.purple};
`;

export const nhEquipmentOrderFormEquipmentSchema = (t: TFunction) =>
  yup.object({
    cliponCount: yup.number(),
    gatewayCount: yup.number(),
    chargingStationCount: yup.number(),
  });

export type NHEquipmentOrderFormEquipmentSchema = yup.InferType<
  ReturnType<typeof nhEquipmentOrderFormEquipmentSchema>
>;

interface Props {
  equipmentsCount: NHEquipmentOrderFormEquipmentSchema;
  limit: ShippingRequestOrderSparePartsLimitsResponse;
}

const NHEquipmentOrderFormEquipment = ({ equipmentsCount, limit }: Props) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <CounterEquipmentInputNew
        name="cliponCount"
        title="Clip-on"
        badge={<Badge as={CliponBadge} />}
        label={t(
          'components.nursingHome.equipments.order.orderForm.equipements.label',
          {
            value: equipmentsCount.cliponCount,
          },
        )}
        step={10}
        max={limit.maxClipons}
      />
      <CounterEquipmentInputNew
        name="gatewayCount"
        title="Gateway"
        badge={<Badge as={GatewayBadge} />}
        mainColor={palette.main.purple}
        secondaryColor={palette.main.lightPurple}
        label={t(
          'components.nursingHome.equipments.order.orderForm.equipements.label',
          {
            value: equipmentsCount.gatewayCount,
          },
        )}
        max={limit.maxGateways}
      />
      <CounterEquipmentInputNew
        name="chargingStationCount"
        title="Charging"
        badge={<Badge as={ChargingBadge} />}
        mainColor={palette.main.yellow}
        secondaryColor={palette.main.lightYellow}
        label={t(
          'components.nursingHome.equipments.order.orderForm.equipements.label',
          {
            value: equipmentsCount.chargingStationCount,
          },
        )}
        max={limit.maxChargingStations}
      />
    </Wrapper>
  );
};

export default NHEquipmentOrderFormEquipment;
