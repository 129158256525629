import { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { ReactComponent as ConnectionStatusConnected } from 'src/assets/connection-status-connected.svg';
import { ReactComponent as ConnectionStatusError } from 'src/assets/connection-status-error.svg';
import { ReactComponent as GatewayBadge } from 'src/assets/gateway-badge.svg';
import EquipmentStatusLabel from 'src/components/Equipment/EquipmentStatusLabel';
import { GatewayDetails } from 'src/sdk/provisioning';
import { Card, CardBody } from 'src/uikit/Card';
import { Text, Label } from 'src/uikit/Typography';
import styled from 'styled-components/macro';

import EquipmentBadgeCol from '../EquipmentBadgeCol';

const GatewayBadgeStyled = styled(GatewayBadge)`
  width: 40px;
  height: 40px;
`;

const StyledCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconCol = styled(StyledCol)`
  justify-content: flex-end;
  padding-right: 1.5rem !important; // "important" is needed to overwrite the  property
`;

const NumberText = styled(Text)`
  font-weight: normal;
  margin: 0;
  color: ${({ theme }) => theme.palette.black[90]};
  word-break: break-word;
  text-align: center;
`;

const StatusLost = styled(Label)`
  background: ${({ theme }) => theme.palette.main.orange};
  color: ${({ theme }) => theme.palette.main.white};
  border-radius: 50%;
  margin: 0.254rem;
  padding: 0 8px;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1px;
`;

const StatusBroken = styled(StatusLost)`
  background: ${({ theme }) => theme.palette.main.red};
`;

interface Props {
  detail: GatewayDetails;
  onClick?: () => void;
  global?: boolean;
}

const EquipmentGatewayCard = ({ detail, onClick, global }: Props) => {
  const connectionStatus = useMemo(() => {
    const connectionStatus = detail?.connectionActivity?.connectionStatus;
    if (connectionStatus === 'CONNECTED') {
      return <ConnectionStatusConnected />;
    }
    return <ConnectionStatusError />;
  }, [detail?.connectionActivity?.connectionStatus]);

  return (
    <Card onClick={onClick} $clickable>
      <CardBody>
        <Row>
          <EquipmentBadgeCol>
            <GatewayBadgeStyled />
          </EquipmentBadgeCol>
          {!global ? (
            <>
              <StyledCol md="2">
                <NumberText>{detail.serialNumber}</NumberText>
              </StyledCol>
              <StyledCol md="3">
                <NumberText>{detail.handlingUnit?.ssccNumber}</NumberText>
              </StyledCol>
              <StyledCol md="2">
                <NumberText>{detail.eui}</NumberText>
              </StyledCol>
              <StyledCol md="2">
                <NumberText>{detail.model}</NumberText>
              </StyledCol>
              <StyledCol md="1">{connectionStatus}</StyledCol>
            </>
          ) : (
            <>
              <StyledCol md="2">
                <NumberText>{detail.name}</NumberText>
              </StyledCol>
              <StyledCol md="2">
                <NumberText>{detail.serialNumber}</NumberText>
              </StyledCol>
              <StyledCol md="2">
                <EquipmentStatusLabel status={detail.equipmentStatus} />
              </StyledCol>
              <StyledCol md="2">
                <NumberText>{detail.handlingUnit?.ssccNumber}</NumberText>
              </StyledCol>
              <StyledCol md="1">
                <NumberText>{detail.model}</NumberText>
              </StyledCol>
              <StyledCol md="1">{connectionStatus}</StyledCol>
            </>
          )}
          <IconCol md="1">
            {detail.broken && <StatusBroken>B</StatusBroken>}
            {detail.lost && <StatusLost>L</StatusLost>}
          </IconCol>
        </Row>
      </CardBody>
    </Card>
  );
};

export default EquipmentGatewayCard;
