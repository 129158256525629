import { useTranslation } from 'react-i18next';
import { ReactComponent as CliponBadgeRedIcon } from 'src/assets/clipon-badge-red.svg';
import { ReactComponent as CliponBadgeIcon } from 'src/assets/clipon-badge.svg';
import { Clipons } from 'src/sdk/nursingHome';
import palette from 'src/uikit/theme/palette';
import styled from 'styled-components/macro';

const StyledRow = styled.li`
  display: flex;
  margin-bottom: 12px;
  padding: 8px;
`;
const StyledCol = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 33.33%;
`;

interface Props {
  nursingHomeClipons?: Array<Clipons & { outdated: boolean }>;
}

const FirmwareNursingHomeDetailsCard = ({ nursingHomeClipons }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      {nursingHomeClipons ? (
        <ul style={{ margin: 0, padding: 0 }}>
          {nursingHomeClipons?.map((clipon, id) => (
            <StyledRow
              key={id}
              {...(clipon.outdated && { style: { color: palette.main.red } })}
            >
              <StyledCol style={{ justifyContent: 'start' }}>
                <div
                  style={{ height: '40px', width: '40px', marginRight: '12px' }}
                >
                  {clipon.outdated ? (
                    <CliponBadgeRedIcon />
                  ) : (
                    <CliponBadgeIcon />
                  )}
                </div>
                <span>{clipon.name}</span>
              </StyledCol>
              <StyledCol>{clipon.concatVersion}</StyledCol>
              <StyledCol>
                <span>{clipon.serialNumber}</span>
              </StyledCol>
            </StyledRow>
          ))}
        </ul>
      ) : (
        <div style={{ margin: '24px 0px' }}>
          {t('components.firmwareList.noClipon')}
        </div>
      )}
    </>
  );
};

export default FirmwareNursingHomeDetailsCard;
