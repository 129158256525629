import { useState } from 'react';
import ForgotPasswordForm from 'src/components/Auth/ForgotPassword/ForgotPasswordForm';
import ForgotPasswordSent from 'src/components/Auth/ForgotPassword/ForgotPasswordSent';

const ForgotPassword = () => {
  const [forgotPasswordSent = false, setEmailSent] = useState<boolean>();

  return (
    <>
      {forgotPasswordSent ? (
        <ForgotPasswordSent />
      ) : (
        <ForgotPasswordForm onReset={() => setEmailSent(true)} />
      )}
    </>
  );
};

export default ForgotPassword;
