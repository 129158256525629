import { Fragment, useCallback, useState, useEffect } from 'react';
import { Col, Row, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';
import { ReactComponent as PlusBadge } from 'src/assets/plus-badge.svg';
import ModalDetailCharging from 'src/components/Equipment/charging/EquipmentModalCharging';
import EquipmentCliponCard from 'src/components/Equipment/clipon/EquipmentCliponCard';
import EquipmentCliponHeader from 'src/components/Equipment/clipon/EquipmentCliponHeader';
import ModalDetailClipon from 'src/components/Equipment/clipon/EquipmentModalClipon';
import ProvisioningMetricList from 'src/components/Equipment/EquipmentMetricList';
import ModalDetailGateway from 'src/components/Equipment/gateway/EquipmentModalGateway';
import useFetchEquipmentsMetricsQuery from 'src/components/Equipment/hooks/useFetchEquipmentMetricsQuery';
import useFetchEquipmentsQuery, {
  EquipmentType,
  SortField,
} from 'src/components/Equipment/hooks/useFetchEquipmentQuery';
import ListPaginated from 'src/components/Misc/ListPaginated';
import { useAuth } from 'src/contexts/Auth';
import { useModal } from 'src/hooks/useModal';
import Routes from 'src/router/Routes';
import { Profiles } from 'src/sdk/authentification';
import { Status } from 'src/sdk/equipment';
import { CliponDetails, GatewayDetails } from 'src/sdk/provisioning';
import { Button } from 'src/uikit/Button';
import { List } from 'src/uikit/Layout/List';
import SearchInput from 'src/uikit/SearchInput';
import SwitchInput from 'src/uikit/SwitchInput';
import styled from 'styled-components/macro';

import { ChargingStationDetails } from '../../sdk/provisioning';
import { DEFAULT_PAGE_SIZE } from '../Misc/Pagination';
import EquipmentChargingHeader from './charging/EquipmentChargingHeader';
import EquipmentChargingStationCard from './charging/EquipmentChargingStationCard';
import EquipmentGatewayCard from './gateway/EquipmentGatewayCard';
import EquipmentGatewayHeader from './gateway/EquipmentGatewayHeader';

const StyledRow = styled(Row)`
  align-items: center;
  gap: 1rem;
`;

interface Props {
  type: EquipmentType;
  defaultSortField: SortField<
    CliponDetails | ChargingStationDetails | GatewayDetails
  >;
  isGlobal?: boolean;
}

const EquipmentList = ({ type, defaultSortField, isGlobal }: Props) => {
  const { nhSession, authUser } = useAuth();
  const { t } = useTranslation();
  const [query, setQuery] = useState('');
  const [brokenFilter, setBrokenFilter] = useState<boolean>(false);
  const [lostFilter, setLostFilter] = useState<boolean>(false);

  const [selected = -1, setSelected] = useState<number>();

  const [page, setPage] = useState(0);
  const { subscription, id } = nhSession || {};
  const { status } = subscription || {};
  const subType = subscription?.type || '';
  const { profile } = authUser || {};


  const shouldRenderButton = (profile === 'ORIZON_ADMINISTRATOR' || profile === 'PARTNER_ADMINISTRATOR') || (status === 'ACTIVE' && subType === 'STANDARD');

  const { modalOpen, setModalOpen } = useModal();

  const [sortField, setSortField] =
    useState<
      SortField<CliponDetails | ChargingStationDetails | GatewayDetails>
    >(defaultSortField);

  const [filterValue = Status.NO_STATUS, setFilter] = useState<Status>();

  const { data: dataMetrics } = useFetchEquipmentsMetricsQuery(type, {
    enabled:
      isGlobal === true &&
      (authUser?.profile === Profiles.ORIZON_ADMIN ||
        authUser?.profile === Profiles.PARTNER_ADMIN),
  });

  const handleFilter = useCallback(
    (value: Status) => {
      setFilter(value === filterValue ? Status.NO_STATUS : value);
    },
    [filterValue],
  );

  const { data, isLoading, refetch } = useFetchEquipmentsQuery(type, {
    broken: brokenFilter,
    lost: lostFilter,
    page,
    size: DEFAULT_PAGE_SIZE,
    sortField: sortField || defaultSortField,
    query,
    ...(!isGlobal
      ? { 'nursing-home-id': nhSession?.id }
      : {
          filterValue,
        }),
  });

  
  console.log('Data    ====>',data);
  
  
  useEffect(() => {
    setPage(0);
    refetch();
  }, [brokenFilter, lostFilter, query, filterValue]);

  const handleBorkenFilter  = () => {
    setBrokenFilter((prev) => !prev);
    refetch();
  }

  
  const handleLostFilter  = () => {
    setLostFilter((prev) => !prev);
    refetch();
  }


  const handleclick = useCallback(
    (index) => {
      setSelected(index);
      setModalOpen(true);
    },
    [setModalOpen],
  );

  return (
    <>
      {isGlobal && (
        <Row className="justify-content-center align-items-center mb-4">
          <ProvisioningMetricList
            metrics={dataMetrics}
            filterValue={filterValue}
            onFilterBy={handleFilter}
          />
        </Row>
      )}
      <StyledRow
        xs="auto"
        className="align-items-center justify-content-between mb-4"
      >
        <Col>
          <Stack direction="horizontal" gap={2}>
            <SearchInput onChange={(query) => setQuery(query)} />
            <SwitchInput
              label={t('pages.equipment.broken')}
              checked={brokenFilter}
              onChange={handleBorkenFilter}
            />
            <SwitchInput
              label={t('pages.equipment.lost')}
              checked={lostFilter}
              onChange={handleLostFilter}
            />
          </Stack>
        </Col>
        <Col>
        {
          shouldRenderButton ? (
            <Row xs="auto" className="justify-content-center mt-auto">
              <Col>
                <Button
                  as={Link}
                  to={generatePath(Routes.NURSING_EQUIPMENT_ORDERING_NEW, {
                    id: id?.toString() || '-1',
                  })}
                  className="align-self-end"
                >
                  <PlusBadge className="mr-2" />
                  {t('components.nursingHome.equipments.order.list.add')}
                </Button>
              </Col>
            </Row>
          ) : null
        }
        </Col>
      </StyledRow>

      <ListPaginated
        header={
          <>
            {type === 'clipon' && (
              <EquipmentCliponHeader
                onSortingChange={
                  setSortField as React.Dispatch<
                    React.SetStateAction<SortField<CliponDetails>>
                  >
                }
                sortField={sortField}
                global={isGlobal}
              />
            )}
            {type === 'charging' && (
              <EquipmentChargingHeader global={isGlobal} />
            )}
            {type === 'gateway' && <EquipmentGatewayHeader global={isGlobal} />}
          </>
        }
        data={data}
        isLoading={isLoading}
        page={page}
        setPage={setPage}
      >
        <List className="mt-3">
          {data?.content.map((detail) => (
            <Fragment key={detail.id}>
              {type === 'clipon' && (
                <EquipmentCliponCard
                  detail={detail as CliponDetails}
                  onClick={() => handleclick(detail.id)}
                  global={isGlobal}
                />
              )}
              {type === 'charging' && (
                <EquipmentChargingStationCard
                  detail={detail as ChargingStationDetails}
                  onClick={() => handleclick(detail.id)}
                  global={isGlobal}
                />
              )}
              {type === 'gateway' && (
                <EquipmentGatewayCard
                  detail={detail as GatewayDetails}
                  onClick={() => handleclick(detail.id)}
                  global={isGlobal}
                />
              )}
            </Fragment>
          ))}
        </List>
      </ListPaginated>
      <>
        {type === 'clipon' && (
          <ModalDetailClipon
            cliponId={selected}
            show={modalOpen}
            onClose={() => setModalOpen(false)}
            onChange={() => refetch()}
          />
        )}
        {type === 'charging' && (
          <ModalDetailCharging
            chargingId={selected}
            show={modalOpen}
            onClose={() => setModalOpen(false)}
            onChange={() => refetch()}
          />
        )}
        {type === 'gateway' && (
          <ModalDetailGateway
            gatewayId={selected}
            show={modalOpen}
            onClose={() => setModalOpen(false)}
            onChange={() => refetch()}
          />
        )}
      </>
    </>
  );
};
export default EquipmentList;
