import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SearchIcon } from 'src/assets/search.svg';
import useDebounce from 'src/hooks/useDebounce';
import TextInput, { TextInputProps } from 'src/uikit/TextInput';
import styled from 'styled-components/macro';

const StyledTextInput = styled(TextInput)`
  max-width: 200px;
`;

const Icon = styled(SearchIcon)`
  color: ${({ theme }) => theme.palette.black[90]};
`;

export type SearchInputProps = {
  debounce?: number;
  value?: string;
  onChange: (value: string) => void;
} & Omit<TextInputProps, 'value' | 'onChange'>;

const SearchInput = ({
  value,
  onChange,
  debounce,
  placeholder,

  ...rest
}: SearchInputProps) => {
  const { t } = useTranslation();

  const [searchValue = '', setSearchValue] = useState<string>(value || '');

  const searchDebounce = useDebounce(searchValue, debounce || 400);

  useEffect(() => {
    onChange(searchDebounce);
  }, [onChange, searchDebounce]);

  return (
    <StyledTextInput
      type="text"
      name="search"
      value={searchValue}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        setSearchValue(e.target.value)
      }
      placeholder={placeholder || t('components.searchInput.inputPlaceholder')}
      prependIcon={<Icon />}
      {...rest}
    />
  );
};

export default SearchInput;
