import { useMemo } from 'react';
import { Col, Form, Row, Stack } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import useLanguageOptions from 'src/hooks/useLanguageOptions';
import useOptionsCountries from 'src/hooks/useOptionsCountries';
import useTimeZoneOptions from 'src/hooks/useTimeZoneOptions';
import { PartnerProspectCreationRequest } from 'src/sdk/partner-prospect';
import { Button, ButtonColors } from 'src/uikit/Button';
import FormSelectInput from 'src/uikit/Form/SelectInput';
import FormTextInput from 'src/uikit/Form/TextInput';
import styled from 'styled-components/macro';
import * as yup from 'yup';

const FormGroup = styled(Form.Group)`
  margin-bottom: 0.25rem;
`;

const FormLabel = styled(Form.Label)`
  width: 100%;
  font-weight: 600;
  margin-bottom: 1rem;
  text-align: left;
  font-size: 14px;
`;

const FormFooter = styled(Stack)`
  flex-direction: row;
  justify-content: flex-end;
  gap: 1rem;

  ${Button} {
    font-size: 14px;
  }
`;

export const partnersProspectCreateFormSchema = (t: TFunction) =>
  yup.object({
    name: yup.string().required(),
    identifier: yup.string(),
    language: yup
      .mixed<PartnerProspectCreationRequest['language']>()
      .required(),
    timeZone: yup.string().required(),
    deliveryAddressName: yup
      .string()
      .min(
        2,
        t(
          'components.PartnerProspects.PartnerProspectsList.PartnerProspectCreateModal.PartnerProspectCreateForm.errorMessage.name',
        ),
      )
      .max(
        35,
        t(
          'components.PartnerProspects.PartnerProspectsList.PartnerProspectCreateModal.PartnerProspectCreateForm.errorMessage.nameMax',
        ),
      )
      .required(),
    deliveryAddressStreet: yup
      .string()
      .min(
        2,
        t(
          'components.PartnerProspects.PartnerProspectsList.PartnerProspectCreateModal.PartnerProspectCreateForm.errorMessage.street',
        ),
      )
      .max(
        35,
        t(
          'components.PartnerProspects.PartnerProspectsList.PartnerProspectCreateModal.PartnerProspectCreateForm.errorMessage.streetMax',
        ),
      )
      .required(),
    deliveryAddressZip: yup
      .string()
      .matches(
        /^[0-9]+$/,
        t(
          'components.PartnerProspects.PartnerProspectsList.PartnerProspectCreateModal.PartnerProspectCreateForm.errorMessage.zip',
        ),
      )
      .max(
        9,
        t(
          'components.PartnerProspects.PartnerProspectsList.PartnerProspectCreateModal.PartnerProspectCreateForm.errorMessage.zipMax',
        ),
      )
      .required(),
    deliveryAddressTown: yup
      .string()
      .min(
        1,
        t(
          'components.PartnerProspects.PartnerProspectsList.PartnerProspectCreateModal.PartnerProspectCreateForm.errorMessage.town',
        ),
      )
      .max(
        35,
        t(
          'components.PartnerProspects.PartnerProspectsList.PartnerProspectCreateModal.PartnerProspectCreateForm.errorMessage.townMax',
        ),
      )
      .required(),
    deliveryAddressCountry: yup
      .string()
      .min(
        1,
        t(
          'components.PartnerProspects.PartnerProspectsList.PartnerProspectCreateModal.PartnerProspectCreateForm.errorMessage.country',
        ),
      )
      .required(),
    deliveryAddressEmail: yup
      .string()
      .min(
        1,
        t(
          'components.PartnerProspects.PartnerProspectsList.PartnerProspectCreateModal.PartnerProspectCreateForm.errorMessage.email',
        ),
      )
      .max(
        70,
        t(
          'components.PartnerProspects.PartnerProspectsList.PartnerProspectCreateModal.PartnerProspectCreateForm.errorMessage.emailMax',
        ),
      )
      .email(
        t(
          'components.PartnerProspects.PartnerProspectsList.PartnerProspectCreateModal.PartnerProspectCreateForm.errorMessage.emailFormat',
        ),
      ),
    deliveryAddressTelephone: yup
      .string()
      .min(
        1,
        t(
          'components.PartnerProspects.PartnerProspectsList.PartnerProspectCreateModal.PartnerProspectCreateForm.errorMessage.phone',
        ),
      )
      .max(
        25,
        t(
          'components.PartnerProspects.PartnerProspectsList.PartnerProspectCreateModal.PartnerProspectCreateForm.errorMessage.phoneMax',
        ),
      ),
  });

export type PartnerProspectCreateFormSchema = yup.InferType<
  ReturnType<typeof partnersProspectCreateFormSchema>
>;

interface Props {
  onSubmit: (values: PartnerProspectCreateFormSchema) => void;
  onCancel: () => void;
}

const PartnerProspectCreateForm = ({ onSubmit, onCancel }: Props) => {
  const { groupedCountriesOptions } = useOptionsCountries();

  const { t } = useTranslation();
  const languageOptions = useLanguageOptions();
  const { data: timeZoneData } = useTimeZoneOptions();
  const timeZoneOptions = useMemo(() => {
    return timeZoneData || [];
  }, [timeZoneData]);

  const {
    handleSubmit,
    formState: { isValid, isDirty },
  } = useFormContext();

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup>
        <FormLabel>
          {t(
            'components.PartnerProspects.PartnerProspectsList.PartnerProspectCreateModal.PartnerProspectCreateForm.labels.name',
          )}
        </FormLabel>
        <FormTextInput
          name="name"
          placeholder={t(
            'components.PartnerProspects.PartnerProspectsList.PartnerProspectCreateModal.PartnerProspectCreateForm.placeholder.name',
          )}
          width="100%"
        />
      </FormGroup>
      <FormGroup>
        <FormLabel>
          {t(
            'components.PartnerProspects.PartnerProspectsList.PartnerProspectCreateModal.PartnerProspectCreateForm.labels.identifier',
          )}
        </FormLabel>
        <FormTextInput
          name="identifier"
          placeholder={t(
            'components.PartnerProspects.PartnerProspectsList.PartnerProspectCreateModal.PartnerProspectCreateForm.placeholder.identifier',
          )}
          width="100%"
          maxLength={20}
        />
      </FormGroup>
      <FormGroup>
        <Row>
          <Col>
            <FormSelectInput
              name="language"
              placeholder={t(
                'components.PartnerProspects.PartnerProspectsList.PartnerProspectCreateModal.PartnerProspectCreateForm.placeholder.language',
              )}
              options={languageOptions}
            />
          </Col>
          <Col>
            <FormSelectInput
              name="timeZone"
              placeholder={t(
                'components.PartnerProspects.PartnerProspectsList.PartnerProspectCreateModal.PartnerProspectCreateForm.placeholder.timezone',
              )}
              options={timeZoneOptions}
            />
          </Col>
        </Row>
      </FormGroup>
      <FormLabel>
        {t(
          'components.PartnerProspects.PartnerProspectsList.PartnerProspectCreateModal.PartnerProspectCreateForm.labels.address',
        )}
      </FormLabel>
      <FormGroup>
        <FormTextInput
          name="deliveryAddressName"
          placeholder={t(
            'components.PartnerProspects.PartnerProspectsList.PartnerProspectCreateModal.PartnerProspectCreateForm.placeholder.deliveryAddressName',
          )}
          width="100%"
        />
      </FormGroup>
      <FormGroup>
        <FormTextInput
          name="deliveryAddressStreet"
          placeholder={t(
            'components.PartnerProspects.PartnerProspectsList.PartnerProspectCreateModal.PartnerProspectCreateForm.placeholder.deliveryAddressStreet',
          )}
          width="100%"
        />
      </FormGroup>
      <Row>
        <Col>
          <FormGroup>
            <FormTextInput
              name="deliveryAddressTown"
              placeholder={t(
                'components.PartnerProspects.PartnerProspectsList.PartnerProspectCreateModal.PartnerProspectCreateForm.placeholder.deliveryAddressCity',
              )}
              width="100%"
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <FormTextInput
              name="deliveryAddressZip"
              placeholder={t(
                'components.PartnerProspects.PartnerProspectsList.PartnerProspectCreateModal.PartnerProspectCreateForm.placeholder.deliveryAddressZip',
              )}
              width="100%"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <FormSelectInput
              name="deliveryAddressCountry"
              placeholder={t(
                'components.PartnerProspects.PartnerProspectsList.PartnerProspectCreateModal.PartnerProspectCreateForm.placeholder.deliveryAddressCountry',
              )}
              options={groupedCountriesOptions}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <FormTextInput
              name="deliveryAddressTelephone"
              placeholder={t(
                'components.PartnerProspects.PartnerProspectsList.PartnerProspectCreateModal.PartnerProspectCreateForm.placeholder.deliveryAddressPhone',
              )}
              width="100%"
            />
          </FormGroup>
        </Col>
      </Row>
      <FormGroup>
        <FormTextInput
          placeholder={t(
            'components.PartnerProspects.PartnerProspectsList.PartnerProspectCreateModal.PartnerProspectCreateForm.placeholder.deliveryAddressEmail',
          )}
          name="deliveryAddressEmail"
          width="100%"
        />
      </FormGroup>
      <FormFooter>
        <Button background={ButtonColors.CANCEL} onClick={onCancel}>
          {t(
            'components.PartnerProspects.PartnerProspectsList.PartnerProspectCreateModal.PartnerProspectCreateForm.cancel',
          )}
        </Button>
        <Button type="submit" disabled={!isValid && isDirty}>
          {t(
            'components.PartnerProspects.PartnerProspectsList.PartnerProspectCreateModal.PartnerProspectCreateForm.create',
          )}
        </Button>
      </FormFooter>
    </Form>
  );
};

export default PartnerProspectCreateForm;
