import { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { ReactComponent as DeleteIcon } from 'src/assets/delete-badge.svg';
import { ReactComponent as EditIcon } from 'src/assets/edit-badge-solid.svg';
import { ReactComponent as PartnersIcon } from 'src/assets/partners-outline.svg';
import { Partner } from 'src/sdk/partner';
import { Button } from 'src/uikit/Button';
import { Card, CardBody } from 'src/uikit/Card';
import { Text } from 'src/uikit/Typography';
import styled from 'styled-components/macro';

const StyledCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`;

const StyledColAction = styled(StyledCol)`
  justify-content: flex-end;
`;

const StyledColIcon = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: start;
  flex: 0 0 50px;
`;

const StyledWrapperIcon = styled.div<{ hasProspect: boolean }>`
  --color: ${({ hasProspect, theme }) =>
    hasProspect ? theme.palette.main.primary : theme.palette.black[60]};

  color: var(--color);
  border: 1px solid var(--color);
  display: flex;
  align-items: center;
  justify-content: cemter;
  padding: 0.5rem;
  border-radius: 50%;
`;

const NumberText = styled(Text)`
  font-weight: normal;
  margin: 0;
  color: ${({ theme }) => theme.palette.black[90]};
  word-break: break-word;
  text-align: center;
`;

const StyledButton = styled(Button)`
  width: fit-content;
  padding: 0;
  border-radius: 2px;
  min-height: fit-content;
  background: transparent;
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  justify-content: center;
`;

const StyledButtonEdit = styled(StyledButton)``;

const StyledHoverdCard = styled(Card)`
  ${StyledButton} {
    filter: grayscale(1);
  }

  ${StyledButtonEdit} {
    opacity: 0;
  }

  &:hover {
    ${StyledWrapperIcon} {
      background: var(--color);
      color: ${({ theme }) => theme.palette.main.white};
    }

    ${StyledButtonEdit} {
      opacity: 1;
      transition: opacity 200ms ease-in-out;
    }

    ${StyledButton} {
      filter: grayscale(0);
    }
  }
`;

interface Props {
  partner: Partner;
  onClickDelete: (partner: Partner) => void;
  onClickUpdate: (partner: Partner) => void;
}

const PartnerCard = ({ partner, onClickDelete, onClickUpdate }: Props) => {
  const hasProspect = useMemo(
    () => Boolean(partner?.counts?.prospects && partner?.counts?.prospects > 0),
    [partner.counts?.prospects],
  );

  const handleClick = (e: Event) => {
    e.stopPropagation();
    onClickDelete(partner);
  };

  return (
    <StyledHoverdCard
      key={partner.id}
      onClick={() => onClickUpdate(partner)}
      shadow="xs"
      $clickable
    >
      <CardBody>
        <Row>
          <StyledColIcon className="p-0">
            <StyledWrapperIcon hasProspect={hasProspect}>
              <PartnersIcon />
            </StyledWrapperIcon>
          </StyledColIcon>
          <StyledCol xs="10" md="3">
            <NumberText as="span">{partner.name}</NumberText>
          </StyledCol>
          <StyledCol xs="3" md="2">
            <NumberText>{partner.soldTo}</NumberText>
          </StyledCol>
          <StyledCol xs="2" md="2">
            <NumberText>{partner.counts?.prospects || '-'}</NumberText>
          </StyledCol>
          <StyledCol xs="2" md="2">
            <NumberText>{partner.counts?.subscriptions || '-'}</NumberText>
          </StyledCol>
          <StyledCol xs="2" md="1">
            <NumberText>{partner.counts?.productSpecialists || '-'}</NumberText>
          </StyledCol>
          <StyledColAction md="1">
            <StyledButtonEdit background="primary">
              <EditIcon />
            </StyledButtonEdit>
            {!hasProspect && (
              <StyledButton onClick={handleClick} background="danger">
                <DeleteIcon />
              </StyledButton>
            )}
          </StyledColAction>
        </Row>
      </CardBody>
    </StyledHoverdCard>
  );
};

export default PartnerCard;
