import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import { Button } from 'src/uikit/Button';
import { FormGroup } from 'src/uikit/Form';
import FormTextInput from 'src/uikit/Form/TextInput';
import { H3, P } from 'src/uikit/Typography';
import styled from 'styled-components/macro';
import * as yup from 'yup';

import AuthForm from '../AuthForm';
import BackToLogin from '../BackToLogin';
import useStartPasswordResetMutation from '../hooks/useStartPasswordResetMutation';

const Wrapper = styled.div`
  width: auto;
`;

const ForgotPasswordWelcome = styled(H3)``;

const ForgotPasswordSubtitle = styled(P)``;

const ErrorMessage = styled(P)`
  color: ${({ theme }) => theme.palette.main.red};
  padding-bottom: 6px;
`;

const ForgotBlock = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const SubmitBlock = styled(ForgotBlock)`
  display: flex;
  align-items: baseline;
`;

const forgotPasswordSchema = (t: TFunction) =>
  yup.object({
    email: yup
      .string()
      .email()
      .required(t('components.Auth.ForgotPasswordForm.validations.required')),
  });

type ForgotPasswordSchema = yup.InferType<
  ReturnType<typeof forgotPasswordSchema>
>;

interface Props {
  onReset: () => void;
}

const ForgotPasswordForm = ({ onReset }: Props) => {
  const { t } = useTranslation();
  const [wrongAccount, setWrongAccount] = useState(false);

  const methods = useForm<ForgotPasswordSchema>({
    mode: 'onChange',
    resolver: yupResolver(forgotPasswordSchema(t)),
  });

  const { handleSubmit, watch, formState } = methods;
  const email = watch('email');

  const { startPasswordResetMutation, isLoading } =
    useStartPasswordResetMutation();

  const onSubmit = handleSubmit((data: ForgotPasswordSchema) => {
    setWrongAccount(false);
    startPasswordResetMutation({ username: email }) // username & email should be the same, but we keep that difference.
      .then(() => {
        onReset?.();
      })
      .catch(() => {
        setWrongAccount(true);
      });
  });

  return (
    <Wrapper>
      <FormProvider {...methods}>
        <AuthForm onSubmit={onSubmit}>
          <ForgotPasswordWelcome>
            {t('components.Auth.ForgotPasswordForm.title')}
          </ForgotPasswordWelcome>
          <ForgotPasswordSubtitle className="mt-2">
            {t('components.Auth.ForgotPasswordForm.subtitle')}
          </ForgotPasswordSubtitle>
          <FormGroup>
            <FormTextInput
              name="email"
              placeholder={t('components.loginForm.email')}
              width="100%"
            />
          </FormGroup>
          {wrongAccount && (
            <ErrorMessage>
              {t('components.Auth.ForgotPasswordForm.errors.wrongAccount')}
            </ErrorMessage>
          )}
          <SubmitBlock>
            <Button type="submit" disabled={!formState.isValid}>
              {isLoading ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                t('components.Auth.ForgotPasswordForm.reset')
              )}
            </Button>
          </SubmitBlock>
          <BackToLogin />
        </AuthForm>
      </FormProvider>
    </Wrapper>
  );
};

export default ForgotPasswordForm;
