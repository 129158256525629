import { useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EditIcon } from 'src/assets/edit-badge.svg';
import { UserResponse } from 'src/sdk/users';
import styled from 'styled-components/macro';

import { usePartner } from '../../../../contexts/Partner';
import UserEmailVerification from './UserEmailVerification';
import UserInformationForm from './UserInformationForm';
import UserResetPassword from './UserResetPassword';
import UserResume from './UserResume';

const Wrapper = styled(Container)`
  width: auto;
  display: flex;
  margin: 1rem;
  padding: 1rem 2rem 2rem;
  flex-direction: column;
  background: ${({ theme }) => theme.palette.main.white};
  box-shadow: ${({ theme }) => theme.shadows.md};

  border-radius: 15px;
`;

const Title = styled.h1`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  margin-bottom: 0;
`;

const IconButton = styled(Button)`
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.25rem;
  width: 40px;
  height: 40px;
  color: ${({ theme }) => theme.palette.black[80]};

  svg {
    width: inherit;
    height: inherit;
  }
`;

const UserActions = styled.div`
  display: flex;
  margin-top: 3rem;
`;

const Divider = styled.hr`
  opacity: 0.6;
  border-width: 0px 0px 0px 1px;
  border-image: none 100% / 1 / 0 stretch;
  border-color: ${({ theme }) => theme.palette.black[60]};
  border-style: solid;
  height: auto;
`;

interface Props {
  user: UserResponse;
  callback?: () => void;
}

const UserGeneral = ({ user, callback }: Props) => {
  const { t } = useTranslation();
  const [edit, setEdit] = useState(false);
  const { partner } = usePartner();
  return (
    <Wrapper fluid>
      <Row xs="auto" className="justify-content-between align-items-center">
        <Title>{t('components.Users.UserDetails.UserGeneral.title')}</Title>
        <IconButton
          variant="light"
          disabled={edit}
          onClick={() => setEdit(true)}
        >
          <EditIcon />
        </IconButton>
      </Row>
      <Row className="mt-2">
        <UserResume data={user} />
      </Row>
      {edit && (
        <Row>
          <UserInformationForm
            user={user}
            onEnd={() => {
              setEdit(false);
              callback?.();
            }}
          />
        </Row>
      )}
      <UserActions>
        <Col md="6">
          <UserResetPassword user={user} />
        </Col>
        {!partner?.id && (
          <>
            <Divider />
            <Col md="6">
              <UserEmailVerification user={user} />
            </Col>
          </>
        )}
      </UserActions>
    </Wrapper>
  );
};

export default UserGeneral;
