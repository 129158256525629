import { ElementType, ReactNode } from 'react';
import { Col, Row } from 'react-bootstrap';
import { ReactComponent as Postal } from 'src/assets/postal.svg';
import ActionCard, {
  ActionCardProps,
} from 'src/components/Misc/Card/ActionCard';
import CheckCard, { CheckCardProps } from 'src/components/Misc/Card/CheckCard';
import HoverCard, { HoverCardProps } from 'src/components/Misc/Card/HoverCard';
import { Address } from 'src/sdk/common';
import { Card, CardBody, CardProps } from 'src/uikit/Card';
import { Light, Strong, Text } from 'src/uikit/Typography';
import styled from 'styled-components/macro';

const WrapperAddress = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  text-transform: uppercase;
  min-height: 80px;
`;

const WrapperIcon = styled.div<{ isDisabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: ${({ theme, isDisabled }) =>
    isDisabled ? theme.palette.black.grey : theme.palette.main.cyan};
`;

const ColHeader = styled(Col)`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const DefaultCard = ({
  children,
  ...rest
}: { children: ReactNode } & CardProps) => (
  <Card {...rest}>
    <CardBody>{children}</CardBody>
  </Card>
);

type variant = 'default' | 'hover' | 'check' | 'action';

const Components: { [key in variant]: ElementType } = {
  default: DefaultCard,
  check: CheckCard,
  hover: HoverCard,
  action: ActionCard,
} as const;

export type AddressCardProps = (
  | ({
      variant?: 'hover';
    } & Omit<HoverCardProps, 'children'>)
  | ({ variant?: 'check' } & Omit<CheckCardProps, 'children'>)
  | ({ variant?: 'action' } & Omit<ActionCardProps, 'children'>)
  | { variant?: 'default' }
) & {
  address: Address;
} & CardProps;

const AddressCard = ({ address, variant, ...rest }: AddressCardProps) => {
  const Component: ElementType = variant
    ? Components[variant]
    : Components.default;

  return (
    <Component {...rest}>
      <div>
        <Row>
          <Col
            xs="2"
            className="d-flex px-0 align-items-center justify-content-center"
          >
            <WrapperIcon isDisabled={rest.disabled}>
              <Postal />
            </WrapperIcon>
          </Col>
          <ColHeader className="pl-1">
            {address.shipTo ? (
              <Light>{address.shipTo}</Light>
            ) : (
              <Strong>{address.name}</Strong>
            )}
          </ColHeader>
        </Row>
        <Row className="mb-2">
          <Col xs="2"></Col>
          <Col className="pl-1">
            <WrapperAddress>
              {address.shipTo && <Strong>{address.name}</Strong>}
              <Text>{address.street}</Text>
              <Text>
                {address.zip} - {address.town}
              </Text>
            </WrapperAddress>
          </Col>
        </Row>
      </div>
    </Component>
  );
};

export default AddressCard;
