import { SubscriptionResponse } from 'src/sdk/subscription';
import { DetailsCard } from 'src/uikit/DetailsCard';
import { Label } from 'src/uikit/Typography';
import styled from 'styled-components/macro';

const StyledCard = styled(DetailsCard)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const TypeText = styled(Label)`
  font-weight: bold;
  line-height: 25px;
  margin: 0;
  color: ${({ theme }) => theme.palette.main.primary};
`;

type Props = {
  details?: SubscriptionResponse;
  isInactive: boolean;
};

const SubscriptionDetails = ({ details, isInactive }: Props) => {
  return (
    <StyledCard $isInactive={isInactive}>
      <TypeText>{details?.type}</TypeText>
    </StyledCard>
  );
};

export default SubscriptionDetails;
