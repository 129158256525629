import { useMemo, useState } from 'react';
import { Row } from 'react-bootstrap';
import AddressCard from 'src/components/Misc/Card/AddressCard';
import Pagination, { DEFAULT_PAGE_SIZE } from 'src/components/Misc/Pagination';
import { DeliveryAddress } from 'src/sdk/prospect';
import { List } from 'src/uikit/Layout/List';
import { paginate } from 'src/utils/paginate';
import styled from 'styled-components/macro';

const PAGE_SIZE = DEFAULT_PAGE_SIZE;

interface Props {
  onSelected: (selectedShipTo: string[]) => void;
  selectedShipTo: string[];
  addresses?: DeliveryAddress[];
}

export const StyledListShipRow = styled(Row)`
  display: flex;
  flex-wrap: wrap;
  padding-block: 1.5rem;
  gap: 1.5rem;

  & > [class*='col-'] {
    display: flex;
    flex-direction: column;
  }
`;

const ListDeliveryAddress = ({
  onSelected,
  selectedShipTo,
  addresses,
}: Props) => {
  const [page, setPage] = useState(0);

  const addressesPaginated = useMemo(
    () => paginate(page, PAGE_SIZE, addresses),
    [addresses, page],
  );

  const onSetChecked = (shipTo: string) => {
    let index = selectedShipTo?.findIndex((e) => e === shipTo);
    const newShipTos =
      index !== -1
        ? [
            ...selectedShipTo.slice(0, index),
            ...selectedShipTo.slice(index + 1),
          ]
        : [...selectedShipTo, shipTo];

    onSelected(newShipTos);
  };

  return (
    <div className="container-fluid">
      <List columnSize="400px">
        {addressesPaginated?.map((content, index) => (
          <AddressCard
            key={content.shipTo}
            variant="check"
            type="add"
            checked={selectedShipTo.includes(content.shipTo)}
            onClick={() => onSetChecked(content.shipTo)}
            address={content}
            shadow="xs"
            border={true}
            disabled={!content.available}
          />
        ))}
      </List>
      {addresses && addresses.length > PAGE_SIZE && (
        <Pagination
          totalElements={addresses.length}
          perPage={PAGE_SIZE}
          onPageChange={setPage}
          currentPage={page}
        />
      )}
    </div>
  );
};

export default ListDeliveryAddress;
