import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Routes from 'src/router/Routes';
import { Button } from 'src/uikit/Button';
import styled from 'styled-components/macro';

const StyledContainer = styled(Container)`
  height: calc(100% - 70px);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
`;

const ErrorPage = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <StyledContainer fluid className="height: 100%;display flex">
      <p>{t('common.somethingWentWrong')}</p>
      <Button onClick={() => history.push(Routes.HOME)}>
        Back to homepage
      </Button>
    </StyledContainer>
  );
};

export default ErrorPage;
