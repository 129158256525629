import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ChargingSVG } from 'src/assets/big-icon-charging-station.svg';
import { ReactComponent as CliponSVG } from 'src/assets/big-icon-clipon.svg';
import { ReactComponent as GatewaySVG } from 'src/assets/big-icon-gateway.svg';
import { H1, H3 } from 'src/uikit/Typography';
import styled from 'styled-components/macro';

import GeneralCard, { Direction, Type } from './GeneralCard';

const StyledText = styled(H1)`
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
`;

const StyledSubtext = styled(H3)`
  font-size: 12px;
  line-height: 18px;
  margin: 20px 0;
  text-align: center;
`;

const IconLayout = styled(Row)`
  margin: 45px 0;
  width: -webkit-fill-available;
  justify-content: space-evenly;
`;

const ChargingIcon = styled(ChargingSVG)`
  height: 112px;
  width: 112px;
  color: ${({ theme }) => theme.palette.black[50]};
`;

const GatewayIcon = styled(GatewaySVG)`
  height: 112px;
  width: 112px;
  color: ${({ theme }) => theme.palette.black[50]};
`;
const CliponIcon = styled(CliponSVG)`
  height: 112px;
  width: 112px;
  color: ${({ theme }) => theme.palette.black[50]};
`;

const NoEquipmentCard = () => {
  const { t } = useTranslation();
  return (
    <GeneralCard
      title={t('components.equipmentBlock.title')}
      type={Type.DISABLED}
      direction={Direction.COLUMN}
    >
      <IconLayout>
        <CliponIcon />
        <ChargingIcon />
        <GatewayIcon />
      </IconLayout>
      <StyledText>{t('components.equipmentBlock.noEquipmentText')}</StyledText>
      <StyledSubtext>
        {t('components.equipmentBlock.addSubscriptionForEquipment')}
      </StyledSubtext>
    </GeneralCard>
  );
};

export default NoEquipmentCard;
