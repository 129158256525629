import { useQuery, QueryObserverOptions } from 'react-query';
import request from 'src/axios';
import {
  NursingHomeResponse,
  NursingHomeExpand,
  nursingHomeExpandList,
} from 'src/sdk/nursingHome';

export type NursingHomeSortField = {
  label: keyof NursingHomeResponse;
  direction: 'asc' | 'desc';
};

type QueryParams = {
  id: number;
  expand?: 'all' | NursingHomeExpand[];
};

export async function fetchNursingHome({
  id,
  expand = 'all',
}: QueryParams): Promise<NursingHomeResponse> {
  const _expand = expand === 'all' ? nursingHomeExpandList : expand;
  const { data } = await request.get(`/nursing-homes/${id}`, {
    params: {
      expand: _expand.join(','),
    },
  });

  return data;
}

const useFetchNursingHomeQuery = (
  params: QueryParams,
  options?: QueryObserverOptions<NursingHomeResponse, Error>,
) => {
  const { data, isLoading, refetch, error } = useQuery<
    NursingHomeResponse,
    Error
  >(
    [`nursing-home-${params.id}`, { ...params }],
    () => fetchNursingHome(params),
    {
      ...options,
    },
  );

  return {
    data,
    isLoading,
    refetch,
    error,
  };
};

export default useFetchNursingHomeQuery;
