import { useMemo } from 'react';
import { Button as BootstrapButton, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  ChevronLeft,
  ChevronRight,
  ChevronsLeft,
  ChevronsRight,
} from 'src/assets/arrows';
import { Button } from 'src/uikit/Button';
import { fontPrimary } from 'src/uikit/theme/typography';
import { H5 } from 'src/uikit/Typography';
import styled, { css } from 'styled-components/macro';

const defaultStyleRow = {
  sm: css`
    margin-top: 20px;
  `,
  md: css`
    margin: 50px 0;
  `,
};

const defaultStyleButton = {
  sm: css`
    padding: 0;
    width: 20px;
    height: 20px;
    font-size: 10px;
    font-weight: 700;
  `,
  md: css`
    width: 37px;
    height: 37px;
    font-size: 16px;
  `,
};

interface StyledProps {
  $isCurrentPage: boolean;
  $extraSize: keyof typeof defaultStyleRow;
}

const StyledRow = styled(Row)<StyledProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ $extraSize }) => defaultStyleRow[$extraSize || 'md']}
  color: ${({ theme }) => theme.palette.black.main};
`;

const StyledButton = styled(BootstrapButton)<StyledProps>`
  ${fontPrimary}
  color: ${({ theme }) => theme.palette.black.main};
  background-color: transparent;
  border: none;
  border-radius: 100%;
  padding: 0.375rem;

  :hover,
  :not(:disabled):not(.disabled):active {
    color: ${({ theme }) => theme.palette.main.white};
    background-color: ${({ theme }) => theme.palette.main.primary};
  }

  :focus {
    color: ${({ theme }) => theme.palette.main.white};
    background-color: ${({ theme }) => theme.palette.main.primary};
    box-shadow: 0 0 0 0.2rem rgb(12 158 171 / 25%);
  }

  :hover > svg,
  :focus > svg {
    color: ${({ theme }) => theme.palette.main.white};
  }

  ${({ $isCurrentPage }) =>
    $isCurrentPage &&
    css`
      color: ${({ theme }) => theme.palette.main.white};
      background-color: ${({ theme }) => theme.palette.main.primary};
    `}

  ${({ $extraSize }) => defaultStyleButton[$extraSize || 'md']}
`;

const OptionText = styled(H5)`
  font-size: 12px;
  margin: 0 5px;
`;

export const DEFAULT_PAGE_SIZE = 25 as const;

export type PaginationType = 'infinite-scroll' | 'page';

interface Props {
  size?: string;
  totalPages?: number;
  totalElements: number;
  perPage: number;
  onPageChange: (nextPage: number) => void;
  currentPage: number;
  type?: PaginationType;
}

const Pagination = ({
  size,
  totalElements,
  totalPages,
  onPageChange,
  perPage,
  currentPage,
  type = 'page',
}: Props) => {
  const { t } = useTranslation();
  const _totalPages = useMemo(() => {
    return totalPages || Math.ceil(totalElements / perPage);
  }, [perPage, totalElements, totalPages]);

  const text = `${t('components.pagination.page')} ${currentPage + 1} ${t(
    'components.pagination.of',
  )} ${_totalPages}`;

  return (
    <>
      {type === 'page' && (
        <StyledRow $extraSize={size} xs="auto">
          {currentPage !== 0 && (
            <>
              <StyledButton onClick={() => onPageChange(0)} $extraSize={size}>
                <ChevronsLeft />
              </StyledButton>
              <StyledButton
                onClick={() => onPageChange(currentPage - 1)}
                $extraSize={size}
              >
                <ChevronLeft />
              </StyledButton>
            </>
          )}
          <OptionText>{text}</OptionText>
          {currentPage !== _totalPages - 1 && (
            <>
              <StyledButton
                onClick={() => onPageChange(currentPage + 1)}
                $extraSize={size}
              >
                <ChevronRight />
              </StyledButton>
              <StyledButton
                onClick={() => onPageChange(_totalPages - 1)}
                $extraSize={size}
              >
                <ChevronsRight />
              </StyledButton>
            </>
          )}
        </StyledRow>
      )}
      {type === 'infinite-scroll' && (
        <StyledRow>
          {currentPage !== _totalPages - 1 && (
            <Button onClick={() => onPageChange(currentPage + 1)}>
              Load More
            </Button>
          )}
        </StyledRow>
      )}
    </>
  );
};

export default Pagination;
