import { useQuery } from 'react-query';
import request from 'src/axios';
import {
  PartnerProspect,
  PartnerProspectExpand,
} from 'src/sdk/partner-prospect';

export type PartnerPropsectSortField = {
  label: keyof PartnerProspect;
  direction: 'asc' | 'desc';
};

type QueryParams = {
  id: string;
  expand: PartnerProspectExpand[];
};

const fetchPartnerProspect = async ({ id, expand }: QueryParams) => {
  const { data } = await request.get(`/partner-prospects/${id}`, {
    params: {
      expand: expand.join(','),
    },
  });

  return data;
};

const useFetchPartnerProspectQuery = (
  id: string,
  expand: PartnerProspectExpand[],
) => {
  const { data, isLoading, refetch, error } = useQuery<PartnerProspect, Error>(
    [`partnerProspect${id}`, { id, expand }],
    () =>
      fetchPartnerProspect({
        id,
        expand,
      }),
  );

  return {
    data,
    isLoading,
    refetch,
    error,
  };
};

export default useFetchPartnerProspectQuery;
