import { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DeleteIcon } from 'src/assets/delete-badge.svg';
import AddressCard from 'src/components/Misc/Card/AddressCard';
import useFormatErrorApi from 'src/hooks/useFormatErrorApi';
import useToast from 'src/hooks/useToast';
import { BillingConfigurationParametersRequest } from 'src/sdk/billing';
import { NursingHomeResponse } from 'src/sdk/nursingHome';
import { Button } from 'src/uikit/Button';
import { Card, CardBody } from 'src/uikit/Card';
import Divider from 'src/uikit/Divider';
import { List } from 'src/uikit/Layout/List';
import { H1, H2, Text } from 'src/uikit/Typography';
import { PageWrapper } from 'src/uikit/Wrapper';
import styled from 'styled-components/macro';
import { usePartner } from '../../../../contexts/Partner';
import useCreateBillingConfigurations from '../hooks/useCreateBillingConfigurations';
import useDeleteBillingConfigurationMutation from '../hooks/useDeleteBillingConfigurationMutation';
import useFetchBillingConfigurationsEffective from '../hooks/useFetchBillingConfigurationsEffectiveQuery';
import useFetchBillingConfigurationsQuery from '../hooks/useFetchBillingConfigurationsQuery';
import useFetchBillingModesQuery from '../hooks/useFetchBillingModesQuery';
import NursingHomeConfigurationBillingsForm, {
  NursingHomeConfigurationBillingsFormSchema,
} from './NursingHomeConfigurationBillingsForm';

const StyledButton = styled(Button)`
  width: fit-content;
  padding: 0;
  border-radius: 2px;
  min-height: fit-content;
  background: transparent;
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  justify-content: center;
`;

interface Props {
  nursingHome: NursingHomeResponse;
}

const NursingHomeConfigurationBillings = ({ nursingHome }: Props) => {
  const { t, i18n } = useTranslation();
  const now = new Date();
  const today = new Date();
  
  const { partner } = usePartner();

  const { toastError, toastSuccess } = useToast();
  const { formatErrorApi } = useFormatErrorApi();

  const { data: currentBillingConfigurationData, isLoading: currentIsLoading } =
    useFetchBillingConfigurationsEffective({
      'nursing-home-id': nursingHome.id,
      month: today.getMonth() + 1,
      year: today.getFullYear(),
    });
    
  const { data: billingConfigurationsData } =
    useFetchBillingConfigurationsQuery({
      'nursing-home-id': nursingHome.id,
      page: 0,
    });
  const { data: billingModesData } = useFetchBillingModesQuery();
  const { createBillingConfiguration } = useCreateBillingConfigurations();
  const { deleteBillingConfiguration } =
    useDeleteBillingConfigurationMutation();

  const defaultValues: NursingHomeConfigurationBillingsFormSchema =
    useMemo(() => {
      const mode =
        currentBillingConfigurationData?.mode ||
        (billingModesData && billingModesData[0]) ||
        'NIGHTS_AND_DAYS';
      
      const type =
        mode === 'FULL_DAYS' || mode === 'NIGHTS_AND_DAYS'
          ? 'usage'
          : 'resident';
      const actualUsageOnlyOnExcess = currentBillingConfigurationData?.actualUsageOnlyOnExcess || false;

      const nightTimeEnd = nursingHome?.nightStartTime!;

      const nightTimeStart = nursingHome?.dayStartTime!;
        
      return {
        doNotBill: currentBillingConfigurationData?.doNotBill,
        type,
        actualUsageOnlyOnExcess,
        date: new Date(),
        mode,
        nightTimeEnd,
        nightTimeStart,
        tolerance: nursingHome?.tolerance || 30,
      };
    }, [
      billingModesData,
      currentBillingConfigurationData?.doNotBill,
      currentBillingConfigurationData?.mode,
      nursingHome.dayStartTime,
      nursingHome.nightStartTime,
      nursingHome.tolerance
    ]);
    
  /**
   * Submits the form with the given values and performs necessary actions.
   *
   * @param {NursingHomeConfigurationBillingsFormSchema} values - The form values submitted.
   * @return {Promise<void>} - A promise that resolves when the form is successfully submitted.
   */
  const onSubmit = async (values: NursingHomeConfigurationBillingsFormSchema) => {
    
    const body: BillingConfigurationParametersRequest = values.doNotBill
      ? { doNotBill: true, nursingHomeId: nursingHome.id }
      : {
          doNotBill: false,
          mode: values.mode,
          actualUsageOnlyOnExcess: values.actualUsageOnlyOnExcess,
          nursingHomeId: nursingHome.id,
          month: (values.date?.getMonth() || 0) + 1,
          year: values.date?.getFullYear(),
        };
    try {      

      await createBillingConfiguration.mutateAsync(body);
      toastSuccess(t('nursingHome.configuration.billings.success'));
    } catch (err) {
      const message = formatErrorApi(err);
      toastError(message);
    }
  };

  const onClickAddress = async (shipTo: string) => {
    if (shipTo === currentBillingConfigurationData?.shipTo) {
      return;
    }

    const body: BillingConfigurationParametersRequest = {
      nursingHomeId: nursingHome.id,
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      shipTo,
    };
    try {
      await createBillingConfiguration.mutateAsync(body);
      toastSuccess(t('nursingHome.configuration.billings.success'));
    } catch (err) {
      const message = formatErrorApi(err);
      toastError(message);
    }
  };

  const onDeleteBillingConfiguration = async (id: number) => {
    try {
      await deleteBillingConfiguration.mutateAsync({ id });
      toastSuccess(t('nursingHome.configuration.billings.success'));
    } catch (err) {
      const message = formatErrorApi(err);
      toastError(message);
    }
  };

  const getType = (mode: BillingConfigurationParametersRequest['mode']) => {
    return mode === 'FULL_DAYS' || mode === 'NIGHTS_AND_DAYS'
      ? 'usage'
      : 'resident';
  };

  const type = useMemo(() => {
    return getType(currentBillingConfigurationData?.mode);
  }, [currentBillingConfigurationData?.mode]);

  return (
    <PageWrapper>
      <Row className="justify-content-center">
        <H1>{t('nursingHome.configuration.billings.title')}</H1>
      </Row>
      <Card className="mt-4">
        <CardBody>
          <H2 className="my-2">
            {t('nursingHome.configuration.billings.subtitle')}
          </H2>
          <Row>
            {!currentIsLoading && (
              <Text $color="primary" className="mt-2">
                {t(`nursingHome.configuration.billings.current`)}
                {t(`nursingHome.configuration.billings.currentType.${type}`)}
              </Text>
            )}
          </Row>
          <Row className="mt-4">
            {billingConfigurationsData?.content
              ?.filter((billingConfiguration) => {
                return (
                  new Date(
                    billingConfiguration.year || 0,
                    (billingConfiguration.month || 0) - 1,
                  ) > new Date(now.getFullYear(), now.getMonth())
                );
              })
              .map((billingConfiguration, i) => {
                return (
                  <Row key={billingConfiguration.id} className="w-100 my-2">
                    <Col>
                      {t(`nursingHome.configuration.billings.asked`, {
                        date: new Intl.DateTimeFormat(i18n.language, {
                          year: 'numeric',
                          month: 'long',
                        }).format(
                          new Date(
                            billingConfiguration.year || 0,
                            (billingConfiguration.month || 0) - 1,
                          ),
                        ),
                      })}
                    </Col>
                    <Col>
                      {t(
                        `nursingHome.configuration.billings.nextType.${getType(
                          i === 0
                            ? currentBillingConfigurationData?.mode
                            : billingConfigurationsData.content[i - 1].mode,
                        )}`,
                      )}
                      {' -> '}
                      {t(
                        `nursingHome.configuration.billings.nextType.${getType(
                          billingConfiguration.mode,
                        )}`,
                      )}
                    </Col>
                    <Col className="d-flex justify-content-end">
                      <StyledButton
                        onClick={() =>
                          onDeleteBillingConfiguration(
                            billingConfiguration.id || -1,
                          )
                        }
                        background="danger"
                      >
                        <DeleteIcon />
                      </StyledButton>
                    </Col>
                  </Row>
                );
              })}
          </Row>

          <Divider />
          {currentBillingConfigurationData && (
            <NursingHomeConfigurationBillingsForm
              defaultValues={defaultValues}
              onSubmit={onSubmit}
            />
          )}
        </CardBody>
      </Card>
      {!partner?.id && (
        <Card className="mt-4">
          <CardBody className="p-4">
            {t(`nursingHome.configuration.billings.address`)}
            <List columnSize={{ xs: '250px', md: '350px' }} className="mt-3">
              {nursingHome.deliveryAddresses && nursingHome.deliveryAddresses
                .filter((deliveryAddress) => deliveryAddress.shipTo)
                .map((deliveryAddress) => (
                  <AddressCard
                    type="add"
                    variant="check"
                    key={deliveryAddress.shipTo}
                    address={deliveryAddress}
                    checked={
                      currentBillingConfigurationData?.shipTo ===
                      deliveryAddress.shipTo
                    }
                    onClick={() =>
                      deliveryAddress.shipTo &&
                      onClickAddress(deliveryAddress.shipTo)
                    }
                    border
                  />
                ))}
            </List>
          </CardBody>
        </Card>
      )}
    </PageWrapper>
  );
};

export default NursingHomeConfigurationBillings;
