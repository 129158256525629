import { AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import request from 'src/axios';
import { NursingHomeDeliveryAddressesRequest } from 'src/sdk/nursingHome';

const useUpdateDeliveryAddresses = (id: string) => {
  const queryClient = useQueryClient();

  const updatePasswordMutation = useMutation<
    AxiosResponse<any>,
    Error,
    NursingHomeDeliveryAddressesRequest
  >(
    (payload) =>
      request.put<NursingHomeDeliveryAddressesRequest>(
        `/nursing-homes/${id}/update-addresses`,
        payload,
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`nursing-home-${id}`);
        queryClient.invalidateQueries(`prospect`);
      },
    },
  );

  return updatePasswordMutation;
};

export default useUpdateDeliveryAddresses;
