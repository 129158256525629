import { useQueryClient, useMutation } from "react-query";
import { AxiosResponse } from 'axios';
import request from 'src/axios';


export interface ChangeTenantRequest {
    tenantName: string;
  }

const useChangeTenant = () => {

    const queryClient = useQueryClient();

    const changeTenant = useMutation<AxiosResponse<any>,Error,string>((tenantName) => request.post('/security/change-tenant', {tenantName}),
        {
            onSuccess: () => {
                queryClient.invalidateQueries();
            },
        }
    )
    return { changeTenant };
}

export default useChangeTenant