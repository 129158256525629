import { UserResponse } from 'src/sdk/users';
import { List } from 'src/uikit/Layout/List';

import UserRow from './UserRow';

interface Props {
  users: UserResponse[];
}

const UsersList = ({ users }: Props) => {
  return (
    <List className="mt-2">
      {users?.map((user) => (
        <UserRow key={user.id} user={user} />
      ))}
    </List>
  );
};

export default UsersList;
