import { useCallback, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { ReactComponent as PlusBadge } from 'src/assets/plus-badge.svg';
import request from 'src/axios';
import ListPaginated from 'src/components/Misc/ListPaginated';
import { DEFAULT_PAGE_SIZE } from 'src/components/Misc/Pagination';
import ModalCreateOrder from 'src/components/Ordering/List/ModalCreateOrder';
import OrderingHistoryCard from 'src/components/Ordering/List/OrderingHistoryCard';
import SortingOrderingHistoryHeader from 'src/components/SortingHeaders/SortingOrderingHistoryHeader';
import { GetOrdersResponse, Orders } from 'src/sdk/order';
import ButtonIcon from 'src/uikit/ButtonIcon';
import Center from 'src/uikit/Layout/Center';
import SearchInput from 'src/uikit/SearchInput';
import { H1 } from 'src/uikit/Typography';
import { PageWrapper } from 'src/uikit/Wrapper';
import styled from 'styled-components/macro';

const SearchColumn = styled(Col)`
  display: flex;
  justify-content: flex-start;
`;

const ButtonColumn = styled(Col)`
  display: flex;
  justify-content: flex-end;
`;

export type OrdersSortField = {
  label: keyof Orders;
  direction: 'asc' | 'desc';
};

const PAGE_SIZE = DEFAULT_PAGE_SIZE;

type QueryParams = {
  page: number;
  sort: OrdersSortField;
  'po-number-part': string;
};

async function fetchOrders(queryParams: QueryParams) {
  const { data } = await request.get('/purchase-orders?expand=items,vendor', {
    params: {
      'po-number-part': queryParams['po-number-part'],
      page: queryParams.page,
      size: PAGE_SIZE,
      sort: `${queryParams.sort.label}${
        queryParams.sort.label === 'id' ? '' : ',id'
      },${queryParams.sort.direction}`, // sorteed by criteria, then by id if necessary.
    },
  });
  return data;
}

const Ordering = () => {
  const { t } = useTranslation();
  const [showCreateOrder = false, setShowCreateOrder] = useState<boolean>();
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState<OrdersSortField>({
    label: 'sapCreationDate',
    direction: 'desc',
  });
  const [filter = '', setFilter] = useState<string>();

  const handleChangeSearch = useCallback((value) => {
    setFilter(value);
  }, []);

  const { data, isLoading, refetch } = useQuery<GetOrdersResponse, Error>(
    ['purchase-orders', { page, sort, filter }],
    () => fetchOrders({ page, sort, 'po-number-part': filter }),
  );

  const handleFinishCreation = useCallback(() => {
    setShowCreateOrder(false);
    refetch();
  }, [refetch]);

  return (
    <PageWrapper>
      <Center>
        <H1>{t('pages.ordering.title')}</H1>
      </Center>
      <Row className="justify-content-between my-3">
        <SearchColumn md="2">
          <SearchInput onChange={handleChangeSearch} />
        </SearchColumn>
        <ButtonColumn md="3">
          <ButtonIcon
            prepend={<PlusBadge />}
            handleClick={() => setShowCreateOrder(true)}
            label={t('pages.ordering.orderButton')}
          />
        </ButtonColumn>
      </Row>
      <ListPaginated
        isLoading={isLoading}
        page={page}
        setPage={setPage}
        data={data}
        header={
          <SortingOrderingHistoryHeader
            onSortingChange={setSort}
            sortField={sort}
          />
        }
      >
        <OrderingHistoryCard orders={data?.content} />
      </ListPaginated>
      {showCreateOrder && (
        <ModalCreateOrder
          onFinish={handleFinishCreation}
          onClose={() => setShowCreateOrder(false)}
          show={showCreateOrder}
        />
      )}
    </PageWrapper>
  );
};

export default Ordering;
