import { Children } from 'react';
import Select, { components, Props as ReactSelectProps } from 'react-select';
import { ChevronBottom } from 'src/assets/arrows';
import SelectGroupLabel from 'src/uikit/SelectInput/SelectGroupLabel';
import { fontPrimary, fonts } from 'src/uikit/theme/typography';
import styled, { css } from 'styled-components/macro';

import palette from '../../uikit/theme/palette';

export const orizonStyleSelect = {
  clearIndicator: (provided: any, state: any) => ({
    ...provided,
    color: palette.black[60],
    '&:hover': {
      color: palette.main.primary,
    },
  }),
  container: (provided: any, state: any) => ({
    ...provided,
  }),

  group: (provided: any, state: any) => ({
    ...provided,
  }),
  groupHeading: (provided: any, state: any) => ({
    ...provided,
  }),

  input: (provided: any, state: any) => ({
    ...provided,
  }),
  loadingIndicator: (provided: any, state: any) => ({
    ...provided,
  }),
  loadingMessage: (provided: any, state: any) => ({
    ...provided,
  }),
  menuList: (provided: any, state: any) => ({
    ...provided,
  }),
  menuPortal: (provided: any, state: any) => ({
    ...provided,
    zIndex: '9999',
  }),
  noOptionsMessage: (provided: any, state: any) => ({
    ...provided,
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    overflow: 'visible',
    padding: '0 8px',
  }),
  indicatorSeparator: (provided: any, state: any) => ({}),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    paddingLeft: '3px',
    marginLeft: '0px',
  }),
  control: (provided: any, state: any) => {
    return {
      ...provided,
      padding:
        state.isMulti && state.hasValue && state.getValue().length > 1
          ? '0px 0px 0px 0px'
          : '0px',
      position: 'relative',
      borderWidth: '1px',
      borderColor: state.isFocused ? palette.main.primary : palette.black[50],
      borderStyle: 'solid',
      borderRadius: '10px',
      fontFamily: fontPrimary,
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '24px',
      letterSpacing: '0.1px',
      minHeight: '44px',
      boxShadow: 'none',
      '&:hover': {
        borderColor: state.isFocused ? palette.main.primary : palette.black[60],
      },
    };
  },
  multiValue: (provided: any, state: any) => ({
    ...provided,
    lineHeight: '12px',
    fontFamily: fontPrimary,
    color: palette.black[80],
    backgroundColor: palette.main.white,
    border: '2px solid ' + palette.main.primary,
    borderRadius: '5px',
  }),
  multiValueLabel: (provided: any, state: any) => ({
    ...provided,
    fontSize: '12px',
    lineHeight: '12px',
    fontFamily: fontPrimary,
    color: palette.black[80],
  }),
  multiValueRemove: (provided: any, state: any) => ({
    ...provided,
    color: palette.main.primary,
    '&:hover': {
      color: palette.main.primary,
      backgroundColor: palette.main.primary,
    },
  }),
  menu: (provided: any) => ({
    ...provided,
    zIndex: '100',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontFamily: fontPrimary,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20 px',
    letterSpacing: '0.1px',
    textAlign: 'start',
    color: palette.black.main,
    borderBottom: '1px solid ' + palette.black.lightGrey,
    opacity: state.isDisabled ? 0.5 : 1,
    paddingInline: '1rem',
    backgroundColor: state.isFocused
      ? palette.main.lightBlue
      : palette.main.white,
    '&:active': {
      backgroundColor: palette.main.primary,
      color: palette.main.white,
    },
  }),
};

const DropdownIndicatorIcon = styled(ChevronBottom)`
  display: flex;
  margin: 8px;
  color: #44444f;
  width: 15px;
  height: 15px;
`;

const DropdownIndicator = () => {
  return <DropdownIndicatorIcon />;
};

type StyledSelectProps = {
  variant?: 'main' | 'rounded';
  value?: any;
  isInvalid?: boolean;
  isDisabled?: boolean;
  width?: string;
} & Omit<ReactSelectProps, 'value' | 'onChange'>;

export const StyledSelect = styled(Select)<StyledSelectProps>`
  min-width: 170px;
  width: 100%;
  font-family: ${fonts.primary};
  border-radius: 10px;

  .styledSelect__control {
    border: 1px solid
      ${({ theme, isInvalid }) =>
        isInvalid ? theme.palette.main.red : theme.palette.black[50]};
    background-color: ${({ theme }) => theme.palette.main.white};
    box-sizing: border-box;
    width: ${({ width }) => (width ? width : '100%')};
    font-weight: normal;
    font-family: ${fontPrimary};

    &:has(.styledSelect__value-container--is-multi) {
      min-width: max-content;
    }

    :focus {
      border-color: ${({ theme }) => theme.palette.main.primary};
      color: ${({ theme }) => theme.palette.black.main};
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      box-shadow: none;
      font-family: ${fontPrimary};
      font-style: normal;
      font-weight: normal;
      line-height: 24px;
      letter-spacing: 0.1px;
      outline: 0 !important;
      box-shadow: none !important;
    }

    :focus:not(:focus-visible) {
    }

    :error  {
      background-color: ${({ theme }) => theme.palette.main.greenApple};
    }

    .styledSelect__placeholder {
      transform: none;
      position: absolute;
      top: 0px;
      left: 10px;
      transition: 200ms;
      pointer-events: none;
      color: ${({ theme }) => theme.palette.black[90]};
      margin: 0 5px 0;
      padding: 4px;

      // For too long placeholders, eg. in german.
      max-width: 160px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 0.9rem;
      color: hsl(0, 0%, 50%);
    }
  }

  ${({ variant, theme }) =>
    (variant === 'main' &&
      `
        min-height: 44px;
        > .styledSelect__control {
          min-height: 44px;
          border-radius: 10px;
        }
        > .styledSelect__indicators {
          min-height: 44px;
        }
      `) ||
    (variant === 'rounded' &&
      `
        height: 32px;
        border-radius: 32px;

        > .styledSelect__control {
          height: 32px;
          min-height: 32px;
          border-radius: 32px;
          
          .styledSelect__placeholder {
                font-size: 0.9rem;

            color: ${theme.palette.black[90]};
          }
        }
        
        .styledSelect__indicators {
          min-height: 32px;
          height: 32px;
        }
      `)};

  ${({ variant, value, theme }) =>
    (variant === 'main' &&
      value &&
      `
      > .styledSelect__control {
        .styledSelect__placeholder {
          
          transform: translate3d(-4px, -24px, 0);
          color: ${theme.palette.main.primary};
          background-blend-mode: screen; // for Edge & Chrome, to avoid bold text on transition

          &::before {
            background: ${theme.palette.main.white};
            z-index: -1;
            content: '';
            display: block;
            height: 3px;
            width: 100%;
            position: absolute;
            top: 18px;
            left: 0;
          }
        }
      }
    `) ||
    (variant === 'rounded' &&
      value &&
      `
      > .styledSelect__control {
       .styledSelect__placeholder {

          top: 0px;
          font-size: 0.9rem;


          transform: translate3d(0px, -18px, 0);
          color: ${theme.palette.main.primary};
          background-blend-mode: screen; // for Edge & Chrome, to avoid bold text on transition

          &::before {
            background: ${theme.palette.main.white};
            z-index: -1;
            content: '';
            display: block;
            height: 3px;
            width: 100%;
            position: absolute;
            top: 17px;
            left: 0;
          }
        }
      }
    `)};

  ${({ isDisabled, theme }) =>
    isDisabled &&
    css`

    > .styledSelect__control {
      border-color: ${({ theme }) => theme.palette.black[50]};
      color: ${({ theme }) => theme.palette.black[60]};
      background: ${({ theme }) => theme.palette.black.strongLightGrey};
    }
 

    > .styledSelect__control {
      .styledSelect__placeholder {
      color: ${({ theme }) => theme.palette.black[70]};
      &::before {
        background: ${({ theme }) => theme.palette.black.strongLightGrey};
      }
    }
  `}
`;

const { ValueContainer, Placeholder } = components;
const OrizonValueContainer = (props: any) => {
  let isFocusedValue = false;

  if (props.children[0] !== null) {
    if (typeof props.children[0].props !== 'undefined') {
      // For single value version
      isFocusedValue = props.children[0].props.isFocused;
    } else if (props.children[0][0] !== null) {
      // For multiple value version
      if (typeof props.children[0][0].props !== 'undefined') {
        isFocusedValue = props.children[0][0].props.isFocused;
      }
    }
  }

  return (
    <ValueContainer {...props} isFocused={isFocusedValue}>
      <Placeholder {...props} isFocused={isFocusedValue}>
        {props.selectProps.placeholder}
      </Placeholder>
      {Children.map(props.children, (child) =>
        child && child.type !== Placeholder ? child : null,
      )}
    </ValueContainer>
  );
};

export interface Option {
  label: string | JSX.Element;
  value: string | number | null;
}
export interface GroupedOption<T = Option> {
  label: string;
  options: T[];
}

export type SelectInputProps = {
  placeholder?: string;
  value: any;
  options: Option[] | GroupedOption[];
  onChange: (e: any) => void;
  reference?: any;
  disabled?: boolean;
  isInvalid?: boolean;
  variant?: 'main' | 'rounded';
  width?: 'string';
} & Omit<ReactSelectProps, 'value' | 'onChange' | 'options'>;

const SelectInput = ({
  placeholder,
  value,
  onChange,
  options,
  reference,
  disabled,
  isInvalid,
  width,
  variant = 'main',
  ...rest
}: SelectInputProps) => {
  return (
    <StyledSelect
      id={placeholder}
      value={value}
      onChange={onChange}
      options={options}
      variant={variant}
      styles={orizonStyleSelect}
      ref={reference}
      isDisabled={disabled}
      menuPlacement={'auto'}
      menuPosition={'absolute'}
      isInvalid={isInvalid}
      classNamePrefix="styledSelect"
      className="selectWrapper"
      width={width}
      components={{
        DropdownIndicator,
        IndicatorSeparator: null,
        ValueContainer: OrizonValueContainer,
      }}
      formatGroupLabel={SelectGroupLabel}
      placeholder={placeholder}
      {...rest}
    />
  );
};

export default SelectInput;
