import breakpoints from 'src/uikit/theme/breakpoints';
import styled, { css } from 'styled-components/macro';

type ColumnBreakpoint = keyof typeof breakpoints;

type ColumnSize = string | { [key in ColumnBreakpoint]?: string };

export const List = styled.div<{ columnSize?: ColumnSize; gap?: string }>`
  display: grid;
  gap: ${({ gap }) => gap || '1rem'};

  ${({ columnSize }) =>
    columnSize &&
    (typeof columnSize === 'string'
      ? css`
          grid-template-columns: repeat(auto-fill, minmax(${columnSize}, 1fr));
        `
      : css`
          ${Object.keys(columnSize).map(
            (breakpoint: string) =>
              breakpoints[breakpoint as ColumnBreakpoint] &&
              css`
                @media (min-width: ${breakpoints[
                    breakpoint as ColumnBreakpoint
                  ]}) {
                  grid-template-columns: repeat(
                    auto-fill,
                    minmax(${columnSize[breakpoint as ColumnBreakpoint]}, 1fr)
                  );
                }
              `,
          )}
        `)}
`;
