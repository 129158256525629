import { useCallback, useEffect } from 'react';
import { Row, Image, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import request from 'src/axios';
import ModalEditAvatar from 'src/components/Misc/Modal/ModalEditAvatar';
import { useAuth } from 'src/contexts/Auth';
import { useModal } from 'src/hooks/useModal';
import { UserResponse } from 'src/sdk/users';
import { Button } from 'src/uikit/Button';
import styled from 'styled-components/macro';

//import UserImageDisplay from '../Misc/UserImageDisplay';

const RowButtonAvatar = styled(Row)`
  justify-content: center;
`;

const AvatarWrapper = styled(Row)`
  margin: 0 auto 20px;
  justify-content: center;
`;

const StyledImage = styled(Image)``;

interface Props {
  user?: UserResponse;
  onChange: () => void;
}

const ProfileAvatar = ({ user, onChange }: Props) => {
  const { t } = useTranslation();
  const { authUser, setAuthUser } = useAuth();
  const { modalOpen, setModalOpen } = useModal();

  const { data: avatar, refetch } = useQuery(
    'user-avatar',
    () => {
      const path = user?.avatarImagePath;
      return request
        .get(path!.replace('/api/v1', ''), {
          responseType: 'blob',
        })
        .then((responseAvatar) => {
          if (authUser && authUser?.avatarImagePath !== user?.avatarImagePath) {
            setAuthUser?.({
              ...authUser,
              avatarImagePath: user?.avatarImagePath,
            });
          }
          return URL.createObjectURL(responseAvatar.data);
        });
    },
    {
      enabled: !!user?.avatarImagePath,
    },
  );

  useEffect(() => {
    if (user?.avatarImagePath) refetch();
  }, [refetch, user?.avatarImagePath]);

  const handleChangeAvatar = useCallback(
    (avatarId: number) => {
      const userId = authUser?.id;

      if (userId !== -1) {
        if (avatarId !== -1) {
          request
            .put(`/users/${userId}/avatar`, { commonAvatarId: avatarId })
            .then(() => {
              onChange();
            })
            .catch((e) => {
              console.error(e);
            });
        }
      }
      setModalOpen(false);
    },
    [authUser?.id, onChange, setModalOpen],
  );

  const handleUploadAvatar = useCallback(
    (upload: string) => {
      const userId = authUser?.id;

      if (userId !== -1) {
        fetch(upload)
          .then((res) => res.blob())
          .then((blob) => {
            let dto ={
              gender : user?.gender,
              profile : user?.profile,
              defaultAvatar : false
            }
            // Convert the dto object to a JSON string
            const dtoString = JSON.stringify(dto);
            const formData = new FormData();
            const file = new File([blob], '');
            // Append the JSON string as a field value
            formData.append('dto', dtoString);
            formData.append('file', file);
            request
              .post(`/users/${userId}/avatar`, formData , {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              })
              .then(() => {
                onChange();
              })
              .catch((e) => {
                console.error(e);
              });
          });
      }
      setModalOpen(false);
    },
    [authUser?.id, onChange, setModalOpen],
  );

  return (
    <>
      {avatar ? (
        <AvatarWrapper>
          <StyledImage src={avatar} width={109} height={109} />
        </AvatarWrapper>
      ) : (
        <>
        </>
      )}

      <RowButtonAvatar xs="auto">
        <Col>
          <Button onClick={() => setModalOpen(true)}>
            {t('components.profileForm.editAvatarButton')}
          </Button>
        </Col>
      </RowButtonAvatar>
      {user && (
        <ModalEditAvatar
          show={modalOpen}
          onClose={() => setModalOpen(false)}
          userId={authUser?.id || -1}
          role={user.profile}
          partnerUser={user.partnerUser}
          gender={user?.gender}
          preview={avatar}
          onChangeAvatar={handleChangeAvatar}
          onUploadAvatar={handleUploadAvatar}
        />
      )}
    </>
  );
};

export default ProfileAvatar;
