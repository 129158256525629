import { Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ChevronLeft } from 'src/assets/arrows';
import { ChevronRight } from 'src/assets/arrows';
import { Button, ButtonColors } from 'src/uikit/Button';
import ButtonIcon from 'src/uikit/ButtonIcon';
import styled from 'styled-components/macro';

export interface UserCreateFormFooterProps {
  onBack: () => void;
  onCancel: () => void;
}

const FormButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 15px;
  }
`;
const FormFooter = styled(Stack)`
  margin-top: 1rem;
  justify-content: space-between;
  gap: 1rem;

  ${Button} {
    font-size: 14px;
  }
`;

const UserCreateFormFooter = ({
  onBack,
  onCancel,
}: UserCreateFormFooterProps) => {
  const { t } = useTranslation();
  return (
    <FormFooter direction="horizontal">
      <ButtonIcon prepend={<ChevronLeft />} handleClick={onBack} />
      <Stack direction="horizontal">
        <FormButton background={ButtonColors.CANCEL} onClick={onCancel}>
          {t('components.Users.UsersList.UserCreateFormFooter.cancel')}
        </FormButton>
        <FormButton type="submit">
          {t('components.Users.UsersList.UserCreateFormFooter.continue')}
          <ChevronRight />
        </FormButton>
      </Stack>
    </FormFooter>
  );
};

export default UserCreateFormFooter;
