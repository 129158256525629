import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as HomeIcon } from 'src/assets/home.svg';
import useToast from 'src/hooks/useToast';
import { clientTimeZone } from 'src/utils/timeZone';

import useCreatePartnerProspectMutation from '../../hooks/useCreatePartnerProspectMutation';
import PartnerProspectsModal from '../../PartnerProspectModal';
import PartnerProspectCreateForm, {
  partnersProspectCreateFormSchema,
  PartnerProspectCreateFormSchema,
} from './PartnerProspectCreateForm';

interface Props {
  show: boolean;
  onFinish: () => void;
  onCancel: () => void;
}

const PartnerProspectsCreateModal = ({ show, onFinish, onCancel }: Props) => {
  const { t } = useTranslation();
  const { toastSuccess, toastError } = useToast();

  const { createPartnerProspectMutation } = useCreatePartnerProspectMutation();

  const createDefaultValues: PartnerProspectCreateFormSchema = useMemo(() => {
    return {
      name: '',
      identifier: '',
      language: 'en',
      timeZone: clientTimeZone,
      deliveryAddressName: '',
      deliveryAddressStreet: '',
      deliveryAddressZip: '',
      deliveryAddressTown: '',
      deliveryAddressCountry: '',
      deliveryAddressEmail: '',
      deliveryAddressTelephone: '',
    };
  }, []);

  const createMethods = useForm<PartnerProspectCreateFormSchema>({
    resolver: yupResolver(partnersProspectCreateFormSchema(t)),
    defaultValues: createDefaultValues,
    mode: 'onChange',
  });

  const { reset } = createMethods;

  const onSubmit = async (values: PartnerProspectCreateFormSchema) => {
    try {
      await createPartnerProspectMutation.mutateAsync({
        name: values.name,
        identifier: values.identifier,
        language: values.language,
        timeZone: values.timeZone,
        deliveryAddressName: values.deliveryAddressName,
        deliveryAddressStreet: values.deliveryAddressStreet,
        deliveryAddressZip: values.deliveryAddressZip,
        deliveryAddressTown: values.deliveryAddressTown,
        deliveryAddressCountry: values.deliveryAddressCountry,
        deliveryAddressEmail: values.deliveryAddressEmail,
        deliveryAddressTelephone: values.deliveryAddressTelephone,
      });
      toastSuccess(
        t(
          'components.PartnerProspects.PartnerProspectsList.PartnerProspectCreateModal.success',
        ),
      );
      reset();
      onFinish?.();
    } catch (err: any) {
      if (err.code) {
        return toastError(t(`errorsApi.${err.code}`));
      }
      toastError(
        t(
          'components.PartnerProspects.PartnerProspectsList.PartnerProspectCreateModal.error',
        ),
      );
    }
  };

  const handleCancel = () => {
    reset();
    onCancel();
  };

  return (
    <PartnerProspectsModal
      title={t(
        'components.PartnerProspects.PartnerProspectsList.PartnerProspectCreateModal.title',
      )}
      icon={<HomeIcon />}
      show={show}
      handleClose={handleCancel}
    >
      <FormProvider {...createMethods}>
        <PartnerProspectCreateForm
          onSubmit={onSubmit}
          onCancel={handleCancel}
        />
      </FormProvider>
    </PartnerProspectsModal>
  );
};

export default PartnerProspectsCreateModal;
