import { AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import request from 'src/axios';

const useDeleteBillingConfigurationMutation = () => {
  const queryClient = useQueryClient();

  const deleteBillingConfiguration = useMutation<
    AxiosResponse<any>,
    Error,
    { id: number }
  >(
    ({ id }) => {
      return request.delete(`/billing-configurations/${id}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
      },
    },
  );
  return { deleteBillingConfiguration };
};

export default useDeleteBillingConfigurationMutation;
