import { add, compareAsc } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';
import useFetchNursingHomeQuery from 'src/components/NursingHome/hooks/useFetchNursingHomeQuery';
import useFetchSubscription from 'src/components/Subscription/hooks/useFetchSubscriptionQuery';
import SubscriptionCreateEquipments from 'src/components/Subscription/SubscriptionUpsert/SubscriptionCreate/SubscriptionCreateEquipments';
import SubscriptionCreatePlan from 'src/components/Subscription/SubscriptionUpsert/SubscriptionCreate/SubscriptionCreatePlan';
import SubscriptionCreateSummary from 'src/components/Subscription/SubscriptionUpsert/SubscriptionCreate/SubscriptionCreateSummary';
import { CardWrapper } from 'src/components/Subscription/SubscriptionUpsert/SubscriptionUpdate/SubscriptionUpdatePlan';
import { useAuth } from 'src/contexts/Auth';
import { usePartner } from 'src/contexts/Partner';
import Routes from 'src/router/Routes';
import { DeliveryAddress } from 'src/sdk/nursingHome';
import { SubscriptionCreationRequest } from 'src/sdk/subscription';
import Stepper from 'src/uikit/Stepper';
import { H1 } from 'src/uikit/Typography';
import { getStartingDate } from 'src/utils/getDate';

import { SubscriptionUpsertProvider } from '../components/Subscription/SubscriptionUpsert/SubscriptionContext';

export enum SubscriptionCreationStep {
  ChoosSubscriptionPlan,
  ChooseEquipment,
  Summary,
}

const SubscriptionCreation = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { nhSession } = useAuth();
  const { partner } = usePartner();
  const [currentStep = 0, setCurrentStep] = useState<number>();

  const { data: nh } = useFetchNursingHomeQuery({
    id: nhSession?.id || -1,
    expand: [
      'address',
      'delivery-addresses',
      'gateways',
      'charging-stations',
      'clipons',
    ],
  });

  const { selectedData, creationData, isLoading } = useFetchSubscription(
    nhSession?.id || -1,
  );

  const [data, setData] = useState<SubscriptionCreationRequest>();

  const setDataAndIncrementStep = useCallback(
    (newData: Partial<SubscriptionCreationRequest>) => {
      if (data) {
        setData({ ...data, ...newData });
        setCurrentStep(currentStep + 1);
      }
    },
    [currentStep, data],
  );

  const decrementStep = useCallback(() => {
    setCurrentStep(currentStep - 1);
  }, [currentStep]);

  const handleBackToConfig = useCallback(() => {
    if (nhSession?.id) {
      history.push(generatePath(Routes.NURSING_CONFIG, { id: nhSession?.id }));
    }
  }, [history, nhSession?.id]);

  const onCreationSuccess = useCallback(() => {
    if (nhSession?.id) {
      history.push(
        generatePath(Routes.NURSING_SUBSCRIPTION_HISTORY, {
          id: nhSession?.id,
        }),
      );
    }
  }, [history, nhSession?.id]);

  const getDefaultValues = useCallback(() => {
    const defaultValue = {
      type: 'STANDARD' as SubscriptionCreationRequest['type'],
      startDate: getStartingDate(),
      duration: 2,
      automaticRenewal: true,
      residentEstimate: 20,
      deliveryAddressId: null,
      address: {} as DeliveryAddress,
      requestedDeliveryDate: getStartingDate(),
      extraChargingStations: 0,
      extraGateways: 0,
      noShippingRequest: false,
      nursingHomeId: nhSession?.id || -1,
    };

    if (!selectedData) return defaultValue;

    const isCurrent =
      compareAsc(
        add(new Date(selectedData?.startDate), {
          months: selectedData?.duration,
        }),
        new Date(),
      ) === 1;

    if (selectedData.type === 'DEMO' && !isCurrent) return defaultValue;

    if (selectedData.type === 'DEMO') {
      return {
        ...selectedData,
        extraChargingStations: 0,
        extraGateways: 0,
        duration: 1,
      };
    }

    return selectedData;
  }, [nhSession?.id, selectedData]);

  useEffect(() => {
    const values = getDefaultValues();

    setData(values);
  }, [nhSession?.id, getDefaultValues]);

  const deliveryAddresses: DeliveryAddress[] = useMemo(() => {
    const list = [
      ...(nh?.deliveryAddresses || []),
      ...(partner?.deliveryAddresses?.map((deliveryAddress) => ({
        ...deliveryAddress,
        isPartner: true,
      })) || []),
    ];

    return Object.values(
      list.reduce<{ [key: string]: DeliveryAddress }>(
        (accumulator, currentAddress) => {
          const { shipTo } = currentAddress;
          return {
            ...accumulator,
            [shipTo!]: accumulator[shipTo!] || currentAddress,
          };
        },
        {},
      ),
    );
  }, [nh?.deliveryAddresses, partner?.deliveryAddresses]);

  if (!data || isLoading) {
    return <></>;
  }

  return (
    <CardWrapper>
      <H1 className="mx-auto">{t('components.subscriptionCreation.title')}</H1>
      <SubscriptionUpsertProvider
        defaultType={data.type}
        configurations={creationData?.typeConfigurations}
      >
        <Stepper
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          hideStepLabel
          stepperData={[
            {
              label: t('pages.creatingSubscription.firstStep'),
              name: t('pages.creatingSubscription.firstStep'),
              content: (
                <SubscriptionCreatePlan
                  data={data}
                  onSuccess={setDataAndIncrementStep}
                  onCancel={handleBackToConfig}
                />
              ),
            },
            ...(!data.noShippingRequest
              ? [
                  {
                    label: t('pages.creatingSubscription.secondStep'),
                    name: t('pages.creatingSubscription.secondStep'),
                    content: (
                      <SubscriptionCreateEquipments
                        data={data}
                        onSuccess={setDataAndIncrementStep}
                        onCancel={decrementStep}
                        deliveryAddresses={deliveryAddresses || []}
                      />
                    ),
                  },
                ]
              : []),
            {
              label: t('pages.creatingSubscription.thirdStep'),
              name: t('pages.creatingSubscription.thirdStep'),
              content: (
                <SubscriptionCreateSummary
                  data={data}
                  setCurrentStep={setCurrentStep}
                  onSuccess={onCreationSuccess}
                  nh={nh}
                  deliveryAddresses={deliveryAddresses || []}
                />
              ),
            },
          ]}
        />
      </SubscriptionUpsertProvider>
    </CardWrapper>
  );
};

export default SubscriptionCreation;
