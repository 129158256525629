import { useEffect } from 'react';
import { Col } from 'react-bootstrap';
import NursingHomeList from 'src/components/NursingHome/List/NursingHomeList';
import { useAuth } from 'src/contexts/Auth';

const NursingHomes = () => {
  const { removeNhSession } = useAuth();

  useEffect(() => {
    removeNhSession?.();
  }, [removeNhSession]);

  return (
    <Col>
      <NursingHomeList />
    </Col>
  );
};

export default NursingHomes;
