import { useEffect, useState, useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory, generatePath } from 'react-router-dom';
import { ReactComponent as PartnersIcon } from 'src/assets/partners.svg';
import request from 'src/axios';
import { useAuth } from 'src/contexts/Auth';
import useLocalDate from 'src/hooks/useLocalDate';
import Routes from 'src/router/Routes';
import { Profiles } from 'src/sdk/authentification';
import { UserResponse } from 'src/sdk/users';
import { Card, CardBody } from 'src/uikit/Card';
import { Subtext, Text } from 'src/uikit/Typography';
import styled from 'styled-components/macro';

import UserStatusBadge from '../UserStatusBadge';

interface Props {
  user: UserResponse;
}

const Avatar = styled.img`
  width: 40px;
  aspect-ratio: 1/1;
`;

const StyledColIcon = styled(Col)`
  flex-basis: 75px;
  flex-grow: initial;
  align-items: center;
  display: flex;
`;

const StyledCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`;

const StyledColName = styled(Col)`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
`;

const Fullname = styled(Text)`
  font-weight: 700;
`;

const Email = styled(Subtext)``;

const WrapperIcon = styled.div`
  color: ${({ theme }) => theme.palette.main.primary};
`;

const UserRow = ({ user }: Props) => {
  const { authUser } = useAuth();

  const { t } = useTranslation();
  const [avatar, setAvatar] = useState('');
  const { stringToDate } = useLocalDate();

  const history = useHistory();

  const loadImage = useCallback(async () => {
    if (user.avatarImagePath) {
      const response = await request.get(
        user.avatarImagePath.replace('/api/v1', ''),
        { responseType: 'blob' },
      );

      setAvatar(URL.createObjectURL(response.data));
    }
  }, [user.avatarImagePath]);

  useEffect(() => {
    loadImage();
  }, [loadImage]);

  const handleclick = (): void => {
    history.push(
      generatePath(Routes.USERS_DETAIL, {
        id: user.id,
      }),
    );
  };

  return (
    <Card key={user.id} onClick={handleclick} shadow="sm" $clickable>
      <CardBody>
        <Row>
          <StyledColIcon>
            <Avatar src={avatar} />
          </StyledColIcon>
          <StyledColName xs="10" md="4">
            <Fullname as="span">
              {user.firstName} {user.lastName}
            </Fullname>
            <Email>{user.email}</Email>
          </StyledColName>
          <StyledCol xs="6" md="2">
            {authUser?.profile !== Profiles.PARTNER_ADMIN &&
              user.profile === Profiles.PARTNER_ADMIN && (
                <WrapperIcon>
                  <PartnersIcon />
                </WrapperIcon>
              )}
            {t(`components.Users.UsersList.UserCard.profile.${user.profile}`)}
          </StyledCol>
          <StyledCol xs="3" md="2" className="text-end">
            {user.employeeId}
          </StyledCol>
          <StyledCol xs="3" md="2">
            {stringToDate(user.createdDate)}
          </StyledCol>

          <StyledCol md="1" className="ml-auto justify-content-end">
            <UserStatusBadge status={user.accountStatus} />
          </StyledCol>
        </Row>
      </CardBody>
    </Card>
  );
};

export default UserRow;
