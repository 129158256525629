import { Col, Row, Stack } from 'react-bootstrap';
import { COUNTRY_FLAGS } from 'src/assets/flags';
import { ReactComponent as HomeIcon } from 'src/assets/home.svg';
import HoverCard from 'src/components/Misc/Card/HoverCard';
import { PartnerProspect } from 'src/sdk/partner-prospect';
import { Text, Detail } from 'src/uikit/Typography';
import styled from 'styled-components/macro';

const Name = styled(Text)`
  color: ${({ theme }) => theme.palette.black[90]};
  font-weight: 500;
`;

const Identifier = styled(Detail)`
  color: ${({ theme }) => theme.palette.black[90]};
`;

const WrapperIcon = styled.div`
  min-width: 42px;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: cemter;
  padding: 0.5rem;
  border-radius: 50%;
`;

const WrapperNameIcon = styled(WrapperIcon)`
  color: ${({ theme }) => theme.palette.main.primary};
  border: 1px solid ${({ theme }) => theme.palette.main.primary};
`;

type Props = {
  type: 'available' | 'owned';
  prospect: PartnerProspect;
  onClick: (nursingHome: PartnerProspect) => void;
};

const UserProspectCard = ({ prospect, onClick, type }: Props) => {
  return (
    <HoverCard
      type={type === 'owned' ? 'delete' : 'add'}
      onClick={() => onClick(prospect)}
    >
      <Row>
        <Col xs="11">
          <Stack gap={1}>
            <Stack direction="horizontal" gap={2}>
              <WrapperNameIcon>
                <HomeIcon />
              </WrapperNameIcon>
              <Name>{prospect.name}</Name>
            </Stack>
            <Stack direction="horizontal" gap={2}>
              <WrapperIcon>
                {COUNTRY_FLAGS[String(prospect.language) || 'fr']}
              </WrapperIcon>
              <Identifier>{prospect.identifier || 'XXXXX'}</Identifier>
            </Stack>
          </Stack>
        </Col>
      </Row>
    </HoverCard>
  );
};

export default UserProspectCard;
