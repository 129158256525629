import { differenceInMonths, format } from 'date-fns';
import { useMemo } from 'react';
import { Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ChevronRight } from 'src/assets/arrows/index';
import { DATE_BACKEND } from 'src/constants';
import {
  GetSubscriptionCreationData,
  SubscriptionCreationRequest,
} from 'src/sdk/subscription';
import { Button, ButtonColors } from 'src/uikit/Button';
import { Card } from 'src/uikit/Card';
import { Label } from 'src/uikit/Typography';
import { getStartingDate } from 'src/utils/getDate';
import styled from 'styled-components/macro';

import { CurrentEquipments } from '../components/SubscriptionEquipmentForm';
import SubscriptionUpdatePlanForm, {
  SubscriptionUpdatePlanFormSchema,
} from '../components/SubscriptionUpdatePlanForm';

export const CardWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  border-radius: 10px;
`;

const ButtonSubmit = styled(Button)`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  svg {
    width: 12px;
  }
`;

const SafeArea = styled.div`
  height: 2rem;
`;

interface Props {
  data: SubscriptionCreationRequest;
  onSuccess: (data: Partial<SubscriptionCreationRequest>) => void;
  onCancel: () => void;
  creationData: GetSubscriptionCreationData;
  currentEquipments?: CurrentEquipments;
}

const SubscriptionUpdatePlan = ({
  data,
  onSuccess,
  onCancel,
  currentEquipments,
  creationData,
}: Props) => {
  const { t } = useTranslation();

  const onSubmit = (values: SubscriptionUpdatePlanFormSchema) => {
    onSuccess({
      startDate: values.startDate,
      duration: differenceInMonths(
        new Date(values.endDate),
        new Date(values.startDate),
      ),
      residentEstimate: values.residentEstimate,
      type: values.type,
      extraChargingStations: values.chargingStations,
      extraGateways: values.gateways,
    });
  };

  const defaultValues: SubscriptionUpdatePlanFormSchema = useMemo(() => {
    const { residentEstimate, type } = data;
    const startDate = getStartingDate();
    const endDate = format(
      new Date(
        creationData.secondarySubscription?.endDate ||
          creationData.mainSubscription?.endDate ||
          '',
      ),
      DATE_BACKEND,
    );

    return {
      residentEstimate: residentEstimate,
      startDate,
      endDate,
      type: type,
      chargingStations:
        currentEquipments?.chargingStations ||
        0 + (data.extraChargingStations || 0) ||
        0,
      gateways:
        currentEquipments?.gateways || 0 + (data.extraGateways || 0) || 0,
      clipons: currentEquipments?.clipons || 0,
    };
  }, [
    creationData.mainSubscription?.endDate,
    creationData.secondarySubscription?.endDate,
    currentEquipments?.chargingStations,
    currentEquipments?.clipons,
    currentEquipments?.gateways,
    data,
  ]);

  return (
    <>
      <CardWrapper>
        <Label>
          {t('components.SubscriptionUpsert.SubscriptionUpdatePlan.title')}
        </Label>
        <SubscriptionUpdatePlanForm
          data={data}
          defaultValues={defaultValues}
          creationData={creationData}
          onSubmit={onSubmit}
          currentEquipments={currentEquipments}
        />
        <SafeArea />
      </CardWrapper>
      <Stack direction="horizontal" className="justify-content-end">
        <Button background={ButtonColors.CANCEL} onClick={onCancel}>
          {t('components.SubscriptionUpsert.SubscriptionUpdatePlan.cancel')}
        </Button>
        <ButtonSubmit form="subscription-update-form" type="submit">
          {t('components.SubscriptionUpsert.SubscriptionUpdatePlan.next')}
          <ChevronRight />
        </ButtonSubmit>
      </Stack>
    </>
  );
};

export default SubscriptionUpdatePlan;
