import { useCallback } from 'react';
import { useQuery } from 'react-query';
import request from 'src/axios';
import { Countries } from 'src/sdk/subscription';

const useFetchCountries = () => {
  const fetchCountries = useCallback(
    () => request.get('/countries').then((response) => response.data),
    [],
  );

  const { data, isLoading } = useQuery<Countries>('countries', fetchCountries);

  return { countries: data, isLoading };
};

export default useFetchCountries;
