const getNestedProperty = (object: any, path: string) => {
  // src: https://stackoverflow.com/questions/6491463/accessing-nested-javascript-objects-and-arrays-by-string-path
  path = path.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  path = path.replace(/^\./, '');           // strip a leading dot
  var a = path.split('.');
  a.forEach((nested) => {
    if (nested in object) {
      object = object[nested];
    } else {
      return;
    }
  })
  return object;
}

export default getNestedProperty;
