import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useParams } from 'react-router-dom';
import Routes from 'src/router/Routes';
import { RouteParams } from 'src/sdk/nursingHome';
import HorizontalMenu, { HorizontalMenuItems } from 'src/uikit/HorizontalMenu';

import { usePartner } from '../../../contexts/Partner';

const NursingHomeConfigurationHorizontalMenu = () => {
  const { t } = useTranslation();
  const { id } = useParams<RouteParams>();

  const { partner } = usePartner();

  const MenuItem: HorizontalMenuItems[] = useMemo(() => {
    return [
      {
        routes: generatePath(Routes.NURSING_CONFIG_GENERAL, { id }),
        label: t('configuration.generalSettings'),
      },
      {
        routes: generatePath(Routes.NURSING_CONFIG_ADDRESSES, { id }),
        label: t('configuration.addresses'),
      },
      ...(!partner?.id
        ? [
            {
              routes: generatePath(Routes.NURSING_CONFIG_BILLINGS, { id }),
              label: t('configuration.billings'),
            },
          ]
        : []),
      {
        routes: generatePath(Routes.NURSING_CONFIG_PRODUCTS, { id }),
        label: t('configuration.products'),
      },
      {
        routes: generatePath(Routes.NURSING_CONFIG_MANAGE_USERS, { id }),
        label: t('configuration.manage'),
      },
    ];
  }, [id, t, partner?.id]);

  return <HorizontalMenu menuItems={MenuItem} />;
};

export default NursingHomeConfigurationHorizontalMenu;
