import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'src/contexts/Auth';
import { Profiles } from 'src/sdk/authentification';
import { UserResponse } from 'src/sdk/users';

import UserAsyncSelect from '../Users/UserAsyncSelect';

interface Props {
  onChangeFilterValue: (filterValue?: UserResponse) => void;
}

const FilterProductSpecialist = ({ onChangeFilterValue }: Props) => {
  const { t } = useTranslation();
  const [filterValue, setFilterValue] = useState<UserResponse>();

  const { authUser } = useAuth();

  // Changing filterValue state if new option is chosen
  useEffect(() => {
    onChangeFilterValue(filterValue);
  }, [onChangeFilterValue, filterValue]);

  return (
    <UserAsyncSelect
      placeholder={t('components.filterInput.productSpecialistPlaceholder')}
      value={filterValue}
      onChange={(user: UserResponse) => setFilterValue(user)}
      ontexAndPaOnly={!Boolean(authUser?.partnerUser)}
      profile={Profiles.PRODUCT_SPECIALIST}
    />
  );
};

export default FilterProductSpecialist;
