import { AxiosResponse, AxiosRequestConfig } from 'axios';
import { useMutation } from 'react-query';
import request from 'src/axios';
import { UserVerifyEmailRequest } from 'src/sdk/users';

const useVerifyEmailMutation = () => {
  const { mutateAsync, isLoading } = useMutation<
    AxiosResponse<any>,
    Error,
    {
      body: UserVerifyEmailRequest;
      config?: AxiosRequestConfig;
    }
  >(({ body, config }) => request.post('/users/verify-email', body, config));

  return { startPasswordResetMutation: mutateAsync, isLoading };
};

export default useVerifyEmailMutation;
