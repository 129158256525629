import { useState } from 'react';
import { useHistory, generatePath } from 'react-router-dom';
import { useModal } from 'src/hooks/useModal';
import Routes from 'src/router/Routes';
import { PartnerProspect } from 'src/sdk/partner-prospect';
import { List } from 'src/uikit/Layout/List';

import PartnerProspectCard from './PartnerProspectCard';
import PartnerProspectsDeleteModal from './PartnerProspectDeleteModal';

interface Props {
  partners?: PartnerProspect[];
}

const PartnerProspectsList = ({ partners }: Props) => {
  const [currentPartnerProspects, setCurrentPartnerProspects] =
    useState<PartnerProspect | null>(null);
  const { modalOpen: modalOpenDelete, setModalOpen: setModalOpenDelete } =
    useModal();

  const history = useHistory();

  const onClickDelete = (partnerProspect: PartnerProspect) => {
    setCurrentPartnerProspects(partnerProspect);
    setModalOpenDelete(true);
  };

  const onClickUpdate = (partnerProspect: PartnerProspect) => {
    history.push(
      generatePath(Routes.PARTNERS_PROSPECT, {
        id: partnerProspect.id,
      }),
    );
  };

  const onFinish = () => {
    setCurrentPartnerProspects(null);
    setModalOpenDelete(false);
  };

  return (
    <List className="mt-4">
      {partners?.map((partnerProspect) => (
        <PartnerProspectCard
          key={partnerProspect.id}
          partnerProspect={partnerProspect}
          onClickDelete={onClickDelete}
          onClickUpdate={onClickUpdate}
        />
      ))}
      {currentPartnerProspects && (
        <>
          <PartnerProspectsDeleteModal
            partnerProspect={currentPartnerProspects}
            onFinish={onFinish}
            show={modalOpenDelete}
          />
        </>
      )}
    </List>
  );
};

export default PartnerProspectsList;
