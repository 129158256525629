import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import SortingButton from 'src/components/SortingHeaders/SortingButton';
import { OrdersSortField } from 'src/pages/Ordering';
import { Orders } from 'src/sdk/order';
import { sortByDirection } from 'src/utils/sortByDirection';
import styled from 'styled-components/macro';

const StyledRow = styled(Row)`
  padding-left: 10px;
  padding-right: 10px;
`;

const StyledCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface Props {
  onSortingChange: React.Dispatch<React.SetStateAction<OrdersSortField>>;
  sortField: OrdersSortField;
}

const SortingOrderingHistoryHeader = ({
  onSortingChange,
  sortField,
}: Props) => {
  const { t } = useTranslation();

  const handleSortClick = (label: keyof Orders) => {
    sortByDirection(label, sortField, onSortingChange);
  };

  return (
    <StyledRow>
      <StyledCol md={{ span: 2, offset: 1 }}>
        <SortingButton
          primary
          label={t('components.sortingHeader.sortByDate')}
          field="sapCreationDate"
          onClick={() => handleSortClick('sapCreationDate')}
        />
      </StyledCol>
      <StyledCol md="2">
        <SortingButton
          primary
          label={t('components.sortingHeader.sortByPONumber')}
          field="poNumber"
          disableSort
        />
      </StyledCol>
      <StyledCol md="2">
        <SortingButton
          primary
          label={t('components.sortingHeader.sortByItems')}
          field="items"
          disableSort
        />
      </StyledCol>
      <StyledCol md="2">
        <SortingButton
          primary
          label={t('components.sortingHeader.sortByVendorName')}
          field="vendorName"
          disableSort
        />
      </StyledCol>
    </StyledRow>
  );
};

export default SortingOrderingHistoryHeader;
