import { Container } from 'react-bootstrap';
import {
  generatePath,
  Redirect,
  Switch,
  useLocation,
  useParams,
} from 'react-router-dom';
import PartnerProspectAddresses from 'src/components/PartnerProspects/PartnerProspectsDetails/PartnerProspectAddresses';
import PartnerProspectHorizontalMenu from 'src/components/PartnerProspects/PartnerProspectsDetails/PartnerProspectHorizontalMenu';
import PartnerProspectSpecialist from 'src/components/PartnerProspects/PartnerProspectsDetails/PartnerProspectSpecialist';
import PrivateRoute from 'src/router/PrivateRoute';
import Routes from 'src/router/Routes';
import { RouteParams } from 'src/sdk/nursingHome';

import useFetchPartnerProspectQuery from '../components/PartnerProspects/hooks/useFetchPartnerProspectQuery';
import PartnerProspectGeneral from '../components/PartnerProspects/PartnerProspectsDetails/PartnerProspectGeneral/index';

const PartnerProspect = () => {
  const { id } = useParams<RouteParams>();
  const location = useLocation();

  const { data, isLoading } = useFetchPartnerProspectQuery(id, [
    'delivery-addresses',
    'delivery-addresses-shipping-request-count',
    'address',
  ]);

  if (location.pathname === generatePath(Routes.PARTNERS_PROSPECT, { id })) {
    return (
      <Redirect to={generatePath(Routes.PARTNERS_PROSPECT_GENERAL, { id })} />
    );
  }

  return (
    <Container fluid className="p-0">
      <PartnerProspectHorizontalMenu />
      <Container fluid>
        {!isLoading ? (
          data && (
            <Switch>
              <PrivateRoute path={Routes.PARTNERS_PROSPECT_GENERAL}>
                <PartnerProspectGeneral data={data} />
              </PrivateRoute>
              <PrivateRoute path={Routes.PARTNERS_PROSPECT_DELIVERY_ADDRESSES}>
                <PartnerProspectAddresses data={data} />
              </PrivateRoute>
              <PrivateRoute path={Routes.PARTNERS_PROSPECT_ASSIGNED_PS}>
                <PartnerProspectSpecialist partnerProspect={data} />
              </PrivateRoute>
            </Switch>
          )
        ) : (
          <></>
        )}
      </Container>
    </Container>
  );
};

export default PartnerProspect;
