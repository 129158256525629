import { AxiosResponse } from 'axios';
import { useMutation } from 'react-query';
import request from 'src/axios';
import { UpdateUserProfileRequest } from 'src/sdk/users';

const useUpdateUserProfileMutation = () => {
  const updateUserProfileMutation = useMutation<
    AxiosResponse,
    Error,
    UpdateUserProfileRequest & {
      id: number;
    }
  >(({ id, ...params }) => request.put(`/users/${id}/profile`, params));

  return { updateUserProfileMutation };
};

export default useUpdateUserProfileMutation;
