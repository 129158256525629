import { Col } from 'react-bootstrap';
import ComingSoonCard from 'src/components/Misc/Card/ComingSoonCard';
import { H1 } from 'src/uikit/Typography';
import styled from 'styled-components/macro';

const Title = styled(H1)`
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  margin: 30px;
`;

const HardwareDashboard = () => {
  return (
    <Col>
      <Title>Hardware Dashboard</Title>
      <ComingSoonCard />
    </Col>
  );
};

export default HardwareDashboard;
