import { UpAndDown } from 'src/assets/arrows';
import styled from 'styled-components/macro';

const StyledButton = styled('button')<{
  cursor: string;
}>`
  border: none;
  background-color: inherit;
  display: flex;
  align-items: center;
  cursor: ${(props) => props.cursor} !important;
  padding-inline: 0;
`;

interface Props {
  $isPrimary?: boolean;
}

const SortingText = styled.span<Props>`
  color: ${({ $isPrimary, theme }) =>
    $isPrimary ? theme.palette.main.primary : theme.palette.black[80]};
  letter-spacing: 0.8px;
  margin: 0;
  font-weight: 600;
`;

const SortingIcon = styled(UpAndDown)<Props>`
  color: ${({ $isPrimary, theme }) =>
    $isPrimary ? theme.palette.main.primary : theme.palette.black[80]};
`;

interface SortingButtonProps {
  primary?: boolean;
  label: string;
  field: string | string[];
  onClick?: (field: string | string[]) => void;
  disableSort?: boolean;
  type?: 'button' | 'submit' | 'reset';
}

const SortingButton = ({
  field,
  label,
  primary,
  onClick,
  disableSort = false,
  type
}: SortingButtonProps) => {
  return (
    <StyledButton
      onClick={() => onClick?.(field)}
      cursor={disableSort ? 'auto' : 'pointer'}
      type={type}
    >
      <SortingText $isPrimary={primary}>{label}</SortingText>
      {!disableSort && <SortingIcon $isPrimary={primary} />}
    </StyledButton>
  );
};

export default SortingButton;
