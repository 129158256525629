import { Container } from 'react-bootstrap';
import { generatePath, Redirect, Switch, useLocation } from 'react-router-dom';
import Navigation from 'src/components/Header/Navigation';
import SidebarMenu from 'src/components/Sidebars/SidebarMenu';
import { useAuth } from 'src/contexts/Auth';
import ErrorPage from 'src/pages/ErrorPage';
import MainDashboard from 'src/pages/MainDashboard';
import NursingHomeCreation from 'src/pages/NursingHomeCreation';
import NursingHomeCreationOntex from 'src/pages/NursingHomeCreationOntex';
import NursingHomeCreationPartner from 'src/pages/NursingHomeCreationPartner';
import NursingHomes from 'src/pages/NursingHomes';
import Profile from 'src/pages/Profile';
import AdminLayout from 'src/router/MainLayout/AdminLayout';
import styled from 'styled-components/macro';

import NursingHomeSubmenuLayout from './MainLayout/NursingHomeLayout';
import PrivateRoute from './PrivateRoute';
import Routes from './Routes';

const Root = styled.div`
  max-width: 1920px;
`;

const Content = styled(Container)`
  max-width: 1920px;
  display: grid;
  grid-template-columns: auto 1fr;
  background-color: ${({ theme }) => theme.palette.black.lightGrey};
  height: calc(100% - 70px);
  padding: 0;
`;

const Body = styled.div`
  width: 100%;
  padding-block: 1rem;
`;

const MainLayout = () => {
  const location = useLocation();
  const { nhSession } = useAuth();

  return location.pathname === Routes.HOME ? (
    nhSession ? (
      <Redirect
        to={generatePath(Routes.NURSING_GENERAL, { id: nhSession?.id })}
      />
    ) : (
      <Redirect to={Routes.NURSINGS} />
    )
  ) : (
    <Root>
      <Navigation />
      <Content fluid>
        <SidebarMenu />
        <Body>
          <Switch>
            <PrivateRoute
              path={Routes.MAIN_DASHBOARD}
              component={MainDashboard}
            />
            <PrivateRoute path={Routes.ERROR} component={ErrorPage} />
            <PrivateRoute
              exact
              path={Routes.NURSINGS}
              component={NursingHomes}
            />

            <PrivateRoute
              path={Routes.NURSING_CREATION}
              component={NursingHomeCreation}
              exact
            />

            <PrivateRoute
              path={Routes.NURSING_CREATION_ONTEX}
              component={NursingHomeCreationOntex}
              exact
            />

            <PrivateRoute
              path={Routes.NURSING_CREATION_PARTNER}
              component={NursingHomeCreationPartner}
              exact
            />

            <PrivateRoute
              path={Routes.NURSING}
              component={NursingHomeSubmenuLayout}
            />

            <PrivateRoute path={Routes.PROFILE} component={Profile} />
            <AdminLayout />
          </Switch>
        </Body>
      </Content>
    </Root>
  );
};
export default MainLayout;
