import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import SortingButton from 'src/components/SortingHeaders/SortingButton';
import { Partner } from 'src/sdk/partner';
import { sortByDirection } from 'src/utils/sortByDirection';
import styled from 'styled-components/macro';

import { PartnersSortField } from '../Partners/hooks/useFetchPartnersQuery';

const StyledRow = styled(Row)`
  padding-left: 10px;
  padding-right: 10px;
`;

const StyledCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledColIcon = styled(StyledCol)`
  flex: 0 0 50px;
`;

interface Props {
  onSortingChange: React.Dispatch<React.SetStateAction<PartnersSortField>>;
  sortField: PartnersSortField;
}

const SortingPartnersHeader = ({ onSortingChange, sortField }: Props) => {
  const { t } = useTranslation();

  const handleSortClick = (label: keyof Partner) => {
    sortByDirection(label, sortField, onSortingChange);
  };

  return (
    <StyledRow>
      <StyledColIcon />
      <StyledCol md={{ span: 3 }}>
        <SortingButton
          primary
          label={t('components.sortingHeader.sortByName')}
          field="name"
          onClick={() => handleSortClick('name')}
        />
      </StyledCol>
      <StyledCol md="2">
        <SortingButton
          primary
          label={t('components.sortingHeader.sortBySapIdentifer')}
          field="sapIdentifier"
          disableSort
        />
      </StyledCol>
      <StyledCol md="2">
        <SortingButton
          primary
          label={t('components.sortingHeader.sortByProspects')}
          field="prospects"
          disableSort
        />
      </StyledCol>
      <StyledCol md="2">
        <SortingButton
          primary
          label={t('components.sortingHeader.sortBySubscriptions')}
          field="subscriptions"
          disableSort
        />
      </StyledCol>
      <StyledCol md="1">
        <SortingButton
          primary
          label={t('components.sortingHeader.sortByUsers')}
          field="users"
          disableSort
        />
      </StyledCol>
    </StyledRow>
  );
};

export default SortingPartnersHeader;
