import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';
import { ReactComponent as NursingEquipmentIcon } from 'src/assets/clipons.svg';
import { ReactComponent as DashboardIcon } from 'src/assets/dashboard.svg';
import { ReactComponent as NursingGeneralIcon } from 'src/assets/nursing-general.svg';
import { ReactComponent as NursingListIcon } from 'src/assets/nursing-list-icon.svg';
import { ReactComponent as NursingIcon } from 'src/assets/nursinghome.svg';
import { ReactComponent as SettingsIcon } from 'src/assets/settings.svg';
import { ReactComponent as SubscriptionIcon } from 'src/assets/subscription-icon.svg';
import { Tenant } from 'src/contexts/Auth';
import { usePartner } from 'src/contexts/Partner';
import Routes from 'src/router/Routes';
import { Button } from 'src/uikit/Button';
import { H3 } from 'src/uikit/Typography';
import styled from 'styled-components/macro';

import SidebarItem from '../SidebarItem';
import SidebarSubitem from '../SidebarSubitem';

const MainTitle = styled(H3)`
  color: ${({ theme }) => theme.palette.black.strongGrey};
  margin: 1rem 0.5rem;
  text-transform: uppercase;
  display: flex;
  gap: 10px;
  justify-content: left;
  align-items: center;
`;

const NursingLabel = styled.label`
  margin: 0;
  width: 100%;
`;

const NHTitle = styled.div<{ $opened: boolean }>`
  display: flex;
  gap: 12px;
  color: ${({ theme }) => theme.palette.black.strongGrey};
  background-color: ${({ theme }) => theme.palette.main.lightBlue};
  padding: 14px 14px 14px ${({ $opened }) => ($opened ? '18px' : '14px')};
  margin-block: 1rem;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  justify-content: flex-start;
`;

const NHButton = styled(Button)<{ $opened: boolean }>`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  justify-content: center;
  padding: ${({ $opened }) => ($opened ? '0.5rem' : '0.5rem')};
`;

export type SidebarMenuTopProps = {
  nh?: Tenant;
  nhSession?: Tenant;
  menuOpen: boolean;
  handleClickButton: () => void;
};

const SidebarMenuTop = ({
  nh,
  nhSession,
  menuOpen,
  handleClickButton,
}: SidebarMenuTopProps) => {
  const { t } = useTranslation();
  const { partner } = usePartner();

  return (
    <>
      {nhSession ? (
        <>
          <NHButton onClick={handleClickButton}>
            {menuOpen ? '< ' + t('pages.nursingHome.backToListButton') : '<'}
          </NHButton>
          <NHTitle $opened={menuOpen}>
            <NursingIcon width={24} height={24} />
            {menuOpen && <NursingLabel>{nhSession?.name}</NursingLabel>}
          </NHTitle>
          {nh?.subscription && (
            <SidebarItem
              label={t('components.nursinghomeSubmenu.linkToDashboard')}
              path={generatePath(Routes.NURSING_DASHBOARD, {
                id: nhSession.id,
              })}
              icon={<DashboardIcon />}
              opened={menuOpen}
            />
          )}
          <SidebarItem
            label={t('components.nursinghomeSubmenu.linkToGeneral')}
            path={generatePath(Routes.NURSING_GENERAL, {
              id: nhSession.id,
            })}
            icon={<NursingGeneralIcon />}
            opened={menuOpen}
          />
          <SidebarItem
            label={t('components.nursinghomeSubmenu.linkToConfig')}
            path={generatePath(Routes.NURSING_CONFIG, {
              id: nhSession.id,
            })}
            icon={<SettingsIcon />}
            opened={menuOpen}
          >
            <SidebarSubitem
              label={t('components.nursinghomeSubmenu.linkToConfigGeneral')}
              path={generatePath(Routes.NURSING_CONFIG_GENERAL, {
                id: nhSession.id,
              })}
              opened={menuOpen}
            />
            <SidebarSubitem
              label={t('components.nursinghomeSubmenu.linkToConfigAddresses')}
              path={generatePath(Routes.NURSING_CONFIG_ADDRESSES, {
                id: nhSession.id,
              })}
              opened={menuOpen}
            />
            {!partner?.id && (
              <SidebarSubitem
                label={t('components.nursinghomeSubmenu.linkToConfigBilling')}
                path={generatePath(Routes.NURSING_CONFIG_BILLINGS, {
                  id: nhSession.id,
                })}
                opened={menuOpen}
              />
            )}
            <SidebarSubitem
              label={t('components.nursinghomeSubmenu.linkToConfigProducts')}
              path={generatePath(Routes.NURSING_CONFIG_PRODUCTS, {
                id: nhSession.id,
              })}
              opened={menuOpen}
            />
            <SidebarSubitem
              label={t('components.nursinghomeSubmenu.linkToConfigManageUsers')}
              path={generatePath(Routes.NURSING_CONFIG_MANAGE_USERS, {
                id: nhSession.id,
              })}
              opened={menuOpen}
            />
          </SidebarItem>
          <SidebarItem
            label={t('components.nursinghomeSubmenu.linkToSubscription')}
            path={generatePath(Routes.NURSING_SUBSCRIPTION, {
              id: nhSession.id,
            })}
            icon={<SubscriptionIcon />}
            opened={menuOpen}
          >
            <SidebarSubitem
              label={t('components.subscriptionSubmenu.linkToHistory')}
              path={generatePath(Routes.NURSING_SUBSCRIPTION_HISTORY, {
                id: nhSession.id,
              })}
              opened={menuOpen}
            />
            <SidebarSubitem
              label={t('components.subscriptionSubmenu.linkToBilling')}
              path={generatePath(Routes.NURSING_SUBSCRIPTION_BILLING, {
                id: nhSession.id,
              })}
              opened={menuOpen}
            />
          </SidebarItem>
          {nh?.subscription && (
            <SidebarItem
              label={t('components.nursinghomeSubmenu.linkToEquipment')}
              path={generatePath(Routes.NURSING_EQUIPMENT, {
                id: nhSession.id,
              })}
              icon={<NursingEquipmentIcon />}
              opened={menuOpen}
            >
              <SidebarSubitem
                label={t('components.nursinghomeSubmenu.linkToClipons')}
                path={generatePath(Routes.NURSING_EQUIPMENT_CLIPON, {
                  id: nhSession.id,
                })}
                opened={menuOpen}
              />
              <SidebarSubitem
                label={t('components.nursinghomeSubmenu.linkToGateways')}
                path={generatePath(Routes.NURSING_EQUIPMENT_GATEWAY, {
                  id: nhSession.id,
                })}
                opened={menuOpen}
              />
              <SidebarSubitem
                label={t('components.nursinghomeSubmenu.linkToChargings')}
                path={generatePath(Routes.NURSING_EQUIPMENT_CHARGING, {
                  id: nhSession.id,
                })}
                opened={menuOpen}
              />
              <SidebarSubitem
                label={t('components.nursinghomeSubmenu.linkToOrdering')}
                path={generatePath(Routes.NURSING_EQUIPMENT_ORDERING, {
                  id: nhSession.id,
                })}
                opened={menuOpen}
              />
            </SidebarItem>
          )}
        </>
      ) : (
        <>
          <MainTitle>
            {menuOpen ? t('components.sidebarMenu.mainTitle') : ''}
          </MainTitle>
          <SidebarItem
            label={t('components.sidebarMenu.linkToDashboard')}
            path={Routes.MAIN_DASHBOARD}
            icon={<DashboardIcon />}
            opened={menuOpen}
          />
          <SidebarItem
            label={t('components.sidebarMenu.linkToNursingHomes')}
            path={Routes.NURSINGS}
            icon={<NursingListIcon />}
            opened={menuOpen}
            exact
          />
        </>
      )}
    </>
  );
};

export default SidebarMenuTop;
