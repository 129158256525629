import { P } from 'src/uikit/Typography';
import styled from 'styled-components/macro';

const ErrorMessage = styled(P)`
  font-size: 0.8rem;
  text-align: start;
  color: ${({ theme }) => theme.palette.main.red};
  margin-bottom: 0;
  height: 1.5rem;
`;

export default ErrorMessage;
