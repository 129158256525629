import { OverlayTrigger } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Delete, Edit, List } from 'src/assets/list-icons';
import { ReactComponent as GeneralIcon } from 'src/assets/nursing-general.svg';
import AddressCard from 'src/components/Misc/Card/AddressCard';
import { PartnerProspectDeliveryAddressResponse } from 'src/sdk/partner-prospect';
import { Menu, MenuItem, MenuItems, MenuToggle } from 'src/uikit/Menu';
import { Tooltip } from 'src/uikit/Tooltip';
import styled from 'styled-components/macro';

const StyledWrapperIcon = styled.div`
  width: 28px;
  height: 28px;
  padding: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const StyledEdit = styled(Edit)`
  color: ${({ theme }) => theme.palette.main.primary};
`;
const StyledDelete = styled(Delete)`
  color: ${({ theme }) => theme.palette.main.red};
`;
const WrapperInformatioIcon = styled.div`
  margin-top: 0.25rem;
  color: ${({ theme }) => theme.palette.black[60]};
  width: 28px;
  height: 28px;

  svg {
    width: inherit;
    height: inherit;
  }
`;

interface Props {
  address: PartnerProspectDeliveryAddressResponse;
  canDelete: boolean;
  onClickDelete: (address: PartnerProspectDeliveryAddressResponse) => void;
  onClickUpdate: (address: PartnerProspectDeliveryAddressResponse) => void;
}

const PartnerProspectAddressCard = ({
  address,
  canDelete,
  onClickDelete,
  onClickUpdate,
}: Props) => {
  const { t } = useTranslation();
  return (
    <AddressCard
      address={address}
      variant="action"
      disabled={!!address.shippingRequestCount}
      action={
        address.shippingRequestCount ? (
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Tooltip id="button-tooltip">
                {t(
                  'components.PartnerProspects.PartnerProspectDetails.PartnerProspectAddresses.tooltipDisabled',
                )}
              </Tooltip>
            }
          >
            <WrapperInformatioIcon>
              <GeneralIcon />
            </WrapperInformatioIcon>
          </OverlayTrigger>
        ) : (
          <Menu toogleIcon alignRight>
            <MenuToggle id="partner-prospect-address-menu">
              <List />
            </MenuToggle>
            <MenuItems>
              <MenuItem onClick={() => onClickUpdate(address)}>
                <StyledWrapperIcon>
                  <StyledEdit />
                </StyledWrapperIcon>
                {t(
                  'components.PartnerProspects.PartnerProspectDetails.PartnerProspectAddresses.update',
                )}
              </MenuItem>
              {canDelete && (
                <MenuItem onClick={() => onClickDelete(address)}>
                  <StyledWrapperIcon>
                    <StyledDelete />
                  </StyledWrapperIcon>
                  {t(
                    'components.PartnerProspects.PartnerProspectDetails.PartnerProspectAddresses.delete',
                  )}
                </MenuItem>
              )}
            </MenuItems>
          </Menu>
        )
      }
    />
  );
};

export default PartnerProspectAddressCard;
