import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, generatePath } from 'react-router-dom';
import { ChargingStation, Clipon, Gateway } from 'src/assets/list-icons';
import { ReactComponent as PlusBadge } from 'src/assets/plus-badge.svg';
import { useAuth } from 'src/contexts/Auth';
import Routes from 'src/router/Routes';
import { NursingHomeResponse } from 'src/sdk/nursingHome';
import { Button } from 'src/uikit/Button';
import { H3, Label } from 'src/uikit/Typography';
import styled from 'styled-components/macro';

import GeneralCard from './GeneralCard';

const EquipmentWrapper = styled.div`
  width: 100%;
  display: grid;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
`;

const EquipmentRow = styled(Row)`
  width: 100%;
  display: flex;
  justify-content: center;

  gap: 0.5rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    justify-content: space-center;
    padding-inline: 0;
  }
`;

const EquipmentCol = styled(Col)`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    justify-content: start;
    flex-direction: row;
  }
`;

type EquipmentProps = {
  currentEquipmentCount?: NursingHomeResponse;
};

const EquipmentBlock = ({ currentEquipmentCount }: EquipmentProps) => {
  const { t } = useTranslation();  
  const { nhSession, authUser } = useAuth();
  
  const { subscription, id } = nhSession || {};
  const { status, type } = subscription || {};
  const { profile } = authUser || {};

  const shouldRenderButton = (profile === 'ORIZON_ADMINISTRATOR' || profile === 'PARTNER_ADMINISTRATOR') || (status === 'ACTIVE' && type === 'STANDARD');
  
  return (
    <GeneralCard title={t('components.equipmentBlock.title')}>
      <EquipmentWrapper>
        <Row className="justify-content-center">
          <H3 className="align-self-center">
            {t('components.equipmentBlock.subtitle')}{' '}
          </H3>
        </Row>
        <EquipmentRow>
          <EquipmentCol xs="5" md="2">
            <Clipon />
            <Label>
              {currentEquipmentCount?.clipons
                ? currentEquipmentCount?.clipons.length
                : 0}
            </Label>
          </EquipmentCol>
          <EquipmentCol xs="5" md="2">
            <ChargingStation />
            <Label>
              {currentEquipmentCount?.chargingStations
                ? currentEquipmentCount?.chargingStations.length
                : 0}
            </Label>
          </EquipmentCol>
          <EquipmentCol xs="5" md="2">
            <Gateway />
            <Label>
              {currentEquipmentCount?.gateways
                ? currentEquipmentCount?.gateways.length
                : 0}
            </Label>
          </EquipmentCol>
        </EquipmentRow>
        {
          shouldRenderButton ? (
            <Row xs="auto" className="justify-content-center mt-auto">
              <Col>
                <Button
                  as={Link}
                  to={generatePath(Routes.NURSING_EQUIPMENT_ORDERING_NEW, {
                    id: id?.toString() || '-1',
                  })}
                  className="align-self-end"
                >
                  <PlusBadge className="mr-2" />
                  {t('components.nursingHome.equipments.order.list.add')}
                </Button>
              </Col>
            </Row>
          ) : null
        }
      </EquipmentWrapper>
    </GeneralCard>
  );
};

export default EquipmentBlock;
