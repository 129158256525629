import { useTranslation } from 'react-i18next';
import { Card as UikitCard } from 'src/uikit/Card';
import styled from 'styled-components/macro';

import UserCreateIcon from './components/UserCreateIcon';

const WrapperCard = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 300px);
  gap: 2rem;
  justify-content: space-around;
  margin-top: 3rem;
`;

const _Card = styled(UikitCard)`
  background: #ffffff;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.palette.black[50]};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;current stroke css
`;

const Card = styled(_Card)`
  border-radius: 10px;

  min-height: 250px;
  padding: 1.25rem 0.25rem;
  display: grid;
  align-items: center;
  

  :hover {
    background: ${({ theme }) => theme.palette.main.lightBlue};
    border: 2px solid ${({ theme }) => theme.palette.main.primary};
  }
`;

const CardIcon = styled(_Card)`
  justify-self: center;
  width: 120px;
  padding: 0.75rem 2rem;
`;

const TitleCard = styled.h3`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;

  color: ${({ theme }) => theme.palette.main.primary};
`;

const SubtitleCard = styled.h3`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  align-self: start;
`;

export type UserCreateType = 'ontex' | 'partner';

interface Props {
  onSuccess: (type: UserCreateType) => void;
}

const UserCreateTypeSelect = ({ onSuccess }: Props) => {
  const { t } = useTranslation();
  return (
    <WrapperCard>
      <Card onClick={() => onSuccess('ontex')}>
        <TitleCard>
          {t(`components.Users.UsersList.UserCreateTypeSelect.ontex`)}
        </TitleCard>
        <SubtitleCard>
          {t('components.Users.UsersList.UserCreateTypeSelect.ontexSubtitle')}
        </SubtitleCard>
        <CardIcon>
          <UserCreateIcon type="ontex" />
        </CardIcon>
      </Card>
      <Card onClick={() => onSuccess('partner')}>
        <TitleCard>
          {t('components.Users.UsersList.UserCreateTypeSelect.partner')}
        </TitleCard>
        <SubtitleCard>
          {t('components.Users.UsersList.UserCreateTypeSelect.partnerSubtitle')}
        </SubtitleCard>
        <CardIcon>
          <UserCreateIcon type="partner" />
        </CardIcon>
      </Card>
    </WrapperCard>
  );
};

export default UserCreateTypeSelect;
