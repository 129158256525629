import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Row, Stack } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useUpdateUserEmailMutation from 'src/components/Users/hooks/useUpdateUserEmailMutation';
import useUpdateUserEmployeeIdMutation from 'src/components/Users/hooks/useUpdateUserEmployeeIdMutation';
import useUpdateUserMutation from 'src/components/Users/hooks/useUpdateUserMutation';
import useUpdateUserProfileMutation from 'src/components/Users/hooks/useUpdateUserProfileMutation';
import useLanguageOptions from 'src/hooks/useLanguageOptions';
import useProfileOptions from 'src/hooks/useProfileOptions';
import useToast from 'src/hooks/useToast';
import { Profiles } from 'src/sdk/authentification';
import { UserResponse } from 'src/sdk/users';
import { Button, ButtonColors } from 'src/uikit/Button';
import FormSelectInput from 'src/uikit/Form/SelectInput';
import FormTextInput from 'src/uikit/Form/TextInput';
import { H3 } from 'src/uikit/Typography';
import styled from 'styled-components/macro';
import * as yup from 'yup';

import { useAuth } from '../../../../contexts/Auth';
import { UpdateUserRequest } from '../../../../sdk/users';
import { areDifferentStrings } from '../../../../utils/string';

const Wrapper = styled(Row)`
  display: flex;
  margin-top: 2rem;
  flex-direction: column;
  width: 100%;
`;

const HeaderRow = styled(Row)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const Title = styled(H3)``;

const StyledRow = styled(Row)``;

const StyledSelect = styled(FormSelectInput)`
  min-width: 240px;
  max-width: 240px;
`;

const userInformationFormSchema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().email().required(),
  profile: yup.mixed<Profiles>().required(),
  language: yup.string(),
  employeeId: yup.string(),
});

type UserInformationFormSchema = yup.InferType<
  typeof userInformationFormSchema
>;

interface Props {
  user: UserResponse;
  onEnd: () => void;
}

const UserInformationForm = ({ user, onEnd }: Props) => {
  const { t } = useTranslation();
  const { toastSuccess, toastError } = useToast();
  const { authUser } = useAuth();
  const { updateUserMutation } = useUpdateUserMutation();
  const { updateUserEmployeeIdMutation } = useUpdateUserEmployeeIdMutation();
  const { updateUserEmailMutation } = useUpdateUserEmailMutation();
  const { updateUserProfileMutation } = useUpdateUserProfileMutation();
  const defaultValues = {
    firstName: user?.firstName,
    lastName: user?.lastName || '',
    email: user?.email || '',
    profile: user?.profile || Profiles.PRODUCT_SPECIALIST,
    language: user?.language || '',
    employeeId: user?.employeeId || '',
  } as UserInformationFormSchema;
  const methods = useForm<UserInformationFormSchema>({
    resolver: yupResolver(userInformationFormSchema),
    defaultValues,
  });
  const { handleSubmit } = methods;
  const onSubmit = handleSubmit(async (data) => {
    try {
      await updateUserMutation.mutateAsync({
        id: Number(user?.id),
        firstName: data.firstName,
        lastName: data.lastName,
        gender: user.gender,
        language: data.language as UpdateUserRequest['language'],
      });
      if (areDifferentStrings(data.employeeId, user.employeeId)) {
        await updateUserEmployeeIdMutation.mutateAsync({
          id: Number(user?.id),
          employeeId: data.employeeId,
        });
      }
      if (data.email !== user.email) {
        await updateUserEmailMutation.mutateAsync({
          id: Number(user?.id),
          email: data.email,
        });
      }
      if (data.profile !== user.profile) {
        await updateUserProfileMutation.mutateAsync({
          id: Number(user?.id),
          profile: data.profile,
        });
      }
      toastSuccess(
        t('components.Users.UserDetails.UserInformationForm.success'),
      );
      onEnd();
    } catch (err: any) {
      if (err.code) {
        return toastError(t(`errorsApi.${err.code}`));
      }
      toastError(t('components.Users.UserDetails.UserInformationForm.error'));
    }
  });

  const onCancel = () => {
    onEnd();
  };

  const languageOption = useLanguageOptions();

  const profileValues = [];
  if (user?.profile === Profiles.PARTNER_ADMIN) {
    profileValues.push(Profiles.PARTNER_ADMIN);
  } else {
    profileValues.push(Profiles.PRODUCT_SPECIALIST, Profiles.SALES_ASSISTANT);
    if (authUser?.profile === Profiles.ORIZON_ADMIN) {
      profileValues.push(Profiles.ORIZON_ADMIN);
    }
    if (authUser?.profile === Profiles.PARTNER_ADMIN) {
      profileValues.push(Profiles.PARTNER_ADMIN);
    }
  }

  const profileOption = useProfileOptions(profileValues);

  return (
    <Wrapper>
      <HeaderRow className="ml-2 mb-4">
        <Title>
          {t('components.Users.UserDetails.UserInformationForm.title')}
        </Title>
      </HeaderRow>
      <FormProvider {...methods}>
        <form onSubmit={onSubmit}>
          <StyledRow>
            <Col xs="12" md="4" className="px-md-2">
              <FormTextInput
                name="firstName"
                placeholder={t(
                  'components.Users.UserDetails.UserInformationForm.firstName',
                )}
                width="100%"
                disabled
              />
            </Col>
            <Col xs="12" md="4" className="px-md-2">
              <FormTextInput
                name="lastName"
                placeholder={t(
                  'components.Users.UserDetails.UserInformationForm.lastName',
                )}
                width="100%"
                disabled
              />
            </Col>
            <Col xs="12" md="4" className="px-md-2">
              <FormTextInput
                name="email"
                placeholder={t(
                  'components.Users.UserDetails.UserInformationForm.email',
                )}
                width="100%"
              />
            </Col>
          </StyledRow>
          <StyledRow className="mt-md-2">
            {(user?.profile === Profiles.ORIZON_ADMIN ||
              user?.profile === Profiles.PRODUCT_SPECIALIST ||
              user?.profile === Profiles.SALES_ASSISTANT) && (
              <Col xs="12" md="4" className="px-md-2">
                <FormTextInput
                  name="employeeId"
                  placeholder={t(
                    'components.Users.UserDetails.UserInformationForm.employeeId',
                  )}
                  width="100%"
                  disabled={authUser?.profile !== Profiles.ORIZON_ADMIN}
                />
              </Col>
            )}
            {/* Beware, hiding this element makes the submit button (for some currently unknown reasons) not work anymore*/}
            <Col xs="12" md="4" className="px-md-2">
              <StyledSelect
                name="profile"
                placeholder={t(
                  'components.Users.UserDetails.UserInformationForm.profile.label',
                )}
                options={profileOption}
                disabled={user?.profile === Profiles.PARTNER_ADMIN}
              />
            </Col>
            <Col xs="12" md="4" className="px-md-2">
              <StyledSelect
                name="language"
                placeholder={t(
                  'components.Users.UserDetails.UserInformationForm.language',
                )}
                options={languageOption}
              />
            </Col>
          </StyledRow>
          <Stack direction="horizontal" className="justify-content-end">
            <Button
              variant="light"
              background={ButtonColors.CANCEL}
              onClick={onCancel}
            >
              {t('components.Users.UserDetails.UserInformationForm.cancel')}
            </Button>
            <Button type="submit">
              {t('components.Users.UserDetails.UserInformationForm.submit')}
            </Button>
          </Stack>
        </form>
      </FormProvider>
    </Wrapper>
  );
};

export default UserInformationForm;
