import { Row, Col } from 'react-bootstrap';
import BillingCard from 'src/components/NursingHome/General/BillingCard';
import DeliveryAddressCard from 'src/components/NursingHome/General/DeliveryAddressCard';
import { Partner } from 'src/sdk/partner';

type Props = {
  partner: Partner;
};

const PartnerConfigurationGeneral = ({ partner }: Props) => {
  return (
    <div>
      <Row>
        <Col md="6">
          <BillingCard address={partner.address} />
        </Col>
        <Col md="6">
          <DeliveryAddressCard addresses={partner.deliveryAddresses} />
        </Col>
      </Row>
    </div>
  );
};

export default PartnerConfigurationGeneral;
