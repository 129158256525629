import { yupResolver } from '@hookform/resolvers/yup';
import { Form } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import { Profiles } from 'src/sdk/authentification';
import * as yup from 'yup';

import UserCreateForm, {
  userCreateFormSchema,
} from './components/UserCreateFormFieldset';
import UserCreateFormFooter, {
  UserCreateFormFooterProps,
} from './components/UserCreateFormFooter';

const userPartnerCreateFormSchema = (t: TFunction) => userCreateFormSchema(t);

export type UserPartnerCreateFormSchema = yup.InferType<
  ReturnType<typeof userPartnerCreateFormSchema>
>;

interface Props extends UserCreateFormFooterProps {
  onSubmit: (values: UserPartnerCreateFormSchema) => void;
}

const UserPartnerCreateForm = ({ onBack, onCancel, onSubmit }: Props) => {
  const { t } = useTranslation();
  const methods = useForm<UserPartnerCreateFormSchema>({
    resolver: yupResolver(userPartnerCreateFormSchema(t)),
  });

  const { handleSubmit } = methods;

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <UserCreateForm
          profilesFilter={[Profiles.PRODUCT_SPECIALIST, Profiles.PARTNER_ADMIN]}
        />
        <UserCreateFormFooter onBack={onBack} onCancel={onCancel} />
      </Form>
    </FormProvider>
  );
};

export default UserPartnerCreateForm;
