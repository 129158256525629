import { useCallback, useState } from 'react';
import { Row } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ReactComponent as EmailIcon } from 'src/assets/email.svg';
import { ReactComponent as GooglePlayStoreLogoIcon } from 'src/assets/google-play-store-logo.svg';
import { ReactComponent as HomeIcon } from 'src/assets/home.svg';
import { ReactComponent as MobileIcon } from 'src/assets/mobile.svg';
import { ReactComponent as NurseAppQrCodeIcon } from 'src/assets/nurse-app-qr-code.svg';
import { ReactComponent as NurseAvatarIcon } from 'src/assets/nurse-avatar.svg';
import { ReactComponent as NursingHomeIcon } from 'src/assets/nursing-home.svg';
import { ReactComponent as SubscriptionIcon } from 'src/assets/subscription-icon.svg';
import { Button, ButtonColors } from 'src/uikit/Button';
import { Card } from 'src/uikit/Card';
import CircleStatus from 'src/uikit/CircleStatus';
import Center from 'src/uikit/Layout/Center';
import palette from 'src/uikit/theme/palette';
import styled from 'styled-components/macro';
import useSynchronize from '../hooks/useSynchronize';
import useChangeTenant from '../hooks/useChangeTenant';
import SynchronizeModel from './SynchronizeModel';

interface Props {
  nhId?: number;
  nhName?: string;
  mode?: string;
  tenantName?: string;
}

const HomeIconWrapper = styled.div`
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${palette.main.primary};
  border-radius: 100%;
  color: ${palette.main.primary};
  background: white;
  width: 48px;
  height: 48px;
`;

const StepIconWrapper = styled.div`
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${palette.main.primary};
  border-radius: 100%;
  color: ${palette.main.white};
  background: ${palette.main.primary};
  width: 48px;
  height: 48px;
  fill: ${palette.main.white};
`;

const Completed = ({ nhId, nhName, mode, tenantName }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [ visible, setVisible ] = useState<boolean>(false)
  const [ isLoding, setIsLoading ] = useState<boolean>(false)
  const [data, setData] = useState<any>()
  const { synchronize } = useSynchronize()
  const { changeTenant } = useChangeTenant()

  type SychronizedData = {
    createdDepartmentCount? : number;
    createdResidentCount? : number;
    createdRoomCount? : number;
    errorSychronized? : string;
  };


  /**
   * A function that handles error responses.
   *
   * @param {SychronizedData} sychronizedData - The synchronized data object.
   * @return {void} This function does not return anything.
   */
  const errorResponse = (sychronizedData : SychronizedData) => {
    sychronizedData.errorSychronized = "error"
    setData(sychronizedData)
    setTimeout(() => {
      setIsLoading(false)
    },1000)
  } 


  /**
   * Updates the synchronized data with the response received from the server.
   *
   * @param {SychronizedData} sychronizedData - The current synchronized data object.
   * @param {any} rep - The response received from the server.
   */
  const validResponse = (sychronizedData : SychronizedData, rep :any) => {
    sychronizedData = {
      createdDepartmentCount : rep.data.createdDepartmentCount,
      createdResidentCount : rep.data.createdResidentCount,
      createdRoomCount : rep.data.createdRoomCount,
      errorSychronized : ''
    }
    setData(sychronizedData)
  }

  const handleClick = useCallback(() => {
    history.push(`/nursing-home/${nhId}/general`);
  }, [history, nhId]);

  const handleSynchronize = useCallback(() => {
    setVisible(true)
    setIsLoading(true)
    let sychronizedData : SychronizedData  = {}
    changeTenant.mutateAsync(tenantName!).then(() =>{
      synchronize.mutateAsync().then((rep) => {
        if(rep?.data){
          validResponse(sychronizedData, rep)
        }else{
          errorResponse(sychronizedData)
        }
        setTimeout(() => {
          setIsLoading(false)
        },1000)
      }).catch(() => {
        errorResponse(sychronizedData)
      });
    }).catch(() => {
      errorResponse(sychronizedData)
    })
  }, [mode,tenantName]);

  return (
    <Card className="mx-3 p-4">
      
      <div
        style={{
          background: 'white',
          borderRadius: '12px 12px 0px 0px',
          padding: '0px 24px',
          display: 'flex',
          justifyContent: 'center',
          height: '80px',
        }}
      >
        <div
          style={{
            marginTop: '16px',
            textAlign: 'center',
          }}
        >
          <h4>
            <strong>
              {t('components.nursingHomeCreation.validation.success')}
            </strong>
          </h4>
          {nhName && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <HomeIconWrapper>
                <HomeIcon />
              </HomeIconWrapper>
              <h5>
                <strong>{nhName}</strong>
              </h5>
            </div>
          )}
        </div>
      </div>
      <Center className="justify-content-center p-4">
        <Row className="pt-4">
          <CircleStatus type="success" />
        </Row>
      </Center>
      <Row className="pt-4">
        {
          mode === 'B2B' ?
             <div className='mb-4'>
              <p>
                <strong>{t('B2B-nursing-home')}</strong>
              </p>
              <div className='d-flex justify-content-between align-items-center'>
                <div className='d-flex justify-content-center align-items-center'>
                  <div>
                    <StepIconWrapper>
                      <EmailIcon />
                    </StepIconWrapper>
                  </div>
                  <div>{t('import-resident')}</div>
                </div>
                <div>
                  <Button
                    style={{ margin: 'auto' }}
                    onClick={handleSynchronize}
                    background={ButtonColors.SUCCESS}
                  >
                    {t('sychronize')}
                  </Button>
                </div>
              </div>
             </div>
          : 
          null
        }
        <p>
          <strong>{t('next-steps')}</strong>
        </p>
        <ul>
          <li
            style={{
              marginBottom: '24px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div>
              <StepIconWrapper>
                <EmailIcon />
              </StepIconWrapper>
            </div>
            <p style={{ margin: 0 }}>
              <Trans
                i18nKey={t('an-email-has-been-sent')}
                components={{ 1: <strong /> }}
              />
            </p>
          </li>
          <li
            style={{
              marginBottom: '24px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div>
              <StepIconWrapper>
                <HomeIcon />
              </StepIconWrapper>
            </div>
            <p style={{ margin: 0 }}>{t('connect-to-the-nursing-home')}</p>
          </li>
          <li
            style={{
              marginBottom: '24px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div>
              <StepIconWrapper>
                <SubscriptionIcon />
              </StepIconWrapper>
            </div>
            <p style={{ margin: 0 }}>{t('create-the-subscription')}</p>
          </li>
          <li
            style={{
              marginBottom: '24px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div>
              <StepIconWrapper>
                <MobileIcon />
              </StepIconWrapper>
            </div>
            <p style={{ margin: 0 }}>{t('once-the-devices-received')}</p>
          </li>
        </ul>
      </Row>
      <div style={{ position: 'relative' }}>
        <div>
          <div style={{ marginBottom: '12px' }}>
            <NurseAppQrCodeIcon />
          </div>
          <div
            style={{
              width: '220px',
              height: '100%',
            }}
          >
            <GooglePlayStoreLogoIcon
              style={{
                width: '100%',
                height: '100%',
              }}
            />
          </div>
          <div
            style={{
              width: '200px',
              height: '100%',
              marginBottom: '12px',
              display: 'flex',
              justifyContent: 'start',
            }}
          >
            <NurseAvatarIcon
              style={{
                width: '50%',
                height: '100%',
              }}
            />
            <strong
              style={{
                color: palette.main.primary,
                marginLeft: '-18px',
                marginTop: '18px',
              }}
            >
              {t('nurses-app')}
            </strong>
          </div>
        </div>
        <div
          style={{
            zIndex: 10,
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
          }}
        >
          <div
            style={{
              marginBottom: '48px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <NursingHomeIcon />
          </div>
          <Button
            style={{ margin: 'auto' }}
            onClick={handleClick}
            background={ButtonColors.SUCCESS}
          >
            {t('components.nursingHomeCreation.validation.buttonNh')}
          </Button>
        </div>
      </div>
      {
        mode === 'B2B' ?
          <SynchronizeModel isVisible={visible} handleClose={() => setVisible(false)} isLoding={isLoding} data={data} />
        :
        null
      } 
    </Card>
  );
};

export default Completed;
