import { AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import request from 'src/axios';
import {
  PartnerProspectAddressCreationResponse,
  PartnerProspectAddressCreationRequest,
} from 'src/sdk/partner-prospect';

const useCreatePartnerProspectAddressMutation = () => {
  const queryClient = useQueryClient();

  const createPartnerProspectAddressMutation = useMutation<
    AxiosResponse<PartnerProspectAddressCreationResponse>,
    Error,
    { partnerProspectId: number } & PartnerProspectAddressCreationRequest
  >(
    ({ partnerProspectId, ...params }) => {
      return request.post(
        `/partner-prospects/${partnerProspectId}/delivery-addresses`,
        params,
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
      },
    },
  );

  return { createPartnerProspectAddressMutation };
};

export default useCreatePartnerProspectAddressMutation;
