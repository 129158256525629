import { format } from 'date-fns';
import { DATE_BACKEND, DATE_BACKEND_FIRST_DAY } from 'src/constants';

export const getStartingDate = () => {
  let date = new Date(Date.now());
  date.setUTCDate(date.getUTCDate() + 15);
  return format(date, DATE_BACKEND_FIRST_DAY);
};

export const getDeliveryDate = () => {
  let date = new Date(Date.now());
  date.setUTCMonth(date.getUTCMonth() + 1);
  return format(date, DATE_BACKEND);
};
