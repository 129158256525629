import { Badge } from 'src/uikit/Badge';
import { GroupedOption } from 'src/uikit/SelectInput';
import { Label } from 'src/uikit/Typography';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 0.25rem;
`;

export const SelectGroupLabel = (data: GroupedOption) => (
  <Wrapper>
    <Label>{data.label}</Label>
    <Badge bg="primary">{data.options.length}</Badge>
  </Wrapper>
);

export default SelectGroupLabel;
