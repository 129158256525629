import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import SortingButton from '../../SortingHeaders/SortingButton';

const StyledRow = styled(Row)`
  padding-left: 10px;
  padding-right: 10px;
`;

const StyledCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SubscriptionBillingHeader = () => {
  const { t } = useTranslation();

  return (
    <StyledRow>
      <StyledCol md={{ offset: '1', span: '2' }}>
        <SortingButton
          primary
          label={t('components.sortingHeader.sortByMonth')}
          field="month"
          disableSort
        />
      </StyledCol>
      <StyledCol md="2">
        <SortingButton
          primary
          label={t('components.sortingHeader.sortByYear')}
          field="year"
          disableSort
        />
      </StyledCol>
      <StyledCol md="2">
        <SortingButton
          primary
          label={t('components.sortingHeader.sortByBillingDate')}
          field="billingDate"
          disableSort
        />
      </StyledCol>
      <StyledCol md="2">
        <SortingButton
          primary
          label={t('components.sortingHeader.sortByNumberOfDaysBilled')}
          field="numberOfDaysBilled"
          disableSort
        />
      </StyledCol>
    </StyledRow>
  );
};

export default SubscriptionBillingHeader;
