import { useMemo } from 'react';
import { Col, Row, Stack } from 'react-bootstrap';
import { useWatch } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import DeliveryAddressCard from 'src/components/Subscription/SubscriptionUpsert/components/DeliveryAddressCard';
import { DATE_FORMAT_DAY } from 'src/constants';
import useOptionsCountries from 'src/hooks/useOptionsCountries';
import { DeliveryAddress } from 'src/sdk/nursingHome';
import { ShippingRequestOrderSparePartsLimitsResponse } from 'src/sdk/shippingRequest';
import { SubscriptionCreationDataElementAddressRequest } from 'src/sdk/subscription';
import FormDateSelector from 'src/uikit/Form/DateSelector';
import FormSelectInput from 'src/uikit/Form/SelectInput';
import FormTextArea from 'src/uikit/Form/Textarea';
import FormTextInput from 'src/uikit/Form/TextInput';
import { getStartingDate } from 'src/utils/getDate';
import styled from 'styled-components/macro';
import * as yup from 'yup';

const Section = styled.div`
  width: 100%;
`;

const StyledCol = styled(Col)``;

const StyledRow = styled(Row)`
  align-items: center;
  width: 100%;
`;

const StyledLabel = styled.label`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1px;
  color: ${({ theme }) => theme.palette.black.main};
  margin: 0;
`;

export interface CurrentEquipments {
  clipons: number;
  gateways: number;
  chargingStations: number;
}

const Fieldset = styled.fieldset`
  display: flex;
  gap: 2rem;
  flex-direction: column;
`;

export const nhEquipmentOrderFormDeliveryCustomAddressSchema = (
  t: TFunction,
): yup.SchemaOf<SubscriptionCreationDataElementAddressRequest> =>
  yup.object({
    name: yup
      .string()
      .min(
        2,
        t(
          'components.nursingHome.equipments.order.orderForm.delivery.errorMessage.name',
        ),
      )
      .max(
        35,
        t(
          'components.nursingHome.equipments.order.orderForm.delivery.errorMessage.nameMax',
        ),
      )
      .required(),
    street: yup
      .string()
      .min(
        2,
        t(
          'components.nursingHome.equipments.order.orderForm.delivery.errorMessage.street',
        ),
      )
      .max(
        35,
        t(
          'components.nursingHome.equipments.order.orderForm.delivery.errorMessage.streetMax',
        ),
      )
      .required(),
    zip: yup
      .string()
      .matches(
        /^[0-9]+$/,
        t(
          'components.nursingHome.equipments.order.orderForm.delivery.errorMessage.zip',
        ),
      )
      .max(
        9,
        t(
          'components.nursingHome.equipments.order.orderForm.delivery.errorMessage.zipMax',
        ),
      )
      .required(),
    town: yup
      .string()
      .min(
        1,
        t(
          'components.nursingHome.equipments.order.orderForm.delivery.errorMessage.town',
        ),
      )
      .max(
        35,
        t(
          'components.nursingHome.equipments.order.orderForm.delivery.errorMessage.townMax',
        ),
      )
      .required(),
    country: yup
      .string()
      .min(
        1,
        t(
          'components.nursingHome.equipments.order.orderForm.delivery.errorMessage.country',
        ),
      )
      .required(),
    email: yup
      .string()
      .min(
        1,
        t(
          'components.nursingHome.equipments.order.orderForm.delivery.errorMessage.email',
        ),
      )
      .max(
        70,
        t(
          'components.nursingHome.equipments.order.orderForm.delivery.errorMessage.emailMax',
        ),
      )
      .email(
        t(
          'components.nursingHome.equipments.order.orderForm.delivery.errorMessage.emailFormat',
        ),
      )
      .required(),
    telephone: yup
      .string()
      .min(
        1,
        t(
          'components.nursingHome.equipments.order.orderForm.delivery.errorMessage.phone',
        ),
      )
      .max(
        25,
        t(
          'components.nursingHome.equipments.order.orderForm.delivery.errorMessage.phoneMax',
        ),
      )
      .required(),
  });

export type NHEquipmentOrderFormDeliveryCustomAddressSchema = yup.InferType<
  ReturnType<typeof nhEquipmentOrderFormDeliveryCustomAddressSchema>
>;

export const nhEquipmentOrderFormDeliverySchema = (t: TFunction) =>
  yup.object({
    requestedDeliveryDate: yup.string().required(),
    deliveryAddressId: yup.number().required(),
    comment: yup.string().required(),
    customAddress: yup
      .object()
      .when('deliveryAddressId', {
        is: -1,
        then: () =>
          nhEquipmentOrderFormDeliveryCustomAddressSchema(
            t,
          ) as yup.SchemaOf<SubscriptionCreationDataElementAddressRequest>,
      })
      .nullable(
        true,
      ) as yup.SchemaOf<SubscriptionCreationDataElementAddressRequest | null>,
  });

export type NHEquipmentOrderFormDeliverySchema = yup.InferType<
  ReturnType<typeof nhEquipmentOrderFormDeliverySchema>
>;

interface Props {
  deliveryAddresses: (DeliveryAddress & { isPartner?: boolean })[];
  limit: ShippingRequestOrderSparePartsLimitsResponse;
}

const NHEquipmentOrderFormDelivery = ({ deliveryAddresses, limit }: Props) => {
  const { t } = useTranslation();

  const deliveryAddressId = useWatch({ name: 'deliveryAddressId' });

  const { groupedCountriesOptions } = useOptionsCountries();

  const addressOptions = useMemo(() => {
    const propspectAddressOptions = deliveryAddresses
      .filter((deliveryAddress) => !deliveryAddress.isPartner)
      ?.map((address: DeliveryAddress) => {
        return { label: address.name, value: address.id };
      })
      .concat({ label: 'Custom', value: -1 });

    const partnerAddressOptions = deliveryAddresses
      .filter((deliveryAddress) => deliveryAddress.isPartner)
      ?.map((address: DeliveryAddress) => {
        return { label: address.name, value: address.id };
      });

    return partnerAddressOptions && partnerAddressOptions.length > 0
      ? [
          {
            label: t(
              'components.nursingHome.equipments.order.orderForm.delivery.nursing',
            ),
            options: propspectAddressOptions,
          },
          {
            label: t(
              'components.nursingHome.equipments.order.orderForm.delivery.partner',
            ),
            options: partnerAddressOptions,
          },
        ]
      : propspectAddressOptions;
  }, [deliveryAddresses, t]);

  return (
    <Fieldset>
      <Row>
        <Col md="6" className="pt-2">
          <StyledLabel>
            {t(
              'components.nursingHome.equipments.order.orderForm.delivery.date',
            )}
          </StyledLabel>
        </Col>
        <Col md="4">
          <FormDateSelector
            name="requestedDeliveryDate"
            minDate={
              new Date(limit.minRequestedDeliveryDate || getStartingDate())
            }
            placeholder="Delivery date"
            dateFormat={DATE_FORMAT_DAY}
          />
        </Col>
      </Row>
      <Row>
        <Col md="6" className="pt-2">
          <StyledLabel>
            {t(
              'components.nursingHome.equipments.order.orderForm.delivery.comment',
            )}
          </StyledLabel>
        </Col>
        <Col md="4">
          <FormTextArea name="comment" width="100%" rows={4} maxLength={512} />
        </Col>
      </Row>
      <Stack>
        <Row>
          <Col md="6" className="pt-2">
            <StyledLabel>
              {t(
                'components.nursingHome.equipments.order.orderForm.delivery.address',
              )}
            </StyledLabel>
          </Col>
          <Col md="4">
            <FormSelectInput
              name="deliveryAddressId"
              placeholder="Delivery Address"
              options={addressOptions}
            />
          </Col>
        </Row>
        <Section>
          {deliveryAddressId !== -1 && (
            <DeliveryAddressCard
              data={deliveryAddresses.find((e) => e.id === deliveryAddressId)}
            />
          )}
          {deliveryAddressId === -1 && (
            <>
              <StyledRow>
                <StyledCol md="12">
                  <FormTextInput
                    name="customAddress.name"
                    placeholder={t('common.address.name')}
                    width={'100%'}
                  />
                </StyledCol>
              </StyledRow>
              <StyledRow>
                <StyledCol md="12">
                  <FormTextInput
                    name="customAddress.street"
                    placeholder={t('common.address.street')}
                    width={'100%'}
                  />
                </StyledCol>
              </StyledRow>
              <StyledRow>
                <StyledCol md="3">
                  <FormTextInput
                    name="customAddress.zip"
                    placeholder={t('common.address.zip')}
                    width={'100%'}
                  />
                </StyledCol>
                <StyledCol md="1" />
                <StyledCol md="4">
                  <FormTextInput
                    name="customAddress.town"
                    placeholder={t('common.address.town')}
                    width={'100%'}
                  />
                </StyledCol>
                <StyledCol md="1" />
                <StyledCol md="3">
                  <FormSelectInput
                    name="customAddress.country"
                    placeholder={t('common.address.country')}
                    width={'100%'}
                    options={groupedCountriesOptions}
                  />
                </StyledCol>
              </StyledRow>
              <StyledRow>
                <StyledCol md="4">
                  <FormTextInput
                    name="customAddress.email"
                    placeholder={t('common.address.email')}
                    width={'100%'}
                  />
                </StyledCol>
                <StyledCol md="4" />
                <StyledCol md="4">
                  <FormTextInput
                    name="customAddress.telephone"
                    placeholder={t('common.address.phone')}
                    width={'100%'}
                  />
                </StyledCol>
              </StyledRow>
            </>
          )}
        </Section>
      </Stack>
    </Fieldset>
  );
};

export default NHEquipmentOrderFormDelivery;
