import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Routes } from 'src/router/Routes';
import Divider from 'src/uikit/Divider';
import { A } from 'src/uikit/Typography';
import styled from 'styled-components/macro';

const StyledLink = styled(A)`
  color: ${({ theme }) => theme.palette.black.strongGrey};
`;

const BackToLogin = () => {
  const { t } = useTranslation();
  return (
    <>
      <Divider />
      <StyledLink as={Link} to={Routes.LOGIN}>
        {'< '}
        {t('components.Auth.BackToLogin.label')}
      </StyledLink>
    </>
  );
};

export default BackToLogin;
