import { useTranslation } from 'react-i18next';
import { Profiles } from 'src/sdk/authentification';

export type ProfilesOption = {
  label: string;
  value: keyof typeof Profiles
}

const useProfileOptions = (profilesAsked?: Profiles[]) => {
  const { t } = useTranslation();
  const profiles = [
    {
      label: t(`common.role.${Profiles.ORIZON_ADMIN}`),
      value: Profiles.ORIZON_ADMIN,
    },
    {
      label: t(`common.role.${Profiles.PRODUCT_SPECIALIST}`),
      value: Profiles.PRODUCT_SPECIALIST,
    },
    {
      label: t(`common.role.${Profiles.PARTNER_ADMIN}`),
      value: Profiles.PARTNER_ADMIN,
    },
    {
      label: t(`common.role.${Profiles.NURSE}`),
      value: Profiles.NURSE,
    },
    {
      label: t(`common.role.${Profiles.HEAD_NURSE}`),
      value: Profiles.HEAD_NURSE,
    },
    {
      label: t(`common.role.${Profiles.DIRECTOR}`),
      value: Profiles.DIRECTOR,
    },
    {
      label: t(`common.role.${Profiles.CAREGIVER}`),
      value: Profiles.CAREGIVER,
    },
    {
      label: t(`common.role.${Profiles.SALES_ASSISTANT}`),
      value: Profiles.SALES_ASSISTANT,
    },
  ];

  return profilesAsked && profilesAsked.length > 1
    ? profiles.filter((profile) =>
        profilesAsked.find((profileAsked) => profileAsked === profile.value),
      )
    : profiles;
};

export default useProfileOptions;
