import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useParams } from 'react-router-dom';
import useFetchNursingHomeQuery from 'src/components/NursingHome/hooks/useFetchNursingHomeQuery';
import Routes from 'src/router/Routes';
import { RouteParams } from 'src/sdk/nursingHome';
import HorizontalMenu, { HorizontalMenuItems } from 'src/uikit/HorizontalMenu';

const EquipmentHorizontalMenu = () => {
  const { id } = useParams<RouteParams>();
  const { t } = useTranslation();

  const { data } = useFetchNursingHomeQuery({
    id: Number(id),
  });

  const MenuItem: HorizontalMenuItems[] = useMemo(() => {
    return [
      {
        routes: generatePath(Routes.NURSING_EQUIPMENT_CLIPON, { id }),
        label: t('components.equipmentBlock.clipon_plural'),
        totalCount: data?.cliponMetrics?.totalCount || 0,
      },
      {
        routes: generatePath(Routes.NURSING_EQUIPMENT_GATEWAY, { id }),
        label: t('components.equipmentBlock.gateway_plural'),
        totalCount: data?.gatewayMetrics?.totalCount || 0,
      },
      {
        routes: generatePath(Routes.NURSING_EQUIPMENT_CHARGING, { id }),
        label: t('components.equipmentBlock.charging_plural'),
        totalCount: data?.chargingStationMetrics?.totalCount || 0,
      },
      {
        routes: generatePath(Routes.NURSING_EQUIPMENT_ORDERING, { id }),
        totalCount: data?.counts?.shippingRequestCount,
        label: t('components.equipmentBlock.ordering'),
      },
    ];
  }, [
    data?.chargingStationMetrics?.totalCount,
    data?.cliponMetrics?.totalCount,
    data?.counts?.shippingRequestCount,
    data?.gatewayMetrics?.totalCount,
    id,
    t,
  ]);

  return <HorizontalMenu menuItems={MenuItem} />;
};

export default EquipmentHorizontalMenu;
