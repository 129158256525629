import { useTranslation } from 'react-i18next';
import EmptyModal from 'src/components/Misc/Modal/EmptyModal';
import { Button, ButtonColors } from 'src/uikit/Button';
import styled from 'styled-components/macro';
import { SpinnerLoaderSychronize } from 'src/uikit/SpinnerLoader';
import { ChevronRight } from 'src/assets/arrows/index';

type SychronizedData = {
  createdDepartmentCount?: number;
  createdResidentCount?: number;
  createdRoomCount?: number;
  errorSychronized?: string;
};

interface Props {
  isVisible: boolean;
  handleClose: () => void;
  isLoding: boolean;
  data: SychronizedData;
}

const StyledEmptyModal = styled(EmptyModal)`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;


export const StyledButtonsWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  gap: 0.25rem;
  margin-bottom: 1rem;
`;

const SynchronizeModel = ({
  isVisible,
  handleClose,
  isLoding,
  data
}: Props) => {
  const { t } = useTranslation();
  
  return (
    <StyledEmptyModal isVisible={isVisible} handleClose={handleClose} width='25rem'>
      <StyledHeader>
      </StyledHeader>
      <div className='w-100 d-flex justify-content-start align-items-start flex-column'>
        <div className='w-100 d-flex justify-content-start align-items-start flex-column'>
            {
                isLoding ? 
                    <div className='w-100 d-flex justify-content-center align-items-center flex-column mb-3'>
                        <p className='mb-5'>{t('import-in-progress')}</p>
                        <div className="d-flex justify-content-center align-items-center">
                          <SpinnerLoaderSychronize animation="border" />
                        </div>
                    </div>
                : 
                data?.errorSychronized !== "error" ? 
                    <div className='w-100 mb-3'>
                        <p className='w-100 text-center'>{t('import-done')}</p>
                        <div className='mx-5'>
                          <ul className='list-unstyled'>
                              <li>{data?.createdResidentCount ? data?.createdResidentCount : 0} {t('residents')}</li>
                              <li>{data?.createdDepartmentCount ? data?.createdDepartmentCount : 0} {t('departements')}</li>
                              <li>{data?.createdRoomCount ? data?.createdRoomCount : 0} {t('rooms')}</li>
                          </ul>
                        </div>
                    </div>
                    :
                    <div className='w-100 d-flex justify-content-center align-items-center flex-column mb-3'>
                        <p className='mb-3'>{t('erreur-during-import')}</p>
                        <p>{t('contact-support')}</p>
                    </div>
            }
        </div>
        <div className='w-100 d-flex justify-content-center align-items-center mb-2'>
          {
              isLoding ?
              null:
              data?.errorSychronized !== "error" ? 
                <Button background={ButtonColors.SUCCESS} onClick={handleClose}>
                  <div className='d-flex justify-content-center align-items-center'>
                    <span className='d-flex align-items-end'> {t('finish')} </span>
                    <ChevronRight style={{ width : '1.2rem', marginLeft : '0.5rem'}}  />
                  </div>
                </Button>
              :
                <Button background={ButtonColors.DANGER} onClick={handleClose}>
                  <div className='d-flex justify-content-center align-items-center'>
                      <span className='d-flex align-items-end'> {t('close')} </span>
                      <ChevronRight style={{ width : '1.2rem', marginLeft : '0.5rem'}}  />
                  </div>
                </Button>
          }
        </div>
      </div>
    </StyledEmptyModal>
  );
};

export default SynchronizeModel;
