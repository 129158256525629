import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo } from 'react';
import { Row, Col } from 'react-bootstrap';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useCommonAvatars from 'src/components/NursingHome/Creation/hooks/useCommonAvatars';
import useSimulateNursingHomeCreation from 'src/components/NursingHome/Creation/hooks/useSimulateNursingHomeCreation';
import useLanguageOptions from 'src/hooks/useLanguageOptions';
import { Gender } from 'src/sdk/common';
import {
  CreateNursingHome,
  NursingHomeCreationType,
} from 'src/sdk/nursingHome';
import { Button } from 'src/uikit/Button';
import { Form, FormGroup } from 'src/uikit/Form';
import AvatarInput from 'src/uikit/Form/AvatarInput';
import RadioButton from 'src/uikit/Form/RadioInput';
import FormSelectInput from 'src/uikit/Form/SelectInput';
import FormTextInput from 'src/uikit/Form/TextInput';
import { Text } from 'src/uikit/Typography';
import { FormButtonsWrapper } from 'src/uikit/Wrapper';
import styled from 'styled-components/macro';
import * as yup from 'yup';

import {
  NursingHomeCreationStep,
  useNursingHomeCreation,
} from './NursingHomeCreationContext';

const Description = styled(Text)``;

type DirectorAccountForm = {
  directorFirstName: string;
  directorLastName: string;
  directorEmail: string;
  directorPin: string;
  directorLanguage: CreateNursingHome['language'];
  directorGender: CreateNursingHome['directorGender'];
  directorCommonAvatarId: number;
};

type Props = {
  data: CreateNursingHome;
  onSuccess: (data: Partial<CreateNursingHome>) => void;
  onCancel: () => void;
  type: NursingHomeCreationType;
};

const NursingHomeCreationDirector = ({
  data,
  onSuccess,
  onCancel,
  type,
}: Props) => {
  const { t } = useTranslation();
  const languageOptions = useLanguageOptions();
  const {
    commonFemaleAvatars,
    commonMaleAvatars,
    isLoading: isAvatarLoading,
  } = useCommonAvatars();
  const { simulateNursingHome, isLoading } =
    useSimulateNursingHomeCreation(type);

  const { nursingHomeCreationSteps } = useNursingHomeCreation();

  const accountFormSchema = useMemo(
    () =>
      yup.object().shape({
        directorFirstName: yup
          .string()
          .min(2, 'First name must contains at least 2 characters')
          .max(64),
        directorLastName: yup
          .string()
          .min(2, 'Last name must contains at least 2 characters')
          .max(64),
        directorEmail: yup.string().email('Must be a valid email address'),
        directorPin: yup
          .string()
          .matches(/^[0-9]+$/, 'Must contains only digits')
          .min(4, 'Must be at least 4 digit')
          .max(4, 'Must be no more than 4 digits'),
        directorLanguage: yup.string().required(),
        directorGender: yup.string().required(),
        directorCommonAvatarId: yup.number().required(),
      }),
    [],
  );

  const methods = useForm<DirectorAccountForm>({
    resolver: yupResolver(accountFormSchema),
    mode: 'onChange',
    defaultValues: data,
  });
  const { formState, handleSubmit, setValue, watch, getValues } = methods;
  const { isValid } = formState;

  const selectedGender = watch('directorGender');

  const avatars =
    selectedGender === Gender.Male ? commonMaleAvatars : commonFemaleAvatars;
  // Set initial value for avatar field
  useEffect(() => {
    if (
      !avatars
        .map((avatar) => avatar.id)
        .includes(getValues('directorCommonAvatarId'))
    ) {
      setValue('directorCommonAvatarId', avatars[0]?.id);
    }
  }, [avatars, selectedGender, getValues, setValue]);

  const genderOption = [
    {
      id: Gender.Female,
      label: t('components.profileForm.gender.female'),
    },
    {
      id: Gender.Male,
      label: t('components.profileForm.gender.male'),
    },
  ];

  const onSubmit: SubmitHandler<DirectorAccountForm> = async (formData) => {
    simulateNursingHome(
      { ...data, ...formData },
      nursingHomeCreationSteps,
      NursingHomeCreationStep.DirectorAccount,
    ).then(() => onSuccess(formData));
    // TODO: catch and handle simulate error
  };

  return (
    <div>
      <div
        style={{
          background: 'white',
          borderRadius: '0px 0px 12px 12px',
          padding: '16px',
        }}
      >
        <Description style={{ marginBottom: '32px' }}>
          <span>{t('you-are-about-to-create-director')}</span>
          <p>{t('director-will-recieve-an-email')}</p>
        </Description>
        <FormProvider {...methods}>
          <Form onSubmit={handleSubmit(onSubmit)} id="director-account-form">
            <Row>
              <Col>
                <FormGroup className="d-flex justify-content-between">
                  <label style={{ width: '30%' }}>{t('first-name')}</label>
                  <FormTextInput
                    name="directorFirstName"
                    placeholder={t(
                      'components.nursingHomeCreation.directorAccount.firstName.placeholder',
                    )}
                    width="100"
                  />
                </FormGroup>
                <FormGroup className="d-flex justify-content-between">
                  <label style={{ width: '30%' }}>{t('last-name')}</label>
                  <FormTextInput
                    name="directorLastName"
                    placeholder={t(
                      'components.nursingHomeCreation.directorAccount.lastName.placeholder',
                    )}
                    width="100"
                  />
                </FormGroup>
                <FormGroup className="d-flex justify-content-between">
                  <label style={{ width: '30%' }}>{t('email')}</label>
                  <FormTextInput
                    name="directorEmail"
                    placeholder={t(
                      'components.nursingHomeCreation.directorAccount.email.placeholder',
                    )}
                    width="100"
                  />
                </FormGroup>
                <FormGroup className="d-flex justify-content-between">
                  <label style={{ width: '30%' }}>{t('language')}</label>
                  <FormSelectInput
                    placeholder={t(
                      'components.nursingHomeCreation.directorAccount.language.placeholder',
                    )}
                    options={languageOptions}
                    name="directorLanguage"
                    width="100"
                  />
                </FormGroup>
                <FormGroup className="d-flex justify-content-between">
                  <label style={{ width: '30%' }}>{t('pin-code')}</label>
                  <FormTextInput
                    name="directorPin"
                    type="pin"
                    placeholder={t(
                      'components.nursingHomeCreation.directorAccount.pin.placeholder',
                    )}
                    maxLength={4}
                    width="100"
                  />
                </FormGroup>
              </Col>
              <Col>
                <div className="d-flex justify-content-center">
                  <AvatarInput
                    name="directorCommonAvatarId"
                    avatars={avatars}
                    isLoading={isAvatarLoading}
                  />
                </div>
                <FormGroup>
                  <div
                    className="d-flex justify-content-center"
                    style={{ marginBottom: '32px' }}
                  >
                    <RadioButton
                      name="directorGender"
                      elements={genderOption}
                      defaultValue="F"
                    />
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </FormProvider>
      </div>
      <FormButtonsWrapper>
        <Button onClick={onCancel}>{t('common.action.previous')}</Button>
        <Button
          disabled={!isValid || isLoading}
          form="director-account-form"
          type="submit"
        >
          {t('common.action.next')}
        </Button>
      </FormButtonsWrapper>
    </div>
  );
};

export default NursingHomeCreationDirector;
