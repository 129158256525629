import { Language } from 'src/sdk/common';

/**
 * Date format with a day precision.
 */
export const DATE_FORMAT_DAY = 'dd/MM/yyyy';

export const DATE_TIME = 'hh:mm aa';
export const DATE_TIME_LOCALE: { [keyof in Language]: string } = {
  de: 'hh:mm aa',
  en: 'hh:mm aa',
  es: 'hh:mm aa',
  fr: 'HH:mm',
  it: 'hh:mm aa',
  nl_BE: 'hh:mm',
};

/**
 * Date format with a second precision.
 */
export const DATE_FORMAT_SECOND = 'dd/MM/yyyy HH:mm:ss';

export const DATE_BACKEND = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'";
export const DATE_BACKEND_FIRST_DAY = "yyyy-MM-dd'T'00:00:00.000'Z'";
