import { useCallback, useState } from "react";
import Avatar from "react-avatar-edit";
import { ModalFooter } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Button } from "src/uikit/Button";
import styled from "styled-components/macro";

import DefaultModal from "./DefaultModal"


const Footer = styled(ModalFooter)`
  border: none;
`;

interface Props {
  show: boolean;
  onClose: () => void;
  onContinue: (preview: string) => void
}

const ModalUploadAvatar = ({ show, onClose, onContinue }: Props) => {
  const { t } = useTranslation();
  const [preview = '', setPreview] = useState<string>();

  const handleCloseAvatar = useCallback(() => {
    setPreview('');
  }, []);

  const handleCropAvatar = useCallback((preview) => {
    setPreview(preview);
  }, []);

  return (
    <DefaultModal size="sm" isVisible={show} handleClose={onClose}>
      <Avatar
        width={250}
        height={250}
        exportSize={512}
        onCrop={handleCropAvatar}
        onClose={handleCloseAvatar}
      />
      <Footer>
        <Button onClick={() => onContinue(preview)}>{t('common.action.next')}</Button>
      </Footer>
    </DefaultModal>
  )
}

export default ModalUploadAvatar;
