import { Gender } from './common';

export interface LoginBody {
  username: string;
  password: string;
}

export enum Profiles {
  ORIZON_ADMIN = 'ORIZON_ADMINISTRATOR',
  PARTNER_ADMIN = 'PARTNER_ADMINISTRATOR',
  PRODUCT_SPECIALIST = 'PRODUCT_SPECIALIST',
  SALES_ASSISTANT = 'SALES_ASSISTANT',
  DIRECTOR = 'DIRECTOR',
  HEAD_NURSE = 'HEAD_NURSE',
  NURSE = 'NURSE',
  CAREGIVER = 'CAREGIVER',
}

export interface LoginResponse {
  userId: number;
  username: string;
  profile: Profiles;
  firstStepUsername: string;
  authenticationToken?: string;
  tenantName: string;
  roles: string[];
  connection: {
    id: string;
    name: string;
    clientType: string;
    creationDate: string;
    expirationDate: string;
    switchDate: string;
    useDate: string;
  };
  user: {
    id: string;
    username: string;
    firstName: string;
    lastName: string;
    gender: Gender;
    language: string;
    partnerUser: boolean;
  };
  nursingHome: {
    id: string;
    name: string;
    tenantName: string;
  };
  avatarImagePath?: string;
}
