import { useQuery, QueryObserverOptions } from 'react-query';
import request from 'src/axios';
import { UserResponse, UserExpand } from 'src/sdk/users';

export type UserSortField = {
  label: keyof UserResponse;
  direction: 'asc' | 'desc';
};

type QueryParams = {
  id: number;
  expand: UserExpand[];
};

export async function fetchUser({
  id,
  expand,
}: QueryParams): Promise<UserResponse> {
  const { data } = await request.get(`/users/${id}`, {
    params: {
      expand: expand.join(', '),
    },
  });

  return data;
}

const useFetchUserQuery = (
  id: number,
  expand: UserExpand[],
  options?: QueryObserverOptions<UserResponse, Error>,
) => {
  const { data, isLoading, refetch, error } = useQuery<UserResponse, Error>(
    [`user${id}`, { id, expand }],
    () =>
      fetchUser({
        id,
        expand,
      }),
    { ...options },
  );

  return {
    data,
    isLoading,
    refetch,
    error,
  };
};

export default useFetchUserQuery;
