import { format } from 'date-fns';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SubscriptionIcon } from 'src/assets/subscription-icon.svg';
import { DATE_FORMAT_DAY } from 'src/constants';
import {
  BillingAssemblySubscriptionResponse,
  SubscriptionStatus,
} from 'src/sdk/subscription';
import { HeaderTableLabel } from 'src/uikit/Typography';
import styled, { css } from 'styled-components/macro';

import SubscriptionStatusBadge from '../History/SubscriptionStatusBadge';

const StyledSubscriptionIcon = styled(SubscriptionIcon)<{ disabled: boolean }>`
  border-radius: 50%;
  color: ${({ theme }) => theme.palette.main.white};
  background-color: ${({ theme }) => theme.palette.main.primary};
  height: 40px;
  width: 40px;
  padding: 8px;

  ${({ disabled, theme }) =>
    disabled &&
    css`
      background-color: ${({ theme }) => theme.palette.black[60]};
    `}
`;

const Wrapper = styled.div`
  padding-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const WrapperSubscriptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-left: 40px;
`;

const Separator = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.palette.black[50]};
`;

const StyledRow = styled(Row)`
  display: flex;
  align-items: center;
  width: 100%;
`;

const StyledCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface Props {
  data?: BillingAssemblySubscriptionResponse[];
}

const SubscriptionBillingSubcards = ({ data }: Props) => {
  const { t } = useTranslation();

  return (
    <Wrapper className="pb-2">
      <Separator />
      {data && data.length > 0 && (
        <WrapperSubscriptions>
          <StyledRow>
            <StyledCol md={{ span: 2, offset: 1 }}>
              <HeaderTableLabel>
                {t('components.sortingHeader.sortByType')}
              </HeaderTableLabel>
            </StyledCol>
            <StyledCol md="2">
              <HeaderTableLabel>
                {t('components.sortingHeader.sortByStartingDate')}
              </HeaderTableLabel>
            </StyledCol>
            <StyledCol md="2">
              <HeaderTableLabel>
                {t('components.sortingHeader.sortByEndingDate')}
              </HeaderTableLabel>
            </StyledCol>
            <StyledCol md="2">
              <HeaderTableLabel>
                {t('components.sortingHeader.sortByNumberOfResidents')}
              </HeaderTableLabel>
            </StyledCol>
          </StyledRow>
          {data.map(
            ({ type, startDate, endDate, residentEstimate, status }, index) => (
              <StyledRow key={index}>
                <StyledCol md="1">
                  <StyledSubscriptionIcon
                    disabled={status === SubscriptionStatus.TERMINATED}
                  />
                </StyledCol>
                <StyledCol md="2">{type}</StyledCol>
                <StyledCol md="2">
                  {format(new Date(startDate || ''), DATE_FORMAT_DAY)}
                </StyledCol>
                <StyledCol md="2">
                  {format(new Date(endDate || ''), DATE_FORMAT_DAY)}
                </StyledCol>
                <StyledCol md="2">{residentEstimate}</StyledCol>
                <StyledCol md="2">
                  <SubscriptionStatusBadge
                    status={status as SubscriptionStatus}
                  />
                </StyledCol>
              </StyledRow>
            ),
          )}
        </WrapperSubscriptions>
      )}
    </Wrapper>
  );
};

export default SubscriptionBillingSubcards;
