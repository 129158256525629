import { Col, Stack } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import palette from 'src/uikit/theme/palette';
import { fontPrimary, fontSecondary } from 'src/uikit/theme/typography';
import { H2 } from 'src/uikit/Typography';
import styled from 'styled-components/macro';

import Counter from '../Counter';

const Wrapper = styled('div')<{ color: string }>`
  border-radius: 0.75rem;
  min-width: 300px;
  max-width: 300px;

  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0.75rem;
  gap: 0.5rem;
  border: 1px solid ${({ color }) => color};
`;

const WrapperCounter = styled(Stack)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`;

const Header = styled(Stack)`
  flex-direction: row;
  display: flex;
  align-items: center;
  padding: 0;
  gap: 16px;
`;

const ItemTitle = styled(H2)`
  ${fontSecondary}
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.black[90]};
  margin: 0;
`;

const CounterWrapper = styled(Stack)`
  flex-direction: row;
  width: 100%;
  justify-content: end;
`;

const SupplyLabel = styled(Col)`
  ${fontPrimary}
  color: ${({ theme }) => theme.palette.black[90]};
  text-align: center;
`;

const CurrentLabel = styled(SupplyLabel)`
  align-items: flex-start;
`;

interface Props {
  name: string;
  title: string;
  badge: any;
  mainColor?: string;
  secondaryColor?: string;
  disabled?: boolean;
  hidden?: boolean;
  initial: number;
  max?: number;
  current?: number;
  defaultValue?: number;
}

const CounterEquipmentInputExtra = ({
  name,
  title,
  badge,
  mainColor,
  secondaryColor,
  disabled,
  hidden,
  initial,
  max,
  current,
  defaultValue,
}: Props) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={(defaultValue || 0) + initial}
      render={({ value, onChange, ref }) => (
        <Wrapper color={mainColor || palette.main.primary}>
          <WrapperCounter>
            <Header>
              {badge}
              <ItemTitle>{title}</ItemTitle>
            </Header>
            <SupplyLabel md="2">{initial}</SupplyLabel>
          </WrapperCounter>
          {!disabled && (
            <CounterWrapper>
              {current && (
                <CurrentLabel md="6">
                  {t('components.equipmentCardCounter.currentCount')}: {current}
                </CurrentLabel>
              )}
              <Counter
                name={name}
                buttonsColor={mainColor}
                bgColor={secondaryColor}
                hidden={hidden}
                min={0}
                max={max}
                step={1}
                value={value}
                onChange={(value) => onChange(value)}
              />
            </CounterWrapper>
          )}
        </Wrapper>
      )}
    />
  );
};

export default CounterEquipmentInputExtra;
