import { ReactNode } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Card, CardBody } from 'src/uikit/Card';
import styled from 'styled-components/macro';

const StyledCard = styled(Card)`
  min-height: 100px;
`;

export type ActionCardProps = {
  children: ReactNode;
  action: ReactNode;
};

const ActionCard = ({ children, action, ...rest }: ActionCardProps) => {
  return (
    <StyledCard {...rest}>
      <CardBody>
        <Row>
          <Col>{children}</Col>
          <Col xs="auto" className="px-0">
            {action}
          </Col>
        </Row>
      </CardBody>
    </StyledCard>
  );
};

export default ActionCard;
