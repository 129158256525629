import { ReactComponent as CheckedIcon } from 'src/assets/avatar-checked.svg';
import { ReactComponent as WarningIcon } from 'src/assets/warning.svg';
import palette from 'src/uikit/theme/palette';
import styled from 'styled-components/macro';

const Circle = styled.div`
  position: absolute;
  border-radius: 100%;
  height: 80px;
  width: 80px;
`;

const Wrapper = styled.div`
  position: relative;
  border-radius: 100%;
  height: 80px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 32px 0;
`;

const Checked = styled(CheckedIcon)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  transform: scale(0.4);
  color: ${palette.main.white};
`;

const Warning = styled(WarningIcon)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  transform: scale(0.4);
  color: ${palette.main.white};
`;

interface Props {
  type: 'success' | 'warning';
}

const CircleStatus = ({ type = 'success' }: Props) => {
  return (
    <Wrapper
      style={{
        outline: `40px solid ${
          type === 'success' ? palette.main.lightGreen : palette.main.lightRed
        }`,
      }}
    >
      <Circle
        style={{
          background:
            type === 'success' ? palette.main.greenElectric : palette.main.red,
          boxShadow: `0px 5px 12px ${
            type === 'success' ? palette.main.greenElectric : palette.main.red
          }`,
        }}
      />
      {type === 'success' ? <Checked /> : <Warning />}
    </Wrapper>
  );
};

export default CircleStatus;
