import { useCallback, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PlusBadge } from 'src/assets/plus-badge.svg';
import ListPaginated from 'src/components/Misc/ListPaginated';
import { DEFAULT_PAGE_SIZE } from 'src/components/Misc/Pagination';
import useFetchPartnersQuery, {
  PartnersSortField,
} from 'src/components/Partners/hooks/useFetchPartnersQuery';
import PartnerCreateModal from 'src/components/Partners/PartnerCreateModal';
import PartnersList from 'src/components/Partners/PartnersList';
import SortingPartnersHeader from 'src/components/SortingHeaders/SortingPartnersHeader';
import { useModal } from 'src/hooks/useModal';
import ButtonIcon from 'src/uikit/ButtonIcon';
import Center from 'src/uikit/Layout/Center';
import SearchInput from 'src/uikit/SearchInput';
import { H1 } from 'src/uikit/Typography';
import { PageWrapper } from 'src/uikit/Wrapper';
import styled from 'styled-components/macro';

const SearchColumn = styled(Col)`
  display: flex;
  justify-content: flex-start;
`;

const ButtonColumn = styled(Col)`
  display: flex;
  justify-content: flex-end;
`;

const PAGE_SIZE = DEFAULT_PAGE_SIZE;

const Partners = () => {
  const { t } = useTranslation();
  const { modalOpen, setModalOpen } = useModal();
  const [page, setPage] = useState(0);

  const [nameOrSoldtoPart = '', setNameOrSoldtoPart] = useState<string>();

  const handleChangeSearch = (value: string) => {
    setNameOrSoldtoPart(value);
  };

  const [sort, setSort] = useState<PartnersSortField>({
    label: 'name',
    direction: 'asc',
  });

  const { data, isLoading, refetch } = useFetchPartnersQuery({
    page,
    size: PAGE_SIZE,
    nameOrSoldtoPart,
    expand: ['counts', 'address', 'delivery-addresses'],
    sort,
  });

  const handleFinishCreation = useCallback(() => {
    setModalOpen(false);
    refetch();
  }, [refetch, setModalOpen]);

  return (
    <PageWrapper>
      <Center>
        <H1>{t('pages.partners.title')}</H1>
      </Center>
      <Row className="justify-content-between my-3">
        <SearchColumn md="2">
          <SearchInput onChange={handleChangeSearch} />
        </SearchColumn>
        <ButtonColumn md="3">
          <ButtonIcon
            prepend={<PlusBadge />}
            handleClick={() => setModalOpen(true)}
            label={t('pages.partners.partnerButton')}
          />
        </ButtonColumn>
      </Row>

      <ListPaginated
        header={
          <SortingPartnersHeader onSortingChange={setSort} sortField={sort} />
        }
        data={data}
        isLoading={isLoading}
        page={page}
        setPage={setPage}
      >
        <PartnersList partners={data?.content} />
      </ListPaginated>
      <PartnerCreateModal onFinish={handleFinishCreation} show={modalOpen} />
    </PageWrapper>
  );
};

export default Partners;
