import { ReactNode } from 'react';
import { Col } from 'react-bootstrap';
import styled from 'styled-components/macro';

const BadgeWrapper = styled(Col)`
  flex: 0 0 60px;
  display: flex;
  justify-content: center;
`;

interface Props {
  children?: ReactNode;
}

const EquipmentBadgeCol = ({ children }: Props) => {
  return <BadgeWrapper>{children}</BadgeWrapper>;
};

export default EquipmentBadgeCol;
