import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import SubscriptionBillingList from 'src/components/Subscription/Billing/SubscriptionBillingList';
import { H1 } from 'src/uikit/Typography';
import styled from 'styled-components/macro';

const Wrapper = styled(Container)`
  display: grid;
  gap: 1rem;
  padding: 40px;
  overflow-x: auto;
  text-align: center;
`;

const Title = styled(H1)`
  line-height: 30px;
  color: ${({ theme }) => theme.palette.black[90]};
`;

const SubscriptionBilling = () => {
  const { t } = useTranslation();

  return (
    <Wrapper fluid>
      <Title className="mb-2">{t('pages.subscription.billing.title')}</Title>
      <SubscriptionBillingList />
    </Wrapper>
  );
};

export default SubscriptionBilling;
