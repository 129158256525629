import { useTranslation } from 'react-i18next';
import { ShippingRequestResponse } from 'src/sdk/shippingRequest';
import { Badge, BadgeStatus } from 'src/uikit/Badge';

const colors = {
  CREATED: BadgeStatus.primary,
  ERROR: BadgeStatus.red,
  RECEIVED: BadgeStatus.greenElectric,
  REQUESTED: BadgeStatus.grey,
  SHIPPED: BadgeStatus.orange,
};

type ShippingStatusProps = {
  status: ShippingRequestResponse['status'];
};

const ShippingStatus = ({ status }: ShippingStatusProps) => {
  const { t } = useTranslation();
  return (
    <Badge bg={status ? colors[status] : BadgeStatus.grey}>
      {t(`shippingRequest.status.${status}`)}
    </Badge>
  );
};
export default ShippingStatus;
