import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useParams } from 'react-router-dom';
import Routes from 'src/router/Routes';
import { RouteParams } from 'src/sdk/nursingHome';
import HorizontalMenu, { HorizontalMenuItems } from 'src/uikit/HorizontalMenu';

const SubscriptionHorizontalMenu = () => {
  const { id } = useParams<RouteParams>();
  const { t } = useTranslation();

  const MenuItem: HorizontalMenuItems[] = useMemo(() => {
    return [
      {
        routes: generatePath(Routes.NURSING_SUBSCRIPTION_HISTORY, { id }),
        label: t('pages.subscription.history.pageLabel'),
      },
      {
        routes: generatePath(Routes.NURSING_SUBSCRIPTION_BILLING, { id }),
        label: t('pages.subscription.billing.pageLabel'),
      },
    ];
  }, [id, t]);

  return <HorizontalMenu menuItems={MenuItem} />;
};

export default SubscriptionHorizontalMenu;
