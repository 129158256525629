import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import { useMemo } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useSimulateNursingHomeCreation from 'src/components/NursingHome/Creation/hooks/useSimulateNursingHomeCreation';
import useFormatErrorApi from 'src/hooks/useFormatErrorApi';
import useLanguageOptions from 'src/hooks/useLanguageOptions';
import useTimeZoneOptions from 'src/hooks/useTimeZoneOptions';
import useToast from 'src/hooks/useToast';
import ErrorCode from 'src/sdk/error';
import {
  CreateNursingHome,
  NursingHomeCreationType,
} from 'src/sdk/nursingHome';
import { Button } from 'src/uikit/Button';
import { Form, FormGroup } from 'src/uikit/Form';
import FormSelectInput from 'src/uikit/Form/SelectInput';
import FormTextInput from 'src/uikit/Form/TextInput';
import { Text } from 'src/uikit/Typography';
import { FormButtonsWrapper } from 'src/uikit/Wrapper';
import styled from 'styled-components/macro';
import * as yup from 'yup';

import useLegalEntityOptions from '../hooks/useLegalEntityOptions';
import {
  NursingHomeCreationStep,
  useNursingHomeCreation,
} from './NursingHomeCreationContext';


const Description = styled(Text)``;

type FormType = {
  nursingHomeUsername: string;
  name: string;
  language: CreateNursingHome['language'];
  timeZone: string;
  legalEntity?: string;
};

type Props = {
  data: CreateNursingHome;
  onSuccess: (data: Partial<CreateNursingHome>) => void;
  onCancel: () => void;
  type: NursingHomeCreationType;
};

const NursingHomeCreationAccount = ({
                                      data,
                                      onSuccess,
                                      onCancel,
                                      type,
                                    }: Props) => {
  const { t } = useTranslation();
  const languageOptions = useLanguageOptions();
  const { simulateNursingHome, isLoading } =
    useSimulateNursingHomeCreation(type);
  const timezoneOptions = useTimeZoneOptions();
  const legalEntityOptions = useLegalEntityOptions();

  const { formatErrorApi } = useFormatErrorApi();
  const { toastError } = useToast();

  const { nursingHomeCreationSteps } = useNursingHomeCreation();

  const formSchema = useMemo(
    () =>
      yup.object().shape({
        nursingHomeUsername: yup
          .string()
          .min(
            2,
            t('form.error.mustBeAtLeast', {
              fieldName: t(
                'components.nursingHomeCreation.nursingHomeAccount.login.placeholder',
              ),
              nbCharMin: 2,
            }),
          )
          .matches(/^\S*$/, 'The NH login cannot contain white space'),
        name: yup.string().min(
          2,
          t('form.error.mustBeAtLeast', {
            fieldName: t(
              'components.nursingHomeCreation.nursingHomeAccount.name.placeholder',
            ),
            nbCharMin: 2,
          }),
        ),
        language: yup.string().required(),
        timeZone: yup.string().required(),
        legalEntity: yup.string()
          .when([], {
            is: () => type == 'Prospect',
            then: yup.string().required(),
          }),
      }),
    [t],
  );

  const methods = useForm<FormType>({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    defaultValues: data,
  });
  const { formState, handleSubmit, setError } = methods;
  const { isValid } = formState;

  const onSubmit: SubmitHandler<FormType> = async (formData) => {
    let postData = { ...data, ...formData };
    if (type == 'PartnerProspect') {
      postData.legalEntity = undefined;
    }
    console.log('submit', postData);
    simulateNursingHome(
      postData,
      nursingHomeCreationSteps,
      NursingHomeCreationStep.NursingHomeAccount,
    )
      .then(() => onSuccess(formData))
      .catch((error: AxiosError) => {
        if (error?.code === ErrorCode.NursingHomeUsernameAlreadyUsed) {
          return setError('nursingHomeUsername', {
            message: t(
              'components.nursingHomeCreation.nursingHomeAccount.loginAlreadyUsed',
            ),
          });
        }

        const message = formatErrorApi(error);
        toastError(message);
      });
  };

  return (
    <div>
      <div style={{
        background: 'white',
        borderRadius: '0px 0px 12px 12px',
        padding: '16px',
      }}>
        <Description className="mt-2">
          {t('configure-nursing-home-credentials')}
        </Description>
        <FormProvider {...methods}>
          <Form
            onSubmit={handleSubmit(onSubmit)}
            id="home-account-form"
            className="mt-4"
          >
            <FormGroup className="d-flex justify-content-between">
              <label style={{ width: '65%' }}>{t('choose-name')}</label>
              <FormTextInput
                placeholder={t(
                  'components.nursingHomeCreation.nursingHomeAccount.name.placeholder',
                )}
                name="name"
                maxLength={64}
                width="100"
              />
            </FormGroup>
            <FormGroup className="d-flex justify-content-between">
              <div style={{ width: '65%' }}>
                <label>{t('choose-login')}</label>
                <p style={{ fontSize: '0.9rem', color: 'grey' }}>
                  {t('identifier-used-to-connect')}
                </p>
              </div>
              <FormTextInput
                placeholder={t(
                  'components.nursingHomeCreation.nursingHomeAccount.login.placeholder',
                )}
                name="nursingHomeUsername"
                maxLength={64}
                width="100"
              />
            </FormGroup>
            <FormGroup className="d-flex justify-content-between">
              <label style={{ width: '65%' }}>{t('choose-language')}</label>
              <FormSelectInput
                placeholder={t('components.profileForm.language')}
                options={languageOptions}
                name="language"
              />
            </FormGroup>
            <FormGroup className="d-flex justify-content-between">
              <label style={{ width: '65%' }}>{t('choose-timezone')}</label>
              <FormSelectInput
                placeholder={t(
                  'components.nursingHomeCreation.nursingHomeAccount.timezone.placeholder',
                )}
                options={timezoneOptions.data || []}
                name="timeZone"
              />
            </FormGroup>
            {type != 'PartnerProspect' &&
              <FormGroup className="d-flex justify-content-between">
                <div style={{ width: '65%' }}>
                  <label>{t('choose-legal-entity')}</label>
                  <p style={{ fontSize: '0.9rem', color: 'grey' }}>
                    {t('legal-entity-explanation')}
                  </p>
                </div>
                <FormSelectInput
                  placeholder={t(
                    'components.nursingHomeCreation.nursingHomeAccount.legalEntity.placeholder',
                  )}
                  options={legalEntityOptions.data || []}
                  name="legalEntity"
                />
              </FormGroup>
            }
          </Form>
        </FormProvider>
      </div>
      <FormButtonsWrapper>
        <Button onClick={onCancel}>{t('common.action.previous')}</Button>
        <Button
          disabled={!isValid || isLoading}
          form="home-account-form"
          type="submit"
        >
          {t('common.action.next')}
        </Button>
      </FormButtonsWrapper>
    </div>
  );
};

export default NursingHomeCreationAccount;
