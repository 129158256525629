import { useMemo } from 'react';
import { Row, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useFormatErrorApi from 'src/hooks/useFormatErrorApi';
import { useModal } from 'src/hooks/useModal';
import useToast from 'src/hooks/useToast';
import { UserResponse } from 'src/sdk/users';
import { Button, ButtonColors } from 'src/uikit/Button';
import { H4 } from 'src/uikit/Typography';
import styled from 'styled-components/macro';

import useForceVerificationEmailMutation from '../../hooks/useForceVerificationEmailMutation';
import useStartVerificationEmailMutation from '../../hooks/useStartVerificationEmailMutation';
import UserConfirmationModal from './UserConfirmationModal';

const Wrapper = styled(Row)`
  padding-inline: 1rem;
  display: flex;
`;

const HeaderRow = styled(Row)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled(H4)``;

const Subtitle = styled.p`
  color: ${({ theme }) => theme.palette.black[70]};
`;

const Message = styled.p`
  color: ${({ theme }) => theme.palette.main.red};
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
`;

interface Props {
  user: UserResponse;
}

const UserEmailVerification = ({ user }: Props) => {
  const { t } = useTranslation();
  const { toastSuccess, toastError } = useToast();
  const { modalOpen, setModalOpen } = useModal();
  const { formatErrorApi } = useFormatErrorApi();

  const { mutateAsync: mutateAsyncForce } = useForceVerificationEmailMutation();
  const { mutateAsync: mutateAsyncVerification } =
    useStartVerificationEmailMutation();

  const fullName = useMemo(() => `${user.firstName} ${user.lastName}`, [user]);

  const handleStartVerification = async () => {
    try {
      await mutateAsyncVerification({ userId: user.id });
      toastSuccess(
        t('components.Users.UserDetails.UserEmailVerification.startSuccess'),
      );
    } catch (err) {
      const message = formatErrorApi(err);
      toastError(message);
    }
  };

  const handleForce = async () => {
    try {
      await mutateAsyncForce({ userId: user.id });
      toastSuccess(
        t('components.Users.UserDetails.UserEmailVerification.success'),
      );
      setModalOpen(false);
    } catch (err) {
      const message = formatErrorApi(err);
      toastError(message);
    }
  };

  return (
    <Wrapper className="h-100">
      <HeaderRow>
        <Title>
          {t('components.Users.UserDetails.UserEmailVerification.title')}
        </Title>
      </HeaderRow>
      <Row>
        <Subtitle>
          {t('components.Users.UserDetails.UserEmailVerification.subtitle')}
        </Subtitle>
      </Row>
      <Stack
        direction="horizontal"
        gap={2}
        className="w-100 justify-content-center mt-auto"
      >
        <Button disabled={user.emailVerified} onClick={handleStartVerification}>
          {t(
            'components.Users.UserDetails.UserEmailVerification.buttonSendEmail',
          )}
        </Button>
        <Button
          disabled={user.emailVerified}
          className="mx-2"
          onClick={() => setModalOpen(true)}
        >
          {t(
            'components.Users.UserDetails.UserEmailVerification.buttonForceEmail',
          )}
        </Button>
      </Stack>
      <UserConfirmationModal
        isVisible={modalOpen}
        onClose={() => setModalOpen(false)}
      >
        <Row className="justify-content-center mb-2">
          <Message>
            {t('components.Users.UserDetails.UserEmailVerification.message', {
              fullName,
            })}
          </Message>
        </Row>
        <Stack direction="horizontal" gap={2} className="justify-content-end">
          <Button
            background={ButtonColors.CANCEL}
            onClick={() => setModalOpen(false)}
          >
            {t('components.Users.UserDetails.UserEmailVerification.cancel')}
          </Button>
          <Button className="ml-2" onClick={handleForce}>
            {t('components.Users.UserDetails.UserEmailVerification.confirm')}
          </Button>
        </Stack>
      </UserConfirmationModal>
    </Wrapper>
  );
};

export default UserEmailVerification;
