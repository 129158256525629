import { useEffect, useState, useMemo } from 'react';
import { Spinner } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { ReactComponent as CliponBlueIcon } from 'src/assets/clipon-blue.svg';
import request from 'src/axios';
import Pagination from 'src/components/Misc/Pagination';
import SortingButton from 'src/components/SortingHeaders/SortingButton';
import useSortedList from 'src/hooks/useSortedList';
import { Button, ButtonColors } from 'src/uikit/Button';
import { Card } from 'src/uikit/Card';
import FormSelectInput from 'src/uikit/Form/SelectInput';
import SearchInput from 'src/uikit/SearchInput';
import SwitchInput from 'src/uikit/SwitchInput';
import { PageWrapper } from 'src/uikit/Wrapper';
import styled from 'styled-components/macro';

const PAGE_SIZE = 50;

const HoveredCard = styled(Card)`
  width: 100%;
  cursor: pointer;
  margin-bottom: 15px;
  padding: 12px 12px;
  background-color: ${({ theme }) => theme.palette.main.white};
  border-radius: 10px;
  opacity: 1;
  box-shadow: 0.3px 0.4px 0.6px hsl(0deg 0% 70% / 0.33),
    1.2px 1.6px 3px -2px hsl(0deg 0% 70% / 0.41);
  transition: transform 0.1s ease-in, opacity 0.1s ease-in,
    box-shadow 0.1s ease-in-out;
  &:hover {
    box-shadow: 0.3px 0.4px 0.6px hsl(0deg 0% 70% / 0.31),
      1.3px 2.2px 3.1px -1px hsl(0deg 0% 70% / 0.36),
      4.6px 7.8px 10.9px -2px hsl(0deg 0% 70% / 0.41);
    transform: scaleX(1.01);
    opacity: 1;
  }
`;

const StyledRow = styled.div`
  display: flex;
  margin: 24px 0px;
`;

const PrimaryStyledCol = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 66%;
`;

const StyledCol = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 33%;
`;

const Title = styled.div`
  color: ${({ theme }) => theme.palette.main.primary};
  letter-spacing: 0.8px;
  margin: 0;
  font-weight: 600;
`;


const FirmwaresDeploy = () => {

  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [deploy, setDeploy] = useState<any>({});
  const [query, setQuery] = useState('');
  const [selectAll, setSelectAll] = useState(false);

  // State to track the current sorting order
  const [sortingOrder, setSortingOrder] = useState<'asc' | 'desc'>('asc');


  const { mutateAsync } = useMutation(({ id, ...params }: any) =>
    request.put(`/firmwares/${id}/deploy`, params),
  );

  /**
   * Submits the form asynchronously.
   *
   * @param {any} a - The parameter to be passed to the function.
   * @return {Promise<void>} A promise that resolves when the function is completed.
  */
  const onSubmit = async (a: any): Promise<void> => {

    if(a.nh.length !== 0){
      await mutateAsync({ id: selectedVersion.value, nursingHomeIds: a.nh });
      refetch();
   }else{    
    let message = t('components.firmwareList.modalUploadNewFirmware.errorMessages.selectNursingHome');
    toast.error(message);
   }
  };


  
  const { data: nsf, refetch } = useQuery(
    ['firmware for nursing homes', { page, query }],
    async () => {
      return request
        .get('/nursing-homes?expand=counts,firmware,clipons', {
          params: {
            page: page,
            size: PAGE_SIZE,
            'name-part': query.length > 0 ? query : undefined,
          },
        })
        .then((res) => res.data);
    },
  );

  type FirmwareContent = {
    label : string,
    value: number
  }


  type NursingHome = {
    id : number,
    name: string,
    firmware: string,
    selected : boolean
  }



  const { data, isLoading  } = useQuery('firmwares-history', async () => {
    return request
      .get('/firmwares?expand=counts&sort=archived,asc')
      .then((res) =>res.data);
  });

  // Filter firmwares not archived
  const firmwares = data?.content?.filter((e: any) => e.archived === false).map((e: any) => ({
    // Create a label combining version major, minor, and revision
    label: `${e.versionMajor}.${e.versionMinor}.${e.versionRevision}`,
    // Use firmware ID as the corresponding value
    value: e.id,
  }));

  
  const [selectedVersion, setSelectedVersion] = useState<FirmwareContent>({
    label: "0",
    value: 0,
  });
  
  const { sortedItems } = useSortedList(nsf);

  const nhListContent = (sortedItems as any)?.content;
  
  const initialNursingHomes = useMemo(() => {
    return nhListContent?.map((e: any) => ({
      id: e.id,
      name: e.name,
      firmware: e.firmware
        ? `${e.firmware.versionMajor}.${e.firmware.versionMinor}.${e.firmware.versionRevision}`
        : '-',
      selected: false,
    }));
  }, [nhListContent]);

  
  // State to store the nursing home data
  const [nursingHomes, setNursingHomes] = useState<NursingHome[]>(initialNursingHomes);
  
  const methods = useForm({
    defaultValues: {
      firmware: firmwares?.[0]?.value,
      nh: [],
    },
  });


  /**
   * Function to handle sorting by firmware version
   * 
   * @param key - Column key to sort 
  */
  const onSortingChange = (key: string) => {
    let sortedList : any[] = [];
    if(key === 'concatVersion'){
      sortedList = [...nursingHomes].sort((a, b) => {
        const versionA = a.firmware.split('.').map(Number);
        const versionB = b.firmware.split('.').map(Number);

        const sortOrderFactor = sortingOrder === 'asc' ? 1 : -1;
        
        for (let i = 0; i < Math.max(versionA.length, versionB.length); i++) {
          const numA = versionA[i] || 0;
          const numB = versionB[i] || 0;

          if (numA > numB) {
            return -1 * sortOrderFactor; // Reverse the order if sorting in descending
          } else if (numA < numB) {
            return 1 * sortOrderFactor; // Reverse the order if sorting in descending
          }
        }
        // If versions are equal, maintain the original order
        return 0;
      });

    } else if (key === 'name') {
      sortedList = [...nursingHomes].sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
  
        const sortOrderFactor = sortingOrder === 'asc' ? 1 : -1;
  
        if (nameA < nameB) {
          return -1 * sortOrderFactor; // Reverse the order if sorting in descending
        } else if (nameA > nameB) {
          return 1 * sortOrderFactor; // Reverse the order if sorting in descending
        }
        // If names are equal, maintain the original order
        return 0;
      });
    }
  
    // Update the state with the new sorted array and toggle the sorting order
    setNursingHomes(sortedList);
    setSortingOrder(sortingOrder === 'asc' ? 'desc' : 'asc');
  };

  // const field = useWatch({ name: ['nh'], control: methods.control });
  useEffect(() => {
    if (!Object.keys(deploy)) {
      const deploy = initialNursingHomes ?.reduce((acc: any, cur: any) => {
        acc[cur?.name] = false;
        return acc;
      }, {});
      setDeploy(deploy);
    }
    setNursingHomes(initialNursingHomes);
  }, [initialNursingHomes]);


  useEffect(() => {
    setPage(0);
  }, [query]);



  /**
  * Updates the selected version and nursing homes based on the provided firmware content.
  *
  * @param {FirmwareContent} version - The firmware content to be selected.
  */
  const listSelectedVersion = (verion : FirmwareContent)  =>{   
    setSelectedVersion(verion);
    setSelectAll(false);
    setNursingHomes(prevDevices =>
      prevDevices.map(device => ({ ...device, selected: false }))
    );
  }


  /**
  * Function to select all devices
  * 
  * @param event - The event object
  */
  const selectAllFirmware = (event : boolean) => {
    setSelectAll(event);
    setNursingHomes(prevDevices =>
      prevDevices.map(device => (
      selectedVersion.label > device.firmware ? { ...device, selected: event } : { ...device, selected: false }))
    );
  };


  /**
   * Function to toggle the 'selected' attribute based on the id
   * 
   * @param deviceId - The id of the device
  */
  const selectedFirmware = (deviceId : any ) => {
    setNursingHomes(prevDevices =>
      prevDevices.map(device =>
        device.id === deviceId
          ? { ...device, selected: !device.selected } // Toggle the selected attribute
          : device // Keep other devices unchanged
      )
    );
  };

  /**
   * Updates the search value and triggers a re-render.
   *
   * @param {string} value - The new search value.
   * @return {void} No return value.
  */
  const handleChangeSearchValue = (value: string): void => {
    setQuery(value);
  }

  return (
    <PageWrapper>
      <h4 style={{ marginBottom: '36px' }}>
        <strong>{t('deploy-a-new-firmware')}</strong>
      </h4>
      {isLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Spinner animation="border" variant="secondary" />
        </div>
      ) : (
        <>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <div
                style={{
                  width: '50%',
                  display: 'flex',
                  justifyContent: 'start',
                }}
              >
                <span
                  style={{
                    display: 'block',
                    marginRight: '12px',
                    marginTop: '8px',
                  }}
                >
                  {t('select-the-version-to-deploy')}
                </span>
                <div
                  style={{
                    width: '30%',
                    height: '100%',
                  }}
                >
                <FormSelectInput
                  name="firmware"
                  placeholder={t('firmware')}
                  options={firmwares}
                  onChange={(version : FirmwareContent ) => listSelectedVersion(version)}
                  value={selectedVersion}
                />
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                  margin: '30px 0px',
                }}
              >
                <div style={{ display: 'flex', width: '20%', marginRight: '12px' }}>
                  <SearchInput onChange={handleChangeSearchValue} type="button" />
                </div>
                <SwitchInput
                  label={t('select-all')}
                  onChange={(event) =>selectAllFirmware(event)}
                  disabled={ selectedVersion.label === "0" ? true : false }
                  checked={selectAll}
                />
              </div>
              <StyledRow>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '50%',
                  }}
                >
                  <PrimaryStyledCol>
                  <SortingButton
                      primary
                      label={t('name')}
                      field="name"
                      onClick={() => onSortingChange('name')}
                      type='button'
                    />
                  </PrimaryStyledCol>
                  <StyledCol>
                  <SortingButton
                      primary
                      field={'concatVersion'}
                      label={t('deployed-firmware')}
                      onClick={() => onSortingChange('concatVersion')}
                      type='button'
                    />
                  </StyledCol>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'end',
                    width: '50%',
                  }}
                >
                  <Title>{t('ask-deploy')}</Title>
                </div>
              </StyledRow>
              {
                nursingHomes?.map((e: any, key: number) => (
                    <HoveredCard key={key} disabled={e.firmware < selectedVersion.label ? false : true}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              width: '50%',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'start',
                                alignItems: 'center',
                                width: '66%',
                              }}
                            >
                              <CliponBlueIcon
                                style={{
                                  width: '42px',
                                  marginRight: '12px',
                                }}
                              />
                              <span style={{ textTransform: 'capitalize' }}>
                                {e.name?.toLowerCase()}
                              </span>
                            </div>
                            <div
                              style={{
                                width: '33%',
                                textAlign: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              {e.firmware}
                            </div>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'end',
                              width: '50%',
                            }}
                          >

                            <input
                              value={e.id}
                              type="checkbox"
                              name="nh"
                              checked={e.selected}
                              onClick={() =>selectedFirmware(e.id)}
                              ref={methods.register}
                              disabled={e.firmware < selectedVersion.label ? false : true}
                            />
                          </div>
                        </div>
                  </HoveredCard>
              ))}
              <div style={{ display: 'flex', justifyContent: 'end' }}>
                <Button
                  background={ButtonColors.CANCEL}
                  style={{ marginRight: '12px' }}
                  type="reset"
                  onlcick={() => {
                    selectAllFirmware(false);
                  }}
                >
                  {t('cancel')}
                </Button>
                <Button 
                    background={ButtonColors.SUCCESS} 
                    type="submit" 
                    disabled={selectedVersion.label === "0" ? true : false}>
                  {t('deploy')}
                </Button>
              </div>
            </form>
          </FormProvider>
          {nsf && nsf.totalPages > 1 && (
            <Pagination
              totalElements={nsf.totalElements}
              perPage={PAGE_SIZE}
              onPageChange={setPage}
              currentPage={page}
            />
          )}
        </>
      )}
    </PageWrapper>
  );
};

export default FirmwaresDeploy;
