import { useMemo } from 'react';
import { SubscriptionResponse, SubscriptionStatus } from 'src/sdk/subscription';

const useSubscriptionStates = (data: SubscriptionResponse[]) => {
  const canCreate = useMemo(() => {
    // there is one active demo subscription and no other created subscription
    // or the main subscription is not demo and terminated
    return (
      (data.some((subscription) => {
        return (
          ((subscription.main && subscription.type === 'DEMO') ||
            (subscription.status === SubscriptionStatus.TERMINATED &&
              subscription.type !== 'DEMO')) &&
          subscription.status !== SubscriptionStatus.BLOCKED
        );
      }) &&
        !data.some(
          (subscription) => subscription.status === SubscriptionStatus.CREATED,
        )) ||
      data.length <= 0
    );
  }, [data]);

  const canEdit = useMemo(() => {
    return (
      data.map((subscription) => {
        return (
          subscription.status !== SubscriptionStatus.BLOCKED &&
          subscription.status !== SubscriptionStatus.TERMINATED &&
          ((subscription.type === 'DEMO' &&
            subscription.status !== SubscriptionStatus.ACTIVE) ||
            subscription.type !== 'DEMO')
        );
      }) || []
    );
  }, [data]);

  return { canCreate, canEdit };
};

export default useSubscriptionStates;
