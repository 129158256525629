import { Col, OverlayTrigger, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ShippingRequestDeliveryAddressResponse } from 'src/sdk/shippingRequest';
import { Tooltip } from 'src/uikit/Tooltip';
import { Label } from 'src/uikit/Typography';
import styled from 'styled-components/macro';

import { ReactComponent as Icon } from '../../../assets/list-icons/address.svg';

const StyledLogo = styled(Icon)``;

const StyledCol = styled(Col)`
  text-align: left;
`;

interface Props {
  address: ShippingRequestDeliveryAddressResponse;
}

const ShippingAddress = ({
  address: { name, street, town, country, zip, email, telephone },
}: Props) => {
  const { t } = useTranslation();

  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={
        <Tooltip id="button-tooltip">
          <Row>
            <StyledCol xs="12">
              <Label>
                {t(
                  'components.nursingHome.equipments.shippingRequests.shippingAddress.title',
                )}
              </Label>
            </StyledCol>
            <StyledCol xs="12">
              <span>{name}</span>
            </StyledCol>
            <StyledCol xs="12">
              <span>{street}</span>
            </StyledCol>
            <StyledCol xs="12">
              <span>{zip}</span> <span>{town}</span> - <span>{country}</span>
            </StyledCol>
          </Row>
        </Tooltip>
      }
    >
      <StyledLogo />
    </OverlayTrigger>
  );
};

export default ShippingAddress;
