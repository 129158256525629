import { ReactComponent as PartnersIcon } from 'src/assets/partners.svg';
import styled from 'styled-components/macro';

import { usePartner } from '../../contexts/Partner';
import { H1 } from '../../uikit/Typography/index';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
`;

const WrapperIcon = styled.div`
  color: ${({ theme }) => theme.palette.main.primary};
`;

const Text = styled(H1)`
  margin-bottom: 0;
  font-size: 1rem;
`;

const HeaderPartner = () => {
  const { partner } = usePartner();

  return (
    <Wrapper>
      <WrapperIcon>
        <PartnersIcon />
      </WrapperIcon>
      <Text>{partner?.name}</Text>
    </Wrapper>
  );
};

export default HeaderPartner;
