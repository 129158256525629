import { fr, enGB, de, nl, it, es } from 'date-fns/locale';
import { forwardRef, useEffect } from 'react';
import DatePicker, {
  ReactDatePickerProps,
  registerLocale,
} from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Calendar } from 'src/assets/calendar.svg';
import { DATE_FORMAT_DAY } from 'src/constants';
import styled from 'styled-components/macro';
import './DateSelector.css';

type Language = 'en' | 'de' | 'fr' | 'nl_BE' | 'it' | 'es';
const locales: { [keyof in Language]: Locale } = {
  de: de,
  en: enGB,
  es: es,
  fr: fr,
  it: it,
  nl_BE: nl,
};

const CalendarIcon = styled(Calendar)`
  position: relative;
  right: 34px;
`;

const Wrapper = styled(DatePicker)`
  width: 100%;
  border-radius: 10px;
  transition: border-color 0.15s ease-in-out;
  border: 1px solid ${({ theme }) => theme.palette.black[50]};
  .react-datepicker__month-container {
    font-size: 0.9rem;
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background-color: ${({ theme }) => theme.palette.main.primary};
  }
  .react-datepicker__header {
    border-bottom: 1px solid ${({ theme }) => theme.palette.main.lightBlue};
    background-color: ${({ theme }) => theme.palette.main.white};
  }
`;

const StyledCalendarIcon = styled(CalendarIcon)`
  color: ${({ theme }) => theme.palette.main.primary};
  position: absolute;
  right: 12px;
`;

const WrapperCustomInput = styled.input`
  background-color: ${({ theme }) => theme.palette.main.white};
  border-radius: 10px;
  padding: 10px;
  color: ${({ theme }) => theme.palette.black[90]};

  transition: border-color 0.15s ease-in-out;
  height 44px;

  :disabled {
    background-color: ${({ theme }) => theme.palette.black.strongLightGrey};
    border-color: ${({ theme }) => theme.palette.black[50]};
    color: ${({ theme }) => theme.palette.black[60]};

    & + ${StyledCalendarIcon} {
      color: ${({ theme }) => theme.palette.black[60]};
    }
  }

  :focus-visible {
    border: none;
  }

  :focus-within {
    border: 1px solid
      ${({ theme }) => theme.palette.main.primary};
    outline: 0;
  }

  :focus {
    border-color: ${({ theme }) => theme.palette.main.primary};
    color: ${({ theme }) => theme.palette.black.main};
    transition: box-shadow 0.15s ease-in-out;
    box-shadow: none;
  }
`;

const InnerWrapper = styled.span`
  display: flex;
  align-items: center;
`;

const CustomInput = forwardRef<HTMLInputElement>((props, ref) => (
  <InnerWrapper>
    <WrapperCustomInput {...props} ref={ref} />
    <StyledCalendarIcon />
  </InnerWrapper>
));

export type DateSelectorProps = {
  value?: Date;
  onChange: (value: Date) => void;
  placeholder?: string;
  dateFormat?: string;
} & Omit<ReactDatePickerProps, 'value' | 'customInput'>;

const DateSelector = ({
  value,
  onChange,
  placeholder,
  dateFormat,
  ...rest
}: DateSelectorProps) => {
  const { i18n } = useTranslation();
  useEffect(
    () =>
      registerLocale(i18n.language, locales[i18n.language as Language] || enGB),
    [i18n.language],
  );
  return (
    <Wrapper
      locale={i18n.language}
      selected={value || null}
      dateFormat={dateFormat || DATE_FORMAT_DAY}
      placeholderText={placeholder}
      onChange={(value: Date) => onChange(value)}
      customInput={<CustomInput />}
      {...rest}
    />
  );
};

export default DateSelector;
