import { Container, Spinner } from 'react-bootstrap';
import { Redirect, Switch, useLocation } from 'react-router-dom';
import PartnerConfigurationAddresses from 'src/components/Partners/Configuration/Addresses';
import PartnerConfigurationProducts from 'src/components/Partners/Configuration/Products';
import useFetchCurrentPartnerQuery from 'src/components/Partners/hooks/useFetchCurrentPartnerQuery';
import PrivateRoute from 'src/router/PrivateRoute';
import Routes from 'src/router/Routes';

import PartnerConfigurationGeneral from './General';
import PartnerConfigurationHorizontalMenu from './PartnerConfigurationHorizontalMenu';

const PartnerConfiguration = () => {
  const location = useLocation();

  const { data, isLoading } = useFetchCurrentPartnerQuery({
    expand: ['address', 'delivery-addresses'],
  });

  if (isLoading) {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <Spinner animation="border" variant="secondary" />
      </div>
    );
  }

  if (location.pathname === Routes.PARTNER_CONFIG) {
    return <Redirect to={Routes.PARTNER_CONFIG_GENERAL} />;
  }

  return (
    <>
      <PartnerConfigurationHorizontalMenu />
      {data && (
        <Container fluid className="p-4">
          <Switch>
            <PrivateRoute path={Routes.PARTNER_CONFIG_GENERAL}>
              <PartnerConfigurationGeneral partner={data} />
            </PrivateRoute>

            <PrivateRoute path={Routes.PARTNER_CONFIG_ADDRESSES}>
              {data && <PartnerConfigurationAddresses partner={data} />}
            </PrivateRoute>
            <PrivateRoute path={Routes.PARTNER_CONFIG_PRODUCTS}>
              {data && <PartnerConfigurationProducts partner={data} />}
            </PrivateRoute>
          </Switch>
        </Container>
      )}
    </>
  );
};

export default PartnerConfiguration;
