import { useState } from 'react';
import { useHistory, generatePath } from 'react-router-dom';
import { useModal } from 'src/hooks/useModal';
import Routes from 'src/router/Routes';
import { Partner } from 'src/sdk/partner';
import { List } from 'src/uikit/Layout/List';

import PartnerDeleteModal from '../PartnerDeleteModal';
import PartnerCard from './PartnerCard';

interface Props {
  partners?: Partner[];
}

const PartnersCardList = ({ partners }: Props) => {
  const history = useHistory();

  const [currentPartner, setCurrentPartner] = useState<Partner | null>(null);
  const { modalOpen: modalOpenDelete, setModalOpen: setModalOpenDelete } =
    useModal();

  const onClickDelete = (partner: Partner) => {
    setCurrentPartner(partner);
    setModalOpenDelete(true);
  };

  const onClickUpdate = (partner: Partner) => {
    setCurrentPartner(partner);
    history.push(
      generatePath(Routes.PARTNER, {
        id: partner.id,
      }),
    );
  };

  const onFinish = () => {
    setCurrentPartner(null);
    setModalOpenDelete(false);
  };

  return (
    <List className="mt-2">
      {partners?.map((partner) => (
        <PartnerCard
          key={partner.id}
          partner={partner}
          onClickDelete={onClickDelete}
          onClickUpdate={onClickUpdate}
        />
      ))}
      {currentPartner && (
        <>
          <PartnerDeleteModal
            partner={currentPartner}
            onFinish={onFinish}
            show={modalOpenDelete}
          />
        </>
      )}
    </List>
  );
};

export default PartnersCardList;
