import { ReactComponent as BrokenIcon } from 'src/assets/broken-badge.svg';
import { ReactComponent as CheckedIcon } from 'src/assets/checked-badge.svg';
import { ReactComponent as EmailIcon } from 'src/assets/email.svg';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  width: 120px;
  height: 120px;
  position: relative;

  svg:first-child {
    width: 100%;
    height: 100%;
  }

  svg:not(:first-child) {
    position: absolute;
    right: -20px;
    bottom: -5px;
    width: 60px;
    height: 60px;
  }
`;

interface Props {
  type?: 'success' | 'error';
}

const EmailVerificationImage = ({ type }: Props) => {
  return (
    <Wrapper>
      <EmailIcon />
      {type === 'success' && <CheckedIcon />}
      {type === 'error' && <BrokenIcon />}
    </Wrapper>
  );
};

export default EmailVerificationImage;
