import { AxiosResponse } from 'axios';
import { useMutation } from 'react-query';
import request from 'src/axios';
import { OrizonError } from 'src/sdk/common';
import { NursingHomeResponse } from 'src/sdk/nursingHome';

import { UpdatePasswordFormType } from '../GeneralSettings/UpdatePasswordForm';

const useUpdatePassword = (nhId: number) => {
  const updatePasswordMutation = useMutation<
    AxiosResponse<NursingHomeResponse>,
    OrizonError,
    UpdatePasswordFormType
  >((values: UpdatePasswordFormType) => {
    const payload = {
      value: values.newPassword,
    };
    return request.put(`/nursing-homes/${nhId}/password`, payload);
  });

  return { updatePasswordMutation };
};

export default useUpdatePassword;
