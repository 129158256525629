import { Row, Col, Stack } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { Label } from 'src/uikit/Typography';
import styled from 'styled-components/macro';

const Wrapper = styled(Row)<{ $direction?: 'column' | 'row' }>`
  display: flex;
  position: relative;
  flex-direction: ${({ $direction }) => $direction || 'row'};
  z-index: unset;
`;

const ElementWrapper = styled(Col)`
  display: flex;
  position: relative;
`;

export const RadioBox = styled.div`
  position: absolute;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  background: white;
  border: 1px solid #bebebe;
  top: 1px;
  left: 1px;
  background: ${({ theme }) => theme.palette.black.lightGrey};

  &::after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
  }
`;

const StyledLabel = styled(Label)`
  font-weight: 400;
`;

const StyledRadio = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 50%;
  min-width: 22px;
  width: 22px;
  height: 22px;
  margin-right: 0.5rem;

  &:hover ~ ${RadioBox} {
    border: 1px solid ${({ theme }) => theme.palette.main.primary};
    background: ${({ theme }) => theme.palette.main.white};
    &::after {
      background: ${({ theme }) => theme.palette.main.primary};
    }
  }

  ${({ checked, theme }) =>
    checked &&
    ` 
    &:checked  {
      & + ${RadioBox} {
        background: ${theme.palette.main.primary};
        border: 1px solid ${theme.palette.main.primary};
        &::after {
          box-shadow: ${theme.shadows.xs};
          background: white;
        }
      }
    }

    &:disabled + ${RadioBox} {
      &::after {
        background: ${theme.palette.black.grey};
      }
    }

    &:checked ~ ${Label} {
      color: ${theme.palette.main.primary};
    }
  `}

  &:disabled ~ ${RadioBox} {
    border: 1px solid ${({ theme }) => theme.palette.black[50]};
    background: ${({ theme }) => theme.palette.black.strongLightGrey};
  }

  &:disabled ~ ${StyledLabel} {
    color: ${({ theme }) => theme.palette.black[70]};
  }
`;

type RadioElement = {
  id: string;
  label: string;
};

interface Props {
  name: string;
  elements: RadioElement[];
  defaultValue?: string;
  colSize?: number;
  disabled?: boolean;
}

const RadioButton = ({
  name,
  elements,
  defaultValue,
  colSize,
  disabled,
}: Props) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ value, onChange }) => (
        <Wrapper>
          {elements.map((e) => (
            <ElementWrapper md={colSize}>
              <Stack direction="horizontal" style={{ position: 'relative' }}>
                <StyledRadio
                  type="radio"
                  value={e.id}
                  id={e.id}
                  checked={value === e.id}
                  onChange={() => onChange(e.id)}
                  disabled={disabled}
                />
                <RadioBox />
                <StyledLabel htmlFor={e.id}>{e.label}</StyledLabel>
              </Stack>
            </ElementWrapper>
          ))}
        </Wrapper>
      )}
    />
  );
};

export default RadioButton;
