import { useQueryClient, useMutation } from "react-query";
import request from 'src/axios';

const useSynchronize = () => {

    const queryClient = useQueryClient();

    const synchronize = useMutation(() => request.post('/nursing-homes/synchronize-from-ecs'),
        {
            onSuccess: () => {
                queryClient.invalidateQueries();
            },
        }
    )
    return { synchronize };
}

export default useSynchronize