import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BrokenActiveSvg } from 'src/assets/broken-badge-active.svg';
import { ReactComponent as BrokenSvg } from 'src/assets/broken-badge.svg';
import { ReactComponent as LostActiveSvg } from 'src/assets/lost-badge-active.svg';
import { ReactComponent as LostSvg } from 'src/assets/lost-badge.svg';
import { Status } from 'src/sdk/equipment';
import palette from 'src/uikit/theme/palette';
import { Text } from 'src/uikit/Typography';
import styled from 'styled-components/macro';

const BrokenIcon = styled(BrokenSvg)`
  margin-right: 10px;
`;

const BrokenActiveIcon = styled(BrokenActiveSvg)`
  margin-right: 10px;
`;

const LostIcon = styled(LostSvg)`
  margin-right: 10px;
`;

const LostActiveIcon = styled(LostActiveSvg)`
  margin-right: 10px;
`;

const Wrapper = styled('span')<{ color: string }>`
  cursor: pointer;
  width: 180px;
  padding: 5px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  text-align: left;
  border: ${({ color }) => `1px solid ${color}`};

  &.active {
    background-color: ${({ color }) => `${color}`};
  }

  input {
    display: none;
  }
`;

const StyledText = styled(Text)<{ enabled: boolean; color: string }>`
  font-weight: bold;
  margin: 0;
  color: ${({ enabled, color, theme }) =>
    enabled ? theme.palette.main.white : color};
`;

interface Props {
  type: Status;
  enabled: boolean;
  onClick: () => void;
}

const StatusButton = ({ type, enabled, onClick }: Props) => {
  const { t } = useTranslation();
  const color = useMemo(() => {
    if (type === Status.BROKEN) return palette.main.red;
    else if (type === Status.LOST) return palette.main.orange;
    else return '';
  }, [type]);

  return (
    <Wrapper
      className={enabled ? 'active' : ''}
      onClick={onClick}
      color={color}
    >
      {type === Status.BROKEN && enabled && <BrokenActiveIcon />}
      {type === Status.BROKEN && !enabled && <BrokenIcon />}
      {type === Status.LOST && enabled && <LostActiveIcon />}
      {type === Status.LOST && !enabled && <LostIcon />}
      <input type="checkbox" name="equipmentStatus" defaultChecked={enabled} />
      <StyledText enabled={enabled} color={color}>
        {type === Status.BROKEN &&
          t('components.modalDetailCharging.brokenStatus')}
        {type === Status.LOST && t('components.modalDetailCharging.lostStatus')}
      </StyledText>
    </Wrapper>
  );
};

export default StatusButton;
