import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import request from 'src/axios';
import useFetchEquipmentsMetricsQuery from 'src/components/Equipment/hooks/useFetchEquipmentMetricsQuery';
import Routes from 'src/router/Routes';
import { EquipmentMetrics } from 'src/sdk/provisioning';
import HorizontalMenu, { HorizontalMenuItems } from 'src/uikit/HorizontalMenu';

const ProvisioningHorizontalMenu = () => {
  const { t } = useTranslation();

  const { data: dataCharging } = useFetchEquipmentsMetricsQuery('charging');
  const { data: dataGateway } = useFetchEquipmentsMetricsQuery('gateway');
  const { data: dataClipon } = useFetchEquipmentsMetricsQuery('clipon');

  const shippingRequestMetrics = useQuery<EquipmentMetrics, Error>(
    'shipping requests Metrics',
    () => request.get('/shipping-requests/metrics').then((res) => res.data),
  );

  const MenuItem: HorizontalMenuItems[] = useMemo(() => {
    return [
      {
        routes: Routes.EQUIPMENT_CLIPON,
        label: t('components.equipmentBlock.clipon_plural'),
        totalCount: dataClipon?.totalCount,
      },
      {
        routes: Routes.EQUIPMENT_GATEWAY,
        label: t('components.equipmentBlock.gateway_plural'),
        totalCount: dataGateway?.totalCount,
      },
      {
        routes: Routes.EQUIPMENT_CHARGING,
        label: t('components.equipmentBlock.charging_plural'),
        totalCount: dataCharging?.totalCount,
      },
      {
        routes: Routes.EQUIPMENT_ORDERING,
        label: t('components.equipmentBlock.ordering'),
        totalCount: shippingRequestMetrics.data?.totalCount,
      },
    ];
  }, [
    dataCharging?.totalCount,
    dataClipon?.totalCount,
    dataGateway?.totalCount,
    shippingRequestMetrics.data?.totalCount,
    t,
  ]);

  return <HorizontalMenu menuItems={MenuItem} />;
};

export default ProvisioningHorizontalMenu;
