import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Status } from 'src/sdk/equipment';
import { Badge, BadgeStatus } from 'src/uikit/Badge';

const colors = {
  [Status.ORDERED]: BadgeStatus.grey,
  [Status.IN_TRANSIT]: BadgeStatus.grey,
  [Status.IN_STOCK]: BadgeStatus.primary,
  [Status.SENT]: BadgeStatus.grey,
  [Status.IN_USE]: BadgeStatus.greenElectric,
  [Status.NO_STATUS]: BadgeStatus.grey,
  [Status.BROKEN]: BadgeStatus.grey,
  [Status.LOST]: BadgeStatus.grey,
  [Status.READYTOSEND]: BadgeStatus.grey,
};

interface Props {
  status: Status;
}

const EquipmentStatusLabel = ({ status }: Props) => {
  const { t } = useTranslation();
  const label = useMemo(() => {
    if (status === Status.ORDERED) {
      return t('components.orderStatus.statusCard.ordered');
    } else if (status === Status.IN_TRANSIT) {
      return t('components.orderStatus.statusCard.inTransit');
    } else if (status === Status.IN_STOCK) {
      return t('components.orderStatus.statusCard.inStock');
    } else if (status === Status.SENT) {
      return t('components.orderStatus.statusCard.sent');
    } else if (status === Status.IN_USE) {
      return t('components.orderStatus.statusCard.inUse');
    } else {
      return '';
    }
  }, [status, t]);

  return <Badge bg={status ? colors[status] : BadgeStatus.grey}>{label}</Badge>;
};

export default EquipmentStatusLabel;
