import { AxiosResponse } from 'axios';
import { useMutation } from 'react-query';
import request from 'src/axios';
import { UpdateUserEmailRequest } from 'src/sdk/users';

const useUpdateUserEmailMutation = () => {
  const updateUserEmailMutation = useMutation<
    AxiosResponse,
    Error,
    UpdateUserEmailRequest & {
      id: number;
    }
  >(({ id, ...params }) => request.put(`/users/${id}/email`, params));

  return { updateUserEmailMutation };
};

export default useUpdateUserEmailMutation;
