import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import SortingButton from 'src/components/SortingHeaders/SortingButton';
import { EquipmentOrder } from 'src/sdk/order';
import styled from 'styled-components/macro';

const StyledRow = styled(Row)``;

const StyledCol = styled(Col)`
  display: flex;
  align-items: center;
`;

interface Props {
  onSortingChange: (field: keyof EquipmentOrder) => void;
}

const SortingOrderingDetailsHeader = ({ onSortingChange }: Props) => {
  const { t } = useTranslation();

  return (
    <StyledRow>
      <StyledCol md="1" />
      <StyledCol md="3">
        <SortingButton
          primary
          label={t('components.sortingHeader.sortByPlannedDeliveryDate')}
          field="plannedDeliveryDate"
          disableSort
        />
      </StyledCol>
      <StyledCol md="2">
        <SortingButton
          primary
          label={t('components.sortingHeader.sortByQuantity')}
          field="quantity"
          disableSort
        />
      </StyledCol>
      <StyledCol md="5">
        <SortingButton
          primary
          label={t('components.sortingHeader.sortByDescription')}
          field="description"
          disableSort
        />
      </StyledCol>
    </StyledRow>
  );
};

export default SortingOrderingDetailsHeader;
