import { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useQuery } from 'react-query';
import request from 'src/axios';
import FirmwaresHistory from 'src/components/Firmware/FirmwaresHistory';
import UploadNewFirmware from 'src/components/Firmware/UploadNewFirmware';
import { PageWrapper } from 'src/uikit/Wrapper';
import Pagination from 'src/components/Misc/Pagination';

const FirmwaresUpload = () => {
  const [page, setPage] = useState(0);

  const PAGE_SIZE = 50;


  const { data, isLoading, refetch } = useQuery(
    ['firmwares-history', { page }],
    async () => {
      return request
        .get('/firmwares?expand=counts&sort=archived,asc',{
        params: {
          page: page,
          size: PAGE_SIZE,
        },
      })
        .then((res) => res.data);
    },
  );
  
  const firmwares = data?.content;
  return (
    <PageWrapper>
      <UploadNewFirmware callback={refetch} />
      {isLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Spinner animation="border" variant="secondary" />
        </div>
      ) : (
        <FirmwaresHistory firmwares={firmwares} callback={refetch} />
      )}
       {data && data.totalPages > 1 && (
          <Pagination
            totalElements={data.totalElements}
            perPage={PAGE_SIZE}
            onPageChange={setPage}
            currentPage={page}
          />
        )}
    </PageWrapper>
  );
};

export default FirmwaresUpload;
