export enum Routes {
  // auth
  LOGIN = '/login',
  REGISTER = '/register',
  FORGET_PASSWORD = '/forget-password',
  RESET_PASSWORD = '/reset-password',
  CHOOSE_PASSWORD = '/choose-password',
  ERROR = '/error',
  EMAIL_VERIFICATION = '/email-verification',

  // nursings
  HOME = '/',
  MAIN_DASHBOARD = '/main-dashboard',
  DASHBOARD = '/dashboard',
  NURSINGS = '/nursing-home',
  NURSING = '/nursing-home/:id',
  NURSING_CREATION = '/nursing-home/create',
  NURSING_CREATION_ONTEX = '/nursing-home/create/prospect/:id/:salesOrg',
  NURSING_CREATION_PARTNER = '/nursing-home/create/partner-prospect/:id/:salesOrg',
  NURSING_DASHBOARD = '/nursing-home/:id/dashboard',
  NURSING_GENERAL = '/nursing-home/:id/general/:modal?',
  NURSING_CONFIG = '/nursing-home/:id/configuration',
  NURSING_CONFIG_GENERAL = '/nursing-home/:id/configuration/general',
  NURSING_CONFIG_ADDRESSES = '/nursing-home/:id/configuration/adresses',
  NURSING_CONFIG_PRODUCTS = '/nursing-home/:id/configuration/products',
  NURSING_CONFIG_BILLINGS = '/nursing-home/:id/configuration/billings',
  NURSING_CONFIG_MANAGE_USERS = '/nursing-home/:id/configuration/assign-user',
  NURSING_EQUIPMENT = '/nursing-home/:id/equipment',
  NURSING_EQUIPMENT_CLIPON = '/nursing-home/:id/equipment/clipons',
  NURSING_EQUIPMENT_GATEWAY = '/nursing-home/:id/equipment/gateways',
  NURSING_EQUIPMENT_CHARGING = '/nursing-home/:id/equipment/charging-stations',
  NURSING_EQUIPMENT_ORDERING = '/nursing-home/:id/equipment/ordering',
  NURSING_EQUIPMENT_ORDERING_NEW = '/nursing-home/:id/equipment/ordering/new',
  NURSING_STOCK = '/nursing-home/:id/stock',
  NURSING_SUBSCRIPTION = '/nursing-home/:id/subscription',
  NURSING_SUBSCRIPTION_HISTORY = '/nursing-home/:id/subscription/history',
  NURSING_SUBSCRIPTION_BILLING = '/nursing-home/:id/subscription/billing',
  NURSING_SUBSCRIPTION_CREATION = '/nursing-home/:id/subscription/create',
  NURSING_SUBSCRIPTION_UPDATE = '/nursing-home/:id/subscription/update',

  USERS = '/users',
  USERS_DETAIL = '/users/:id/',
  USERS_DETAIL_GENERAL = '/users/:id/general',
  USERS_DETAIL_NURSINGS = '/users/:id/nursing-homes',
  USERS_DETAIL_PROSPECTS = '/users/:id/prospects',
  ORDER = '/purchase-order',
  PROFILE = '/profile',
  PARTNERS = '/partners',
  PARTNER = '/partner/:id',
  PARTNER_GENERAL = '/partner/:id/general',
  PARTNER_PRODUCTS = '/partner/:id/products',
  PARTNER_CONFIG = '/configuration',
  PARTNER_CONFIG_GENERAL = '/configuration/general',
  PARTNER_CONFIG_ADDRESSES = '/configuration/adresses',
  PARTNER_CONFIG_PRODUCTS = '/configuration/products',
  PARTNERS_PROSPECTS = '/partner-prospects',
  PARTNERS_PROSPECT = '/partner-prospects/:id',
  PARTNERS_PROSPECT_GENERAL = '/partner-prospects/:id/general',
  PARTNERS_PROSPECT_DELIVERY_ADDRESSES = '/partner-prospects/:id/delivery-addresses',
  PARTNERS_PROSPECT_ASSIGNED_PS = '/partner-prospects/:id/assignes-ps',
  EQUIPMENT = '/equipment',
  EQUIPMENT_CLIPON = '/equipment/clip-ons',
  EQUIPMENT_GATEWAY = '/equipment/gateways',
  EQUIPMENT_CHARGING = '/equipment/charging-stations',
  EQUIPMENT_ORDERING = '/equipment/ordering',
  FIRMWARES = '/firmwares',
  FIRMWARES_OVERVIEW = '/firmwares/overview',
  FIRMWARES_DEPLOY = '/firmwares/deploy',
  FIRMWARES_UPLOAD = '/firmwares/upload',
  PROSPECTS = '/prospects',
  PROSPECT = '/prospects/:id/:salesOrg',
  PROSPECT_SUBSCRIPTION = '/prospects/:id/:modal?',
}

export default Routes;
