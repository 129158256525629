import { useState, useCallback, useMemo } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { ReactComponent as PlusBadge } from 'src/assets/plus-badge.svg';
import { useModal } from 'src/hooks/useModal';
import { PartnerProspect } from 'src/sdk/partner-prospect';
import { AlertCard } from 'src/uikit/AlertCard';
import ButtonIcon from 'src/uikit/ButtonIcon';
import SearchInput from 'src/uikit/SearchInput';
import styled from 'styled-components/macro';

import PartnerProspectHeader from '../PartnerProspectHeader';
import PartnerProspectAddressesList from './PartnerProspectAddressesList';
import PartnerProspectAddressUpsertModal from './PartnerProspectAddressUpsertModal';

const Wrapper = styled(Container)`
  display: grid;
  gap: 2rem;
  overflow-x: auto;
`;

const SearchColumn = styled(Col)`
  display: flex;
  justify-content: flex-start;
`;

const ButtonColumn = styled(Col)`
  display: flex;
  justify-content: flex-end;
`;

const Label = styled.label`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
`;

const Parenthesis = styled.span`
  font-style: italic;
  font-weight: 400;
`;

interface Props {
  data: PartnerProspect;
}

const PartnerProspectAddresses = ({ data }: Props) => {
  const { t } = useTranslation();
  const { modalOpen, setModalOpen } = useModal();

  const [searchFilter, setSearchFilter] = useState<string>('');

  const deliveryAddressesFilter = useMemo(() => {
    return data.deliveryAddresses.filter((deliveryAddress) => {
      return (deliveryAddress.name || '')
        .toLowerCase()
        .includes(searchFilter.toLowerCase());
    });
  }, [data.deliveryAddresses, searchFilter]);

  const handleChangeSearch = (value: string) => {
    setSearchFilter(value);
  };

  const handleFinishCreation = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);

  return (
    <Wrapper fluid>
      <PartnerProspectHeader title={data.name} />
      <Row className="justify-content-between">
        <SearchColumn md="2">
          <SearchInput onChange={handleChangeSearch} />
        </SearchColumn>
        <ButtonColumn md="3">
          <ButtonIcon
            prepend={<PlusBadge />}
            handleClick={() => setModalOpen(true)}
            label={t(
              'components.PartnerProspects.PartnerProspectDetails.PartnerProspectAddresses.add',
            )}
          />
        </ButtonColumn>
      </Row>
      <Row className="justify-content-between">
        <Label>
          <Trans
            i18nKey={t(
              'components.PartnerProspects.PartnerProspectDetails.PartnerProspectAddresses.label',
            )} // optional -> fallbacks to defaults if not provide
            components={{ span: <Parenthesis /> }}
          />
        </Label>
      </Row>
      <Row>
        {data?.deliveryAddresses.length === 0 && (
          <AlertCard>{t('components.list.noResult')}</AlertCard>
        )}

        {data?.deliveryAddresses && (
          <PartnerProspectAddressesList
            partnerProspectId={data.id}
            addresses={deliveryAddressesFilter}
          />
        )}
      </Row>
      <PartnerProspectAddressUpsertModal
        partnerProspectId={data.id}
        onFinish={handleFinishCreation}
        show={modalOpen}
      />
    </Wrapper>
  );
};

export default PartnerProspectAddresses;
