import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import GeneralCard from 'src/components/NursingHome/General/GeneralCard';
import SubscriptionGeneralInformation from 'src/components/Subscription/SubscriptionGeneralInformation';
import { NursingHomeResponse } from 'src/sdk/nursingHome';
import { SubscriptionStatus } from 'src/sdk/subscription';
import styled from 'styled-components/macro';

import SubscriptionStatusBadge from './History/SubscriptionStatusBadge';

const StyledRow = styled(Row)`
  position: relative;
  justify-content: center;
  align-items: center;
`;

const StyledBadge = styled.div`
  position: absolute;
  right: 2rem;
  top: -5px;
`;

type SubscriptionProps = {
  nursingHome: NursingHomeResponse;
};

const SubscriptionCard = ({ nursingHome }: SubscriptionProps) => {
  const { t } = useTranslation();

  return (
    <GeneralCard
      title={
        <StyledRow>
          <Col>
            <StyledBadge>
              <SubscriptionStatusBadge
                status={nursingHome.subscription.status}
              />
            </StyledBadge>
          </Col>
          <Col>
            <span>{t('components.subscriptionCard.title')}</span>
          </Col>
          <Col />
        </StyledRow>
      }
    >
      <SubscriptionGeneralInformation
        nursingHome={nursingHome}
        isInactive={
          nursingHome.subscription.status !== SubscriptionStatus.ACTIVE
        }
      />
    </GeneralCard>
  );
};

export default SubscriptionCard;
