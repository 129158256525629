import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosResponse } from 'axios';
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import request from 'src/axios';
import { useApp } from 'src/contexts/App';
import { useAuth } from 'src/contexts/Auth';
import Routes from 'src/router/Routes';
import { LoginResponse, LoginBody } from 'src/sdk/authentification';
import { locales, Locales } from 'src/translations/i18n';
import { Button } from 'src/uikit/Button';
import Divider from 'src/uikit/Divider';
import ErrorMessage from 'src/uikit/ErrorMessage';
import { FormGroup } from 'src/uikit/Form';
import FormPasswordInput from 'src/uikit/Form/PasswordInput';
import FormTextInput from 'src/uikit/Form/TextInput';
import { A, H3, P } from 'src/uikit/Typography';
import styled, { css } from 'styled-components/macro';
import * as yup from 'yup';

import AuthForm from '../AuthForm';

const LoginWelcome = styled(H3)`
  padding-bottom: 1rem;
`;

const ForgotBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ForgotText = styled(P)`
  color: ${({ theme }) => theme.palette.black.strongGrey};
  margin: 0;
`;

const CreateText = styled(P)`
  display: flex;
  gap: 0.5rem;
  color: ${({ theme }) => theme.palette.black.strongGrey};
`;

interface FormLinkProps {
  $isUnderline?: boolean;
}

const FormLink = styled(A)<FormLinkProps>`
  color: ${({ theme }) => theme.palette.black.main};
  ${({ $isUnderline }) =>
    $isUnderline &&
    css`
      text-decoration: underline;
    `}
`;

const loginFormSchema = (t: TFunction) =>
  yup.object({
    username: yup
      .string()
      .required(t('components.loginForm.validations.required')),
    password: yup
      .string()
      .required(t('components.loginForm.validations.required')),
  });

type LoginFormSchema = yup.InferType<ReturnType<typeof loginFormSchema>>;

const LoginForm = () => {
  const { t } = useTranslation();
  const history = useHistory<{ referer: string }>();
  const { authUser, setAuthUser } = useAuth();
  const { setLanguage } = useApp();
  const [wrongCredentials, setWrongCredentials] = useState(false);

  const methods = useForm<LoginFormSchema>({
    resolver: yupResolver(loginFormSchema(t)),
    mode: 'onChange',
    defaultValues: {
      password: '',
      username: '',
    },
  });

  const { handleSubmit, formState } = methods;

  const { mutateAsync, isLoading } = useMutation<
    AxiosResponse<LoginResponse>,
    Error,
    LoginBody
  >((params) =>
    request.post(
      'security/password-authentication?expand=user,avatar-image-path',
      params,
    ),
  );

  const onSubmit = handleSubmit(async (data: LoginFormSchema) => {
    try {
      setWrongCredentials(false);

      const result = await mutateAsync({
        username: data.username,
        password: data.password,
      });

      setAuthUser?.({
        partnerUser: result.data.user.partnerUser,
        id: result.data.userId,
        firstName: result.data.user.firstName,
        lastName: result.data.user.lastName,
        profile: result.data.profile,
        language: result.data.user.language,
        token: result.data.authenticationToken || authUser?.token,
        avatarImagePath: result.data.avatarImagePath,
      });

      setLanguage?.(
        locales.find((locale) => result.data.user.language === locale.tag)
          ?.locale || Locales.EN,
      );

      if (result.data.userId) {
        const referer =
          history.action !== 'REPLACE'
            ? history.location.state?.referer || Routes.NURSINGS
            : Routes.NURSINGS;

        history.push(referer);
      }
    } catch (error) {
      setWrongCredentials(true);
    }
  });

  return (
    <FormProvider {...methods}>
      <AuthForm onSubmit={onSubmit}>
        <LoginWelcome>{t('components.loginForm.title')}</LoginWelcome>
        <FormGroup className="mt-3">
          <FormTextInput
            name="username"
            placeholder={t('components.loginForm.email')}
            width="100%"
          />
        </FormGroup>
        <FormGroup>
          <FormPasswordInput
            name="password"
            placeholder={t('components.loginForm.password')}
            width="100%"
          />
        </FormGroup>
        {wrongCredentials && (
          <ErrorMessage>
            {t('components.loginForm.errors.wrongCredentials')}
          </ErrorMessage>
        )}
        <ForgotBlock>
          <ForgotText>
            <FormLink to={Routes.FORGET_PASSWORD} $isUnderline={true}>
              {t('components.loginForm.forgotCredentials')}
            </FormLink>
          </ForgotText>
          <Button type="submit" disabled={!formState.isValid}>
            {isLoading ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              t('components.loginForm.login')
            )}
          </Button>
        </ForgotBlock>
        <Row>
          <Col>
            <Divider />
            <CreateText>
              {t('components.loginForm.register.noAccount')}
              <FormLink to={Routes.REGISTER}>
                {t('components.loginForm.register.createNewAccount')}
              </FormLink>
            </CreateText>
          </Col>
        </Row>
      </AuthForm>
    </FormProvider>
  );
};

export default LoginForm;
