import {
  useCallback,
  useState,
  useEffect,
  ElementType,
  ReactNode,
} from 'react';
import { Stack } from 'react-bootstrap';
import request from 'src/axios';
import ActionCard, {
  ActionCardProps,
} from 'src/components/Misc/Card/ActionCard';
import CheckCard, { CheckCardProps } from 'src/components/Misc/Card/CheckCard';
import HoverCard, { HoverCardProps } from 'src/components/Misc/Card/HoverCard';
import { UserResponse } from 'src/sdk/users';
import { Card, CardBody, CardProps } from 'src/uikit/Card';
import { Text } from 'src/uikit/Typography';
import styled from 'styled-components/macro';

const Avatar = styled.img`
  width: 40px;
  aspect-ratio: 1/1;
  margin-left: 0.25rem;
`;

const Subtitle = styled.span`
  font-weight: 500;
  font-size: 12px;
  color: ${({ theme }) => theme.palette.black[80]};
`;

const DefaultCard = ({
  children,
  ...rest
}: { children: ReactNode } & CardProps) => (
  <Card {...rest}>
    <CardBody>{children}</CardBody>
  </Card>
);

type variant = 'default' | 'hover' | 'check' | 'action';

const Components: { [key in variant]: ElementType } = {
  default: DefaultCard,
  check: CheckCard,
  hover: HoverCard,
  action: ActionCard,
} as const;

export type UserSimpleCardProps = (
  | ({
      variant?: 'hover';
    } & Omit<HoverCardProps, 'children'>)
  | ({ variant?: 'check' } & Omit<CheckCardProps, 'children'>)
  | ({ variant?: 'action' } & Omit<ActionCardProps, 'children'>)
  | { variant?: 'default' }
) & {
  user: UserResponse;
} & CardProps;

const UserSimpleCard = ({ user, variant, ...rest }: UserSimpleCardProps) => {
  const Component: ElementType = variant
    ? Components[variant]
    : Components.default;

  const [avatar, setAvatar] = useState('');

  const loadImage = useCallback(async () => {
    if (user.avatarImagePath) {
      const response = await request.get(
        user.avatarImagePath.replace('/api/v1', ''),
        { responseType: 'blob' },
      );

      setAvatar(URL.createObjectURL(response.data));
    }
  }, [user.avatarImagePath]);

  useEffect(() => {
    loadImage();
  }, [loadImage]);

  return (
    <Component {...rest}>
      <Stack
        direction="horizontal"
        className="h-100 align-items-center"
        gap={3}
      >
        <Avatar src={avatar} />
        <Stack className="justify-content-center">
          <Text as="span">
            {user.firstName} {user.lastName}
          </Text>
          <Subtitle>{user.employeeId}</Subtitle>
        </Stack>
      </Stack>
    </Component>
  );
};

export default UserSimpleCard;
