import { useQuery } from 'react-query';
import request from 'src/axios';
import { Profiles } from 'src/sdk/authentification';
import {
  GetUsersResponse,
  UserExpand,
  UserExtra,
  UserSortField,
} from 'src/sdk/users';

export type UsersQueryParams = {
  page: number;
  size: number;
  expand?: UserExpand[];
  extra?: UserExtra[];
  sortField: UserSortField;
  search?: string;
  profilesFilter?: Profiles[] | Profiles | null;
  ontexAndPaOnly?: boolean;
  'account-status'?: 'PENDING' | 'ACTIVE' | 'BLOCKED';
  nursingHomeId?: number;
};

const fetchUsers = async ({
  page,
  size,
  expand,
  sortField,
  search,
  profilesFilter,
  ontexAndPaOnly,
  extra,
  'account-status': accountStatus,
  nursingHomeId,
}: UsersQueryParams) => {
  const sort = `${sortField.label},${sortField.direction}`;
  const { data } = await request.get('/users', {
    params: {
      page,
      size,
      sort,
      ...(profilesFilter && {
        profiles:
          typeof profilesFilter === 'string'
            ? profilesFilter
            : profilesFilter.join(','),
      }),
      ...(expand && { expand: expand.join(',') }),
      ...(extra && { extra: extra.join(',') }),
      ...(search && { 'name-part': search }),
      ...(ontexAndPaOnly && { 'ontex-and-pa-only': ontexAndPaOnly }),
      ...(nursingHomeId && { 'nursing-home-id': nursingHomeId }),
      'account-status': accountStatus,
    },
  });

  return data;
};

const useFetchUsersQuery = (params: UsersQueryParams) => {
  const { data, isLoading, refetch, error } = useQuery<GetUsersResponse, Error>(
    ['usersList', { ...params }],
    () => fetchUsers(params),
    {
      keepPreviousData: true,
    },
  );

  return {
    data,
    isLoading,
    refetch,
    error,
  };
};

export default useFetchUsersQuery;
