import { AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import request from 'src/axios';
import { PartnerUpdateResponse, PartnerUpdateRequest } from 'src/sdk/partner';

const useUpdatePartnerMutation = () => {
  const queryClient = useQueryClient();

  const updatePartnerMutation = useMutation<
    AxiosResponse<PartnerUpdateResponse>,
    Error,
    PartnerUpdateRequest & {
      id: number;
    }
  >(({ id, ...params }) => request.put(`/partners/${id}`, params), {
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
  });

  return { updatePartnerMutation };
};

export default useUpdatePartnerMutation;
