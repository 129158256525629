import { format, isPast } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { ChevronRight } from 'src/assets/arrows';
import { DATE_FORMAT_DAY } from 'src/constants';
import { H5 } from 'src/uikit/Typography';
import styled, { css } from 'styled-components/macro';

enum DurationStatus {
  ONGOING = 'onGoing',
  EXPIRED = 'expired',
}

interface ContentProps {
  color: string;
}

const Content = styled.div<ContentProps>`
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: column;
  }
  min-height: 80px;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  ${({ color }) =>
    (color === DurationStatus.EXPIRED &&
      css`
        border: 1px solid ${({ theme }) => theme.palette.black[60]};
        background-color: ${({ theme }) => theme.palette.black.lightGrey};
      `) ||
    (color === DurationStatus.ONGOING &&
      css`
        border: 1px solid ${({ theme }) => theme.palette.main.primary};
        background-color: ${({ theme }) => theme.palette.main.lightBlue};
      `)}
`;

const DurationText = styled(H5)`
  color: ${({ theme }) => theme.palette.black[90]};
  margin: 0;
`;

const DateContainer = styled.div`
  display: flex;
  align-items: center;
`;

const DateCard = styled.div`
  height: 40px;
  min-width: 132px;
  background-color: ${({ theme }) => theme.palette.main.white};
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DateText = styled(H5)`
  color: ${({ theme }) => theme.palette.black[90]};
  font-weight: normal;
  font-size: 17px;
  margin: 0;
`;

const Icon = styled(ChevronRight)`
  margin: 0 10px;
`;

type Props = {
  startDate: string;
  endDate: string;
};

const ContractDuration = ({ startDate, endDate }: Props) => {
  const { t } = useTranslation();

  return (
    <Content
      color={
        isPast(new Date(endDate))
          ? DurationStatus.EXPIRED
          : DurationStatus.ONGOING
      }
    >
      <DurationText>{t('components.contractDuration.title')}</DurationText>
      <DateContainer>
        <DateCard>
          <DateText>{format(new Date(startDate), DATE_FORMAT_DAY)}</DateText>
        </DateCard>
        <Icon />
        <DateCard>
          <DateText>{format(new Date(endDate), DATE_FORMAT_DAY)}</DateText>
        </DateCard>
      </DateContainer>
    </Content>
  );
};

export default ContractDuration;
