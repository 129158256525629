import { Col, Form, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import AddressCard from 'src/components/Misc/Card/AddressCard';
import useLanguageOptions from 'src/hooks/useLanguageOptions';
import { PartnerCreationRequest } from 'src/sdk/partner';
import { Button, ButtonColors } from 'src/uikit/Button';
import ErrorMessage from 'src/uikit/ErrorMessage';
import { FormGroup, FormLabel } from 'src/uikit/Form';
import FormSelectInput from 'src/uikit/Form/SelectInput';
import FormTextInput from 'src/uikit/Form/TextInput';
import { List } from 'src/uikit/Layout/List';
import styled from 'styled-components/macro';
import * as yup from 'yup';

import useLegalEntityOptions from '../../NursingHome/Creation/hooks/useLegalEntityOptions';
import PartnerAddressForm, {
  partnerAddressFormSchema,
} from '../PartnerAddressForm';


const FormLabelInformation = styled.span`
    color: ${({ theme }) => theme.palette.black[60]};
    font-weight: 4r400;
    font-size: 12px;
    margin-left: 0.5rem;
`;

const FormFooter = styled(Row)`
    justify-content: end;
    gap: 1rem;

    ${Button} {
        font-size: 14px;
    }
`;

export const partnerCreateFormSchema = partnerAddressFormSchema.shape({
  name: yup.string().required(),
  language: yup.mixed<PartnerCreationRequest['language']>().required(),
  legalEntity: yup.string().required(),
});

export type PartnerCreateFormSchema = yup.InferType<
  typeof partnerCreateFormSchema
>;

interface Props {
  error?: string;
  onSubmit: (values: PartnerCreateFormSchema) => void;
  onCancel: () => void;
  partner?: any;
}

const PartnerCreateForm = ({ onSubmit, onCancel, error, partner }: Props) => {
  const { t } = useTranslation();
  const languageOptions = useLanguageOptions();
  const legalEntityOptions = useLegalEntityOptions();
  const { handleSubmit } = useFormContext();
  return (
    <Form className="text-start" onSubmit={handleSubmit(onSubmit)}>
      <FormLabel>
        <Trans
          i18nKey="components.PartnerCreateModal.PartnerCreateForm.labels.subtitle" // optional -> fallbacks to defaults if not provide
          components={{ span: <FormLabelInformation /> }}
        />
      </FormLabel>
      <FormGroup>
        <FormTextInput
          name="name"
          placeholder={t(
            'components.PartnerCreateModal.PartnerCreateForm.placeholder.name',
          )}
          width="100%"
        />
      </FormGroup>
      <FormGroup>
        <FormSelectInput
          name="language"
          placeholder={t('language')}
          options={languageOptions}
        />
      </FormGroup>
      <FormGroup>
        <FormSelectInput
          name="legalEntity"
          placeholder={t('components.PartnerCreateModal.PartnerCreateForm.placeholder.legalEntity')}
          options={legalEntityOptions.data || []}
        />
      </FormGroup>
      <PartnerAddressForm />
      <List columnSize={'300px'}>
        {partner?.deliveryAddresses?.map((deliveryAddress: any) => (
          <AddressCard
            key={deliveryAddress.id}
            disabled
            border
            address={deliveryAddress}
          />
        ))}
      </List>
      <FormFooter xs="auto">
        <Col>{error && <ErrorMessage>{error}</ErrorMessage>}</Col>
        <Col
          style={{ marginTop: '24px' }}
          className="d-flex justify-content-end"
        >
          <Button background={ButtonColors.CANCEL} onClick={onCancel}>
            {t(
              'components.PartnerProspects.PartnerProspectsList.PartnerProspectCreateModal.PartnerProspectCreateForm.cancel',
            )}
          </Button>
          <Button type="submit" className="ml-2">
            {t(
              'components.PartnerProspects.PartnerProspectsList.PartnerProspectCreateModal.PartnerProspectCreateForm.create',
            )}
          </Button>
        </Col>
      </FormFooter>
    </Form>
  );
};

export default PartnerCreateForm;
