import { ReactNode } from 'react';
import { ModalBody } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import { CloseCross } from 'src/assets/list-icons';
import DefaultModal from 'src/components/Misc/Modal/DefaultModal';
import styled from 'styled-components/macro';

const Header = styled(ModalHeader)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.25rem 1rem;
  width: 100%;
  border-bottom: none;
`;

const WrapperIcon = styled.div`
  background-color: ${({ theme }) => theme.palette.main.primary};
  padding: 0.5rem;
  border-radius: 50%;

  & > * {
    color: white;
  }
`;

const Title = styled.h1`
  font-size: 17px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.1px;
  margin-bottom: 0;
`;

const CloseButton = styled.button`
  background: none;
  padding: 0.5rem;
  box-shadow: unset;
  border: none;
  border-radius: 50%;

  :hover:not(:disabled) {
    transform: scale(1.1);
  }
`;

interface Props {
  title: string;
  children: ReactNode;
  icon: ReactNode;
  show: boolean;
  handleClose: () => void;
}

const PartnerProspectsModal = ({
  title,
  children,
  icon,
  show,
  handleClose,
}: Props) => {
  return (
    <DefaultModal
      size="lg"
      isVisible={show}
      handleClose={handleClose}
      hasStep={false}
      customClose
      header={
        <Header>
          <WrapperIcon>{icon}</WrapperIcon>
          <Title>{title}</Title>
          <CloseButton onClick={handleClose}>
            <CloseCross />
          </CloseButton>
        </Header>
      }
    >
      <ModalBody>{children}</ModalBody>
    </DefaultModal>
  );
};

export default PartnerProspectsModal;
