import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FormGroup, FormLabel } from 'src/uikit/Form';
import FormTextInput from 'src/uikit/Form/TextInput';
import * as yup from 'yup';

export const partnerAddressFormSchema = yup.object({
  addressName: yup.string(),
  addressStreet: yup.string(),
  addressZip: yup.string(),
  addressTown: yup.string(),
  addressCountry: yup.string(),
  addressEmail: yup.string(),
  addressTelephone: yup.string().nullable().optional(),
});

export type PartnerAddressFormSchema = yup.InferType<
  typeof partnerAddressFormSchema
>;

const PartnerAddressForm = () => {
  const { t } = useTranslation();
  return (
    <>
      <FormLabel>
        {t('components.PartnerCreateModal.PartnerCreateForm.labels.address')}
      </FormLabel>
      <FormGroup>
        <FormTextInput
          name="addressName"
          placeholder={t(
            'components.PartnerCreateModal.PartnerCreateForm.placeholder.address.name',
          )}
          disabled
          width="100%"
        />
      </FormGroup>
      <FormGroup>
        <FormTextInput
          disabled
          name="addressStreet"
          placeholder={t(
            'components.PartnerCreateModal.PartnerCreateForm.placeholder.address.street',
          )}
          width="100%"
        />
      </FormGroup>
      <Row>
        <Col>
          <FormGroup>
            <FormTextInput
              disabled
              name="addressTown"
              placeholder={t(
                'components.PartnerCreateModal.PartnerCreateForm.placeholder.address.city',
              )}
              width="100%"
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <FormTextInput
              name="addressZip"
              placeholder={t(
                'components.PartnerCreateModal.PartnerCreateForm.placeholder.address.zip',
              )}
              disabled
              width="100%"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <FormTextInput
              placeholder={t(
                'components.PartnerCreateModal.PartnerCreateForm.placeholder.address.country',
              )}
              name="addressCountry"
              disabled
              width="100%"
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <FormTextInput
              name="addressTelephone"
              placeholder={t(
                'components.PartnerCreateModal.PartnerCreateForm.placeholder.address.phone',
              )}
              disabled
              width="100%"
            />
          </FormGroup>
        </Col>
      </Row>
      <FormGroup>
        <FormTextInput
          placeholder={t(
            'components.PartnerCreateModal.PartnerCreateForm.placeholder.address.email',
          )}
          name="addressEmail"
          disabled
          width="100%"
        />
      </FormGroup>
    </>
  );
};

export default PartnerAddressForm;
