import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';
import useFetchNursingHomeQuery from 'src/components/NursingHome/hooks/useFetchNursingHomeQuery';
import useFetchSubscription from 'src/components/Subscription/hooks/useFetchSubscriptionQuery';
import { SubscriptionUpsertProvider } from 'src/components/Subscription/SubscriptionUpsert/SubscriptionContext';
import SubscriptionUpdateDelivery from 'src/components/Subscription/SubscriptionUpsert/SubscriptionUpdate/SubscriptionUpdateDelivery';
import SubscriptionUpdatePlan from 'src/components/Subscription/SubscriptionUpsert/SubscriptionUpdate/SubscriptionUpdatePlan';
import SubscriptionUpdateSummary from 'src/components/Subscription/SubscriptionUpsert/SubscriptionUpdate/SubscriptionUpdateSummary';
import { useAuth } from 'src/contexts/Auth';
import { usePartner } from 'src/contexts/Partner';
import Routes from 'src/router/Routes';
import { SubscriptionCreationRequest } from 'src/sdk/subscription';
import Stepper from 'src/uikit/Stepper';
import { CardWrapper } from 'src/uikit/Wrapper';

import { H1 } from '../uikit/Typography/index';

export enum SubscriptionCreationStep {
  ChoosSubscriptionPlan,
  ChooseEquipment,
  Summary,
}

const SubscriptionUpdate = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { nhSession } = useAuth();
  const { partner } = usePartner();

  const [subscription, setSubscription] =
    useState<SubscriptionCreationRequest>();

  const [currentStep = 0, setCurrentStep] = useState<number>();

  const onSuccessEditPlans = (
    newData: Partial<SubscriptionCreationRequest>,
  ): void => {
    setSubscription(Object.assign({}, subscription, newData));
    setCurrentStep(currentStep + 1);
  };

  const onSuccessUpdateDelivery = (
    newData: Partial<SubscriptionCreationRequest>,
  ): void => {
    setSubscription(Object.assign({}, subscription, newData));
    setCurrentStep(currentStep + 1);
  };

  const onCancel = useCallback(() => {
    history.push(
      generatePath(Routes.NURSING_SUBSCRIPTION_HISTORY, {
        id: nhSession?.id || -1,
      }),
    );
  }, [history, nhSession?.id]);

  const onCreationSuccess = useCallback(() => {
    if (nhSession?.id) {
      history.push(
        generatePath(Routes.NURSING_SUBSCRIPTION_HISTORY, {
          id: nhSession?.id,
        }),
      );
    }
  }, [history, nhSession?.id]);

  const { data: nh } = useFetchNursingHomeQuery({
    id: nhSession?.id || -1,
    expand: 'all',
  });

  const { selectedData, currentEquipments, creationData, isLoading } =
    useFetchSubscription(nhSession?.id || -1);

  useEffect(() => {
    if (selectedData) setSubscription(selectedData);
  }, [selectedData]);

  const deliveryAddresses = useMemo(() => {
    return [
      ...(nh?.deliveryAddresses || []),
      ...(partner?.deliveryAddresses?.map((deliveryAddress) => ({
        ...deliveryAddress,
        isPartner: true,
      })) || []),
    ];
  }, [nh?.deliveryAddresses, partner?.deliveryAddresses]);

  if (isLoading || !subscription || !creationData) {
    return <></>;
  }

  return (
    <CardWrapper key={nh?.id}>
      <H1 className="mx-auto">{t('pages.SubscriptionUpdate.title')}</H1>

      <SubscriptionUpsertProvider
        defaultType={subscription?.type || 'STANDARD'}
        configurations={creationData?.typeConfigurations}
      >
        <Stepper
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          hideStepLabel
          stepperData={[
            {
              label: t('pages.SubscriptionUpdate.firstStep'),
              name: t('pages.SubscriptionUpdate.firstStep'),
              content: (
                <SubscriptionUpdatePlan
                  currentEquipments={currentEquipments}
                  creationData={creationData}
                  data={subscription}
                  onSuccess={onSuccessEditPlans}
                  onCancel={onCancel}
                />
              ),
            },
            {
              label: t('pages.SubscriptionUpdate.secondStep'),
              name: t('pages.SubscriptionUpdate.secondStep'),
              content: (
                <>
                  {subscription && (
                    <SubscriptionUpdateDelivery
                      currentEquipments={currentEquipments}
                      data={subscription}
                      deliveryAddresses={deliveryAddresses || []}
                      onSuccess={onSuccessUpdateDelivery}
                      onCancel={onCancel}
                      onBack={() => setCurrentStep(currentStep + -1)}
                    />
                  )}
                </>
              ),
            },
            {
              label: t('pages.SubscriptionUpdate.thirdStep'),
              name: t('pages.SubscriptionUpdate.thirdStep'),
              content: (
                <SubscriptionUpdateSummary
                  data={subscription}
                  setCurrentStep={setCurrentStep}
                  onSuccess={onCreationSuccess}
                  nh={nh}
                  deliveryAddresses={deliveryAddresses || []}
                  currentEquipments={currentEquipments}
                />
              ),
            },
          ]}
        />
      </SubscriptionUpsertProvider>
    </CardWrapper>
  );
};

export default SubscriptionUpdate;
