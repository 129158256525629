import { ReactNode } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ReactComponent as HomeIcon } from 'src/assets/home.svg';
import { H2 } from 'src/uikit/Typography';
import styled from 'styled-components/macro';

interface Props {
  title: ReactNode;
}

const Wrapper = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  gap: 0.5rem;
`;

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 1px solid ${({ theme }) => theme.palette.main.primary};
  background-color: white;
  border-radius: 50%;
  color: ${({ theme }) => theme.palette.main.primary};
`;

const PartnerProspectHeader = ({ title }: Props) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Icon>
        <HomeIcon />
      </Icon>
      <H2>
        {t(
          'components.PartnerProspects.PartnerProspectDetails.PartnerProspectHeader.title',
          { title },
        )}
      </H2>
    </Wrapper>
  );
};

export default PartnerProspectHeader;
