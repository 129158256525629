import { useTranslation } from 'react-i18next';
import { ReactComponent as EquipmentIcon } from 'src/assets/clipons.svg';
import { ReactComponent as DashboardIcon } from 'src/assets/dashboard.svg';
import { ReactComponent as FirmwaresIcon } from 'src/assets/firmwares.svg';
import { ReactComponent as HomeIcon } from 'src/assets/home.svg';
import { ReactComponent as OrderingIcon } from 'src/assets/ordering.svg';
import { ReactComponent as PartnersIcon } from 'src/assets/partners-outline.svg';
import { ReactComponent as ProductSpecialistsIcon } from 'src/assets/product-specialist.svg';
import { ReactComponent as SettingsIcon } from 'src/assets/settings.svg';
import { useAuth } from 'src/contexts/Auth';
import Routes from 'src/router/Routes';
import { Profiles } from 'src/sdk/authentification';
import { H3 } from 'src/uikit/Typography';
import styled from 'styled-components/macro';

import SidebarItem from '../SidebarItem';
import SidebarSubitem from '../SidebarSubitem';

const MainTitle = styled(H3)`
  color: ${({ theme }) => theme.palette.black.strongGrey};
  margin: 1rem 0.5rem;
  text-transform: uppercase;
  display: flex;
  gap: 10px;
  justify-content: left;
  align-items: center;
`;

export type SidebarMenuBottomProps = {
  menuOpen: boolean;
};

const SidebarMenuBottom = ({ menuOpen }: SidebarMenuBottomProps) => {
  const { t } = useTranslation();
  const { authUser } = useAuth();

  const partnerAdminMenu = (
    <>
      <SidebarItem
        label={t('components.sidebarMenu.linkToDashboard')}
        path={Routes.DASHBOARD}
        icon={<DashboardIcon />}
        opened={menuOpen}
        exact
      />
      <SidebarItem
        label={t('components.sidebarMenu.linkToUsers')}
        path={Routes.USERS}
        icon={<ProductSpecialistsIcon />}
        opened={menuOpen}
      />
      <SidebarItem
        label={t('components.sidebarMenu.linkToConfig')}
        path={Routes.PARTNER_CONFIG}
        icon={<SettingsIcon />}
        opened={menuOpen}
      >
        <SidebarSubitem
          label={t('components.sidebarMenu.linkToConfigGeneral')}
          path={Routes.PARTNER_CONFIG_GENERAL}
          opened={menuOpen}
        />
        <SidebarSubitem
          label={t('components.sidebarMenu.linkToConfigAddresses')}
          path={Routes.PARTNER_CONFIG_ADDRESSES}
          opened={menuOpen}
        />
        <SidebarSubitem
          label={t('components.sidebarMenu.linkToConfigProducts')}
          path={Routes.PARTNER_CONFIG_PRODUCTS}
          opened={menuOpen}
        />
      </SidebarItem>
      <SidebarItem
        label={t('components.nursinghomeSubmenu.linkToEquipment')}
        path={Routes.EQUIPMENT}
        icon={<EquipmentIcon />}
        opened={menuOpen}
      >
        <SidebarSubitem
          label={t('components.nursinghomeSubmenu.linkToClipons')}
          path={Routes.EQUIPMENT_CLIPON}
          opened={menuOpen}
        />
        <SidebarSubitem
          label={t('components.nursinghomeSubmenu.linkToGateways')}
          path={Routes.EQUIPMENT_GATEWAY}
          opened={menuOpen}
        />
        <SidebarSubitem
          label={t('components.nursinghomeSubmenu.linkToChargings')}
          path={Routes.EQUIPMENT_CHARGING}
          opened={menuOpen}
        />
        <SidebarSubitem
          label={t('components.nursinghomeSubmenu.linkToOrdering')}
          path={Routes.EQUIPMENT_ORDERING}
          opened={menuOpen}
        />
      </SidebarItem>
      <SidebarItem
        label={t('components.sidebarMenu.linkToPartnerProspects')}
        path={Routes.PARTNERS_PROSPECTS}
        icon={<HomeIcon />}
        opened={menuOpen}
      />
    </>
  );

  const orizonAdminMenu = (
    <>
      <SidebarItem
        label={t('components.sidebarMenu.linkToDashboard')}
        path={Routes.DASHBOARD}
        icon={<DashboardIcon />}
        opened={menuOpen}
        exact
      />
      <SidebarItem
        label={t('components.sidebarMenu.linkToUsers')}
        path={Routes.USERS}
        icon={<ProductSpecialistsIcon />}
        opened={menuOpen}
      />
      <SidebarItem
        label={t('components.sidebarMenu.linkToOrder')}
        path={Routes.ORDER}
        icon={<OrderingIcon />}
        opened={menuOpen}
      />
      <SidebarItem
        label={t('components.nursinghomeSubmenu.linkToEquipment')}
        path={Routes.EQUIPMENT}
        icon={<EquipmentIcon />}
        opened={menuOpen}
      >
        <SidebarSubitem
          label={t('components.nursinghomeSubmenu.linkToClipons')}
          path={Routes.EQUIPMENT_CLIPON}
          opened={menuOpen}
        />
        <SidebarSubitem
          label={t('components.nursinghomeSubmenu.linkToGateways')}
          path={Routes.EQUIPMENT_GATEWAY}
          opened={menuOpen}
        />
        <SidebarSubitem
          label={t('components.nursinghomeSubmenu.linkToChargings')}
          path={Routes.EQUIPMENT_CHARGING}
          opened={menuOpen}
        />
        <SidebarSubitem
          label={t('components.nursinghomeSubmenu.linkToOrdering')}
          path={Routes.EQUIPMENT_ORDERING}
          opened={menuOpen}
        />
      </SidebarItem>
      <SidebarItem
        label={t('components.sidebarMenu.linkToPartners')}
        path={Routes.PARTNERS}
        icon={<PartnersIcon />}
        opened={menuOpen}
      />
      <SidebarItem
        label={t('components.sidebarMenu.linkToFirmwares')}
        path={Routes.FIRMWARES}
        icon={<FirmwaresIcon />}
        opened={menuOpen}
      >
        <SidebarSubitem
          label={t('overview')}
          path={Routes.FIRMWARES_OVERVIEW}
          opened={menuOpen}
        />
        <SidebarSubitem
          label={t('deploy')}
          path={Routes.FIRMWARES_DEPLOY}
          opened={menuOpen}
        />
        <SidebarSubitem
          label={t('upload')}
          path={Routes.FIRMWARES_UPLOAD}
          opened={menuOpen}
        />
      </SidebarItem>
    </>
  );

  return (
    <>
      <MainTitle>
        {menuOpen && (
          <>
            {authUser?.profile === Profiles.PARTNER_ADMIN
            ? t('components.sidebarMenu.administrationTitle')
            : t('components.sidebarMenu.logisticTitle')}
          </>
        )}
      </MainTitle>
      {authUser?.profile === Profiles.PARTNER_ADMIN && partnerAdminMenu}
      {authUser?.profile === Profiles.ORIZON_ADMIN && orizonAdminMenu}
    </>
  );
};

export default SidebarMenuBottom;
