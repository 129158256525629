import { AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import request from 'src/axios';

const useAddNursingHomeToUserMutation = () => {
  const queryClient = useQueryClient();

  const addNursingHomeToUserMutation = useMutation<
    AxiosResponse<any>,
    Error,
    {
      id: number;
      nursingHomeId: number;
    }
  >(
    ({ id, nursingHomeId }) => {
      return request.post(`/users/${id}/nursing-homes/${nursingHomeId}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('usersList');
      },
    },
  );

  return addNursingHomeToUserMutation;
};

export default useAddNursingHomeToUserMutation;
