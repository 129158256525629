import styled from 'styled-components/macro';

const Center = styled.div<{ direction?: 'row' | 'column' }>`
  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: ${({ direction }) =>
    direction === 'row' ? 'row' : 'column'};
`;

export default Center;
