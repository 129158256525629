import { useEffect, useCallback } from 'react';
import { Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import request from 'src/axios';
import ModalEditAvatar from 'src/components/Misc/Modal/ModalEditAvatar';
import { useModal } from 'src/hooks/useModal';
import { UserResponse } from 'src/sdk/users';
import { Button } from 'src/uikit/Button';
import styled from 'styled-components/macro';

const AvatarCol = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-top: 1rem;
  gap: 1rem;
`;

const Avatar = styled.img`
  width: 120px;
  aspect-ratio: 1/1;
`;

interface Props {
  user: UserResponse;
  avatar: string;
  setAvatar: (avatar: string) => void;
}

const UserAvatar = ({ user, avatar, setAvatar }: Props) => {
  const { t } = useTranslation();
  const { modalOpen, setModalOpen } = useModal();

  const loadImage = useCallback(
    async (avatarPath: string) => {
      if (avatarPath) {
        const response = await request.get(avatarPath.replace('/api/v1', ''), {
          responseType: 'blob',
        });

        setAvatar(URL.createObjectURL(response.data));
      }
    },
    [setAvatar],
  );

  useEffect(() => {
    loadImage(user?.avatarImagePath || '');
  }, [user, loadImage]);

  const handleChangeAvatar = useCallback(
    (avatarId: number) => {
      const userId = user?.id;

      if (userId !== -1) {
        if (avatarId !== -1) {
          request
            .put(`/users/${userId}/avatar`, { commonAvatarId: avatarId })
            .then((response) => {
              loadImage(response.data.avatarImagePath);
            })
            .catch((e) => {
              console.error(e);
            });
        }
      }
      setModalOpen(false);
    },
    [user?.id, setModalOpen, loadImage],
  );

  const handleUploadAvatar = useCallback(
    (upload: string) => {
      const userId = user?.id;

      if (userId !== -1) {
        fetch(upload)
          .then((res) => res.blob())
          .then((blob) => {
            let dto ={
              gender : user?.gender,
              profile : user?.profile,
              defaultAvatar : false
            }
            // Convert the dto object to a JSON string
            const dtoString = JSON.stringify(dto);
            const formData = new FormData();
            const file = new File([blob], '');
            // Append the JSON string as a field value
            formData.append('dto', dtoString);
            formData.append('file', file);
            request
              .post(`/users/${userId}/avatar`, formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              })
              .then((response) => {
                loadImage(response.data.avatarImagePath);
              })
              .catch((e) => {
                console.error(e);
              });
          });
      }
      setModalOpen(false);
    },
    [user?.id, setModalOpen, loadImage],
  );

  return (
    <>
      <AvatarCol>
        <Avatar src={avatar} />
        <Button onClick={() => setModalOpen(true)}>
          {t('components.Users.UserDetails.UserResume.editAvatarButton')}
        </Button>
      </AvatarCol>
      {user && (
        <ModalEditAvatar
          show={modalOpen}
          onClose={() => setModalOpen(false)}
          userId={user?.id || -1}
          role={user.profile}
          partnerUser={user.partnerUser}
          gender={user?.gender}
          preview={avatar}
          onChangeAvatar={handleChangeAvatar}
          onUploadAvatar={handleUploadAvatar}
        />
      )}
    </>
  );
};

export default UserAvatar;
