import { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useWatch } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import { DATE_FORMAT_DAY } from 'src/constants';
import useOptionsCountries from 'src/hooks/useOptionsCountries';
import { DeliveryAddress } from 'src/sdk/nursingHome';
import { SubscriptionCreationDataElementAddressRequest } from 'src/sdk/subscription';
import FormDateSelector from 'src/uikit/Form/DateSelector';
import FormSelectInput from 'src/uikit/Form/SelectInput';
import FormTextInput from 'src/uikit/Form/TextInput';
import { getStartingDate } from 'src/utils/getDate';
import styled from 'styled-components/macro';
import * as yup from 'yup';

import DeliveryAddressCard from './DeliveryAddressCard';

const Section = styled.div`
  width: 100%;
`;

const StyledCol = styled(Col)``;

const StyledRow = styled(Row)`
  align-items: center;
  width: 100%;
`;

const StyledLabel = styled.label`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1px;
  color: ${({ theme }) => theme.palette.black.main};
  margin: 0;
`;

export interface CurrentEquipments {
  clipons: number;
  gateways: number;
  chargingStations: number;
}

const Fieldset = styled.fieldset`
  display: flex;
  gap: 1rem;
  flex-direction: column;
`;

export const subscriptionDeliveryFormCustomAddressSchema = (
  t: TFunction,
): yup.SchemaOf<SubscriptionCreationDataElementAddressRequest> =>
  yup.object({
    name: yup
      .string()
      .min(
        2,
        t(
          'components.SubscriptionUpsert.SubscriptionDeliveryForm.equipments.errorMessage.name',
        ),
      )
      .max(
        35,
        t(
          'components.SubscriptionUpsert.SubscriptionDeliveryForm.equipments.errorMessage.nameMax',
        ),
      )
      .required(),
    street: yup
      .string()
      .min(
        2,
        t(
          'components.SubscriptionUpsert.SubscriptionDeliveryForm.equipments.errorMessage.street',
        ),
      )
      .max(
        35,
        t(
          'components.SubscriptionUpsert.SubscriptionDeliveryForm.equipments.errorMessage.streetMax',
        ),
      )
      .required(),
    zip: yup
      .string()
      .matches(
        /^[0-9]+$/,
        t(
          'components.SubscriptionUpsert.SubscriptionDeliveryForm.equipments.errorMessage.zip',
        ),
      )
      .max(
        9,
        t(
          'components.SubscriptionUpsert.SubscriptionDeliveryForm.equipments.errorMessage.zipMax',
        ),
      )
      .required(),
    town: yup
      .string()
      .min(
        1,
        t(
          'components.SubscriptionUpsert.SubscriptionDeliveryForm.equipments.errorMessage.town',
        ),
      )
      .max(
        35,
        t(
          'components.SubscriptionUpsert.SubscriptionDeliveryForm.equipments.errorMessage.townMax',
        ),
      )
      .required(),
    country: yup
      .string()
      .min(
        1,
        t(
          'components.SubscriptionUpsert.SubscriptionDeliveryForm.equipments.errorMessage.country',
        ),
      )
      .required(),
    email: yup
      .string()
      .min(
        1,
        t(
          'components.SubscriptionUpsert.SubscriptionDeliveryForm.equipments.errorMessage.email',
        ),
      )
      .max(
        75,
        t(
          'components.SubscriptionUpsert.SubscriptionDeliveryForm.equipments.errorMessage.emailMax',
        ),
      )
      .email(
        t(
          'components.SubscriptionUpsert.SubscriptionDeliveryForm.equipments.errorMessage.emailFormat',
        ),
      )
      .required(),
    telephone: yup
      .string()
      .min(
        1,
        t(
          'components.SubscriptionUpsert.SubscriptionDeliveryForm.equipments.errorMessage.phone',
        ),
      )
      .max(
        25,
        t(
          'components.SubscriptionUpsert.SubscriptionDeliveryForm.equipments.errorMessage.phoneMax',
        ),
      )
      .required(),
  });

export type SubscriptionDeliveryFormCustomAddressSchema = yup.InferType<
  ReturnType<typeof subscriptionDeliveryFormCustomAddressSchema>
>;

export const subscriptionDeliveryFormSchema = (t: TFunction) =>
  yup.object({
    requestedDeliveryDate: yup.string(),
    deliveryAddressId: yup.number().required(),
    customAddress: yup
      .object()
      .when('deliveryAddressId', {
        is: -1,
        then: () =>
          subscriptionDeliveryFormCustomAddressSchema(
            t,
          ) as yup.SchemaOf<SubscriptionCreationDataElementAddressRequest>,
      })
      .nullable(
        true,
      ) as yup.SchemaOf<SubscriptionCreationDataElementAddressRequest | null>,
  });

export type SubscriptionDeliveryFormSchema = yup.InferType<
  ReturnType<typeof subscriptionDeliveryFormSchema>
>;

interface Props {
  deliveryAddresses: (DeliveryAddress & { isPartner?: boolean })[];
  startDate: string;
}

const SubscriptionDeliveryForm = ({ deliveryAddresses, startDate }: Props) => {
  const { t } = useTranslation();
  const { groupedCountriesOptions } = useOptionsCountries();

  const deliveryAddressId = useWatch({ name: 'deliveryAddressId' });

  const addressOptions = useMemo(() => {
    const propspectAddressOptions = deliveryAddresses
      .filter((deliveryAddress) => !deliveryAddress.isPartner)
      ?.map((address: DeliveryAddress) => {
        return { label: address.name, value: address.id };
      })
      .concat({ label: 'Custom', value: -1 });

    const partnerAddressOptions = deliveryAddresses
      .filter((deliveryAddress) => deliveryAddress.isPartner)
      ?.map((address: DeliveryAddress) => {
        return { label: address.name, value: address.id };
      });

    return partnerAddressOptions && partnerAddressOptions.length > 0
      ? [
          {
            label: t(
              'components.SubscriptionUpsert.SubscriptionDeliveryForm.equipments.address.nursing',
            ),
            options: propspectAddressOptions,
          },
          {
            label: t(
              'components.SubscriptionUpsert.SubscriptionDeliveryForm.equipments.address.partner',
            ),
            options: partnerAddressOptions,
          },
        ]
      : propspectAddressOptions;
  }, [deliveryAddresses, t]);

  const minDeliveryDate = useMemo(() => {
    let tmp = new Date(getStartingDate());
    tmp.setDate(tmp.getDate() + 1);
    return tmp;
  }, []);

  return (
    <Fieldset>
      <Row>
        <Col md="6" className="pt-2">
          <StyledLabel>
            {t(
              'components.SubscriptionUpsert.SubscriptionDeliveryForm.equipments.deliveryDate',
            )}
          </StyledLabel>
        </Col>
        <Col md="4">
          <FormDateSelector
            name="requestedDeliveryDate"
            minDate={minDeliveryDate}
            maxDate={new Date(startDate)}
            placeholder="Delivery date"
            dateFormat={DATE_FORMAT_DAY}
          />
        </Col>
      </Row>
      <Row>
        <Col md="6" className="pt-2">
          <StyledLabel>
            {t(
              'components.SubscriptionUpsert.SubscriptionDeliveryForm.equipments.deliveryAddress',
            )}
          </StyledLabel>
        </Col>
        <Col md="4">
          <FormSelectInput
            name="deliveryAddressId"
            placeholder="Delivery Address"
            options={addressOptions}
          />
        </Col>
      </Row>
      <Section>
        {deliveryAddressId !== -1 && (
          <DeliveryAddressCard
            data={deliveryAddresses.find((e) => e.id === deliveryAddressId)}
          />
        )}
        {deliveryAddressId === -1 && (
          <>
            <StyledRow>
              <StyledCol md="12">
                <FormTextInput
                  name="customAddress.name"
                  placeholder={t('common.address.name')}
                  width={'100%'}
                />
              </StyledCol>
            </StyledRow>
            <StyledRow>
              <StyledCol md="12">
                <FormTextInput
                  name="customAddress.street"
                  placeholder={t('common.address.street')}
                  width={'100%'}
                />
              </StyledCol>
            </StyledRow>
            <StyledRow>
              <StyledCol md="3">
                <FormTextInput
                  name="customAddress.zip"
                  placeholder={t('common.address.zip')}
                  width={'100%'}
                />
              </StyledCol>
              <StyledCol md="1" />
              <StyledCol md="4">
                <FormTextInput
                  name="customAddress.town"
                  placeholder={t('common.address.town')}
                  width={'100%'}
                />
              </StyledCol>
              <StyledCol md="1" />
              <StyledCol md="3">
                <FormSelectInput
                  name="customAddress.country"
                  placeholder={t('common.address.country')}
                  width={'100%'}
                  options={groupedCountriesOptions}
                />
              </StyledCol>
            </StyledRow>
            <StyledRow>
              <StyledCol md="4">
                <FormTextInput
                  name="customAddress.email"
                  placeholder={t('common.address.email')}
                  width={'100%'}
                />
              </StyledCol>
              <StyledCol md="4" />
              <StyledCol md="4">
                <FormTextInput
                  name="customAddress.telephone"
                  placeholder={t('common.address.phone')}
                  width={'100%'}
                />
              </StyledCol>
            </StyledRow>
          </>
        )}
      </Section>
    </Fieldset>
  );
};

export default SubscriptionDeliveryForm;
