import { ReactNode } from 'react';
import { Dropdown as BootstrapDropdown } from 'react-bootstrap';
import { DropdownToggleProps as BootstrapDropdownToggleProps } from 'react-bootstrap/esm/DropdownToggle';
import { ChevronBottom } from 'src/assets/arrows';
import styled from 'styled-components/macro';

const DropdownIndicatorIcon = styled(ChevronBottom)`
  display: flex;
  margin: 8px;
  color: #44444f;
  width: 15px;
  height: 15px;
`;

export const StyledDropdownToggle = styled(BootstrapDropdown.Toggle)`
  display: flex !important;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;

  padding-block: 0.25rem;
  padding-inline: 0.5rem 0;
  cursor: pointer;

  color: ${({ theme }) => theme.palette.black.main};
  background-color: ${({ theme }) => theme.palette.main.white};
  border: none !important;

  :focus,
  :active,
  :hover,
  :visited,
  :not(:disabled):not(.disabled):active {
    color: ${({ theme }) => theme.palette.black.main};
    background-color: ${({ theme }) => theme.palette.main.white};
    box-shadow: none !important;
  }

  ::after {
    display: none;
  }

  .show {
  }
`;

type DropdownToggleProps = {
  children: ReactNode;
} & BootstrapDropdownToggleProps;

export const DropdownToggle = ({ children, ...rest }: DropdownToggleProps) => {
  return (
    <StyledDropdownToggle {...rest}>
      {children}
      <DropdownIndicatorIcon />
    </StyledDropdownToggle>
  );
};

export const DropdownItem = styled(BootstrapDropdown.Item)``;

export const DropdownMenu = styled(BootstrapDropdown.Menu)`
  z-index: 999;
  padding: 0.25rem 0;
  border-color: ${({ theme }) => theme.palette.black.lightGrey};
  box-shadow: ${({ theme }) => theme.shadows.xs} !important;
`;

export const Dropdown = styled(BootstrapDropdown)`
  &.show {
    ${StyledDropdownToggle} {
      color: ${({ theme }) => theme.palette.black.main};
      background-color: ${({ theme }) => theme.palette.black.lightGrey};
    }
  }
`;
