import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Routes from 'src/router/Routes';
import { Button } from 'src/uikit/Button';
import { H4, P } from 'src/uikit/Typography';
import styled from 'styled-components/macro';


const Wrapper = styled.div`
  width: auto;
`;

const BlockWrapper = styled.div`
  width: 325px;
  margin: 150px auto 0 auto;
  text-align: center;
`

const ForgotPasswordWelcome = styled(H4)`
  margin-bottom: 12px;
`;

const ForgotPasswordSubtitle = styled(P)`
  margin-bottom: 47px;
`;

const ForgotBlock = styled.div`
  display: flex;
  align-items: baseline;
  margin: 30px 0;
`;

const SubmitBlock = styled(ForgotBlock)`
  justify-content: center;
`;

const ForgotPasswordSent = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleClick = useCallback(() => {
    history.push(Routes.LOGIN);
  }, [history]);

  return (
    <Wrapper>
      <BlockWrapper>
        <ForgotPasswordWelcome>{t('components.Auth.ForgotPasswordForm.forgotPasswordSent.thank')}</ForgotPasswordWelcome>
        <ForgotPasswordSubtitle>{t('components.Auth.ForgotPasswordForm.forgotPasswordSent.message')}</ForgotPasswordSubtitle>
        <SubmitBlock>
          <Button onClick={handleClick}>{t('components.Auth.ForgotPasswordForm.login')}</Button>
        </SubmitBlock>
      </BlockWrapper>
    </Wrapper>
  );
};

export default ForgotPasswordSent;
