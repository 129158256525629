import { useTranslation } from 'react-i18next';
import { useApp } from 'src/contexts/App';
import { Locales } from 'src/translations/i18n';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'src/uikit/Dropdown';
import { fontSecondary } from 'src/uikit/theme/typography';
import { Label } from 'src/uikit/Typography';
import styled from 'styled-components/macro';

import { BeFlag, DeFlag, FrFlag, ItFlag, EnFlag } from '../../assets/flags';

const LangDropdownToggle = styled(DropdownToggle)``;

const LangIcon = styled.div`
  height: 100%;
  display: flex;
  justify: content: center;
  align-items: center;`;

const LangLabel = styled(Label)`
  color: ${({ theme }) => theme.palette.black.main};
`;

const LangOption = styled(DropdownItem)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  ${fontSecondary};
  font-size: 14px;
  color: ${({ theme }) => theme.palette.black.main};
  padding-inline: 1rem;

  span {
    margin-top: 0.175rem;
  }
`;

const LangMenu = styled(DropdownMenu)`
  min-width: 180px;
  width: max-content;
  margin-top: 0.25rem !important;
`;

const supportedLanguageList = {
  [Locales.EN]: {
    label: 'En',
    icon: <EnFlag />,
  },
  [Locales.DE]: {
    label: 'De',
    icon: <DeFlag />,
  },
  [Locales.FR]: {
    label: 'Fr',
    icon: <FrFlag />,
  },
  [Locales.NL]: {
    label: 'Be',
    icon: <BeFlag />,
  },
  [Locales.IT]: {
    label: 'It',
    icon: <ItFlag />,
  },
};

const LanguageSwitcher = () => {
  const { language, setLanguage } = useApp();
  const { t } = useTranslation();

  return (
    <Dropdown>
      <LangDropdownToggle
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="true"
      >
        <LangIcon>
          {supportedLanguageList[language || Locales.EN].icon}
        </LangIcon>
        <LangLabel>
          {supportedLanguageList[language || Locales.EN].label}
        </LangLabel>
      </LangDropdownToggle>
      <LangMenu aria-labelledby="dropdownMenuButton">
        <LangOption onClick={() => setLanguage?.(Locales.EN)}>
          <EnFlag /> <span>{t('common.language.EN')}</span>
        </LangOption>
        <LangOption onClick={() => setLanguage?.(Locales.DE)}>
          <DeFlag /> <span>{t('common.language.DE')}</span>
        </LangOption>
        <LangOption onClick={() => setLanguage?.(Locales.FR)}>
          <FrFlag /> <span>{t('common.language.FR')}</span>
        </LangOption>
        <LangOption onClick={() => setLanguage?.(Locales.NL)}>
          <BeFlag /> <span>{t('common.language.NL')}</span>
        </LangOption>
        <LangOption onClick={() => setLanguage?.(Locales.IT)}>
          <ItFlag /> <span>{t('common.language.IT')}</span>
        </LangOption>
      </LangMenu>
    </Dropdown>
  );
};

export default LanguageSwitcher;
