import { GetResponse } from './common';

export enum SubscriptionStatus {
  CREATED = 'CREATED',
  BLOCKED = 'BLOCKED',
  ACTIVE = 'ACTIVE',
  TERMINATED = 'TERMINATED',
}

export interface Country {
  name: string;
  sapCode: string;
  priority: number;
}
export type Countries = Country[];

interface NHAttached {
  nursingHomeId: number;
}

export interface SubscriptionComputedEstimateResponse {
  residentEstimate: number;
  gatewayEstimate: number;
  cliponEstimate: number;
  chargingStationEstimate: number;
}

export interface SubscriptionResponse {
  automaticRenewal?: boolean;
  /** Format: int32 */
  chargingStationEstimate?: number;
  /** Format: int32 */
  cliponEstimate?: number;
  /** Format: date-time */
  endDate?: string;
  /** Format: int32 */
  gatewayEstimate?: number;
  /** Format: int64 */
  id?: number;
  main?: boolean;
  /** Format: int64 */
  nursingHomeId?: number;
  /** Format: int32 */
  residentEstimate?: number;
  /** Format: date-time */
  startDate?: string;
  /** @enum {string} */
  status?: 'CREATED' | 'BLOCKED' | 'ACTIVE' | 'TERMINATED';
  /** @enum {string} */
  type?: 'DEMO' | 'STANDARD';
}

export interface GetSubscriptionResponse
  extends GetResponse<SubscriptionResponse> {}

export type SubscriptionCreationDataElementAddressRequest = {
  country: string;
  email: string;
  name: string;
  street: string;
  telephone: string;
  town: string;
  zip: string;
};

export interface SubscriptionCreationRequest extends NHAttached {
  automaticRenewal: boolean;
  /** Format: int64 */
  deliveryAddressId?: number | null;
  /**
   * Format: int32
   * @description The subscription duration. The time unit is subscription type dependant.
   */
  duration: number;
  /** Format: int32 */
  extraChargingStations?: number | null;
  /** Format: int32 */
  extraGateways?: number | null;
  /** Format: int64 */
  nursingHomeId: number;
  /**
   * Format: date-time
   * @description Must be the start of a day in UTC (i.e. 00:00:00.000) and far enough in the future to respect the blocking duration.
   */
  requestedDeliveryDate: string | null;
  /** Format: int32 */
  residentEstimate: number;
  /**
   * Format: date-time
   * @description Must be the start of a day in UTC (i.e. 00:00:00.000) and after (or at the same moment than) the requested delivery date.
   */
  startDate: string;
  /** @enum {string} */
  type: 'DEMO' | 'STANDARD';
  customAddress?: SubscriptionCreationDataElementAddressRequest | null;
  noShippingRequest: boolean;
}

export interface GetSubscriptionsResponse
  extends GetResponse<SubscriptionResponse> {}

/** @description The details of the delivery address (when deliveryAddressId is not null) or custom address (when deliveryAddressId is null) of the most recent shipping request of the subscription. */
export interface SubscriptionCreationDataElementAddressResponse {
  /** @description Not used by custom addresses. */
  comment?: string;
  country?: string;
  email?: string;
  name?: string;
  /** @description Not used by custom addresses. */
  shipTo?: string;
  street?: string;
  telephone?: string;
  town?: string;
  zip?: string;
}

export interface SubscriptionCreationDataElementResponse {
  address?: SubscriptionCreationDataElementAddressResponse;
  automaticRenewal?: boolean;
  /**
   * Format: int32
   * @description This estimate includes the extra equipments.
   */
  chargingStationEstimate?: number;
  /** Format: int32 */
  cliponEstimate?: number;
  /**
   * Format: int64
   * @description The optional delivery address ID of the most recent shipping request of the subscription.
   */
  deliveryAddressId?: number;
  /** Format: date-time */
  endDate?: string;
  /** Format: int32 */
  extraChargingStations?: number;
  /** Format: int32 */
  extraGateways?: number;
  /**
   * Format: int32
   * @description This estimate includes the extra equipments.
   */
  gatewayEstimate?: number;
  /**
   * Format: date-time
   * @description The requested delivery date of the most recent shipping request of the subscription.
   */
  requestedDeliveryDate?: string;
  /** Format: int32 */
  residentEstimate?: number;
  /** Format: date-time */
  startDate?: string;
  /** @enum {string} */
  type?: 'DEMO' | 'STANDARD';
  hasShippingRequest?: boolean;
}

export type TypeConfiguration = {
  allowNegativeExtraChargingStations: boolean;
  allowNegativeExtraGateways: boolean;
  automaticRenewal: boolean | null;
  chargingStationEstimate: number | null;
  cliponEstimate: number | null;
  durationUnit: 'YEARS';
  gatewayEstimate: number | null;
  maximumDuration: number;
  maximumExtraChargingStations: number;
  maximumExtraGateways: number;
  minimumResidentEstimate: number;
  residentEstimate: number;
  type: SubscriptionCreationRequest['type'];
  /** @description True when the subscription type can use no shipping request, false otherwise. */
  supportNoShippingRequest?: boolean;
};

export interface GetSubscriptionCreationData {
  /** Format: int64 */
  blockedDurationAmount?: number;
  /** @enum {string} */
  blockedDurationUnit?:
    | 'Nanos'
    | 'Micros'
    | 'Millis'
    | 'Seconds'
    | 'Minutes'
    | 'Hours'
    | 'HalfDays'
    | 'Days'
    | 'Weeks'
    | 'Months'
    | 'Years'
    | 'Decades'
    | 'Centuries'
    | 'Millennia'
    | 'Eras'
    | 'Forever';
  mainSubscription?: SubscriptionCreationDataElementResponse;
  secondarySubscription?: SubscriptionCreationDataElementResponse;
  typeConfigurations?: TypeConfiguration[];
}

export interface BillingAssemblySubscriptionResponse {
  automaticRenewal?: boolean;
  /** Format: int32 */
  chargingStationEstimate?: number;
  /** Format: int32 */
  cliponEstimate?: number;
  /** Format: date-time */
  endDate?: string;
  /** Format: int32 */
  gatewayEstimate?: number;
  /** Format: int64 */
  id?: number;
  main?: boolean;
  /** Format: int64 */
  nursingHomeId?: number;
  /** Format: int32 */
  residentEstimate?: number;
  /** Format: date-time */
  startDate?: string;
  /** @enum {string} */
  status?: 'CREATED' | 'BLOCKED' | 'ACTIVE' | 'TERMINATED';
  /** @enum {string} */
  type?: 'DEMO' | 'STANDARD';
}

export interface BillingAssemblyResponse {
  /**
   * Format: int64
   * @description Persistence ID of the configuration which has been used to make the assembly. Can be -1 if there was no configuration (and the default configuration was used).
   */
  configurationId?: number;
  /**
   * Format: int32
   * @description Number of daytime units in the assembly. Only in NIGHTS_AND_DAYS mode.
   */
  daytimeCount?: number;
  /**
   * Format: int32
   * @description Number of demo units in the assembly. Only in DEMO mode.
   */
  demoCount?: number;
  /**
   * Format: int32
   * @description Number of full-day units in the assembly. Used in both FULL_DAYS and FLAT_RATE modes.
   */
  fullDayCount?: number;
  /**
   * Format: int64
   * @description Persistence ID of the assembly.
   */
  id: number;
  /** @description Idoc number with which SAP answered (if the call to SAP was successful). */
  idocNumber?: string;
  /**
   * Format: int32
   * @description Month (1-12) of the assembly.
   */
  month?: number;
  /**
   * Format: int32
   * @description Number of nighttime units in the assembly. Only in NIGHTS_AND_DAYS mode.
   */
  nighttimeCount?: number;
  /**
   * Format: int64
   * @description Persistence ID of the nursing home.
   */
  nursingHomeId?: number;
  /**
   * Format: date-time
   * @description Timestamp at which the assembly has been sent to SAP.
   */
  sendToSapDateTime?: string;
  subscriptions?: BillingAssemblySubscriptionResponse[];
  /**
   * Format: int32
   * @description Year of the assembly.
   */
  year?: number;
}

export interface GetBillingAssembliesResponse
  extends GetResponse<BillingAssemblyResponse> {}
