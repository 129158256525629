import {
  createContext,
  FC,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { useQueryClient } from 'react-query';
import { SubscriptionResponse } from 'src/sdk/subscription';

import useFetchSecurityMe from '../components/Auth/hooks/useFetchSecurityMe';

export type UserSession = {
  id: number;
  firstName: string;
  lastName: string;
  profile:
    | 'ORIZON_ADMINISTRATOR'
    | 'NURSING_HOME'
    | 'PARTNER_ADMINISTRATOR'
    | 'PRODUCT_SPECIALIST'
    | 'SALES_ASSISTANT'
    | 'DIRECTOR'
    | 'HEAD_NURSE'
    | 'NURSE'
    | 'CAREGIVER'
    | 'SAP'
    | 'TTN';
  language: string;
  token: string | undefined;
  avatarImagePath?: string;
  partnerUser: boolean;
};

export type Tenant = {
  id: number;
  name: string;
  tenantName: string;
  subscription?: SubscriptionResponse;
};

type AuthContextProps = {
  authUser?: UserSession;
  setAuthUser: (data: UserSession) => void;
  removeAuthUser: () => void;
  nhSession?: Tenant;
  setNhSession: (data: Tenant) => void;
  removeNhSession: () => void;
};

export const AuthContext = createContext<Partial<AuthContextProps>>({});

export const AuthProvider: FC = ({ children }) => {
  const queryClient = useQueryClient();

  const [authUser, setAuthUser] = useState<UserSession | undefined>(() => {
    const existingUser = localStorage.getItem('CURRENT_USER');
    if (existingUser && existingUser !== 'undefined') {
      return JSON.parse(existingUser);
    }
  });

  const { data } = useFetchSecurityMe({
    enabled: !!authUser?.id,
  });

  const setUser = async (data: UserSession) => {
    localStorage.setItem('CURRENT_USER', JSON.stringify(data));
    setAuthUser(data);
  };

  const removeUser = useCallback(async () => {
    await localStorage.removeItem('CURRENT_USER');
    await setAuthUser(undefined);
    await setNhSession(undefined);
    queryClient.resetQueries();
  }, [queryClient]);

  useEffect(() => {
    if (data && data.userId === null) {
      removeUser();
    }
  }, [data, removeUser]);

  const [nhSession, setNhSession] = useState<Tenant | undefined>(() => {
    const existingNh = localStorage.getItem('CURRENT_NH');
    if (existingNh && existingNh !== 'undefined') {
      return JSON.parse(existingNh);
    }
  });

  const setNH = (data: Tenant) => {
    localStorage.setItem('CURRENT_NH', JSON.stringify(data));
    setNhSession(data);
  };

  const removeNH = () => {
    localStorage.removeItem('CURRENT_NH');
    setNhSession(undefined);
  };

  return (
    <AuthContext.Provider
      value={{
        authUser,
        setAuthUser: setUser,
        removeAuthUser: removeUser,
        nhSession,
        setNhSession: setNH,
        removeNhSession: removeNH,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  return useContext(AuthContext);
}
