import { useMemo, useState } from 'react';
import { Col, OverlayTrigger, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ReactComponent as GeneralIcon } from 'src/assets/nursing-general.svg';
import AddressCard from 'src/components/Misc/Card/AddressCard';
import Pagination, { DEFAULT_PAGE_SIZE } from 'src/components/Misc/Pagination';
import useFormatErrorApi from 'src/hooks/useFormatErrorApi';
import useToast from 'src/hooks/useToast';
import { NursingHomeResponse } from 'src/sdk/nursingHome';
import { List } from 'src/uikit/Layout/List';
import SearchInput from 'src/uikit/SearchInput';
import { Tooltip } from 'src/uikit/Tooltip';
import { paginate } from 'src/utils/paginate';
import styled from 'styled-components/macro';

import useFetchConfigurationProspectsQuery from '../hooks/useFetchConfigurationProspectsQuery';
import useUpdateDeliveryAddresses from '../hooks/useUpdateDeliveryAddresses';

const StyledTitle = styled.div`
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  margin-bottom: 20px;
`;

// TODO Handle save button always visible
const Wrapper = styled.div`
  padding: 30px;
`;

const WrapperInformatioIcon = styled.div`
  margin-top: 0.25rem;
  color: ${({ theme }) => theme.palette.black[60]};
  width: 28px;
  height: 28px;

  svg {
    width: inherit;
    height: inherit;
  }
`;

const StyledUnselectedTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ErrorMessage = styled.p`
  font-weight: 600;
  text-align: center;
  color: ${({ theme }) => theme.palette.main.red};
  font-size: 16px;
  padding: 0 4rem;
`;

interface Props {
  nursingHome: NursingHomeResponse;
  id: string;
}

const PAGE_SIZE = DEFAULT_PAGE_SIZE;

const NursingHomeConfigurationAddressesOntex = ({ nursingHome, id }: Props) => {
  const [page, setPage] = useState(0);
  const [searchValue, setSearchValue] = useState('');

  const { formatErrorApi } = useFormatErrorApi();

  const { data } = useFetchConfigurationProspectsQuery('Prospect', {
    salesOrg: nursingHome.salesOrg,
    soldTo: nursingHome.soldTo,
  });

  const deliveryAddressesSelected = useMemo(() => {
    return nursingHome?.deliveryAddresses;
  }, [nursingHome?.deliveryAddresses]);

  const deliveryAddressesAvailableNotSelected = useMemo(() => {
    const _address =
      data?.deliveryAddresses.filter(
        (address) =>
          address.available &&
          deliveryAddressesSelected.findIndex(
            (addressSelect) =>
              address.shipTo.toString() === addressSelect.shipTo,
          ) === -1,
      ) || [];

    const shipTos = _address.map((o) => o.shipTo);
    return _address.filter(
      ({ shipTo }, index) => !shipTos.includes(shipTo, index + 1),
    );
  }, [data?.deliveryAddresses, deliveryAddressesSelected]);

  const { t } = useTranslation();
  const { toastSuccess, toastError } = useToast();

  const updateDeliveryAddressesMutation = useUpdateDeliveryAddresses(id);

  const onSubmit = async (data: {
    addShipTos: string[];
    removeShipTos: string[];
  }) => {
    try {
      await updateDeliveryAddressesMutation.mutateAsync(data);
      toastSuccess(t('nursingHome.updateDeliveryAddresses.success'));
    } catch (err) {
      const message = formatErrorApi(err);
      toastError(message);
    }
  };

  const onAdd = (shipTo: string) => {
    onSubmit({
      addShipTos: [shipTo],
      removeShipTos: [],
    });
  };

  const onDelete = (shipTo: string) => {
    onSubmit({
      addShipTos: [],
      removeShipTos: [shipTo],
    });
  };

  const deliveryAddressesAvailableNotSelectedFiltered = useMemo(() => {
    return deliveryAddressesAvailableNotSelected?.filter((address) =>
      address.name.toLowerCase().includes(searchValue.toLowerCase()),
    );
  }, [deliveryAddressesAvailableNotSelected, searchValue]);

  const deliveryAddressesAvailableNotSelectedPaginated = paginate(
    page,
    PAGE_SIZE,
    deliveryAddressesAvailableNotSelectedFiltered,
  );

  return (
    <Wrapper>
      <StyledTitle>
        {t('nursingHome.updateDeliveryAddresses.title', {
          name: nursingHome.name,
        })}
      </StyledTitle>

      <p className="margin-bottom: 30px">
        {t('nursingHome.updateDeliveryAddresses.unselectionLabel')}
      </p>
      <List columnSize="350px" gap="1.5rem">
        {deliveryAddressesSelected && deliveryAddressesSelected
          .filter((deliveryAddress) => deliveryAddress.shipTo)
          .map((deliveryAddress) =>
            !deliveryAddress.shippingRequestCount ? (
              <AddressCard
                key={deliveryAddress.id}
                variant="hover"
                type="delete"
                address={deliveryAddress}
                onClick={() => onDelete(String(deliveryAddress.shipTo))}
              />
            ) : (
              <AddressCard
                key={deliveryAddress.id}
                variant="action"
                address={deliveryAddress}
                disabled
                action={
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip id="button-tooltip">
                        {t(
                          'nursingHome.updateDeliveryAddresses.tooltipDisabled',
                        )}
                      </Tooltip>
                    }
                  >
                    <WrapperInformatioIcon>
                      <GeneralIcon />
                    </WrapperInformatioIcon>
                  </OverlayTrigger>
                }
              />
            ),
          )}
      </List>
      <StyledUnselectedTitle className="mt-4">
        <p>{t('nursingHome.updateDeliveryAddresses.selectionLabel')}</p>
      </StyledUnselectedTitle>
      <Row className="mb-4">
        <Col md="2">
          <SearchInput onChange={setSearchValue} />
        </Col>
      </Row>
      <List columnSize="350px" gap="1.5rem">
        {deliveryAddressesAvailableNotSelectedPaginated?.map(
          (deliveryAddress, i) => (
            <AddressCard
              key={`${deliveryAddress.shipTo}-${i}`}
              variant="hover"
              type="add"
              address={deliveryAddress}
              onClick={() => onAdd(deliveryAddress.shipTo)}
            />
          ),
        )}
      </List>
      {deliveryAddressesAvailableNotSelectedFiltered &&
        deliveryAddressesAvailableNotSelectedFiltered.length > PAGE_SIZE && (
          <Pagination
            size="sm"
            totalElements={
              deliveryAddressesAvailableNotSelectedFiltered?.length
            }
            perPage={PAGE_SIZE}
            onPageChange={setPage}
            currentPage={page}
          />
        )}
    </Wrapper>
  );
};

export default NursingHomeConfigurationAddressesOntex;
