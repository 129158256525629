import { format } from 'date-fns';
import { Col, Row } from 'react-bootstrap';
import { ReactComponent as Charging } from 'src/assets/charging-badge.svg';
import { ReactComponent as Clipon } from 'src/assets/clipon-badge.svg';
import { ReactComponent as Gateway } from 'src/assets/gateway-badge.svg';
import SortingOrderingDetailsHeader from 'src/components/SortingHeaders/SortingOrderingDetailsHeader';
import { DATE_FORMAT_DAY } from 'src/constants';
import { EquipmentTypes } from 'src/sdk/equipment';
import { EquipmentOrder } from 'src/sdk/order';
import { List } from 'src/uikit/Layout/List';
import { Text } from 'src/uikit/Typography';
import styled from 'styled-components/macro';

const Line = styled.div`
  margin: 15px 0;
  border-bottom: 1px solid ${({ theme }) => theme.palette.black[50]};
`;

const CliponBadge = styled(Clipon)`
  height: 40px;
  width: 40px;
`;

const ChargingBadge = styled(Charging)`
  height: 40px;
  width: 40px;
`;

const GatewayBadge = styled(Gateway)`
  height: 40px;
  width: 40px;
`;

const StyledCol = styled(Col)`
  display: flex;
  align-items: center;
  // justify-content: center;
`;

const NumberText = styled(Text)`
  font-weight: normal;
  margin: 0;
  color: ${({ theme }) => theme.palette.black[90]};
`;

interface Props {
  ordersList: EquipmentOrder[];
}

const OrderingDetailsList = ({ ordersList }: Props) => {
  return (
    <Col md="12">
      <Line />
      <List className="mx-4">
        <SortingOrderingDetailsHeader onSortingChange={(field) => null} />
        {ordersList &&
          ordersList.map((equipment) => (
            <Row key={equipment.id}>
              <StyledCol md="1">
                {equipment.equipmentType === EquipmentTypes.CLIPON && (
                  <CliponBadge />
                )}
                {equipment.equipmentType === EquipmentTypes.GATEWAY && (
                  <GatewayBadge />
                )}
                {equipment.equipmentType === EquipmentTypes.CHARGING && (
                  <ChargingBadge />
                )}
              </StyledCol>
              <StyledCol md="3">
                <NumberText>
                  {format(
                    new Date(equipment.plannedDeliveryDate),
                    DATE_FORMAT_DAY,
                  )}
                </NumberText>
              </StyledCol>
              <StyledCol md="2">
                <NumberText>{equipment.quantity}</NumberText>
              </StyledCol>
              <StyledCol md="5">
                <NumberText>{equipment.description}</NumberText>
              </StyledCol>
            </Row>
          ))}
      </List>
    </Col>
  );
};

export default OrderingDetailsList;
