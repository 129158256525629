import { useTranslation } from 'react-i18next';
import { ShippingRequestHandlingUnitResponse } from 'src/sdk/shippingRequest';
import { Badge } from 'src/uikit/Badge';
import styled, { css } from 'styled-components/macro';

const StyledBadge = styled(Badge)<{
  status: ShippingRequestHandlingUnitResponse['shippingStatus'] | null;
}>`
  ${({ theme, status }) =>
    status === 'SENT' &&
    css`
      background-color: ${theme.palette.main.orange};
    `}
  ${({ theme, status }) =>
    status === 'RECEIVED' &&
    css`
      background-color: ${theme.palette.main.greenElectric};
    `}
  ${({ theme, status }) =>
    !status &&
    css`
      background-color: ${theme.palette.black[60]};
    `}
`;

interface Props {
  status: ShippingRequestHandlingUnitResponse['shippingStatus'];
}

const HandlingUnitStatusBadge = ({ status }: Props) => {
  const { t } = useTranslation();
  return (
    <StyledBadge variant="primary" status={status}>
      {t(
        `components.nursingHome.equipments.shippingRequests.HandlingUnitStatusBadge.${
          status || 'default'
        }`,
      )}
    </StyledBadge>
  );
};

export default HandlingUnitStatusBadge;
