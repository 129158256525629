import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import { useEffect, useMemo } from 'react';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useToast from 'src/hooks/useToast';
import { Button } from 'src/uikit/Button';
import { StyledHelper } from 'src/uikit/Form';
import FormPasswordInput from 'src/uikit/Form/PasswordInput';
import { H4 } from 'src/uikit/Typography';
import { CardWrapper, FormButtonsWrapper } from 'src/uikit/Wrapper';
import styled from 'styled-components/macro';
import * as yup from 'yup';

import useUpdatePassword from '../hooks/useUpdatePassword';

const StyledInputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const StyledCentered = styled(StyledInputs)`
  align-self: center;
`;

export type UpdatePasswordFormType = {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
};

const defaultValues = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
};

type Props = {
  id: number;
};

const UpdatePasswordForm = ({ id }: Props) => {
  const { t } = useTranslation();
  const { toastSuccess, toastError } = useToast();
  const { updatePasswordMutation } = useUpdatePassword(id);

  const formSchema = useMemo(
    () =>
      yup.object({
        newPassword: yup.string().required(t(`form.error.passwordRequired`)),
        confirmPassword: yup
          .string()
          .required(t('form.error.passwordConfirmRequired'))
          .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
      }),
    [t],
  );

  const methods = useForm<UpdatePasswordFormType>({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    defaultValues,
  });

  const { formState, handleSubmit, reset, watch, trigger } = methods;
  const { isValid } = formState;

  const newPassword = watch('newPassword');

  useEffect(() => {
    if (newPassword) {
      trigger('confirmPassword');
    }
  }, [newPassword, trigger]);

  // TODO Handle success and error
  const onSubmit: SubmitHandler<UpdatePasswordFormType> = async (formData) => {
    updatePasswordMutation
      .mutateAsync(formData)
      .then(() => {
        reset(defaultValues);
        toastSuccess(t('nursingHome.passwordForm.success'));
      })
      .catch((error: AxiosError) => {
        toastError(t('common.errorHasOccured'));
      });
  };

  return (
    <CardWrapper>
      <FormProvider {...methods}>
        <H4>{t('nursingHome.passwordForm.generalLabel')}</H4>
        <StyledHelper className="mt-2">
          {t('nursingHome.passwordForm.helper')}
        </StyledHelper>
        <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
          <StyledInputs>
            <StyledCentered>
              <FormPasswordInput
                placeholder={t(`nursingHome.passwordForm.newPassword`)}
                name="newPassword"
                showRules
              />
              <FormPasswordInput
                placeholder={t(`nursingHome.passwordForm.confirmPassword`)}
                name="confirmPassword"
              />
            </StyledCentered>
          </StyledInputs>
          <FormButtonsWrapper oneButton>
            <Button
              disabled={!isValid || updatePasswordMutation.isLoading}
              type="submit"
            >
              {t('common.action.save')}
            </Button>
          </FormButtonsWrapper>
        </form>
      </FormProvider>
    </CardWrapper>
  );
};

export default UpdatePasswordForm;
