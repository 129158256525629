import styled from 'styled-components/macro';

import { ReactComponent as AddIcon } from './add.svg';
import { ReactComponent as AddressIcon } from './address.svg';
import { ReactComponent as AlarmsIcon } from './alarms.svg';
import { ReactComponent as BuildingIcon } from './building.svg';
import { ReactComponent as ChargingStationIcon } from './charging-station.svg';
import { ReactComponent as CheckIcon } from './check.svg';
import { ReactComponent as CliponBatteryIcon } from './clipon-battery.svg';
import { ReactComponent as CliponMessageIcon } from './clipon-message.svg';
import { ReactComponent as CliponPairedIcon } from './clipon-paired.svg';
import { ReactComponent as CliponStateIcon } from './clipon-state.svg';
import { ReactComponent as CliponIcon } from './clipon.svg';
import { ReactComponent as CloseCrossIcon } from './close-cross.svg';
import { ReactComponent as CloseIcon } from './close.svg';
import { ReactComponent as CommonRoomIcon } from './commonRoom.svg';
import { ReactComponent as ConnectedDiaperIcon } from './connected-diaper.svg';
import { ReactComponent as DeleteIcon } from './delete.svg';
import { ReactComponent as DepartmentIcon } from './department.svg';
import { ReactComponent as DiaperImageIcon } from './diaper-image.svg';
import { ReactComponent as DiaperIcon } from './diaper.svg';
import { ReactComponent as DropsIcon } from './drops.svg';
import { ReactComponent as EditIcon } from './edit.svg';
import { ReactComponent as EmptyBedIcon } from './emptyBed.svg';
import { ReactComponent as FloorIcon } from './floor.svg';
import { ReactComponent as GatewayIcon } from './gateway.svg';
import { ReactComponent as GroupIcon } from './group.svg';
import { ReactComponent as HomeIcon } from './home.svg';
import { ReactComponent as InfoIcon } from './info.svg';
import { ReactComponent as ListIcon } from './list.svg';
import { ReactComponent as MobileNotConnectedIcon } from './mobile-not-connected.svg';
import { ReactComponent as MobileIcon } from './mobile.svg';
import { ReactComponent as OccupiedBedIcon } from './occupiedBed.svg';
import { ReactComponent as ResidentIcon } from './residents.svg';
import { ReactComponent as ResidentsIcon } from './residents.svg';
import { ReactComponent as RoomIcon } from './room.svg';
import { ReactComponent as ShippingRequestOutlineIcon } from './shipping-request-outline.svg';
import { ReactComponent as ShippingRequestIcon } from './shipping-request.svg';
import { ReactComponent as SparePartOutlineIcon } from './spare-part-outline.svg';
import { ReactComponent as SparePartIcon } from './spare-part.svg';
import { ReactComponent as SubDemoOutlineIcon } from './sub-demo-outline.svg';
import { ReactComponent as SubDemoIcon } from './sub-demo.svg';
import { ReactComponent as SubStandardOutlineIcon } from './sub-standard-outline.svg';
import { ReactComponent as SubStandardIcon } from './sub-standard.svg';
import { ReactComponent as SubscriptionIcon } from './subscription.svg';
import { ReactComponent as UsersIcon } from './users.svg';
import { ReactComponent as WarningIcon } from './warning.svg';
import { ReactComponent as WebNotConnectedIcon } from './web-not-connected.svg';
import { ReactComponent as WebIcon } from './web.svg';

export const SparePart = styled(SparePartIcon)`
  width: 42px;
  height: 42px;
`;

export const SparePartOutline = styled(SparePartOutlineIcon)`
  width: 42px;
  height: 42px;
`;

export const ShippingRequest = styled(ShippingRequestIcon)`
  width: 42px;
  height: 42px;
`;

export const ShippingRequestOutline = styled(ShippingRequestOutlineIcon)`
  width: 42px;
  height: 42px;
`;

export const Edit = styled(EditIcon)`
  width: 42px;
  height: 42px;
`;

export const Delete = styled(DeleteIcon)`
  width: 42px;
  height: 42px;
`;

export const List = styled(ListIcon)`
  width: 42px;
  height: 42px;
`;

export const Close = styled(CloseIcon)`
  width: 42px;
  height: 42px;
`;

export const Check = styled(CheckIcon)`
  width: 42px;
  height: 42px;
`;

export const Add = styled(AddIcon)`
  width: 42px;
  height: 42px;
`;

export const Address = styled(AddressIcon)`
  width: 42px;
  height: 42px;
`;
export const Address63px = styled(AddressIcon)`
  width: 63px;
  height: 63px;
`;

export const Alarms = styled(AlarmsIcon)`
  width: 42px;
  height: 42px;
`;

export const Building = styled(BuildingIcon)`
  width: 42px;
  height: 42px;
`;

export const Building63px = styled(BuildingIcon)`
  width: 63px;
  height: 63px;
`;

export const CliponBattery = styled(CliponBatteryIcon)`
  width: 42px;
  height: 42px;
`;

export const CliponMessage = styled(CliponMessageIcon)`
  width: 42px;
  height: 42px;
`;

export const CliponPaired = styled(CliponPairedIcon)`
  width: 42px;
  height: 42px;
`;

export const CliponState = styled(CliponStateIcon)`
  width: 42px;
  height: 42px;
`;

export const Clipon = styled(CliponIcon)`
  width: 42px;
  height: 42px;
`;

export const ChargingStation = styled(ChargingStationIcon)`
  width: 42px;
  height: 42px;
`;

export const Gateway = styled(GatewayIcon)`
  width: 42px;
  height: 42px;
`;

export const Resident = styled(ResidentIcon)`
  width: 42px;
  height: 42px;
`;

export const CommonRoom = styled(CommonRoomIcon)`
  width: 42px;
  height: 42px;
`;

export const ConnectedDiaper = styled(ConnectedDiaperIcon)`
  width: 42px;
  height: 42px;
`;

export const Department = styled(DepartmentIcon)`
  width: 42px;
  height: 42px;
`;

export const DiaperImage = styled(DiaperImageIcon)`
  width: 42px;
  height: 42px;
`;

export const Diaper = styled(DiaperIcon)`
  width: 42px;
  height: 42px;
`;

export const Drops = styled(DropsIcon)`
  width: 42px;
  height: 42px;
`;

export const EmptyBed = styled(EmptyBedIcon)`
  width: 42px;
  height: 42px;
`;

export const Floor = styled(FloorIcon)`
  width: 42px;
  height: 42px;
`;

export const SubDemo = styled(SubDemoIcon)`
  width: 42px;
  height: 42px;
`;

export const SubDemoOutline = styled(SubDemoOutlineIcon)`
  width: 42px;
  height: 42px;
`;

export const SubStandard = styled(SubStandardIcon)`
  width: 42px;
  height: 42px;
`;

export const SubStandardOutline = styled(SubStandardOutlineIcon)`
  width: 42px;
  height: 42px;
`;

export const Group63px = styled(GroupIcon)`
  width: 63px;
  height: 63px;
`;

export const Home = styled(HomeIcon)`
  width: 42px;
  height: 42px;
`;

export const Info = styled(InfoIcon)`
  width: 42px;
  height: 42px;
`;

export const MobileNotConnected = styled(MobileNotConnectedIcon)`
  width: 42px;
  height: 42px;
`;

export const Mobile = styled(MobileIcon)`
  width: 42px;
  height: 42px;
`;

export const OccupiedBed = styled(OccupiedBedIcon)`
  width: 42px;
  height: 42px;
`;

export const Residents = styled(ResidentsIcon)`
  width: 42px;
  height: 42px;
`;

export const Room = styled(RoomIcon)`
  width: 42px;
  height: 42px;
`;

export const Subscription = styled(SubscriptionIcon)`
  width: 42px;
  height: 42px;
`;

export const Users = styled(UsersIcon)`
  width: 42px;
  height: 42px;
`;

export const Warning = styled(WarningIcon)`
  width: 42px;
  height: 42px;
`;

export const WebNotConnected = styled(WebNotConnectedIcon)`
  width: 42px;
  height: 42px;
`;

export const Web = styled(WebIcon)`
  width: 42px;
  height: 42px;
`;

export const CloseCross = styled(CloseCrossIcon)`
  width: 24px;
  height: 24px;
`;
