import { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { NursingHomeResponse } from 'src/sdk/nursingHome';
import styled from 'styled-components/macro';
import { format, parse } from 'date-fns';
import UpdateMainInformationsForm from './NursingHomeConfigurationInformations';
import { NursingHomeConfigurationInformationsSchema } from './NursingHomeConfigurationInformations';
import UpdatePasswordForm from './UpdatePasswordForm';
import BillingTimeForm, {
  BillingTimeFormSchema,
  defaultNighttimeEnd,
  defaultNighttimeStart,
} from './BillingTimeForm';
import useFetchBillingModesQuery from '../hooks/useFetchBillingModesQuery';
import { ScheduleConfigurationParametersRequest } from 'src/sdk/schedule';
import useToast from 'src/hooks/useToast';
import useFormatErrorApi from 'src/hooks/useFormatErrorApi';
import { useTranslation } from 'react-i18next';
import useScheduleConfigurations from '../hooks/useProtocolConfiguration';
import useFetchProtocolConfigurationsEffectiveQuery from '../hooks/useFetchProtocolConfigurationsEffectiveQuery';



type GeneralSettingsProps = {
  id: number;
  nursingHome: NursingHomeResponse;
  isError?: boolean;
};

const StyledRow = styled(Row)`
  padding: 0 1rem;
`;

const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const GeneralSettings = ({ id, nursingHome }: GeneralSettingsProps) => {

  const { t } = useTranslation();
  const { scheduleConfiguration } = useScheduleConfigurations(nursingHome.id);
  const { toastError, toastSuccess, toastErrorClient } = useToast();
  const { formatErrorApi } = useFormatErrorApi();
  const today = new Date();

  
  const defaultValues: NursingHomeConfigurationInformationsSchema = useMemo(
    () => ({
      language: nursingHome.language,
      login: nursingHome.nursingHomeUser?.username || '',
      timeZone: nursingHome.timeZone,
      name: nursingHome.name,
      dayStartTime: nursingHome.dayStartTime,
      nightStartTime: nursingHome.nightStartTime,
      tolerance: nursingHome.tolerance,
    }),
    [
      nursingHome.language,
      nursingHome.name,
      nursingHome.nursingHomeUser?.username,
      nursingHome.timeZone,
      nursingHome.dayStartTime,
      nursingHome.nightStartTime,
      nursingHome.tolerance,
    ],
  );

  const { data: billingModesData } = useFetchBillingModesQuery();

  
  const { data: currentBillingConfigurationData } =
     useFetchProtocolConfigurationsEffectiveQuery({
    'nursing-home-id': nursingHome.id,
      year: today.getFullYear(),
      month: today.getMonth() + 1,
  });
    

  const defaultBillingValues: any =
  useMemo(() => {    
    const mode =
      currentBillingConfigurationData?.mode ||
      (billingModesData && billingModesData[0]) ||
      'NIGHTS_AND_DAYS';

    const nighttimeEnd = defaultValues ? parse(defaultValues?.dayStartTime!, 'HH:mm:ss', new Date()) : defaultNighttimeEnd;
    const nighttimeStart = defaultValues ? parse(defaultValues?.nightStartTime!, 'HH:mm:ss', new Date()) : defaultNighttimeStart;
    
    return {
      mode,
      nighttimeEnd,
      nighttimeStart,
      tolerance: defaultValues?.tolerance || 30,
    };
  }, [
    defaultValues?.dayStartTime,
    defaultValues?.nightStartTime,
    defaultValues?.tolerance
  ]);

  const onSubmit = async (values: BillingTimeFormSchema) => {    
    const body: ScheduleConfigurationParametersRequest = {
      nightStartTime: format(values?.nighttimeStart!, 'HH:mm:ss'),
      dayStartTime: format(values?.nighttimeEnd!, 'HH:mm:ss'),
      tolerance: values?.tolerance!,
    };

  try {
    if (currentBillingConfigurationData?.doNotBill){
      toastErrorClient(t('nursingHome.configuration.billings.failed'));
    }else{
      await scheduleConfiguration.mutateAsync(body);
      toastSuccess(t('nursingHome.configuration.billings.success'));
    }
  } catch (err) {
    const message = formatErrorApi(err);
    toastError(message);
  }
};

  return (
    <StyledRow className="p-4">
      <Row>
        <Col xs={12} md={6}>
          <StyledCol>
            <UpdateMainInformationsForm id={id} defaultValues={defaultValues} />
          </StyledCol>
        </Col>
        <Col xs={12} md={6}>
          <StyledCol>
            <UpdatePasswordForm id={id} />
          </StyledCol>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <StyledCol className="mt-4">
            <BillingTimeForm defaultValues={defaultBillingValues} onSubmit={onSubmit} />
          </StyledCol>
        </Col>
      </Row>
    </StyledRow>
  );
};

export default GeneralSettings;
