import { AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import request from 'src/axios';
import { UserResponse, CreateUserRequest } from 'src/sdk/users';

const useCreateUserMutation = () => {
  const queryClient = useQueryClient();

  const createUserMutation = useMutation<
    AxiosResponse<UserResponse>,
    Error,
    CreateUserRequest
  >(
    (body) => {
      return request.post('/users/create-manager-private', body);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
      },
    },
  );

  return { createUserMutation };
};

export default useCreateUserMutation;
