import { AxiosResponse } from 'axios';
import { format } from 'date-fns';
import { useCallback, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { ReactComponent as Order } from 'src/assets/order-badge.svg';
import request from 'src/axios';
import DefaultModal from 'src/components/Misc/Modal/DefaultModal';
import ModalCreateOrderForm, {
  VendorFormSchema,
  VendorOption,
} from 'src/components/Ordering/List/ModalCreateOrder/ModalCreateOrderForm';
import { DATE_BACKEND } from 'src/constants';
import useFormatErrorApi from 'src/hooks/useFormatErrorApi';
import { EquipmentTypes } from 'src/sdk/equipment';
import {
  CreateMaterialOrder,
  CreatePurchaseOrder,
  VendorCode,
} from 'src/sdk/order';
import { H1 } from 'src/uikit/Typography';
import styled from 'styled-components/macro';

const OrderBadge = styled(Order)`
  height: 40px;
  width: 40px;
`;

const ModalHeader = styled(Row)`
  text-align: center;
  width: 100%;
`;

const TitleCol = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalTitle = styled(H1)`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: ${({ theme }) => theme.palette.black[90]};
`;

const ModalBody = styled.div`
  text-align: left;
  margin: 1rem;
`;

interface Props {
  show: boolean;
  onClose: () => void;
  onFinish: () => void;
}

const ModalCreateOrder = ({ show, onClose, onFinish }: Props) => {
  const { t } = useTranslation();
  const { formatErrorApi } = useFormatErrorApi();

  const [error, setError] = useState('');

  const defaultValues = {
    poNumber: '',
    vendor: '',
    equipmentsType: [],
    charging: {},
    clipon: {},
    gateway: {},
  };

  const { data: vendors } = useQuery<[VendorOption], Error>(
    'vendor-code',
    () => {
      return request.get('/vendor-codes').then((res) =>
        res.data.map((e: VendorCode) => {
          return { label: e.name, value: e.code };
        }),
      );
    },
  );

  const { mutateAsync } = useMutation<
    AxiosResponse<any>,
    Error,
    CreatePurchaseOrder
  >((params) => request.post('/purchase-orders', params));

  const onSubmit = async (values: VendorFormSchema) => {
    const params = {
      poNumber: values.poNumber,
      vendorCode: values.vendor,
      date: format(Date.now(), DATE_BACKEND),
      items: new Array<CreateMaterialOrder>(),
    };
    if (values.gateway && (values.gateway.gatewayQuantity || 0) > 0) {
      params.items.push({
        materialNumber:
          values.equipmentsType.find(
            (e) => e.equipmentType === EquipmentTypes.GATEWAY,
          )?.code || '',
        description: values.gateway.gatewayDescription || '',
        quantity: values.gateway.gatewayQuantity || 0,
        plannedDeliveryDate: format(
          new Date(values.gateway.gatewayDate || ''),
          DATE_BACKEND,
        ),
      });
    }
    if (values.clipon && (values.clipon.cliponQuantity || 0) > 0) {
      params.items.push({
        materialNumber:
          values.equipmentsType.find(
            (e) => e.equipmentType === EquipmentTypes.CLIPON,
          )?.code || '',
        description: values.clipon.cliponDescription || '',
        quantity: values.clipon.cliponQuantity || 0,
        plannedDeliveryDate: format(
          new Date(values.clipon.cliponDate || ''),
          DATE_BACKEND,
        ),
      });
    }
    if (values.charging && (values.charging.chargingQuantity || 0) > 0) {
      params.items.push({
        materialNumber:
          values.equipmentsType.find(
            (e) => e.equipmentType === EquipmentTypes.CHARGING,
          )?.code || '',
        description: values.charging.chargingDescription || '',
        quantity: values.charging.chargingQuantity || 0,
        plannedDeliveryDate: format(
          new Date(values.charging.chargingDate || ''),
          DATE_BACKEND,
        ),
      });
    }
    try {
      await mutateAsync(params);
      onFinish?.();
    } catch (err) {
      const message = formatErrorApi(err);
      setError(message);
    }
  };

  const handleClose = useCallback(() => {
    onFinish?.();
  }, [onFinish]);

  return (
    <DefaultModal
      size="lg"
      isVisible={show}
      handleClose={handleClose}
      hasStep={false}
      header={
        <ModalHeader>
          <Col md="1">
            <OrderBadge />
          </Col>
          <TitleCol md="10">
            <ModalTitle>{t('components.modalCreate.order.title')}</ModalTitle>
          </TitleCol>
        </ModalHeader>
      }
    >
      <ModalBody>
        <ModalCreateOrderForm
          defaultValues={defaultValues}
          onSubmit={onSubmit}
          vendors={vendors || []}
          error={error}
        />
      </ModalBody>
    </DefaultModal>
  );
};

export default ModalCreateOrder;
