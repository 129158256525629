import { ReactNode } from 'react';
import { Check, Close } from 'src/assets/list-icons';
import { Card, CardBody } from 'src/uikit/Card';
import styled, { css } from 'styled-components/macro';

const StyledWrapperIcon = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
`;

const StyledCard = styled(Card)<{ checked?: boolean; disabled?: boolean }>`
  min-height: 100px;

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      background: ${({ theme }) => theme.palette.black.lightGrey};
      filter: grayscale(100%);
    `}

  ${({ checked }) =>
    checked &&
    css`
      transform: scale(1.01);
      box-shadow: ${({ theme }) => theme.shadows.md};
    `}
`;

export type CheckCardProps = {
  onClick: (value: boolean) => void;
  checked: boolean;
  type: 'add' | 'delete';
  children: ReactNode;
  disabled?: boolean;
};

const CheckCard = ({
  children,
  type,
  onClick,
  checked,
  disabled,
  ...rest
}: CheckCardProps) => {
  return (
    <StyledCard
      onClick={onClick}
      checked={checked}
      aria-disabled={disabled}
      disabled={disabled}
      $clickable
      {...rest}
    >
      <CardBody>
        {children}
        {checked && (
          <StyledWrapperIcon>
            {type === 'add' && <Check />}
            {type === 'delete' && <Close />}
          </StyledWrapperIcon>
        )}
      </CardBody>
    </StyledCard>
  );
};

export default CheckCard;
