import { differenceInMonths, differenceInYears } from 'date-fns';
import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import request from 'src/axios';
import {
  GetSubscriptionCreationData,
  SubscriptionCreationDataElementAddressRequest,
  SubscriptionCreationRequest,
} from 'src/sdk/subscription';

const useFetchSubscriptionQuery = (id: number) => {
  const fetchSubscription = useCallback((id: number) => {
    return request
      .get<GetSubscriptionCreationData>(
        `/subscriptions/creation-data?nursing-home-id=${id}`,
      )
      .then((response) => response.data);
  }, []);

  const { data: creationData, isLoading } = useQuery<
    GetSubscriptionCreationData,
    Error
  >('subscription-creation-data', () => fetchSubscription(id), {
    enabled: id > 0,
  });

  const selectedData: SubscriptionCreationRequest | null = useMemo(() => {
    if (
      !!creationData &&
      !!creationData?.mainSubscription &&
      !creationData?.secondarySubscription
    ) {
      // if there is only a main subscription
      return {
        type: creationData.mainSubscription.type || 'STANDARD',
        startDate: creationData.mainSubscription.startDate || '',
        duration:
          creationData.secondarySubscription?.type === 'DEMO'
            ? differenceInMonths(
                new Date(creationData.mainSubscription.endDate || 0),
                new Date(creationData.mainSubscription.startDate || 0),
              )
            : differenceInYears(
                new Date(creationData.mainSubscription.endDate || 0),
                new Date(creationData.mainSubscription.startDate || 0),
              ),
        automaticRenewal:
          creationData.mainSubscription.automaticRenewal || false,
        residentEstimate: creationData.mainSubscription.residentEstimate || 20,
        deliveryAddressId: creationData.mainSubscription.deliveryAddressId,
        customAddress: (creationData.mainSubscription.address ||
          {}) as SubscriptionCreationDataElementAddressRequest,
        requestedDeliveryDate:
          creationData.mainSubscription.requestedDeliveryDate || '',
        extraChargingStations:
          creationData.mainSubscription.extraChargingStations,
        extraGateways: creationData.mainSubscription.extraGateways,
        nursingHomeId: id,
        noShippingRequest: !creationData.mainSubscription.hasShippingRequest,
      };
    } else if (!!creationData?.secondarySubscription) {
      // if there is a secondary subscription
      return {
        type: creationData.secondarySubscription.type || 'STANDARD',
        startDate: creationData.secondarySubscription.startDate || '',
        duration:
          creationData.secondarySubscription.type === 'DEMO'
            ? differenceInMonths(
                new Date(creationData.secondarySubscription.endDate || 0),
                new Date(creationData.secondarySubscription.startDate || 0),
              )
            : differenceInYears(
                new Date(creationData.secondarySubscription.endDate || 0),
                new Date(creationData.secondarySubscription.startDate || 0),
              ),
        automaticRenewal:
          creationData.secondarySubscription.automaticRenewal || false,
        residentEstimate:
          creationData.secondarySubscription.residentEstimate || 20,
        deliveryAddressId:
          creationData.secondarySubscription.deliveryAddressId ||
          creationData.mainSubscription?.deliveryAddressId,
        customAddress: (creationData.secondarySubscription.address ||
          creationData.mainSubscription?.address ||
          {}) as SubscriptionCreationDataElementAddressRequest,
        requestedDeliveryDate:
          creationData.secondarySubscription.requestedDeliveryDate || '',
        extraChargingStations:
          creationData.secondarySubscription.extraChargingStations,
        extraGateways: creationData.secondarySubscription.extraGateways,
        nursingHomeId: id,
        noShippingRequest:
          !creationData.secondarySubscription.hasShippingRequest,
      };
    } else {
      return null;
    }
  }, [creationData, id]);

  const currentEquipments = useMemo(() => {
    return creationData?.mainSubscription
      ? {
          clipons: creationData?.mainSubscription.cliponEstimate || 0,
          gateways: creationData?.mainSubscription.gatewayEstimate || 0,
          chargingStations:
            creationData?.mainSubscription.chargingStationEstimate || 0,
        }
      : undefined;
  }, [creationData]);

  return { creationData, selectedData, currentEquipments, isLoading };
};

export default useFetchSubscriptionQuery;
