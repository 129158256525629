import EquipmentList from 'src/components/Equipment/EquipmentList';
import { PageWrapper } from 'src/uikit/Wrapper';

const ProvisioningChargingStation = () => {
  return (
    <PageWrapper>
      <EquipmentList
        type="charging"
        defaultSortField={{
          label: 'serialNumber',
          direction: 'asc',
        }}
        isGlobal
      />
    </PageWrapper>
  );
};

export default ProvisioningChargingStation;
