import { useCallback, useState } from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';
import { ReactComponent as PlusBadge } from 'src/assets/plus-badge.svg';
import Pagination from 'src/components/Misc/Pagination';
import SubscriptionHistoryList from 'src/components/Subscription/History/SubscriptionHistoryList';
import useFetchSubscriptionsQuery from 'src/components/Subscription/hooks/useFetchSubscriptionsQuery';
import useSubscriptionStates from 'src/components/Subscription/hooks/useSubscriptionStates';
import { useAuth } from 'src/contexts/Auth';
import Routes from 'src/router/Routes';
import ButtonIcon from 'src/uikit/ButtonIcon';
import { H1 } from 'src/uikit/Typography';
import styled from 'styled-components/macro';

const PAGE_SIZE = 50;

const Wrapper = styled(Container)`
  display: grid;
  gap: 2rem;
  padding: 40px;
  overflow-x: auto;
  text-align: center;
`;

const Title = styled(H1)`
  line-height: 30px;
  color: ${({ theme }) => theme.palette.black[90]};
`;

const ButtonColumn = styled(Col)`
  display: flex;
  justify-content: flex-end;
`;

const SubscriptionHistory = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { nhSession } = useAuth();
  const [page = 0, setPage] = useState<number>();
  const {
    subscriptions: data,
    isLoading,
    refetch,
  } = useFetchSubscriptionsQuery(nhSession?.id || -1, page, PAGE_SIZE);

  const handleGoToCreate = useCallback(() => {
    if (nhSession?.id) {
      history.push(
        generatePath(Routes.NURSING_SUBSCRIPTION_CREATION, {
          id: nhSession.id,
        }),
      );
    }
  }, [history, nhSession?.id]);

  const { canCreate } = useSubscriptionStates(data?.content || []);

  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center">
          <Spinner animation="border" variant="secondary" />
        </div>
      ) : (
        <Wrapper fluid>
          <Title>{t('pages.subscription.history.title')}</Title>
          {canCreate && (
            <Row className="justify-content-end">
              <ButtonColumn>
                <ButtonIcon
                  prepend={<PlusBadge />}
                  handleClick={handleGoToCreate}
                  label={t('pages.subscription.history.addButton')}
                />
              </ButtonColumn>
            </Row>
          )}
          <SubscriptionHistoryList
            data={data}
            isLoading={isLoading}
            refetch={refetch}
          />
          {data && data.totalPages > 1 && (
            <Pagination
              totalElements={data.totalElements}
              perPage={PAGE_SIZE}
              onPageChange={setPage}
              currentPage={page}
            />
          )}
        </Wrapper>
      )}
    </>
  );
};

export default SubscriptionHistory;
