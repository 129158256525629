import { ChangeEventHandler, MutableRefObject } from 'react';
import styled from 'styled-components/macro';

const StyledFileUpload = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`;
interface Props {
  name: string;
  inputRef: MutableRefObject<HTMLInputElement | null>;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

const FileUploader = ({ name, inputRef, onChange }: Props) => {
  return (
    <StyledFileUpload
      type="file"
      name={name}
      ref={inputRef}
      onChange={onChange}
    />
  );
};

export default FileUploader;
