import { useLocation } from 'react-router-dom';
import { A } from 'src/uikit/Typography';
import styled, { css } from 'styled-components/macro';

export const HorizontalMenuWrapper = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  grid-auto-rows: 1fr;
  background-color: ${({ theme }) => theme.palette.black.strongLightGrey};
  box-shadow: ${({ theme }) => theme.shadows.xs};
  margin: 0;
  margin-top: -1rem;
  padding: 0;
`;

const HorizontalMenuItem = styled.li`
  font-weight: bold;
  margin: 0;
  color: ${({ theme }) => theme.palette.black.main};
  width: 100%;
  display: block;
  :hover,
  :focus {
    text-decoration: none;
    color: ${({ theme }) => theme.palette.main.primary};
  }
`;

interface TabProps {
  $isActive: boolean;
}

const Label = styled(A)<TabProps>`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
  text-align: center;
  text-decoration: none;
  font-size: 0.9rem;
  color: ${({ theme }) => theme.palette.black.main};

  transition: border 50ms ease-in;

  :hover {
    color: ${({ theme }) => theme.palette.main.primary};
    text-decoration: none;
  }

  :hover,
  :focus {
    border-bottom: 3px solid ${({ theme }) => theme.palette.main.primary};
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      border-bottom: 3px solid ${({ theme }) => theme.palette.main.primary};
      text-decoration: none;
      font-weigt: 600;
      color: ${({ theme }) => theme.palette.main.primary};
    `}
`;

const NbElement = styled('span')<{ active: boolean }>`
  border-radius: 5px;
  position: relative;
  margin-left: 15px;
  text-align: right;
  padding: 4px 7px;

  ${(props) =>
    props.active
      ? css`
          color: ${({ theme }) => theme.palette.main.primary};
          background: ${({ theme }) => theme.palette.main.lightBlue};
        `
      : css`
          color: ${({ theme }) => theme.palette.black[90]};
          background: ${({ theme }) => theme.palette.black.lightGrey};
        `}
`;

export interface HorizontalMenuItems {
  routes: string;
  label: string;
  totalCount?: number;
  disabled?: boolean;
}

interface Props {
  menuItems?: HorizontalMenuItems[];
}

const HorizontalMenu = ({ menuItems }: Props) => {
  const location = useLocation();

  return (
    <HorizontalMenuWrapper>
      {menuItems?.map((item, id) => (
        <HorizontalMenuItem key={id}>
          {!item?.disabled && (
            <Label
              $isActive={location.pathname === item.routes}
              to={item.routes}
            >
              {item.label}
              {item.totalCount !== undefined && (
                <NbElement active={location.pathname === item.routes}>
                  {item.totalCount}
                </NbElement>
              )}
            </Label>
          )}
        </HorizontalMenuItem>
      ))}
    </HorizontalMenuWrapper>
  );
};

export default HorizontalMenu;
