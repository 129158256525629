import { yupResolver } from '@hookform/resolvers/yup';
import { useState, useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { FormProvider, useWatch, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import useCheckPartnerMutation from 'src/components/Partners/hooks/useCheckPartnerMutation';
import useFormatErrorApi from 'src/hooks/useFormatErrorApi';
import { Button } from 'src/uikit/Button';
import { FormLabel } from 'src/uikit/Form';
import FormSelectInput from 'src/uikit/Form/SelectInput';
import FormTextInput from 'src/uikit/Form/TextInput';
import styled from 'styled-components/macro';
import * as yup from 'yup';

const FormGroup = styled(Form.Group)`
    margin-bottom: 0;
    text-align: left;
`;

const ErrorMessage = styled.div`
    color: ${({ theme }) => theme.palette.main.red};
    text-align: left;
`;

const FormFooter = styled(Row)`
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 1rem;

    ${ErrorMessage} {
        margin-bottom: 0;
    }

    ${Button} {
        font-size: 14px;
    }
`;

export const partnerCheckFormSchema = yup.object({
  soldTo: yup.string().required(),
  salesOrg: yup.string(),
});

export type PartnerCheckFormSchema = yup.InferType<
  typeof partnerCheckFormSchema
>;

interface Props {
  callback?: (partner: any) => void;
  onCancel: () => void;
}

const PartnerCheckForm = ({ callback, onCancel }: Props) => {
  const { t } = useTranslation();
  const [error, setError] = useState('');
  const [show, setShow] = useState<boolean>(true);
  const [partner, setPartner] = useState<any>();
  const [salesOrganisations, setSalesOrganisations] = useState<any[]>([]);
  const { formatErrorApi } = useFormatErrorApi();
  const { checkPartnerMutation } = useCheckPartnerMutation();


  const methods = useForm<PartnerCheckFormSchema>({
    resolver: yupResolver(partnerCheckFormSchema),
    mode: 'onChange',
  });


  const { control } = methods;
  const soldTo = useWatch({ control, name: 'soldTo' });
  const salesOrg = useWatch({ control, name: 'salesOrg' });


  /**
   * Submit the form with the given soldTo and salesOrg values.
   *
   * @param {any} soldTo - The soldTo value to be submitted.
   * @param {any} salesOrg - The salesOrg value to be submitted.
   * @return {Promise<void>} A promise that resolves when the form submission is complete.
   */
  const onSubmit = async (soldTo: any, salesOrg: any) => {
    setError('');
    try {
      const { data } = await checkPartnerMutation.mutateAsync({
        soldTo,
        salesOrg,
      });
      if (!data) {
        toast.error(t('components.PartnerCreateModal.errorSAP'));
      }
      setPartner(data);
      if (data?.salesOrgs?.length) {
        setSalesOrganisations(
          (data?.salesOrgs as any)?.map((e: any) => ({
            value: e.salesOrg,
            label: e.description,
          })),
        );
      }
    } catch (err) {
      const message = formatErrorApi(err);
      setError(message);
    }
  };

  useEffect(() => {
    setShow(true);
    setSalesOrganisations([]);
    callback?.(null);
  }, [soldTo]);


  useEffect(() => {
    if (salesOrg) {
      onSubmit(soldTo, salesOrg);
    }
  }, [salesOrg]);


  useEffect(() => {
    if (soldTo && salesOrg && partner) {
      setShow(false);
      callback?.(partner);
    }
  }, [soldTo, salesOrg, partner, callback]);


  return (
    <FormProvider {...methods}>
      <Form>
        <FormGroup>
          <FormLabel>
            {t('components.PartnerCreateModal.PartnerCheckForm.subtitle')}
          </FormLabel>
          <FormTextInput
            name="soldTo"
            placeholder={t(
              'components.PartnerCreateModal.PartnerCheckForm.labels.soldTo',
            )}
          />
        </FormGroup>
        {Boolean(salesOrganisations?.length) && (
          <FormGroup>
            <FormSelectInput
              name="salesOrg"
              placeholder={t(
                'components.PartnerCreateModal.PartnerCheckForm.labels.salesOrg',
              )}
              options={salesOrganisations}
            />
          </FormGroup>
        )}
        {error && <ErrorMessage>{error}</ErrorMessage>}
        {show && (
          <FormFooter xs="auto">
            <Col>
              <Button
                onClick={() => {
                  onSubmit(soldTo, salesOrg);
                }}
                type="button"
              >
                {t('components.PartnerCreateModal.PartnerCheckForm.check')}
              </Button>
            </Col>
          </FormFooter>
        )}
      </Form>
    </FormProvider>
  );
};

export default PartnerCheckForm;
