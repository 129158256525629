import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CircleStatus from 'src/uikit/CircleStatus';
import styled from 'styled-components/macro';

import BackToLogin from '../BackToLogin';

const Wrapper = styled.div`
  width: auto;
`;

const ResetPasswordExpired = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Row className="flex-column align-items-center">
        <CircleStatus type="warning" />
        {t('components.Auth.ResetPasswordExpired.expired')}
      </Row>

      <BackToLogin />
    </Wrapper>
  );
};

export default ResetPasswordExpired;
