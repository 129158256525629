import { useQuery } from 'react-query';
import request from 'src/axios';
import { Partner, PartnerExpand } from 'src/sdk/partner';

export type PartnerPropsectSortField = {
  label: keyof Partner;
  direction: 'asc' | 'desc';
};

type QueryParams = {
  id: string;
  expand: PartnerExpand[];
};

const fetchPartner = async ({ id, expand }: QueryParams) => {
  const { data } = await request.get(`/partners/${id}`, {
    params: {
      expand: expand.join(','),
    },
  });

  return data;
};

const useFetchPartnerQuery = (id: string, expand: PartnerExpand[]) => {
  const { data, isLoading, refetch, error } = useQuery<Partner, Error>(
    [`partner${id}`, { id, expand }],
    () =>
      fetchPartner({
        id,
        expand,
      }),
  );

  return {
    data,
    isLoading,
    refetch,
    error,
  };
};

export default useFetchPartnerQuery;
