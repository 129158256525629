import { Dispatch, ReactNode, SetStateAction } from 'react';
import { Container, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Pagination, { PaginationType } from 'src/components/Misc/Pagination';
import { GetResponse } from 'src/sdk/common';
import { AlertCard } from 'src/uikit/AlertCard';
import styled from 'styled-components/macro';

const Wrapper = styled(Container)`
  padding-left: 0;
  padding-right: 0;
`;

const WrapperSpinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface Props<T> {
  data?: GetResponse<T>;
  header?: ReactNode;
  children: ReactNode;
  isLoading: boolean;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  type?: PaginationType;
  empty?: boolean;
}

const pageSize = 10;

const ListPaginated = <T extends unknown>({
  data,
  header,
  children,
  isLoading,
  page,
  setPage,
  type,
  empty,
}: Props<T>) => {
  const { t } = useTranslation();

  return (
    <Wrapper fluid>
      {header}
      {(empty || data?.empty) && (
        <AlertCard className="mt-4 p-3">
          {t('components.list.noResult')}
        </AlertCard>
      )}
      {isLoading && (
        <WrapperSpinner className="d-flex justify-content-center align-items-center">
          <Spinner animation="border" variant="secondary" />
        </WrapperSpinner>
      )}
      {children}
      {data && data.totalPages > 1 && (
        <Pagination
          totalElements={data.totalElements}
          totalPages={data.totalPages}
          perPage={pageSize}
          onPageChange={setPage}
          currentPage={page}
          type={type}
        />
      )}
    </Wrapper>
  );
};

export default ListPaginated;
