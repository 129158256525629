import { ArrowForward } from 'src/assets/arrows';
import styled from 'styled-components/macro';

const Wrapper = styled.div``;

const Text = styled.span<{ type: 'equals' | 'up' | 'down' }>`
  color: ${({ type, theme }) => {
    return type === 'equals'
      ? theme.palette.black[60]
      : type === 'down'
      ? theme.palette.main.red
      : theme.palette.main.primary;
  }};
  font-size: ${({ type, theme }) => type === 'equals' && '20px'};
`;

interface Props {
  newValue: number | string;
  oldValue: number | string;
}

const SubscriptionUpdatePlanDifference = ({ newValue, oldValue }: Props) => {
  return (
    <Wrapper>
      {newValue === oldValue ? (
        <Text type="equals">=</Text>
      ) : (
        <>
          {typeof newValue === 'number' && typeof oldValue === 'number' ? (
            <>
              {newValue > oldValue && (
                <Text type="up">+{newValue - oldValue}</Text>
              )}
              {newValue < oldValue && (
                <Text type="down">{newValue - oldValue}</Text>
              )}
            </>
          ) : (
            <Text type="up">
              <ArrowForward />
            </Text>
          )}
        </>
      )}
    </Wrapper>
  );
};

export default SubscriptionUpdatePlanDifference;
