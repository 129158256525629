import { yupResolver } from '@hookform/resolvers/yup';
import { format } from 'date-fns';
import { useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { ReactComponent as Charging } from 'src/assets/charging-stations.svg';
import { ReactComponent as Clipon } from 'src/assets/clipons.svg';
import { ReactComponent as Gateway } from 'src/assets/gateways.svg';
import request from 'src/axios';
import { DATE_BACKEND } from 'src/constants';
import { EquipmentTypes } from 'src/sdk/equipment';
import { MaterialNumber } from 'src/sdk/order';
import { Button } from 'src/uikit/Button';
import DateSelector from 'src/uikit/DateSelector';
import ErrorMessage from 'src/uikit/ErrorMessage';
import CounterInput from 'src/uikit/Form/CounterInput';
import FormSelectInput from 'src/uikit/Form/SelectInput';
import Textarea from 'src/uikit/Form/Textarea';
import FormTextInput from 'src/uikit/Form/TextInput';
import palette from 'src/uikit/theme/palette';
import { Text } from 'src/uikit/Typography';
import styled from 'styled-components/macro';
import * as yup from 'yup';

const CliponBadge = styled(Clipon)`
  height: 40px;
  width: 40px;
  color: ${({ theme }) => theme.palette.main.primary};
`;

const ChargingBadge = styled(Charging)`
  height: 40px;
  width: 40px;
  color: ${({ theme }) => theme.palette.main.orange};
`;

const GatewayBadge = styled(Gateway)`
  height: 40px;
  width: 40px;
  color: ${({ theme }) => theme.palette.main.purple};
`;

const StyledRow = styled(Row)`
  margin: 1rem 0;
`;

const FormFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  align-items: center;
`;

const getDeliveryDate = () => {
  let date = new Date(Date.now());
  date.setUTCMonth(date.getUTCMonth() + 1);
  return format(date, DATE_BACKEND);
};

const equipmentsTypeSchema: yup.SchemaOf<MaterialNumber> = yup.object({
  id: yup.number().required(),
  code: yup.string().required(),
  equipmentType: yup.mixed<EquipmentTypes>().required(),
});

const cliponSchema = yup.object({
  cliponQuantity: yup
    .number()
    .when(['gateway.gatewayQuantity', 'charging.chargingQuantity'], {
      is: (val: number) => !val || val === 0,
      then: yup.number().required(),
      otherwise: yup.number(),
    }),
  cliponDate: yup.string().when('cliponQuantity', {
    is: (val: number) => val > 0,
    then: yup.string().required(),
  }),
  cliponDescription: yup.string(),
});

const chargingSchema = yup.object({
  chargingQuantity: yup
    .number()
    .when(['clipon.cliponQuantity', 'gateway.gatewayQuantity'], {
      is: (val: number) => !val || val === 0,
      then: yup.number().required(),
      otherwise: yup.number(),
    }),
  chargingDate: yup.string().when('chargingQuantity', {
    is: (val: number) => val > 0,
    then: yup.string().required(),
  }),
  chargingDescription: yup.string(),
});

const gatewaySchema = yup.object({
  gatewayQuantity: yup
    .number()
    .when(['clipon.cliponQuantity', 'charging.chargingQuantity'], {
      is: (val: number) => !val || val === 0,
      then: yup.number().required(),
      otherwise: yup.number(),
    }),
  gatewayDate: yup.string().when('gatewayQuantity', {
    is: (val: number) => val > 0,
    then: yup.string().required(),
  }),
  gatewayDescription: yup.string(),
});

type EquipmentsTypeSchema = yup.InferType<typeof equipmentsTypeSchema>;

const vendorFormSchema = yup
  .object({
    poNumber: yup.string().required(),
    vendor: yup.string().required(),
    equipmentsType: yup
      .array()
      .when('vendor', {
        is: (val: string) => val,
        then: yup.array().of(equipmentsTypeSchema),
        otherwise: yup.array(),
      })
      .required(),
    clipon: yup.object().when('equipmentsType', {
      is: (val: EquipmentsTypeSchema[]) =>
        val &&
        val.findIndex(
          (equipment: EquipmentsTypeSchema) =>
            equipment.equipmentType === 'CLIPON',
        ) !== -1,
      then: cliponSchema,
    }),
    charging: yup.object().when('equipmentsType', {
      is: (val: EquipmentsTypeSchema[]) =>
        val &&
        val.findIndex(
          (equipment: EquipmentsTypeSchema) =>
            equipment.equipmentType === 'CHARGING_STATION',
        ) !== -1,
      then: chargingSchema,
    }),
    gateway: yup.object().when('equipmentsType', {
      is: (val: EquipmentsTypeSchema[]) =>
        val &&
        val.findIndex(
          (equipment: EquipmentsTypeSchema) =>
            equipment.equipmentType === 'GATEWAY',
        ) !== -1,
      then: gatewaySchema.test(
        'gateway-group',
        'Either gatewayQuantity or gatewayDate is required',
        (value) => {
          const { gatewayQuantity, gatewayDate } = value || {};
          return !!(gatewayQuantity || gatewayDate);
        },
      ),
    }),
  })
  .test('group', 'Missing equipments', (value) => {
    return (
      (value.clipon && value.clipon.cliponQuantity > 0) ||
      (value.charging && value.charging.chargingQuantity > 0) ||
      (value.gateway && value.gateway.gatewayQuantity > 0)
    );
  });

export type VendorOption = { label: string; value: string };
export type VendorFormSchema = yup.InferType<typeof vendorFormSchema>;

interface Props {
  defaultValues: VendorFormSchema;
  onSubmit: (values: VendorFormSchema) => void;
  vendors: VendorOption[];
  error?: string;
}

const ModalCreateOrderForm = ({
  defaultValues,
  onSubmit,
  vendors,
  error,
}: Props) => {
  const methods = useForm<VendorFormSchema>({
    resolver: yupResolver(vendorFormSchema),
    mode: 'onChange',
    defaultValues,
    shouldUnregister: false,
  });

  const { control, handleSubmit, watch, setValue, formState } = methods;
  const vendor = watch('vendor');
  const equipmentsType = watch('equipmentsType');

  useEffect(() => {
    if (vendor) {
      request
        .get(`/material-numbers?vendor-code=${vendor}`)
        .then((response) => {
          setValue('equipmentsType', response.data);
        });
    } else {
      setValue('equipmentsType', []);
    }
  }, [setValue, vendor]);

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <StyledRow>
          <Col className="pr-4">
            <FormTextInput
              width="100%"
              name="poNumber"
              placeholder="PO number"
            />
          </Col>
          <Col>
            <FormSelectInput
              name="vendor"
              placeholder="Vendor"
              options={vendors}
            />
          </Col>
        </StyledRow>
        {equipmentsType.findIndex(
          (e) => e.equipmentType === EquipmentTypes.CLIPON,
        ) !== -1 && (
          <>
            <StyledRow>
              <Col md="1">
                <CliponBadge />
              </Col>
              <Col md="4">
                <Text>Clip-on</Text>
              </Col>
              <Col>
                <CounterInput name="clipon.cliponQuantity" step={10} min={0} />
              </Col>
              <Col>
                <Controller
                  control={control}
                  name="clipon.cliponDate"
                  render={(field) => (
                    <DateSelector
                      minDate={new Date(getDeliveryDate())}
                      value={field.value}
                      onChange={(value) => field.onChange(value)}
                      placeholder="Delivery date"
                    />
                  )}
                />
              </Col>
            </StyledRow>
            <StyledRow>
              <Textarea
                rows={3}
                name="clipon.cliponDescription"
                placeholder="Description ..."
              />
            </StyledRow>
          </>
        )}
        {equipmentsType.findIndex(
          (e) => e.equipmentType === EquipmentTypes.CHARGING,
        ) !== -1 && (
          <>
            <StyledRow>
              <Col md="1">
                <ChargingBadge />
              </Col>
              <Col md="4">
                <Text>Charging stations</Text>
              </Col>
              <Col>
                <CounterInput
                  name="charging.chargingQuantity"
                  step={1}
                  min={0}
                  mainColor={palette.main.yellow}
                  secondaryColor={palette.main.lightYellow}
                />
              </Col>
              <Col>
                <Controller
                  control={control}
                  name="charging.chargingDate"
                  render={(field) => (
                    <DateSelector
                      minDate={new Date(getDeliveryDate())}
                      value={field.value}
                      onChange={(value) => field.onChange(value)}
                      placeholder="Delivery date"
                    />
                  )}
                />
              </Col>
            </StyledRow>
            <StyledRow>
              <Textarea
                rows={3}
                name="charging.chargingDescription"
                placeholder="Description ..."
              />
            </StyledRow>
          </>
        )}
        {equipmentsType.findIndex(
          (e) => e.equipmentType === EquipmentTypes.GATEWAY,
        ) !== -1 && (
          <>
            <StyledRow>
              <Col md="1">
                <GatewayBadge />
              </Col>
              <Col md="4">
                <Text>Gateways</Text>
              </Col>
              <Col>
                <CounterInput
                  name="gateway.gatewayQuantity"
                  step={1}
                  min={0}
                  mainColor={palette.main.purple}
                  secondaryColor={palette.main.lightPurple}
                />
              </Col>
              <Col>
                <Controller
                  control={control}
                  name="gateway.gatewayDate"
                  render={(field) => (
                    <DateSelector
                      minDate={new Date(getDeliveryDate())}
                      value={field.value}
                      onChange={(value) => field.onChange(value)}
                      placeholder="Delivery date"
                    />
                  )}
                />
              </Col>
            </StyledRow>
            <StyledRow>
              <Textarea
                rows={3}
                name="gateway.gatewayDescription"
                placeholder="Description ..."
              />
            </StyledRow>
          </>
        )}
        <FormFooter>
          <div>{error && <ErrorMessage> {error} </ErrorMessage>}</div>
          <Button type="submit" disabled={!formState.isValid}>
            Finish
          </Button>
        </FormFooter>
      </Form>
    </FormProvider>
  );
};

export default ModalCreateOrderForm;
