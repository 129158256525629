import { useCallback } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useStartVerificationEmailMutation from 'src/components/Users/hooks/useStartVerificationEmailMutation';
import useFormatErrorApi from 'src/hooks/useFormatErrorApi';
import useToast from 'src/hooks/useToast';
import { UserResponse } from 'src/sdk/users';
import { Button } from 'src/uikit/Button';
import CircleStatus from 'src/uikit/CircleStatus';
import { H1, P } from 'src/uikit/Typography';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  width: auto;
  overflow-y: auto;
`;

const Content = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding-inline: 4rem;
`;

const Title = styled(H1)`
  line-height: 30px;
`;

const Text = styled(P)`
  font-size: 14px;
  line-height: 24px;
  text-align: center;
`;

interface Props {
  user: UserResponse;
}

const RegisterSuccess = ({ user }: Props) => {
  const { mutateAsync } = useStartVerificationEmailMutation();

  const { t } = useTranslation();
  const { formatErrorApi } = useFormatErrorApi();
  const { toastError, toastSuccess } = useToast();

  const handleClick = useCallback(async () => {
    try {
      mutateAsync({
        userId: user.id,
      });
      toastSuccess(t('components.Auth.RegisterSuccess.resendSuccess'));
    } catch (err) {
      const message = formatErrorApi(err);
      toastError(message);
    }
  }, [formatErrorApi, mutateAsync, t, toastError, toastSuccess, user.id]);

  return (
    <Wrapper>
      <Content>
        <CircleStatus type="success" />
        <Title>
          {t('components.Auth.RegisterSuccess.congratulations.title')}
        </Title>
        <Text>
          {t('components.Auth.RegisterSuccess.congratulations.subtitle')}
        </Text>
        <Title>{t('components.Auth.RegisterSuccess.verify.title')}</Title>
        <Text>
          {t('components.Auth.RegisterSuccess.verify.email', {
            email: user.email || 'debug@ontexglobal.com',
          })}
        </Text>
        <Text>{t('components.Auth.RegisterSuccess.verify.text')}</Text>
        <Button onClick={handleClick}>
          {t('components.Auth.RegisterSuccess.resendButton')}
          {' >'}
        </Button>
      </Content>
    </Wrapper>
  );
};

export default RegisterSuccess;
