import { useCallback, useMemo, useState } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import useFetchNursingHomesQuery from 'src/components/NursingHome/hooks/useFetchNursingHomesQuery';
import useFormatErrorApi from 'src/hooks/useFormatErrorApi';
import useToast from 'src/hooks/useToast';
import { UserResponse } from 'src/sdk/users';
import { List } from 'src/uikit/Layout/List';
import SearchInput from 'src/uikit/SearchInput';
import { H4 } from 'src/uikit/Typography';
import { Stack } from 'src/uikit/Wrapper';
import styled from 'styled-components/macro';

import { UserNursingHomeResponse } from '../../../../sdk/users';
import useAddNursingHomeToUserMutation from '../../hooks/useAddNursingHomeToUserMutation';
import  useDeleteNursingHomeToUserMutation from '../../hooks/useDeleteNursingHomeToUserMutation';
import UserNursingHomeCard from './UserNursingHomeCard';

const Wrapper = styled(Container)`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  padding: 1rem;
  height: 100%;
`;

const SearchColumn = styled(Col)`
  display: flex;
  justify-content: flex-start;
`;

const Label = styled.label`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
`;

const Emphased = styled.span`
  font-style: bold;
  color: ${({ theme }) => theme.palette.main.primary};
`;

const PAGE_SIZE = 1000;

interface Props {
  user: UserResponse;
  callback?: () => void;
}

const UserNursingHome = ({ user, callback }: Props) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const { toastError, toastSuccess } = useToast();
  const { formatErrorApi } = useFormatErrorApi();
  const [searchFilter, setSearchFilter] = useState<string>('');
  const handleChangeSearch = useCallback((value: string) => {
    setPage(0);
    setSearchFilter(value);
  }, []);
  const { mutateAsync: addNursingHomeToUserMutation } =
    useAddNursingHomeToUserMutation();
  const { mutateAsync: deleteNursingHomeToUserMutation } =
    useDeleteNursingHomeToUserMutation();
  const { data, refetch } = useFetchNursingHomesQuery({
    page,
    size: PAGE_SIZE,
    nameOrSoldtoPart: searchFilter,
    expand: [],
    sort: { direction: 'asc', label: 'name' },
  });
  const handleAddNursingHone = async (nursingHome: UserNursingHomeResponse) => {
    try {
      await addNursingHomeToUserMutation({
        id: user.id,
        nursingHomeId: nursingHome.id,
      });
      refetch();
      callback?.();
      toastSuccess(t('components.Users.UserDetails.UserNursingHomes.success'));
    } catch (err) {
      const message = formatErrorApi(err);
      toastError(message);
    }
  };
  const handleDeleteNursingHone = async (
    nursingHome: UserNursingHomeResponse,
  ) => {
    try {
      await deleteNursingHomeToUserMutation({
        id: user.id,
        nursingHomeId: nursingHome.id,
      });
      refetch();
      callback?.();
      toastSuccess(t('components.Users.UserDetails.UserNursingHomes.success'));
    } catch (err) {
      const message = formatErrorApi(err);
      toastError(message);
    }
  };
  const availableList = useMemo(() => {
    return data?.content.filter((nh) => {
      return (
        !user.nursingHomes ||
        user.nursingHomes?.findIndex((userNh) => userNh.id === nh.id) === -1
      );
    });
  }, [data?.content, user.nursingHomes]);
  const ownedList = useMemo(() => {
    if (!user.nursingHomes) {
      return [];
    }
    return data?.content.filter((nh) => {
      return (
        user.nursingHomes?.findIndex((userNh) => userNh.id === nh.id) !== -1
      );
    });
  }, [data?.content, user.nursingHomes]);
  return (
    <Wrapper fluid>
      <Row className="justify-content-between">
        <Label>
          <Trans
            i18nKey={'components.Users.UserDetails.UserNursingHomes.label'}
            components={{
              span: <Emphased />,
            }}
            values={{
              name: `${user.firstName} ${user.lastName}`,
            }}
          />
        </Label>
      </Row>
      <Stack className="mt-4">
        <H4>{t('components.Users.UserDetails.UserNursingHomes.ownedList')}</H4>
        <List columnSize="300px">
          {ownedList?.map((nursingHome) => {
            return (
              <UserNursingHomeCard
                type="owned"
                key={nursingHome.id}
                nursingHome={nursingHome}
                onClick={handleDeleteNursingHone}
              />
            );
          })}
        </List>
      </Stack>
      <Stack className="mt-4">
        <H4>
          {t('components.Users.UserDetails.UserNursingHomes.availableList')}
        </H4>
        <Row className="justify-content-between">
          <SearchColumn md="2">
            <SearchInput onChange={handleChangeSearch} />
          </SearchColumn>
        </Row>
        <List columnSize="300px">
          {availableList?.map((nursingHome) => {
            return (
              <UserNursingHomeCard
                type="available"
                key={nursingHome.id}
                nursingHome={nursingHome}
                onClick={handleAddNursingHone}
              />
            );
          })}
        </List>
      </Stack>
    </Wrapper>
  );
};

export default UserNursingHome;
