import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CloseCross, Warning } from 'src/assets/list-icons';
import EmptyModal from 'src/components/Misc/Modal/EmptyModal';
import { Button, ButtonColors } from 'src/uikit/Button';
import styled from 'styled-components/macro';

interface Props {
  isVisible: boolean;
  handleClose: () => void;
  handleValidation: () => Promise<void>;
  children: ReactNode;
}

const StyledEmptyModal = styled(EmptyModal)`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const StyledButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  align-self: start;
`;

export const StyledButtonsWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1rem;
  gap: 0.25rem;
`;

const ModalConfirmation = ({
  isVisible,
  handleClose,
  handleValidation,
  children,
}: Props) => {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState<boolean>(false);

  const onReset = async () => {
    setDisabled(true);
    await handleValidation();
    setDisabled(false);
  };

  return (
    <StyledEmptyModal isVisible={isVisible} handleClose={handleClose}>
      <StyledHeader>
        <Warning />
        <StyledButton onClick={handleClose}>
          <CloseCross />
        </StyledButton>
      </StyledHeader>
      {children}
      <StyledButtonsWrapper>
        <Button background={ButtonColors.CANCEL} onClick={handleClose}>
          {t('common.action.cancel')}
        </Button>
        <Button onClick={onReset} disabled={disabled}>
          {t('common.action.reset')}
        </Button>
      </StyledButtonsWrapper>
    </StyledEmptyModal>
  );
};

export default ModalConfirmation;
