import { yupResolver } from '@hookform/resolvers/yup';
import { Form } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import { Profiles } from 'src/sdk/authentification';
import * as yup from 'yup';

import UserCreateForm, {
  userCreateFormSchema,
} from './components/UserCreateFormFieldset';
import UserCreateFormFooter, {
  UserCreateFormFooterProps,
} from './components/UserCreateFormFooter';

const userOntexCreateFormSchema = (t: TFunction) => userCreateFormSchema(t);

export type UserOntexCreateFormSchema = yup.InferType<
  ReturnType<typeof userOntexCreateFormSchema>
>;

interface Props extends UserCreateFormFooterProps {
  onSubmit: (values: UserOntexCreateFormSchema) => void;
}

const UserOntexCreateForm = ({ onBack, onCancel, onSubmit }: Props) => {
  const { t } = useTranslation();
  const methods = useForm<UserOntexCreateFormSchema>({
    resolver: yupResolver(userOntexCreateFormSchema(t)),
  });

  const { handleSubmit } = methods;

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <UserCreateForm
          profilesFilter={[Profiles.ORIZON_ADMIN, Profiles.PRODUCT_SPECIALIST]}
        />
        <UserCreateFormFooter onBack={onBack} onCancel={onCancel} />
      </Form>
    </FormProvider>
  );
};

export default UserOntexCreateForm;
