import { useState } from 'react';
import { useModal } from 'src/hooks/useModal';
import { PartnerProspectDeliveryAddressResponse } from 'src/sdk/partner-prospect';
import { List } from 'src/uikit/Layout/List';

import PartnerProspectAddressCard from './PartnerProspectAddressCard';
import PartnerProspectAddressDeleteModal from './PartnerProspectAddressDeleteModal';
import PartnerProspectAddressUpsertModal from './PartnerProspectAddressUpsertModal';

interface Props {
  partnerProspectId: number;
  addresses: PartnerProspectDeliveryAddressResponse[];
}

const PartnerProspectAddressesList = ({
  addresses,
  partnerProspectId,
}: Props) => {
  const [currentAddress, setCurrentAddress] =
    useState<PartnerProspectDeliveryAddressResponse | null>(null);
  const { modalOpen: modalOpenDelete, setModalOpen: setModalOpenDelete } =
    useModal();
  const { modalOpen: modalOpenUpdate, setModalOpen: setModalOpenUpdate } =
    useModal();

  const onClickDelete = (partner: PartnerProspectDeliveryAddressResponse) => {
    setCurrentAddress(partner);
    setModalOpenDelete(true);
  };

  const onClickUpdate = (partner: PartnerProspectDeliveryAddressResponse) => {
    setCurrentAddress(partner);
    setModalOpenUpdate(true);
  };

  const onFinish = () => {
    setCurrentAddress(null);
    setModalOpenUpdate(false);
    setModalOpenDelete(false);
  };

  return (
    <List className="w-100 py-2" columnSize="350px">
      {addresses.map((address) => (
        <PartnerProspectAddressCard
          key={address.id}
          address={address}
          onClickDelete={onClickDelete}
          onClickUpdate={onClickUpdate}
          canDelete={addresses.length > 1}
        />
      ))}
      {currentAddress && (
        <>
          <PartnerProspectAddressDeleteModal
            partnerProspectId={partnerProspectId}
            address={currentAddress}
            onFinish={onFinish}
            show={modalOpenDelete}
          />
          <PartnerProspectAddressUpsertModal
            partnerProspectId={partnerProspectId}
            address={currentAddress}
            onFinish={onFinish}
            show={modalOpenUpdate}
          />
        </>
      )}
    </List>
  );
};

export default PartnerProspectAddressesList;
