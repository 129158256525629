import { ForwardedRef, forwardRef, ReactElement } from 'react';
import { AsyncPaginate, OptionsList } from 'react-select-async-paginate';

import { orizonStyleSelect, StyledSelect } from './SelectInput';
import { SelectInputProps } from './SelectInput/index';

export type AsyncOptionType<O = unknown> = {
  value: O;
  label: string;
};

export type AsyncSelectInputProps<T extends AsyncOptionType<unknown>> = {
  value: T | null;
  disabled?: boolean;
  defaultOptions?: OptionsList<T>;
  placeholder?: string;
  onChange: (value: T) => void;
  loadOptions: (
    search: string,
    prevOptions: OptionsList<T>,
  ) => Promise<{
    options: T[];
    hasMore: boolean;
  }>;
  isInvalid?: boolean;
} & Pick<SelectInputProps, 'variant'>;

const AsyncSelectInputInner = <T extends AsyncOptionType>(
  {
    value,
    onChange,
    loadOptions,
    disabled,
    placeholder,
    variant,
    defaultOptions,
    isInvalid,
  }: AsyncSelectInputProps<T>,
  ref: ForwardedRef<HTMLSelectElement>,
) => {
  return (
    <StyledSelect
      as={AsyncPaginate}
      ref={ref}
      value={value}
      onChange={onChange}
      defaultOptions={defaultOptions}
      loadOptions={loadOptions}
      debounceTimeout={500}
      styles={orizonStyleSelect}
      isDisabled={disabled}
      menuPlacement={'auto'}
      classNamePrefix="styledSelect"
      className="selectWrapper"
      placeholder={placeholder}
      variant={variant}
      isInvalid={isInvalid}
      isClearable
    />
  );
};

const AsyncSelectInput = forwardRef(AsyncSelectInputInner) as <
  T extends AsyncOptionType,
>(
  p: AsyncSelectInputProps<T> & { ref?: ForwardedRef<HTMLSelectElement> },
) => ReactElement;

export default AsyncSelectInput;
