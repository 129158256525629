import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import SortingButton from '../../SortingHeaders/SortingButton';

const StyledRow = styled(Row)`
  padding-left: 10px;
  padding-right: 10px;
`;

const StyledCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SubscriptionHistoryHeader = () => {
  const { t } = useTranslation();

  return (
    <StyledRow>
      <StyledCol md={{ offset: '1', span: '2' }}>
        <SortingButton
          primary
          label={t('components.sortingHeader.sortByType')}
          field="type"
          disableSort
        />
      </StyledCol>
      <StyledCol md="2">
        <SortingButton
          primary
          label={t('components.sortingHeader.sortByStartingDate')}
          field="startingDate"
          disableSort
        />
      </StyledCol>
      <StyledCol md="2">
        <SortingButton
          primary
          label={t('components.sortingHeader.sortByEndingDate')}
          field="endingDate"
          disableSort
        />
      </StyledCol>
      <StyledCol md="2">
        <SortingButton
          primary
          label={t('components.sortingHeader.sortByResidents')}
          field="residents"
          disableSort
        />
      </StyledCol>
    </StyledRow>
  );
};

export default SubscriptionHistoryHeader;
