import { useQuery, QueryObserverOptions } from 'react-query';
import request from 'src/axios';
import { GetProspects, Prospect } from 'src/sdk/prospect';

export type ProspectsSortField = {
  label: keyof Prospect;
  direction: 'asc' | 'desc';
};

type QueryParams = {
  userId?: number;
};

const fetchProspects = async ({ userId }: QueryParams) => {
  const { data } = await request.get('/prospects', {
    params: {
      'user-id': userId,
    },
  });

  return data;
};

const useFetchProspectsQuery = (
  params: QueryParams,
  options?: QueryObserverOptions<GetProspects, Error>,
) => {
  const { data, isLoading, refetch, error } = useQuery<GetProspects, Error>(
    ['prospectsList', { ...params }],
    () => fetchProspects(params),
    {
      ...options,
    },
  );

  return {
    data,
    isLoading,
    refetch,
    error,
  };
};

export default useFetchProspectsQuery;
