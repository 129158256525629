import { Col, Row, Stack } from 'react-bootstrap';
import { generatePath, useHistory } from 'react-router-dom';
import { ArrowRight } from 'src/assets/arrows';
import { COUNTRY_FLAGS } from 'src/assets/flags';
import { ReactComponent as HomeIcon } from 'src/assets/home.svg';
import {
  ChargingStation,
  Clipon,
  Gateway,
  Resident,
} from 'src/assets/list-icons';
import Routes from 'src/router/Routes';
import { NursingHomeResponse } from 'src/sdk/nursingHome';
import { SubscriptionStatus } from 'src/sdk/subscription';
import { Card, CardBody } from 'src/uikit/Card';
import { Detail, Text, Label } from 'src/uikit/Typography';
import styled, { css } from 'styled-components/macro';

import NursingHomeRowShippingRequest from './NursingHomeRowShippingRequest';
import NursingHomeRowSubscription from './NursingHomeRowSubscription';

const WrapperIcon = styled.div`
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: cemter;
  padding: 0.5rem;
  border-radius: 50%;
`;

const WrapperIconFlag = styled(WrapperIcon)`
  padding: 0 0.5rem;
  height: 24px;
`;

const WrapperNameIcon = styled(WrapperIcon)<{ disabled: boolean }>`
  --color: ${({ theme }) => theme.palette.main.primary};
  --background: none;
  color: var(--color);
  border: 1px solid var(--color);
  transition: background 100ms ease-in-out;

  ${({ disabled }) =>
    disabled &&
    css`
      --color: ${({ theme }) => theme.palette.black[50]};
      --background: none;
    `}
`;

const Name = styled(Text)`
  font-weight: 700;
`;

const Identifier = styled(Detail)`
  color: ${({ theme }) => theme.palette.black[90]};
`;

const EquipmentRow = styled(Stack)`
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
    `}
`;

const EquipmentCol = styled(Col)`
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  justify-content: center;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    justify-content: start;
    flex-direction: row;
  }
`;

const StyledArrowRight = styled(ArrowRight)`
  border: 1px solid ${({ theme }) => theme.palette.main.primary};
  width: 42px;
  height: 42px;
  padding: 0.5rem;
  color: ${({ theme }) => theme.palette.main.primary};
  border-radius: 50%;
  transition: background 100ms ease-in-out;
`;

const StyledCard = styled(Card)`
  :hover {
    transform: scale(1.01);
    box-shadow: ${({ theme }) => theme.shadows.lg};
    cursor: pointer;
    ${StyledArrowRight} {
      color: ${({ theme }) => theme.palette.main.white};
      background: ${({ theme }) => theme.palette.main.primary};
    }

    ${WrapperNameIcon} {
      color: ${({ theme }) => theme.palette.main.white};
      background: var(--color);
    }
  }
`;

interface Props {
  nursingHome: NursingHomeResponse;
}

const NursingHomeRow = ({ nursingHome }: Props) => {
  const history = useHistory();

  return (
    <StyledCard
      as={'article'}
      onClick={() =>
        history.push(
          generatePath(Routes.NURSING_GENERAL, {
            id: nursingHome.id,
          }),
        )
      }
    >
      <CardBody>
        <Row>
          <Col md={3} className="d-flex flex-column justify-content-between">
            <Stack direction="horizontal" gap={2}>
              <WrapperNameIcon
                disabled={
                  !nursingHome.subscription ||
                  nursingHome.subscription?.status ===
                    SubscriptionStatus.TERMINATED
                }
              >
                <HomeIcon />
              </WrapperNameIcon>

              <Name>{nursingHome.name}</Name>
            </Stack>
            <Stack direction="horizontal" gap={2}>
              <WrapperIconFlag>
                {COUNTRY_FLAGS[nursingHome.language || 'fr']}
              </WrapperIconFlag>

              <Identifier>{nursingHome.salesOrg || 'XXXXX'}</Identifier>
            </Stack>
          </Col>
          <Col
            md={4}
            className="d-flex justify-content-center align-items-center"
          >
            <EquipmentRow
              className="my-auto"
              direction="horizontal"
              gap={2}
              disabled={
                !nursingHome.subscription ||
                nursingHome.subscription.status ===
                  SubscriptionStatus.TERMINATED
              }
            >
              <EquipmentCol xs="5" md="2">
                <Clipon />
                <Label>{nursingHome.cliponMetrics?.totalCount || '-'}</Label>
              </EquipmentCol>
              <EquipmentCol xs="5" md="2">
                <ChargingStation />
                <Label>
                  {nursingHome.chargingStationMetrics?.totalCount || '-'}
                </Label>
              </EquipmentCol>
              <EquipmentCol xs="5" md="2">
                <Gateway />
                <Label>{nursingHome.gatewayMetrics?.totalCount || '-'}</Label>
              </EquipmentCol>
              <EquipmentCol xs="5" md="4">
                <Resident />
                <Label>
                  {nursingHome.subscription
                    ? nursingHome.subscription.residentEstimate
                    : '-'}
                </Label>
              </EquipmentCol>
            </EquipmentRow>
          </Col>
          <Col xs={9} md="3" xxl={4} className="mt-3 mt-sm-0">
            <Stack gap={2}>
              <NursingHomeRowSubscription
                subscription={nursingHome.subscription}
              />
              <NursingHomeRowShippingRequest
                shippingRequests={nursingHome.shippingRequests}
              />
            </Stack>
          </Col>
          <Col
            xs={3}
            xl={1}
            className="mt-3 mt-sm-0 d-flex align-items-center justify-content-end"
          >
            <StyledArrowRight />
          </Col>
        </Row>
      </CardBody>
    </StyledCard>
  );
};

export default NursingHomeRow;
