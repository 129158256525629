import { createGlobalStyle } from 'styled-components/macro';

import breakpoints from './breakpoints';
import palette from './palette';
import { shadows } from './shadows';
import { fontPrimary, fontSecondary } from './typography';

const theme = {
  palette,
  breakpoints,
  typography: {
    fontPrimary,
    fontSecondary,
  },
  shadows,
};

export type Theme = typeof theme & {
  palette: { main: { curiousBlue: string } };
};

export const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
  }
  * {
    margin: 0;
  }
  html {
    font-size: 16px;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
  }
  html, body {
    height: 100%;
    color: ${palette.black[90]}
  }
  body {
    ${fontPrimary}
    color: ${palette.black[90]};
    line-height: 1.5;
    overflow-x: hidden;
  }
  img, picture, video, canvas, svg {
    display: block;
    max-width: 100%;
  }
  input, button, textarea, select {
    font: inherit;
  }
  p, h1, h2, h3, h4, h5, h6 {
    overflow-wrap: break-word;
  }
  label {
    margin: 0;
  }
  #root {
    isolation: isolate;
  }
`;

export default theme;
