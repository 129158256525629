import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo } from 'react';
import { Form, ModalBody } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import { FormProvider, useForm } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import { SparePart, CloseCross } from 'src/assets/list-icons';
import DefaultModal from 'src/components/Misc/Modal/DefaultModal';
import NHEquipmentOrderResume from 'src/components/NursingHome/Equipment/Order/OrderForm/NHEquipmentOrderResume';
import { useModal } from 'src/hooks/useModal';
import { DeliveryAddress } from 'src/sdk/nursingHome';
import { Button } from 'src/uikit/Button';
import { H2 } from 'src/uikit/Typography';
import { FormButtonsWrapper } from 'src/uikit/Wrapper';
import styled from 'styled-components/macro';
import * as yup from 'yup';

import { ShippingRequestOrderSparePartsLimitsResponse } from '../../../../sdk/shippingRequest';
import { nhEquipmentOrderFormDeliverySchema } from './OrderForm/NHEquipmentOrderFormDelivery';
import NHEquipmentOrderFormDelivery from './OrderForm/NHEquipmentOrderFormDelivery';
import {
  NHEquipmentOrderFormEquipmentSchema,
  nhEquipmentOrderFormEquipmentSchema,
} from './OrderForm/NHEquipmentOrderFormEquipment';
import NHEquipmentOrderFormEquipment from './OrderForm/NHEquipmentOrderFormEquipment';

const Header = styled(ModalHeader)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 0rem;
  width: 100%;
  border-bottom: none;
`;

const WrapperIcon = styled.div`
  border-radius: 50%;

  & > * {
    color: white;
  }
`;

const CloseButton = styled.button`
  background: none;
  padding: 0.5rem;
  box-shadow: unset;
  border: none;
  border-radius: 50%;

  :hover:not(:disabled) {
    transform: scale(1.1);
  }
`;

const nhEquipmentOrderFormSchema = (t: TFunction) =>
  nhEquipmentOrderFormDeliverySchema(t).concat(
    nhEquipmentOrderFormEquipmentSchema(t),
  );

export type NHEquipmentOrderFormSchema = yup.InferType<
  ReturnType<typeof nhEquipmentOrderFormSchema>
>;

interface Props {
  deliveryAddresses: (DeliveryAddress & { isPartner?: boolean })[];
  defaultValues: NHEquipmentOrderFormSchema;
  onSubmit: (values: NHEquipmentOrderFormSchema) => void;
  equipmentsCount: NHEquipmentOrderFormEquipmentSchema;
  limit: ShippingRequestOrderSparePartsLimitsResponse;
}

const NHEquipmentOrderForm = ({
  defaultValues,
  onSubmit,
  deliveryAddresses,
  equipmentsCount,
  limit,
}: Props) => {
  const { t } = useTranslation();
  const { modalOpen, setModalOpen } = useModal();
  const methods = useForm<NHEquipmentOrderFormSchema>({
    resolver: yupResolver(nhEquipmentOrderFormSchema(t)),
    mode: 'onChange',
    defaultValues,
  });

  const { formState, handleSubmit, getValues, watch } = methods;
  const { isValid } = formState;

  const _onSubmit = async (values: NHEquipmentOrderFormSchema) => {
    setModalOpen(true);
  };

  const onFinish = async (values: NHEquipmentOrderFormSchema) => {
    await onSubmit(values);
    setModalOpen(false);
  };

  const equipmentValues = watch([
    'cliponCount',
    'gatewayCount',
    'chargingStationCount',
  ]);

  const hasEquipments = useMemo(() => {
    return (
      equipmentValues.chargingStationCount !== 0 ||
      equipmentValues.cliponCount !== 0 ||
      equipmentValues.gatewayCount !== 0
    );
  }, [
    equipmentValues.chargingStationCount,
    equipmentValues.cliponCount,
    equipmentValues.gatewayCount,
  ]);

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(_onSubmit)} id="choose-equipments-form">
        <fieldset>
          <NHEquipmentOrderFormDelivery
            deliveryAddresses={deliveryAddresses}
            limit={limit}
          />
        </fieldset>
        <fieldset className="mt-4">
          <NHEquipmentOrderFormEquipment
            equipmentsCount={equipmentsCount}
            limit={limit}
          />
        </fieldset>
      </Form>
      <FormButtonsWrapper className="mt-4 justify-content-end">
        <Button
          disabled={!isValid || !hasEquipments}
          type="submit"
          form="choose-equipments-form"
        >
          {t('common.action.next')} &gt;
        </Button>
      </FormButtonsWrapper>
      {modalOpen && (
        <DefaultModal
          customClose
          isVisible={modalOpen}
          handleClose={() => setModalOpen(false)}
          hasStep={false}
          header={
            <Header>
              <WrapperIcon>
                <SparePart />
              </WrapperIcon>
              <div>
                <H2>Spare part request validation</H2>
              </div>
              <CloseButton onClick={() => setModalOpen(false)}>
                <CloseCross />
              </CloseButton>
            </Header>
          }
        >
          <ModalBody>
            <NHEquipmentOrderResume
              values={getValues()}
              onFinish={onFinish}
              deliveryAddresses={deliveryAddresses}
            />
          </ModalBody>
        </DefaultModal>
      )}
    </FormProvider>
  );
};

export default NHEquipmentOrderForm;
