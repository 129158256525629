import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Home } from 'src/assets/list-icons';
import { NursingHomeResponse } from 'src/sdk/nursingHome';
import { Partner } from 'src/sdk/partner';
import { Label, Text } from 'src/uikit/Typography';
import styled from 'styled-components/macro';

import GeneralCard from './GeneralCard';

const HomeIcon = styled(Home)`
  width: 150px;
  height: 150px;
`;

const ColumnIcon = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const AddressWrapper = styled(Row)`
  padding-block: 1rem;
`;

const AddressCol = styled(Col)`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-block: 0.25rem;
`;

const AddressData = styled(Text)`
  color: ${({ theme }) => theme.palette.black[70]};
`;
// const NHLink = styled.a`
//   ${fontPrimary}
//   font-style: normal;
//   font-weight: 400;
//   font-size: 10px;
//   line-height: 14px;
//   color: ${({ theme }) => theme.palette.main.primary};
//   text-decoration: none;

//   &:hover {
//     color: ${({ theme }) => theme.palette.main.primary};
//   }
// `;

interface Props {
  address?: NursingHomeResponse['address'] | Partner['address'];
}

const BillingCard = ({ address }: Props) => {
  // const { authUser } = useAuth();
  // const nhUrl = useMemo(() => {
  //   // TODO PC : looks like a dirty idea to figure out the web nurse url to reach.
  //   const domain = window.location.hostname;
  //   if (domain.includes('localhost') || domain.includes('development')) {
  //     return 'https://www.development.pioneer.ontdf.io';
  //   } else if (domain.includes('staging')) {
  //     return 'https://www.staging.pioneer.ontdf.io';
  //   } else {
  //     return 'https://www.orizon.app';
  //   }
  // }, []);

  const { t } = useTranslation();

  return (
    <GeneralCard title={t('components.nursingHome.general.billingCard.title')}>
      <Row>
        <ColumnIcon lg="3">
          <HomeIcon />
          {/* {authUser?.profile !== Profiles.ORIZON_ADMIN && (
            <NHLink href={nhUrl}>{t('components.billingCard.nhLink')}</NHLink>
          )} */}
        </ColumnIcon>
        <Col>
          <AddressWrapper>
            <AddressCol md="12">
              <Label>{t('common.address.name')} :</Label>
              <AddressData>{address?.name}</AddressData>
            </AddressCol>
            <AddressCol md="12">
              <Label>{t('common.address.street')} :</Label>
              <AddressData>{address?.street}</AddressData>
            </AddressCol>
            <AddressCol md="12">
              <Label>{t('common.address.city')} :</Label>
              <AddressData>{address?.town}</AddressData>
            </AddressCol>
            <AddressCol md="6">
              <Label>{t('common.address.zip')} :</Label>
              <AddressData>{address?.zip}</AddressData>
            </AddressCol>
            <AddressCol md="6">
              <Label>{t('common.address.country')} :</Label>
              <AddressData>{address?.country}</AddressData>
            </AddressCol>
            <AddressCol md="12">
              <Label>{t('common.address.phone')} :</Label>
              <AddressData>{address?.telephone}</AddressData>
            </AddressCol>
          </AddressWrapper>
        </Col>
      </Row>
    </GeneralCard>
  );
};

export default BillingCard;
