import { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { NHEquipmentOrderFormSchema } from 'src/components/NursingHome/Equipment/Order/NHEquipmentOrderForm';
import EquipmentCardCounter from 'src/components/Subscription/SubscriptionUpsert/components/EquipmentCardCounter';
import { Address } from 'src/sdk/common';
import { EquipmentTypes } from 'src/sdk/equipment';
import { DeliveryAddress } from 'src/sdk/nursingHome';
import { Button } from 'src/uikit/Button';
import { ButtonColors } from 'src/uikit/Button/index';
import { Label, Text } from 'src/uikit/Typography';
import { formatDate } from 'src/utils/date';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Field = styled.div`
  display: grid;
  grid-template-columns: minmax(90px, auto) 1fr;
  gap: 0.25rem;
  text-align: left;
`;

interface Props {
  values: NHEquipmentOrderFormSchema;
  onFinish: (values: NHEquipmentOrderFormSchema) => void;
  deliveryAddresses: DeliveryAddress[];
}

const NHEquipmentOrderResume = ({
  values,
  onFinish,
  deliveryAddresses,
}: Props) => {
  const { t, i18n } = useTranslation();

  const address: Address | null | undefined = useMemo(() => {
    if (values.deliveryAddressId === -1) {
      return values.customAddress;
    }

    return deliveryAddresses.find(
      (address) => address.id === values.deliveryAddressId,
    );
  }, [deliveryAddresses, values.customAddress, values.deliveryAddressId]);

  return (
    <Wrapper>
      <Row className="w-100 justify-content-center">
        <Text>
          {t('components.nursingHome.equipments.order.orderResume.subtitle')}
        </Text>
      </Row>
      <Field>
        <Label>
          {t('components.nursingHome.equipments.order.orderResume.date')}
        </Label>
        <Text> {formatDate(values.requestedDeliveryDate, i18n.language)}</Text>
      </Field>
      <Field>
        <Label>
          {t('components.nursingHome.equipments.order.orderResume.comment')}
        </Label>
        <Text> {values.comment}</Text>
      </Field>
      {address && (
        <Field>
          <Label>
            {t('components.nursingHome.equipments.order.orderResume.address')}
          </Label>
          <div>
            <Text> {address.name} </Text>
            <Text> {address.street} </Text>
            <Text>
              {address.zip} - {address.town} - {address.country}
            </Text>
          </div>
        </Field>
      )}
      <Col className="text-start" xs="12">
        <Label>
          {t('components.nursingHome.equipments.order.orderResume.requested')}
        </Label>
      </Col>
      <div style={{ justifyContent: 'center', display: 'flex' }}>
        <div>
          <div style={{ marginBottom: '12px' }}>
            <EquipmentCardCounter
              type={EquipmentTypes.CLIPON}
              value={values.cliponCount || 0}
            />
          </div>
          <div style={{ marginBottom: '12px' }}>
            <EquipmentCardCounter
              type={EquipmentTypes.GATEWAY}
              value={values.gatewayCount || 0}
            />
          </div>
          <div style={{ marginBottom: '12px' }}>
            <EquipmentCardCounter
              type={EquipmentTypes.CHARGING}
              value={values.chargingStationCount || 0}
            />
          </div>
        </div>
      </div>
      <div style={{ justifyContent: 'end', display: 'flex' }}>
        <Button
          background={ButtonColors.SUCCESS}
          onClick={() => onFinish(values)}
        >
          {t('components.nursingHome.equipments.order.orderResume.finish')} &gt;
        </Button>
      </div>
    </Wrapper>
  );
};

export default NHEquipmentOrderResume;
