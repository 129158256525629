import { useCallback, useState, useEffect } from 'react';
import { Col, Row, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import ListPaginated from 'src/components/Misc/ListPaginated';
import { ShippingRequestMode } from 'src/components/NursingHome/ShippingRequest/ShippingRequestExpandableItem';
import ShippingRequestExpandableList from 'src/components/NursingHome/ShippingRequest/ShippingRequestExpandableList';
import ShippingRequestExpandableListHeader from 'src/components/NursingHome/ShippingRequest/ShippingRequestExpandableListHeader';
import ShippingRequestFilterStatus from 'src/components/NursingHome/ShippingRequest/ShippingRequestFilterStatus';
import Routes from 'src/router/Routes';
import { NursingHomeResponse } from 'src/sdk/nursingHome';
import { ShippingRequestResponse } from 'src/sdk/shippingRequest';
import { Badge } from 'src/uikit/Badge';
import SearchInput from 'src/uikit/SearchInput';
import SwitchInput from 'src/uikit/SwitchInput';
import { H4 } from 'src/uikit/Typography';
import { PageWrapper } from 'src/uikit/Wrapper';

import NursingHomeAsyncSelect from '../components/NursingHome/NursingHomeAsyncSelect';
import useFetchShipingRequestQuery, {
  ShippingRequestSortField,
} from '../components/NursingHome/ShippingRequest/hooks/useFetchShipingRequestQuery';

const ProvisioningShippingRequests = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [sortField, setSortField] = useState<ShippingRequestSortField>({
    label: 'createdDate',
    direction: 'desc',
  });
  const [filterStatus, setFilterStatus] =
    useState<ShippingRequestResponse['status']>();
  const [filterSource, setFilterSource] =
    useState<ShippingRequestResponse['source']>();
  const [filterNursingHome, setFilterNursingHome] =
    useState<NursingHomeResponse>();

  const { data, isLoading, isError } = useFetchShipingRequestQuery({
    page,
    size: 18,
    expand: [
      'counts',
      'custom-address',
      'handling-units',
      'movements',
      'delivery-address',
      'requester',
      'nursing-home',
    ],
    extra: ['count-by-status'],
    search: searchValue,
    sortField,
    status: filterStatus,
    source: filterSource,
    nursingHome: filterNursingHome,
  });

  useEffect(() => {
    setPage(0);
  }, [filterStatus, searchValue, filterSource, filterNursingHome]);

  const handleFilterMetric = useCallback(
    (value: ShippingRequestResponse['status']) => {
      setFilterStatus(value === filterStatus ? undefined : value);
    },
    [filterStatus],
  );

  if (isError) return <Redirect to={Routes.ERROR} />;

  return (
    <PageWrapper>
      <Row>
        <ShippingRequestFilterStatus
          extra={data?.extra}
          filterValue={filterStatus}
          onFilterBy={handleFilterMetric}
        />
      </Row>
      <Stack direction="horizontal" className="align-items-center mt-4" gap={2}>
        <H4>{t('pages.provisioning.title')}</H4>
        <Badge bg="primary" className="ml-2">
          {data?.totalElements}
        </Badge>
      </Stack>
      <Row className="align-items-center mt-3 mb-4">
        <Col md="2">
          <SearchInput onChange={setSearchValue} />
        </Col>
        <Col md="2" className="ml-3 d-flex">
          <NursingHomeAsyncSelect
            placeholder={t(
              'pages.provisioning.shippingRequest.nursingHomePlaceholder',
            )}
            value={filterNursingHome}
            onChange={(value) => setFilterNursingHome(value)}
          />
        </Col>
        <Col className="ml-3 d-flex">
          <SwitchInput
            label={t('pages.provisioning.shippingRequest.spareParts')}
            labelPosition="left"
            onChange={(isChecked) =>
              setFilterSource(isChecked ? 'SPARE_PARTS' : undefined)
            }
          />
        </Col>
      </Row>
      <ListPaginated
        header={
          <ShippingRequestExpandableListHeader
            onSortingChange={setSortField}
            sortField={sortField}
          />
        }
        isLoading={isLoading}
        page={page}
        setPage={setPage}
        data={data}
      >
        <ShippingRequestExpandableList
          shippingRequests={data?.content}
          mode={ShippingRequestMode.HANDLING_UNIT}
        />
      </ListPaginated>
    </PageWrapper>
  );
};

export default ProvisioningShippingRequests;
