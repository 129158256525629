import {
  ReactNode,
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import {
  TypeConfiguration,
  SubscriptionCreationRequest,
} from 'src/sdk/subscription';

type SubscriptionUpsertContextProps = {
  currentConfiguration?: TypeConfiguration;
  configurations?: TypeConfiguration[];
  changeConfiguration: (type: SubscriptionCreationRequest['type']) => void;
};

export const SubscriptionUpsertContext =
  createContext<SubscriptionUpsertContextProps>({
    currentConfiguration: undefined,
    changeConfiguration: (type: SubscriptionCreationRequest['type']) =>
      undefined,
  });

type SubscriptionUpsertProviderProps = {
  defaultType?: SubscriptionCreationRequest['type'];
  children: ReactNode;
  configurations?: TypeConfiguration[];
};

export const SubscriptionUpsertProvider = ({
  defaultType,
  children,
  configurations,
}: SubscriptionUpsertProviderProps) => {
  const [currentConfiguration, setCurrentConfiguration] =
    useState<TypeConfiguration>();

  const changeConfiguration = useCallback(
    (type: SubscriptionCreationRequest['type']) => {
      const newConfiguration = configurations?.find(
        (_configuration) => _configuration.type === type,
      );
      setCurrentConfiguration(newConfiguration);
    },
    [configurations],
  );

  useEffect(() => {
    changeConfiguration(defaultType || 'STANDARD');
  }, [changeConfiguration, defaultType]);

  return (
    <SubscriptionUpsertContext.Provider
      value={{
        currentConfiguration,
        configurations,
        changeConfiguration,
      }}
    >
      {children}
    </SubscriptionUpsertContext.Provider>
  );
};

export function useSubscriptionUpsert() {
  return useContext(SubscriptionUpsertContext);
}
